import LeadDetails from "../Components/CRM/Jobs/LeadDetails";
import QuarryTool from "../Components/Database/QuarryTool";
import Login from "../Login";
import Register from "../Register";
import TruckDriverDataBase from "../Components/Database/TruckDriverDataBase";
import SystemUsers from "../Components/System Settings/SystemUsers";
import ManageRoles from "../Components/System Settings/ManageRoles";
import FinanceInvoiceManagement from "../Components/Finance/Invoices/FinanceInvoiceMangement";
import OwnerDatabase from "../Components/Database/OwnerDatabase";
import DispatchTool from "../Components/Operations/DispatchTool";
import Dashboard from "../Components/Dashboard";
import Invoice from "../Components/Finance/Invoices/Invoice";
import CompanyDetails from "../Components/CRM/Company/CompanyDetails";
import QuarryDetails from "../Components/Database/QuarryDetails";
import NotFoundView from "../Common/NotFoundView";
import MyProfile from "../Components/MyProfile/MyProfile";
import ProfileSetting from "../Components/MyProfile/ProfileSetting";
import TwistedNailOwnerOperator from "../Components/Database/TwistedNailOwnerOperator";
import OwnerOperator from "../Components/CRM/OwnerOperator/OwnerOperator";
import TruckingMaterial from "../Components/CRM/Jobs/TruckingMaterial";
import CustomerDatabase from "../Components/Database/CustomerDatabase";
import OwnerOperatorProfile from "../Components/CRM/OwnerOperator/OwnerOperatorProfile";
import AccountsPayable from "../Components/Finance/AccountsPayable";
import AccountsReceivable from "../Components/Finance/AccountsReceivable";
import TwistedNailOwnerOperatorProfile from "../Components/Database/TwistedNailOwnerOperatorProfile";
import SystemSettings from "../Components/System Settings/SystemSettings";
import TicketProcessing from "../Components/Finance/Ticket Processing/TicketProcessing";
import TrucksScheduler from "../Components/Scheduler/TrucksScheduler";
import RequestDispatch from "../Components/Operations/RequestDispatch";
import InvoiceManagementDetails from "../Components/Finance/Invoices/InvoiceManagementDetails";
import DispatchArchive from "../Components/Operations/DispatchArchive";
import salesPerson from "../Components/System Settings/salesPerson";
import SalesPersonDetails from "../Components/System Settings/SalesPersonDetails";
import RevenueAnalysis from "../Components/Finance/RevenueAnalysis";
import TicketUpload from "../Components/Finance/Ticket Upload/TicketUpload";
import restoreData from "../restoreData";
import UpdateJobDetails from "../Components/CRM/Jobs/UpdateJobDetails";
import ImageDatabase from "../Components/System Settings/ImageDatabase";
import BillManagement from "../Components/Finance/BillManagement";
import ManagerReview from "../Components/System Settings/ManagerReview";
import MaterialDatabase from "../Components/Database/MaterialDatabase";
import JobDataBase from "../Components/Database/JobDataBase";

const AppRoutes = [
  {
    key: "001",
    exact: true,
    path: "/",
    component: Login,
  },
  {
    key: "002",
    exact: true,
    path: "/login",
    component: Login,
  },
  {
    key: "003",
    exact: true,
    path: "/register",
    component: Register,
  },
  {
    key: "005",
    exact: true,
    path: `/app/jobs/job-profile/:id`,
    component: LeadDetails,
  },
  {
    key: "006",
    exact: true,
    path: "/app/quarry-finder-tool",
    component: QuarryTool,
  },
  {
    key: "007",
    exact: true,
    path: "/app/truck-driver-database",
    component: TruckDriverDataBase,
  },
  {
    key: "008",
    exact: true,
    path: "/app/system-users",
    component: SystemUsers,
  },
  {
    key: "009",
    exact: true,
    path: "/app/manage-roles",
    component: ManageRoles,
  },
  {
    key: "010",
    exact: true,
    path: "/app/ticket-processing",
    component: TicketProcessing,
  },
  {
    key: "011",
    exact: true,
    path: "/app/invoiceManagement",
    component: FinanceInvoiceManagement,
  },
  {
    key: "012",
    exact: true,
    path: "/app/owner-operator-database-tool",
    component: OwnerDatabase,
  },
  {
    key: "013",
    exact: true,
    path: "/app/dispatch-tool",
    component: DispatchTool,
  },
  {
    key: "014",
    exact: true,
    path: "/app/dashboard",
    component: Dashboard,
  },
  {
    key: "015",
    exact: true,
    path: "/app/invoice",
    component: Invoice,
  },
  {
    key: "016",
    exact: true,
    path: "/app/customer-account-profile/:id",
    component: CompanyDetails,
  },
  {
    key: "017",
    exact: true,
    path: "/app/quarry-profile/:id",
    component: QuarryDetails,
  },
  {
    key: "018",
    exact: true,
    path: "/app/notfound",
    component: NotFoundView,
  },
  {
    key: "019",
    exact: true,
    path: "/app/MyProfile",
    component: MyProfile,
  },
  {
    key: "020",
    exact: true,
    path: "/app/profile-settings",
    component: ProfileSetting,
  },
  {
    key: "021",
    exact: true,
    path: "/app/twisted-nail",
    component: TwistedNailOwnerOperator,
  },
  {
    key: "022",
    exact: true,
    path: "/app/owner-operator",
    component: OwnerOperator,
  },
  {
    key: "023",
    exact: true,
    path: "/app/trucking-material",
    component: TruckingMaterial,
  },
  {
    key: "024",
    exact: true,
    path: "/app/customer-database",
    component: CustomerDatabase,
  },
  {
    key: "025",
    exact: true,
    path: "/app/owner-operator-profile/:id",
    component: OwnerOperatorProfile,
  },
  {
    key: "026",
    exact: true,
    path: "/app/accountsReceivable",
    component: AccountsReceivable,
  },
  {
    key: "027",
    exact: true,
    path: "/app/accountsPayable",
    component: AccountsPayable,
  },
  {
    key: "028",
    exact: true,
    path: "/app/twisted-nail/carrier-profile/:id",
    component: TwistedNailOwnerOperatorProfile,
  },
  {
    key: "029",
    exact: true,
    path: "/app/system-settings",
    component: SystemSettings,
  },
  {
    key: "030",
    exact: true,
    path: "/app/trucks-scheduler",
    component: TrucksScheduler,
  },
  {
    key: "031",
    exact: true,
    path: "/app/dispatch-jobs",
    component: RequestDispatch,
  },
  {
    key: "031",
    exact: true,
    path: "/app/Invoice-Management/:id",
    component: InvoiceManagementDetails,
  },
  {
    key: "032",
    exact: true,
    path: "/app/dispatch-archive",
    component: DispatchArchive,
  },
  // {
  //   key: "033",
  //   exact: true,
  //   path: "/app/dispatch-Quote",
  //   component: QuoteBuildingTool,
  // },
  {
    key: "034",
    exact: true,
    path: "/app/salesPerson",
    component: salesPerson,
  },
  {
    key: "035",
    exact: true,
    path: "/app/salesPerson/:id",
    component: SalesPersonDetails,
  },
  {
    key: "036",
    exact: true,
    path: "/app/projectAnalysis",
    component: RevenueAnalysis,
  },
  {
    key: "037",
    exact: true,
    path: "/app/ticket-upload",
    component: TicketUpload,
  },
  {
    key: "040",
    exact: true,
    path: "/app/restoreData",
    component: restoreData,
  },
  {
    key: "041",
    exact: true,
    path: `/app/updateJobDetails/:id`,
    component: UpdateJobDetails,
  },
  {
    key: "042",
    exact: true,
    path: `/app/imageDatabase`,
    component: ImageDatabase,
  },
  {
    key: "044",
    exact: true,
    path: `/app/billManagement`,
    component: BillManagement,
  },
  {
    key: "045",
    exact: true,
    path: "/app/material-database",
    component: MaterialDatabase,
  },
  {
    key: "046",
    exact: true,
    path: "/app/job-database",
    component: JobDataBase,
  },
  {
    key: "047",
    exact: true,
    path: "/app/manager-review",
    component: ManagerReview,
  },
  {
    key: "038",
    component: NotFoundView,
  },
];
export default AppRoutes;
