import React, { Component } from "react";
import { Row, Col, Card, Button, Input, Form, InputNumber, Switch } from "antd";
import { BsArrowLeftShort } from "react-icons/bs";
import Loading from "../../../Common/Loading";
import TwistedNail from "../../../Images/TwistedNail.png";
import { withAppContext } from "../../../Store/AppContext";
import { withRouter } from "react-router-dom";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { MdDocumentScanner } from "react-icons/md";
import { connect } from "react-redux";
import {
  getInvoiceDetails,
  updateInvoices,
} from "../../../Redux/Actions/actions";
import BaseModal from "../../../Common/BaseModal";
import { PDFViewer } from "react-view-pdf";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import moment from "moment";

class InvoiceManagementDetails extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      showInvoiceData: {},
      leadsData: {},
      isModalVisible: false,
      enableLoading: false,
      selectedRows: [],
      isAllRowsSelected: false,
      showHours: false,
      minHoursJob: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.setState({
      enableLoading: true,
    });
    this.props?.context?.updateInvoiceId(id);
    this.props?.actionFetchGetInvoiceDetails(id);
    document.title = "Twisted Nail - Invoice Management";
  }

  componentDidUpdate = async (prevProps) => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    const { totalResult } = this.props;
    const { selectedRows, leadsData } = this.state;
    if (
      prevProps?.totalResult?.success === false &&
      totalResult?.success === true
    ) {
      if (selectedRows.length > 0) {
        let filterInfo = selectedRows?.filter((e) => e !== leadsData?.id);
        if (filterInfo?.length > 0) {
          this.setState({
            selectedRows: filterInfo,
          });
          this.ModalOpen(filterInfo[0]);
        } else {
          this.setState({
            isModalVisible: false,
            selectedRows: [],
            isAllRowsSelected: [],
          });
        }
      }
      const minHoursJob = totalResult?.invoiceData?.ticketDetails?.some(
        (ticket) =>
          ticket.material?.some(
            (item) =>
              item.truckingCostType === "Hour" ||
              item.truckingPriceType === "Hour"
          )
      );
      this.setState({
        showInvoiceData: totalResult?.invoiceData,
        minHoursJob,
        enableLoading: false,
      });
    }

    if (
      prevProps?.invoiceInfo?.success === false &&
      this.props?.invoiceInfo?.success === true
    ) {
      this.props?.actionFetchGetInvoiceDetails(id);
      this.setState({
        enableLoading: false,
      });
    }
  };

  calculateTimeDifferences = (objects) => {
    const currentTime = new Date(moment(objects?.othersData?.startTimeStamp));
    const nextTime = new Date(moment(objects?.othersData?.endTimeStamp));
    let differenceInSeconds;
    if (nextTime > currentTime) {
      differenceInSeconds = (nextTime - currentTime) / 1000; // Convert milliseconds to seconds
    } else {
      differenceInSeconds = (currentTime - nextTime) / 1000; // Convert milliseconds to seconds
    }

    return differenceInSeconds;
  };

  formatTimeDifference = (differenceInSeconds) => {
    if (differenceInSeconds < 60) {
      return `${differenceInSeconds} seconds`;
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      const remainingMinutes = Math.floor((differenceInSeconds % 3600) / 60);
      return `${hours} hour${
        hours !== 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`;
    } else {
      const days = Math.floor(differenceInSeconds / 86400);
      const remainingHours = Math.floor((differenceInSeconds % 86400) / 3600);
      const remainingMinutes = Math.floor((differenceInSeconds % 3600) / 60);
      return `${days} day${days !== 1 ? "s" : ""} ${remainingHours} hour${
        remainingHours !== 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`;
    }
  };

  showModal = (record) => {
    this?.formRef?.current?.setFieldsValue({
      quantity: record?.quantity,
      truckingPrice: record?.material[0]?.truckingPrice,
      totalPrice: record?.material[0]?.totalPrice,
    });
    this.setState({
      loading: false,
      isModalVisible: true,
      leadsData: record,
      selectedMaterialId: record?.material[0]?.id,
    });
  };

  ModalOpen = (item) => {
    const { showInvoiceData } = this.state;
    showInvoiceData?.ticketDetails?.map((e) => {
      if (item === e.id) {
        this.showModal(e);
      }
      return true;
    });
  };

  onFinish = (values) => {
    this.setState({
      enableLoading: true,
    });
    const { leadsData, showInvoiceData } = this.state;
    const { actionUpdateInvoice } = this.props;
    let ticketDetails = showInvoiceData.ticketDetails;
    ticketDetails.forEach((e) => {
      if (leadsData.id === e.id) {
        //TruckingPrice
        e.material[0].truckingPrice = Number(values["truckingPrice"]);
        e.selectedMaterial[0].truckingPrice = Number(values["truckingPrice"]);

        if (leadsData?.jobType === "Trucking & Material Job") {
          //MaterialPrice
          e.material[0].totalPrice = Number(values["totalPrice"]);
          e.selectedMaterial[0].totalPrice = Number(values["totalPrice"]);
        }
        //quantity
        e.material[0].quantity = Number(values["quantity"]);
        e.selectedMaterial[0].quantity = Number(values["quantity"]);
        e["quantity"] = Number(values["quantity"]);

        e["totalPrice"] =
          Number(values["truckingPrice"]) + Number(values["totalPrice"] || 0);

        e["totalTons"] = Number(values["quantity"]);
        e["total"] = Number(e["totalTons"]) * e["totalPrice"] || 0;
        e["sum"] = Number(e["total"]);
      }
    });

    showInvoiceData.ticketData.material[0].truckingPrice = Number(
      values["truckingPrice"]
    );
    showInvoiceData.ticketData.selectedMaterial[0].truckingPrice = Number(
      values["truckingPrice"]
    );

    let data = {
      entityData: {
        ticketData: showInvoiceData.ticketData,
        ticketDetails,
        ticketTon: ticketDetails?.reduce(
          (a, c) => (a = a + Number(c.totalTons)),
          0
        ),
        ticketsTotal: ticketDetails?.reduce(
          (a, c) => (a = a + Number(c.sum)),
          0
        ),
      },
    };

    actionUpdateInvoice(showInvoiceData.id, data);
  };

  handleRowSelect = (ticketNumber) => {
    this.setState((prevState) => {
      const selectedRows = prevState.selectedRows.includes(ticketNumber)
        ? prevState.selectedRows.filter((row) => row !== ticketNumber)
        : [...prevState.selectedRows, ticketNumber];
      const isAllRowsSelected =
        selectedRows.length === this.state.showInvoiceData.ticketDetails.length;
      return {
        selectedRows,
        isAllRowsSelected,
      };
    });
  };

  handleCheckboxClick = (ticketNumber) => {
    this.handleRowSelect(ticketNumber);
  };

  handleAllRowsSelect = () => {
    const { isAllRowsSelected } = this.state;
    const allTicketNumbers = this.state.showInvoiceData.ticketDetails.map(
      (e) => e.id
    );

    const updatedSelectedRows = isAllRowsSelected ? [] : [...allTicketNumbers];
    const updatedIsAllRowsSelected = !isAllRowsSelected;

    this.setState({
      selectedRows: updatedSelectedRows,
      isAllRowsSelected: updatedIsAllRowsSelected,
    });
  };

  calculateTotal = (qty, totalPrice) => {
    let numberString = totalPrice;
    let cleanNumberString =
      typeof numberString === "string"
        ? numberString?.replace(/,/g, "")
        : numberString;
    let numberValue = Number(cleanNumberString);

    return qty * numberValue;
  };

  calculateHours = (e) => {
    const convertHoursToSeconds = (hours) => {
      return hours * 3600;
    };
    const convertSecondsToHours = (seconds) => {
      return seconds / 3600;
    };
    const actualMiniumHours = convertHoursToSeconds(
      e?.material[0]?.hourlyCharge
    );
    const calulatedMiniumHours = this.calculateTimeDifferences(e);
    if (actualMiniumHours > calulatedMiniumHours) {
      return `${convertSecondsToHours(actualMiniumHours)} Hours`;
    } else {
      return `${convertSecondsToHours(calulatedMiniumHours)} Hours`;
    }
  };

  showQuntityType = () => {
    const { showInvoiceData } = this.state;
    const { ticketDetails } = showInvoiceData;
    const sameType = ticketDetails?.some((ticket) =>
      ticket.material?.some(
        (item) => item?.totalPriceType === item?.truckingPriceType
      )
    );
    const quantityType = showInvoiceData?.ticketData?.quantityType
      ? showInvoiceData?.ticketData?.quantityType
      : showInvoiceData?.ticketData?.selectedMaterial[0]?.quantityType;
    
    if (sameType || showInvoiceData?.ticketData?.jobType === 'Trucking Job') {
      return quantityType;
    } else {
      return (
        `${showInvoiceData?.ticketData?.material[0]?.landedPriceType}s` ||
        `${showInvoiceData?.ticketData?.material[0]?.totalPriceType}s`
      );
    }
  };

  render() {
    const {
      loading,
      showInvoiceData,
      leadsData,
      isModalVisible,
      enableLoading,
      showHours,
      minHoursJob,
      selectedRows,
    } = this.state;

    const Controls = () => {
      const { resetTransform } = useControls();

      return (
        <div className="tools">
          {/* <span class="custom-button" onClick={() => zoomIn()}>
            Zoom In
          </span>
          <span class="custom-button1" onClick={() => zoomOut()}>
            Zoom Out
          </span> */}
          <span class="custom-button2" onClick={() => resetTransform()}>
            Reset
          </span>
        </div>
      );
    };
    const numRows = 19;
    const tableRows = Array.from({ length: numRows }, (_, index) => (
      <tr key={index}>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {minHoursJob && <td></td>}
      </tr>
    ));

    return (
      <div
        style={{
          background: "#fafafa",
          textAlign: "left",
        }}
      >
        <Loading enableLoading={loading || enableLoading} />
        <div>
          <Row
            onClick={() => this.props.history.goBack()}
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              style={{ color: "#5f6c74", fontSize: "25px" }}
              className="back-button-return-icon"
            />
            <p
              style={{
                color: "#808080",
                fontSize: "16px",
                fontWeight: "600",
              }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </div>
        <Row>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <ReactToPrint content={() => this.componentRef}>
              <div className="printStyle">
                {selectedRows?.length > 0 && (
                  <>
                    {minHoursJob && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          lassName="title_changes"
                          style={{
                            marginRight: "10px",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          Waive Contract Hourly Minimum
                        </span>
                        <Switch
                          checkedChildren={"true"}
                          unCheckedChildren={"false"}
                          onChange={(e) => this.setState({ showHours: e })}
                          checked={showHours}
                        />
                      </div>
                    )}
                    <Button
                      type="primary"
                      onClick={() => this.ModalOpen(this.state.selectedRows[0])}
                    >
                      Edit Invoice
                    </Button>
                  </>
                )}

                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <Button type="primary" onClick={handlePrint}>
                      <MdDocumentScanner />
                      Export to PDF
                    </Button>
                  )}
                </PrintContextConsumer>
              </div>

              <Card
                className="export_class page"
                bodyStyle={{ padding: "0px" }}
                ref={(el) => (this.componentRef = el)}
              >
                <div className="main-print-box">
                  <div
                    style={{ padding: "35px 2%" }}
                    className="invoice-management-address"
                  >
                    <div className="fullpage-print-width">
                      <div className="grid-box-imgsys">
                        <img
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            marginTop: "-8px",
                            objectFit: "cover",
                          }}
                          src={TwistedNail}
                          alt="example"
                        />
                      </div>
                      <div className="grid-box-middle">
                        <div style={{ width: "100%" }}>
                          <span class="brdbottom-line top">
                            {showInvoiceData?.ticketData?.invoiceNotes?.notes1}
                          </span>
                          <span class="brdbottom-line top">
                            {" "}
                            {showInvoiceData?.ticketData?.invoiceNotes?.notes2}
                          </span>
                          <span class="brdbottom-line top">
                            {" "}
                            {showInvoiceData?.ticketData?.invoiceNotes?.notes3}
                          </span>
                        </div>
                      </div>
                      <div className="grid-box-last">
                        <div
                          style={{ float: "right", marginRight: "0px" }}
                          className="invoice-management-address"
                        >
                          <h3 style={{ fontWeight: "600", fontSize: "15px" }}>
                            Truck #{" "}
                            <span className="brdtxtline-rightside">
                              {showInvoiceData?.ticketData?.truckNumber}
                            </span>
                          </h3>

                          <h3 style={{ fontWeight: "600", fontSize: "15px" }}>
                            Invoice #{" "}
                            <span className="brdtxtline-rightside">
                              {showInvoiceData?.invoiceNumber}
                            </span>
                          </h3>
                          <h3 style={{ fontWeight: "600", fontSize: "15px" }}>
                            Invoice Date:
                            <span className="brdtxtline-rightside">
                              {showInvoiceData?.ticketData?.ticketDate}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span className="thank-you">
                      Thank You For Your Business
                    </span>
                  </div>
                  <div style={{ padding: "10px 2%" }}>
                    <div className="grid-box-choice">
                      <div className="gridchoice-box">
                        <span
                          style={{ fontWeight: "bold" }}
                          className="table-font-mobile"
                        >
                          Customer :{" "}
                          <span
                            style={{
                              fontWeight: "normal",
                              textDecoration: "underline",
                            }}
                            className="table-font-mobile"
                          >
                            {showInvoiceData?.companyName}
                          </span>
                        </span>
                      </div>
                      <div className="gridchoice-box1">
                        <span
                          style={{ fontWeight: "bold" }}
                          className="table-font-mobile"
                        >
                          {" "}
                          Dates:{" "}
                          <span
                            style={{
                              fontWeight: "normal",
                              textDecoration: "underline",
                            }}
                            className="table-font-mobile"
                          >
                            {showInvoiceData?.startDate}
                          </span>
                        </span>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="table-font-mobile"
                        >
                          {" "}
                          to{" "}
                          <span
                            style={{
                              fontWeight: "normal",
                              textDecoration: "underline",
                            }}
                            className="table-font-mobile"
                          >
                            {showInvoiceData?.endDate}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "5px 10px", width: "100%" }}>
                    <div
                      className="ant-table-content ant-table invoicetablennd"
                      style={{
                        margin: "5px 0px",
                        float: "left",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <table
                        style={{ tableLayout: "auto", whiteSpace: "nowrap" }}
                      >
                        <thead className="ant-table-thead rows-col textcol">
                          <tr>
                            <th className="no-print">
                              <input
                                type="checkbox"
                                checked={this.state.isAllRowsSelected}
                                onChange={this.handleAllRowsSelect}
                              />
                            </th>
                            <th>Date</th>
                            <th
                              style={{
                                width: showInvoiceData?.ticketData
                                  ?.isTruckingAndMaterialJob
                                  ? "20%"
                                  : "14%",
                              }}
                            >
                              Vendor
                            </th>

                            <th
                              style={{
                                width: showInvoiceData?.ticketData
                                  ?.isTruckingAndMaterialJob
                                  ? "20%"
                                  : "10%",
                              }}
                            >
                              Project
                            </th>
                            <th
                              style={{
                                width: showInvoiceData?.ticketData
                                  ?.isTruckingAndMaterialJob
                                  ? "20%"
                                  : "11%",
                              }}
                            >
                              Ticket
                            </th>
                            <th style={{ width: "24%" }}>Material</th>
                            {minHoursJob && (
                              <th style={{ width: "10%" }}>Hours</th>
                            )}
                            <th>
                              {this.showQuntityType(
                                showInvoiceData.ticketDetails
                              )}
                            </th>
                            <th
                              style={{ minWidth: "90px", textAlign: "center" }}
                            >
                              Rate
                            </th>
                            <th
                              style={{ minWidth: "90px", textAlign: "right" }}
                            >
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody child-odd">
                          {showInvoiceData.ticketDetails?.map((e) => (
                            <tr
                              key={e.id}
                              style={{
                                color: "#000",
                                fontWeight: "500",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // this.showModal(e);
                              }}
                            >
                              <td className="no-print">
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(e.id)}
                                  onChange={() =>
                                    this.handleCheckboxClick(e.id)
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </td>
                              <td>
                                <div className="txtinvoiceprt">
                                  {e.ticketDate}
                                </div>
                              </td>

                              <td>
                                <div className="txtinvoiceprt">
                                  {e?.isTruckingAndMaterialJob
                                    ? e.companyName
                                    : "Trucking Only"}
                                </div>
                              </td>

                              <td>
                                <div className="txtinvoiceprt">
                                  {e.jobNumber}
                                </div>
                              </td>
                              <td>
                                <div className="txtinvoiceprt">
                                  {e.ticketNumber}
                                </div>
                              </td>
                              <td>
                                <div className="txtinvoiceprt">
                                  {e?.isTruckingAndMaterialJob
                                    ? e.material.map(
                                        (m) => m.materialShortValue
                                      )
                                    : e.material.map((m) => m.materialName)}
                                </div>
                              </td>
                              {minHoursJob && (
                                <td style={{ width: "10%" }}>
                                  {showHours && selectedRows?.includes(e.id)
                                    ? this.formatTimeDifference(
                                        this.calculateTimeDifferences(e)
                                      )
                                    : this.calculateHours(e)}
                                </td>
                              )}
                              <td>
                                <div className="txtinvoiceprt">
                                  {`${e.quantity.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })}`}
                                </div>
                              </td>
                              <td>
                                <div className="txtinvoiceprt2">{`$ ${e.totalPrice?.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}`}</div>
                              </td>
                              <td>
                                <div className="txtinvoiceprt1">
                                  {"$ "}
                                  {this.calculateTotal(
                                    e.quantity,
                                    e.totalPrice
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </td>
                            </tr>
                          ))}
                          {tableRows}
                        </tbody>
                      </table>
                      <table>
                        <tfoot className="ant-table-tfoot">
                          <tr>
                            <td>
                              <span class="line1-text">
                                {" "}
                                <b>Twisted Nail Broker Services, LLC.</b>
                              </span>
                              <span class="line1-text">
                                {" "}
                                266 Pioneer Parkway, Waco, Texas 76708
                              </span>
                              <span class="line1-text">
                                254.644.3714 | remittance@twistednail.com
                              </span>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <h4>
                                <b>Total Units</b>{" "}
                                <span className="underscore-text">
                                  {showInvoiceData?.ticketTon?.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </span>
                              </h4>

                              <h4>
                                <b>Subtotal</b>{" "}
                                <span className="underscore-text">
                                  {"$ "}
                                  {showInvoiceData?.ticketsTotal?.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </span>
                              </h4>

                              {showInvoiceData.ticketDetails?.[0].material[0]
                                ?.materialTax === "Natural" ? (
                                <h4>
                                  <b>Sales Tax</b>{" "}
                                  <span className="underscore-text">
                                    {"$ "}0.00
                                  </span>
                                </h4>
                              ) : (
                                <h4>
                                  <b>Sales Tax</b>{" "}
                                  <span className="underscore-text">
                                    {"$ "}
                                    {showInvoiceData?.ticketDetails &&
                                    showInvoiceData.ticketDetails?.length > 0 &&
                                    showInvoiceData.ticketDetails?.[0]
                                      .material[0]?.additionalTaxRate !==
                                      undefined
                                      ? (
                                          (parseFloat(
                                            showInvoiceData.ticketDetails?.[0]
                                              .material[0]?.additionalTaxRate
                                          ) *
                                            parseFloat(
                                              showInvoiceData.ticketsTotal
                                            )) /
                                          100
                                        ).toFixed(2)
                                      : "0.00"}
                                  </span>
                                </h4>
                              )}

                              <h4>
                                <b>Balance Due</b>{" "}
                                <span className="underscore-text">
                                  {"$ "}
                                  {(
                                    parseFloat(showInvoiceData?.ticketsTotal) +
                                    (showInvoiceData?.ticketDetails &&
                                    showInvoiceData.ticketDetails?.length > 0 &&
                                    parseFloat(
                                      showInvoiceData.ticketDetails?.[0]
                                        ?.material[0]?.additionalTaxRate
                                    ) > 0
                                      ? (parseFloat(
                                          showInvoiceData.ticketDetails?.[0]
                                            ?.material[0]?.additionalTaxRate
                                        ) *
                                          parseFloat(
                                            showInvoiceData.ticketsTotal
                                          )) /
                                        100
                                      : 0)
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </h4>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </Card>
            </ReactToPrint>
          </Col>
        </Row>

        {isModalVisible && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={`Edit Details`}
            onCancel={() =>
              this.setState({
                isModalVisible: false,
                ticketUploaded: "",
                percentage: 0,
                ticketStatus: "",
              })
            }
            formId="ticketForm"
            loading={loading}
            submitButton={"Submit"}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "850px"
            }
            btnHeight="40px"
          >
            <Form id="ticketForm" onFinish={this.onFinish} ref={this.formRef}>
              {leadsData?.singlePdfTicket !== "" && (
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  className="pdfviewcls"
                >
                  <TransformWrapper
                    initialScale={1}
                    initialPositionX={1}
                    initialPositionY={1}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <Controls />
                        <TransformComponent>
                          <PDFViewer
                            url={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${leadsData?.singlePdfTicket}?#view=FitH`}
                            viewer="url"
                            style={{
                              width: "100%",
                              height: "400px",
                              overflow: "auto",
                            }}
                            onError={(error) => {
                              console.error("Error loading PDF:", error);
                            }}
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </Col>
              )}

              <div className="bordered-class">
                {leadsData?.ticketVerification !== "Open" && (
                  <>
                    <Row>
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{
                          backgroundColor: "#586370",
                          color: "#fff",
                          fontWeight: "bold",
                          margin: "10px 0px",
                          borderRadius: "3px",
                        }}
                      >
                        <span
                          style={{ padding: "5px 0px", display: "flex" }}
                          className="title_changes_name"
                        >
                          TICKET DETAILS
                        </span>
                      </Col>
                    </Row>
                    <Row
                      gutter={[12, 0]}
                      style={{
                        marginBottom: "10px",
                      }}
                      className="tiprotitle"
                    >
                      <Col
                        xxl={{
                          span:
                            leadsData?.jobType === "Trucking & Material Job"
                              ? 8
                              : 12,
                        }}
                        xl={{
                          span:
                            leadsData?.jobType === "Trucking & Material Job"
                              ? 8
                              : 12,
                        }}
                        lg={{
                          span:
                            leadsData?.jobType === "Trucking & Material Job"
                              ? 8
                              : 12,
                        }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <b style={{ marginBottom: "10px" }}>Quantity:</b>
                        <Form.Item
                          name="quantity"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Quantity!",
                            },
                          ]}
                          initialValue={leadsData?.quantity}
                        >
                          <Input className="form-input" />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{
                          span:
                            leadsData?.jobType === "Trucking & Material Job"
                              ? 8
                              : 12,
                        }}
                        xl={{
                          span:
                            leadsData?.jobType === "Trucking & Material Job"
                              ? 8
                              : 12,
                        }}
                        lg={{
                          span:
                            leadsData?.jobType === "Trucking & Material Job"
                              ? 8
                              : 12,
                        }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <b style={{ marginBottom: "10px" }}>Truck Rate:</b>{" "}
                        <Form.Item
                          name="truckingPrice"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Trucking Price!",
                            },
                          ]}
                          initialValue={leadsData?.material[0]?.truckingPrice?.toLocaleString(
                            2
                          )}
                        >
                          <InputNumber
                            prefix={"$"}
                            precision={2}
                            decimalSeparator={"."}
                            placeholder="Please Enter Trucking Rate"
                            style={{
                              width: "100%",
                            }}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          />
                        </Form.Item>
                      </Col>
                      {leadsData?.jobType === "Trucking & Material Job" && (
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 8 }}
                          md={{ span: 12 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <b style={{ marginBottom: "10px" }}>Material Rate:</b>{" "}
                          <Form.Item
                            name="totalPrice"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Trucking Price!",
                              },
                            ]}
                            initialValue={leadsData?.material[0]?.totalPrice?.toLocaleString(
                              2
                            )}
                          >
                            <InputNumber
                              prefix={"$"}
                              precision={2}
                              decimalSeparator={"."}
                              placeholder="Please Enter Material Rate"
                              style={{
                                width: "100%",
                              }}
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </>
                )}
              </div>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalResult: state?.reducer?.invoiceResult,
  loading: state?.reducer?.invoiceResult?.loading,
  errorLoading: state?.reducer?.invoiceResult?.error,
  invoiceInfo: state?.reducer?.invoiceInfo,
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetInvoiceDetails: (id) => {
    dispatch(getInvoiceDetails(id));
  },
  actionUpdateInvoice: (id, data) => {
    dispatch(updateInvoices(id, data));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(InvoiceManagementDetails)));
