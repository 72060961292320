import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addTicket,
  deleteTicket,
  // getDispatch,
  getTickets,
  getOrders,
  getTrucksList,
  updateOrder,
} from "../../../Redux/Actions/actions";
import { Storage } from "aws-amplify";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Select,
  Upload,
  notification,
  Pagination,
  Progress,
  message,
  Switch,
  DatePicker,
  Popover,
  Table,
} from "antd";
import {
  PlusOutlined,
  CheckOutlined,
  InboxOutlined,
  CloseOutlined,
  // ArrowRightOutlined,
} from "@ant-design/icons";
import { withAppContext } from "../../../Store/AppContext";
import moment from "moment";
import AOS from "aos";
import BaseModal from "../../../Common/BaseModal";
import Loading from "../../../Common/Loading";
import SearchFunction from "../../../Common/SearchFunction";
import TicketUploadProcessing from "./TicketUploadProcessing";
import Nodata from "../../nodata";
import { BsArrowLeftShort } from "react-icons/bs";
import "aos/dist/aos.css";
import RejectUpload from "./RejectUpload";
import { PDFViewer } from "react-view-pdf";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

const { Option } = Select;
const { Dragger } = Upload;
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const { Column, ColumnGroup } = Table;

class TicketUpload extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      totalTicketsData: [],
      filterData: [],
      groupedData: {},
      selectedTickets: [],
      selectedRowKeys: [],
      assignedJobs: [],
      filterJobs: [],
      assignedCustomers: [],
      isModalVisible: false,
      newTicket: false,
      ticketUploaded: "",
      leadsData: {},
      selectedJob: "",
      percentage: 0,
      showApproved: true,
      showProcesssed: true,
      ticketLoading: false,
      fileList: [],
      dateRestriction: "",
      minValue: 0,
      maxValue: 10,
      currentPage: 1,
      perpageSize: 0,
      minjobValue: 0,
      maxjobValue: 10,
      jobcurrentPage: 1,
      jobperpageSize: 0,
      jobInfoData: {},
      showFinace: false,
      customerId: undefined,
      materialId: "",
      showSingleJob: false,
      assignedMaterials: [],
      assignedvendors: [],
      orderDetails: [],
      individualOrder: {},
      showSingleOrder: false,
      rejectedData: [],
      filterMaterial: [],
      individualJobId: "",
      trucksResult: [],
      assignTrucks: [],
      assignTrucksInfo: [],
      ticketResult: "",
      suggestModal: false,
      totalSuggestJobData: [],
      dispatchDate: "",
      suggestTrucks: [],
      suggestQuarry: [],
      suggestMaterial: [],
      showJobData: [],
      jobTruckType: "",
      selectedVendorId: null,
      selectedMaterialId: null,
    };
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Ticket Processing";
    const {
      actionFetchTrucksList,
      actionFetchGetOrders,
      actionFetchGetTickets,
    } = this.props;

    actionFetchGetOrders();
    // actionFetchGetDispatch();
    actionFetchGetTickets();
    actionFetchTrucksList();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps?.trucksResult?.success === false &&
      this.props?.trucksResult?.success === true
    ) {
      let newData = this.props?.trucksResult?.trucksListData;
      let carrierData = [];
      if (newData?.length) {
        newData?.forEach((c) => {
          if (c?.carrierDetails?.length) {
            c?.carrierDetails?.forEach((t) => {
              if (t?.truckId !== null && t?.truckId !== undefined) {
                carrierData?.push({
                  carrierId: c?.id,
                  carrier_name: t?.carrier_name,
                  parking_location: t?.parking_location,
                  truckingId: t?.id,
                  truckId: t?.truckId,
                  truck_code: t?.truck_code,
                  truck_type: t?.truck_type,
                  lat: t?.lat,
                  lng: t?.lng,
                  usdot: c?.usdot,
                  contact_name: c?.contactDetails
                    ?.filter((con) => con?.isPrimaryContact === true)
                    ?.map((p) =>
                      p?.contact_name
                        ? p?.contact_name
                        : p?.firstName + " " + p?.lastName
                    )[0],
                  contact_number: c?.contactDetails
                    ?.filter((con) => con?.isPrimaryContact === true)
                    ?.map((p) => (p?.phone ? p?.phone : p?.contact_number))[0],
                  insurance_expiry: c?.insurance_expiry,
                  payment_terms: c?.payment_terms,
                  payment_discount: c?.payment_discount,
                  direct_deposit: c?.direct_deposit,
                  showCarrier: c?.showCarrier,
                  banCarrier: c?.banCarrier,
                  assignTrucksInfo: {
                    truck_type: t?.truck_type,
                    truckId: t?.truckId,
                    truck_code: t?.truck_code,
                    payment_terms: c?.payment_terms,
                    parking_location: t?.parking_location,
                    lat: t?.lat,
                    lng: t?.lng,
                    secondTruckNo: t?.secondTruckNo,
                    payment_discount: c?.payment_discount,
                    id: t?.id,
                    carrierId: c?.id,
                    carrier_name: t?.carrier_name,
                    fileType: t?.fileType,
                    overWeightExpiryDate: t?.overWeightExpiryDate,
                    docAttachmentId: t?.docAttachmentId,
                  },
                });
              }
            });
          }
        });
        this.setState({
          trucksResult: carrierData,
        });
      }
    }
    if (
      prevProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      this.showTIcketInfo(this.props?.totalResult?.ticketsData, false);
    }
    if (
      prevProps?.dispatchResult?.success === false &&
      this.props?.dispatchResult?.success === true
    ) {
      let data = this.props?.dispatchResult?.ordersData?.filter(
        (e) =>
          "assigned" in e === true &&
          e.assigned &&
          e.dispatchStatus === "Completed"
      );
      let customers = data?.filter(
        (value, index, self) =>
          index === self.findIndex((e) => e.companyId === value.companyId)
      );

      let filterJobs = data?.filter(
        (value, index, self) =>
          index ===
          self.findIndex((e) => e.originalJobId === value.originalJobId)
      );

      this.setState({
        filterJobs: filterJobs,
        assignedJobs: data,
        assignedCustomers: customers,
        dispatchJobs: this.props?.dispatchResult,
        loading: false,
      });
    }

    if (
      prevProps?.ticketsInfo?.success === false &&
      this.props?.ticketsInfo?.success === true
    ) {
      this.onCancelSuggestModal();
      this.setState(
        {
          ticketResult: this.props?.ticketsInfo.result,
          isModalVisible: false,
          ticketUploaded: "",
          leadsData: {},
          selectedJob: "",
        },
        () => this.props?.actionFetchGetTickets()
      );
    }
  }

  init = (data) => {
    const intervalId = setInterval(() => {
      const lastDiv = document.querySelector(
        ".rc-virtual-list-holder-inner > div:last-child"
      );

      if (lastDiv) {
        const title = lastDiv.getAttribute("title");
        if (title === `${Object.keys(data)?.length} / page`) {
          lastDiv.style.padding = "5px 16px";
          lastDiv.innerHTML = "All / page";
        }
        clearInterval(intervalId); // Stop the interval once the content is updated
      }
    }, 300);

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  };

  showTIcketInfo = (ticketsData, status) => {
    const { jobInfoData, individualJobId, ticketResult } = this.state;
    const lastweeksDate = moment().subtract(3, "weeks").calendar();
    let sortData = ticketsData;
    if (!status) {
      sortData = ticketsData?.filter((ele) =>
        moment(moment(ele?.uploadDate).format("L")).isSameOrAfter(
          moment(lastweeksDate).format("L")
        )
      );
    }
    let data = sortData?.sort(
      (a, b) =>
        new Date(moment(b?.uploadDate).format("L")) -
        new Date(moment(a?.uploadDate).format("L"))
    );

    let individualJob = {};
    data.map((a) => {
      individualJob[a["jobId"]] =
        a["jobId"] in individualJob === true ? individualJob[a["jobId"]] : [];
      individualJob[a["jobId"]].push(a);
      return true;
    });
    if (ticketResult !== "" && individualJob[ticketResult?.jobId]) {
      this.handleClickCard(
        ticketResult?.jobId,
        Object.values(individualJob[ticketResult?.jobId]),
        ticketResult?.mid
      );
    }
    if (
      Object.values(jobInfoData)?.length > 0 &&
      individualJobId !== "" &&
      individualJob !== undefined &&
      individualJob[individualJobId]
    ) {
      this.handleClickCard(
        individualJobId,
        Object.values(individualJob[individualJobId])
      );
    }
    this.setState({
      totalTicketsData: ticketsData,
      filterData: ticketsData,
      groupedData: {},
      showSingleJob: true,
      individualJobData: individualJob,
      maxValue: Object.keys(individualJob)?.length,
      perpageSize: Object.keys(individualJob)?.length,
      selectedTickets: [],
      selectedRowKeys: [],
    });
    this.init(individualJob);
  };

  uploadTicketPdf = async (info) => {
    this.setState({
      ticketLoading: true,
    });

    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              let ticketUploadPicture = info["file"]["uid"] + "_TNBS_ticket";
              Storage.put(ticketUploadPicture, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState(
                  {
                    ticketUploaded: result.key,
                    ticketLoading: false,
                    percentage: info?.file?.percent,
                  },
                  this.settime()
                );
              });
            } else {
              this.setState({
                ticketLoading: false,
                percentage: 0,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        this.setState({
          ticketUploaded: "",
          ticketLoading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState(
          {
            ticketLoading: false,
            percentage: 0,
          },
          this.settime()
        );
    }
  };

  settime = () => {
    setTimeout(() => {
      this.setState({ percentage: this.state.percentage + 40 });
    }, 1000);
  };

  handleUploadType = (file) => {
    let isPDF = file.type === "application/pdf";
    if (!isPDF) {
      notification["error"]({
        message: `${file.name} is not a PDf file`,
      });
      this.setState({
        ticketUploaded: "",
        ticketLoading: false,
      });
      return Upload.LIST_IGNORE;
    }
  };

  showSuggestModal = () => {
    const { dispatchJobs } = this.state;
    const dispatchData = dispatchJobs.ordersData?.filter(
      (e) => e.dispatchStatus === "Completed"
    );
    // let materialInfo = dispatchJobs.ordersData?.flatMap(e => e?.material?.map(value => ({ dispatchDate: e.dispatchDate, ...value })));
    let materialInfo = dispatchData?.flatMap((e) => e?.material);
    let assignTrucksInfoData = materialInfo?.flatMap((e) => e.assignTrucksInfo);
    const suggestTrucks = assignTrucksInfoData?.filter(
      (value, index, self) =>
        value !== undefined &&
        self.findIndex((e) => e?.truckId === value?.truckId) === index
    );
    const suggestQuarry = dispatchData?.filter(
      (value, index, self) =>
        self.findIndex(
          (e) => e?.material[0]?.vendorId === value?.material[0]?.vendorId
        ) === index
    );
    const suggestMaterial = dispatchData?.filter(
      (value, index, self) =>
        value !== undefined &&
        self.findIndex(
          (e) => e?.material[0]?.materialId === value?.material[0]?.materialId
        ) === index
    );
    this.setState({
      suggestModal: true,
      totalSuggestJobData: dispatchData,
      suggestTrucks,
      suggestQuarry,
      suggestMaterial,
      selectedJob: "",
      leadsData: {},
      percentage: 0,
      ticketUploaded: "",
      assignedMaterials: [],
      assignedvendors: [],
      showJobData: [],
    });
  };

  onCancelSuggestModal = () => {
    this.setState({
      suggestModal: false,
      totalSuggestJobData: [],
      showJobData: [],
      suggestTrucks: [],
      suggestQuarry: [],
      suggestMaterial: [],
      selectedJob: "",
      leadsData: {},
      ticketUploaded: "",
      percentage: 0,
      newTicket: false,
    });
  };

  handleFinish = (value) => {
    const { totalSuggestJobData, jobTruckType } = this.state;
    let jobsData = [];
    if (value.vendorId) {
      jobsData = totalSuggestJobData?.filter(
        (e) => e.jobType === "Trucking & Material Job"
      );
    } else {
      jobsData = totalSuggestJobData;
    }
    const result = this.findAllFilterData(
      jobsData,
      value,
      jobTruckType === "Trucking Job" ? "id" : "materialId"
    );
    
    if (result?.length > 0) {
      // remove Duplicate values
      const resultData = result?.filter(
        (value, index, self) =>
          self.findIndex((e) => e.material[0]?.id === value.material[0]?.id) ===
          index
      );
      this.setState({
        showJobData: resultData,
      });
    } else {
      message.error("No jobs found with this criteria");
    }
  };

  findAllFilterData = (data, filterCriteria, mId) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.dispatchDate ||
          moment(filterCriteria.dispatchDate).format("l") ===
            item.dispatchDate) &&
        (!filterCriteria.truckNumber ||
          item.material[0]["assignTrucks"]?.includes(
            filterCriteria.truckNumber
          )) &&
        (!filterCriteria.materialId ||
          filterCriteria.materialId === item.material[0][mId]) &&
        (!filterCriteria.vendorId ||
          filterCriteria.vendorId === item.material[0]["vendorId"])
      ) {
        return true;
      }
      return false;
    });
  };

  showModal = () => {
    this.setState({
      isModalVisible: true,
      selectedJob: "",
      leadsData: {},
      percentage: 0,
      ticketUploaded: "",
      newTicket: true,
      assignedMaterials: [],
      assignedvendors: [],
    });
  };

  handleApproved = () => {
    this.setState({
      showApproved: !this.state.showApproved,
    });
  };

  handlePending = () => {
    this.setState({
      showProcesssed: !this.state.showProcesssed,
    });
  };

  searchResult = (searchValue) => {
    const { totalTicketsData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = totalTicketsData.filter(
        (e) => parseInt(e["jobNumber"]) === searchValue
      );
    } else {
      searchFilter = totalTicketsData.filter(
        (e) => e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    let individualJob = {};
    searchFilter.map((a) => {
      individualJob[a["jobId"]] =
        a["jobId"] in individualJob === true ? individualJob[a["jobId"]] : [];
      individualJob[a["jobId"]].push(a);
      return true;
    });
    this.setState({
      individualJobData: individualJob,
      filterData: searchFilter,
    });
  };

  handleClickCard = (jobId, jobValue, mId) => {
    const { ticketResult } = this.state;
    let individualOrder = {};
    jobValue.map((e) => {
      individualOrder[e["mid"]] =
        e["mid"] in individualOrder === true ? individualOrder[e["mid"]] : [];
      individualOrder[e["mid"]].push(e);
      return e;
    });
    this.init(individualOrder);
    if (ticketResult !== "" && individualOrder[mId]) {
      this.handleSingleOrder(mId, individualOrder[mId]);
    }
    this.setState({
      individualOrder,
      showSingleOrder: true,
      individualJobId: jobId,
      customerId: Object.values(individualOrder)[0][0]?.companyId,
      jobperpageSize: Object.keys(individualOrder)?.length,
      maxjobValue: Object.keys(individualOrder)?.length,
    });
  };

  handleSingleOrder = (jobKey, jobValue) => {
    this.setState({
      showFinace: true,
      jobInfoData: { [jobKey]: jobValue },
      ticketResult: "",
    });
  };

  showIndividualJob = (jobKey, jobValue) => {
    let individualJob = {};
    jobValue.map((a) => {
      individualJob[a["jobId"]] =
        a["jobId"] in individualJob === true ? individualJob[a["jobId"]] : [];
      individualJob[a["jobId"]].push(a);
      return true;
    });
    this.setState({
      individualJobData: individualJob,
      showSingleJob: true,
      dateRestriction: jobKey,
    });
  };

  handleCancel = () => {
    this.setState({
      showFinace: false,
      // jobInfoData: {},
    });
    this.props?.actionFetchGetTickets();
  };

  onResetData = () => {
    this.props?.actionFetchGetTickets();
    // this.props?.actionFetchGetDispatch();
  };

  customersCount = (value) => {
    let filterCustomers = value?.filter(
      (val, ind, self) =>
        ind === self?.findIndex((e) => e.companyId === val.companyId)
    );
    return filterCustomers;
  };

  stageCount = (value, type) => {
    let filterCustomers = value?.filter((e) => e.ticketVerification === type);
    return filterCustomers;
  };

  onClickReturn = () => {
    this.setState({
      showSingleJob: false,
    });
  };

  onSelectJob = (value, option) => {
    this.formRef.current.setFieldsValue({
      selectVendor: [],
      selectMaterial: [],
      selectOrder: [],
    });
    this.setState({
      assignedvendors: [],
      assignedMaterials: [],
      orderDetails: [],
    });
    const { assignedJobs } = this.state;
    let jobDetails = assignedJobs.filter(
      (job) => String(job.originalJobId) === String(option.value)
    );
    let filterMaterial = jobDetails?.filter(
      (value, index, self) =>
        index ===
        self.findIndex((e) => e?.material[0]?.id === value?.material[0]?.id)
    );
    this.setState({
      orderDetails: jobDetails,
      filterMaterial,
    });
  };

  showJobDetails = (jobDetails, orderDetails) => {
    const { leadsData } = this.state;
    let materialInfo = [];
    const combinedAssignTrucksInfo = [];
    if (orderDetails?.length > 0) {
      orderDetails?.forEach((eachItem) => {
        if (eachItem?.material[0]?.id === jobDetails?.material[0]?.id) {
          materialInfo.push(eachItem.material[0]);
          if (materialInfo?.length > 0) {
            materialInfo?.forEach((item) => {
              item.assignTrucksInfo.forEach((info) => {
                const existingInfo = combinedAssignTrucksInfo.find(
                  (resultInfo) => resultInfo.truckId === info.truckId
                );
                if (!existingInfo) {
                  combinedAssignTrucksInfo.push(info);
                }
              });
            });
          }
        }
      });
    }
    if (jobDetails) {
      const combinedAssignTrucks = [
        ...new Set(materialInfo.map((item) => item.assignTrucks).flat()),
      ];

      jobDetails.material[0].assignTrucksInfo = combinedAssignTrucksInfo;
      jobDetails.material[0].assignTrucks = combinedAssignTrucks;
      // if (assign) {
      //   this.formRef.current.setFieldsValue({
      //     selectMaterial: jobDetails["material"][0]["assignTrucks"],
      //   });
      // }
      leadsData["salesPersonId"] = jobDetails["salesPersonId"];
      leadsData["salesCommission"] = jobDetails["salesCommission"];
      leadsData["jobId"] = jobDetails["originalJobId"];
      leadsData["jobNumber"] = jobDetails["jobNumber"];
      leadsData["jobName"] = jobDetails["jobName"];
      leadsData["dropoffAddress"] = jobDetails["dropoff"];
      leadsData["material"] = jobDetails["material"];
      leadsData["materiaName"] = jobDetails["materiaName"];
      leadsData["pickupAddress"] = jobDetails["pickup"];
      leadsData["jobType"] = jobDetails["jobType"];
      leadsData["companyId"] = jobDetails["companyId"];
      leadsData["companyName"] = jobDetails["companyName"];
      leadsData["companyAddress"] = jobDetails["companyAddress"];
      leadsData["companyPhone"] = jobDetails["companyNumber"];
      leadsData["taxExempt"] = jobDetails["taxExempt"];
      leadsData["ticketNumber"] = "";
      leadsData["ticketDate"] = "";
      leadsData["assignTrucks"] = jobDetails["material"][0]["assignTrucks"];
      leadsData["dispatchNotes"] = jobDetails["dispatchNotes"];
      leadsData["jobNotes"] = jobDetails["notes"];
      leadsData["quantityType"] = jobDetails["trucksRequestedType"];
      // leadsData["orderNumber"] = jobDetails["orderNumber"];
      leadsData["orderId"] = jobDetails["id"];
      leadsData["approvedDate"] = jobDetails["approvedDate"];
      leadsData["invoiceNotes"] = jobDetails["invoiceNotes"];
      leadsData["waiveContract"] = jobDetails["waiveContract"];
      leadsData["originalJobType"] = jobDetails["originalJobType"];
      this.setState({
        selectedJob: jobDetails.originalJobId,
        leadsData,
        newTicket: true,
      });
    }
  };

  handlePagination = async (page, pageSize, load) => {
    const { showSingleOrder } = this.state;
    if (showSingleOrder) {
      if (page <= 1) {
        this.setState({
          minjobValue: 0,
          maxjobValue: pageSize,
        });
      } else {
        this.setState({
          minjobValue: (page - 1) * pageSize,
          maxjobValue: page * pageSize,
        });
      }
      this.setState({
        jobcurrentPage: page,
        jobperpageSize: pageSize,
      });
    } else {
      if (page <= 1) {
        this.setState({
          minValue: 0,
          maxValue: pageSize,
        });
      } else {
        this.setState({
          minValue: (page - 1) * pageSize,
          maxValue: page * pageSize,
        });
      }
      this.setState({
        currentPage: page,
        perpageSize: pageSize,
      });
    }
  };

  handleAssignChange = (trucks, trucksInfo) => {
    this.setState({
      assignTrucks: [trucks],
      assignTrucksInfo: [trucksInfo?.assignTrucksInfo],
    });
  };

  onFinish = (values) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const {
      ticketUploaded,
      leadsData,
      selectedJob,
      assignTrucks,
      assignTrucksInfo,
    } = this.state;
    let data = {
      entityData: {},
    };
    this.setState({
      loading: true,
    });
    try {
      let newMaterial = leadsData?.material[0];
      if (assignTrucks?.length && assignTrucksInfo?.length) {
        let newMaterial = leadsData?.material[0];
        // const combineTruck = newMaterial?.assignTrucks.concat(assignTrucks);
        // const combineTruckInfo =
        //   newMaterial?.assignTrucksInfo.concat(assignTrucksInfo);
        newMaterial["assignTrucks"] = assignTrucks;
        newMaterial["assignTrucksInfo"] = assignTrucksInfo;
        newMaterial["truckType"] = [assignTrucksInfo[0].truck_type];
      }
      values["othersData"] = {
        waiveContract: leadsData?.waiveContract,
        originalJobType: leadsData?.originalJobType,
      };
      values["invoiceNotes"] = leadsData?.invoiceNotes;
      values["approvedDate"] = leadsData?.approvedDate;
      // values["orderId"] = leadsData?.orderId;
      // values["orderNumber"] = leadsData?.orderNumber;
      values["quantityType"] = leadsData.quantityType;
      values["taxExempt"] = leadsData?.taxExempt;
      values["jobType"] = leadsData?.jobType;
      values["jobNumber"] = leadsData?.jobNumber;
      values["salesPersonId"] = leadsData?.salesPersonId;
      values["salesCommission"] = leadsData?.salesCommission;
      values["jobName"] = leadsData?.jobName;
      values["companyName"] = leadsData?.companyName;
      values["companyId"] = leadsData?.companyId;
      values["companyAddress"] = leadsData?.companyAddress;
      values["companyPhone"] = leadsData?.companyPhone;
      values["material"] = [newMaterial];
      values["dropoffAddress"] = leadsData?.dropoffAddress;
      values["selectedMaterial"] = [];
      values["totalPrice"] = 0;
      values["pickupAddress"] = "";
      values["ticketVerification"] = "Open";
      values["ticketUploadPicture"] = ticketUploaded;
      values["jobId"] = selectedJob;
      values["materialId"] = leadsData?.material[0]?.id;
      values["createdBy"] = userDetails?.id;
      values["uploadDate"] = moment().format("L");
      values["uploadStatus"] = "Review";

      let orderSubmitData = {
        entityData: {
          material: [newMaterial],
        },
      };
      const { truckNumber, ...rest } = values;
      data["entityData"] = rest;
      if (ticketUploaded !== "" && selectedJob !== "") {
        this.props?.actionUpdateOrder(leadsData["orderId"], orderSubmitData);
        this.props?.actionFetchAddTickets(data);
        message.success("Ticket uploaded sucessfully");
      } else {
        notification["error"]({
          message: "Please upload ticket & Select Job, Before submit",
        });
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      console.log("catchError", error);
    }
  };

  ordersCount = (individualJobData, e) => {
    return (
      Object.values(individualJobData[e])?.filter(
        (val, ind, self) => self.findIndex((e) => e.mid === val.mid) === ind
      ) || []
    );
  };

  showRejected = () => {
    const { totalTicketsData } = this.state;
    const rejectedData = totalTicketsData?.filter(
      (e) => e.uploadStatus === "Open"
    );
    this.setState({
      rejectedData,
    });
  };

  ticketsPendingCount = (record) => {
    let closedJobs = record?.filter((item) => item?.uploadStatus === "Closed");
    let managerReview = record?.filter((item) => "ticketNotes" in item);
    return closedJobs.concat(managerReview);
  };

  removejobsList = (record) => {
    const { showJobData, selectedRowKeys } = this.state;
    const removeJob = showJobData?.filter((e) => e.id !== record.id);
    this.setState({
      showJobData: removeJob,
    });
    if (selectedRowKeys?.length > 0 && selectedRowKeys[0] === record.id) {
      this.setState({
        newTicket: false,
        selectedJob: "",
      });
    }
  };

  resetJobValues = () => {
    this.setState({
      showJobData: [],
      selectedRowKeys: [],
      selectedJob: "",
      newTicket: false,
      selectedMaterialId: "",
    });
  };

  handleRadio = (selectedRowKeys, selectedRows) => {
    const { assignedJobs } = this.state;
    const filterData = assignedJobs.filter(
      (job) =>
        String(job.originalJobId) === String(selectedRows[0]?.originalJobId)
    );
    this.showJobDetails(selectedRows[0], filterData);
    console.log(selectedRowKeys, 'selectedRowKeys');
    this.setState({
      selectedRowKeys,
    });
  };

  handleQuarryChange = (value) => {
    this.setState(
      {
        selectedVendorId: value,
        selectedMaterialId: null,
      },
      () => {
        this.resetJobValues();
      }
    );
    this.formRef.current.setFieldsValue({ materialId: null });
  };

  handleMaterialChange = (value, option) => {
    this.setState(
      {
        selectedMaterialId: value,
        jobTruckType: option?.newLable,
      },
      () => this.resetJobValues()
    );
  };

  filteredTrucks = (trucks, trucksResult) => {
    // Create a Set to track unique truck IDs
    const uniqueTruckIds = new Set(trucks.map((truck) => truck));
    // Filter trucksResult based on uniqueTruckIds
    return trucksResult.filter((job) => {
      if (!uniqueTruckIds.has(job.truckId)) {
        uniqueTruckIds.add(job.truckId);
        return true;
      }
      return false;
    });
  };

  findJobType = (value) => {
    const checkBoth = value?.find((e) => e?.othersData?.originalJobType);
    if (checkBoth) {
      return "Trucking & Material Job";
    }
    return value[0]?.jobType;
  };

  render() {
    const {
      minValue,
      maxValue,
      currentPage,
      perpageSize,
      selectedMaterialId,
      trucksResult,
      rejectedData,
      showSingleOrder,
      individualOrder,
      filterJobs,
      showSingleJob,
      newTicket,
      customerId,
      totalTicketsData,
      dateRestriction,
      jobperpageSize,
      minjobValue,
      maxjobValue,
      individualJobData,
      jobInfoData,
      showFinace,
      leadsData,
      isModalVisible,
      singlePdfTicket,
      ticketLoading,
      assignedCustomers,
      selectedJob,
      percentage,
      showApproved,
      showProcesssed,
      filterMaterial,
      suggestModal,
      suggestTrucks,
      suggestQuarry,
      suggestMaterial,
      showJobData,
      orderDetails,
      selectedRowKeys,
      selectedVendorId,
    } = this.state;
    const { loading } = this.props;
    let loginType = "";
    const { userRoleAccess } = this.props.context;
    const roleData = userRoleAccess?.otherData?.filter(
      (e) => e.title === "Finance"
    );
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.handleRadio(selectedRowKeys, selectedRows);
      },
    };

    const filteredMaterials = selectedVendorId
      ? suggestMaterial?.filter(
          (job) => job.material[0]?.vendorId === selectedVendorId
        )
      : suggestMaterial;

    const Controls = () => {
      const { resetTransform } = useControls();

      return (
        <div className="tools">
          {/* <span class="custom-button" onClick={() => zoomIn()}>
            Zoom In
          </span>
          <span class="custom-button1" onClick={() => zoomOut()}>
            Zoom Out
          </span> */}
          <span class="custom-button2" onClick={() => resetTransform()}>
            Reset
          </span>
        </div>
      );
    };

    const finaceInfo =
      roleData &&
      roleData[0]?.multiPannel?.filter((m) => m.title === "Ticket Processing");
    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />
        <Row data-aos="fade-up">
          {showFinace ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <TicketUploadProcessing
                jobInfo={jobInfoData}
                handleCancel={this.handleCancel}
                showApproved={showApproved}
                showProcesssed={showProcesssed}
                handleApproved={this.handleApproved}
                handlePending={this.handlePending}
                dateRestriction={dateRestriction}
                trucksResult={trucksResult}
                finaceInfo={finaceInfo}
              />
            </Col>
          ) : (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              {finaceInfo &&
              finaceInfo[0]?.selectedOptions?.includes("Edit") ? (
                <Row gutter={[12, 0]}>
                  <Col span={8}>
                    <Card
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                      }}
                      className="cards-top-layout"
                    >
                      <Col
                        xxl={{ span: 20 }}
                        xl={{ span: 20 }}
                        lg={{ span: 20 }}
                        md={{ span: 20 }}
                        sm={{ span: 20 }}
                        xs={{ span: 20 }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#747474",
                            fontWeight: "600",
                          }}
                          className="owner-operator-card-head"
                        >
                          Quailty Check
                        </span>
                      </Col>

                      <Col>
                        <p
                          style={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                          className="owner-operator-card-body"
                        >
                          {parseInt(
                            totalTicketsData.filter(
                              (e) => e.uploadStatus === "Review"
                            ).length
                          )}
                        </p>
                      </Col>
                    </Card>
                  </Col>

                  <Col span={8}>
                    <Card
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                      }}
                      className="cards-top-layout"
                    >
                      <Col
                        xxl={{ span: 20 }}
                        xl={{ span: 20 }}
                        lg={{ span: 20 }}
                        md={{ span: 20 }}
                        sm={{ span: 20 }}
                        xs={{ span: 20 }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#747474",
                            fontWeight: "600",
                          }}
                          className="show-on-desktop"
                        >
                          Reupload
                        </span>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#747474",
                            fontWeight: "600",
                          }}
                          className="show-on-mobile owner-operator-card-head"
                        >
                          Re - Upload
                        </span>
                      </Col>

                      <Col>
                        <p
                          style={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                          className="owner-operator-card-body"
                        >
                          {parseInt(
                            totalTicketsData.filter(
                              (e) => e.uploadStatus === "Open"
                            ).length
                          )}
                        </p>
                      </Col>
                    </Card>
                  </Col>

                  <Col span={8}>
                    <Card
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                      }}
                      className="cards-top-layout"
                    >
                      <Col
                        xxl={{ span: 20 }}
                        xl={{ span: 20 }}
                        lg={{ span: 20 }}
                        md={{ span: 20 }}
                        sm={{ span: 20 }}
                        xs={{ span: 20 }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#747474",
                            fontWeight: "600",
                          }}
                          className="owner-operator-card-head"
                        >
                          Closed
                        </span>
                      </Col>

                      <Col>
                        <p
                          style={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                          className="owner-operator-card-body"
                        >
                          {parseInt(
                            totalTicketsData.filter(
                              (e) => e.uploadStatus === "Closed"
                            ).length
                          )}
                        </p>
                      </Col>
                    </Card>
                  </Col>

                  {/* <Col span={6}>
                    <Card
                      style={{
                        alignItems: "left",
                        textAlign: "left",
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                        height: "100px",
                      }}
                    >
                      <Col
                        xxl={{ span: 20 }}
                        xl={{ span: 20 }}
                        lg={{ span: 20 }}
                        md={{ span: 20 }}
                        sm={{ span: 20 }}
                        xs={{ span: 20 }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#747474",
                            fontWeight: "600",
                          }}
                        >
                          Invoiced
                        </span>
                      </Col>

                      <Col>
                        <p
                          style={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                        >
                          {parseInt(
                            totalTicketsData.filter(
                              (e) => e.ticketVerification === "Invoiced"
                            ).length
                          )}
                        </p>
                      </Col>
                    </Card>
                  </Col> */}
                </Row>
              ) : (
                ""
              )}

              <br />

              {finaceInfo && finaceInfo.length > 0 && (
                <Row style={{ marginBottom: "1vw" }}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 8 }}
                    xl={{ span: 6 }}
                    xxl={{ span: 6 }}
                    className="quarrytoolgrid"
                  >
                    <SearchFunction
                      tableName={"ticketProcess"}
                      getSearchData={this.searchResult}
                      onResetData={this.onResetData}
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 7, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    xxl={{ span: 5, offset: 1 }}
                    className="quarrytoolgrid"
                  ></Col>
                  {/* <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 7, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    xxl={{ span: 5, offset: 1 }}
                    className="quarrytoolgrid"
                  >
                    <Button
                      type="primary"
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      onClick={this.showRejected}
                    >
                      <span style={{ fontWeight: "500", fontSize: "16px" }}>
                        {"Manager Review (" +
                          totalTicketsData?.filter(
                            (e) => e.uploadStatus === "Open"
                          )?.length +
                          ")"}
                      </span>
                    </Button>
                  </Col> */}

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Row className="upload-ticket">
                      {finaceInfo &&
                      finaceInfo[0]?.selectedOptions?.includes("Edit") ? (
                        <>
                          {showSingleJob && !showSingleOrder && (
                            <Col className="gridbtntool">
                              <Switch
                                className="ticketUpload_switch"
                                defaultChecked={false}
                                checkedChildren={"Show All"}
                                unCheckedChildren={"Last 3 Weeks"}
                                onChange={(e) => {
                                  this.setState(
                                    {
                                      jobInfoData: {},
                                    },
                                    () =>
                                      this.showTIcketInfo(
                                        this.props?.totalResult?.ticketsData,
                                        e
                                      )
                                  );
                                }}
                                style={{ height: "30px" }}
                              />
                            </Col>
                          )}
                          <Col className="gridbtntool">
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              style={{
                                borderRadius: "5px",
                                height: "40px",
                              }}
                              onClick={this.showSuggestModal}
                            >
                              <span
                                style={{ fontWeight: "500", fontSize: "16px" }}
                              >
                                Suggest Job
                              </span>
                            </Button>
                          </Col>
                          <Col className="gridbtntool">
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              style={{
                                borderRadius: "5px",
                                height: "40px",
                              }}
                              onClick={this.showModal}
                            >
                              <span
                                style={{ fontWeight: "500", fontSize: "16px" }}
                              >
                                Upload Ticket
                              </span>
                            </Button>
                          </Col>
                        </>
                      ) : finaceInfo[0]?.selectedOptions?.includes("Review") ? (
                        <Col className="gridbtntool">
                          <Button
                            className="hover_style"
                            style={{
                              borderRadius: "5px",
                              height: "40px",
                              border: "none",
                            }}
                            onClick={() =>
                              this.handleApproved(this.state.showApproved)
                            }
                          >
                            <CheckOutlined
                              style={{
                                color: "#586370",
                                fontSize: "17px",
                                fontWeight: "500",
                              }}
                            />
                            <span
                              style={{
                                color: "#586370",
                                fontSize: "17px",
                                fontWeight: "500",
                              }}
                            >
                              {this.state.showApproved
                                ? "Show Approved"
                                : "Hide Approved"}
                            </span>
                          </Button>
                        </Col>
                      ) : finaceInfo[0]?.selectedOptions?.includes(
                          "Data Input"
                        ) ? (
                        <Col className="gridbtntool">
                          <Button
                            className="hover_style"
                            style={{
                              borderRadius: "5px",
                              height: "40px",
                              border: "none",
                            }}
                            onClick={() =>
                              this.handlePending(this.state.showProcesssed)
                            }
                          >
                            <CheckOutlined
                              style={{
                                color: "#586370",
                                fontSize: "17px",
                                fontWeight: "500",
                              }}
                            />
                            <span
                              style={{
                                color: "#586370",
                                fontSize: "17px",
                                fontWeight: "500",
                              }}
                            >
                              {this.state.showProcesssed
                                ? "Show Processsed"
                                : "Hide Processsed"}
                            </span>
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Col>
                </Row>
              )}

              {rejectedData?.length > 0 ? (
                <RejectUpload
                  jobInfo={rejectedData}
                  handleCancel={() =>
                    this.setState({
                      rejectedData: [],
                    })
                  }
                  handleApproved={this.handleApproved}
                  handlePending={this.handlePending}
                />
              ) : showSingleOrder ? (
                Object.keys(individualOrder)?.length > 0 ? (
                  <div className="dispatchertool-grpgrid">
                    {showSingleOrder && (
                      <Row
                        onClick={() =>
                          this.setState({
                            showSingleOrder: false,
                            customerId: undefined,
                          })
                        }
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        <BsArrowLeftShort
                          style={{ color: "#5f6c74", fontSize: "23px" }}
                          className="back-button-return-icon"
                        />
                        <p
                          style={{
                            color: "#808080",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                          className="back-button-return"
                        >
                          Return
                        </p>
                      </Row>
                    )}

                    {Object.keys(individualOrder)
                      .slice(minjobValue, maxjobValue)
                      ?.map((e, i) => (
                        <div className="dispatcher-contentgrid">
                          <Card
                            key={e}
                            bodyStyle={{ padding: "0px" }}
                            style={{
                              borderRadius: "5px",
                              height: "220px",
                            }}
                            hoverable
                            className={
                              parseInt(
                                parseInt(
                                  Object.values(individualOrder[e])?.filter(
                                    (t) => t?.uploadStatus === "Closed"
                                  )?.length * 100
                                ) / Object.values(individualOrder[e])?.length
                              ) === 100
                                ? "dispatcherCard green-dispatchertoolgrp"
                                : parseInt(
                                    parseInt(
                                      Object.values(individualOrder[e])?.filter(
                                        (t) => t?.uploadStatus === "Closed"
                                      )?.length * 100
                                    ) /
                                      Object.values(individualOrder[e])?.length
                                  ) !== 0
                                ? "dispatcherCard red-dispatchertoolgrp"
                                : "dispatcherCard bor-dispatchertoolgrp"
                            }
                            onDoubleClick={() =>
                              this.handleSingleOrder(
                                e,
                                Object.values(individualOrder[e])
                              )
                            }
                          >
                            <div
                              className="bor-dispatchertool"
                              style={{
                                padding: "10px",
                                height: "218px",
                                borderRadius: "15px",
                                backgroundColor:
                                  parseInt(
                                    parseInt(
                                      Object.values(individualOrder[e])?.filter(
                                        (t) => t?.uploadStatus === "Closed"
                                      )?.length * 100
                                    ) /
                                      Object.values(individualOrder[e])?.length
                                  ) === 100
                                    ? "#00800417"
                                    : parseInt(
                                        parseInt(
                                          Object.values(
                                            individualOrder[e]
                                          )?.filter(
                                            (t) => t?.uploadStatus === "Closed"
                                          )?.length * 100
                                        ) /
                                          Object.values(individualOrder[e])
                                            ?.length
                                      ) !== 0
                                    ? "#ff090924"
                                    : "#8080802b",
                              }}
                            >
                              <p
                                className="ticket-upload-card"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                {
                                  Object.values(individualOrder[e])[0]
                                    ?.companyName
                                }
                              </p>
                              <p>
                                {Object.values(individualOrder[e])[0]?.jobType}
                              </p>
                              <p>
                                {"#" +
                                  Object.values(individualOrder[e])[0]
                                    ?.jobNumber}{" "}
                              </p>
                              <p>
                                {" "}
                                {Object.values(individualOrder[e])[0]?.jobName}
                              </p>
                              <p>
                                {
                                  Object.values(
                                    individualOrder[e]
                                  )[0]?.material[0]?.label?.split(" - ")[1]
                                }
                              </p>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column-reverse",
                                  width: "100%",
                                }}
                              >
                                <p>
                                  Tickets :{" "}
                                  {
                                    this.ticketsPendingCount(
                                      Object.values(individualOrder[e])
                                    )?.length
                                  }
                                  /{Object.values(individualOrder[e])?.length}
                                </p>

                                <Progress
                                  percent={(
                                    (this.ticketsPendingCount(
                                      Object.values(individualOrder[e])
                                    )?.length *
                                      100) /
                                    Object.values(individualOrder[e])?.length
                                  )?.toFixed(e)}
                                  size="small"
                                  strokeColor="#00B894"
                                />
                              </div>
                            </div>
                          </Card>
                        </div>
                      ))}
                    <div
                      className="disp_fixed show-on-desktop"
                      style={{ height: "50px", marginBottom: "20px" }}
                    >
                      <Pagination
                        style={{ float: "right", marginTop: "1vw" }}
                        total={Object.keys(individualOrder).length}
                        showTotal={(total) => `${total} items`}
                        pageSizeOptions={[
                          10,
                          25,
                          50,
                          100,
                          `${Object.keys(individualOrder)?.length}`,
                        ]}
                        current={currentPage}
                        pageSize={jobperpageSize}
                        onChange={this.handlePagination}
                        showSizeChanger={true}
                      />
                    </div>
                  </div>
                ) : (
                  <Nodata />
                )
              ) : showSingleJob ? (
                Object.keys(individualJobData)?.length > 0 ? (
                  <div className="dispatchertool-grpgrid">
                    {showFinace && (
                      <Row
                        onClick={this.onClickReturn}
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        <BsArrowLeftShort
                          style={{ color: "#5f6c74", fontSize: "23px" }}
                          className="back-button-return-icon"
                        />
                        <p
                          style={{
                            color: "#808080",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                          className="back-button-return"
                        >
                          Return
                        </p>
                      </Row>
                    )}
                    {Object.keys(individualJobData)
                      .slice(minValue, maxValue)
                      ?.map((e, i) => (
                        <div className="dispatcher-contentgrid">
                          <Card
                            key={e}
                            bodyStyle={{ padding: "0px" }}
                            style={{
                              borderRadius: "5px",
                              height: "220px",
                            }}
                            hoverable
                            className={
                              parseInt(
                                parseInt(
                                  this.ticketsPendingCount(
                                    Object.values(individualJobData[e])
                                  )?.length * 100
                                ) / Object.values(individualJobData[e])?.length
                              ) === 100
                                ? "dispatcherCard green-dispatchertoolgrp"
                                : parseInt(
                                    (this.ticketsPendingCount(
                                      Object.values(individualJobData[e])
                                    )?.length *
                                      100) /
                                      Object.values(individualJobData[e])
                                        ?.length
                                  ) !== 0
                                ? "dispatcherCard red-dispatchertoolgrp"
                                : "dispatcherCard bor-dispatchertoolgrp"
                            }
                            onDoubleClick={() => {
                              this.handleClickCard(
                                e,
                                Object.values(individualJobData[e])
                              );
                            }}
                          >
                            <div
                              className="bor-dispatchertool"
                              style={{
                                padding: "10px",
                                height: "218px",
                                borderRadius: "15px",
                                backgroundColor:
                                  parseInt(
                                    parseInt(
                                      this.ticketsPendingCount(
                                        Object.values(individualJobData[e])
                                      )?.length * 100
                                    ) /
                                      Object.values(individualJobData[e])
                                        ?.length
                                  ) === 100
                                    ? "#00800417"
                                    : parseInt(
                                        parseInt(
                                          this.ticketsPendingCount(
                                            Object.values(individualJobData[e])
                                          )?.length * 100
                                        ) /
                                          Object.values(individualJobData[e])
                                            ?.length
                                      ) !== 0
                                    ? "#ff090924"
                                    : "#8080802b",
                              }}
                            >
                              <p
                                className="ticket-upload-card"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                {
                                  Object.values(individualJobData[e])[0]
                                    ?.companyName
                                }
                              </p>
                              <p>
                                {this.findJobType(
                                  Object.values(individualJobData[e])
                                )}
                              </p>
                              <p>
                                {"#" +
                                  Object.values(individualJobData[e])[0]
                                    ?.jobNumber}{" "}
                              </p>
                              <p>
                                {" "}
                                {
                                  Object.values(individualJobData[e])[0]
                                    ?.jobName
                                }
                              </p>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column-reverse",
                                  width: "100%",
                                }}
                              >
                                <p>
                                  Materials :{" "}
                                  {
                                    this.ordersCount(individualJobData, e)
                                      ?.length
                                  }
                                </p>

                                <Progress
                                  percent={(
                                    (this.ticketsPendingCount(
                                      Object.values(individualJobData[e])
                                    )?.length *
                                      100) /
                                    Object.values(individualJobData[e])?.length
                                  )?.toFixed(e)}
                                  size="small"
                                  strokeColor="#00B894"
                                />
                              </div>
                            </div>
                          </Card>
                        </div>
                      ))}
                    <div
                      className="disp_fixed show-on-desktop"
                      style={{ height: "50px", marginBottom: "20px" }}
                    >
                      <Pagination
                        style={{ float: "right", marginTop: "1vw" }}
                        total={Object.keys(individualJobData).length}
                        showTotal={(total) => `${total} items`}
                        pageSizeOptions={[
                          10,
                          25,
                          50,
                          100,
                          `${Object.keys(individualJobData)?.length}`,
                        ]}
                        current={currentPage}
                        pageSize={perpageSize}
                        onChange={this.handlePagination}
                        showSizeChanger={true}
                      />
                    </div>
                  </div>
                ) : (
                  <Nodata />
                )
              ) : (
                ""
              )}
            </Col>
          )}
        </Row>

        {isModalVisible && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={"Upload Ticket"}
            onCancel={() =>
              this.setState({
                isModalVisible: false,
                ticketUploaded: "",
                percentage: 0,
                customerId: undefined,
                orderDetails: [],
              })
            }
            formId="ticketForm"
            loading={loading}
            submitButton={"Upload"}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "850px"
            }
            // btnWidth="150px"
            btnHeight="40px"
            disabled={ticketLoading ? true : false}
          >
            <Form
              id="ticketForm"
              onFinish={(e) => this.onFinish(e)}
              ref={this.formRef}
            >
              {!newTicket && singlePdfTicket !== "" && (
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <TransformWrapper
                    initialScale={1}
                    initialPositionX={1}
                    initialPositionY={1}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <Controls />
                        <TransformComponent>
                          <PDFViewer
                            url={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${singlePdfTicket}?#view=FitH`}
                            viewer="url"
                            style={{
                              width: "100%",
                              height: "400px",
                              overflow: "auto",
                            }}
                            onError={(error) => {
                              console.error("Error loading PDF:", error);
                            }}
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </Col>
              )}

              <Row gutter={[24, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "1vw" }}
                >
                  <span className="title_changes">Customer</span>
                  <Form.Item name="selectCustomer" initialValue={customerId}>
                    <Select
                      className="select_Height"
                      showSearch
                      style={{
                        fontWeight: "600",
                        height: "40px",
                        width: "100%",
                      }}
                      placeholder="Search to Select Customer"
                      allowClear
                      onChange={(value) => {
                        this.setState({
                          customerId: value,
                          leadsData: {},
                          selectedJob: "",
                          assignedvendors: [],
                          assignedMaterials: [],
                        });
                        this.formRef.current.setFieldsValue({
                          selectJob: [],
                          selectVendor: [],
                          selectMaterial: [],
                          selectOrder: []
                        });
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        option.key.indexOf(input) > -1
                      }
                      showArrow={false}
                    >
                      {assignedCustomers?.map((job) => (
                        <Option
                          key={job.companyId}
                          value={job.companyId}
                          className="option_style"
                          label={job.companyName}
                        >
                          <span>{job.companyName}</span>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "1vw" }}
                >
                  <span className="title_changes">Select Job #</span>
                  <Form.Item
                    name="selectJob"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Job!",
                      },
                    ]}
                  >
                    <Select
                      className="select_Height"
                      showSearch
                      style={{
                        fontWeight: "600",
                        height: "40px",
                        width: "100%",
                      }}
                      placeholder="Search to Select Job"
                      allowClear
                      onChange={this.onSelectJob}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.key.indexOf(input) > -1
                      }
                      showArrow={false}
                      defaultValue={leadsData?.jobNumber}
                    >
                      {customerId !== "" && customerId !== undefined
                        ? filterJobs?.map(
                            (job) =>
                              job?.companyId === customerId && (
                                <Option
                                  key={job?.jobNumber}
                                  value={job?.originalJobId}
                                  label={job?.jobNumber}
                                  className="option_style"
                                >
                                  <span>
                                    {" #" +
                                      job?.jobNumber +
                                      " - " +
                                      job?.jobName}
                                  </span>
                                </Option>
                              )
                          )
                        : filterJobs?.map((job) => (
                            <Option
                              key={job.jobNumber}
                              value={job.originalJobId}
                              label={job.jobNumber}
                              className="option_style"
                            >
                              <span>
                                {" #" + job.jobNumber + " - " + job.jobName}
                              </span>
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Order Name</span>
                  <Form.Item
                    name="selectOrder"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Order!",
                      },
                    ]}
                  >
                    <Select
                      className="select_Height"
                      showSearch
                      style={{
                        fontWeight: "600",
                        height: "40px",
                        width: "100%",
                      }}
                      placeholder="Search to Select Order"
                      allowClear
                      // onChange={this.onSelectJob}
                      onChange={(val, option) =>
                        this.showJobDetails(option?.label, orderDetails)
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.key.indexOf(input) > -1
                      }
                      showArrow={false}
                    >
                      {filterMaterial?.map((job) =>
                        job.jobType === "Trucking Job" ? (
                          <Option
                            key={job?.orderNumber}
                            value={job?.id}
                            label={job}
                            className="option_style"
                          >
                            <span>{job?.material[0]?.materialName}</span>
                          </Option>
                        ) : (
                          <Option
                            key={job?.orderNumber}
                            value={job?.id}
                            label={job}
                            className="option_style"
                          >
                            <span>
                              {job?.material[0]?.vendor +
                                " - " +
                                job?.material[0]?.label?.split(" - ")[1]}
                            </span>
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Assign Truck#</span>
                  <Form.Item
                    name="selectMaterial"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Assign Truck!",
                      },
                    ]}
                  >
                    <Select
                      // mode="multiple"
                      className="select_multiplequarytol"
                      style={{
                        fontWeight: "600",
                        width: "100%",
                      }}
                      placeholder="Search to Select Trucks"
                      allowClear
                      onChange={(value, option) => {
                        this.handleAssignChange(value, option);
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const inputValue = input.toLowerCase();
                        const truckLabel = option.label?.toLowerCase() || "";
                        const truckLabel2 = option.label2?.toLowerCase() || "";
                        const truckValue = String(option.value);

                        return (
                          truckLabel.startsWith(inputValue) ||
                          truckLabel2.includes(inputValue) ||
                          truckValue.includes(inputValue)
                        );
                      }}
                    >
                      {Object.keys(leadsData)?.length > 0 &&
                        // this.filteredTrucks(
                        //   leadsData?.material[0]?.assignTrucks || [],
                        //   trucksResult
                        // )
                        trucksResult?.map((job, i) => (
                          <Option
                            key={i}
                            value={job?.truckId}
                            className="option_style"
                            label={job?.carrier_name}
                            label2={job?.truck_type}
                            assignTrucksInfo={job?.assignTrucksInfo}
                          >
                            <span>
                              {"TNB " +
                                job?.truckId +
                                " - " +
                                job?.carrier_name +
                                " - " +
                                job?.truck_type}
                            </span>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {selectedJob !== "" && (
                <div style={{ marginTop: "10px" }}>
                  <span className="title_changes">Job Details</span>

                  <ul>
                    <li>
                      <Row>
                        <Col
                          xxl={{ span: 5 }}
                          xl={{ span: 5 }}
                          lg={{ span: 5 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Job#:</b>
                        </Col>
                        <Col span={12}>
                          {loginType !== "open" && loginType !== "review" ? (
                            <a
                              href={`/app/jobs/job-profile/${leadsData?.jobId}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <div style={{ textDecoration: "underLine" }}>
                                {leadsData?.jobNumber}
                              </div>
                            </a>
                          ) : (
                            <div>{leadsData?.jobNumber}</div>
                          )}
                        </Col>
                      </Row>
                    </li>

                    <li>
                      <Row>
                        <Col
                          xxl={{ span: 5 }}
                          xl={{ span: 5 }}
                          lg={{ span: 5 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Job Name:</b>{" "}
                        </Col>

                        <Col span={12}>
                          <div>{leadsData?.jobName}</div>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col
                          xxl={{ span: 5 }}
                          xl={{ span: 5 }}
                          lg={{ span: 5 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Customer:</b>
                        </Col>
                        <Col span={12}>
                          <div>{leadsData?.companyName}</div>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col
                          xxl={{ span: 5 }}
                          xl={{ span: 5 }}
                          lg={{ span: 5 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Pickup:</b>
                        </Col>
                        <Col span={12}>
                          <div>
                            {leadsData.material?.map((e) => e.pickUpAddress)}
                          </div>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col
                          xxl={{ span: 5 }}
                          xl={{ span: 5 }}
                          lg={{ span: 5 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Drop Off:</b>
                        </Col>
                        <Col span={14}>
                          <div>{leadsData?.dropoffAddress}</div>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col
                          xxl={{ span: 5 }}
                          xl={{ span: 5 }}
                          lg={{ span: 5 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "0.5vw" }}
                        >
                          <b style={{ marginBottom: "10px" }}>Trucking Rate:</b>
                        </Col>
                        <Col span={12}>
                          <div>
                            {`$ ${parseFloat(
                              leadsData?.material[0]?.truckingPrice
                            )?.toLocaleString("us-EN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}  per ${leadsData?.material[0]?.truckingPriceType.toLowerCase()}`}
                          </div>
                        </Col>
                      </Row>
                    </li>
                    {leadsData?.jobType === "Trucking & Material Job" ? (
                      <>
                        <li>
                          <Row>
                            <Col
                              xxl={{ span: 5 }}
                              xl={{ span: 5 }}
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                              style={{ marginBottom: "0.5vw" }}
                            >
                              <b style={{ marginBottom: "10px" }}>
                                {" "}
                                Material Rate+Tax:
                              </b>
                            </Col>
                            <Col span={12}>
                              <div>
                                {`$ ${parseFloat(
                                  leadsData?.material[0]?.totalPrice
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })} per ${leadsData?.material[0]?.totalPriceType.toLowerCase()}`}
                              </div>
                            </Col>
                          </Row>
                        </li>
                        <li>
                          <Row>
                            <Col
                              xxl={{ span: 5 }}
                              xl={{ span: 5 }}
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                              style={{ marginBottom: "0.5vw" }}
                            >
                              <b style={{ marginBottom: "10px" }}>
                                {" "}
                                Landed Rate:
                              </b>
                            </Col>
                            <Col span={12}>
                              <div>
                                {leadsData?.material[0]?.landedPrice === "N/A"
                                  ? leadsData?.material[0]?.landedPrice
                                  : `$ ${parseFloat(
                                      leadsData?.material[0]?.landedPrice
                                    )?.toLocaleString("us-En", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })} per ${
                                      leadsData?.material[0]?.landedPriceType.toLowerCase() ||
                                      leadsData?.material[0]?.totalPriceType.toLowerCase()
                                    }`}
                              </div>
                            </Col>
                          </Row>
                        </li>

                        <li>
                          <Row>
                            <Col span={5} style={{ marginBottom: "0.5vw" }}>
                              <b style={{ marginBottom: "10px" }}> Material:</b>
                            </Col>
                            <Col span={19}>
                              <div>
                                {leadsData?.material?.map(
                                  (e) => e.category + " - " + e.label
                                )}
                              </div>
                            </Col>
                          </Row>
                        </li>

                        <li>
                          <Row>
                            <Col span={5} style={{ marginBottom: "0.5vw" }}>
                              <b style={{ marginBottom: "10px" }}>
                                {" "}
                                Vendor Name:
                              </b>
                            </Col>
                            <Col span={12}>
                              <div>
                                {leadsData?.material?.map((e) => e.vendor)}
                              </div>
                            </Col>
                          </Row>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    <li>
                      <Row>
                        <Col span={5} style={{ marginBottom: "0.5vw" }}>
                          <b style={{ marginBottom: "10px" }}>
                            {" "}
                            Assigned Trucks#:
                          </b>
                        </Col>
                        <Col span={12}>
                          <div>
                            {leadsData?.material?.[0]?.assignTrucks?.join(", ")}
                          </div>
                        </Col>
                      </Row>
                    </li>
                    {(leadsData?.material[0].truckingCostType === "Hour" ||
                        leadsData?.material[0].truckingPriceType ===
                          "Hour") && (
                        <li>
                          <Row>
                            <Col
                              xxl={{ span: 5 }}
                              xl={{ span: 5 }}
                              lg={{ span: 5 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}
                              style={{ marginBottom: "0.5vw" }}
                            >
                              <b style={{ marginBottom: "10px" }}>
                                Minimum Hours:
                              </b>
                            </Col>
                            <Col span={12}>
                              <div>{`${leadsData?.material[0]?.hourlyCharge} Hours`}</div>
                            </Col>
                          </Row>
                        </li>
                      )}
                  </ul>
                  {/* {leadsData?.assignTrucks?.length === 1 && (
                    <Col span={12}>
                      <span className="title_changes">
                        <b style={{ marginBottom: "10px" }}>Truck Number</b>
                        <div>
                          {Array.isArray(leadsData?.assignTrucks) &&
                            leadsData?.assignTrucks[0]}
                        </div>
                      </span>
                    </Col>
                  )} */}
                </div>
              )}
            </Form>

            {newTicket && (
              <Row>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Dragger
                    onChange={this.uploadTicketPdf}
                    customRequest={dummyRequest}
                    listType="picture"
                    multiple={false}
                    className="drag-ticket"
                    beforeUpload={this.handleUploadType}
                    maxCount={1}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-hint">
                      Click or drag file to this area to upload
                    </p>
                  </Dragger>
                  {console.log(percentage, 'percentage')}
                  {percentage > 1 && (
                    <Progress
                      percent={percentage}
                      status={"active"}
                      strokeColor={percentage === 100 && "green"}
                    />
                  )}
                </Col>
              </Row>
            )}
          </BaseModal>
        )}

        {suggestModal && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={"Upload Ticket"}
            onCancel={this.onCancelSuggestModal}
            formId="ticketForm"
            loading={loading}
            submitButton={showJobData?.length > 0 ? "Upload" : "Find Job"}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "65%"
            }
            btnHeight="40px"
            disabled={ticketLoading ? true : false}
          >
            <Form
              id="ticketForm"
              onFinish={(e) =>
                selectedJob !== "" ? this.onFinish(e) : this.handleFinish(e)
              }
              ref={this.formRef}
            >
              <Row gutter={[24, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Date</span>
                  <Form.Item
                    name="dispatchDate"
                    rules={[
                      {
                        required: false,
                        message: "Please Select Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Please Select A Date"
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      format={"MM/DD/YYYY"}
                      placement={"bottomRight"}
                      allowClear
                      bordered={true}
                      onChange={this.resetJobValues}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Truck Number</span>
                  <Form.Item
                    name="truckNumber"
                    rules={[
                      {
                        required: false,
                        message: "Please Select Truck#!",
                      },
                    ]}
                  >
                    <Select
                      className="select_multiplequarytol"
                      style={{
                        fontWeight: "600",
                        width: "100%",
                      }}
                      placeholder="Search to Select Truck#"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const inputValue = input.toLowerCase();
                        const truckLabel = option.label?.toLowerCase() || "";
                        const truckLabel2 = option.label2?.toLowerCase() || "";
                        const truckValue = String(option.value);

                        return (
                          truckLabel.startsWith(inputValue) ||
                          truckLabel2.includes(inputValue) ||
                          truckValue.includes(inputValue)
                        );
                      }}
                      onChange={this.resetJobValues}
                    >
                      {suggestTrucks?.map((job, i) => (
                        <Option
                          key={i}
                          value={job?.truckId}
                          className="option_style"
                          label={job?.carrier_name}
                          label2={job?.truck_type}
                          assignTrucksInfo={job?.assignTrucksInfo}
                        >
                          <span>
                            {"TNB " +
                              job?.truckId +
                              " - " +
                              job?.carrier_name +
                              " - " +
                              job?.truck_type}
                          </span>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Quarry</span>
                  <Form.Item
                    name="vendorId"
                    rules={[
                      {
                        required: false,
                        message: "Please Select quarry!",
                      },
                    ]}
                  >
                    <Select
                      className="select_Height"
                      showSearch
                      style={{
                        fontWeight: "600",
                        height: "40px",
                        width: "100%",
                      }}
                      placeholder="Search to select quarry"
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const inputValue = input.toLowerCase();
                        const truckLabel = option.label?.toLowerCase() || "";
                        const truckLabel2 = option.label2?.toLowerCase() || "";

                        return (
                          truckLabel.startsWith(inputValue) ||
                          truckLabel2.startsWith(inputValue)
                        );
                      }}
                      onChange={this.handleQuarryChange}
                      showArrow={false}
                    >
                      {suggestQuarry?.map(
                        (job) =>
                          job.jobType !== "Trucking Job" && (
                            <Option
                              key={job?.orderNumber}
                              value={job?.material[0]?.vendorId}
                              label={job?.material[0]?.vendor}
                              className="option_style"
                            >
                              <span>{job?.material[0]?.vendor}</span>
                            </Option>
                          )
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Material</span>
                  <Form.Item
                    name="materialId"
                    rules={[
                      {
                        required: false,
                        message: "Please Select material!",
                      },
                    ]}
                  >
                    <Select
                      className="select_Height"
                      showSearch
                      style={{
                        fontWeight: "600",
                        height: "40px",
                        width: "100%",
                      }}
                      placeholder="Search to select material"
                      allowClear
                      value={selectedMaterialId}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const inputValue = input.toLowerCase();
                        const truckLabel = option.label?.toLowerCase() || "";
                        const truckLabel2 = option.label2?.toLowerCase() || "";

                        return (
                          truckLabel.startsWith(inputValue) ||
                          truckLabel2.startsWith(inputValue)
                        );
                      }}
                      onChange={this.handleMaterialChange}
                      showArrow={false}
                    >
                      {filteredMaterials?.map((job) =>
                        job.jobType === "Trucking Job" ? (
                          <Option
                            key={job?.orderNumber}
                            value={job?.material[0]?.id}
                            label={job?.material[0]?.materialName}
                            newLable={job?.jobType}
                            className="option_style"
                          >
                            <span>{job?.material[0]?.materialName}</span>
                          </Option>
                        ) : (
                          <Option
                            key={job?.orderNumber}
                            value={job?.material[0]?.materialId}
                            label={job?.material[0]?.value}
                            className="option_style"
                            newLable={job?.jobType}
                          >
                            <span>{job?.material[0]?.value}</span>
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                {showJobData?.length > 0 && (
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ marginBottom: "1vw" }}
                  >
                    <Table
                      dataSource={showJobData}
                      total={showJobData?.length}
                      className="table_laptopscreen"
                      rowSelection={{
                        type: "radio",
                        ...rowSelection,
                      }}
                      sizeRange={showJobData?.length}
                      pagination={false}
                      rowKey={(record) => record.id}
                    >
                      <Column
                        title={"JOB#"}
                        dataIndex={"jobNumber"}
                        key={"jobNumber"}
                        render={(jobNumber, record) => (
                          <span
                            className="table-font-mobile table_span"
                            onClick={() =>
                              this.handleRadio([record?.id], [record])
                            }
                          >
                            {jobNumber}
                          </span>
                        )}
                      />
                      {/* <Column
                        title={"Order #"}
                        dataIndex={"orderNumber"}
                        key={"orderNumber"}
                        render={(orderNumber, record) => (
                          <span
                            className="table-font-mobile table_span"
                            onClick={() =>
                              this.handleRadio([record?.id], [record])
                            }
                          >
                            {orderNumber}
                          </span>
                        )}
                      /> */}
                      <Column
                        title={"JOB NAME"}
                        key={"jobName"}
                        render={(_, record) => (
                          <span
                            className="table-font-mobile table_span"
                            onClick={() =>
                              this.handleRadio([record?.id], [record])
                            }
                          >
                            {record?.jobName ? (
                              record?.jobName?.length > 10 ? (
                                <Popover
                                  placement="left"
                                  content={<Row>{record?.jobName}</Row>}
                                >
                                  {`${record?.jobName.slice(0, 10)}...`}
                                </Popover>
                              ) : (
                                record?.jobName
                              )
                            ) : (
                              record?.jobNumber
                            )}
                          </span>
                        )}
                      />
                      <Column
                        title={"Vendor"}
                        key={"vendorName"}
                        render={(_, record) => (
                          <span
                            className="table-font-mobile table_span"
                            onClick={() =>
                              this.handleRadio([record?.id], [record])
                            }
                          >
                            {record.jobType !== "Trucking Job" &&
                              record?.material?.map((e) => e.vendor)}
                          </span>
                        )}
                      />
                      <Column
                        title={"Material"}
                        key={"Material"}
                        render={(_, record) => (
                          <span
                            className="table-font-mobile table_span"
                            onClick={() =>
                              this.handleRadio([record?.id], [record])
                            }
                          >
                            {record.jobType === "Trucking Job" ? (
                              <div>{record?.material[0]?.materialName}</div>
                            ) : record.jobType === "Trucking & Material Job" ? (
                              <>
                                <div>{record?.material[0]?.category}</div>
                                <div>
                                  {record?.material[0]?.value?.split(" - ")[2]}
                                </div>
                              </>
                            ) : (
                              <>
                                <div>{record?.material[0]?.category}</div>
                                <div>
                                  {record?.material[0]?.value?.split(" - ")[2]}
                                </div>
                              </>
                            )}
                          </span>
                        )}
                      />
                      <ColumnGroup title="Rate Information">
                        <Column
                          title={"Trucking"}
                          key={"Trucking"}
                          render={(_, record) => (
                            <span
                              className="table-font-mobile"
                              style={{
                                color: "#474747",
                                fontSize: "16px",
                                fontFamily: "sans-serif",
                              }}
                              onClick={() =>
                                this.handleRadio([record?.id], [record])
                              }
                            >
                              {`$ ${parseFloat(
                                record?.material[0]?.truckingPrice
                              )?.toLocaleString("us-EN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })} per ${record?.material[0]?.truckingPriceType.toLowerCase()}`}
                            </span>
                          )}
                        />
                        <Column
                          title={"Material"}
                          key={"materialRate"}
                          render={(_, record) => (
                            <span
                              className="table-font-mobile"
                              style={{
                                color: "#474747",
                                fontSize: "16px",
                                fontFamily: "sans-serif",
                              }}
                              onClick={() =>
                                this.handleRadio([record?.id], [record])
                              }
                            >
                              {record.jobType !== "Trucking Job" &&
                                `$ ${parseFloat(
                                  record?.material[0]?.totalPrice
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })} per ${record?.material[0]?.totalPriceType.toLowerCase()}`}
                            </span>
                          )}
                        />
                        <Column
                          title={"Landed"}
                          key={"Landed"}
                          render={(_, record) => (
                            <span
                              onClick={() =>
                                this.handleRadio([record?.id], [record])
                              }
                              style={{
                                color: "#474747",
                                fontSize: "16px",
                                fontFamily: "sans-serif",
                              }}
                              className="table-font-mobile"
                            >
                              {record.jobType !== "Trucking Job"
                                ? record?.material[0]?.landedPrice === "N/A"
                                  ? record?.material[0]?.landedPrice
                                  : `$ ${parseFloat(
                                      record?.material[0]?.landedPrice
                                    )?.toLocaleString("us-EN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })} per ${
                                      record?.material[0]?.landedPriceType?.toLowerCase() ||
                                      record?.material[0]?.totalPriceType?.toLowerCase()
                                    }`
                                : ""}
                            </span>
                          )}
                        />
                      </ColumnGroup>
                      <Column
                        title={""}
                        key={"removeJob"}
                        render={(_, record) => (
                          <Row
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "fit-content",
                            }}
                            onClick={() => this.removejobsList(record)}
                          >
                            <CloseOutlined style={{ color: "red" }} />
                          </Row>
                        )}
                      />
                    </Table>
                  </Col>
                )}
                {selectedRowKeys?.length > 0 &&
                   <Col span={24}>
                   <span className="title_changes">Assign Truck#</span>
                   <Form.Item
                     name="selectMaterial"
                     rules={[
                       {
                         required: true,
                         message: "Please Select Assign Truck!",
                       },
                     ]}
                   >
                     <Select
                       className="select_multiplequarytol"
                       style={{
                         fontWeight: "600",
                         width: "100%",
                       }}
                       placeholder="Search to Select Trucks"
                       allowClear
                       onChange={(value, option) => {
                         this.handleAssignChange(value, option);
                       }}
                       showSearch
                       optionFilterProp="children"
                       filterOption={(input, option) => {
                         const inputValue = input.toLowerCase();
                         const truckLabel = option.label?.toLowerCase() || "";
                         const truckLabel2 = option.label2?.toLowerCase() || "";
                         const truckValue = String(option.value);
 
                         return (
                           truckLabel.startsWith(inputValue) ||
                           truckLabel2.includes(inputValue) ||
                           truckValue.includes(inputValue)
                         );
                       }}
                     >
                       {Object.keys(leadsData)?.length > 0 &&
                         // this.filteredTrucks(
                         //   leadsData?.material[0]?.assignTrucks || [],
                         //   trucksResult
                         // )
                         trucksResult?.map((job, i) => (
                           <Option
                             key={i}
                             value={job?.truckId}
                             className="option_style"
                             label={job?.carrier_name}
                             label2={job?.truck_type}
                             assignTrucksInfo={job?.assignTrucksInfo}
                           >
                             <span>
                               {"TNB " +
                                 job?.truckId +
                                 " - " +
                                 job?.carrier_name +
                                 " - " +
                                 job?.truck_type}
                             </span>
                           </Option>
                         ))}
                     </Select>
                   </Form.Item>
                 </Col>
                }
              </Row>
            </Form>

            {showJobData?.length > 0 && newTicket && (
              <Row>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Dragger
                    onChange={this.uploadTicketPdf}
                    customRequest={dummyRequest}
                    listType="picture"
                    multiple={false}
                    className="drag-ticket"
                    beforeUpload={this.handleUploadType}
                    maxCount={1}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-hint">
                      Click or drag file to this area to upload
                    </p>
                  </Dragger>
                  {percentage > 1 && (
                    <Progress
                      percent={percentage}
                      status={"active"}
                      strokeColor={percentage === 100 && "green"}
                    />
                  )}
                </Col>
              </Row>
            )}
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalResult: state?.reducer?.ticketsResult,
  loading: state?.reducer?.ticketsResult?.loading,
  errorLoading: state?.reducer?.ticketsResult?.error,
  // dispatchResult: state?.reducer?.dispatchResult,
  dispatchResult: state?.reducer?.ordersResult,
  ticketsInfo: state?.reducer?.ticketsInfo,
  trucksResult: state.reducer?.trucksListResult,
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetTickets: () => {
    dispatch(getTickets());
  },
  // actionFetchGetDispatch: () => {
  //   dispatch(getDispatch());
  // },
  actionFetchAddTickets: (data) => {
    dispatch(addTicket(data));
  },
  actionsFetchDeleteTicket: (id) => {
    dispatch(deleteTicket(id));
  },
  actionFetchGetOrders: (id) => {
    dispatch(getOrders());
  },
  actionFetchTrucksList: () => {
    dispatch(getTrucksList());
  },
  actionUpdateOrder: (id, data) => {
    dispatch(updateOrder(id, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(TicketUpload)));
