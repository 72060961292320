import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Button,
  Pagination,
  Checkbox,
  message,
  Drawer,
  InputNumber,
  Popconfirm,
  Popover,
  Switch,
  Spin,
} from "antd";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { TfiLocationPin } from "react-icons/tfi";
import {
  CloseCircleOutlined,
  EllipsisOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  InboxOutlined,
  FilePdfOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import L from "leaflet";
import { } from "react-leaflet-markercluster";
import "leaflet.gridlayer.googlemutant";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import redicon from "../../Images/marker-icon.png";
import greenicon from "../../Images/PickupIcon.png";
import blueIcon from "../../Images/blueMarker.png";
import truckgreen from "../../Images/TRUCK LOCATION ICONS_GREEN.png";
import truckred from "../../Images/TRUCK LOCATION ICONS_RED.png";
import truckblack from "../../Images/TRUCK LOCATION ICONS_BLACK.png";
import truckblue from "../../Images/TRUCK LOCATION ICONS_BLUE.png";
import truckyellow from "../../Images/TRUCK LOCATION ICONS_YELLOW.png";
import truckpurple from "../../Images/TRUCK LOCATION ICONS_PURPLE.png";
import truckorange from "../../Images/TRUCK LOCATION ICONS_ORANGE.png";
// import truckContacted from "../../Images/ContactedTruck.png";
import truckRedDark from "../../Images/truckRedDark.png";
import GarageIcon from "../../Images/GarageIcon.png";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine";
import BaseModal from "../../Common/BaseModal";
import moment from "moment";
import { add, update, updateTotal } from "../../api/Apis";
import appConfig from "../../config/AppConfig";
import NumberFormat from "react-number-format";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../Common/Loading";
import TextArea from "antd/lib/input/TextArea";
import AOS from "aos";
import "aos/dist/aos.css";
import { withAppContext } from "../../Store/AppContext";
import Dragger from "antd/lib/upload/Dragger";
import QuarryJsonData from "../../Json/quarryData.json";
import { connect } from "react-redux";
import {
  getDropDownOptions,
  updateDropDownOptions,
} from "../../Redux/Actions/actions";
import { Storage } from "aws-amplify";

const { Option } = Select;
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class MapComponent extends Component {
  formRef = React.createRef();
  assignJobRef = React.createRef();
  constructor(props) {
    super(props);
    this.marker = {};
    this.ID = "multimap";
    this.state = {
      directions: null,
      dragData: [],
      assignTruck: {},
      truckData: "",
      minValue: 0,
      maxValue: 5,
      currentPage: 1,
      perpageSize: 5,
      mapData: this.props?.filteredMapData,
      selectedMaterial: [],
      recordMaterialData: [],
      materialPopup: false,
      citySearch: "",
      isModalVisible: false,
      openModal: [],
      recordMapData: "",
      loading: false,
      latitude: "",
      longitude: "",
      selectedAddress: "",
      quarryData: this?.props?.quarryData,
      onClickEdit: false,
      routePopUp: false,
      routeData: "",
      selectedMaterialType: "",
      materialList: this.props.context.material,
      quarryName: "",
      truckInfo: "",
      quantityType: "Ton",
      materialData: [],
      selectedTerm: "",
      distance: "",
      duration: "",
      notesData: [],
      notesPopup: false,
      recordNotesData: "",
      mapPostions: {
        lat: 31.5500242809478,
        lng: -97.14024043064336,
      },
      quarryPhone: "",
      quarryDiscount: "",
      quarryAccountLimit: "",
      accountTermsOthers: "",
      diameter: "",
      subCategory1: "",
      subCategory2: [],
      imageLoading: false,
      addCategory: false,
      materialCategory: {},
      materialAttachment: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("--------getDerivedStateFromProps------------");
    if (
      JSON.stringify(props?.filteredMapData) !== JSON.stringify(state?.mapData)
    ) {
      return {
        mapData: props.filteredMapData,
      };
    }
    if (
      JSON.stringify(props?.quarryData) !== JSON.stringify(state?.quarryData) &&
      props?.mapFilterInfo === undefined
      // && !Object?.keys(props?.mapFilterInfo)?.length > 0
    ) {
      return {
        quarryData: props.quarryData,
        loading: false,
      };
    }

    if (
      props?.mapFilterInfo &&
      props?.mapFilterInfo !== undefined &&
      Object?.keys(props?.mapFilterInfo)?.length > 0
    ) {
      return {
        quarryData: props.quarryData.concat(props?.mapFilterInfo),
        loading: false,
      };
    }

    return null;
  }

  componentDidMount() {
    const {
      generateEstimate,
      stageId,
      ownerStageId,
      jobProfile,
      singleValues,
      quarryData,
      dispatchTool,
      requestDispatch,
      showDispatchMap,
      filteredMapData
    } = this.props;
    // console.log("--------componentDidMount------------");
    this.props.context.getTrucksType();
    this.props.actiongetDropDownOptions();
    let origin = {};
    if (this.state?.mapData?.length > 0) {
      origin = {
        lat: 31.5500242809478,
        lng: -97.14024043064336,
      };
    } else if (singleValues) {
      origin = {
        lat: quarryData[0]?.lat,
        lng: quarryData[0]?.lng,
      };
    } else if (
      (jobProfile || generateEstimate) &&
      this.props?.quarryData[0]?.dropOffLatLng
    ) {
      origin = {
        lat: quarryData[0]?.dropOffLatLng?.lat,
        lng: quarryData[0]?.dropOffLatLng?.lng,
      };
    } else if (showDispatchMap && filteredMapData?.length > 0) {
      origin = {
        lat: filteredMapData[0]?.lat,
        lng: filteredMapData[0]?.lng,
      };
    } else {
      origin = {
        lat: 31.5500242809478,
        lng: -97.14024043064336,
      };
    }
    if (
      dispatchTool ||
      jobProfile ||
      stageId ||
      ownerStageId ||
      requestDispatch ||
      generateEstimate
    ) {
      this.dispatchMap(true, origin);
    } else {
      this.initializeMap(true, origin);
    }
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate(prevProps) {
    const { mapPostions } = this.state;
    const {
      quarryData,
      quarry,
      jobProfile,
      filteredMapData,
      mapFilterInfo,
      showSearchPosition,
      showAllQuarries,
      truckingSearch,
      generateEstimate,
      materialDatabase,
      totalTrucksData,
      showDispatchMap
    } = this.props;

    let origin = {};

    if (showAllQuarries && quarryData?.length > 0) {
      if (
        mapFilterInfo &&
        mapFilterInfo !== undefined &&
        Object?.keys(mapFilterInfo)?.length > 0
      ) {
        this.setState({
          openModal: quarryData.concat(mapFilterInfo)?.map((e) => e.id),
          loading: true,
        });
      } else {
        this.setState({
          openModal: quarryData?.map((e) => e.id),
          loading: true,
        });
      }
      this.props.handlelQuarries(false);
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 1000);
    }

    if (
      JSON.stringify(prevProps?.quarryData) !== JSON.stringify(quarryData) ||
      generateEstimate
    ) {
      if (quarryData?.length > 0) {
        origin = {
          lat: quarryData[0]?.lat,
          lng: quarryData[0]?.lng,
        };
      } else {
        origin = {
          lat: mapPostions.lat,
          lng: mapPostions.lng,
        };
      }

      if (quarry || jobProfile || generateEstimate) {
        this?.map?.remove();
      }

      if ((generateEstimate || jobProfile) && quarryData[0]?.dropOffLatLng) {
        origin = {
          lat: quarryData[0]?.dropOffLatLng?.lat,
          lng: quarryData[0]?.dropOffLatLng?.lng,
        };
        this.dispatchMap(true, origin);
      }
      if (quarry) {
        origin = {
          lat: mapPostions.lat,
          lng: mapPostions.lng,
        };
      }
      this.initializeMap(true, origin);
    }

    if (
      JSON.stringify(prevProps?.filteredMapData) !==
      JSON.stringify(filteredMapData)
    ) {
      origin = {
        lat: 31.5500242809478,
        lng: -97.14024043064336,
      };
      this?.map?.remove();
      if (showDispatchMap) {
        this.dispatchMap(true, origin);
      }
      if (this.props.ownerProfile || this.props.truck || materialDatabase) {
        if (this.state?.mapData?.length > 0) {
          if (totalTrucksData?.length !== this.state?.mapData?.length) {
            origin = {
              lat: this.state.mapData[0]?.lat,
              lng: this.state.mapData[0]?.lng,
            };
          }
        }
        this.initializeMap(true, origin);
      }
      if (truckingSearch) {
        origin = {
          lat: filteredMapData[0]?.dropOffLatLng?.lat,
          lng: filteredMapData[0]?.dropOffLatLng?.lng,
        };
      }
      this.dispatchMap(true, origin);
    }

    if (
      JSON.stringify(prevProps?.mapFilterInfo) !== JSON.stringify(mapFilterInfo)
    ) {
      this?.map?.remove();
      origin = {
        lat: mapFilterInfo?.lat,
        lng: mapFilterInfo?.lng,
      };
      if (mapFilterInfo === undefined && quarryData?.length > 0) {
        origin = {
          lat: mapPostions.lat,
          lng: mapPostions.lng,
        };
      }
      this.initializeMap(true, origin);
    }

    if (showSearchPosition && mapFilterInfo) {
      this.props.handleClick(false);
      this?.map?.remove();
      origin = {
        lat: mapFilterInfo?.lat,
        lng: mapFilterInfo?.lng,
      };
      this.initializeMap(true, origin);
    }

    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props.dropDownOptions?.success === true
    ) {
      this.setState({
        salesTaxRate: this.props.dropDownOptions?.totalData?.find(
          (e) => e?.typeofDropDown === "salesTaxRate"
        )?.taxRate,
      });
      let materialData = {};
      let materialsNewList = this.props.materials[0]?.materialsNewList;
      let index = 0;
      let sortedMaterialList = materialsNewList
        ?.filter((v) => v.statusCode)
        .sort((a, b) => a?.indexVal - b?.indexVal);
      sortedMaterialList?.map((eachMat) => {
        let subTypes = [];
        if (eachMat?.statusCode) {
          index += 1;
          materialData[eachMat.category] = !Object.keys(materialData).includes(
            eachMat.category
          )
            ? []
            : materialData[eachMat.category];
          let typeCheck = materialData[eachMat.category].filter(
            (o) => o.subcat === eachMat.materialValue.split(" - ")[1]
          );

          if (typeCheck.length > 0) {
            subTypes = typeCheck[0]["subTypes"];
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
          } else {
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
            materialData[eachMat.category].push({
              subcat: eachMat.materialValue.split(" - ")[1],
              cate: eachMat.materialValue.split(" - ")[0],
              categoryKey: eachMat.categoryKey,
              subShortName: eachMat.subShortName,
              subTypes,
            });
          }
          if (
            sortedMaterialList?.filter((e) => e.statusCode)?.length === index
          ) {
            this.setState({
              materialCategory: materialData,
            });
          }
        }
        return true;
      });
      this.setState({
        materialList: this.props.materials[0]?.materialsNewList,
      });
    }
  }

  truckColorCode = (L, markerInfo, colorCode) => {
    return L.divIcon({
      html:
        "<div class='naviconmapss' style='color: " +
        colorCode +
        "; border: 1px solid " +
        colorCode +
        "; border-radius: 30px; padding:15px;width:100%;height:100%'>" +
        "<i class='fas fa-map-marker'></i>" +
        "<div class='truckid'>" +
        "<p>" +
        markerInfo?.truckId +
        "</p>" +
        "</div>" +
        "</div>",
    });
  };

  dispatchToolColorCode = (L, markerInfo) => {
    const colorCode =
      markerInfo?.dispatchToolStatus === "Available"
        ? "#237804"
        : markerInfo?.dispatchToolStatus === "Contacted"
          ? "#facd2d"
          : "#ff0000";

    const borderColor =
      markerInfo?.dispatchToolStatus === "Available"
        ? "#237804"
        : markerInfo?.dispatchToolStatus === "Contacted"
          ? "#facd2d"
          : "#ff0000";

    return L.divIcon({
      html:
        "<div class='naviconmapss' style='color: " +
        borderColor +
        "; border: 1px solid " +
        borderColor +
        "; border-radius: 30px; padding:15px;width:100%;height:100%'>" +
        "<i class='fas fa-map-marker'></i>" +
        "<div class='truckid'>" +
        "<p style='color: " +
        colorCode +
        ";'>" +
        markerInfo?.truckId +
        "</p>" +
        "</div>" +
        "</div>",
    });
  };

  truckStageCode = (L, colorCode) => {
    return L.divIcon({
      html:
        "<div class='naviconmapss' style='border-radius: 30px; padding:15px;width:100%;height:100%'>" +
        "<i class='fas fa-map-marker' style='color: " +
        colorCode +
        ";'></i>" +
        "<div class='truckid'>" +
        "<p style='padding: 8px'>" +
        "</p>" +
        "</div>" +
        "</div>",
    });
  };

  onFinishDrawer = () => {
    this.setState({ loading: true, errorLoading: false });
    const {
      recordMapData,
      latitude,
      longitude,
      selectedMaterial,
      notesData,
      selectedAddress,
      contactInfo,
      quarryName,
      quarryPhone,
      quarryDiscount,
      quarryAccountLimit,
      selectedTerm,
      accountTermsOthers,
    } = this.state;
    let type = [];
    this.state?.selectedMaterial?.map(
      (e) => e?.categoryKey !== undefined && type.push({ key: e?.categoryKey })
    );
    let uniqueObjArray = [
      ...new Map(type?.map((item) => [item["key"], item])).values(),
    ];

    let data = {
      entityData: {
        quarryType: uniqueObjArray,
        quarryName: quarryName,
        address: selectedAddress,
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
        material: selectedMaterial,
        phone: quarryPhone,
        contactDetails: contactInfo,
        accountTerms:
          selectedTerm === "Custom Input" ? accountTermsOthers : selectedTerm,
        accountLimit: quarryAccountLimit,
        notesDetails: notesData,
        payDiscount: quarryDiscount,
      },
    };
    this.addApiCall(data, recordMapData?.id);
  };

  addApiCall = (data, id) => {
    update(appConfig["entityNames"]["qLocations"], id, data)
      .then((result) => {
        if (result.success) {
          this.setState({
            loading: true,
            isModalVisible: false,
            selectedAddress: "",
            selectedMaterial: [],
            recordMapData: result.data,
            onClickEdit: false,
            selectedTerm: "",
            quarryName: "",
            quarryPhone: "",
            quarryDiscount: "",
            quarryAccountLimit: "",
            accountTermsOthers: "",
          });
          this.props.fetchAllQurries();
          this.props.context.getTrucksType();
        } else {
          this.setState({
            loading: false,
            errorLoading: true,
            selectedAddress: "",
            selectedMaterial: [],
            recordMapData: "",
            onClickEdit: false,
            quantityType: "Ton",
            selectedTerm: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          errorLoading: true,
          selectedAddress: "",
          selectedMaterial: [],
          onClickEdit: false,
          selectedTerm: "",
        });
      });
  };

  onSelectMaterial = (e) => {
    const { materialList } = this.state;
    materialList?.filter(
      (f) => f.id === e && this.setState({ materialSelectedData: f })
    );
  };

  DecimalToMixedFraction = (val) => {
    // eslint-disable-next-line no-eval
    const decimal = eval(val);
    // Split the decimal into whole and fractional parts
    const wholeNumber = Math.floor(decimal);
    const fractionalPart = decimal - wholeNumber;
    // Convert the fractional part to a fraction
    const fraction = this.decimalToFraction(fractionalPart);

    // Combine the whole number and fraction
    const mixedFraction =
      wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : `${fraction}`;

    return mixedFraction;
  };

  decimalToFraction = (decimal) => {
    const EPSILON = 1.0e-9;
    let numerator = decimal;
    let denominator = 1;

    while (Math.abs(Math.round(numerator) - numerator) > EPSILON) {
      numerator *= 10;
      denominator *= 10;
    }

    const gcd = this.getGreatestCommonDivisor(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  };

  getGreatestCommonDivisor = (a, b) => {
    if (b === 0) {
      return a;
    }
    return this.getGreatestCommonDivisor(b, a % b);
  };

  onFinishMaterial = (values) => {
    this.setState({
      loading: true,
    });
    const {
      recordMaterialData,
      materialSelectedData,
      quantityType,
      materialAttachment,
      selectedMaterial,
      diameter,
      salesTaxRate,
    } = this.state;

    let minD = String(values["materialMinDiameter"])?.replace('"', "");
    let maxD = String(values["materialMaxDiameter"])?.replace('"', "");
    let mD = String(values["materialDiameter"])?.replace('"', "");

    // console.log(materialSelectedData, "materialSelectedData");
    let materialMinDiameter = "",
      materialMaxDiameter = "",
      materialDiameter = "",
      materialData = [];

    if (diameter) {
      materialMinDiameter =
        minD !== "Unknown"
          ? minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
            ? this.DecimalToMixedFraction(minD)
            : minD
          : minD;

      materialMaxDiameter =
        maxD !== "Unknown"
          ? maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
            ? this.DecimalToMixedFraction(maxD)
            : maxD
          : maxD;
    } else {
      materialDiameter =
        mD !== "Unknown"
          ? mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
            ? this.DecimalToMixedFraction(mD)
            : mD
          : mD;
    }

    if (
      recordMaterialData !== "" &&
      Object.keys(recordMaterialData)?.length > 0
    ) {
      let filteredData = selectedMaterial.filter(
        (c) => c.materialId !== recordMaterialData?.materialId
      );
      materialData = filteredData?.filter(
        (m) =>
          m?.id === materialSelectedData?.id &&
          (m.diameter
            ? m["materialMinDiameter"] === materialMinDiameter &&
            m["materialMaxDiameter"] === materialMaxDiameter
            : m["materialDiameter"] === materialDiameter)
      );
    } else {
      materialData = selectedMaterial?.filter(
        (m) =>
          m?.id === materialSelectedData?.id &&
          (m.diameter
            ? m["materialMinDiameter"] === materialMinDiameter &&
            m["materialMaxDiameter"] === materialMaxDiameter
            : m["materialDiameter"] === materialDiameter)
      );
    }

    if (materialData?.length > 0) {
      message.info("Given Material Is Already Selected");
      this.setState({
        loading: false,
      });
      return true;
    } else {
      let data = {
        entityData: {
          id: materialSelectedData["id"],
          materialId:
            recordMaterialData === ""
              ? uuidv4()
              : recordMaterialData?.materialId
                ? recordMaterialData?.materialId
                : uuidv4(),
          label: materialSelectedData["label"],
          value:
            materialSelectedData["materialValue"] || recordMaterialData?.value,
          category: materialSelectedData["category"],
          subCategory: materialSelectedData["subCategory"],
          price: values["price"] || recordMaterialData["price"],
          quantity: "",
          quantityType: quantityType || recordMaterialData?.quantityType,
          categoryKey: materialSelectedData["categoryKey"],
          materialPicture: materialAttachment,
          materialNotes: values["materialNotes"],
          materialStatus: values["materialStatus"],
          materialSupply: values["materialSupply"],
          materialTest: values["materialTest"],
          materialTax: values["materialTax"],
          additionalTaxRate: salesTaxRate,
          materialMaxDiameter: materialMaxDiameter,
          materialMinDiameter: materialMinDiameter,
          materialDiameter: materialDiameter,
          materialCheckDiameter: diameter,
          statusCode: materialSelectedData["statusCode"],
          subShortName: materialSelectedData["subShortName"],
          confirm: materialSelectedData["confirm"]
            ? materialSelectedData["confirm"]
            : false,
        },
      };

      if (recordMaterialData === "") {
        selectedMaterial.splice(
          selectedMaterial.findIndex(
            (f) => f?.categoryKey === data["entityData"]["categoryKey"]
          ) + 1,
          0,
          data["entityData"]
        );
      } else {
        selectedMaterial.splice(
          selectedMaterial.findIndex(
            (f) => f?.materialId === data["entityData"]["materialId"]
          ),
          1,
          data["entityData"]
        );
      }
      // console.log(data, "data");
      this.setState({
        selectedMaterial: selectedMaterial,
        materialPopup: false,
        loading: false,
        materialCheck: false,
        quantityType: "Ton",
        selectedMaterialType: "",
        recordMaterialData: "",
        materialSelectedData: "",
        diameter: "",
      });
    }
  };

  editMaterial = (data) => {
    this.setState(
      {
        materialPopup: true,
        recordMaterialData: data,
        materialSelectedData: data,
        recordContactData: "",
        selectedMaterialType: data?.category,
        quantityType: data?.quantityType,
        diameter: data?.materialCheckDiameter,
        // subCategory1:data?.label?.split(" - ")[0]
      },
      () => this.subCategoryInfo(data)
      // () => this.onSelectMaterialCat(data?.category, "edit")
    );
  };

  subCategoryInfo = (data) => {
    const { materialCategory } = this.state;
    if (materialCategory[data.category]) {
      Object.values(materialCategory[data.category])?.forEach((c, i) => {
        if (c.subcat === data?.label?.split(" - ")[0]) {
          this.setState({
            subCategory1: data.category,
            subCategory2: c.subTypes,
            categoryKey: c.categoryKey,
            subShortName: c.subShortName,
          });
        }
      });
    }
  };

  handleFileDelete = (info, type) => {
    const { recordMaterialData, materialAttachment } = this.state;
    if (type === "materialPicture") {
      if (recordMaterialData) {
        console.log(info, "info");
        let docData = materialAttachment?.filter((e) => e.id !== info.id);
        this.setState({
          materialAttachment: docData,
        });
      } else {
        let docData = materialAttachment?.filter((e) => e.id !== info.id);
        this.setState({
          materialAttachment: docData,
        });
      }
      this.setState({
        imageLoading: false,
        loading: false,
        percentage: 0,
      });
    }
  };

  deleteMaterial = (id) => {
    const { selectedMaterial } = this.state;
    selectedMaterial?.splice(
      selectedMaterial?.findIndex((e) => e?.materialId === id),
      1
    );
    this.setState({
      selectedMaterial,
    });
    message.info("Material Deleted Successfully!");
  };

  addContact = (values) => {
    const { recordContactData, leadsData, quarryName } = this.state;
    if (recordContactData !== "") {
      let filteredData = this.state.contactInfo.filter(
        (c) => c.id !== recordContactData?.id
      );
      this.setState({
        contactInfo: filteredData,
      });
    }

    let data = {
      entityData: {
        id: uuidv4(),
        firstName: values.fName,
        lastName: values.lName,
        phone: values.phone,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
        notes: values["notes"],
        company: quarryName || leadsData?.quarryName,
      },
    };
    // console.log(data, "data");
    let contactData = this.state?.contactInfo?.filter(
      (c) => c?.email === values?.email && c?.phone === values?.phone
    );

    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number Already Exists");
      return true;
    } else {
      this.setState({
        contactPopup: false,
        loading: true,
      });
      if (this.state.isCheckcontact) {
        this.props.context.updateQuarryRolesData(values["roleOther"]);
      }
      add(appConfig["entityNames"]["checkContact"], data)
        .then((result) => {
          if (result.data.message === "success") {
            message.success("Contact Added Successfully!");
            let contactInfo =
              this.state?.contactInfo?.length > 0
                ? this.state?.contactInfo
                : [];
            if (recordContactData?.length > 0) {
              let filteredData = this.state.contactInfo.filter(
                (c) => c.id !== recordContactData?.id
              );
              filteredData.push(data["entityData"]);
              this.setState({
                contactInfo: filteredData,
                loading: false,
                isCheckcontact: false,
                newRole: "",
              });
            }
            contactInfo.push(data["entityData"]);
            this.setState({
              contactInfo,
              loading: false,
              isCheckcontact: false,
              newRole: "",
            });
          } else {
            message.info(result.data.message);
            this.setState({
              loading: false,
              isCheckcontact: false,
              newRole: "",
            });
            return true;
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
        });
    }
  };

  deleteContactInfo = (id) => {
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState({
      contactInfo,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    // console.log(data);
    this.setState({
      contactPopup: true,
      recordContactData: data,
      recordMaterialData: "",
      quarryName: data?.company,
    });
  };

  getAddress = (address) => {
    this.setState({
      selectedAddress: address.label,
    });
  };

  handleSelect = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let streetNumberValue = "";
    let streetValue = "";
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    let stateShortValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) =>
          this.setState({
            latitude: lat,
            longitude: lng,
          })
        );
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let area = results[0].address_components;
        for (let i = 0; i < area.length; i++) {
          if (area[i].types[0] === "street_number") {
            streetNumberValue = area[i].long_name;
          }
          if (area[i].types[0] === "route") {
            streetValue = area[i].long_name;
          }
          if (area[i].types[0] === "locality") {
            cityValue = area[i].long_name;
          }
          if (area[i].types[0] === "administrative_area_level_1") {
            stateValue = area[i].long_name;
          }
          if (area[i].types[0] === "administrative_area_level_1") {
            stateShortValue = area[i].short_name;
          }
          if (area[i].types[0] === "postal_code") {
            zipCodeValue = area[i].long_name;
          }
        }
        this.setState({
          streetNumber: streetNumberValue,
          street: streetValue,
          city: cityValue,
          addressState: stateValue,
          stateAbbr: stateShortValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onClickEdit = (recordMapData) => {
    this.setState({
      recordMapData,
      onClickEdit: true,
      selectedMaterial: recordMapData?.material,
      contactInfo: recordMapData?.contactDetails,
      quantityType: recordMapData?.quantityType,
      notesData: recordMapData?.notesDetails,
      selectedAddress: recordMapData?.address,
      latitude: recordMapData?.lat,
      longitude: recordMapData?.lng,
      quarryName: recordMapData?.quarryName,
      quarryPhone: recordMapData?.phone,
      quarryDiscount: recordMapData?.payDiscount,
      quarryAccountLimit: recordMapData?.accountLimit,
      selectedTerm: recordMapData?.accountTerms,
      accountTermsOthers: recordMapData?.accountTermsOthers,
    });
  };

  initializeMap = async (createMap, origin) => {
    const { materialDatabase, ownerProfile } = this.props;
    const markers = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      spiderfyDistanceMultiplier: 1,
      maxClusterRadius: 50,
    });

    const redirectLink = (markerInfo) => {
      this.props.propsData.history.push(
        `/app/twisted-nail/carrier-profile/${markerInfo?.id}`
      );
    };
    const checkData = (data) => {
      if (!this.state.openModal.includes(data.id)) {
        this.setState((prevState) => ({
          openModal: [...prevState.openModal, data.id],
        }));
      }
      this.setState({
        recordMapData: data,
        selectedMaterial: data?.material,
        onClickEdit: false,
        contactInfo: data?.contactDetails,
        quantityType: data?.quantityType,
        quarryName: data?.quarryName,
        mapPostions: {
          lat: data.lat,
          lng: data.lng,
        },
      });
      this.props.showSearchPosition && this.props.handleClick(false);
    };
    if (createMap) {
      // markers.clearLayers();
      this.map = L.map(this.ID, {
        zoom:
          ownerProfile || materialDatabase
            ? 6
            : // : this.state.mapPostions.lat !== 31.5500242809478
            // ? 14
            12,
        center: origin,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: "topleft",
        },
        attributionControl: false,
        // zoomControl: false  //remove + - in map,
        scrollWheelZoom: this.props.disableScroll ? false : true,
      });
      var googleHybrid = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
      var googleStreets = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
      var baseMaps = {
        Default: googleStreets,
        Satellite: googleHybrid,
      };

      var overlays = {};

      L.control
        .layers(baseMaps, overlays, { position: "topright" })
        .addTo(this.map);
      L.gridLayer
        .googleMutant({
          maxZoom: 20,
        })
        .addTo(this.map);

      if (materialDatabase) {
        this.state?.mapData?.map(async (markerInfo) => {
          if (markerInfo.lat !== "" && markerInfo.lng !== "") {
            let msg =
              "<p class='parapopup'><b class='para_line'></b>" +
              markerInfo.quarryName +
              ", " +
              "</p>";

            L.marker([markerInfo["lat"], markerInfo["lng"]], {
              draggable: true,
              icon: new L.Icon({
                iconUrl: redicon,
                iconSize: [25, "auto"],
                iconAnchor: [12.5, 41],
                popupAnchor: [0, -41],
              }),
            })
              .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>")
              .addTo(this.map);
          }
          return true;
        });
      }
      this.state?.quarryData?.map(async (markerInfo) => {
        let msg = markerInfo.legal_name ? markerInfo.legal_name + "<br/>" : "";
        msg += markerInfo.mine_name ? markerInfo.mine_name + "<br/>" : "";
        msg += markerInfo.company ? markerInfo.company + "<br/>" : "";
        if (this.props.ownerDatabase) {
          msg += markerInfo.mailing_city ? markerInfo.mailing_city + "," : "";
          msg += markerInfo.mailing_state ? markerInfo.mailing_state + "," : "";
          msg += markerInfo.mailing_zip
            ? markerInfo?.mailing_zip + "<br/>"
            : "";
        } else {
          msg += markerInfo.address
            ? markerInfo.address + "<br/>"
            : markerInfo?.city +
            markerInfo?.addressState +
            markerInfo?.street +
            markerInfo?.zip_cd +
            "<br/>";
        }
        msg += moment(markerInfo?.updatedAt).format("L");
        if (this.props?.quarry) {
          let quarryMarker = L.marker(
            [
              markerInfo["lat"] !== null &&
              markerInfo["lat"] !== 0 &&
              markerInfo["lat"],
              markerInfo["lng"] !== null &&
              markerInfo["lng"] !== 0 &&
              markerInfo["lng"],
            ],
            {
              draggable: false,
              icon: new L.Icon({
                iconUrl: markerInfo?.searchInfo ? blueIcon : redicon,
                iconSize: [25, "auto"],
                iconAnchor: [12.5, 41],
                popupAnchor: [0, -41],
              }),

            }
          ).addTo(this.map);

          quarryMarker.on("click", function (e) {
            !markerInfo?.searchInfo && checkData(markerInfo);
          });
          quarryMarker.bindPopup(
            "<span style='font-weight:bold;'>" +
            markerInfo?.quarryName +
            "<span>"
          );
        } else {
          L.marker([markerInfo["lat"], markerInfo["lng"]], {
            draggable: false,
            icon: new L.Icon({
              iconUrl: redicon,
              iconSize: [25, "auto"],
              iconAnchor: [12.5, 41],
              popupAnchor: [0, -41],
            }),
          })
            // .on("click", function (e) {
            //   console.log(e, "e");
            //   console.log("clicked");
            //   checkData(markerInfo);
            // })
            .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>")
            .addTo(this.map);
          // .openPopup();
        }
      });
      this.state.mapData?.map(async (t) => {
        if (this.props?.ownerProfile) {
          let msg =
            "<p class='parapopup'><b class='para_line'>Carrier Details: <br/>";
          msg += t?.carrier_name ? t.carrier_name + "<br/>" : "";
          msg += t?.phone
            ? "<p class='parapopup'><b class='para_line'></b>" +
            t?.phone +
            ", " +
            "</p>"
            : "";
          msg += t?.email
            ? "<p class='parapopup'><b class='para_line'></b>" +
            t?.email +
            ", " +
            "</p>"
            : "";

          let garageIcon = new L.Icon({
            iconUrl: GarageIcon,
            iconSize: [35, "auto"],
            iconAnchor: [12.5, 41],
            popupAnchor: [0, -41],
          });

          L.marker([t["lat"], t["lng"]], {
            draggable: false,
            icon: garageIcon,
          })
            .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>")
            .addTo(this.map);
          return true;
        }
      });

      if (!this.props.OwnerOperatorProfileWelcomeCall) {
        if (this.props.ownerProfile) {
          this.state.mapData?.map(async (t) =>
            t.carrierDetails?.map((markerInfo) => {
              if (
                markerInfo?.truckId &&
                markerInfo?.truckId !== undefined &&
                markerInfo?.truckId !== ""
              ) {
                let msg = markerInfo.legal_name
                  ? markerInfo.legal_name + "<br/>"
                  : "";
                msg += markerInfo.mine_name
                  ? markerInfo.mine_name + "<br/>"
                  : "";
                msg += markerInfo.company ? markerInfo.company + "<br/>" : "";
                msg +=
                  "<p class='parapopup'><b class='para_line'>Truck Details: </b>";

                msg += markerInfo?.truckId
                  ? "<p class='parapopup'><b class='para_line'></b>" +
                  "TNB " +
                  markerInfo?.truckId +
                  ", " +
                  t?.carrier_name +
                  "</p>"
                  : "";
                msg += markerInfo.truck_type
                  ? "<p class='parapopup'><b class='para_line'></b>" +
                  markerInfo.truck_type +
                  ", " +
                  "</p>"
                  : "";
                msg += markerInfo.contact_name
                  ? "<p class='parapopup'><b class='para_line'></b>" +
                  markerInfo.contact_name +
                  ", " +
                  "</p>"
                  : "";
                msg += markerInfo.contact_number
                  ? "<p class='parapopup'><b class='para_line'></b>" +
                  markerInfo.contact_number +
                  ", " +
                  "</p>"
                  : "";
                L.marker([markerInfo["lat"], markerInfo["lng"]], {
                  draggable: true,
                  icon:
                    markerInfo.truck_type === "Round Bottom"
                      ? this.truckColorCode(L, markerInfo, "#602063")
                      : markerInfo.truck_type === "End Dump"
                        ? this.truckColorCode(L, markerInfo, "#008000")
                        : markerInfo.truck_type === "Belly Dump"
                          ? this.truckColorCode(L, markerInfo, "#ba1c1c")
                          : markerInfo.truck_type === "Super Dump"
                            ? this.truckColorCode(L, markerInfo, "#ffa500")
                            : markerInfo.truck_type === "15+ Ton Dump Truck"
                              ? this.truckColorCode(L, markerInfo, "#1967d2")
                              : markerInfo.truck_type === "10+ Ton Dump Truck"
                                ? this.truckColorCode(L, markerInfo, "#ffcd2c")
                                : this.truckColorCode(L, markerInfo, "#000"),
                })
                  // .bindTooltip(markerInfo.truckId, {
                  //   permanent: true,
                  // direction: "center",
                  // className: "my-labels",
                  // })
                  .bindPopup(
                    "<span style='font-weight:bold;'>" + msg + "<span>"
                  )

                  .addTo(markers);
                this.map.addLayer(markers);
                // .addTo(this.map);
                // this.map.addLayer(markers);
                // .openPopup();
                return true;
              }
              return true;
            })
          );
        } else if (this.props.truck) {
          this.state.mapData?.map(async (markerInfo) => {
            if (
              markerInfo.showCarrier !== false &&
              markerInfo.banCarrier !== false &&
              markerInfo["lat"] &&
              markerInfo["lng"]
            ) {
              let msg = markerInfo.legal_name
                ? markerInfo.legal_name + "<br/>"
                : "";
              msg += markerInfo.mine_name ? markerInfo.mine_name + "<br/>" : "";
              msg += markerInfo.company ? markerInfo.company + "<br/>" : "";
              msg +=
                "<p class='parapopup'><b class='para_line'>Truck Details: </b>";

              msg += markerInfo?.truckId
                ? "<p class='parapopup'><b class='para_line'></b>" +
                "TNB " +
                markerInfo?.truckId +
                ", " +
                markerInfo?.carrier_name +
                "</p>"
                : "";
              msg += markerInfo.truck_type
                ? "<p class='parapopup'><b class='para_line'></b>" +
                markerInfo.truck_type +
                ", " +
                "</p>"
                : "";
              msg += markerInfo?.contact_name
                ? "<p class='parapopup'><b class='para_line'></b>" +
                markerInfo?.contact_name +
                ", " +
                "</p>"
                : "";
              msg += markerInfo.contact_number
                ? "<p class='parapopup'><b class='para_line'></b>" +
                markerInfo.contact_number +
                ", " +
                "</p>"
                : "";

              let EXPIRY_Truck = L.divIcon({
                html:
                  "<div class='naviconmapss' style='color: #FFF;  border: 1px solid #FFF; border-radius: 30px; padding:15px;width:100%;height:100%'>" +
                  "<i class='fas fa-map-marker'></i>" +
                  "<div class='truckid'>" +
                  "<p  style='background-color:#f7595e;' >" +
                  markerInfo?.truckId +
                  "</p>" +
                  "</div>" +
                  "</div>",
              });
              L.marker([markerInfo["lat"], markerInfo["lng"]], {
                draggable: false,
                icon: this.props.dispatchToolMap
                  ? this.dispatchToolColorCode(L, markerInfo)
                  : new Date().getTime() >=
                    new Date(markerInfo?.insurance_expiry).getTime()
                    ? EXPIRY_Truck
                    : markerInfo.truck_type === "Round Bottom"
                      ? this.truckColorCode(L, markerInfo, "#602063")
                      : markerInfo.truck_type === "End Dump"
                        ? this.truckColorCode(L, markerInfo, "#008000")
                        : markerInfo.truck_type === "Belly Dump"
                          ? this.truckColorCode(L, markerInfo, "#ba1c1c")
                          : markerInfo.truck_type === "Super Dump"
                            ? this.truckColorCode(L, markerInfo, "#ffa500")
                            : markerInfo.truck_type === "15+ Ton Dump Truck"
                              ? this.truckColorCode(L, markerInfo, "#1967d2")
                              : markerInfo.truck_type === "10+ Ton Dump Truck"
                                ? this.truckColorCode(L, markerInfo, "#ffcd2c")
                                : this.truckColorCode(L, markerInfo, "#000"),
              })
                // .bindTooltip(markerInfo.truckId, {
                //   permanent: true,
                // direction: "center",
                // className: "my-labels",
                // })
                .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>")
                .on("dblclick", function (e) {
                  redirectLink(markerInfo);

                  // console.log("onClick");
                  // this.setState({
                  //   isMarkerModal: true,
                  //   modalData: markerInfo,
                  // });
                })
                .addTo(this.map);
              // this.map.addLayer(markers);
              // .addTo(this.map);
              //   .addTo(markers);
              // this.map.addLayer(markers);
              // .openPopup();
              return true;
            }
          });
        } else {
          this.state.mapData?.map(async (t) =>
            t.carrierDetails?.map((markerInfo) => {
              let msg = markerInfo.legal_name
                ? markerInfo.legal_name + "<br/>"
                : "";
              msg += markerInfo.mine_name ? markerInfo.mine_name + "<br/>" : "";
              msg += markerInfo.company ? markerInfo.company + "<br/>" : "";

              msg +=
                "<p class='parapopup'><b class='para_line'> Address -</b>" +
                  markerInfo.address +
                  "</p>"
                  ? "<p class='parapopup'><b class='para_line'> Address -</b>" +
                  markerInfo.address +
                  ", " +
                  "</p>"
                  : "<p class='parapopup'><b class='para_line'> City -</b>" +
                  markerInfo.city +
                  ", " +
                  "</p>" +
                  "<p class='parapopup'><b class='para_line'> State -</b>" +
                  markerInfo?.addressState +
                  ", " +
                  "</p>" +
                  "<p class='parapopup'><b class='para_line'> Street -</b>" +
                  markerInfo?.street +
                  ", " +
                  "</p>" +
                  "<p class='parapopup'><b class='para_line'> Zip Code -</b>" +
                  markerInfo?.zip_cd +
                  "</p>";

              L.marker([markerInfo["lat"], markerInfo["lng"]], {
                draggable: false,
                icon:
                  markerInfo.truck_type === "Round Bottom"
                    ? this.truckColorCode(L, markerInfo, "#7d04c2")
                    : markerInfo.truck_type === "End Dump"
                      ? this.truckColorCode(L, markerInfo, "#008000")
                      : markerInfo.truck_type === "Belly Dump"
                        ? this.truckColorCode(L, markerInfo, "#ba1c1c")
                        : markerInfo.truck_type === "Super Dump"
                          ? this.truckColorCode(L, markerInfo, "#ffa500")
                          : markerInfo.truck_type === "15+ Ton Dump Truck"
                            ? this.truckColorCode(L, markerInfo, "#1967d2")
                            : markerInfo.truck_type === "10+ Ton Dump Truck"
                              ? this.truckColorCode(L, markerInfo, "#ffcd2c")
                              : this.truckColorCode(L, markerInfo, "#000"),
              })
                // .bindTooltip(markerInfo.truckId, {
                //   permanent: true,
                // direction: "center",
                // className: "my-labels",
                // })
                .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>")
                .on("click", function (e) {
                  // console.log(e, "marker");
                  // console.log("onClick");
                  this.setState({
                    isMarkerModal: true,
                    modalData: markerInfo,
                  });
                })
                .addTo(this.map);
              // this.map.addLayer(markers);
              // .addTo(this.map);
              //   .addTo(markers);
              // this.map.addLayer(markers);
              // .openPopup();
              return true;
            })
          );
          // Twisted Nail Owner Operator Roster & Map
        }
      } else {
        this.map.eachLayer((layer) => {
          if (layer.feature !== undefined) {
            this.map.removeLayer(layer);
          }
        });
      }
    }
  };

  getDistanceAndDuration = (s, d) => {
    this.setState({ loading: true });
    let url =
      "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
      [s?.lat, s?.lng] +
      "&origins=" +
      [d?.lat, d?.lng] +
      "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
    let milesData = {
      entityData: {
        url,
      },
    };
    add("getMiles", milesData)
      .then((res) => {
        if (res.success) {
          this.setState({
            distance: res.data.distance,
            duration: res.data.duration,
            loading: false,
          });
          // this.formRef.current.setFieldsValue({
          //   material: this.state?.materialData[0]?.id,
          //   jobNumber: this.state?.assignTruck?.route?.jobNumber,
          // });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err, "err");
      });
  };

  dispatchMap = async (createMap, origin) => {
    const { showDispatchMap } = this.props;
    const { mapData } = this.state;
    // const markers = L.markerClusterGroup({
    //   spiderfyOnMaxZoom: true,
    //   showCoverageOnHover: false,
    //   zoomToBoundsOnClick: true,
    //   spiderfyDistanceMultiplier: 1,
    //   maxClusterRadius: 50,
    // });
    const lineCheck = (data) => {
      this.setState({
        routePopUp: true,
        routeData: data,
      });
    };
    const checkData = (d, truck, singleTruck) => {
      let filterQuarryData = this.props?.quarryData.filter(
        (e) => e.assigned !== true
      );
      // let assignData = false;
      // let sourcePath = {},
      //   destinationPath = {};
      filterQuarryData?.map(async (m) => {
        m?.material?.map((mt) => {
          if (mt.assigned === false) {
            if (
              (parseFloat(mt["pickUpLatLng"]["lat"]) <= parseFloat(d["lat"]) &&
                parseFloat(m["dropOffLatLng"]["lat"]) >=
                parseFloat(d["lat"])) ||
              (parseFloat(mt["pickUpLatLng"]["lng"]) <= parseFloat(d["lng"]) &&
                parseFloat(m["dropOffLatLng"]["lng"]) >= parseFloat(d["lng"]))
            ) {
              if (m["truckType"]?.filter((e) => singleTruck?.truck_type === e)
                ?.length > 0) {
                singleTruck["payment_terms"] = truck.payment_terms;
                singleTruck["carrier_name"] = truck.carrier_name;
                singleTruck["contact_Info"] = truck.contactDetails;
                let materialData = [];
                materialData.push(mt);
                // assignData = true;
                let assignedData = {
                  route: m,
                  truck: truck,
                  truckInfo: singleTruck,
                  materialData,
                };
                this.setState({
                  assignTruck: assignedData,
                  truckData: truck,
                  truckInfo: singleTruck,
                  materialData,
                  routeData: m,
                  showJobDispatch: true
                });
                this.marker[singleTruck?.truckId]?.remove();
                // this.getDistanceAndDuration(mt, m);
                this.assignJobRef.current.setFieldsValue({
                  assignJob: m?.id,
                  // jobNumber: m?.jobNumber,
                });
                this.onFinish(m?.jobNumber, assignedData);
              } else {
                message.error(
                  `Please Select Truck Type: ${m["truckType"].join(", ")}`
                );
                this.marker[singleTruck.truckId].setLatLng([
                  singleTruck?.lat,
                  singleTruck?.lng,
                ]);
              }
            } else {
              if (
                m["truckType"]?.filter((e) => singleTruck?.truck_type === e)
                  ?.length > 0
              ) {
                singleTruck["payment_terms"] = truck.payment_terms;
                let materialData = [];
                materialData.push(mt);
                // assignData = true;
                let assignedData = {
                  route: m,
                  truck: truck,
                  truckInfo: singleTruck,
                  materialData,
                };
                this.setState({
                  assignTruck: {
                    route: m,
                    truck: truck,
                    truckInfo: singleTruck,
                    materialData,
                  },
                  truckData: truck,
                  truckInfo: singleTruck,
                  materialData,
                });
                this.marker[singleTruck?.truckId]?.remove();
                this.onFinish(m?.jobNumber, assignedData);
              } else {
                message.error(
                  `Please Select Truck Type: ${m["truckType"].join(", ")}`
                );
                this.marker[singleTruck.truckId].setLatLng([
                  singleTruck?.lat,
                  singleTruck?.lng,
                ]);
              }
            }
            return true;
          }
          return true;
        });
      });
      // if (assignData) {
      //   this.getDistanceAndDuration(sourcePath, destinationPath);
      // }
    };
    if (createMap) {
      // markers.clearLayers();
      this.map = L.map(this.ID, {
        zoom: showDispatchMap ? 8 : 12,
        center: origin,
        attributionControl: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: "topleft",
        },
        scrollWheelZoom: this.props.disableScroll ? false : true,
      });
      var googleHybrid = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
      var googleStreets = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
      var googleTraffic = L.tileLayer(
        "https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          minZoom: 2,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );

      var baseMaps = {
        Default: googleStreets,
        Satellite: googleHybrid,
        Traffic: googleTraffic,
      };

      var overlays = {
        //add any overlays here
      };
      L.control
        .layers(baseMaps, overlays, { position: "topright" })
        .addTo(this.map);
      L.gridLayer
        .googleMutant({
          // type: "hybrid",
          maxZoom: 20,
        })
        .addTo(this.map);

      this.props?.quarryData?.map(async (markerInfo, index) => {
        markerInfo?.material?.map((data) => {
          if (markerInfo?.assigned === true && !this.props.jobProfile) {
          } else {
            let msg;
            if (
              markerInfo.jobType === "Trucking & Material Job" ||
              markerInfo.jobType !== "Trucking Job"
            ) {
              msg =
                "<span class='para_linegrp'><b class='para_line'> Material -</b><p class='parapopup'>" +
                  markerInfo?.material[0]?.value +
                  "</p></span>"
                  ? "<span class='para_linegrp'><b class='para_line'> Material -</b><p class='parapopup'>" +
                  markerInfo?.material?.map((e) => e?.category) +
                  "</p></span>"
                  : "";
            } else {
              msg =
                "<span class='para_linegrp'><b class='para_line'> Material -</b><p class='parapopup'>" +
                  markerInfo?.material[0]?.value +
                  "</p></span>"
                  ? ""
                  : "";
            }

            msg +=
              "<span class='para_linegrp'><b class='para_line'> Date -</b><p class='parapopup'>" +
              moment(markerInfo?.updatedAt).format("L") +
              "</p></span>";

            let waypoints = [
              L.latLng(
                parseFloat(data?.pickUpLatLng?.lat),
                parseFloat(data?.pickUpLatLng?.lng)
              ),
              L.latLng(
                markerInfo["dropOffLatLng"]["lat"],
                markerInfo["dropOffLatLng"]["lng"]
              ),
            ];

            let icon = L.icon({
              iconUrl: greenicon,
              iconSize: [20, "auto"],
              iconAnchor: [8.5, 32],
              popupAnchor: [0, -32],
            });

            const icon1 = L.icon({
              iconUrl: redicon,
              iconSize: [20, "auto"],
              iconAnchor: [8.5, 32],
              popupAnchor: [0, -32],
            });
            var message = [];
            if (this.props.jobProfile) {
              message = [
                "<span class='para_linegrp'><b class='para_line'> PickUp -</b><p class='parapopup'>" +
                markerInfo?.material?.map((e) => e?.pickUpAddress) +
                "</p></span>",
                "<span class='para_linegrp'><b class='para_line'> DropOff -</b><p class='parapopup'>" +
                markerInfo?.dropoff +
                "</p></span>",
              ];
            } else {
              message = [
                "<span class='para_linegrp'><b class='para_line'> PickUp -</b><p class='parapopup'>" +
                markerInfo?.material?.map((p) => p?.pickUpAddress) +
                "</p></span>",
                "<span class='para_linegrp'><b class='para_line'> DropOff -</b><p class='parapopup'>" +
                markerInfo?.dropoff +
                "</p></span>",
              ];
            }
            L.Routing.control({
              waypoints,
              plan: L.Routing.plan(waypoints, {
                createMarker: function (i, wp) {
                  if (waypoints[0]) {
                    return L.marker(wp.latLng, {
                      draggable: false,
                      icon: i === 0 ? icon : icon1,
                    }).bindPopup(
                      "<span style='font-weight:bold;'>" +
                      message[i] +
                      msg +
                      "<span>"
                    );
                  }
                },
                routeWhileDragging: true,
              }),
              lineOptions: {
                styles: [{ color: "#6FA1EC", weight: 6 }],
              },
              show: false,
              addWaypoints: false,
              routeWhileDragging: false,
              draggableWaypoints: false,
              fitSelectedRoutes: false,
              showAlternatives: false,

              routeLine: function (route) {
                var line = L.Routing.line(route, {
                  addWaypoints: false,
                  routeWhileDragging: false,
                  autoRoute: true,
                  useZoomParameter: false,
                  draggableWaypoints: false,
                });
                line.eachLayer(function (l) {
                  l.on("click", function (e) {
                    // console.log("tes", e);
                    lineCheck(markerInfo);
                  });
                });
                return line;
              },
            }).addTo(this.map);
            //.bindPopup("<span style='font-weight:bold;'>" + msg + "<span>");
          }
          return true;
        });
        return true;
      });

      // Trucking Material Map
      if (this.props.stageId || this.props.requestDispatch) {
        mapData?.map(async (markerInfo) => {
          if (Object.keys(markerInfo.dropOffLatLng)?.length > 0) {
            const trucksData = markerInfo?.material?.map((e) => e.truckType);
            let truckCodes = trucksData.map((t) => {
              return this.props.trucksTypesData
                ?.filter((e) => t?.includes(e.value))
                .map((e) => e.truck_code);
            });
            const seperatedwithComma = truckCodes?.flatMap((e) =>
              e?.join(", ")
            );

            let msg =
              "<p class='parapopup'><b class='para_line'></b>" +
              "#" +
              markerInfo.jobNumber +
              "- " +
              markerInfo?.jobName +
              ", " +
              "</p>";
            msg +=
              "<p class='parapopup'><b class='para_line'></b>" +
              markerInfo.jobType +
              ", " +
              "</p>";
            msg +=
              "<p class='parapopup'><b class='para_line'></b>" +
              markerInfo.companyName +
              ", " +
              "</p>";

            msg +=
              "<p class='parapopup'><b class='para_line'></b>" +
              seperatedwithComma +
              ", " +
              "</p>";

            markerInfo["truckId"] = markerInfo["jobNumber"];
            L.marker(
              [
                markerInfo["dropOffLatLng"]["lat"],
                markerInfo["dropOffLatLng"]["lng"],
              ],
              {
                draggable: true,
                icon:
                  markerInfo.stageId === "Job Lead"
                    ? this.truckColorCode(L, markerInfo, "#333")
                    : markerInfo.stageId === "Contacted"
                      ? this.truckColorCode(L, markerInfo, "#6a00ff")
                      : markerInfo.stageId === "Build Quote"
                        ? this.truckColorCode(L, markerInfo, "#237804")
                        : markerInfo.stageId === "Quote Delivered"
                          ? this.truckColorCode(L, markerInfo, "#f7df7c")
                          : markerInfo.stageId === "Quote Accepted"
                            ? this.truckColorCode(L, markerInfo, "#brown")
                            : markerInfo.stageId === "Dispatch Approved"
                              ? this.truckColorCode(L, markerInfo, "#d4380d")
                              : this.truckColorCode(L, markerInfo, "#000"),
              }
            )
              .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>")
              .addTo(this.map);
          }
        });
        return true;
      }

      if (this.props.ownerStageId) {
        mapData?.map(async (markerInfo) => {
          let msg =
            "<p class='parapopup'><b class='para_line'>" +
            markerInfo?.carrier_name +
            "</b></p>";
          if (markerInfo["lat"] && markerInfo["lng"]) {
            L.marker([markerInfo["lat"], markerInfo["lng"]], {
              draggable: true,
              icon:
                markerInfo.stageId === "Uncontacted Lead"
                  ? this.truckStageCode(L, "#333")
                  : markerInfo.stageId === "Pending Carrier Packet + Insurance"
                    ? this.truckStageCode(L, "#e07c2f")
                    : markerInfo.stageId === "Pending Insurance"
                      ? this.truckStageCode(L, "#6a00ff")
                      : markerInfo.stageId === "Pending Carrier Packet"
                        ? this.truckStageCode(L, "#d4380d")
                        : markerInfo.stageId === "Carrier Data Input"
                          ? this.truckStageCode(L, "#8d9ca6")
                          : markerInfo.stageId === "Welcome Call"
                            ? this.truckStageCode(L, "#1890ff")
                            : markerInfo.stageId === "Approved Carrier"
                              ? this.truckStageCode(L, "green")
                              : markerInfo.stageId === "Archived Carrier"
                                ? this.truckStageCode(L, "brown")
                                : markerInfo.stageId === "Stalled Application"
                                  ? this.truckStageCode(L, "#f7df7c")
                                  : this.truckStageCode(L, "#000"),
            })
              .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>")
              .addTo(this.map);
          }
        });
        return true;
      }
      mapData?.map(async (m) => {
        let msg = "";
        m?.carrierDetails?.map((markerInfo) => {
          msg += markerInfo.truckId
            ? "<p class='parapopup'><b class='para_line'>TNB" +
            markerInfo?.truckId +
            "," +
            markerInfo?.truck_code +
            "&nbsp;&nbsp; " +
            markerInfo?.carrier_name +
            "</b></p>"
            : "";
          m.contactDetails?.map(
            (c) =>
              c?.isPrimaryContact &&
              (msg += c.contact_name
                ? "<p class='parapopup'>" +
                c?.contact_name +
                "&nbsp;&nbsp; " +
                c?.contact_number +
                "</p>"
                : "")
          );

          msg +=
            "<p class='parapopup' style='width:100%;justify-content: center;'><b class='para_line' style='width:100%;justify-content: center;'>Current Dispatch</b></p>";

          // Dispatch Tool
          let marker = new L.marker([markerInfo["lat"], markerInfo["lng"]], {
            draggable: true,
            icon: this.props.dispatchToolMap
              ? this.dispatchToolColorCode(L, markerInfo)
              : markerInfo.truck_type === "Round Bottom"
                ? this.truckColorCode(L, markerInfo, "#7d04c2")
                : markerInfo.truck_type === "End Dump"
                  ? this.truckColorCode(L, markerInfo, "#008000")
                  : markerInfo.truck_type === "Belly Dump"
                    ? this.truckColorCode(L, markerInfo, "#ba1c1c")
                    : markerInfo.truck_type === "Super Dump"
                      ? this.truckColorCode(L, markerInfo, "#ffa500")
                      : markerInfo.truck_type === "15+ Ton Dump Truck"
                        ? this.truckColorCode(L, markerInfo, "#1967d2")
                        : markerInfo.truck_type === "10+ Ton Dump Truck"
                          ? this.truckColorCode(L, markerInfo, "#ffcd2c")
                          : this.truckColorCode(L, markerInfo, "#000"),
          })
            // .bindTooltip("12", {
            //   permanent: true,
            //   // direction: "center",
            //   // className: "my-labels",
            // })
            .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>")
            .addTo(this.map);
          // this.map.addLayer(markers);
          // .bindPopup(msg, markerInfo.id);
          // this.marker[markerInfo["truckId"]] = marker;
          marker.on("dragend", function (e) {
            var marker = e.target;
            var position = marker.getLatLng();
            checkData(position, m, markerInfo);
          });
          return true;
        });
      });
    } else {
      this.map.eachLayer((layer) => {
        if (layer.feature !== undefined) {
          this.map.removeLayer(layer);
        }
      });
    }
  };

  onFinish = (values, assignData) => {
    // let data = [];
    // data = this.state.assignTruck;
    // this.props?.quarryData?.map((q) => {
    //   if (values === q.id) {
    //     data.push({
    //       route: q,
    //       truck: this.state.truckData,
    //       truckInfo: this.state.truckInfo,
    //       materialData: this.state.materialData,
    //     });
    //   }
    //   return true;
    // });
    let dragData = this.state.dragData.length > 0 ? this.state.dragData : [];
    dragData.push(assignData);
    // console.log(dragData, "dragData");
    this.setState({
      dragData,
      assignTruck: {},
      truckData: "",
    });
    // this.marker[this.state.truckInfo?.truckId].remove();
  };

  onSelectAccountTerms = (e) => {
    this.setState({ selectedTerm: e });
    this.formRef.current.setFieldsValue({
      remarkAccountTerms: "",
    });
  };

  deleteTruck = (truck, singletruck) => {
    let { dragData } = this.state;
    let newData = [];
    dragData?.map((d) => {
      if (d?.truckInfo?.truckId !== singletruck?.truckId) {
        newData.push(d);
      }
      return true;
    });
    this.setState({
      dragData: newData,
    });
    this.marker[singletruck.truckId].addTo(this.map);
    this.marker[singletruck.truckId].setLatLng([
      singletruck?.lat,
      singletruck?.lng,
    ]);
  };

  handlePagination = async (page, pageSize, load) => {
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
  };

  // onChangecheckbox = (checkedValues) => {
  //   console.log("checked = ", checkedValues);
  // };

  submitMap = () => {
    const { dragData } = this.state;
    let submitData = {};
    dragData.forEach((d) => {
      if (!submitData[d["route"]["id"]]) {
        submitData[d["route"]["id"]] = { material: [] };
        d["route"]["material"].forEach((rm) => {
          rm["assignTrucks"] = rm["assignTrucks"] ? rm["assignTrucks"] : [];
          rm["assignTrucksInfo"] = rm["assignTrucksInfo"]
            ? rm["assignTrucksInfo"]
            : [];
          rm["assigned"] = rm["assigned"] ? rm["assigned"] : false;
          submitData[d["route"]["id"]]["material"].push(rm);
        });
      }
      submitData[d["route"]["id"]]["material"].forEach((m) => {
        d["materialData"].map((e) => {
          if (
            m.id === e["id"] &&
            m["assignTrucks"].indexOf(d["truckInfo"]["truckId"]) === -1
          ) {
            m["assignTrucks"].push(d["truckInfo"]["truckId"]);
            m["assignTrucksInfo"].push(d["truckInfo"]);
            m["assigned"] = true;
          }
          return true;
        });
      });
    });

    let data = {
      entityData: submitData,
    };
    if (Object.keys(submitData).length > 0) {
      updateTotal(appConfig["entityNames"]["updateDirection"], data)
        .then((res) => {
          if (res.success) {
            const { dragData } = this.state;
            let truckNums = dragData?.map((d) => d?.truckInfo?.truckId);
            // console.log(truckNums);
            // console.log(res, "result");
            this.props.handleCancel();
            message.info(
              `${truckNums?.map((e) => e)} trucks are assigned to Job ${dragData[0]?.route?.jobNumber
              }`
            );
          } else {
            // window.location.reload();
            this.props.handleCancel();
          }
        })
        .catch((err) => {
          console.log(err);
          this.props.handleCancel();
        });
    } else {
      message.error("Please assign any one truck!");
    }
  };

  onClickCollapse = (data, type) => {
    // console.log(data, "data");
    this.map?.remove();
    if (type === "drop") {
      this.dispatchMap(true, {
        lat: data?.dropOffLatLng?.lat,
        lng: data?.dropOffLatLng?.lng,
      });
    } else {
      this.dispatchMap(true, {
        lat: data?.pickUpLatLng?.lat,
        lng: data?.pickUpLatLng?.lng,
      });
    }
  };

  onSelectMaterialCat = (e, type) => {
    // console.log(e);
    if (type === "edit") {
      const { materialList } = this.state;
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    } else {
      const { materialList } = this.state;
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e.target.value === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      if (e.target.value) {
        this.formRef.current.setFieldsValue({ material: undefined, price: "" });
        this.setState({
          materialSelectedData: "",
        });
      }
      this.setState({
        selectedMaterialType: e?.target?.value,
        finalMaterialData: materialsData,
      });
    }
  };

  handleChangeMaterial = (option, value) => {
    let materialData = [];
    this.state.assignTruck?.route?.material?.map((mt) => {
      option.map((e) => {
        if (mt.id === e) {
          materialData.push(mt);
        }
        return true;
      });
      return true;

      // if (mt.id === option) {
      //   this.setState({
      //     materialData: mt,
      //     assignTruck: {
      //       ...this.state.assignTruck,
      //       materialData: mt,
      //     },
      //   });
      // }
      // return true;
    });
    this.setState({
      materialData,
      assignTruck: {
        ...this.state.assignTruck,
        materialData,
      },
    });
  };

  handleJobChange = (e) => {
    this.props?.quarryData?.map((q) => {
      if (e === q.id) {
        if (
          q["truckType"].filter((e) => this.state.truckInfo.truck_type === e)
            .length > 0
        ) {
          let materialData = q.material.filter((e) => e.assigned !== true);
          let data = [];
          data.push(materialData[0]);
          this.setState({
            assignTruck: {
              ...this.state.assignTruck,
              route: q,
              materialData: data,
            },
            materialData: data,
          });
          this.formRef.current.setFieldsValue({
            material: data[0].id,
          });
        } else {
          message.error(
            `Please Select Truck Type: ${q["truckType"].join(", ")}`
          );
        }
      }
      return true;
    });
  };

  cancel = () => {
    this.props.cancel();
  };

  addNotes = (values) => {
    const { recordNotesData } = this.state;
    this.setState({ notesPopup: false });
    if (recordNotesData !== "") {
      let filteredData = this.state.notesData.filter(
        (c) => c.id !== recordNotesData?.id
      );
      this.setState({
        notesData: filteredData,
      });
    }

    let data = {
      entityData: {
        id: uuidv4(),
        date:
          recordNotesData?.date !== undefined
            ? recordNotesData?.date
            : moment().format("MM-DD-YYYY LT"),
        typeofNotes: values["type"],
        notes: values["notes"],
      },
    };
    // console.log(data);
    let notesInfo =
      this.state?.notesData?.length > 0 ? this.state?.notesData : [];
    notesInfo.push(data["entityData"]);
    this.setState({ notesData: notesInfo });
    message.info("Notes Added Successfully");
  };

  editNotes = (data) => {
    this.setState({
      notesPopup: true,
      recordNotesData: data,
    });
  };

  deleteNotes = (id) => {
    let notesInfo = this.state.notesData.filter((c) => c?.id !== id);
    this.setState({
      notesData: notesInfo,
    });
    message.info("Notes Deleted Successfully!");
  };

  closeUserModal = (userId, recordMapData) => {
    this.setState((prevState) => ({
      openModal: prevState?.openModal?.filter((user) => user !== userId),
      onClickEdit: false,
      recordMapData,
      materialAttachment: [],
      selectedMaterial: [],
    }));
  };

  showDrawerEditData = (recordMapData) => {
    const {
      selectedMaterial,
      citySearch,
      contactInfo,
      selectedTerm,
      notesData,
      materialList,
      materialCategory,
    } = this.state;
    return (
      <Form
        layout="vertical"
        onFinish={this.onFinishDrawer}
        id="Quarry"
        ref={this.formRef}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <span className="title_changes">Quarry Name</span>
            <Form.Item
              name="quarryName"
              rules={[
                {
                  required: true,
                  message: "Please Enter your Mine Name!",
                },
              ]}
              initialValue={recordMapData?.quarryName}
            >
              <Input
                onChange={(e) => this.setState({ quarryName: e.target.value })}
                className="form-input"
                placeholder="Please Enter your Mine Name"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <span className="title_changes">Phone</span>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Phone",
                },
              ]}
              initialValue={recordMapData?.phone}
            >
              <NumberFormat
                className="ant-input-number numberictest"
                format="### ###-####"
                style={{ width: "100%" }}
                placeholder="Please Enter Your Phone"
                onChange={(e) => this.setState({ quarryPhone: e.target.value })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <span className="title_changes">Location</span>
            <Form.Item
              name="location"
              rules={[
                {
                  required: false,
                  message: "Please Enter Your Location!",
                },
              ]}
            >
              <GooglePlacesAutocomplete
                className="form-input"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["us"],
                  },
                }}
                selectProps={{
                  citySearch,
                  defaultInputValue: `${recordMapData?.address ? recordMapData?.address : ""
                    }`,
                  placeholder: "Search Address...",
                  onChange: (address) => {
                    this.handleSelect(address);
                    this.getAddress(address);
                  },
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <span className="title_changes">Early Pay Discount</span>
            <Form.Item
              initialValue={recordMapData?.payDiscount}
              name="payDiscount"
              rules={[
                {
                  required: false,
                  message: "Please enter your Discount",
                },
              ]}
            >
              <InputNumber
                formatter={(value) => `${value}%`}
                style={{ width: "100%" }}
                placeholder="Please enter your Discount"
                className="form-input"
                onChange={(value) => this.setState({ quarryDiscount: value })}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <span className="title_changes">Account Charge Limit</span>
            <Form.Item
              initialValue={recordMapData?.accountLimit}
              name="accountLimit"
              rules={[
                {
                  required: false,
                  message: "type your Account Limit",
                },
              ]}
            >
              <NumberFormat
                className="ant-input-number numberictest"
                // format="#######"
                style={{ width: "100%" }}
                prefix={"$ "}
                suffix={".00"}
                thousandSeparator
                placeholder="Please enter your Account Limit"
                onChange={(e) =>
                  this.setState({ quarryAccountLimit: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          // span={11}
          >
            <span className="title_changes">Account Terms</span>
            <Form.Item
              name="accountTerms"
              rules={[
                {
                  required: true,
                  message: "Select Your Account Terms",
                },
              ]}
              initialValue={recordMapData?.accountTerms}
            >
              <Select
                size="large"
                allowClear
                className="select_multiplequarytol"
                placeholder="Account Terms"
                showArrow
                style={{ width: "100%" }}
                onChange={this.onSelectAccountTerms}
              >
                <Option value="Net 30">Net 30</Option>
                <Option value="Custom Input">Custom Input</Option>
              </Select>
            </Form.Item>
          </Col>

          {selectedTerm === "Custom Input" && (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            // span={12}
            >
              <span className="title_changes">Account Terms (Others)</span>
              <Form.Item
                name="accountTermsOthers"
                rules={[
                  {
                    required: true,
                    message: "Please Enter New Account Terms",
                  },
                ]}
              >
                <NumberFormat
                  className="ant-input-number numberictest"
                  style={{ width: "100%" }}
                  prefix={"Net "}
                  placeholder="Please Enter New Account Terms"
                  onChange={(e) =>
                    this.setState({ accountTermsOthers: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          )}
          <>
            {selectedMaterial?.length > 0 && (
              <span
                className="title_changes"
                style={{
                  marginLeft: "14px",
                  fontSize: "17px",
                  fontWeight: "500",
                  borderBottom: "1px solid #586370",
                }}
              >
                Materials & Quantity :
              </span>
            )}

            <Col span={24}>
              {selectedMaterial?.map((m) => (
                <Popover
                  title={null}
                  trigger="hover"
                  placement="bottom"
                  content={
                    <Row>
                      <Col span={24}>
                        <span>{m.label}</span>
                      </Col>
                    </Row>
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      width: "100%",
                    }}
                    className="loop-hrline"
                  >
                    <span
                      style={{
                        margin: "0px 3px",
                        wordBreak: "break-word",
                        width: "100%",
                        // maxWidth: "625px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontSize: "16px",
                      }}
                    >
                      {m?.label}
                    </span>
                    <span
                      className="title_changes"
                      style={{
                        margin: "0px 3px",
                        wordBreak: "break-word",
                        width: "100%",
                        // maxWidth: "625px",
                        textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                        // overflow: "hidden",
                        fontSize: "16px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "100%",
                          fontWeight: "400",
                        }}
                      >
                        {` - ($${parseFloat(m?.price)?.toFixed(2)} per ${m?.quantityType
                          })`}
                      </span>
                    </span>
                    <Col>
                      <Popover
                        placement="left"
                        content={
                          <Row className="popovergrid">
                            <Col span={24}>
                              <Button
                                style={{
                                  fontSize: "15px",
                                  cursor: "pointer",
                                  border: "none",
                                  padding: "0px",
                                  textAlign: "left",
                                  width: "100%",
                                }}
                                onClick={() => this.editMaterial(m)}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: "left",
                                    height: "auto",
                                  }}
                                >
                                  <MdModeEditOutline
                                    style={{
                                      marginRight: "5px",
                                    }}
                                  />{" "}
                                  Edit
                                </span>
                              </Button>
                            </Col>
                            <Col span={24}>
                              <Button
                                style={{
                                  fontSize: "15px",
                                  cursor: "pointer",
                                  border: "none",
                                  padding: "0px",
                                  textAlign: "left",
                                  width: "100%",
                                }}
                              >
                                <Popconfirm
                                  title="Are you sure？"
                                  okText="Yes"
                                  cancelText="No"
                                  onConfirm={() => this.deleteMaterial(m?.id)}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "left",
                                    }}
                                  >
                                    <MdDelete
                                      style={{
                                        marginRight: "5px",
                                      }}
                                    />
                                    Delete
                                  </span>
                                </Popconfirm>
                              </Button>
                            </Col>
                          </Row>
                        }
                      >
                        <EllipsisOutlined
                          style={{
                            fontSize: "30px",
                            color: "grey",
                          }}
                          className="action-ellipsis-button"
                        />
                      </Popover>
                    </Col>
                  </div>
                </Popover>
              ))}
            </Col>
          </>

          <Col span={24}>
            <Form.Item name="button">
              <Button
                type="primary"
                style={{
                  width: "100%",
                  fontWeight: "500",
                  height: "40px",
                  marginTop: "25px",
                }}
                onClick={() => {
                  this.setState({
                    materialPopup: true,
                    recordMaterialData: "",
                    quantityType: "Ton",
                    materialAttachment: [],
                    selectedMaterialType: "Unknown",
                  });
                  Object.values(materialCategory["Unknown"])?.map((c, i) => {
                    this.setState({
                      subCategory1: c.subcat,
                      subCategory2: c.subTypes,
                      categoryKey: c.categoryKey,
                      subShortName: c.subShortName,
                    });
                    materialList?.forEach((f) => {
                      if (f.id === c.subTypes?.find((e) => e.id)?.id) {
                        this.setState({ materialSelectedData: f });
                      }
                    });
                    return c;
                  });
                }}
              >
                ADD MATERIAL
              </Button>
            </Form.Item>
          </Col>

          {recordMapData?.contactDetails?.length > 0 && (
            <Col span={24}>
              <span
                className="title_changes"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  borderBottom: "1px solid #586370",
                }}
              >
                Contact Details:
              </span>
            </Col>
          )}

          {contactInfo?.map((c) => (
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
                className="loop-hrline"
              >
                <Popover
                  content={
                    (c?.phone || c?.email) && (
                      <Row>
                        <Col span={24}>
                          <span>{c.phone}</span>
                        </Col>
                        <Col span={24}>
                          <span
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            {c.email}
                          </span>
                        </Col>
                      </Row>
                    )
                  }
                >
                  <span
                    className="title_changes"
                    style={{
                      margin: "0px 5px",
                      wordBreak: "break-word",
                      width: "100%",
                      maxWidth: "270px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textTransform: "capitalize",
                      cursor: "pointer",
                    }}
                  >
                    {c.contactRole
                      ? c.firstName + " " + c.lastName + ", " + c.contactRole
                      : c.firstName + " " + c.lastName}
                  </span>
                </Popover>
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row className="popovergrid">
                        <Col span={24}>
                          <Button
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              border: "none",
                              padding: "0px",
                              textAlign: "left",
                              width: "100%",
                            }}
                            onClick={() => this.editContactInfo(c)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                                height: "auto",
                              }}
                            >
                              <MdModeEditOutline
                                style={{
                                  marginRight: "5px",
                                }}
                              />{" "}
                              Edit
                            </span>
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Button
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              border: "none",
                              padding: "0px",
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => this.deleteContactInfo(c.id)}
                            >
                              <span
                                style={{
                                  color: "black",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "left",
                                }}
                              >
                                <MdDelete
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{
                        fontSize: "30px",
                        color: "grey",
                      }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </div>
            </Col>
          ))}

          <Col span={24}>
            <Form.Item name="button">
              <Button
                type="primary"
                style={{
                  width: "100%",
                  fontWeight: "500",
                  height: "40px",
                }}
                onClick={() =>
                  this.setState({
                    contactPopup: true,
                    recordContactData: "",
                  })
                }
              >
                ADD CONTACT
              </Button>
            </Form.Item>
          </Col>

          {recordMapData?.notesDetails?.length > 0 && (
            <Col span={24}>
              <span
                className="title_changes"
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  borderBottom: "1px solid #586370",
                }}
              >
                Notes:
              </span>
            </Col>
          )}

          {notesData?.map((c) => (
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
                className="loop-hrline"
              >
                <Popover
                  content={
                    (c?.date || c?.typeofNotes) && (
                      <Row>
                        <Col span={24}>
                          <span>{c.date}</span>
                        </Col>
                        <Col span={24}>
                          <span
                            style={{
                              color: "black",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            {c.typeofNotes}
                          </span>
                        </Col>
                      </Row>
                    )
                  }
                >
                  <span
                    className="title_changes"
                    style={{
                      margin: "0px 5px",
                      wordBreak: "break-word",
                      width: "100%",
                      maxWidth: "270px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textTransform: "capitalize",
                      cursor: "pointer",
                    }}
                  >
                    {c.notes}
                  </span>
                </Popover>
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row className="popovergrid">
                        <Col span={24}>
                          <Button
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              border: "none",
                              padding: "0px",
                              textAlign: "left",
                              width: "100%",
                            }}
                            onClick={() => this.editNotes(c)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                                height: "auto",
                              }}
                            >
                              <MdModeEditOutline
                                style={{
                                  marginRight: "5px",
                                }}
                              />{" "}
                              Edit
                            </span>
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Button
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              border: "none",
                              padding: "0px",
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => this.deleteNotes(c.id)}
                            >
                              <span
                                style={{
                                  color: "black",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "left",
                                }}
                              >
                                <MdDelete
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{
                        fontSize: "30px",
                        color: "grey",
                      }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </div>
            </Col>
          ))}

          <Col span={24}>
            <Form.Item name="button">
              <Button
                type="primary"
                style={{
                  width: "100%",
                  fontWeight: "500",
                  height: "40px",
                }}
                onClick={() =>
                  this.setState({
                    notesPopup: true,
                    recordNotesData: "",
                  })
                }
              >
                ADD NOTES
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  showDrawerData = (recordMapData) => {
    return (
      <>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Quarry Type
          </span>
          <a
            href={`/app/quarry-profile/${recordMapData.id}`}
            className="title_changes"
            style={{ float: "right", textDecoration: "underline" }}
          >
            View Profile
          </a>
        </Col>
        <Col span={24} className="detail-ans">
          {recordMapData?.quarryType &&
            recordMapData?.quarryType?.map((e) => <li>{e?.key}</li>)}
        </Col>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Quarry Name
          </span>
        </Col>
        <Col style={{ marginBottom: "1vw" }}>
          <a
            href={`/app/quarry-profile/${recordMapData.id}`}
            className="title_changes"
          >
            {recordMapData?.quarryName}
          </a>
        </Col>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Contact Name
          </span>
        </Col>
        <Col>
          <p className="title_changes">
            {recordMapData?.contactName
              ? recordMapData?.contactName
              : recordMapData?.contactDetails
                ? recordMapData?.contactDetails?.map((e) => (
                  <div>{e?.firstName + " " + e?.lastName}</div>
                ))
                : ""}
          </p>
        </Col>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Phone
          </span>
        </Col>
        <Col>
          <p className="title_changes">{recordMapData?.phone}</p>
        </Col>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Location
          </span>
        </Col>
        <Col>
          <p className="title_changes">
            {recordMapData?.address ? recordMapData?.address : "None"}
          </p>
        </Col>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Co-ordinates
          </span>
        </Col>
        <Col>
          <p className="title_changes">
            {recordMapData?.lat
              ? recordMapData?.lat + ", " + recordMapData?.lng
              : "None"}
          </p>
        </Col>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Early Pay Discount
          </span>
        </Col>
        <Col>
          <p className="title_changes">
            {recordMapData?.payDiscount
              ? `${recordMapData?.payDiscount} %`
              : "None"}
          </p>
        </Col>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Account Charge Limit
          </span>
        </Col>
        <Col>
          <p className="title_changes">
            {recordMapData?.accountLimit ? recordMapData?.accountLimit : "None"}
          </p>
        </Col>
        <Col span={24}>
          <span
            className="detail-heading table-font-mobile"
            style={{
              fontSize: "16px",
              borderBottom: "1px solid #bababa",
              width: "fit-content",
            }}
          >
            Account Terms
          </span>
        </Col>
        <Col>
          <p className="title_changes">
            {recordMapData?.accountTerms ? recordMapData?.accountTerms : "None"}
          </p>
        </Col>

        <Col>
          <Col span={24}>
            <span
              className="detail-heading table-font-mobile"
              style={{
                fontSize: "16px",
                borderBottom: "1px solid #bababa",
                width: "fit-content",
              }}
            >
              Materials & Quantity :
            </span>
          </Col>
          {recordMapData?.material?.length > 0 ? (
            <Col span={24}>
              <div
                style={{
                  flexWrap: "nowrap",
                  width: "100%",
                }}
                className="loop-hrline"
              >
                {recordMapData?.material?.map((e) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        width: "100%",
                      }}
                      className="loop-hrline"
                    >
                      <span
                        className="title_changes"
                        style={{
                          margin: "0px 3px",
                          wordBreak: "break-word",
                          width: "100%",
                          textOverflow: "ellipsis",
                          fontSize: "16px",
                        }}
                      >
                        {e?.label}
                        <span
                          style={{
                            fontSize: "100%",
                            fontWeight: "400",
                          }}
                        >
                          {` - ($${e?.price?.toFixed(2)} per ${e?.quantityType
                            })`}
                        </span>
                      </span>
                    </div>
                  </>
                ))}
              </div>
            </Col>
          ) : (
            <Row>
              <span
                className="title_changes materialadded"
                style={{
                  margin: "0px 3px",
                  wordBreak: "break-word",
                  width: "100%",
                  textOverflow: "ellipsis",
                  fontSize: "16px",
                }}
              >
                None
              </span>
            </Row>
          )}
        </Col>

        <Col>
          <Col span={24}>
            <span
              className="detail-heading table-font-mobile"
              style={{
                fontSize: "16px",
                borderBottom: "1px solid #bababa",
                width: "fit-content",
              }}
            >
              Contacts :
            </span>
          </Col>
          {recordMapData?.contactDetails?.length > 0 ? (
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  width: "100%",
                }}
                className="loop-hrline"
              >
                {recordMapData?.contactDetails?.map((c) => (
                  <Popover
                    content={
                      (c?.phone || c?.email) && (
                        <Row>
                          <Col span={24}>
                            <span>{c.phone}</span>
                          </Col>
                          <Col span={24}>
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              {c.email}
                            </span>
                          </Col>
                        </Row>
                      )
                    }
                  >
                    <span
                      className="title_changes"
                      style={{
                        margin: "0px 5px",
                        wordBreak: "break-word",
                        width: "100%",
                        maxWidth: "270px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textTransform: "capitalize",
                        cursor: "pointer",
                      }}
                    >
                      {c.contactRole
                        ? c.firstName + " " + c.lastName + ", " + c.contactRole
                        : c.firstName + " " + c.lastName}
                    </span>
                  </Popover>
                ))}
              </div>
            </Col>
          ) : (
            <Row>
              <span
                className="title_changes materialadded"
                style={{
                  margin: "0px 3px",
                  wordBreak: "break-word",
                  width: "100%",
                  textOverflow: "ellipsis",
                  fontSize: "16px",
                }}
              >
                None
              </span>
            </Row>
          )}
        </Col>

        <Col>
          <Col span={24}>
            <span
              className="detail-heading table-font-mobile"
              style={{
                fontSize: "16px",
                borderBottom: "1px solid #bababa",
                width: "fit-content",
              }}
            >
              Notes :
            </span>
          </Col>
          {recordMapData?.notesDetails?.length > 0 ? (
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  width: "100%",
                }}
                className="loop-hrline"
              >
                {recordMapData?.notesDetails?.map((c) => (
                  <Popover
                    content={
                      (c?.date || c?.typeofNotes) && (
                        <Row>
                          <Col span={24}>
                            <span>{c.date}</span>
                          </Col>
                          <Col span={24}>
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              {c.typeofNotes}
                            </span>
                          </Col>
                        </Row>
                      )
                    }
                  >
                    <span
                      className="title_changes"
                      style={{
                        margin: "0px 5px",
                        wordBreak: "break-word",
                        width: "100%",
                        maxWidth: "270px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textTransform: "capitalize",
                        cursor: "pointer",
                      }}
                    >
                      {c?.notes}
                    </span>
                  </Popover>
                ))}
              </div>
            </Col>
          ) : (
            <Row>
              <span
                className="title_changes materialadded"
                style={{
                  margin: "0px 3px",
                  wordBreak: "break-word",
                  width: "100%",
                  textOverflow: "ellipsis",
                  fontSize: "16px",
                }}
              >
                None
              </span>
            </Row>
          )}
        </Col>
      </>
    );
  };

  addCategory = (values) => {
    const {
      selectedMaterialType,
      subCategory1,
      materialList,
      categoryKey,
      subShortName,
      subCategory2,
    } = this.state;

    let duplicateMaterialValue = subCategory2?.filter(
      (e) => e.subType === values["materialsub2"]
    );
    let updateSub2 = subCategory2?.filter((e) => e.subType === "");
    let data = {};
    if (duplicateMaterialValue?.length === 0) {
      if (updateSub2?.length > 0) {
        materialList?.forEach((e) => {
          if (e.id === updateSub2[0].id) {
            e[
              "materialValue"
            ] = `${selectedMaterialType} - ${subCategory1} - ${values["materialsub2"]}`;
            e["subCategory"] = `${selectedMaterialType} - ${subCategory1}`;
            e["label"] = `${subCategory1} - ${values["materialsub2"]}`;
          }
        });
      } else {
        data = {
          materialValue: `${selectedMaterialType} - ${subCategory1} - ${values["materialsub2"]}`,
          subCategory: `${selectedMaterialType} - ${subCategory1}`,
          categoryKey: categoryKey,
          id: uuidv4(),
          label: `${subCategory1} - ${values["materialsub2"]}`,
          category: selectedMaterialType,
          statusCode: true,
          subShortName: subShortName,
        };
        materialList?.push(data);
      }

      let submitData = {
        entityData: {
          materialsNewList: materialList,
          typeofDropDown: "material",
        },
      };

      // Adding SubCategory2 in Materials
      let sub2 = {
        subType: values["materialsub2"],
        id: updateSub2?.length > 0 ? updateSub2[0].id : data["id"],
      };
      if (updateSub2?.length > 0) {
        subCategory2[0]["subType"] = values["materialsub2"];
      } else {
        subCategory2.push(sub2);
      }
      this.setState({
        addCategory: false,
        subCategory2,
      });
      // Updating New Material values
      this.props.actionupdateDropDownOptions(
        this.props.materials[0]?.id,
        submitData
      );
      message.success("Material Added successfully!");
      this.onSelectMaterial(sub2["id"]);
      this.formRef.current.setFieldsValue({
        subCategory2: sub2["id"],
      });
    } else {
      message.error("Material name already exists!");
    }
  };

  uploadTicketPdf = async (info, type) => {
    const { recordDocData, quoteAttachment, materialAttachment } = this.state;
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              let uniqueId = uuidv4();
              Storage.put(
                `Quarries/${uuidv4()}/${uniqueId}`,
                info.file.originFileObj,
                {
                  contentType: info.file.type,
                }
              ).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  if (type === "materialPicture") {
                    let materialAttachmentInfo =
                      materialAttachment?.length > 0 ? materialAttachment : [];
                    materialAttachmentInfo.push({
                      id: uniqueId,
                      materialAttachment: urlResult,
                      quoteAttachmentId: result?.key,
                      fileType: info.file.type,
                      fileName: info.file.name,
                    });
                    this.setState({
                      materialAttachment: materialAttachmentInfo,
                      imageLoading: false,
                      loading: false,
                    });
                  }
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                percentage: 0,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        if (recordDocData) {
          let docData = recordDocData.docAttachment.filter(
            (e) => e.id !== info.file.id
          );
          recordDocData["docAttachment"] = docData;
          this.setState({
            quoteAttachment: docData,
            recordDocData,
          });
        } else {
          let docData = quoteAttachment.filter(
            (e) => e.quoteAttachmentId !== info.file.uid
          );
          this.setState({
            quoteAttachment: docData,
          });
        }
        this.setState({
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState({
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
    }
  };

  findTruckCount = (status) => {
    const { mapData } = this.state;
    const { dispatchTool } = this.props;
    let trucksInfo = [];
    if (dispatchTool) {
      trucksInfo = mapData.flatMap(e => e.carrierDetails);
    } else {
      trucksInfo = mapData;
    }
    let result = [];
    if (status !== "Uncontacted") {
      result = trucksInfo?.filter((e) => e.dispatchToolStatus === status);
    } else {
      result = trucksInfo?.filter(
        (e) =>
          e.dispatchToolStatus !== "Available" &&
          e.dispatchToolStatus !== "Contacted"
      );
    }
    return result;
  };

  showRouteData = (routeData) => {
    return (
      <Row gutter={[24, 0]}>
        <Col span={24} className="textfont-route">
          <Row style={{ width: "100%" }}>
            <Col span={4}>
              <Row style={{ width: "100%" }}>
                <b>Job #:</b>
                <p> {routeData?.jobNumber + ","}</p>
              </Row>
            </Col>
            <Col span={14}>
              <Row style={{ width: "100%" }}>
                <b>Job Name:</b>
                <p>{routeData?.jobName}</p>
              </Row>
            </Col>
            <Col span={6}>
              <Row style={{ width: "100%" }}>
                <b>Order #:</b>
                <p>{routeData?.orderNumber}</p>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="textfont-route">
          <Row style={{ width: "100%" }}>
            <Col span={4}>
              <b>Customer Name:</b>
            </Col>
            <Col span={20}>
              <p>{routeData?.companyName}</p>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="textfont-route">
          <Row style={{ width: "100%" }}>
            <Col span={4}>
              <b>Job Type:</b>
            </Col>
            <Col span={20}>
              <p>{routeData?.jobType}</p>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="textfont-route tableroute">
          <table>
            <thead>
              <tr>
                <th>Job Contact Name</th>
                <th>Role</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="tablespp">
                    {routeData?.multipleContacts?.map((e) => (
                      <span>{e?.firstName + " " + e?.lastName}</span>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="tablespp">
                    {routeData?.contactRole
                      ? routeData?.contactRole
                      : routeData?.multipleContacts?.map((e) => (
                        <span>{e?.contactRole}</span>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="tablespp">
                    {routeData?.phone
                      ? routeData?.phone
                      : routeData?.multipleContacts?.map((e) => (
                        <span>{e?.phone}</span>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="tablespp">
                    {routeData?.email
                      ? routeData?.email
                      : routeData?.multipleContacts?.map((e) => (
                        <span>{e?.email}</span>
                      ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>

        {routeData?.jobType !== "Trucking Job" && (
          <Col span={24} className="textfont-route">
            <Row style={{ width: "100%" }}>
              <Col span={4}>
                <b>Material Type:</b>
              </Col>
              <Col span={20}>
                <p>
                  {routeData?.material?.filter(e => e?.category)?.map((m) => (
                    <div className="commagrid">
                      {`${m?.quantity}` +
                        `${"  " + m?.quantityType + " "}` +
                        " of " +
                        m?.value}
                    </div>
                  ))}
                </p>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    )
  }

  assignJobFinish = () => {
    const { routeData, mapData } = this.state;
    let singleTruck = mapData[0]?.carrierDetails[0];
    singleTruck["payment_terms"] = mapData[0]?.payment_terms;
    singleTruck["carrier_name"] = mapData[0]?.carrier_name;
    singleTruck["contact_Info"] = mapData[0]?.contactDetails;
    if (routeData?.material?.length > 0) {
      routeData.material?.forEach((element) => {
        element["assignTrucks"] = [singleTruck?.truckId]
        element["assignTrucksInfo"] = [singleTruck];
        element["assigned"] = true;
      });
      this.setState({
        loading: true,
      });

      let data = {
        entityData: {
          material: routeData.material,
          assigned: true,
          dispatchStatus: "Completed",
          dispatchCompletedDate: moment().format("l"),
        },
      };
      console.log(data, 'data');
      update("orders", routeData?.id, data)
        .then((res) => {
          if (res.success) {
            this.props.handleCancel();
            message.info(
              `${singleTruck?.truckId} truck are assigned to Job ${routeData?.jobNumber
              }`
            );
            this.handleCancelAssignJob();
          } else {
            this.props.handleCancel();
            this.handleCancelAssignJob();
          }
        })
        .catch((err) => {
          console.log(err);
          this.props.handleCancel();
          this.handleCancelAssignJob();
        });
    } else {
      message.error("Please assign any one truck!");
      this.handleCancelAssignJob();
    }
  }

  handleCancelAssignJob = () => {
    this.setState({
      showJobDispatch: false,
      routeData: "",
      loading: false,
      truckInfo: "",
      truckData: "",
      assignTruck: {}
    })
  }

  render() {
    const {
      diameter,
      subCategory1,
      subCategory2,
      imageLoading,
      materialAttachment,
      currentPage,
      perpageSize,
      minValue,
      maxValue,
      recordMaterialData,
      materialPopup,
      openModal,
      loading,
      onClickEdit,
      routePopUp,
      routeData,
      selectedMaterialType,
      newRole,
      contactPopup,
      recordContactData,
      dragData,
      quantityType,
      notesPopup,
      recordNotesData,
      addCategory,
      materialCategory,
      showJobDispatch
    } = this.state;
    const { dispatchToolMap, showDispatchMap, dispatchTool } = this.props;
    const quarryData = this.state?.quarryData?.filter(
      (e) => e?.assigned !== true
    );
    const selectAfter = (
      <Select
        defaultValue={quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    return (
      <div
        style={{
          height: this.props.singleValues ? "100%" : "100%",
          width: "100%",
          textAlign: "left",
        }}
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <Loading enableLoading={loading} />

        {this.props.stageId ? (
          <Row style={{ width: "100%", height: "100%" }}>
            <Col
              xxl={{ span: 20 }}
              xl={{ span: 18 }}
              lg={{ span: 17 }}
              md={{ span: 15 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div
                className="maintwistednail"
                id={this.ID}
                style={{ height: "100%", width: "100%" }}
              />
            </Col>
            <Col
              xxl={{ span: 4 }}
              xl={{ span: 6 }}
              lg={{ span: 7 }}
              md={{ span: 9 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Card
                style={{ height: "75vh", overflow: "auto" }}
                bodyStyle={{ padding: "10px" }}
                className="cardheightreduc"
              >
                <Row>
                  <Col span={24} style={{ margin: "10px 0px" }}>
                    <ul className="listicon-color">
                      <li>
                        {/* <img src={location} alt="" style={{}}/></span> */}

                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#333" }}
                        />
                        <p>Job Lead</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#6a00ff" }}
                        />
                        {/* <img src={location} alt="" /> */}
                        <p>Contacted</p>
                      </li>

                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#237804" }}
                        />
                        {/* <img src={location} alt=""  /> */}

                        <p>Build Quote</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#f7df7c" }}
                        />
                        {/* <img src={location} alt="" /> */}

                        <p>Quote Delivered</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "brown" }}
                        />
                        {/* <img src={location} alt="" /> */}

                        <p>Quote Accepted </p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#d4380d" }}
                        />
                        {/* <img src={location} alt="" /> */}

                        <p> Dispatch Approved </p>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <> </>
        )}

        {this.props.ownerStageId ? (
          <Row style={{ width: "100%", height: "100%" }}>
            <Col
              xxl={{ span: 20 }}
              xl={{ span: 18 }}
              lg={{ span: 17 }}
              md={{ span: 15 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div
                className="maintwistednail"
                id={this.ID}
                style={{ height: "100%", width: "100%" }}
              />
            </Col>
            <Col
              xxl={{ span: 4 }}
              xl={{ span: 6 }}
              lg={{ span: 7 }}
              md={{ span: 9 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Card
                style={{ height: "75vh", overflow: "auto" }}
                bodyStyle={{ padding: "10px" }}
                className="cardheightreduc"
              >
                <Row>
                  <Col span={24} style={{ margin: "10px 0px" }}>
                    <ul className="listicon-color">
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#333" }}
                        />
                        <p>Uncontacted Lead</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#e07c2f" }}
                        />
                        <p>CarrierPacket + Insurance</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#6a00ff" }}
                        />
                        <p>Pending Insurance</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#d4380d" }}
                        />
                        <p>Pending Carrier Packet</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#8d9ca6" }}
                        />
                        <p>Carrier Data Input</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#1890ff" }}
                        />
                        <p> Welcome Call </p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "green" }}
                        />
                        <p>Approved Call</p>
                        <p>Approved Carrier</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "brown" }}
                        />
                        <p>Archived Carrier</p>
                      </li>
                      <li>
                        <TfiLocationPin
                          style={{ fontSize: "45px", color: "#f7df7c" }}
                        />
                        <p>Stalled Application</p>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {this.props.truck ? (
          <Row style={{ width: "100%", height: "100%" }}>
            <Col
              xxl={{ span: 20 }}
              xl={{ span: 18 }}
              lg={{ span: 17 }}
              md={{ span: 15 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div
                className="maintwistednail"
                id={this.ID}
                style={{ height: "100%", width: "100%" }}
              />
            </Col>
            <Col
              xxl={{ span: 4 }}
              xl={{ span: 6 }}
              lg={{ span: 7 }}
              md={{ span: 9 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Card
                style={{ height: "75vh", overflow: "auto" }}
                bodyStyle={{ padding: "10px" }}
                className="cardheightreduc"
              >
                <Row>
                  {dispatchToolMap ? (
                    <Col span={24} style={{ margin: "10px 0px" }}>
                      <ul className="listicon-color">
                        <li>
                          <img src={truckyellow} alt="" />
                          <p>
                            Contacted (
                            {this.findTruckCount("Contacted")?.length})
                          </p>
                        </li>
                        <li>
                          <img src={truckRedDark} alt="" />
                          <p>
                            Uncontacted (
                            {this.findTruckCount("Uncontacted")?.length})
                          </p>
                        </li>
                        <li>
                          <img src={truckgreen} alt="" />
                          <p>
                            Available (
                            {this.findTruckCount("Available")?.length})
                          </p>
                        </li>
                      </ul>
                    </Col>
                  ) : (
                    <Col span={24} style={{ margin: "10px 0px" }}>
                      <ul className="listicon-color">
                        <li>
                          <img src={truckpurple} alt="" />
                          <p>Round Bottom</p>
                        </li>
                        <li>
                          <img src={truckgreen} alt="" />
                          <p>End Dump</p>
                        </li>

                        <li>
                          <img src={truckred} alt="" />
                          <p>Belly Dump</p>
                        </li>
                        <li>
                          <img src={truckorange} alt="" />
                          <p>Super Dump</p>
                        </li>
                        <li>
                          <img src={truckblue} alt="" />
                          <p>15+ Ton Dump Truck </p>
                        </li>
                        <li>
                          <img src={truckyellow} alt="" />
                          <p>10+ Ton Dump Truck </p>
                        </li>
                        <li>
                          <img src={truckRedDark} alt="" />
                          <p>Insurance Expired</p>
                        </li>
                        <li>
                          <img src={truckblack} alt="" />
                          <p>Other</p>
                        </li>
                      </ul>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <> </>
        )}

        {!showDispatchMap && this.props.dispatchTool ? (
          <Row style={{ width: "100%", height: "100%" }}>
            <Col xxl={10} xl={10} lg={24} md={24} sm={24} xs={24}>
              <div id={this.ID} style={{ height: "100%", width: "100%" }} />
            </Col>
            <Col xxl={14} xl={14} lg={24} md={24} sm={24} xs={24}>
              <Card
                style={{ minHeight: "595px", overflow: "auto" }}
                bodyStyle={{ padding: "10px" }}
              >
                <Row>
                  <Col
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    style={{ margin: "10px 0px" }}
                  >
                    <div className="scrlchk">
                      {quarryData
                        .slice(
                          window.screen.width > 1023 ? minValue : 0,
                          window.screen.width > 1023
                            ? maxValue
                            : quarryData.length
                        )
                        .map(
                          (d) =>
                            d?.assigned !== true && (
                              <Row style={{ width: "100%" }}>
                                <Col
                                  xxl={16}
                                  xl={24}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                >
                                  <Row style={{ width: "100%" }}>
                                    <Col
                                      xxl={12}
                                      xl={12}
                                      lg={24}
                                      md={24}
                                      sm={24}
                                      xs={24}
                                    >
                                      <div className="mapbox-1view">
                                        <h5>Customer:</h5>
                                        <p>{d?.companyName}</p>
                                      </div>
                                      <div className="mapbox-1view">
                                        <h5>Job Name:</h5>
                                        <p>{d?.jobName}</p>
                                      </div>
                                      <div className="mapbox-1view">
                                        <h5>Job#:</h5>
                                        <p>{d?.jobNumber}</p>
                                      </div>
                                      <div className="mapbox-1view">
                                        <h5>Job Type:</h5>
                                        <p>{d?.jobType}</p>
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={12}
                                      xl={12}
                                      lg={24}
                                      md={24}
                                      sm={24}
                                      xs={24}
                                    >
                                      <div className="mapbox-1view">
                                        <h5>Jobsite Contact:</h5>
                                        <p>{d?.company}</p>
                                      </div>
                                      <div className="mapbox-1view">
                                        <h5>Contact Title:</h5>
                                        <p>
                                          {d?.accountsPayableContact?.map(
                                            (e) => e.contactRole
                                          )}
                                        </p>
                                      </div>
                                      <div className="mapbox-1view">
                                        <h5>Contact Phone:</h5>
                                        <p>
                                          {d?.accountsPayableContact?.map(
                                            (e) => e.phone
                                          )}
                                        </p>
                                      </div>
                                      <div className="mapbox-1view">
                                        <h5>Contact Email:</h5>
                                        <p>
                                          {d?.accountsPayableContact?.map(
                                            (e) => e.email
                                          )}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={24}
                                      xl={24}
                                      lg={24}
                                      md={24}
                                      sm={24}
                                      xs={24}
                                    >
                                      <div className="mapbox-1view">
                                        <h5>DropOff:</h5>
                                        <p>{d?.dropoff}</p>
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={12}
                                      xl={12}
                                      lg={24}
                                      md={24}
                                      sm={24}
                                      xs={24}
                                    >
                                      <div className="mapbox-1view">
                                        <h5>Material:</h5>
                                        <p>
                                          {console.log(d.material,"123")}
                                          {d.material?.length > 0
                                            ? d.material?.map((e) => e.label)
                                            : d.material[0]?.materialName}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={12}
                                      xl={12}
                                      lg={24}
                                      md={24}
                                      sm={24}
                                      xs={24}
                                    >
                                      <div className="mapbox-1view">
                                        <h5>Load:</h5>
                                        <p>
                                          {d.material?.length > 0
                                            ? d?.trucksRequested +
                                            " " +
                                            d?.trucksRequestedType
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col
                                      xxl={24}
                                      xl={24}
                                      lg={24}
                                      md={24}
                                      sm={24}
                                      xs={24}
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <div className="trucktypeallowedgrid">
                                        <h4>Truck Types Allowed:</h4>
                                      </div>
                                      <div className="typematerials">
                                        {this.props.context.trucksData?.map(
                                          (t) => (
                                            <Col
                                              // span={8}
                                              xxl={{ span: 8 }}
                                              xl={{ span: 8 }}
                                              lg={{ span: 8 }}
                                              md={{ span: 24 }}
                                              sm={{ span: 24 }}
                                              xs={{ span: 24 }}
                                            >
                                              {d?.truckType?.includes(
                                                t.value
                                              ) ? (
                                                <div
                                                  className="itemmaterial"
                                                  value={t.value}
                                                >
                                                  <CheckCircleOutlined
                                                    style={{
                                                      color: "green",
                                                      fontSize: "14px",
                                                    }}
                                                  />
                                                  <span
                                                    style={{
                                                      // color: "#8d9ca6",
                                                      marginLeft: "5px",
                                                      fontWeight: "500",
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    {t.value}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div
                                                  className="itemmaterial"
                                                  value={t.value}
                                                >
                                                  <CloseCircleOutlined
                                                    style={{
                                                      color: "#8d9ca6",
                                                      fontSize: "14px",
                                                    }}
                                                  />
                                                  <span
                                                    style={{
                                                      color: "#8d9ca6",
                                                      marginLeft: "5px",
                                                      fontSize: "14px",
                                                    }}
                                                    className="materialtxtggs"
                                                  >
                                                    {t.value}
                                                  </span>
                                                </div>
                                              )}
                                            </Col>
                                          )
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  xxl={8}
                                  xl={24}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                >
                                  <Card
                                    title={
                                      <h4 className="headingdispatcher">
                                        Trucks Assigned
                                      </h4>
                                    }
                                    className="maptitlecard"
                                    bodyStyle={{ padding: "10px" }}
                                  >
                                    <div className="mapbox-1view">
                                      <h5>Material Request:</h5>
                                      <p> {d.material?.map((e) => e.value)}</p>
                                    </div>
                                    <div className="mapbox-1view">
                                      <h5>Pickup Address:</h5>
                                      <p>
                                        {d.material?.map(
                                          (e) => e.pickUpAddress
                                        )}
                                      </p>
                                    </div>
                                    <Row style={{ width: "100%" }}>
                                      <Col
                                        xxl={24}
                                        xl={24}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                      >
                                        {dragData?.length > 0 &&
                                          dragData?.map(
                                            (e) =>
                                              e?.route?.id === d?.id && (
                                                <div className="mapbox-trucklist">
                                                  <p>
                                                    {"TNB" +
                                                      e?.truckInfo?.truckId}
                                                  </p>
                                                  <h6>
                                                    {e?.truckInfo?.truck_code}
                                                  </h6>
                                                  <p>
                                                    {e?.truck?.carrier_name}
                                                  </p>
                                                </div>
                                              )
                                          )}

                                        {/* <div className="mapbox-trucklist">
                                  <p>TNB309</p>
                                  <h6>BD</h6>
                                  <p>Cass Tranportation</p>
                                </div>
                                <div className="mapbox-trucklist">
                                  <p>TNB327</p>
                                  <h6>ED</h6>
                                  <p>FN Trucking</p>
                                </div>
                                <div className="mapbox-trucklist">
                                  <p>TNB405</p>
                                  <h6>BD</h6>
                                  <p>Jon's Trucking</p>
                                </div> */}
                                        {/* {dragData?.map((drag) =>
                                drag?.materialData?.map(
                                  <div className="mapbox-trucklist">
                                    <p>
                                      {"TNB" + drag.truckInfo?.truckId}
                                    </p>
                                    <h6>{drag.truckInfo?.truck_code}</h6>
                                    <p>{drag.truck?.carrier_name}</p>
                                  </div>
                                )
                              )} */}
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                                <Col
                                  xxl={24}
                                  xl={24}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                  style={{
                                    fontSize: "15px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <b
                                    style={{
                                      marginBottom: "0px",
                                      width: "100%",
                                    }}
                                  >
                                    Notes for Dispatcher:
                                  </b>
                                  {/* <div className="griddispactoolgrp notes"> */}
                                  <div
                                    style={{
                                      minHeight: "75px",
                                      borderRadius: "3px",
                                      // backgroundColor: "#e3e7ea",
                                      padding: "6px",
                                      // marginTop: "5px",
                                      width: "100%",
                                      border: "1px solid #ddd",
                                    }}
                                    className="detail-ans"
                                  >
                                    {d?.dispatchNotes && d?.dispatchNotes}
                                  </div>
                                  {/* </div> */}
                                </Col>
                              </Row>
                            )
                        )}
                    </div>
                  </Col>
                </Row>
                <div className="disp_fixed show-on-desktop">
                  <Pagination
                    style={{ float: "right", marginTop: "1vw" }}
                    total={
                      this.props.quarryData?.filter((d) => d.assigned !== true)
                        .length
                    }
                    showTotal={(total) => `${total} items`}
                    current={currentPage}
                    defaultPageSize={perpageSize}
                    onChange={this.handlePagination}
                    defaultCurrent={1}
                  />
                  <div className="footerdispatch">
                    <Button
                      style={{
                        height: "35px",
                        width: "125px",
                        borderRadius: "5px",
                        marginRight: "25px",
                      }}
                      htmlType="reset"
                      onClick={this.cancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        height: "35px",
                        width: "125px",
                        borderRadius: "5px",
                      }}
                      onClick={this.submitMap}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            {showDispatchMap && (
              <Row
                className={dispatchTool
                  ? "dispatch-tool-map dispatch-tool-jobAssignMap"
                  : "dispatch-tool-map dispatch-tool-jobMap"}
              >
                <Col>
                  <Button
                    style={{
                      borderRadius: "5px",
                      height: dispatchTool ? "38px": "30px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px 5px",
                    }}
                    onClick={() => this.setState({ showJobDispatch: true, routeData: "" })}
                  >
                    <span className="table-font-mobile-accounts">
                      Assign Job
                    </span>
                  </Button>
                </Col>
              </Row>
            )}
            <div id={this.ID} style={{ height: "100%", width: "100%" }} />
          </>
        )}
        {openModal?.length > 0 &&
          openModal?.map((quarry) => (
            <Drawer
              visible={true}
              maskClosable={false}
              mask={false}
              className="quarrytool_modal mapquarry"
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {quarryData?.find((user) => user.id === quarry)?.quarryName}
                  </span>
                  {openModal?.length > 1 && (
                    <div
                      className="editmapc"
                      style={{ margin: "0px", marginRight: "10px" }}
                    >
                      <Button
                        style={{ alignItems: "center" }}
                        onClick={() =>
                          this.setState({
                            openModal: [],
                          })
                        }
                      >
                        Close All
                      </Button>
                    </div>
                  )}
                </div>
              }
              onClose={() =>
                this.closeUserModal(
                  quarry,
                  quarryData.find((user) => user.id === quarry)
                )
              }
              formId="Quarry"
              destroyOnClose={true}
              footer={[
                <>
                  {!onClickEdit && (
                    <div className="editmapc">
                      <Button
                        style={{ alignItems: "center" }}
                        onClick={() =>
                          this.onClickEdit(
                            quarryData?.find((user) => user.id === quarry)
                          )
                        }
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                  {onClickEdit && (
                    <div style={{ float: "right" }}>
                      <Button
                        onClick={() => {
                          this.setState({
                            // isModalVisible: false,
                            // selectedMaterial: [],
                            onClickEdit: false,
                          });
                        }}
                        className="cancelbtnd"
                        style={{
                          height: "35px",
                          width: "100px",
                          borderRadius: "5px",
                          marginRight: "25px",
                        }}
                        type="ghost"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.loading}
                        style={{
                          height: "35px",
                          width: "100px",
                          borderRadius: "5px",
                        }}
                        form="Quarry"
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </>,
              ]}
            >
              {!onClickEdit &&
                this.showDrawerData(
                  quarryData?.find((user) => user.id === quarry)
                )}
              {onClickEdit &&
                this.showDrawerEditData(
                  quarryData?.find(
                    (user) => user.id === this.state.recordMapData?.id
                  )
                )}
            </Drawer>
          ))}

        {materialPopup && (
          <BaseModal
            title={
              recordMaterialData !== ""
                ? "Update Material Details"
                : "Add New Material"
            }
            onCancel={() => {
              this.setState({
                materialPopup: false,
                materialCheck: false,
                selectedMaterialType: "",
                subCategory2: [],
                subCategory1: "",
                materialAttachment: [],
              });
            }}
            visible={materialPopup}
            destroyOnClose={true}
            formId="addMaterial"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"40vw"}
          >
            <Form
              onFinish={this.onFinishMaterial}
              initialValues={{ remember: true }}
              id="addMaterial"
              ref={this.formRef}
            >
              <Row gutter={[24, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Material Categories</span>
                  <Form.Item
                    name="cat"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Your Material Category!",
                      },
                    ]}
                    initialValue={selectedMaterialType}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e) => {
                        this.setState({
                          selectedMaterialType: e,
                        });
                        if (e) {
                          this.formRef.current.setFieldsValue({
                            subCategory1: undefined,
                            subCategory2: undefined,
                            price: "",
                          });
                          this.setState({
                            subCategory1: "",
                            subCategory2: [],
                          });
                        }
                      }}
                    >
                      {Object.keys(materialCategory)?.map((c, i) => (
                        <Option key={i} value={c}>
                          {c}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Material Type</span>
                  <Form.Item
                    name="subCategory1"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Material Type!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.label?.split(" - ")[0] || "Unknown"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e, option) => {
                        this.setState({
                          subCategory1: e,
                          subCategory2: option.label,
                          categoryKey: option.labelprop,
                          subShortName: option.labelprop2,
                        });
                        if (e) {
                          this.formRef.current.setFieldsValue({
                            subCategory2: undefined,
                            price: "",
                          });
                        }
                      }}
                    // onChange={this.onSelectMaterial}
                    >
                      {selectedMaterialType &&
                        materialCategory[selectedMaterialType] !== undefined &&
                        Object.values(
                          materialCategory[selectedMaterialType]
                        )?.map((c, i) => (
                          <Option
                            key={i}
                            value={c?.subcat}
                            label={c?.subTypes}
                            labelprop={c?.categoryKey}
                            labelprop2={c?.subShortName}
                          >
                            {c?.subcat}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span
                    className="title_changes"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Material Name
                  </span>

                  <div className="contactname-multiple">
                    <Form.Item
                      name="subCategory2"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Material Name!",
                        },
                      ]}
                      initialValue={
                        subCategory1
                          ? recordMaterialData?.id || "Unknown"
                          : recordMaterialData?.value?.split(" - ")[2] ||
                          "Unknown"
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height select_arrow_none"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Category2"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={this.onSelectMaterial}
                      >
                        {selectedMaterialType &&
                          subCategory1 &&
                          subCategory2?.map(
                            (c, i) =>
                              c.subType !== "" && (
                                <Option key={i} value={c.id}>
                                  {c.subType}
                                </Option>
                              )
                          )}
                      </Select>
                    </Form.Item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                        padding: "8px",
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        height: "40px",
                        borderLeft: "1px solid #ddd",
                        zIndex: "+9",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (selectedMaterialType && subCategory1) {
                          this.setState({
                            addCategory: true,
                          });
                        } else {
                          message.info(
                            "Please add Material Category & Material Type"
                          );
                        }
                      }}
                    >
                      <span
                        className="title_changes"
                        style={{ fontSize: "13px", fontWeight: "500" }}
                      >
                        <PlusOutlined
                          style={{ color: "#000", marginRight: "5px" }}
                        />
                        Material
                      </span>
                    </div>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Price</span>
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Price",
                      },
                    ]}
                    style={{ marginBottom: "10px" }}
                    initialValue={recordMaterialData?.price}
                  >
                    <InputNumber
                      // prefix={"$"}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      precision={2}
                      decimalSeparator={"."} // value={quantity}
                      addonBefore={"$"}
                      addonAfter={selectAfter}
                      placeholder="Please Enter Price"
                      // addonAfter={selectAfter}
                      style={{ height: "40px", width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Testing Status</span>
                  <Form.Item
                    name="materialTest"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Testing!",
                      },
                    ]}
                    initialValue={recordMaterialData?.materialTest || "Unknown"}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A testMaterial"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.testMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Washed Status</span>
                  <Form.Item
                    name="materialStatus"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter washed status!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialStatus || "Unknown"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A statusMaterial"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.statusMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {diameter ? (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 14 }}
                    md={{ span: 18 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ marginTop: "20px" }}
                  >
                    <Row gutter={[12, 0]}>
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 14 }}
                        md={{ span: 18 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Min</span>
                        <Form.Item
                          name="materialMinDiameter"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialMinDiameter}
                          style={{ marginBottom: "10px" }}
                        >
                          {/* <InputNumber
                        formatter={(value) =>
                          value !== "" ? `${value}"` : ""
                        }
                        style={{ width: "100%", height: "40px" }}
                        placeholder="Please enter your diameter"
                        className="form-input"
                      /> */}
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            className="form-input"
                            // formatter={(value) => `${value}"`}
                            placeholder="Please enter diameter"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 14 }}
                        md={{ span: 18 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="title_changes">Max</span>
                          <Switch
                            checkedChildren={"Diameter"}
                            unCheckedChildren={"Range"}
                            onChange={(e) => this.handleCheckStatus(e)}
                            checked={diameter}
                          >
                            Size Range
                          </Switch>
                        </span>
                        <Form.Item
                          name="materialMaxDiameter"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialMaxDiameter}
                          style={{ marginBottom: "10px" }}
                        >
                          {/* <InputNumber
                        formatter={(value) =>
                          value !== "" ? `${value}"` : ""
                        }
                        style={{ width: "100%", height: "40px" }}
                        placeholder="Please enter your diameter"
                        className="form-input"
                      /> */}
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            className="form-input"
                            // formatter={(value) => `${value}"`}
                            placeholder="Please enter diameter"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 14 }}
                    md={{ span: 18 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ marginTop: "20px" }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="title_changes">Diameter</span>
                      <Switch
                        checkedChildren={"Diameter"}
                        unCheckedChildren={"Range"}
                        onChange={(e) => this.setState({ diameter: e })}
                      >
                        Size Range
                      </Switch>
                    </span>
                    <Form.Item
                      name="materialDiameter"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Diameter!",
                        },
                      ]}
                      initialValue={
                        recordMaterialData?.materialDiameter || "Unknown"
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      <Input
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        className="form-input"
                        // formatter={(value) => `${value}"`}
                        placeholder="Please enter diameter"
                      />
                      {/* <InputNumber
                    formatter={(value) =>
                      value !== "" ? `${value}"` : ""
                    }
                    style={{ width: "100%", height: "40px" }}
                    placeholder="Please enter your diameter"
                    className="form-input"
                  /> */}
                    </Form.Item>
                  </Col>
                )}

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "20px" }}
                >
                  <span className="title_changes">Supply</span>
                  <Form.Item
                    name="materialSupply"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter supply!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialSupply || "Unknown Supply"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Supply"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Material Tax</span>
                  <Form.Item
                    name="materialTax"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Material Tax!",
                      },
                    ]}
                    initialValue={recordMaterialData?.materialTax || "Unknown"}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value="Unknown">Unknown</Option>
                      <Option value="Natural"> Natural</Option>
                      <Option value="Manufactured">Manufactured</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Material Description</span>
                  <Form.Item
                    name="materialNotes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter materialNotes!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialNotes
                        ? recordMaterialData?.materialNotes
                        : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter materialNotes"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                // span={24}
                >
                  <span className="title_changes">Material Picture</span>
                  <Form.Item
                    name="materialPicture"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach materialPicture",
                      },
                    ]}
                  >
                    <Spin spinning={imageLoading}>
                      <Dragger
                        onChange={(e) =>
                          this.uploadTicketPdf(e, "materialPicture")
                        }
                        customRequest={dummyRequest}
                        listType="picture"
                        multiple={true}
                        className="drag-ticket"
                        showUploadList={false}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>

                      <div style={{ marginTop: "10px" }}>
                        {materialAttachment?.length > 0 &&
                          materialAttachment?.map((file) => (
                            <div style={{ marginBottom: "5px" }}>
                              <Card
                                key={file.uid}
                                bodyStyle={{ padding: "4px 10px 4px 4px" }}
                                width="100%"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {file.fileType === "image/jpeg" ||
                                    file.fileType === "image/png" ? (
                                    <img
                                      src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${file?.quoteAttachmentId}`}
                                      alt="Avatar"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        objectFit: "fill",
                                        marginRight: "10px",
                                      }}
                                    />
                                  ) : (
                                    <FilePdfOutlined
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "35px",
                                      }}
                                    />
                                  )}
                                  <span>{file?.fileName}</span>
                                  <Button
                                    icon={
                                      <DeleteOutlined
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      />
                                    }
                                    onClick={() =>
                                      this.handleFileDelete(
                                        file,
                                        "materialPicture"
                                      )
                                    }
                                    className="upload_Delete_Icon"
                                  />
                                </div>
                              </Card>
                            </div>
                          ))}
                      </div>
                    </Spin>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {showJobDispatch && (
          <BaseModal
            title={"Assign Job"}
            onCancel={() => this.handleCancelAssignJob()}
            formId="assignJob"
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "25%"
                : "900px"
            }
          >
            <Form onFinish={this.assignJobFinish} id={"assignJob"} ref={this.assignJobRef}>
              <Col span={24}>
                <Form.Item
                  name="assignJob"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Job",
                    },
                  ]}
                  style={{ marginBottom: "0px" }}
                  className="addontext"
                  initialValue={routeData?.id}
                >
                  <Select
                    // mode="multiple"
                    allowClear
                    style={{
                      fontWeight: "300",
                      minHeight: "40px",
                    }}
                    placeholder="Please Select Your Job"
                    className="select_Height"
                    // className="select_multiplequarytol"
                    filterOption={(input, option) =>
                      option?.labelNew
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option?.key.indexOf(input) > -1
                    }
                    onChange={(e, option) => { this.setState({ routeData: option?.label }) }}
                  >
                    {quarryData?.map((c) => (
                      <Option
                        value={c?.id}
                        key={c?.jobNumber}
                        label={c}
                      >
                        {`#${c?.jobNumber} - #${c?.orderNumber} - ${c?.jobType} - ${c?.companyName}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {routeData !== '' &&
                <div style={{ padding: '14px', }}>
                  <h3 style={{
                    fontWeight: '600',
                    borderBottom: '1px solid',
                    width: 'fit-content'
                  }}>{" "}Job Details{" "}</h3>
                  {this.showRouteData(routeData)}
                </div>}
            </Form>
          </BaseModal>
        )}

        {routePopUp && (
          <BaseModal
            title={"Job Details"}
            onCancel={() => {
              this.setState({
                routePopUp: false,
              });
            }}
            visible={routePopUp}
            // destroyOnClose={true}
            formId="route"
            loading={this.state.loading}
            footer={null}
            width={"30%"}
            className="antmodal_grid headermodal mapcompon_grid"
          >
            {this.showRouteData(routeData)}
          </BaseModal>
        )}

        {contactPopup && (
          <BaseModal
            title={
              recordContactData !== "" ? "Edit Contact" : "Add New Contact"
            }
            onCancel={() => {
              this.setState({
                contactPopup: false,
                isCheckcontact: false,
                newRole: "",
              });
            }}
            visible={contactPopup}
            destroyOnClose={true}
            formId="addContact"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addContact}
              initialValues={{ remember: true }}
              id="addContact"
            >
              <Row gutter={[24, 0]}>
                <>
                  <Col span={12}>
                    <span className="title_changes">First Name</span>
                    <Form.Item
                      name="fName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your First Name!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.firstName
                          ? recordContactData?.firstName
                          : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="title_changes">Last Name</span>
                    <Form.Item
                      name="lName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Last Name!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.lastName
                          ? recordContactData?.lastName
                          : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter your Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Phone</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Phone!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.phone ? recordContactData?.phone : ""
                      }
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Your Phone"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                      initialValue={
                        recordContactData?.email ? recordContactData?.email : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Role</span>
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Role!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.contactRole &&
                        recordContactData?.contactRole
                      }
                    >
                      {/* <Input
                        className="form-input"
                        placeholder="Please Enter Your Role"
                      /> */}
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Role"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                          this.setState({ newRole: value });
                          value === "Others" &&
                            message.info("Please Input Your Role");
                        }}
                      >
                        {this.props.context.quarryRoles?.map((role) => (
                          <Option value={role?.label}>{role?.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {newRole === "Add New Role" && (
                    <Col span={24}>
                      <Form.Item name="roleOther">
                        <Input
                          className="form-input"
                          placeholder="Please Enter Role"
                        />
                      </Form.Item>
                      <Checkbox
                        checked={this.state.isCheckcontact}
                        onChange={(e) =>
                          this.setState({
                            isCheckcontact: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  )}
                  <Col span={24}>
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.notes ? recordContactData?.notes : ""
                      }
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </>
              </Row>
            </Form>
          </BaseModal>
        )}

        {notesPopup && (
          <BaseModal
            title={recordNotesData !== "" ? "Edit Notes" : "Add New Notes"}
            onCancel={() => {
              this.setState({
                notesPopup: false,
              });
            }}
            visible={notesPopup}
            destroyOnClose={true}
            formId="addNotes"
            loading={this.state.loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addNotes}
              initialValues={{ remember: true }}
              id="addNotes"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Type of Notes</span>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.typeofNotes
                        ? recordNotesData?.typeofNotes
                        : ""
                    }
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Type Of Notes"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.notes ? recordNotesData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
        {addCategory && (
          <BaseModal
            title={"Add Material"}
            onCancel={() => {
              this.setState({
                addCategory: false,
              });
            }}
            visible={addCategory}
            destroyOnClose={true}
            formId="addCategory"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addCategory}
              initialValues={{ remember: true }}
              id="addCategory"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Material Name</span>
                  <Form.Item
                    name="materialsub2"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Material Name!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Material"
                      autoFocus
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
// console.log(state),
({
  dropDownOptions: state?.reducer?.dropDownOptions,
  materials: state?.reducer?.dropDownOptions?.totalData?.filter(
    (m) => m?.typeofDropDown === "material"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(MapComponent));
