import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Form,
  Popover,
  Popconfirm,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  EllipsisOutlined,
  PlusOutlined,
  DeleteOutlined,
  RedoOutlined,
  // FilePdfOutlined,
} from "@ant-design/icons";
import {
  MdModeEditOutline,
  MdDelete,
  MdArchive,
  MdUnarchive,
} from "react-icons/md";
// import { BiExport } from "react-icons/bi";
import NumberFormat from "react-number-format";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Filter from "../../Common/Filter";
// import FooterData from "../Common/FooterData";
import SearchFunction from "../../Common/SearchFunction";
import { add, deleteAll } from "../../api/Apis";
// import { RiMailSendFill } from "react-icons/ri";
import appConfig from "../../config/AppConfig";
import Loading from "../../Common/Loading";
import ErrorView from "../../Common/ErrorView";
import BaseTable from "../../Common/BaseTable";
import BaseDrawer from "../../Common/BaseDrawer";
import { IoFilterSharp } from "react-icons/io5";
import { Storage } from "aws-amplify";
import { downloadExcel } from "react-export-table-to-excel";
import excelIcon from "../../Images/excel.png";
import excelIcon1 from "../../Images/excel1.png";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  addCompany,
  deleteContacts,
  getContacts,
  updateContacts,
} from "../../Redux/Actions/actions";
import { connect } from "react-redux";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// import { SiMicrosoftexcel } from "react-icons/si";

const { TextArea } = Input;
class TruckDriverDataBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      loading: false,
      selectionType: "",
      selectedRowKeys: [],
      selected: true,
      color: "active",
      isModalVisible: false,
      visible: false,
      selectedAddress: "",
      columnsData: this.props.columnsData,
      leadsData: "",
      filterData: this.props.filterData,
      errorLoading: false,
      checkData: "",
      archiveData: true,
      selectedData: [],
      city: "",
      addressState: "",
      active: false,
      searchData: undefined,
      showConfirmDriver: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.actionFetchContacts();
    document.title = "Twisted Nail - Truck Driver Database";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    if (
      JSON.stringify(prevProps?.filterData) ===
      JSON.stringify(this.state?.filterData)
    ) {
      let hashKeyValue = window.location.hash;
      if (hashKeyValue === "#quickJob") {
        hashKeyValue = "";
        this.props.history.push(`${window.location.pathname}`);
        this.props.actionFetchContacts();
      }
    }
    if (
      prevProps?.contactsInfo?.success === false &&
      this.props.contactsInfo?.success === true
    ) {
      this.props.actionFetchContacts();
    }
    if (
      prevProps?.companiesInfo?.success === false &&
      this.props.companiesInfo?.success === true
    ) {
      this.props.actionFetchContacts();
    }
    if (
      JSON.stringify(prevProps?.filterData) !==
      JSON.stringify(this.props?.filterData)
    ) {
      const hasFilter =
        filterStorageData?.filter &&
        Object.keys(filterStorageData?.filter).length > 0;

      this.setState(
        {
          filterData: this.props?.filterData,
          columnsData: this.props?.filterData,
        },
        () => {
          if (hasFilter) {
            this.filterResultData(filterStorageData?.filter, true);
          }
        }
      );
    }
  };

  onClickRefresh = () => {
    this.props.actionFetchContacts();
  };

  filterResultData = (data, noUpdate) => {
    this.setState({
      checkData: data,
    });
    const { columnsData } = this.state;
    let finalData = columnsData.filter((d) => !Array.isArray(d));
    let submitData = {
      entityData: {
        origin: data.origin,
        radius: data.radius,
        filterData: [],
      },
    };
    if (
      data.dateAdded !== "" &&
      data.dateAdded !== undefined &&
      data.origin.lat !== "" &&
      data.origin.lat !== undefined
    ) {
      let filterInfo = finalData.filter(
        (e) =>
          new Date(moment(data.dateAdded).format("l")) <=
          new Date(moment(e.createdAt).format("l"))
      );
      submitData["entityData"]["filterData"] = filterInfo;
      add(appConfig["entityNames"]["getDist"], submitData).then((result) => {
        this.setState({ loading: true });
        if (result.success) {
          let resultData = result?.data?.filter((d) => d.isDriver === true);
          this.setState(
            {
              loading: false,
              filterData: resultData,
            },
            () => this.exportData(resultData, data, noUpdate)
          );
        }
      });
    } else if (data.origin.lat !== "" && data.origin.lat !== undefined) {
      this.setState({ loading: true });
      add(appConfig["entityNames"]["getDist"], submitData).then((result) => {
        if (result.success) {
          let resultData = result?.data?.filter((d) => d.isDriver === true);
          this.setState(
            {
              loading: false,
              filterData: resultData,
            },
            () => this.exportData(resultData, data, noUpdate)
          );
        }
      });
    } else {
      let filterInfo = finalData.filter(
        (e) =>
          new Date(moment(data.dateAdded).format("l")) <=
          new Date(moment(e.createdAt).format("l"))
      );
      let resultData = filterInfo?.filter((d) => d.isDriver === true);
      this.setState(
        {
          filterData: resultData,
        },
        () => this.exportData(resultData, data, noUpdate)
      );
    }
    this.closeFilter();
  };

  searchResult = (searchVal) => {
    let searchValue = searchVal.toLowerCase();
    let searchFilter = [];
    const { columnsData } = this.state;
    // console.log(searchValue,columnsData);
    if (typeof searchVal === "number") {
      searchFilter = columnsData?.filter(
        (e) => parseInt(e["phone"]?.replace(/[^+0-9]/g, "")) === searchVal
      );
    } else {
      searchFilter = columnsData?.filter(
        (e) =>
          e["firstName"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["lastName"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["email"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["address"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.getLatLangData(searchValue);
    this.setState({
      filterData: searchFilter,
    });
  };

  getLatLangData = (address) => {
    let newData = {};
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          newData["lat"] = lat;
          newData["lng"] = lng;
          newData["address"] = address;
          this.setState({
            searchData: newData,
          });
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onSelectChange = (data, record) => {
    this.setState({
      selectedRowKeys: data,
      selectedData: record,
      active: true,
    });
  };

  onResetData = () => {
    this.setState({
      searchData: undefined,
    });
    this.props.actionFetchContacts();
  };

  getAddress = (address) => {
    this.setState({
      selectedAddress: address.label,
    });
  };

  showModal = () => {
    this.setState({ isModalVisible: true, leadsData: "" });
  };

  fileDownload = async (fileName) => {
    Storage.get(fileName, { download: true })
      .then((res) => this.downloadBlobQuote(res.Body, fileName))
      .catch((err) => console.log(err));
  };

  downloadBlobQuote(blob, fileName) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loading: false,
    });
    return a;
  }

  exportData = (selectedData, filterData, noUpdate) => {
    this.setState({
      loading: true,
    });
    if (filterData) {
      let filterInfo = {};
      const { origin, ...employeeRest } = filterData;
      filterInfo = employeeRest;
      if (filterData.address === "") {
        const { address, radius, ...employeeRest } = filterInfo;
        filterInfo = employeeRest;
      }
      if (filterData.radius === 0) {
        const { radius, ...employeeRest } = filterInfo;
        filterInfo = employeeRest;
      }
      Object.keys(filterInfo)?.forEach((key) => {
        if (filterInfo[key] === null) {
          delete filterInfo[key];
        }
      });
      if (!noUpdate) {
        localStorage.setItem(
          "filterStorageData",
          JSON.stringify({
            filter: filterData,
            filterShowData: filterInfo,
          })
        );
      }
    }
    if (selectedData?.length > 0) {
      let data = [];
      const header = [
        "First Name",
        "Last Name",
        "Home City",
        "Phone",
        "Email",
        "Date Added",
      ];
      selectedData?.map((e) =>
        data.push({
          firstName: e.firstName,
          lastName: e.lastName,
          address: e.address,
          phone: e.phone,
          email: e.email,
          dateAdded: moment(e?.createdAt)?.format("l"),
        })
      );

      downloadExcel({
        fileName: "Driver Details",
        sheet: "Driver Contacts",
        tablePayload: {
          header,
          body: data,
        },
      });
      this.setState({
        loading: false,
      });

      message.success("Record downloaded Successfully!");
    } else {
      message.error("Please select atleast one driver!");
      this.setState({
        loading: false,
      });
    }
  };

  clearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.props.actionFetchContacts();
    this.setState({
      visible: false,
      checkData: "",
      loading: true,
    });
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  handleSelect = (address) => {
    // console.log(address);
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        // console.log(results);
        getLatLng(results[0]).then(({ lat, lng }) => {
          this.setState({ latitude: lat, longitude: lng });
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          } else if (address[i].types[0] === "route") {
            cityValue = address[i].long_name;
          } else if (address[i].types[0] === "political") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        //  console.log(cityValue, stateValue, zipCodeValue);
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  editModal = (record) => {
    console.log(record);
    this.setState({
      isModalVisible: true,
      leadsData: record,
      selectedAddress: record?.address,
      city: record?.cityName,
      addressState: record?.stateName,
      latitude: record?.lat,
      longitude: record?.lng,
    });
  };

  onFinish = (values) => {
    const { actionUpdateContacts, actionAddContacts } = this.props;
    const {
      selectedAddress,
      leadsData,
      city,
      addressState,
      latitude,
      longitude,
    } = this.state;
    this.setState({ loading: true, isModalVisible: false });
    let newData = {
      entityData: {
        firstName: values["firstName"],
        lastName: values["lastName"],
        phone: values["phone"],
        email: values["email"],
        address: selectedAddress,
        cityName: city,
        stateName: addressState,
        stageId: "Contacted",
        leadType: [
          {
            id: "3",
            value: "Driver",
          },
        ],
        lat: latitude,
        lng: longitude,
        notes: values["notes"],
      },
    };
    // console.log(newData);
    if (leadsData !== "") {
      actionUpdateContacts(leadsData?.id, newData);
      this.setState({
        leadsData: "",
        latitude: "",
        longitude: "",
        city: "",
        addressState: "",
      });
    } else {
      actionAddContacts(newData);
      this.setState({
        leadsData: "",
        latitude: "",
        longitude: "",
        city: "",
        addressState: "",
      });
    }
  };

  confirmDriver = (record) => {
    const { actionUpdateContacts } = this.props;
    let data = {
      entityData: {
        confirmDriver: true,
      },
    };
    actionUpdateContacts(record?.id, data);
  };

  deleteDriver = (record) => {
    const { actionDeleteContacts } = this.props;
    actionDeleteContacts(record?.id);
  };

  archiveEdit = (record) => {
    let data = {
      entityData: {
        showDriver:
          record.showDriver !== undefined ? !record.showDriver : false,
      },
    };
    this.props.actionUpdateContacts(record?.id, data);
  };

  deleteBulkTrucks = () => {
    const { selectedRowKeys } = this.state;
    this.setState({ loading: true });
    let data = {
      entityData: selectedRowKeys,
    };
    deleteAll("bulkDelete/contacts", data)
      .then((result) => {
        if (result.success) {
          this.setState({ loading: false });
          this.props.actionFetchContacts();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, truckDriverDataBase: props })
    );
  };

  handleMoveToOperator = (record) => {
    let data = {
      entityData: {
        address: record["address"],
        company: record["firstName"] + " " + record["lastName"],
        carrier_name: record["firstName"] + " " + record["lastName"],
        carrier_status: "Applicant",
        cityName: record["cityName"],
        stateName: record["stateName"],
        usdot: null,
        payment_terms: "Net 7",
        payment_discount: "12.5%",
        phone: record["phone"],
        insurance_expiry: "",
        direct_deposit: "Direct Deposit",
        email: record["email"],
        lat: record["lat"],
        lng: record["lng"],
        contactInfo: [
          {
            firstName: record["firstName"],
            lastName: record["lastName"],
            contact_name: record["firstName"] + " " + record["lastName"],
            contactRole: "",
            company: "",
            isPrimaryContact: false,
            contact_number: record["phone"],
            email: record["email"],
            id: uuidv4(),
          },
        ],
        registered_since: moment().format("l"),
        leadType: [{ id: "2", value: "Owner Operator" }],
        stageId: "Uncontacted Lead",
        isOwnerOperator: true,
        carrierDetails: [],
        notes: record["notes"],
      },
    };
    this.props.actionAddContacts(data);
    this.deleteDriver(record);
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const {
      showConfirmDriver,
      archiveData,
      selectedRowKeys,
      isModalVisible,
      leadsData,
      citySearch,
      filterData,
      active,
      searchData,
      // columnsData,
    } = this.state;
    const { userRoleAccess } = this.props.context;
    const { loading, errorLoading } = this.props;

    const columns = [
      {
        title: "FIRST NAME",
        dataIndex: "firstName",
        key: "firstName",
        sorter: false,
        className: "col_styling",
        width: "125px",
        // fixed: "left",
        render: (firstName) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row>{firstName}</Row>
            </span>
          );
        },
      },
      {
        title: "LAST NAME",
        dataIndex: "lastName",
        key: "lastName",
        sorter: false,
        className: "col_styling",
        width: "125px",
        render: (lastName) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row>{lastName}</Row>
            </span>
          );
        },
      },
      {
        title: "CITY, STATE",
        dataIndex: "address",
        key: "address",
        sorter: false,
        className: "col_styling",
        render: (address, record) => {
          let searchInfo =
            searchData !== undefined
              ? `(${searchData.lat + ", " + searchData.lng}) `
              : "";
          return (
            <Popover
              content={
                <Row>
                  <Col span={24}>
                    <span>{address}</span>
                  </Col>
                </Row>
              }
            >
              {record?.cityName ? (
                <span>
                  <b>{searchInfo}</b>
                  {`${record?.cityName}, ${record?.stateName}`}
                </span>
              ) : (
                <span>
                  <b>{searchInfo}</b>
                  {`${record?.stateName}`}
                </span>
              )}
            </Popover>
          );
        },
      },
      {
        title: "PHONE",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        className: "col_styling",
        width: "150px",
        render: (phone) => {
          return (
            <span
              className="col_styling"
              style={{
                textTransform: "capitalize",
              }}
            >
              {phone}
            </span>
          );
        },
      },
      {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
        sorter: false,
        className: "col_styling",
        render: (email) => {
          return <span className="col_styling">{email}</span>;
        },
      },
      {
        title: "DATE ADDED",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        className: "col_styling",
        render: (createdAt) => {
          return (
            <span className="col_styling">{moment(createdAt).format("l")}</span>
          );
        },
      },
      // {
      //   title: "STATUS",
      //   dataIndex: "status",
      //   key: "status",
      //   sorter: false,
      //   className: "col_styling",
      //   render: (status) => {
      //     return (
      //       <Row>
      //         {status === "Available" && (
      //           <span
      //             style={{
      //               fontSize: "14px",
      //               borderRadius: "5px",
      //               padding: "5px",
      //               backgroundColor: "#cdf2cb",
      //               color: "#0b9c03",
      //             }}
      //           >
      //             {status}
      //           </span>
      //         )}
      //         {status === "Unanswered" && (
      //           <span
      //             style={{
      //               fontSize: "14px",
      //               borderRadius: "5px",
      //               padding: "5px",
      //               backgroundColor: "#ffce91",
      //               color: "#cf7300",
      //             }}
      //           >
      //             {status}
      //           </span>
      //         )}
      //       </Row>
      //     );
      //   },
      // },
      {
        title: "ACTIONS",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "action_tab",
        width: "120px",
        fixed: "right",
        // fixed: "right",
        render: (id, record) => {
          return (
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <Col span={7}>
                <a href={`tel:${record.phone}`}>
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <MdPhone
                      style={{ color: "#878787", fontSize: "22px" }}
                      className="icon"
                    />
                  </button>
                </a>
              </Col>
              <Col span={7}>
                <a href={`mailto:${record.email}`}>
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <MdEmail
                      style={{ color: "#878787", fontSize: "22px" }}
                      className="icon"
                    />
                  </button>
                </a>
              </Col> */}
              <Col span={24}>
                <Popover
                  placement="left"
                  trigger="hover"
                  content={
                    <Row className="popovergrid" style={{ maxWidth: "186px" }}>
                      <Col span={24}>
                        <Button
                          className="btn_edit"
                          onClick={() => this.editModal(record)}
                        >
                          <span className="span_edit">
                            <MdModeEditOutline style={{ marginRight: "5px" }} />{" "}
                            Edit
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button className="btn_edit">
                          <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.deleteDriver(record)}
                          >
                            <span className="span_edit">
                              <MdDelete style={{ marginRight: "5px" }} /> Delete
                            </span>
                          </Popconfirm>
                        </Button>
                      </Col>
                      {record?.confirmDriver !== true && (
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.confirmDriver(record)}
                          >
                            <span
                              className="span_edit"
                              style={{
                                color: "black",
                                height: "auto",
                              }}
                            >
                              <CheckCircleOutlined
                                style={{
                                  marginRight: "5px",
                                }}
                              />{" "}
                              Confirm
                            </span>
                          </Button>
                        </Col>
                      )}
                      <Col span={24}>
                        <Button
                          className="btn_edit"
                          onClick={() => this.archiveEdit(record)}
                        >
                          <span className="span_edit">
                            {record?.showDriver === false ? (
                              <>
                                <MdUnarchive style={{ marginRight: "5px" }} />{" "}
                                Retrive
                              </>
                            ) : (
                              <>
                                <MdArchive style={{ marginRight: "5px" }} />{" "}
                                Archive
                              </>
                            )}
                          </span>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          className="btn_edit"
                          onClick={() => this.handleMoveToOperator(record)}
                        >
                          <span className="span_edit">
                            <RedoOutlined style={{ marginRight: "5px" }} /> Move
                            OwnerOperator
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <EllipsisOutlined
                    style={{ fontSize: "30px", color: "grey" }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </Row>
          );
        },
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const searchFields = ["firstName", "lastName", "address", "phone", "email"];

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />

        <Row style={{ marginBottom: "1vw" }}>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 6 }}
            lg={{ span: 6 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className="layout-landscape"
            // span={12}
          >
            <Row>
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                // span={10}
                className="quarrytoolgrid"
              >
                <SearchFunction
                  tableName={"contacts"}
                  getSearchData={this.searchResult}
                  onResetData={this.onResetData}
                  searchFields={searchFields}
                />
              </Col>
            </Row>
          </Col>

          <Col
            xxl={{ span: 18 }}
            xl={{ span: 18 }}
            lg={{ span: 18 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            // span={12}
          >
            <Row style={{ justifyContent: "right" }}>
              {/* <Col>
                <span
                  style={{
                    fontSize: "18px",
                    color: "#b3b3b3",
                    lineHeight: "2",
                  }}
                >
                  Status :
                </span>
                <Select
                  // value={selectedValue || "Actions"}
                  className="select_truckDriver"
                  // onChange={(value) => {
                  //   this.setState({
                  //     selectedValue: value,
                  //   });
                  // }}
                  bordered={false}
                  defaultValue="All"
                  style={{ float: "right", height: "40px" }}
                >
                  <Option className="option_style" value="Jack">
                    Jack
                  </Option>
                  <Option className="option_style" value="Lucy">
                    Lucy
                  </Option>
                  <Option className="option_style" value="yiminghe">
                    yiminghe
                  </Option>
                </Select>
              </Col> */}
              {selectedRowKeys?.length > 0 &&
                userRoleAccess?.roleName === "Admin" && (
                  <Col className="gridbtntool">
                    <Button
                      icon={<DeleteOutlined style={{ color: "red" }} />}
                      onClick={this.deleteBulkTrucks}
                      style={{
                        borderRadius: "5px",
                        height: "35px",
                        margin: "auto 0px",
                        border: "1px solid red",
                      }}
                    >
                      <span style={{ color: "red" }}>Bulk Delete</span>
                    </Button>
                  </Col>
                )}
              {selectedRowKeys?.length > 0 ? (
                <Col
                  className="gridbtntool"
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: active === true ? "" : "green",
                    fontColor: "white",
                    border: "1px solid #008000",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.exportData(this.state.selectedData)}
                >
                  {/* <Button
                    type="primary"
                    icon={
                      <FilePdfOutlined
                        style={{ fontSize: "22px", color: "#ff0000c2" }}
                      />
                    }
                    style={{
                      borderRadius: "5px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      background: "#FFF",
                      borderColor: "#ff000063",
                    }}
                  > */}

                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      // marginLeft: "5px",
                      color: "grey",
                      display: "flex",
                      flexWrap: "",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={excelIcon}
                      alt="excelIcon"
                      style={{
                        maxWidth: "28px",
                        height: "auto",
                        fontSize: "18px",
                        marginRight: "8px",
                      }}
                    />
                    {/* <SiMicrosoftexcel
                    style={{
                      // fontWeight: "500",
                      fontSize: "120%",
                      marginRight: "8px",
                      // color: "green",
                    }}
                  />   */}
                    <Row style={{ color: "green" }}>Export Excel</Row>
                  </span>

                  {/* </Button> */}
                </Col>
              ) : (
                <Col
                  className="gridbtntool"
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    Color: active === true ? "" : "green",
                    border: "1px solid grey",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.exportData(this.state.selectedData)}
                >
                  {/* <Button
                    type="primary"
                    icon={
                      <FilePdfOutlined
                        style={{ fontSize: "22px", color: "#ff0000c2" }}
                      />
                    }
                    style={{
                      borderRadius: "5px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      background: "#FFF",
                      borderColor: "#ff000063",
                    }}
                  > */}

                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      // marginLeft: "5px",
                      color: "grey",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={excelIcon1}
                      alt="excelIcon1"
                      style={{
                        // color:"black",
                        maxWidth: "28px",
                        height: "auto",
                        fontSize: "18px",
                        marginRight: "8px",
                        width: "22px",

                        // backgroundColor: "grey",
                      }}
                    />
                    {/* <SiMicrosoftexcel
                    style={{
                      // fontWeight: "500",
                      fontSize: "120%",
                      marginRight: "8px",
                      // color: "green",
                    }}
                  />   */}
                    <div className="show-on-desktop">Export Excel</div>
                    <div className="show-on-mobile excel-driver-database">
                      Excel
                    </div>
                  </span>

                  {/* </Button> */}
                </Col>
              )}
              <Col className="gridbtntool">
                <Button
                  type="primary"
                  icon={
                    showConfirmDriver ? (
                      <MdArchive style={{ fontSize: "18px" }} />
                    ) : (
                      <MdUnarchive style={{ fontSize: "18px" }} />
                    )
                  }
                  onClick={() =>
                    this.setState({
                      showConfirmDriver: !showConfirmDriver,
                    })
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "5px",
                    }}
                  >
                    {showConfirmDriver ? "unconfirmed" : "Confirmed"}
                  </span>
                </Button>
              </Col>
              <Col className="gridbtntool">
                <Button
                  type="primary"
                  icon={
                    archiveData ? (
                      <MdArchive style={{ fontSize: "18px" }} />
                    ) : (
                      <MdUnarchive style={{ fontSize: "18px" }} />
                    )
                  }
                  onClick={() =>
                    this.setState({
                      archiveData: !archiveData,
                    })
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "5px",
                    }}
                    className="archive-button-ownerOperator"
                  >
                    {archiveData ? "Archive" : "Retrive"}
                  </span>
                </Button>
              </Col>
              <Col className="gridbtntool">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={this.showModal}
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                >
                  <span
                    className="archive-button-ownerOperator"
                    style={{ fontWeight: "500", fontSize: "16px" }}
                  >
                    Driver
                  </span>
                </Button>
              </Col>
              <Col className="gridbtntool">
                <Button
                  className="filterButton-driver-database"
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => {
                    this.setState({ visible: true });
                  }}
                >
                  <Row>
                    <Col>
                      <span
                        style={{
                          color:
                            Object.keys(filterInfo)?.length > 0
                              ? "red"
                              : "#5a6067",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                        className="show-on-desktop"
                      >
                        {Object.keys(filterInfo)?.length > 0
                          ? `${"Filter (" + Object.keys(filterInfo)?.length})`
                          : "Filter"}
                      </span>
                    </Col>
                    <Col>
                      <IoFilterSharp
                        style={{
                          fontSize: "22px",
                          marginLeft: "8px",
                          color:
                            Object.keys(filterInfo)?.length > 0
                              ? "red"
                              : "#5a6067",
                        }}
                        className="filterIcon-driver-database"
                      />
                    </Col>
                  </Row>
                </Button>
              </Col>

              {/* <Col offset={1}>
                <Button
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    padding: "0px",
                    backgroundColor: "#e3e7ea",
                    border: "1px solid #e3e7ea",
                    width: "45px",
                  }}
                >
                  <EllipsisOutlined
                    style={{ fontSize: "35px", color: "grey" }}
                  />
                </Button>
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            {errorLoading ? (
              <ErrorView refreshTruck={this.onClickRefresh} type="Truck" />
            ) : (
              <Card bodyStyle={{ padding: "0px" }} data-aos="fade-up">
                <BaseTable
                  className="truckdriver_table table_laptopscreen"
                  columnsData={columns}
                  source={
                    showConfirmDriver
                      ? filterData
                          .filter((e) => e.confirmDriver)
                          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                      : archiveData
                      ? filterData
                          .filter(
                            (e) =>
                              e.showDriver !== false && e.confirmDriver !== true
                          )
                          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                      : filterData
                          .filter((e) => e.showDriver === false)
                          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                  }
                  rowSelection={rowSelection}
                  rowKey={(record) => record.id}
                  total={
                    showConfirmDriver
                      ? filterData.filter((e) => e.confirmDriver).length
                      : archiveData
                      ? filterData.filter(
                          (e) =>
                            e.showDriver !== false && e.confirmDriver !== true
                        ).length
                      : filterData.filter((e) => e.showDriver === false).length
                  }
                  handleTableChage={this.handleTableChage}
                  sizeRange={
                    window.screen.width > 1023
                      ? JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.truckDriverDataBase
                      : archiveData
                      ? filterData.filter((e) => e.showDriver !== false).length
                      : filterData.filter((e) => e.showDriver === false).length
                  }
                  pagination={window.screen.width > 1023 ? true : false}
                />
              </Card>
            )}
            {/* {selectedRowKeys.length > 0 && (
              <FooterData count={selectedRowKeys.length} assign />
            )} */}
          </Col>
        </Row>

        {isModalVisible && (
          <BaseDrawer
            // title="Add Driver"
            title={leadsData !== "" ? "Update Driver" : "Add Driver"}
            onClose={() => {
              this.setState({ isModalVisible: false });
            }}
            width="33%"
            destroyOnClose={true}
            visible={isModalVisible}
            className="headerdrawer"
            footer={[
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    this.setState({ isModalVisible: false });
                  }}
                  className="cancelbtnd"
                  style={{
                    height: "35px",
                    width: "100px",
                    borderRadius: "5px",
                    marginRight: "25px",
                  }}
                  type="ghost"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    height: "35px",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  form="dirverForm"
                >
                  Save
                </Button>
              </div>,
            ]}
          >
            <Form
              onFinish={this.onFinish}
              id="dirverForm"
              initialValues={{ remember: true }}
              layout="vertical"
            >
              <Row gutter={[18, 0]}>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <span className="title_changes">First Name</span>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your First Name",
                      },
                    ]}
                    initialValue={leadsData?.firstName}
                  >
                    <Input
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your First Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <span className="title_changes">Last Name</span>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Last Name",
                      },
                    ]}
                    initialValue={leadsData?.lastName}
                  >
                    <Input
                      className="form-input"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Last Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <span className="title_changes">Phone</span>
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: false, message: "Please Enter Your Phone!" },
                    ]}
                    initialValue={leadsData?.phone}
                  >
                    <NumberFormat
                      className="ant-input-number form-input numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Phone"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <span className="title_changes"> Email</span>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: false, message: "Please Enter Your Email!" },
                      {
                        type: "email",
                        message: "The Input Value Is Not Valid Email",
                      },
                    ]}
                    initialValue={leadsData?.email}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter Your Email"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <span className="title_changes">Home City</span>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Home City!",
                      },
                    ]}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      autocompletionRequest={{
                        // types: ["regions"],
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        citySearch,
                        defaultInputValue: `${
                          leadsData ? leadsData.address : ""
                        }`,
                        placeholder: "Search Home City...",
                        onChange: (address) => {
                          this.handleSelect(address);
                          this.getAddress(address);
                        },
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={leadsData?.notes}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>

                {/* <Col>
              <span className="title_changes">Status</span>
              <Form.Item
                name="status"
                rules={[
                  { required: true, message: "Please select your select!" },
                ]}
              >
                <Select
                  allowClear
                  className="select_Height"
                  style={{ height: "40px", fontWeight: "300" }}
                  placeholder="Please enter your type"
                >
                  <Option value="Available">Available</Option>
                  <Option value="Unanswered">Unanswered</Option>
                </Select>
              </Form.Item>
            </Col> */}
              </Row>
            </Form>
          </BaseDrawer>
        )}

        {this.state.visible && (
          <Filter
            visible={this.state.visible}
            onClearFilter={this.clearFilter}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            checkData={this.state.checkData}
            driver
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  columnsData: state?.reducer?.contactsData?.contacts?.filter(
    (d) => d?.isDriver === true
  ),
  filterData: state?.reducer?.contactsData?.contacts?.filter(
    (d) => d?.isDriver === true
  ),
  loading: state.reducer?.contactsData?.loading,
  errorLoading: state.reducer?.contactsData?.error,
  contactsInfo: state.reducer?.contactsInfo,
  companiesInfo: state.reducer?.companiesInfo,
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchContacts: () => {
    dispatch(getContacts());
  },
  actionDeleteContacts: (id) => {
    dispatch(deleteContacts(id));
  },
  actionUpdateContacts: (id, data) => {
    dispatch(updateContacts(id, data));
  },
  actionAddContacts: (data) => {
    dispatch(addCompany(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(TruckDriverDataBase)));
