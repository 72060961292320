import React, { Component } from "react";
import { Row, Col, Card, Divider, Tabs, Badge, Button, message } from "antd";
import BaseTable from "../../Common/BaseTable";
import { DownloadOutlined, CloseOutlined } from "@ant-design/icons";
import { IoFilterSharp } from "react-icons/io5";
import { connect } from "react-redux";
import { Storage } from "aws-amplify";
import { withAppContext } from "../../Store/AppContext";
import { withRouter } from "react-router-dom";
import SearchFunction from "../../Common/SearchFunction";
import {
  getAccountsPayable,
  updateMultipleInvoice,
} from "../../Redux/Actions/actions";
import AOS from "aos";
import Loading from "../../Common/Loading";
import { downloadExcel } from "react-export-table-to-excel";
import Filter from "../../Common/Filter";
import moment from "moment";
import excelIcon from "../../Images/excel.png";
import excelIcon1 from "../../Images/excel1.png";
import { PDFViewer } from "react-view-pdf";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import BaseModal from "../../Common/BaseModal";
import { saveAs } from "file-saver";
import JSZip from "jszip";
class BillManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      selectedData: [],
      filterData: [],
      totalData: [],
      loacalLoading: false,
      visible: false,
      billKey: 1,
      materialCategory: [],
      checkData: [],
      filterVendorInfo: [],
      enableRowSelection: false,
    };
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Accounts Payable";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
    this.props.getAccountsPayable();
  };

  componentDidUpdate = (prevProps) => {
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    if (
      prevProps.accountsResult.success === false &&
      this.props.accountsResult.success === true
    ) {
      let data = this.props.accountsResult.data
        ?.filter((e) => e.isTruckingAndMaterialJob)
        ?.sort((a, b) => (a.invoiceNumber > b.invoiceNumber ? -1 : 1));

      data?.forEach((e) =>
        e.ticketDetails.forEach((t) => {
          t["invoiceId"] = e["invoiceId"];
          t["invoiceId"] = e["invoiceId"];
          t["createdAt"] = e["createdAt"];
        })
      );
      const hasFilter =
        filterStorageData?.filter &&
        Object.keys(filterStorageData?.filter).length > 0;
      let updateData = data?.map((e) => e.ticketDetails);
      let newData = updateData?.flatMap((m) => m);
      this.setState(
        {
          totalData: newData,
          filterData: newData,
        },
        () => {
          if (hasFilter) {
            this.filterResultData(filterStorageData?.filter, true);
          }
        }
      );
      this.handleTabChange(1, newData);
    }

    if (
      prevProps?.invoiceInfo?.success === false &&
      this.props?.invoiceInfo?.success === true
    ) {
      this.props.getAccountsPayable();
    }
  };

  onSelectChange = (data, selectedRows) => {
    this.setState({ selectedRowKeys: data, selectedData: selectedRows });
    if (data?.length > 0) {
      this.setState({
        enableRowSelection: true,
      });
    } else {
      this.setState({
        enableRowSelection: false,
      });
    }
  };

  exportData = (selectedData) => {
    this.setState({
      localLoading: true,
    });
    if (selectedData?.length > 0) {
      let data = [],
        header = [];

      header = [
        "BILL NUMBER #",
        "VENDOR NAME",
        "BILL DATE",
        "DUE DATE",
        "TERMS",
        "MEMO",
        "EXPENSE ACCOUNT",
        "EXPENSE DESCRIPTION",
        "EXPENSE LINE AMOUNT",
      ];
      selectedData?.map((e) => {
        let terms = e?.selectedMaterial[0]?.vendorTerms?.split("Net ")[1];
        let dueDate = moment(e?.createdAt).add(terms, "days").calendar();
        let materialCost = e?.selectedMaterial[0].materialCost * e?.totalTons;
        data.push({
          billNumber: e?.jobNumber + "AP - " + e?.ticketNumber,
          vendorName: e?.selectedMaterial[0]?.vendor,
          billDate: moment(e?.createdAt).format("L"),
          dueDate: moment(dueDate).format("L"),
          terms: e?.selectedMaterial[0]?.vendorTerms,
          memo: e.truckNumber + "#, " + e?.jobName,
          expenseAccount: "",
          expenseDescription: e?.selectedMaterial[0]?.materialShortValue,
          expenseLineAmount:
            `$ ` +
            materialCost?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
        });
        return e;
      });

      downloadExcel({
        fileName: "Bill Management Details",
        sheet: "Bill Management",
        tablePayload: {
          header,
          body: data,
        },
      });
      this.setState({
        localLoading: false,
      });

      message.success("Record downloaded Successfully!");
    } else {
      message.error("Please select atleast one record!");
      this.setState({
        localLoading: false,
      });
    }
  };

  handleMarkUpload = (exportData, type) => {
    if (exportData?.length > 0) {
      this.setState({
        localLoading: true,
      });
      let data = {
        entityData: {
          invoiceIds: exportData,
          status: type,
        },
      };
      this.props.updateMultipleInvoice(data);
      this.exportData(exportData);
    } else {
      message.error("Please select atleast one record!");
    }
  };

  downloadZip = async (selectedData) => {
    this.setState({
      loading: true,
    });
    const zip = new JSZip();
    // Create an array of promises to fetch and add images to the zip file
    const promises = selectedData?.map(async (image) => {
      let folderName = `${image?.singlePdfTicket}.pdf`;
      try {
        const response = await Storage.get(
          image?.singlePdfTicket.replace("public/", ""),
          {
            download: true,
          }
        );
        const blob = response.Body;
        zip.file(`${folderName.replace("public/tickets/", "")}`, blob, {
          binary: true,
        });
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Generate the zip file
    const content = await zip.generateAsync({ type: "blob" });
    // Save the zip file
    saveAs(content, "billManagement.zip");
    this.setState({
      loading: false,
    });
  };

  fileDownloadQuote = async (fileName, type) => {
    let pdfUrl = fileName?.replace("public/", "");
    this.setState({
      loading: true,
    });
    Storage.get(pdfUrl, { download: true })
      .then((res) => this.downloadBlobQuote(res.Body, "Bill_Ticket"))
      .catch((err) => console.log(err));
  };

  downloadImages = async (fileName) => {
    this.setState({
      loading: true,
    });
    fileName?.map((e) =>
      Storage.get(e.singlePdfTicket?.replace("public/", ""), { download: true })
        .then((res) => this.downloadBlobQuote(res.Body, "Bill_Ticket"))
        .catch((err) => console.log(err))
    );
  };

  downloadBlobQuote(blob, fileName) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    message.success("Downloaded successfully");
    this.setState({
      loading: false,
    });
    return a;
  }

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  onClearFilter = () => {
    const { totalData } = this.state;
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.setState({
      filterData: totalData,
      visible: false,
      checkData: [],
    });
    this.props.getAccountsPayable();
  };
  onResetData = () => {
    this.setState({
      newData: this.props.result?.filter((c) => !c.billManagement),
      filterData: this.state.totalData,
    });
    // this.props.actionFetchCompanies();
  };

  searchResult = (searchValue) => {
    const { totalData } = this.state;
    let searchFilter = [];
    console.log(totalData);
    if (typeof searchValue === "number") {
      searchFilter = totalData.filter(
        (item) =>
          item?.jobNumber.toString().indexOf(searchValue.toString()) > -1 ||
          item?.ticketNumber?.toLowerCase().indexOf(searchValue.toString()) > -1
      );
    } else {
      searchFilter = totalData?.filter(
        (item) =>
          item?.selectedMaterial[0]?.vendor
            ?.toLowerCase()
            .indexOf(searchValue) > -1
      );
    }

    this.setState({
      filterData: searchFilter,
    });
  };

  showTable = (billColumns, key) => {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const { filterData, selectedRowKeys, selectedData } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const newData = filterData?.filter((e) =>
      key === 1 ? !e.billUploaded : e.billUploaded
    );
    return (
      <Row>
        <Col
          className="show-early-pay-discount"
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <Col span={6} className="quarrytoolgrid">
            <SearchFunction
              getSearchData={this.searchResult}
              onResetData={this.onResetData}
            />
          </Col>
          <Col span={18}>
            <div
              style={{
                marginBottom: "12px",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "end",
              }}
            >
              <Col className="gridbtntool">
                <Button
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => this.downloadZip(selectedData)} // Pass selected data to download function
                  icon={
                    <DownloadOutlined
                      style={{
                        fontSize: "20px",
                        marginLeft: "8px",
                        marginTop: "2px",
                      }}
                      className="trucking-filter-icon"
                    />
                  }
                >
                  <span
                    style={{
                      color: "#5a6067",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                    className="col_styling table-font-mobile"
                  >
                    Images
                  </span>
                </Button>
              </Col>
              <Col className="gridbtntool">
                <Button
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => {
                    this.exportData(selectedData);
                  }}
                >
                  <img
                    src={selectedData?.length > 0 ? excelIcon : excelIcon1}
                    alt="excelIcon"
                    style={{
                      width: "24px",
                      height: "auto",
                      marginRight: "8px",
                    }}
                  />
                  <span
                    style={{
                      color: "#5a6067",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                    className="col_styling table-font-mobile"
                  >
                    Excel
                  </span>
                </Button>
              </Col>

              <Col className="gridbtntool">
                <Button
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => {
                    this.handleMarkUpload(
                      selectedData,
                      key === 1 ? true : false
                    );
                  }}
                  icon={
                    <DownloadOutlined
                      style={{
                        fontSize: "20px",
                        marginLeft: "8px",
                        marginTop: "2px",
                      }}
                      className="trucking-filter-icon"
                    />
                  }
                >
                  <span
                    style={{
                      color: "#5a6067",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                    className="col_styling table-font-mobile"
                  >
                    {"Upload"}
                  </span>
                </Button>
              </Col>

              <Col className="gridbtntool">
                <Button
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => {
                    this.setState({
                      selectedRowKeys: [],
                      selectedData: [],
                    });
                  }}
                  icon={
                    <CloseOutlined
                      style={{
                        fontSize: "18px",
                        marginLeft: "8px",
                        marginTop: "2px",
                      }}
                      className="trucking-filter-icon"
                    />
                  }
                >
                  <span
                    style={{
                      color: "#5a6067",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                    className="col_styling table-font-mobile"
                  >
                    Cancel
                  </span>
                </Button>
              </Col>

              <Col className="gridbtntool">
                <Button
                  className="filterButton"
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => {
                    this.setState({ visible: true });
                  }}
                >
                  <Row>
                    <Col>
                      <span
                        style={{
                          color:
                            Object.keys(filterInfo)?.length > 0
                              ? "red"
                              : "#5a6067",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                        className="col_styling table-font-mobile"
                      >
                        {Object.keys(filterInfo)?.length > 0
                          ? `${"Filter (" + Object.keys(filterInfo)?.length})`
                          : "Filter"}
                      </span>
                    </Col>
                    <Col>
                      <IoFilterSharp
                        style={{
                          fontSize: "22px",
                          marginLeft: "8px",
                          marginTop: "2px",
                          color:
                            Object.keys(filterInfo)?.length > 0
                              ? "red"
                              : "#5a6067",
                        }}
                        className="trucking-filter-icon"
                      />
                    </Col>
                  </Row>
                </Button>
              </Col>
            </div>
          </Col>
        </Col>
        <Col
          xxl={{ span: 24 }}
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Card bodyStyle={{ padding: "0px" }} data-aos="fade-up">
            <BaseTable
              className="financeinvoice_table table_laptopscreen"
              columnsData={billColumns}
              sortDirections={["ascend", "descend"]}
              source={newData}
              handleTableChage={(e) =>
                this.handleTableChage(e, "APAllInvoices")
              }
              sizeRange={
                window.screen.width > 1023
                  ? JSON.parse(localStorage.getItem("tableSizeRange"))
                      ?.APAllInvoices
                  : newData?.length
              }
              pagination={window.screen.width > 1023 ? true : false}
              total={newData?.length}
              rowKey={(record) => record.id}
              rowSelection={rowSelection}
            />
          </Card>
        </Col>
      </Row>
    );
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  handleTabChange = (e, data) => {
    let newData = data.filter((element) =>
      e === 1 ? !element.billUploaded : element.billUploaded
    );
    const filterMaterialInfo = newData?.filter(
      (value, index, self) =>
        self.findIndex(
          (e) =>
            e?.selectedMaterial[0]?.materialId ===
            value?.selectedMaterial[0]?.materialId
        ) === index
    );
    const filterVendorInfo = newData?.filter(
      (value, index, self) =>
        self.findIndex(
          (e) =>
            e?.selectedMaterial[0]?.vendorId ===
            value?.selectedMaterial[0]?.vendorId
        ) === index
    );
    this.setState({
      billKey: e,
      filterVendorInfo,
      materialCategory: filterMaterialInfo?.sort((a, b) =>
        a.selectedMaterial[0]?.category > b.selectedMaterial[0]?.category
          ? 1
          : -1
      ),
    });
  };

  filterResultData = (filterCriteria, noUpdate) => {
    const { totalData } = this.state;
    let resultData = this.findAllFilterData(totalData, filterCriteria);
    this.setState({
      filterData: resultData,
      checkData: filterCriteria,
    });
    let filterInfo = filterCriteria;
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
    });
    if (!noUpdate) {
      localStorage.setItem(
        "filterStorageData",
        JSON.stringify({
          filter: filterCriteria,
          filterShowData: filterInfo,
        })
      );
    }
    this.closeFilter();
  };

  findAllFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.vendorId ||
          filterCriteria.vendorId === item.selectedMaterial[0].vendorId) &&
        (!filterCriteria.materialId ||
          filterCriteria.materialId === item.selectedMaterial[0]?.materialId) &&
        (!filterCriteria.min ||
          (parseInt(filterCriteria.min) <=
            parseInt(item.selectedMaterial[0]?.materialCost * item.totalTons) &&
            parseInt(filterCriteria.max) >=
              parseInt(
                item.selectedMaterial[0]?.materialCost * item.totalTons
              ))) &&
        (!filterCriteria.dateRange ||
          moment(moment(item.createdAt).format("l")).isBetween(
            moment(filterCriteria.dateRange[0]).format("l"),
            moment(filterCriteria.dateRange[1]).format("l")
          ))
      ) {
        return true;
      }
      return false;
    });
  };

  render() {
    const {
      billViewData,
      viewData,
      filterData,
      localLoading,
      visible,
      materialCategory,
      checkData,
      filterVendorInfo,
    } = this.state;

    const { loading } = this.props;

    const Controls = () => {
      const { resetTransform } = useControls();

      return (
        <div className="tools">
          {/* <span class="custom-button" onClick={() => zoomIn()}>
            Zoom In
          </span>
          <span class="custom-button1" onClick={() => zoomOut()}>
            Zoom Out
          </span> */}
          <span class="custom-button2" onClick={() => resetTransform()}>
            Reset
          </span>
        </div>
      );
    };

    const billColumns = [
      {
        title: "Bill Number #",
        dataIndex: "ticketNumber",
        key: "ticketNumber",
        sorter: (a, b) => (a?.ticketNumber < b?.ticketNumber ? -1 : 1),
        className: "col_styling",
        width: "11%",
        show: false,
        onCell: (record) => ({
          onDoubleClick: () => {
            this.setState({
              viewData: true,
              billViewData: record,
            });
          },
        }),
        render: (ticketNumber, record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {record?.jobNumber + "AP - " + ticketNumber}
            </span>
          );
        },
      },
      {
        title: "VENDOR NAME",
        dataIndex: "invoiceId",
        key: "invoiceId",
        sorter: (a, b) => {
          const aValue =
            a?.selectedMaterial[0]?.assignTrucksInfo[0]?.carrier_name;
          const bValue =
            b?.selectedMaterial[0]?.assignTrucksInfo[0]?.carrier_name;

          return aValue?.toLowerCase() > bValue?.toLowerCase() ? 1 : -1;
        },
        className: "col_styling",
        width: "20%",
        show: false,
        onCell: (record) => ({
          onDoubleClick: () => {
            this.setState({
              viewData: true,
              billViewData: record,
            });
          },
        }),
        render: (invoiceId, record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                color: "#212121",
              }}
            >
              {record?.selectedMaterial[0]?.vendor}
            </span>
          );
        },
      },
      {
        title: "Bill Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => {
          const createdAtA = moment(a.createdAt);
          const createdAtB = moment(b.createdAt);
          return createdAtA - createdAtB;
        },
        className: "col_styling",
        // width: "8%",
        show: false,
        onCell: (record) => ({
          onDoubleClick: () => {
            this.setState({
              viewData: true,
              billViewData: record,
            });
          },
        }),
        render: (createdAt) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(createdAt).format("l")}
            </span>
          );
        },
      },
      {
        title: "Due Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => {
          const createdAtA = moment(a.createdAt);
          const createdAtB = moment(b.createdAt);
          return createdAtA - createdAtB;
        },
        className: "col_styling",
        // width: "8%",
        show: false,
        onCell: (record) => ({
          onDoubleClick: () => {
            this.setState({
              viewData: true,
              billViewData: record,
            });
          },
        }),
        render: (createdAt, record) => {
          let terms =
            record?.selectedMaterial[0]?.vendorTerms?.split("Net ")[1];
          let dueDate = moment(createdAt).add(terms, "days").calendar();
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(dueDate).format("l")}
            </span>
          );
        },
      },
      {
        title: "Terms",
        dataIndex: "",
        key: "terms",
        className: "col_styling",
        // width: "8%",
        show: false,
        onCell: (record) => ({
          onDoubleClick: () => {
            this.setState({
              viewData: true,
              billViewData: record,
            });
          },
        }),
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {record?.selectedMaterial[0]?.vendorTerms}
            </span>
          );
        },
      },
      // {
      //   title: "Memo",
      //   dataIndex: "truckNumber",
      //   key: "truckNumber",
      //   className: "col_styling",
      //   width: "10%",
      //   show: false,
      //   onCell: (record) => ({
      //     onDoubleClick: () => {
      //       this.setState({
      //         showPDFview: !billManagement,
      //         viewData: billManagement,
      //         paymentData: record,
      //         billViewData: record,
      //       });
      //     },
      //   }),
      //   render: (truckNumber, record) => {
      //     return (
      //       <span
      //         className="col_styling table-font-mobile-accounts"
      //         style={{
      //           textTransform: "capitalize",
      //         }}
      //       >
      //         {truckNumber + "#, " + record?.ticketDetails[0]?.jobName}
      //       </span>
      //     );
      //   },
      // },
      // {
      //   title: "Expense Account",
      //   dataIndex: "",
      //   key: "materialCost",
      //   className: "col_styling",
      //   width: "12%",
      //   show: false,
      //   onCell: (record) => ({
      //     onDoubleClick: () => {
      //       this.setState({
      //         viewData: true,
      //         billViewData: record,
      //       });
      //     },
      //   }),
      //   render: (record) => {
      //     return (
      //       <span
      //         className="col_styling table-font-mobile-accounts"
      //         style={{
      //           textTransform: "capitalize",
      //         }}
      //       >
      //         {`$ ` +
      //           record?.vendor[0]?.materialRate?.toLocaleString("en-US", {
      //             minimumFractionDigits: 2,
      //             maximumFractionDigits: 2,
      //           })}
      //       </span>
      //     );
      //   },
      // },
      {
        title: "Description",
        dataIndex: "",
        key: "Description",
        className: "col_styling",
        // width: "15%",
        show: false,
        onCell: (record) => ({
          onDoubleClick: () => {
            this.setState({
              viewData: true,
              billViewData: record,
            });
          },
        }),
        render: (record) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {record?.selectedMaterial[0]?.materialShortValue}
            </span>
          );
        },
      },
      {
        title: "Amount",
        dataIndex: "",
        key: "materialCost",
        className: "col_styling",
        // width: "10%",
        show: false,
        onCell: (record) => ({
          onDoubleClick: () => {
            this.setState({
              viewData: true,
              billViewData: record,
            });
          },
        }),
        render: (record) => {
          let materialCost =
            record?.selectedMaterial[0].materialCost * record?.totalTons;
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
              }}
            >
              {`$ ` +
                materialCost?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </span>
          );
        },
      },
    ].filter((e) => !e.show);

    return (
      <div>
        <Loading enableLoading={loading || localLoading} />

        <Row>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Divider className="linedivider show-on-desktop" />
            <div className="tabstatus">
              <Tabs
                defaultActiveKey={1}
                className="tabversi"
                onChange={(e) => this.handleTabChange(e, filterData)}
              >
                <Tabs.TabPane
                  tab={
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col>Not Uploaded</Col>
                      <Badge
                        count={
                          filterData?.filter((e) => !e.billUploaded)?.length
                        }
                        dot={false}
                        style={{
                          backgroundColor: "#586370",
                          margin: "1px 5px",
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                        overflowCount={1000}
                      />
                    </Row>
                  }
                  key={1}
                >
                  {this.showTable(billColumns, 1)}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col>Uploaded</Col>
                      <Badge
                        count={
                          filterData?.filter((e) => e.billUploaded)?.length
                        }
                        dot={false}
                        style={{
                          backgroundColor: "#586370",
                          margin: "1px 5px",
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                        overflowCount={1000}
                      />
                    </Row>
                  }
                  key={2}
                >
                  {this.showTable(billColumns, 2)}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>

        {viewData && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "auto" }}>View Details</span>
                <Button
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    marginRight: "45px",
                  }}
                  onClick={() => {
                    this.fileDownloadQuote(billViewData?.singlePdfTicket);
                  }}
                  icon={
                    <DownloadOutlined
                      style={{
                        fontSize: "20px",
                        marginTop: "2px",
                      }}
                      className="trucking-filter-icon"
                    />
                  }
                >
                  Download
                </Button>
              </div>
            }
            onCancel={() =>
              this.setState({
                viewData: false,
                billViewData: "",
              })
            }
            formId="ticketForm"
            loading={loading}
            footer={false}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "850px"
            }
            disabled={true}
          >
            {billViewData?.singlePdfTicket !== "" && (
              <Col
                xxl={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                className="pdfviewcls"
              >
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={1}
                  initialPositionY={1}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <Controls />
                      <TransformComponent>
                        <PDFViewer
                          url={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${billViewData?.singlePdfTicket}?#view=FitH`}
                          viewer="url"
                          style={{
                            width: "100%",
                            height: "500px",
                            overflow: "auto",
                          }}
                          onError={(error) => {
                            console.error("Error loading PDF:", error);
                          }}
                        />
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </Col>
            )}
          </BaseModal>
        )}

        {visible && (
          <Filter
            visible={visible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            onClickClearFilter={this.onClearFilter}
            billData={checkData}
            vendorData={filterVendorInfo}
            billManagement
            materialCategory={materialCategory}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state?.reducer?.accountsResult?.loading,
  invoiceInfo: state?.reducer?.invoiceInfo,
  accountsResult: state?.reducer?.accountsResult,
});

const mapDispatchToProps = (dispatch) => ({
  getAccountsPayable: () => {
    dispatch(getAccountsPayable());
  },
  updateMultipleInvoice: (data) => {
    dispatch(updateMultipleInvoice(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(BillManagement)));
