import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Storage } from "aws-amplify";
import { Row, Col, Input, Button, message, Checkbox } from "antd";
import {
  // UnorderedListOutlined,
  // PictureOutlined,
  LikeOutlined,
  LikeFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import BaseTable from "../../Common/BaseTable";
import {
  getQuarryLocations,
  getDropDownOptions,
  updateQuarry,
} from "../../Redux/Actions/actions";
import Loading from "../../Common/Loading";
import BaseModal from "../../Common/BaseModal";
import MapComponent from "../Maps/MapComponent";
import Filter from "../../Common/Filter";
import { add } from "../../api/Apis";
// import { FaMap } from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import Carousel from "react-elastic-carousel";
import { saveAs } from "file-saver";
import JSZip from "jszip";

const { Search } = Input;

export default function MaterialDatabase() {
  const [localLoading, setLocalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [selected, setSelected] = useState("listView");
  const [filterVisible, setFilterVisible] = useState(false);
  const [materialDispatch, setMaterialDispatch] = useState(true);
  const [loadingDispatch, setLoadingDispatch] = useState(true);
  const [checkData, setCheckData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [materialCategory, setMaterialCategory] = useState({});
  const [filterMaterialData, setFilterMaterialData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [totalMaterialData, setTotalMaterialData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [filtergroupedData, setFilterGroupedData] = useState([]);
  const [pictureData, setPictureData] = useState([]);
  const [allPictureData, setAllPictureData] = useState([]);
  const [quarryInfo, setQuarryInfo] = useState({});
  const [downloadList, setDownloadList] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQuarryLocations());
    dispatch(getDropDownOptions());
  }, [dispatch]);

  const loading = useSelector((state) => state.reducer.quarriesResult.loading);
  const totalData = useSelector(
    (state) => state.reducer.quarriesResult.quarryData
  );
  const dropLoading = useSelector((state) => state?.reducer?.dropDownOptions);
  const dropDownOptions = useSelector((state) =>
    state?.reducer?.dropDownOptions?.totalData?.filter(
      (m) => m?.typeofDropDown === "material"
    )
  );

  useEffect(() => {
    if (
      dropLoading?.loading === false &&
      dropLoading?.success === true &&
      materialDispatch
    ) {
      handleDropdown(dropDownOptions);
      setMaterialDispatch(!materialDispatch);
    }
    if (!loading && loadingDispatch && totalData?.length) {
      handleMaterialData(totalData);
      setLoadingDispatch(!loadingDispatch);
    }
  }, [
    dropLoading,
    materialDispatch,
    dropDownOptions,
    loading,
    totalData,
    loadingDispatch,
  ]);

  const handleMaterialData = (record, type) => {
    const materialsData = record
      ?.map((e) => {
        return e?.material?.map((mt) => {
          mt["quarryId"] = e["id"];
          mt["quarryName"] = e["quarryName"];
          mt["quarryPhone"] = e["phone"];
          mt["address"] = e["address"];
          mt["lat"] = e["lat"];
          mt["lng"] = e["lng"];
          return mt;
        });
      })
      .flat();
    let filtersInfo = [];
    if (type !== "filter") {
      filtersInfo = materialsData?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
    } else {
      filtersInfo = materialsData;
    }

    const groupedData = {};
    filtersInfo?.forEach((element) => {
      groupedData[element["category"]] =
        element["category"] in groupedData === true
          ? groupedData[element["category"]]
          : [];
      groupedData[element["category"]].push(element);
    });
    setFilterGroupedData(groupedData);
    setGroupedData(groupedData);
    if (type === "filter") {
      return filtersInfo;
    } else {
      setTotalMaterialData(materialsData);
      setMaterialData(filtersInfo);
      setFilterMaterialData(filtersInfo);
    }
  };

  const handleDropdown = (record) => {
    let materialData = {};
    let index = 0;
    let sortedMaterialList = record[0]?.materialsNewList
      ?.filter((v) => v.statusCode)
      .sort((a, b) => a?.indexVal - b?.indexVal);
    sortedMaterialList?.map((eachMat) => {
      let subTypes = [];
      if (eachMat?.statusCode) {
        index += 1;
        materialData[eachMat.category] = !Object.keys(materialData).includes(
          eachMat.category
        )
          ? []
          : materialData[eachMat.category];
        let typeCheck = materialData[eachMat.category].filter(
          (o) => o.subcat === eachMat.materialValue.split(" - ")[1]
        );

        if (typeCheck.length > 0) {
          subTypes = typeCheck[0]["subTypes"];
          subTypes.push({
            subType: eachMat.materialValue.split(" - ")[2],
            id: eachMat.id,
          });
        } else {
          subTypes.push({
            subType: eachMat.materialValue.split(" - ")[2],
            id: eachMat.id,
          });
          materialData[eachMat.category].push({
            subcat: eachMat.materialValue.split(" - ")[1],
            cate: eachMat.materialValue.split(" - ")[0],
            categoryKey: eachMat.categoryKey,
            subShortName: eachMat.subShortName,
            subTypes,
          });
        }
        if (sortedMaterialList?.filter((e) => e.statusCode)?.length === index) {
          setMaterialCategory(materialData);
          setMaterialDispatch(false);
        }
      }
      return true;
    });
  };

  const handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  const showModalData = (record) => {
    let materialInfo = totalMaterialData?.filter(
      (element) => element.id === record.id
    );
    setPictureData(
      materialInfo.map((element) => element.materialPicture)?.flat()
    );
    setAllPictureData(
      materialInfo.map((element) => element.materialPicture)?.flat()
    );
    setDownloadList([]);
    setQuarryInfo(record);
    setShowModal(true);
    setModalData(materialInfo);
  };

  const searchData = (event) => {
    let searchValue = "";
    if (event === "") {
      setFilterMaterialData(filterMaterialData);
    }
    if (Number(event)) {
      searchValue = parseInt(event);
    } else {
      searchValue = event.trim().toLowerCase();
    }

    let filterInfo = materialData?.filter(
      (e) =>
        e?.category?.toLowerCase().includes(searchValue.toLowerCase()) ||
        e?.subCategory?.toLowerCase().includes(searchValue.toLowerCase()) ||
        e?.label?.toLowerCase().includes(searchValue.toLowerCase()) ||
        e?.materialTest?.toLowerCase().includes(searchValue.toLowerCase()) ||
        e?.materialStatus?.toLowerCase().includes(searchValue.toLowerCase()) ||
        e?.materialSupply?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilterMaterialData(filterInfo);
  };

  const closeFilter = () => {
    setFilterVisible(false);
  };

  const onClearFilter = () => {
    setGroupedData(filtergroupedData);
    setFilterMaterialData(materialData);
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    setFilterVisible(false);
    setCheckData([]);
    dispatch(getQuarryLocations());
  };

  const filterResultData = async (data) => {
    const mergedList = data["subCategoryId"]?.flatMap((item) =>
      item.key.split(",")
    );

    let testInfo = {
      quarryType:
        data["quarryType"]?.length > 0 ? data["quarryType"] : undefined,
      origin: data["origin"],
      quarryAddress: data["quarryAddress"] ? data["quarryAddress"] : undefined,
      subCategoryId: mergedList ? mergedList : undefined,
      radius: data["radius"] ? data["radius"] : undefined,
      materialDiameter: data["materialDiameter"]
        ? data["materialDiameter"]
        : undefined,
      materialStatus:
        data["materialStatus"]?.length > 0 ? data["materialStatus"] : undefined,
      materialSupply:
        data["materialSupply"]?.length > 0 ? data["materialSupply"] : undefined,
      materialTest:
        data["materialTest"]?.length > 0 ? data["materialTest"] : undefined,
      quarryMaterial:
        data["quarryMaterial"]?.length > 0 ? data["quarryMaterial"] : undefined,
    };
    let resultData = [];

    if (
      testInfo["quarryAddress"] !== "" &&
      testInfo["quarryAddress"] !== undefined
    ) {
      let submitData = {
        entityData: {
          origin: data.origin,
          filterData: [],
          radius: data.radius,
        },
      };

      let filterAddress = (await getFilterAddress(submitData)) || [];
      resultData = findAllFilterData(filterAddress, testInfo);
    } else {
      resultData = findAllFilterData(materialData, testInfo);
      const groupedData = {};
      resultData?.forEach((element) => {
        groupedData[element["category"]] =
          element["category"] in groupedData === true
            ? groupedData[element["category"]]
            : [];
        groupedData[element["category"]].push(element);
      });
      setGroupedData(groupedData);
    }
    let filterInfo = data || {};
    if (data?.subCategoryId) {
      const { subCategoryId, quarryType, ...employeeRest } = data;
      filterInfo = employeeRest;
    }
    const { quarryType, origin, subCategoryId, ...employeeRest } = filterInfo;
    filterInfo = employeeRest;
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === null) {
        delete filterInfo[key];
      }
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
      if (filterInfo[key]?.length === 0) {
        delete filterInfo[key];
      }
    });
    localStorage.setItem(
      "filterStorageData",
      JSON.stringify({
        filter: data,
        filterShowData: filterInfo,
      })
    );
    setFilterMaterialData(resultData);
    setCheckData(data);
    closeFilter();
  };

  const getFilterAddress = async (submitData) => {
    const result = await add("getDist/quarryLocations", submitData);
    const updatedMaterialData = await handleMaterialData(result.data, "filter");
    return updatedMaterialData;
  };

  const findAllFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.quarryType ||
          filterCriteria.quarryType.includes(item.categoryKey)) &&
        (!filterCriteria.subCategoryId ||
          filterCriteria.subCategoryId.includes(item.id)) &&
        (!filterCriteria.materialDiameter ||
          filterCriteria.materialDiameter === item.materialDiameter) &&
        (!filterCriteria.quarryMaterial ||
          filterCriteria.quarryMaterial.includes(item.id)) &&
        (!filterCriteria.materialTest ||
          filterCriteria.materialTest.includes(item.materialTest)) &&
        (!filterCriteria.materialSupply ||
          filterCriteria.materialSupply.includes(item.materialSupply)) &&
        (!filterCriteria.materialStatus ||
          filterCriteria.materialStatus.includes(item.materialStatus))
      ) {
        return true;
      }
      return false;
    });
  };

  const fileDownloadQuote = async (fileName, type) => {
    setLocalLoading(true);
    Storage.get(fileName, { download: true })
      .then((res) => downloadBlobQuote(res.Body, "Material_Picture"))
      .catch((err) => console.log(err));
  };

  const downloadImages = async (selectedFiles) => {
    if (selectedFiles?.length > 0) {
      setLocalLoading(true);
      selectedFiles?.map((e) =>
        Storage.get(e?.quoteAttachmentId, { download: true })
          .then((res) => downloadBlobQuote(res.Body, "Material_Picture"))
          .catch((err) => console.log(err))
      );
    } else {
      message.info("Please select at least one image");
    }
  };

  const downloadZip = async () => {
    setLocalLoading(true);
    const zip = new JSZip();
    // Create an array of promises to fetch and add images to the zip file
    const promises = allPictureData?.map(async (image) => {
      try {
        const response = await Storage.get(image?.quoteAttachmentId, {
          download: true,
        });
        const blob = response.Body;
        zip.file(`${image.fileName}`, blob, { binary: true });
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Generate the zip file
    const content = await zip.generateAsync({ type: "blob" });
    // Save the zip file
    saveAs(content, "images.zip");

    setLocalLoading(false);
  };

  const downloadBlobQuote = (blob, fileName) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    message.success("Downloaded successfully");
    setLocalLoading(false);
    return a;
  };

  const handleLike = (record) => {
    setLocalLoading(true);
    let updatePicture = pictureData?.filter((e) => e.id === record.id);
    if (record.isLike) {
      updatePicture[0]["isLike"] = false;
    } else {
      updatePicture[0]["isLike"] = true;
    }
    allPictureData.splice(
      allPictureData.findIndex((e) => e.id === record.id),
      1,
      updatePicture[0]
    );
    quarryInfo["materialPicture"] = allPictureData;

    const updateQuarryInfo = totalData?.filter(
      (e) => e?.id === quarryInfo?.quarryId
    )[0];
    updateQuarryInfo.material.splice(
      updateQuarryInfo?.material?.findIndex((e) => e.id === quarryInfo.id),
      1,
      quarryInfo
    );

    let data = {
      entityData: {
        material: updateQuarryInfo.material,
      },
    };
    dispatch(updateQuarry(updateQuarryInfo?.id, data));
    setLocalLoading(false);
  };

  const selectDownlaod = (event, record) => {
    let downloadData = downloadList?.length > 0 ? downloadList : [];
    if (event.target.checked) {
      downloadData.push(record);
      setSelectedCount((prevCount) => prevCount + 1);
    } else {
      const index = downloadData.findIndex((item) => item.key === record.key);
      downloadData.splice(index, 1);
      setSelectedCount((prevCount) => prevCount - 1);
    }
    setDownloadList(downloadData);
  };

  const showPictures = (pictureData) => {
    let sortedData = pictureData?.sort((a, b) => {
      // If both have isLike property, compare by isLike
      if ("isLike" in a && "isLike" in b) {
        return b.isLike - a.isLike; // Reversed order to have true before false
      }

      // If only one has isLike property, prioritize the one with isLike
      if ("isLike" in a) {
        return -1;
      }

      if ("isLike" in b) {
        return 1;
      }

      // If none have isLike property, keep the order unchanged
      return 0;
    });

    return (
      <Row gutter={[24, 0]} style={{ margin: "0px", width: "100%" }}>
        <Loading enableLoading={localLoading} />
        <Carousel
          // showArrows={false}
          pagination
          itemPosition="CENTER"
          disableArrowsOnEnd
          enableMouseSwipe
          enableSwipe
          // enableAutoPlay
          // autoPlaySpeed={2000}
          itemsToShow={1}
          className="home-carousel"
        >
          {sortedData?.map((i) => (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ height: "100%", marginBottom: "1vw", width: "100%" }}
            >
              <div className="container">
                <img
                  className="image"
                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${i.quoteAttachmentId}`}
                  alt=""
                  style={{
                    height: "18vw",
                    width: "100%",
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                />

                <Checkbox
                  style={{
                    background: "#586370",
                    fontSize: "14px",
                    padding: "2px 5px",
                    float: "right",
                    marginTop: "1px",
                  }}
                  onChange={(e) => selectDownlaod(e, i)}
                />
                <div className="likeStyles" onClick={() => handleLike(i)}>
                  {i?.isLike ? (
                    <span>
                      <LikeFilled style={{ marginRight: "5px" }} />
                      Liked
                    </span>
                  ) : (
                    <span>
                      <LikeOutlined style={{ marginRight: "5px" }} />
                      Like
                    </span>
                  )}
                </div>

                <div
                  className="middle"
                  onClick={() =>
                    fileDownloadQuote(
                      i.quoteAttachmentId,
                      "materialPicture",
                      "docAttachment"
                    )
                  }
                >
                  <div className="downloadIcon">
                    <DownloadOutlined
                      style={{
                        fontSize: "30px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Carousel>
        <Row style={{ display: "flex", justifyContent: "end", width: "90%" }}>
          <Col className="gridbtntool">
            <Button
              className="button_Crm"
              style={{
                borderRadius: "5px",
                height: "40px",
              }}
              onClick={() => {
                downloadImages(downloadList);
              }}
              icon={
                <DownloadOutlined
                  style={{
                    fontSize: "20px",
                    marginLeft: "8px",
                    marginTop: "2px",
                  }}
                  className="trucking-filter-icon"
                />
              }
            >
              <span
                style={{
                  color: "#5a6067",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
                className="col_styling table-font-mobile"
              >
                <div>{`Download ${
                  downloadList?.length > 0 ? `(${selectedCount})` : ""
                }`}</div>
              </span>
            </Button>
          </Col>
          <Col className="gridbtntool">
            <Button
              className="button_Crm"
              style={{
                borderRadius: "5px",
                height: "40px",
              }}
              onClick={downloadZip}
              icon={
                <DownloadOutlined
                  style={{
                    fontSize: "20px",
                    marginLeft: "8px",
                    marginTop: "2px",
                  }}
                  className="trucking-filter-icon"
                />
              }
            >
              <span
                style={{
                  color: "#5a6067",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
                className="col_styling table-font-mobile"
              >
                <div>{`Download All (${allPictureData?.length})`}</div>
              </span>
            </Button>
          </Col>
        </Row>
      </Row>
    );
  };

  const columns = [
    {
      title: "Material Category",
      dataIndex: "category",
      key: "category",
      show: true,
      sorter: false,
      className: "col_style_quarry",
      onCell: (record) => ({
        onClick: () => {
          showModalData(record);
        },
      }),
      render: (category) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {category}
          </span>
        );
      },
    },
    {
      title: "Material Type",
      dataIndex: "subCategory",
      key: "subCategory",
      show: true,
      sorter: false,
      className: "col_style_quarry",
      onCell: (record) => ({
        onClick: () => {
          showModalData(record);
        },
      }),
      render: (material, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {record?.subCategory?.split("-")[1]}
          </span>
        );
      },
    },
    {
      title: "Material Name",
      dataIndex: "material",
      key: "label",
      show: true,
      sorter: false,
      className: "col_style_quarry",
      onCell: (record) => ({
        onClick: () => {
          showModalData(record);
        },
      }),
      render: (material, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {record?.label?.split("-")[1]}
          </span>
        );
      },
    },
    {
      title: "Testing Status",
      dataIndex: "materialTest",
      key: "materialTest",
      sorter: false,
      className: "col_style_quarry",
      onCell: (record) => ({
        onClick: () => {
          showModalData(record);
        },
      }),
      render: (materialTest, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: !record?.statusCode ? "red" : "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {materialTest}
          </span>
        );
      },
    },
    {
      title: "Diameter",
      dataIndex: "materialDiameter",
      key: "materialDiameter",
      sorter: false,
      className: "col_style_quarry",
      onCell: (record) => ({
        onClick: () => {
          showModalData(record);
        },
      }),
      render: (materialDiameter, record) => {
        return (
          <span
            className="col_styling"
            style={{
              color:
                (!record?.statusCode ||
                  (!record.materialCheckDiameter &&
                    materialDiameter === "Unknown")) &&
                "red",
              textTransform: "capitalize",
            }}
          >
            {record.materialCheckDiameter
              ? `${record.materialMinDiameter}"` +
                " - " +
                `${record?.materialMaxDiameter}"`
              : materialDiameter === "Unknown"
              ? materialDiameter
              : `${materialDiameter}"`}
          </span>
        );
      },
    },
    {
      title: "Washed",
      dataIndex: "materialStatus",
      key: "materialStatus",
      sorter: false,
      className: "col_style_quarry",
      onCell: (record) => ({
        onClick: () => {
          showModalData(record);
        },
      }),
      render: (materialStatus, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: !record?.statusCode ? "red" : "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {materialStatus}
          </span>
        );
      },
    },
    {
      title: "Supply",
      dataIndex: "materialSupply",
      key: "materialSupply",
      sorter: false,
      className: "col_style_quarry",
      onCell: (record) => ({
        onClick: () => {
          showModalData(record);
        },
      }),
      render: (materialSupply, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: !record?.statusCode ? "red" : "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {materialSupply}
          </span>
        );
      },
    },
    {
      title: "Proximity to Address",
      dataIndex: "address",
      key: "address",
      sorter: false,
      className: "col_style_quarry",
      onCell: (record) => ({
        onClick: () => {
          showModalData(record);
        },
      }),
      render: (address) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {address}
          </span>
        );
      },
    },
  ];

  const materialColumns = [
    {
      title: "Quarry Name",
      dataIndex: "quarryName",
      key: "quarryName",
      sorter: false,
      className: "col_style_quarry",
      render: (quarryName) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {quarryName}
          </span>
        );
      },
    },
    {
      title: "Quarry Phone",
      dataIndex: "quarryPhone",
      key: "quarryPhone",
      sorter: false,
      className: "col_style_quarry",
      render: (quarryPhone) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {quarryPhone}
          </span>
        );
      },
    },
    {
      title: "Quarry Address",
      dataIndex: "address",
      key: "address",
      sorter: false,
      className: "col_style_quarry",
      render: (address) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {address}
          </span>
        );
      },
    },
    {
      title: "Diameter",
      dataIndex: "materialDiameter",
      key: "materialDiameter",
      sorter: false,
      width: "13%",
      className: "col_style_quarry",
      render: (materialDiameter, record) => {
        return (
          <span
            className="col_styling"
            style={{
              color:
                (!record?.statusCode ||
                  (!record.materialCheckDiameter &&
                    materialDiameter === "Unknown")) &&
                "red",
              textTransform: "capitalize",
            }}
          >
            {record.materialCheckDiameter
              ? `${record.materialMinDiameter}"` +
                " - " +
                `${record?.materialMaxDiameter}"`
              : materialDiameter === "Unknown"
              ? materialDiameter
              : `${materialDiameter}"`}
          </span>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: false,
      width: "10%",
      className: "col_style_quarry",
      render: (price, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: !record?.statusCode ? "red" : "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {`$ ${parseFloat(price)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })} Per ${record?.quantityType}`}
          </span>
        );
      },
    },

    {
      title: "Washed",
      dataIndex: "materialStatus",
      key: "materialStatus",
      sorter: false,
      width: "10%",
      className: "col_style_quarry",
      render: (materialStatus, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: !record?.statusCode ? "red" : "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {materialStatus}
          </span>
        );
      },
    },
    {
      title: "Testing",
      dataIndex: "materialTest",
      key: "materialTest",
      sorter: false,
      width: "10%",
      className: "col_style_quarry",
      render: (materialTest, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: !record?.statusCode ? "red" : "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {materialTest}
          </span>
        );
      },
    },
    {
      title: "Supply",
      dataIndex: "materialSupply",
      key: "materialSupply",
      sorter: false,
      width: "10%",
      className: "col_style_quarry",
      render: (materialSupply, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              color: !record?.statusCode ? "red" : "#474747",
              fontSize: "16px",
              fontFamily: "sans-serif",
            }}
            className="col_styling table-font-mobile"
          >
            {materialSupply}
          </span>
        );
      },
    },
  ];

  const filterInfo =
    JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData || {};
  return (
    <div>
      <Loading enableLoading={loading} />
      <Row
        style={{
          borderRadius: "10px",
          marginBottom: "20px",
          padding: "0px 20px",
          backgroundColor: "#ffffff",
          border: "1px solid gray",
          textAlign: "left",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          overflowX: "auto",
        }}
      >
        <div
          className="typematerials"
          style={{ width: "100%", margin: "8px 0px" }}
        >
          {Object.keys(filtergroupedData)?.map((element) => (
            <div
              className="itemtruckType"
              style={{
                maxWidth: `${300 / Object.keys(filtergroupedData)?.length}%`,
                marginRight: "10px",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="owner-operator-card-body"
              >
                <span
                  style={{
                    fontSize: "18px",
                    color: "#747474",
                    fontWeight: "600",
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                  className="owner-operator-card-head"
                >
                  {element}
                </span>
                {groupedData[element] &&
                Object?.values(groupedData[element])?.length > 0
                  ? Object?.values(groupedData[element])?.length
                  : 0}
              </p>
            </div>
          ))}
        </div>
      </Row>
      <Row>
        <Col span={6}>
          <Search
            placeholder="Search"
            size="large"
            onChange={(e) => searchData(e.target.value)}
            enterButton
          />
        </Col>
        <Col span={18} style={{ justifyContent: "end", display: "flex" }}>
          <Button
            className="filterButton"
            style={{
              borderRadius: "5px",
              height: "40px",
            }}
            onClick={() => setFilterVisible(true)}
          >
            <Row>
              <Col>
                <span
                  style={{
                    color:
                      Object.keys(filterInfo)?.length > 0 ? "red" : "#5a6067",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                  className="col_styling table-font-mobile"
                >
                  {Object.keys(filterInfo)?.length > 0
                    ? `${"Filter (" + Object.keys(filterInfo)?.length})`
                    : "Filter"}
                </span>
              </Col>
              <Col>
                <IoFilterSharp
                  style={{
                    fontSize: "22px",
                    marginLeft: "8px",
                    marginTop: "2px",
                    color:
                      Object.keys(filterInfo)?.length > 0 ? "red" : "#5a6067",
                  }}
                  className="trucking-filter-icon"
                />
              </Col>
            </Row>
          </Button>
        </Col>
      </Row>
      <Col span={24} style={{ marginTop: "10px" }}>
        <BaseTable
          className="quarrytooltable table_laptopscreen"
          columnsData={columns}
          source={filterMaterialData}
          handleTableChage={(e) => handleTableChage(e, "materialDataBase")}
          sizeRange={
            window.screen.width > 1023
              ? JSON.parse(localStorage.getItem("tableSizeRange"))
                  ?.materialDataBase
              : filterMaterialData?.length
          }
          pagination={window.screen.width > 1023 ? true : false}
          total={filterMaterialData?.length}
          rowKey={(record) => record.id}
        />
      </Col>

      {showModal && (
        <BaseModal
          title={modalData[0]?.value}
          onCancel={() => {
            setShowModal(false);
            setModalData([]);
            setDownloadList([]);
            setSelectedCount(0);
          }}
          loading={loading}
          className="antmodal_grid headermodal"
          width={
            window.screen.width > "501" && window.screen.width <= "991"
              ? "80%"
              : "80%"
          }
          footer={false}
        >
          <div>
            {/* <Col
              className="gridbtntool"
              style={{
                position: "absolute",
                zIndex: "1000",
                top: "8px",
                right: "50px",
              }}
            >
              <Row
                style={{
                  background: "#cecece",
                  height: "40px",
                  borderRadius: "6px",
                }}
              >
                <Col style={{ marginTop: "4px" }}>
                  <Button
                    style={{
                      border: "none",
                      borderRadius: "5px",
                      padding: "5px 8px",
                      margin: "0px 5px",
                      background:
                        selected === "listView" ? "#FFF" : "transparent",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    onClick={() => setSelected("listView")}
                  >
                    <UnorderedListOutlined
                      style={{
                        fontSize: "22px",
                        color: "#586370",
                      }}
                    />
                  </Button>
                </Col>
                <Col style={{ marginTop: "4px" }}>
                  <Button
                    style={{
                      border: "none",
                      borderRadius: "5px",
                      padding: "5px 8px",
                      margin: "0px 5px",
                      background:
                        selected === "mapView" ? "#FFF" : "transparent",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    onClick={() => setSelected("mapView")}
                  >
                    <FaMap
                      style={{
                        fontSize: "22px",
                        color: "#586370",
                      }}
                    />
                  </Button>
                </Col>
                <Col style={{ marginTop: "4px" }}>
                  <Button
                    style={{
                      border: "none",
                      borderRadius: "5px",
                      padding: "5px 8px",
                      margin: "0px 5px",
                      background:
                        selected === "pictureView" ? "#FFF" : "transparent",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    onClick={() => {
                      setSelected("pictureView");
                    }}
                  >
                    <PictureOutlined
                      style={{
                        fontSize: "22px",
                        color: "#586370",
                      }}
                    />
                  </Button>
                </Col>
              </Row>
            </Col> */}
            <Row style={{ width: "100%" }}>
              <Col span={24}>
                <BaseTable
                  className="quarrytooltable table_laptopscreen"
                  columnsData={materialColumns}
                  source={modalData}
                  handleTableChage={(e) =>
                    handleTableChage(e, "materialDataBaseModal")
                  }
                  sizeRange={
                    window.screen.width > 1023
                      ? JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.materialDataBaseModal
                      : modalData?.length
                  }
                  pagination={window.screen.width > 1023 ? true : false}
                  total={modalData?.length}
                  rowKey={(record) => record.materialId}
                />
              </Col>
              <Row style={{ width: "100%", height: "26vw" }}>
                <Col span={12}>
                  {modalData?.length > 0 && (
                    <div
                      style={{
                        width: "100%",
                        height: "20vw",
                      }}
                    >
                      <MapComponent
                        filteredMapData={modalData}
                        materialDatabase
                      />
                    </div>
                  )}
                </Col>
                {pictureData?.length > 0 && (
                  <Col
                    span={11}
                    style={{
                      width: "100%",
                      height: "26vw",
                      overflow: "auto",
                      marginLeft: "3%",
                    }}
                  >
                    {showPictures(pictureData)}
                  </Col>
                )}
              </Row>
            </Row>
          </div>
        </BaseModal>
      )}

      {filterVisible && (
        <Filter
          visible={filterVisible}
          closeFilter={closeFilter}
          filterResultData={filterResultData}
          filterPropQuarryData={checkData}
          onClearFilter={onClearFilter}
          QuarryTool
          filterQuarryData={totalData}
          materialCategory={materialCategory}
        />
      )}
    </div>
  );
}
