import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Popover,
  Input,
  Form,
  Popconfirm,
  Select,
  message,
  Checkbox,
} from "antd";
import { BsDot } from "react-icons/bs";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import SearchFunction from "../../Common/SearchFunction";
import { add } from "../../api/Apis";
import appConfig from "../../config/AppConfig";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import Loading from "../../Common/Loading";
import ErrorView from "../../Common/ErrorView";
import BaseModal from "../../Common/BaseModal";
import BaseTable from "../../Common/BaseTable";
import TextArea from "antd/lib/input/TextArea";
import Filter from "../../Common/Filter";
import { v4 as uuidv4 } from "uuid";
import { IoFilterSharp } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import {
  addCompany,
  deleteCompany,
  getCompanies,
  getDropDownOptions,
  updateCompany,
  updateDropDownOptions,
  updateArchive
} from "../../Redux/Actions/actions";
import { withAppContext } from "../../Store/AppContext";
import { MdArchive, MdUnarchive } from "react-icons/md";

const { Option } = Select;
class CustomerDatabase extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      visible: false,
      addressState: "",
      zipCode: "",
      citySearch: "",
      filterData: [],
      latitude: "",
      longitude: "",
      filterVisible: false,
      totalCompanyData: this.props.result?.filter((c) => !c.isOwnerOperator),
      filterCompanyData: this.props.result?.filter((c) => !c.isOwnerOperator),
      searchingData: this.props.result?.filter((c) => !c.isOwnerOperator),
      pickUpAddress: "",
      dropAddress: "",
      roleOther: "",
      isContactVisible: false,
      checkData: "",
      dropoffLatitude: "",
      dropoffLongitude: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      selectedTerm: false,
      masterRoleId: "",
      masterTruckId: "",
      companyCheck: "",
      contactInfo: [],
      isCheckcontact: false,
      companyData: "",
      rolesData: [],
      archiveData: true,
    };
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Trucking & Material";
    this.props.actionFetchCompanies();
    this.props.actiongetDropDownOptions();
    document.title = "Twisted Nail - Customer Database";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { actionArchiveCompany, history,dropDownOptions, dropDownOptionsInfo, actionFetchCompanies, actiongetDropDownOptions } = this.props;
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    if (
      JSON.stringify(prevState?.filterCompanyData) ===
      JSON.stringify(this.state?.filterCompanyData)
    ) {
      let hashKeyValue = window.location.hash;
      if (hashKeyValue === "#quickJob") {
        hashKeyValue = "";
        history.push(`${window.location.pathname}`);
        actionFetchCompanies();
        actiongetDropDownOptions();
      }
    }
    if (actionArchiveCompany.success && !prevProps?.actionArchiveCompany.success){
      actionFetchCompanies();
    }
    if (
      prevProps?.companiesInfo?.success === false &&
      this.props?.companiesInfo?.success === true
    ) {
      this.setState({
        selectedAddress: "",
        latitude: "",
        longitude: "",
      });
      actionFetchCompanies();
    }
    if (
      JSON.stringify(prevProps?.result) !== JSON.stringify(this.props?.result)
    ) {
      const hasFilter =
        filterStorageData?.filter &&
        Object.keys(filterStorageData?.filter).length > 0;

      const updtedResult = this.props.result?.filter(
        (c) => !c.isOwnerOperator && c.company
      );
      this.setState(
        {
          totalCompanyData: updtedResult,
          filterCompanyData: updtedResult,
          searchingData: updtedResult,
        },
        () => {
          if (hasFilter) {
            this.filterResultData(filterStorageData?.filter, true);
          }
        }
      );
    }
    if (
      prevProps.dropDownOptions.success === false &&
      dropDownOptions.success === true
    ) {
      this.setState({
        rolesData: this.props?.rolesData[0]?.rolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
      });
    }
    if (
      prevProps?.dropDownOptionsInfo?.success === false &&
      dropDownOptionsInfo?.success === true
    ) {
      this.props.actiongetDropDownOptions();
    }
  };

  onClickRefresh = () => {
    this.funnelInfo();
    this.props.actionFetchCompanies();
  };

  onResetData = () => {
    this.setState({
      filterCompanyData: this.props.result?.filter((c) => !c.isOwnerOperator),
    });
  };

  editCustomerModal = (record) => {
    this.setState({ isCompanyModalVisible: true, companyData: record });
  };

  deleteCompany = (record) => {
    this.props.actionDeleteCompany(record?.id);
    this.props.context.getCompanyData();
    this.setState({
      checkData: "",
    });
  };

  filterResultData = (data, noUpdate) => {
    const { totalCompanyData } = this.state;
    if (data) {
      let accountStatus =
        data.accountStatus !== "" && data.accountStatus !== undefined
          ? data.accountStatus
          : "";
      let filterAddress =
        data.address !== "" && data.address !== undefined ? data.address : "";

      if (accountStatus !== "" && filterAddress !== "") {
        let filterCustomer = totalCompanyData?.filter(
          (e) => e.accountStatus === accountStatus
        );
        let findradiusData = {
          entityData: {
            origin: {
              lat: data.lat,
              long: data.lng,
            },
            radius: data.radius,
            filterData: filterCustomer,
          },
        };
        add("getDist/companies", findradiusData)
          .then((result) => {
            if (result.success) {
              this.setState({
                filterCompanyData: result.data,
                searchingData: result.data,
                checkData: data,
              });
              this.closeFilter(data, noUpdate);
            }
          })
          .catch((err) => console.log(err));
      } else if (accountStatus !== "") {
        let filterCustomer = totalCompanyData?.filter(
          (e) => e.accountStatus === accountStatus
        );
        this.setState({
          filterCompanyData: filterCustomer,
          searchingData: filterCustomer,
          checkData: data,
        });
        this.closeFilter(data, noUpdate);
      } else if (filterAddress !== "") {
        let findradiusData = {
          entityData: {
            origin: {
              lat: data.lat,
              long: data.lng,
            },
            radius: data.radius,
            filterData: [],
          },
        };
        add("getDist/companies", findradiusData)
          .then((result) => {
            if (result.success) {
              this.setState({
                filterCompanyData: result.data,
                searchingData: result.data,
                checkData: data,
              });
              this.closeFilter(data, noUpdate);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  onClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.setState({
      filterVisible: false,
      visible: false,
      checkData: "",
    });
    this.props.actionFetchCompanies();
  };

  onSelectChange = (data) => {
    this.setState({ selectedRowKeys: data });
  };

  closeFilter = (data, noUpdate) => {
    let filterInfo = {};
    if (data?.radius === 0 && data?.lat) {
      const { radius, lat, lng, ...employeeRest } = data;
      filterInfo = employeeRest;
    } else if (data?.radius === 0) {
      const { radius, ...employeeRest } = data;
      filterInfo = employeeRest;
    } else if (data?.lat) {
      const { lat, lng, ...employeeRest } = data;
      filterInfo = employeeRest;
    }
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === null) {
        delete filterInfo[key];
      }
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
    });
    if (!noUpdate) {
      localStorage.setItem(
        "filterStorageData",
        JSON.stringify({
          filter: filterInfo,
          filterShowData: filterInfo,
        })
      );
    }
    this.setState({
      visible: false,
      filterVisible: false,
    });
  };

  getAddress = (address, type) => {
    if (type === "pickup") {
      this.setState({
        selectedPickupAddress: address.label,
      });
    } else if (type === "dropOff") {
      this.setState({
        selectedDropOffAddress: address.label,
      });
    } else {
      this.setState({ selectedAddress: address.label });
    }
  };

  handleSelect = (address, type) => {
    this.setState({
      citySearch: address.label,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "pickup") {
            this.setState({ pickUpLatitude: lat, pickupLongitude: lng });
          } else if (type === "dropOff") {
            this.setState({ dropoffLatitude: lat, dropoffLongitude: lng });
          } else {
            this.setState({ latitude: lat, longitude: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  // searchResult = (filterData) => {
  //   this.setState({
  //     filterCompanyData: filterData,
  //   });
  // };

  searchResult = (searchValue) => {
    // console.log(searchValue);
    const { totalCompanyData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = totalCompanyData?.filter(
        (e) => parseInt(e["phone"]?.replace(/[^+0-9]/g, "")) === searchValue
      );
    } else {
      searchFilter = totalCompanyData?.filter(
        (e) =>
          e["company"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["address"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["accountTerms"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["phone"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["currentBalance"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["accountStatus"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    // console.log(searchFilter);
    this.setState({
      filterCompanyData: searchFilter,
    });
  };

  addContact = (values) => {
    const { recordContactData } = this.state;

    if (recordContactData !== "") {
      let filteredData = this.state.contactInfo.filter(
        (c) => c.id !== recordContactData?.id
      );
      this.setState({
        contactInfo: filteredData,
      });
    }

    let data = {
      entityData: {
        id: uuidv4(),
        contact_name: values["fName"] + " " + values["lName"],
        contact_number: values.phone,
        contact_email: values.email,
        firstName: values.fName,
        lastName: values.lName,
        phone: values.phone,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : recordContactData?.role
            ? recordContactData?.role
            : values["role"],
        notes: values.notes || recordContactData?.notes,
        company:
          this.state.companyCheck === ""
            ? ""
            : this.state.companyCheck || recordContactData?.company,
      },
    };
    let contactData = this.state.contactInfo?.filter(
      (c) => c?.email === values?.email && c?.phone === values?.phone
    );

    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number already exists");
      this.setState({
        loading: false,
      });
      return true;
    } else {
      this.setState({
        loading: true,
        isContactVisible: false,
      });

      if (this.state.isCheckcontact) {
        this.updateRolesData(values["roleOther"], "roles");
      }

      add(appConfig["entityNames"]["checkContact"], data)
        .then((result) => {
          if (result.data.message === "success") {
            message.success("Contact Added Successfully!");
            let contactInfo =
              this.state.contactInfo?.length > 0 ? this.state.contactInfo : [];
            if (recordContactData?.length > 0) {
              let filteredData = this.state.contactInfo?.filter(
                (c) => c.id !== recordContactData?.id
              );
              filteredData.push(data["entityData"]);
              this.setState({
                contactInfo: filteredData,
                loading: false,
                isCheckcontact: false,
                newRole: "",
              });
            }
            contactInfo.push(data["entityData"]);
            this.setState({
              contactInfo,
              isContactVisible: false,
              loading: false,
              newRole: "",
            });
          } else {
            message.info(result.data.message);
            this.setState({
              loading: false,
              isCheckcontact: false,
              newRole: "",
            });
            return true;
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
        });
    }
  };

  updateRolesData = (values) => {
    let rolesData = this.state.rolesData;
    rolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: rolesData?.length + 1,
    });
    let data = {
      entityData: {
        rolesData,
        typeofDropDown: "roles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.rolesData[0]?.id, data);
  };

  deleteContactInfo = (id) => {
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState({
      contactInfo,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    this.setState({
      isContactVisible: true,
      recordContactData: data,
    });
  };

  onFinishCompanyDetails = (values) => {
    const { companyData, contactInfo, totalCompanyData } = this.state;

    const data = {
      entityData: {
        company: values["company"] || companyData?.company,
        companyName: values["company"] || companyData?.company,
        contactInfo: contactInfo,
        accountTerms:
          values["accountTerms"] === "Custom Input"
            ? values["accountTermsOthers"]
            : values["accountTerms"] || companyData.accountTerms,
        // accountChargeLimit:
        //   values["accountChargeLimit"] || companyData?.accountChargeLimit,
        email: values["email"] || companyData?.email,
        phone: values["phone"] || companyData?.phone,
        address: this.state.selectedAddress || companyData?.address,
        lat: this.state.latitude || companyData?.["lat"],
        lng: this.state.longitude || companyData?.["lng"],
        notes: values["notes"] || companyData?.notes,
        isOwnerOperator: false,
        multiContact: true,
        leadType: [
          {
            id: "1",
            value: "Material Sales",
          },
        ],
      },
    };

    let existingCompanyName = totalCompanyData?.filter(
      (e) => e.company === data["entityData"]["company"]
    );

    if (existingCompanyName?.length > 0) {
      message.error("This Company already exists!");
      this?.props?.actionFetchCompanies();
      return;
    } else {
      // console.log(data);
      if (companyData !== "") {
        this.setState({
          isCompanyModalVisible: false,
        });

        this.updateCompanyDetails(data);
      } else {
        this.setState({
          isCompanyModalVisible: false,
        });
        data["entityData"]["activeJobs"] = 0;
        data["entityData"]["accountStatus"] = "Inactive";
        this.addNewCompany(data);
      }
    }
  };

  addNewCompany = (data) => {
    this.props.actionAddCompany(data);
    this.props.context.getCompanyData();
  };

  updateCompanyDetails = (data) => {
    this.props.actionUpdateCompany(this.state.companyData?.id, data);
    this.props.context.getCompanyData();
  };

  onChangeTerms = (e) => {
    this.setState({ selectedTerm: e });
    this.formRef.current.setFieldsValue({
      accountTermsOthers: "",
    });
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, customerDatabase: props })
    );
  };

  archiveEdit = (record) => {
    let data = {
      entityData: {
        showCompany:
          record.showCompany !== undefined ? !record.showCompany : false,
      },
    };
    this.props.actionUpdateArchiveData(record?.id, data);
    message.success(
      `${record.company} ${
        data["entityData"]["showCompany"] ? "retrived" : "archived"
      } successfully`
    );
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const userDetails =
      JSON.parse(localStorage.getItem("userDetails"))||
      {};
    const {
      citySearch,
      companyData,
      isContactVisible,
      newRole,
      totalCompanyData,
      selectedTerm,
      isCompanyModalVisible,
      contactInfo,
      recordContactData,
      rolesData,
      archiveData,
    } = this.state;

    const { loading } = this.props;

    const sourceData = archiveData
      ? totalCompanyData.filter((e) => e.showCompany !== false)
      : totalCompanyData?.filter((e) => e.showCompany === false);

    const customerColumns = [
      {
        title: "CUSTOMER NAME",
        dataIndex: "company",
        key: "company",
        className: "col_styling",
        width: "20%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        render: (company, record) => {
          return (
            <Row>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                <Popover
                  content={
                    <p style={{ marginBottom: "0px" }}>
                      {record?.accountStatus}
                    </p>
                  }
                >
                  <BsDot
                    style={{
                      color:
                        record?.accountStatus === "Inactive"
                          ? "orange"
                          : record?.accountStatus === "Active"
                          ? "green"
                          : "red",
                      fontSize: "40px",
                    }}
                  />
                </Popover>
                {company}{" "}
              </span>
            </Row>
          );
        },
      },
      {
        title: "Total Jobs",
        dataIndex: "totalJobs",
        key: "totalJobs",
        className: "col_styling",
        width: "140px",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        sorter: (a, b) => parseInt(a?.totalJobs) - parseInt(b?.totalJobs),
        render: (totalJobs) => {
          return (
            <span
              className="col_styling table-font-mobile" // centertxttable"
              style={{
                color: "#474747",
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              {totalJobs}
            </span>
          );
        },
      },

      {
        title: "ACTIVE JOBS",
        dataIndex: "activeJobs",
        key: "activeJobs",
        className: "col_styling",
        width: "140px",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        sorter: (a, b) => parseInt(a?.activeJobs) - parseInt(b?.activeJobs),
        render: (activeJobs) => {
          return (
            <span
              className="col_styling table-font-mobile" // centertxttable"
              style={{
                color: "#474747",
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              {activeJobs}
            </span>
          );
        },
      },
      {
        title: "OVERDUE INVOICES",
        dataIndex: "overdueStatus",
        key: "overdueStatus",
        className: "col_styling",
        // width: "190px",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        sorter: (a, b) =>
          parseInt(a?.overdueStatus !== undefined ? a?.overdueStatus : 0) -
          parseInt(b?.overdueStatus !== undefined ? b?.overdueStatus : 0),
        render: (overdueStatus) => {
          return (
            <span
              className="col_styling table-font-mobile" //centertxttable
              style={{
                color: "#474747",
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              {overdueStatus !== undefined ? overdueStatus : "0"}
            </span>
          );
        },
      },
      {
        title: "CURRENT BALANCE",
        dataIndex: "currentBalance",
        key: "currentBalance",
        // sorter: false,
        className: "col_styling",
        // width: "10%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        sorter: (a, b) =>
          parseInt(a?.currentBalance) - parseInt(b?.currentBalance),
        render: (currentBalance) => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                color: "#474747",
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              {currentBalance}
            </span>
          );
        },
      },
      {
        title: "OVERDUE BALANCE",
        dataIndex: "",
        key: "",
        className: "col_styling",
        // width: "10%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        sorter: (a, b) =>
          parseInt(a?.overdue_balance) - parseInt(b?.overdue_balance),
        render: () => {
          return (
            <span
              className="col_styling table-font-mobile"
              style={{
                color: "#474747",
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              {}
            </span>
          );
        },
      },

      {
        title: "LIFE TIME REVENUE",
        dataIndex: "lifetimeRevenue",
        key: "lifetimeRevenue",
        className: "col_styling",
        width: "200px",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        sorter: (a, b) =>
          parseInt(a?.lifetimeRevenue) - parseInt(b?.lifetimeRevenue),
        render: (lifetimeRevenue) => {
          return (
            <span
              className="col_styling table-font-mobile" // centertxttable"
              style={{
                color: "#474747",
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              {lifetimeRevenue !== undefined &&
                lifetimeRevenue !== 0 &&
                `$` +
                  lifetimeRevenue?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </span>
          );
        },
      },
      {
        title: "COGS",
        dataIndex: "cogs",
        key: "cogs",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        sorter: (a, b) => parseInt(a?.cogs) - parseInt(b?.cogs),
        render: (cogs) => {
          return (
            <span
              className="col_styling table-font-mobile" // centertxttable"
              style={{
                color: "#474747",
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              {cogs !== undefined &&
                cogs !== 0 &&
                `$` +
                  cogs?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </span>
          );
        },
      },
      {
        title: "Gross Profit",
        dataIndex: "grossProfit",
        key: "grossProfit",
        width: "200px",
        className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.id}`
            ),
        }),
        sorter: (a, b) => parseInt(a?.grossProfit) - parseInt(b?.grossProfit),
        render: (grossProfit, record) => {
          return (
            <span
              className="col_styling table-font-mobile" // centertxttable"
              style={{
                color: "#474747",
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              {grossProfit !== undefined &&
                grossProfit !== 0 &&
                `$` +
                  grossProfit?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              {record?.profitPer && record?.profitPer !== null
                ? ` (${record?.profitPer?.toFixed(2)}%)`
                : ""}
            </span>
          );
        },
      },

      {
        title: "ACTIONS",
        dataIndex: "actions",
        key: "actions",
        // sorter: false,
        className: "col_styling",
        width: "95px",
        fixed: "right",
        render: (actions, record) => {
          return (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
                className="col_styling table-font-mobile"
              >
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row
                        className="popovergrid"
                        style={{ maxWidth: "100px" }}
                      >
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.editCustomerModal(record)}
                          >
                            <span className="span_edit">
                              <MdModeEditOutline
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Edit
                            </span>
                          </Button>
                        </Col>{" "}
                        <Col span={24}>
                          <Button className="btn_edit">
                            <Popconfirm
                              title={
                                <span>
                                  {`Are you sure you want to ${
                                    record?.showCompany === false
                                      ? "retrive"
                                      : "archive"
                                  } `}
                                  <b>{record.company}？</b>
                                </span>
                              }
                              okText={
                                <span style={{ width: "60px", height: "30px" }}>
                                  Yes
                                </span>
                              }
                              cancelText={
                                <span style={{ width: "60px", height: "30px" }}>
                                  No
                                </span>
                              }
                              onConfirm={() => this.archiveEdit(record)}
                            >
                              <span className="span_edit">
                                {record?.showCompany === false ? (
                                  <>
                                    <MdUnarchive
                                      style={{ marginRight: "5px" }}
                                    />{" "}
                                    Retrive
                                  </>
                                ) : (
                                  <>
                                    <MdArchive style={{ marginRight: "5px" }} />{" "}
                                    Archive
                                  </>
                                )}
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                        {record?.showCompany === false && userDetails?.userProfile === 'Admin' && (
                        <Col span={24}>
                          <Button className="btn_edit">
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => this.deleteCompany(record)}
                            >
                              <span className="span_edit">
                                <MdDelete style={{ marginRight: "5px" }} />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>)}
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      },
    ];

    const searchFields = [
      "companyName",
      "accountTerms",
      "address",
      "phone",
      "email",
    ];

    return (
      <>
        <Loading enableLoading={loading} />
        <div
          style={{
            background: "#fafafa",
          }}
        >
          <Row style={{ marginBottom: "1vw" }}>
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              className="layout-landscape"
            >
              <Row>
                <Col
                  xxl={{ span: 18 }}
                  xl={{ span: 18 }}
                  lg={{ span: 18 }}
                  md={{ span: 14 }}
                  sm={{ span: 14 }}
                  xs={{ span: 10 }}
                  className="quarrytoolgrid customerDatabase_search"
                  // span={10}
                >
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 18 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <SearchFunction
                      tableName={"companies"}
                      getSearchData={this.searchResult}
                      onResetData={this.onResetData}
                      searchFields={searchFields}
                      company
                    />
                  </Col>
                  {/* <SearchFunction
                    data={searchingData}
                    getSearchData={this.searchResult}
                    onResetData={this.onResetData}
                    availableSearchFields={searchFields}
                  /> */}
                </Col>
                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                  md={{ span: 10 }}
                  sm={{ span: 10 }}
                  xs={{ span: 14 }}
                >
                  <Row
                    className="customerDatabase_buttons"
                    style={{ justifyContent: "right" }}
                  >
                    <Col className="gridbtntool-owner-operator">
                      <Button
                        className= 
                        {window.screen.width > 1023 ? 
                        "show-on-desktop" : "show-on-mobile"}
                        type="primary"
                        icon={
                          archiveData ? (
                            <MdArchive style={{ fontSize: "18px" }} />
                          ) : (
                            <MdUnarchive style={{ fontSize: "18px" }} />
                          )
                        }
                        onClick={() =>
                          this.setState({
                            archiveData: !archiveData,
                          })
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "5px",
                          height: "40px",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            marginLeft: "5px",
                          }}
                        >
                          {archiveData ? "Archive" : "Retrive"}
                        </span>
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        className="customer-button"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() =>
                          this.setState({
                            isCompanyModalVisible: true,
                            companyData: "",
                            contactInfo: [],
                          })
                        }
                        style={{
                          borderRadius: "5px",
                          height: "40px",
                          margin: "auto 0px",
                          marginRight: "10px",
                        }}
                      >
                        <span>Customer</span>
                      </Button>
                    </Col>
                    <Col className="gridbtntool">
                      <Button
                        className="filterButton"
                        style={{
                          borderRadius: "5px",
                          height: "40px",
                        }}
                        onClick={() => {
                          this.setState({ visible: true });
                        }}
                      >
                        <Row>
                          <Col className="show-on-desktop">
                            <span
                              style={{
                                color:
                                  Object.keys(filterInfo)?.length > 0
                                    ? "red"
                                    : "#5a6067",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              {Object.keys(filterInfo)?.length > 0
                                ? `${
                                    "Filter (" + Object.keys(filterInfo)?.length
                                  })`
                                : "Filter"}
                            </span>
                          </Col>
                          <Col>
                            <IoFilterSharp
                              style={{
                                fontSize: "20px",
                                marginTop: "2px",
                                marginLeft: "8px",
                                color:
                                  Object.keys(filterInfo)?.length > 0
                                    ? "red"
                                    : "#5a6067",
                              }}
                            />
                          </Col>
                        </Row>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row data-aos="fade-up">
            <Col
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              {this.props.errorLoading ? (
                <ErrorView refreshCrm={this.onClickRefresh} type="CRM" />
              ) : (
                <Card bodyStyle={{ padding: "0px", overflowX: "auto" }}>
                  <BaseTable
                    className="customertablegrp table_laptopscreen"
                    columnsData={customerColumns}
                    source={sourceData?.sort((a, b) =>
                      a?.company?.localeCompare(b?.company)
                    )}
                    // rowSelection={rowSelection}
                    total={sourceData?.length}
                    rowKey={(record) => record.id}
                    handleTableChage={this.handleTableChage}
                    sizeRange={
                      window.screen.width > 1023
                        ? JSON.parse(localStorage.getItem("tableSizeRange"))
                            ?.customerDatabase
                        : sourceData?.length
                    }
                    pagination={window.screen.width > 1023 ? true : false}
                  />
                  {/* {selectedRowKeys?.length > 0 && (
                      <FooterData count={selectedRowKeys?.length} deactive />
                    )} */}
                </Card>
              )}
            </Col>
          </Row>

          {isContactVisible && (
            <BaseModal
              className="crmform_modal antmodal_grid headermodal"
              title="Add New Contact"
              onCancel={() => {
                this.setState({
                  isCheckBox: false,
                  isCheckBoxTruckType: false,
                  isContactVisible: false,
                  newRole: "",
                });
              }}
              loading={this.state.loading}
              visible={isContactVisible}
              destroyOnClose={true}
              formId="contact"
              width={"25%"}
            >
              <Form
                style={{ width: "100%" }}
                onFinish={this.addContact}
                id="contact"
              >
                <Row gutter={[12, 0]}>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">First Name</span>
                    <Form.Item
                      name="fName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your First Name!",
                        },
                      ]}
                      initialValue={recordContactData?.firstName}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <span className="title_changes">Last Name</span>
                    <Form.Item
                      name="lName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Last Name!",
                        },
                      ]}
                      initialValue={recordContactData?.lastName}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter your Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Phone</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Phone!",
                        },
                      ]}
                      initialValue={recordContactData?.phone}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Your Phone"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                      initialValue={recordContactData?.email}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{
                      span: 24,
                    }}
                    xl={{
                      span: 24,
                    }}
                    lg={{
                      span: 24,
                    }}
                    md={{
                      span: 24,
                    }}
                    sm={{
                      span: 24,
                    }}
                    xs={{
                      span: 24,
                    }}
                  >
                    <span className="title_changes">Role</span>
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Role!",
                        },
                      ]}
                      initialValue={recordContactData?.contactRole}
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Role"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                          this.setState({ newRole: value });
                          value === "Others" &&
                            message.info("Please Enter Role");
                        }}
                      >
                        {rolesData?.map((role) => (
                          <Option key={role.id} value={role.label}>
                            {role.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {newRole === "Add New Role" && (
                    <Col span={24}>
                      <span className="title_changes">Add New Role</span>
                      <Form.Item
                        name="roleOther"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter New Role!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({
                              roleOther: e?.target?.value,
                            })
                          }
                          className="form-input"
                          placeholder="Add New Role"
                        />
                      </Form.Item>

                      <Checkbox
                        checked={this.state.isCheckcontact}
                        onChange={(e) =>
                          this.setState({
                            isCheckcontact: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  )}
                  <Col span={24}>
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.notes ? recordContactData?.notes : ""
                      }
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "65px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}

          {this.state.visible && (
            <Filter
              visible={this.state.visible}
              closeFilter={this.closeFilter}
              filterResultData={this.filterResultData}
              // onClickClearFilter={this.onClickClearFilter}
              onClearFilter={this.onClearFilter}
              checkData={this.state.checkData}
              company={companyData}
              customer
            />
          )}

          {isCompanyModalVisible && (
            <BaseModal
              title={
                companyData !== ""
                  ? "Update Customer Details"
                  : "Add New Customer"
              }
              onCancel={() => {
                this.setState({
                  isCompanyModalVisible: false,
                });
              }}
              formId="CompanyDetails"
              className="antmodal_grid headermodal"
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "35%"
              }
              loading={this.state.loading}
            >
              <Form
                onFinish={this.onFinishCompanyDetails}
                id="CompanyDetails"
                ref={this.formRef}
              >
                <Row gutter={[32, 0]}>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Customer Name</span>
                    <Form.Item
                      initialValue={companyData?.company}
                      name="company"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your company name",
                        },
                      ]}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please enter your company name"
                        onChange={(e) =>
                          this.setState({ companyCheck: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">
                      Company Mailing Address
                    </span>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your address",
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        // apiKey={process.env.REACT_APP_MAPS_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          defaultInputValue: `${
                            companyData?.address ? companyData?.address : ""
                          }`,
                          citySearch,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.handleSelect(address);
                            this.getAddress(address);
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11,offset:2}
                  >
                    <span className="title_changes">Office Email</span>
                    <Form.Item
                      initialValue={companyData?.email}
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your email!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid Corporate Email",
                        },
                      ]}
                    >
                      <Input
                        className="form-input"
                        placeholder="Please enter your email"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Office Phone</span>
                    <Form.Item
                      initialValue={companyData?.phone}
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your phone number",
                        },
                      ]}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please enter your phone number"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Account Terms</span>
                    <Form.Item
                      name="accountTerms"
                      rules={[
                        {
                          required: true,
                          message: "type your Account Terms",
                        },
                      ]}
                      initialValue={
                        companyData?.accountTerms
                          ? companyData?.accountTerms
                          : "Net 0"
                      }
                    >
                      <Select
                        size="large"
                        allowClear
                        className="select_multiplequarytol"
                        placeholder="Account Terms"
                        showArrow
                        style={{ width: "100%" }}
                        onChange={(e) => this.onChangeTerms(e)}
                      >
                        <Option value="Net 0">Cash</Option>
                        <Option value="Net 14">Net 14</Option>
                        <Option value="Net 30">Net 30</Option>
                        <Option value="Custom Input">Custom Input</Option>
                      </Select>

                      {/* <NumberFormat
                      className="ant-input-number numberictest"
                      format="#######"
                      style={{ width: "100%" }}
                      placeholder="Please enter your Account Terms"
                    /> */}
                    </Form.Item>
                  </Col>
                  {/* <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Account Charge Limit</span>
                    <Form.Item
                      initialValue={companyData?.accountChargeLimit}
                      name="accountChargeLimit"
                      rules={[
                        {
                          required: false,
                          message: "type your Account Charge Limit",
                        },
                      ]}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        // format="#######"
                        style={{ width: "100%" }}
                        prefix={"$ "}
                        suffix={".00"}
                        thousandSeparator
                        placeholder="Please enter your Account Charge Limit"
                      />
                    </Form.Item>
                  </Col> */}
                  {selectedTerm === "Custom Input" && (
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={12}
                    >
                      <span className="title_changes">
                        Account Terms (Others)
                      </span>
                      <Form.Item
                        name="accountTermsOthers"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter New Account Terms",
                          },
                        ]}
                      >
                        <NumberFormat
                          className="ant-input-number numberictest"
                          style={{ width: "100%" }}
                          prefix={"Net "}
                          placeholder="Please Enter New Account Terms"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {companyData === "" && (
                    <>
                      {contactInfo?.map((c) => (
                        <Col span={24} style={{ marginBottom: "1vw" }}>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                            }}
                          >
                            <Popover
                              content={
                                (c?.phone || c?.email) && (
                                  <Row>
                                    <Col span={24}>
                                      <span>{c.phone}</span>
                                    </Col>
                                    <Col span={24}>
                                      <span
                                        style={{
                                          color: "black",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          alignItems: "center",
                                          justifyContent: "left",
                                        }}
                                      >
                                        {c.email}
                                      </span>
                                    </Col>
                                  </Row>
                                )
                              }
                            >
                              <span
                                className="title_changes"
                                style={{
                                  margin: "0px 5px",
                                  wordBreak: "break-word",
                                  width: "100%",
                                  maxWidth: "270px",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                }}
                              >
                                {c.contactRole
                                  ? c.firstName +
                                    " " +
                                    c.lastName +
                                    ", " +
                                    c.contactRole
                                  : c.firstName + " " + c.lastName}
                              </span>
                            </Popover>
                            <Col>
                              <Popover
                                placement="left"
                                content={
                                  <Row className="popovergrid">
                                    <Col span={24}>
                                      <Button
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                          border: "none",
                                          padding: "0px",
                                          textAlign: "left",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                        onClick={() => this.editContactInfo(c)}
                                      >
                                        <span
                                          style={{
                                            color: "black",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: "left",
                                          }}
                                        >
                                          <MdModeEditOutline
                                            style={{ marginRight: "5px" }}
                                          />
                                          Edit
                                        </span>
                                      </Button>
                                    </Col>
                                    <Col span={24}>
                                      <Button
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                          border: "none",
                                          padding: "0px",
                                          textAlign: "left",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        <Popconfirm
                                          title="Are you sure？"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() =>
                                            this.deleteContactInfo(c.id)
                                          }
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              alignItems: "center",
                                              justifyContent: "left",
                                            }}
                                          >
                                            <MdDelete
                                              style={{ marginRight: "5px" }}
                                            />
                                            Delete
                                          </span>
                                        </Popconfirm>
                                      </Button>
                                    </Col>
                                  </Row>
                                }
                              >
                                <EllipsisOutlined
                                  style={{
                                    fontSize: "30px",
                                    color: "grey",
                                    marginTop: "2px",
                                  }}
                                  className="action-ellipsis-button"
                                />
                              </Popover>
                            </Col>
                          </div>
                        </Col>
                      ))}

                      <Col span={24}>
                        <Form.Item name="button">
                          <Button
                            type="primary"
                            style={{
                              width: "100%",
                              fontWeight: "500",
                              height: "40px",
                            }}
                            onClick={() =>
                              this.state.companyCheck !== ""
                                ? this.setState({
                                    isContactVisible: true,
                                    recordContactData: "",
                                  })
                                : message.info(
                                    "Please Enter Your Company Name First!"
                                  )
                            }
                          >
                            ADD CONTACT
                          </Button>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col span={24}>
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={companyData?.notes}
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </BaseModal>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    result: state?.reducer?.companiesData?.companies,
    loading: state.reducer.companiesData?.loading,
    errorLoading: state.reducer?.companiesData?.error,
    companiesInfo: state.reducer?.companiesInfo,
    rolesData: state.reducer?.dropDownOptions?.totalData?.filter(
      (q) => q?.typeofDropDown === "roles"
    ),
    dropDownOptions: state.reducer?.dropDownOptions,
    dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
    actionArchiveCompany: state?.reducer?.archiveCompany
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchCompanies: () => {
    dispatch(getCompanies());
  },
  actionDeleteCompany: (id) => {
    dispatch(deleteCompany(id));
  },
  actionUpdateCompany: (id, data) => {
    dispatch(updateCompany(id, data));
  },
  actionUpdateArchiveData: (id, data) => {
    dispatch(updateArchive(id, data));
  },
  actionAddCompany: (data) => {
    dispatch(addCompany(data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(CustomerDatabase));
