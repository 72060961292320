import React, { Component } from "react";
import { Storage } from "aws-amplify";
import {
  Select,
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
  Popover,
  Popconfirm,
  Checkbox,
  message,
  InputNumber,
  Switch,
  Spin,
} from "antd";
import {
  PlusOutlined,
  UnorderedListOutlined,
  EllipsisOutlined,
  InboxOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { FaMap } from "react-icons/fa";
import MapComponent from "../../Components/Maps/MapComponent";
// import SearchFunction from "../Common/SearchFunction";
import FooterData from "../../Common/FooterData";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import NumberFormat from "react-number-format";
import appConfig from "../../config/AppConfig";
import { add } from "../../api/Apis";
import Filter from "../../Common/Filter";
import Loading from "../../Common/Loading";
import { IoFilterSharp } from "react-icons/io5";
import ErrorView from "../../Common/ErrorView";
import BaseDrawer from "../../Common/BaseDrawer";

import TimePicker from "rc-time-picker";

import BaseModal from "../../Common/BaseModal";
import BaseTable from "../../Common/BaseTable";
import { withAppContext } from "../../Store/AppContext";
import { v4 as uuidv4 } from "uuid";
import TextArea from "antd/lib/input/TextArea";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import { connect } from "react-redux";
import {
  addQuarry,
  deleteQuarry,
  getDropDownOptions,
  getQuarryLocations,
  updateDropDownOptions,
  updateQuarry,
} from "../../Redux/Actions/actions";
import QuarryJsonData from "../../Json/quarryData.json";
import blueIcon from "../../Images/blueMarker.png";

const Search = Input.Search;
const { Option } = Select;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class QuarryTool extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      selectionType: "",
      selectedRowKeys: [],
      selected: true,
      color: "active",
      isModalVisible: false,
      leadsData: [],
      Success: "",
      citySearch: [],
      streetNumber: "",
      street: "",
      city: "",
      addressState: "",
      stateAbbr: "",
      zipCode: "",
      filterVisible: false,
      latitude: "",
      longitude: "",
      selectedAddress: "",
      bothData: [],
      searchingData: [],
      filterData: [],
      checkData: [],
      quarryMapData: [],
      selectedMaterial: [],
      contactPopup: false,
      recordMaterialData: [],
      selectedMaterialType: "",
      finalMaterialData: [],
      quantityType: "Ton",
      materialPopup: false,
      recordContactData: "",
      quarryType: [],
      quarryData: [],
      quarryName: "",
      selectedTerm: "Net 30",
      rolesData: [],
      isCheckcontact: false,
      notesData: [],
      notesPopup: false,
      primaryContactCheck: false,
      recordNotesData: "",
      materialList: this.props.materials[0]?.materialsNewList,
      quarryRoles: this.props.quarryRoles[0]?.quarryRolesData?.sort((a, b) =>
        a?.value > b?.value ? 1 : -1
      ),
      contactInfo: [],
      showDistance: false,
      materialAttachment: [],
      diameter: "",
      imageLoading: false,
      materialCategory: {},
      showLocationInput: true,
      subCategory1: "",
      subCategory2: [],
      categoryKey: "",
      subShortName: "",
      addCategory: false,
      searchData: undefined,
      showSearch: false,
      showAllQuarries: false,
      localLoading: false,
      salesTaxRate: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props?.materials) !== JSON.stringify(state?.materialList)
    ) {
      return {
        materialList: props.materials[0]?.materialsNewList,
      };
    }
    if (
      JSON.stringify(props?.quarryRoles) !== JSON.stringify(state?.quarryRoles)
    ) {
      console.log("quarryRoles");
      return {
        quarryRoles: props.quarryRoles[0]?.quarryRolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
      };
    }
  }

  componentDidMount() {
    this.props.actionFetchQuarries();
    this.props.actiongetDropDownOptions();
    document.title = "Twisted Nail - Quarry Tool";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    if (
      JSON.stringify(prevState?.filterData) ===
      JSON.stringify(this.state?.filterData)
    ) {
      let hashKeyValue = window.location.hash;
      if (hashKeyValue === "#quickJob") {
        hashKeyValue = "";
        this.props.history.push(`${window.location.pathname}`);
        this.props.actionFetchQuarries();
        this.props.actiongetDropDownOptions();
      }
    }
    if (
      prevProps?.totalData?.success === false &&
      this.props?.totalData?.success === true
    ) {
      const hasFilter =
        filterStorageData?.filter &&
        Object.keys(filterStorageData?.filter).length > 0;

      this.setState(
        {
          bothData: this.props.totalData?.quarryData,
          searchingData: this.props.totalData?.quarryData,
          filterData: this.props.totalData?.quarryData,
        },
        () => {
          if (hasFilter) {
            this.setState({
              localLoading: true,
            });
            this.filterResultData(filterStorageData?.filter, true);
          }
        }
      );
    }
    if (
      prevProps?.quarriesInfo?.success === false &&
      this.props?.quarriesInfo?.success === true
    ) {
      this.setState({
        selectedAddress: "",
        selectedMaterial: [],
        selectedTerm: "",
        latitude: "",
        longitude: "",
      });
      this.props.actionFetchQuarries();
    }
    if (
      prevProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props.actiongetDropDownOptions();
    }
    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props.dropDownOptions?.success === true
    ) {
      this.setState({
        salesTaxRate: this.props.dropDownOptions?.totalData?.find(
          (e) => e?.typeofDropDown === "salesTaxRate"
        )?.taxRate,
      });
      let materialData = {};
      let materialsNewList = this.props.materials[0]?.materialsNewList;
      let index = 0;
      let sortedMaterialList = materialsNewList
        ?.filter((v) => v.statusCode)
        .sort((a, b) => a?.indexVal - b?.indexVal);
      sortedMaterialList?.map((eachMat) => {
        let subTypes = [];
        if (eachMat?.statusCode) {
          index += 1;
          materialData[eachMat.category] = !Object.keys(materialData).includes(
            eachMat.category
          )
            ? []
            : materialData[eachMat.category];
          let typeCheck = materialData[eachMat.category].filter(
            (o) => o.subcat === eachMat.materialValue.split(" - ")[1]
          );

          if (typeCheck.length > 0) {
            subTypes = typeCheck[0]["subTypes"];
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
          } else {
            subTypes.push({
              subType: eachMat.materialValue.split(" - ")[2],
              id: eachMat.id,
            });
            materialData[eachMat.category].push({
              subcat: eachMat.materialValue.split(" - ")[1],
              cate: eachMat.materialValue.split(" - ")[0],
              categoryKey: eachMat.categoryKey,
              subShortName: eachMat.subShortName,
              subTypes,
            });
          }
          if (
            sortedMaterialList?.filter((e) => e.statusCode)?.length === index
          ) {
            this.setState({
              materialCategory: materialData,
            });
          }
        }
        return true;
      });
      // console.log("drop")
      this.setState({
        quarryRoles: this.props.quarryRoles[0]?.quarryRolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        materialList: this.props.materials[0]?.materialsNewList,
      });
    }
  };

  onClickRefresh = () => {
    this.props.actionFetchQuarries();
    this.props.actiongetDropDownOptions();
  };

  showModal = () => {
    this.setState({
      isModalVisible: true,
      leadsData: "",
      selectedMaterial: [],
      contactInfo: [],
    });
  };

  editModal = (record) => {
    this.setState({
      isModalVisible: true,
      leadsData: record,
      selectedMaterial: record?.material,
      contactInfo:
        record?.contactDetails !== undefined ? record?.contactDetails : [],
      quantityType: record?.quantityType,
      // selectedTerm: record?.selectedTerm,
      notesData: record?.notesDetails,
      selectedAddress: record?.address,
      latitude: record?.lat,
      longitude: record?.lng,
      quarryName: record?.quarryName,
      quarryNickName: record?.quarryNickName,
      showLocationInput:
        record?.address !== "" && record?.address !== undefined ? true : false,
    });
  };

  onSelectChange = (data) => {
    this.setState({ selectedRowKeys: data });
  };

  getAddress = (address, latLng, id) => {
    this.setState({
      selectedAddress: address.label,
    });
  };

  handleSelect = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let streetNumberValue = "";
    let streetValue = "";
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    let stateShortValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) =>
          this.setState({
            latitude: lat,
            longitude: lng,
          })
        );
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let area = results[0].address_components;
        for (let i = 0; i < area.length; i++) {
          if (area[i].types[0] === "street_number") {
            streetNumberValue = area[i].long_name;
          }
          if (area[i].types[0] === "route") {
            streetValue = area[i].long_name;
          }
          if (area[i].types[0] === "locality") {
            cityValue = area[i].long_name;
          }
          if (area[i].types[0] === "administrative_area_level_1") {
            stateValue = area[i].long_name;
          }
          if (area[i].types[0] === "administrative_area_level_1") {
            stateShortValue = area[i].short_name;
          }
          if (area[i].types[0] === "postal_code") {
            zipCodeValue = area[i].long_name;
          }
        }
        this.setState({
          streetNumber: streetNumberValue,
          street: streetValue,
          city: cityValue,
          addressState: stateValue,
          stateAbbr: stateShortValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  closeFilter = () => {
    this.setState({
      filterVisible: false,
    });
  };

  searchResult = (event) => {
    let searchValue = event.target.value;
    const { bothData } = this.state;
    let searchFilter = [];
    if (searchValue === "") {
      this.setState({
        searchData: undefined,
      });
      this.props.actionFetchQuarries();
    } else {
      searchValue = searchValue.replace(/\s/g, "").toLowerCase();

      if (!isNaN(searchValue)) {
        searchValue = searchValue.replace(/[^0-9]/g, "");
        searchFilter = bothData?.filter(
          (e) =>
            parseInt(e["phone"]?.replace(/[^+0-9]/g, "")) ===
            parseInt(searchValue)
        );
      } else {
        searchFilter = bothData?.filter(
          (e) =>
            e["quarryName"]
              ?.replace(/\s/g, "")
              .toLowerCase()
              .indexOf(searchValue) > -1 ||
            e["quarryNickName"]
              ?.replace(/\s/g, "")
              .toLowerCase()
              .indexOf(searchValue) > -1 ||
            e["address"]
              ?.replace(/\s/g, "")
              .toLowerCase()
              .indexOf(searchValue) > -1 ||
            e["accountLimit"]
              ?.replace(/\s/g, "")
              .toLowerCase()
              .indexOf(searchValue) > -1 ||
            e["currentBalance"]
              ?.replace(/\s/g, "")
              .toLowerCase()
              .indexOf(searchValue) > -1 ||
            e["accountStatus"]
              ?.replace(/\s/g, "")
              .toLowerCase()
              .indexOf(searchValue) > -1
        );
      }
      this.getLatLangData(searchValue);
      this.setState({
        filterData: searchFilter,
      });
    }
  };

  handleClick = (e) => {
    this.setState({
      showSearch: e,
    });
  };

  fetchAllQurries = () => {
    const { filterData } = this.state;
    this.props.actionFetchQuarries();
    this.mapComponent(filterData);
  };

  getLatLangData = (address) => {
    let newData = {
      id: uuidv4(),
      notesDetails: [],
      lng: "",
      payDiscount: "",
      contactDetails: [],
      quarryName: "Search Data",
      accountLimit: "",
      startTime: null,
      endTime: null,
      accountTerms: "Cash",
      contactName: "",
      updatedAt: 1685337472827,
      payDiscountTerms: "",
      lat: "",
      phone: "",
      material: [],
      searchInfo: true,
    };
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          newData["lat"] = lat;
          newData["lng"] = lng;
          newData["address"] = address;
          this.setState({
            searchData: newData,
            showSearch: true,
          });
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onFinish = (values) => {
    let type = (this.state?.selectedMaterial || [])
      .filter((e) => e?.categoryKey !== undefined)
      .map((e) => ({ key: e.categoryKey }));

    let uniqueObjArray = Array.from(
      new Map(type.map((item) => [item.key, item])).values()
    );

    this.setState({
      isModalVisible: false,
    });

    const {
      leadsData,
      latitude,
      longitude,
      notesData,
      selectedAddress,
      selectedMaterial,
      contactInfo,
      showLocationInput,
    } = this.state;

    let data = {
      entityData: {
        quarryType: uniqueObjArray,
        quarryNickName: values["quarryNickName"],
        quarryName: values["quarryName"],
        lat: latitude,
        lng: longitude,
        material: selectedMaterial,
        phone: values["phone"],
        contactDetails: contactInfo,
        accountLimit: values["accountLimit"],
        accountTerms:
          values["accountTerms"] === "Custom Input"
            ? values["accountTermsOthers"]
            : values["accountTerms"],
        payDiscountTerms: values["payDiscountTerms"] || "",
        notesDetails: notesData,
        payDiscount: values["payDiscount"] || "",
        startTime: values["startTime"] && moment(values["startTime"]),
        endTime: values["endTime"] && moment(values["endTime"]),
      },
    };

    if (showLocationInput === false) {
      data["entityData"]["lat"] = Number(values["lat"]);
      data["entityData"]["lng"] = Number(values["lng"]);
      data["entityData"]["address"] = "";
    } else {
      data["entityData"]["address"] = selectedAddress;
      data["entityData"]["lat"] = Number(latitude);
      data["entityData"]["lng"] = Number(longitude);
    }
    if (
      data["entityData"]["payDiscountTerms"]?.split("Net")[1] === undefined ||
      (data["entityData"]["accountTerms"] !== "Cash" &&
        parseInt(data["entityData"]["accountTerms"]?.split("Net")[1]) >=
          parseInt(data["entityData"]["payDiscountTerms"]?.split("Net")[1]))
    ) {
      if (leadsData !== "") {
        this.props.actionFetchUpdateQuarry(leadsData?.id, data);
      } else {
        this.props.actionFetchAddQuarry(data);
      }
    } else {
      message.error("Discount Terms can't exceed Account Terms!");
    }
  };

  filterResultData = (data, noUpdate) => {
    const mergedList = data["subCategoryId"]?.flatMap((item) =>
      item.key.split(",")
    );

    let submitData = {
      entityData: {
        quarryType: data["quarryType"]?.length > 0 ? data["quarryType"] : [],
        origin: data["origin"],
        quarryAddress: data["quarryAddress"] ? data["quarryAddress"] : "",
        subCategoryId: mergedList ? mergedList : [],
        radius: data["radius"] ? data["radius"] : "",
        materialDiameter: data["materialDiameter"]
          ? data["materialDiameter"]
          : "",
        materialStatus:
          data["materialStatus"]?.length > 0 ? data["materialStatus"] : [],
        materialSupply:
          data["materialSupply"]?.length > 0 ? data["materialSupply"] : [],
        materialTest:
          data["materialTest"]?.length > 0 ? data["materialTest"] : [],
        quarryMaterial:
          data["quarryMaterial"]?.length > 0 ? data["quarryMaterial"] : [],
      },
    };

    add("filterQuarries", submitData)
      .then((result) => {
        let filterInfo = data || {};
        if (data?.subCategoryId) {
          const { subCategoryId, quarryType, ...employeeRest } = data;
          filterInfo = employeeRest;
        }
        const { quarryType, origin, subCategoryId, ...employeeRest } =
          filterInfo;
        filterInfo = employeeRest;
        Object.keys(filterInfo)?.forEach((key) => {
          if (filterInfo[key] === null) {
            delete filterInfo[key];
          }
          if (filterInfo[key] === undefined) {
            delete filterInfo[key];
          }
          if (filterInfo[key]?.length === 0) {
            delete filterInfo[key];
          }
        });
        if (!noUpdate) {
          localStorage.setItem(
            "filterStorageData",
            JSON.stringify({
              filter: data,
              filterShowData: filterInfo,
            })
          );
        }
        this.filterMapData(result.data, data);
      })
      .catch((err) => console.log(err));
  };

  filterMapData = (filterMaterial, data) => {
    if (data["quarryAddress"]) this.getLatLangData(data["quarryAddress"]);
    let showDistance = filterMaterial?.filter((e) => e?.distance);
    this.setState({
      filterData: filterMaterial,
      searchingData: filterMaterial,
      checkData: data,
      quarryData: filterMaterial,
      loading: false,
      showDistance: showDistance?.length > 0 ? true : false,
      localLoading: false
    });
    this.mapComponent(filterMaterial);
    this.tableInfo(filterMaterial);
    this.closeFilter();
  };

  onClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.setState({
      selectedMaterial: [],
      filterVisible: false,
      checkData: [],
      showDistance: false,
      showSearch: false,
      searchData: undefined,
    });
    this.props.actionFetchQuarries();
  };

  deleteQuarry = (record) => {
    // this.deltePictureOfMaterial(record);
    this.props.actionFetchDeleteQuarry(record?.id);
  };

  deltePictureOfMaterial = async (data) => {
    if (data.material?.length > 0) {
      data.material?.forEach((m) => {
        if (m.materialPicture?.length > 0) {
          m.materialPicture?.map((e) => Storage.remove(e.quoteAttachmentId));
        }
      });
    }
  };

  uploadTicketPdf = async (info, type) => {
    const { recordDocData, quoteAttachment, materialAttachment } = this.state;
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              let uniqueId = uuidv4();
              Storage.put(
                type === "materialPicture"
                  ? `Quarries/${uuidv4()}/${uniqueId}`
                  : info.file.uid,
                info.file.originFileObj,
                {
                  contentType: info.file.type,
                }
              ).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  if (type === "materialPicture") {
                    let materialAttachmentInfo =
                      materialAttachment?.length > 0 ? materialAttachment : [];
                    materialAttachmentInfo.push({
                      id: uniqueId,
                      materialAttachment: urlResult,
                      quoteAttachmentId: result?.key,
                      fileType: info.file.type,
                      fileName: info.file.name,
                    });
                    this.setState({
                      materialAttachment: materialAttachmentInfo,
                      imageLoading: false,
                      loading: false,
                    });
                  } else {
                    let quoteAttachmentInfo = quoteAttachment
                      ? quoteAttachment
                      : [];
                    quoteAttachmentInfo.push({
                      id: uniqueId,
                      quoteAttachment: urlResult,
                      quoteAttachmentId: result?.key,
                      fileType: info.file.type,
                      fileName: info.file.name,
                    });
                    this.setState({
                      quoteAttachment: quoteAttachmentInfo,
                      imageLoading: false,
                      loading: false,
                    });
                  }
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                percentage: 0,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        if (recordDocData) {
          let docData = recordDocData.docAttachment.filter(
            (e) => e.id !== info.file.id
          );
          recordDocData["docAttachment"] = docData;
          this.setState({
            quoteAttachment: docData,
            recordDocData,
          });
        } else {
          let docData = quoteAttachment.filter(
            (e) => e.quoteAttachmentId !== info.file.uid
          );
          this.setState({
            quoteAttachment: docData,
          });
        }
        this.setState({
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState({
          imageLoading: false,
          loading: false,
          percentage: 0,
        });
    }
  };

  DecimalToMixedFraction = (val) => {
    // eslint-disable-next-line no-eval
    const decimal = eval(val);
    // Split the decimal into whole and fractional parts
    const wholeNumber = Math.floor(decimal);
    const fractionalPart = decimal - wholeNumber;
    // Convert the fractional part to a fraction
    const fraction = this.decimalToFraction(fractionalPart);

    // Combine the whole number and fraction
    const mixedFraction =
      wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : `${fraction}`;

    return mixedFraction;
  };

  decimalToFraction = (decimal) => {
    const EPSILON = 1.0e-9;
    let numerator = decimal;
    let denominator = 1;

    while (Math.abs(Math.round(numerator) - numerator) > EPSILON) {
      numerator *= 10;
      denominator *= 10;
    }

    const gcd = this.getGreatestCommonDivisor(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  };

  getGreatestCommonDivisor = (a, b) => {
    if (b === 0) {
      return a;
    }
    return this.getGreatestCommonDivisor(b, a % b);
  };

  onFinishMaterial = (values) => {
    this.setState({
      loading: true,
    });
    const {
      recordMaterialData,
      materialSelectedData,
      quantityType,
      materialAttachment,
      selectedMaterial,
      diameter,
      salesTaxRate,
    } = this.state;

    let minD = String(values["materialMinDiameter"])?.replace('"', "");
    let maxD = String(values["materialMaxDiameter"])?.replace('"', "");
    let mD = String(values["materialDiameter"])?.replace('"', "");

    // console.log(materialSelectedData, "materialSelectedData");
    let materialMinDiameter = "",
      materialMaxDiameter = "",
      materialDiameter = "",
      materialData = [];

    if (diameter) {
      materialMinDiameter =
        minD !== "Unknown"
          ? minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
            ? this.DecimalToMixedFraction(minD)
            : minD
          : minD;

      materialMaxDiameter =
        maxD !== "Unknown"
          ? maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
            ? this.DecimalToMixedFraction(maxD)
            : maxD
          : maxD;
    } else {
      materialDiameter =
        mD !== "Unknown"
          ? mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
            ? this.DecimalToMixedFraction(mD)
            : mD
          : mD;
    }

    if (
      recordMaterialData !== "" &&
      Object.keys(recordMaterialData)?.length > 0
    ) {
      let filteredData = selectedMaterial.filter(
        (c) => c.materialId !== recordMaterialData?.materialId
      );
      materialData = filteredData?.filter(
        (m) =>
          m?.id === materialSelectedData?.id &&
          (m.diameter
            ? m["materialMinDiameter"] === materialMinDiameter &&
              m["materialMaxDiameter"] === materialMaxDiameter
            : m["materialDiameter"] === materialDiameter)
      );
    } else {
      materialData = selectedMaterial?.filter(
        (m) =>
          m?.id === materialSelectedData?.id &&
          (m.diameter
            ? m["materialMinDiameter"] === materialMinDiameter &&
              m["materialMaxDiameter"] === materialMaxDiameter
            : m["materialDiameter"] === materialDiameter)
      );
    }

    if (materialData?.length > 0) {
      message.info("Given Material Is Already Selected");
      this.setState({
        loading: false,
      });
      return true;
    } else {
      let data = {
        entityData: {
          id: materialSelectedData["id"],
          materialId:
            recordMaterialData === ""
              ? uuidv4()
              : recordMaterialData?.materialId
              ? recordMaterialData?.materialId
              : uuidv4(),
          label: materialSelectedData["label"],
          value:
            materialSelectedData["materialValue"] || recordMaterialData?.value,
          category: materialSelectedData["category"],
          subCategory: materialSelectedData["subCategory"],
          price: values["price"] || recordMaterialData["price"],
          quantity: "",
          quantityType: quantityType || recordMaterialData?.quantityType,
          categoryKey: materialSelectedData["categoryKey"],
          materialPicture: materialAttachment,
          materialNotes: values["materialNotes"],
          materialStatus: values["materialStatus"],
          materialSupply: values["materialSupply"],
          materialTest: values["materialTest"],
          materialTax: values["materialTax"],
          additionalTaxRate: salesTaxRate,
          materialMaxDiameter: materialMaxDiameter,
          materialMinDiameter: materialMinDiameter,
          materialDiameter: materialDiameter,
          materialCheckDiameter: diameter,
          statusCode: materialSelectedData["statusCode"],
          subShortName: materialSelectedData["subShortName"],
          confirm: materialSelectedData["confirm"]
            ? materialSelectedData["confirm"]
            : false,
        },
      };
      if (recordMaterialData === "") {
        selectedMaterial.splice(
          selectedMaterial.findIndex(
            (f) => f?.categoryKey === data["entityData"]["categoryKey"]
          ) + 1,
          0,
          data["entityData"]
        );
      } else {
        selectedMaterial.splice(
          selectedMaterial.findIndex(
            (f) => f?.materialId === data["entityData"]["materialId"]
          ),
          1,
          data["entityData"]
        );
      }
      // console.log(data, "data");
      this.setState({
        selectedMaterial: selectedMaterial,
        materialPopup: false,
        loading: false,
        materialCheck: false,
        quantityType: "Ton",
        selectedMaterialType: "",
        recordMaterialData: "",
        materialSelectedData: "",
        diameter: "",
      });
    }
  };

  editMaterial = (data) => {
    this.setState(
      {
        materialPopup: true,
        recordMaterialData: data,
        materialSelectedData: data,
        recordContactData: "",
        selectedMaterialType: data?.category,
        quantityType: data?.quantityType,
        diameter: data?.materialCheckDiameter,
        // subCategory1:data?.label?.split(" - ")[0]
      },
      () => this.subCategoryInfo(data)
      // () => this.onSelectMaterialCat(data?.category, "edit")
    );
  };

  subCategoryInfo = (data) => {
    const { materialCategory } = this.state;
    if (materialCategory[data.category]) {
      Object.values(materialCategory[data.category])?.forEach((c, i) => {
        if (c.subcat === data?.label?.split(" - ")[0]) {
          this.setState({
            subCategory1: data.category,
            subCategory2: c.subTypes,
            categoryKey: c.categoryKey,
            subShortName: c.subShortName,
          });
        }
      });
    }
  };

  deleteMaterial = (id) => {
    const { selectedMaterial } = this.state;
    selectedMaterial?.splice(
      selectedMaterial?.findIndex((e) => e?.materialId === id),
      1
    );
    this.setState({
      selectedMaterial,
    });
    message.info("Material Deleted Successfully!");
  };

  addContact = (values) => {
    const { recordContactData, quarryName, primaryContactCheck } = this.state;
    if (recordContactData !== "") {
      let filteredData = this.state.contactInfo.filter(
        (c) => c.id !== recordContactData?.id
      );
      this.setState({
        contactInfo: filteredData,
      });
    }

    let data = {
      entityData: {
        id: uuidv4(),
        firstName: values.fName,
        lastName: values.lName,
        phone: values.phone,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
        notes: values.notes,
        company:
          quarryName !== "" && quarryName !== undefined ? quarryName : "",
        isPrimaryContact: recordContactData?.isPrimaryContact,
      },
    };
    if (primaryContactCheck) {
      data["entityData"]["isPrimaryContact"] = true;
    } else {
      data["entityData"]["isPrimaryContact"] = false;
    }
    // console.log(data, "data");
    let contactData = this.state?.contactInfo?.filter(
      (c) => c?.email === values?.email && c?.phone === values?.phone
    );

    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number already exists");
      this.setState({
        loading: false,
      });
      return true;
    } else {
      this.setState({ loading: true, contactPopup: false });
      if (this.state.isCheckcontact) {
        this.updateQuarryRolesData(values["roleOther"]);
      }
      add(appConfig["entityNames"]["checkContact"], data)
        .then((result) => {
          if (result.data.message === "success") {
            message.success("Contact Added Successfully!");
            let contactInfo =
              this.state.contactInfo?.length > 0 ? this.state.contactInfo : [];
            if (recordContactData?.length > 0) {
              let filteredData = this.state.contactInfo.filter(
                (c) => c.id !== recordContactData?.id
              );
              filteredData?.push(data["entityData"]);
              this.setState({
                contactInfo: filteredData,
                loading: false,
                isCheckcontact: false,
                newRole: "",
                primaryContactCheck: false,
              });
            }
            contactInfo?.push(data["entityData"]);
            this.setState({
              contactInfo,
              loading: false,
              isCheckcontact: false,
              newRole: "",
              primaryContactCheck: false,
            });
          } else {
            message.info(result.data.message);
            this.setState({
              loading: false,
              isCheckcontact: false,
              newRole: "",
              primaryContactCheck: false,
            });
            return true;
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
        });
      // add(appConfig["entityNames"]["checkContact"], data)
      //   .then((result) => {
      //     if (result.data.message === "success") {

      // message.success("Contact Added Successfully!");
      // let contactInfo =
      //   this.state?.contactInfo?.length > 0 ? this.state?.contactInfo : [];
      // if (recordContactData?.length > 0) {
      //   let filteredData = this.state.contactInfo.filter(
      //     (c) => c.id !== recordContactData?.id
      //   );
      //   filteredData.push(data["entityData"]);

      //   this.setState({
      //     contactInfo: filteredData,
      //     loading: false,
      //     isCheckcontact: false,
      //     newRole: "",
      //   });
      // }
      // contactInfo.push(data["entityData"]);
      // this.setState({
      //   contactInfo,
      //   loading: false,
      //   isCheckcontact: false,
      //   newRole: "",
      // });
      //       } else {
      //         message.info(result.data.message);
      //         this.setState({
      //           loading: false,
      //           isCheckcontact: false,
      //           newRole: "",
      //         });
      //         return true;
      //       }
      //     })
      //     .catch((err) => {
      //       this.setState({
      //         loading: false,
      //       });
      //       console.log(err);
      //     });
    }
  };

  updateQuarryRolesData = (values) => {
    let quarryRolesData = this.state.quarryRoles;
    quarryRolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: quarryRolesData?.length + 1,
    });
    let data = {
      entityData: {
        quarryRolesData,
        typeofDropDown: "quarryRoles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.quarryRoles[0]?.id, data);
  };

  deleteContactInfo = (id) => {
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState({
      contactInfo,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    // console.log(data);
    this.setState({
      contactPopup: true,
      recordContactData: data,
      recordMaterialData: "",
      quarryName: data?.company,
      primaryContactCheck: data?.isPrimaryContact,
    });
  };

  handlelQuarries = (value) => {
    this.setState({
      showAllQuarries: value,
    });
  };

  mapComponent = (bothData) => {
    const { searchData, showSearch, showAllQuarries, materialCategory } =
      this.state;
    return (
      <MapComponent
        lat={17.793293585230707}
        lng={83.17732063382557}
        isMarkerShown
        mapFilterInfo={searchData}
        quarryData={bothData}
        quarry
        onRefresh={this.onClickRefresh}
        showSearchPosition={showSearch}
        handleClick={(e) => this.handleClick(e)}
        fetchAllQurries={this.fetchAllQurries}
        showAllQuarries={showAllQuarries}
        handlelQuarries={(e) => this.handlelQuarries(e)}
        materialCategory={materialCategory}
      />
    );
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, quarryTool: props })
    );
  };

  tableInfo = (bothData) => {
    const columns = [
      {
        title: "QUARRY NAME",
        dataIndex: "quarryName",
        key: "quarryName",
        // sorter: false,
        show: true,
        width: "20%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/quarry-profile/${record.id}`),
        }),
        sorter: (a, b) => a?.quarryName?.localeCompare(b?.quarryName),
        render: (quarryName, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                width: "10px",
              }}
            >
              {record?.contactDetails?.map((e) => (
                <div>{e?.isPrimaryContact === true}</div>
              ))}
              <Row className="col_style_quarry">{quarryName}</Row>
            </span>
          );
        },
      },
      {
        title: "LOCATION",
        dataIndex: "address",
        key: "address",
        show: true,
        sorter: false,
        className: "col_style_quarry",
        width: "16%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/quarry-profile/${record.id}`),
        }),
        render: (address, record) => {
          let data =
            record?.distance !== undefined
              ? record?.distance === 0
                ? 0
                : Math.floor(record?.distance)
              : "";
          let city, state;
          const addressParts = address?.split(",");
          if (addressParts?.length > 0) {
            city = addressParts[addressParts?.length - 2]?.trim();
            state = addressParts[addressParts?.length - 1]?.trim();
          }
          return (
            <span
              className="col_style_quarry"
              style={{ textTransform: "capitalize" }}
            >
              {city && state && (
                <span>
                  {city}, {state}
                  {/* <b>{searchInfo}</b> {address} */}
                  <b>{data !== "" && ` - ${data} Miles`}</b>
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: "Coordinates",
        dataIndex: "",
        key: "",
        show: true,
        sorter: false,
        className: "col_style_quarry",
        width: "14%",
        render: (record) => {
          const CopyButton = ({ text }) => {
            const handleCopy = () => {
              navigator.clipboard.writeText(text).then(() => {
                message.success("Coordinates copied successfully");
              });
            };

            return (
              <span onClick={handleCopy}>
                <CopyOutlined />
              </span>
            );
          };
          function truncateCoordinates(coordinates) {
            const maxLength = 15;
            if (coordinates.length <= maxLength) {
              return coordinates;
            }
            return coordinates.slice(0, maxLength) + "...";
          }
          return (
            <span
              className="col_style_quarry"
              style={{ textTransform: "capitalize" }}
            >
              {record.lat !== "" && record.lat !== null && (
                <span>
                  {truncateCoordinates(record.lat + ", " + record.lng)}{" "}
                  <CopyButton text={record.lat + ", " + record.lng} />
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: "PHONE NUMBER",
        dataIndex: "phone",
        key: "phone",
        show: true,
        sorter: false,
        width: "11%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/quarry-profile/${record.id}`),
        }),
        render: (phone) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                textTransform: "capitalize",
              }}
            >
              <div>{phone}</div>
            </span>
          );
        },
      },
      {
        title: "MATERIAL TYPE",
        dataIndex: "quarryType",
        key: "quarryType",
        show: true,
        sorter: false,
        className: "col_style_quarry",
        width: "13%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/quarry-profile/${record.id}`),
        }),
        render: (quarryType, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                width: "10px",
              }}
            >
              {quarryType?.map((eachItem) => (
                <div className="col_quarrytype">{eachItem?.key}</div>
              ))}
            </span>
          );
        },
      },
      {
        title: "ACCOUNT LIMIT",
        dataIndex: "accountLimit",
        key: "accountLimit",
        show: true,
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/quarry-profile/${record.id}`),
        }),
        render: (accountLimit, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                width: "10px",
              }}
            >
              {accountLimit}
            </span>
          );
        },
      },
      {
        title: "CURRENT BALANCE",
        dataIndex: "currentBalance",
        key: "currentBalance",
        show: true,
        // sorter: false,
        className: "col_style_quarry",
        width: "10%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/quarry-profile/${record.id}`),
        }),
        sorter: (a, b) =>
          parseInt(a?.currentBalance) - parseInt(b?.currentBalance),
        render: (currentBalance, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                width: "10px",
              }}
            >
              {currentBalance}
            </span>
          );
        },
      },
      {
        title: "OVERDUE BALANCE",
        dataIndex: "overdueBalance",
        key: "overdueBalance",
        show: true,
        // sorter: false,
        className: "col_style_quarry",
        width: "10%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/quarry-profile/${record.id}`),
        }),
        sorter: (a, b) =>
          parseInt(a?.overduebalance) - parseInt(b?.overduebalance),
        render: (overdueBalance, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                width: "10px",
              }}
            >
              {overdueBalance}
            </span>
          );
        },
      },
      {
        title: "ACTIONS",
        dataIndex: "tag",
        key: "tag",
        show: true,
        sorter: false,
        width: "120px",
        className: "col_style_quarry",
        fixed: "right",
        render: (tag, record) => {
          return (
            <>
              <Row>
                <Col>
                  <Popover
                    content={
                      <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.editModal(record)}
                          >
                            <span className="span_edit">
                              <MdModeEditOutline
                                style={{ marginRight: "5px" }}
                              />
                              Edit
                            </span>
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Button className="btn_edit">
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => this.deleteQuarry(record)}
                            >
                              <span className="span_edit">
                                <MdDelete style={{ marginRight: "5px" }} />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                      </Row>
                    }
                    trigger="hover"
                    placement="left"
                  >
                    <EllipsisOutlined
                      style={{
                        fontSize: "35px",
                        color: "grey",
                        marginLeft: "16px",
                      }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      },
    ].filter((item) => item.show);
    return (
      <BaseTable
        className="quarrytooltable table_laptopscreen"
        columnsData={columns}
        source={bothData}
        handleTableChage={this.handleTableChage}
        sizeRange={
          window.screen.width > 1023
            ? JSON.parse(localStorage.getItem("tableSizeRange"))?.quarryTool
            : bothData?.length
        }
        pagination={window.screen.width > 1023 ? true : false}
        total={bothData?.length}
        rowKey={(record) => record.id}
      />
    );
  };

  onChange = (time, timeString) => {
    console.log(time, timeString);
  };

  onSelectMaterialCat = (e, type) => {
    if (type === "edit") {
      const { materialList } = this.state;
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    } else {
      const { materialList } = this.state;
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      if (e) {
        this.formRef.current.setFieldsValue({ material: undefined, price: "" });
        this.setState({
          materialSelectedData: "",
        });
      }
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    }
  };

  onSelectMaterial = (e) => {
    const { materialList } = this.state;
    materialList?.filter(
      (f) => f.id === e && this.setState({ materialSelectedData: f })
    );
  };

  selectQuarryType = (value, option) => {
    this.setState({
      quarryType: option,
    });
  };

  onResetData = () => {
    this.setState({
      searchData: undefined,
    });
  };

  onSelectAccountTerms = (e) => {
    this.setState({ selectedTerm: e });
    this.formRef.current.setFieldsValue({
      accountTermsOthers: "",
    });
  };

  addNotes = (values) => {
    const { recordNotesData } = this.state;
    this.setState({ notesPopup: false });
    if (recordNotesData !== "") {
      let filteredData = this.state.notesData.filter(
        (c) => c.id !== recordNotesData?.id
      );
      this.setState({
        notesData: filteredData,
      });
    }

    let data = {
      entityData: {
        id: recordNotesData?.id !== undefined ? recordNotesData?.id : uuidv4(),
        date:
          recordNotesData?.date !== undefined
            ? recordNotesData?.date
            : moment().format("MM-DD-YYYY LT"),
        typeofNotes: values["type"],
        notes: values["notes"],
      },
    };
    // console.log(data);
    let notesInfo =
      this.state?.notesData?.length > 0 ? this.state?.notesData : [];
    notesInfo.push(data["entityData"]);
    this.setState({ notesData: notesInfo });
    message.info("Notes Added Successfully");
  };

  editNotes = (data) => {
    this.setState({
      notesPopup: true,
      recordNotesData: data,
    });
  };

  deleteNotes = (id) => {
    let notesInfo = this.state.notesData.filter((c) => c?.id !== id);
    this.setState({
      notesData: notesInfo,
    });
    message.info("Notes Deleted Successfully!");
  };

  handleCheckStatus = (e) => {
    this.setState({ diameter: e });
    this.formRef.current.setFieldsValue({
      materialDiameter: "",
      materialMinDiameter: "",
      materialMaxDiameter: "",
    });
  };

  handleFileDelete = (info, type) => {
    const { recordMaterialData, materialAttachment } = this.state;
    if (type === "materialPicture") {
      if (recordMaterialData) {
        console.log(info, "info");
        let docData = materialAttachment?.filter((e) => e.id !== info.id);
        this.setState({
          materialAttachment: docData,
        });
      } else {
        let docData = materialAttachment?.filter((e) => e.id !== info.id);
        this.setState({
          materialAttachment: docData,
        });
      }
      this.setState({
        imageLoading: false,
        loading: false,
        percentage: 0,
      });
    }
  };

  addCategory = (values) => {
    const {
      selectedMaterialType,
      subCategory1,
      materialList,
      categoryKey,
      subShortName,
      subCategory2,
    } = this.state;

    let duplicateMaterialValue = subCategory2?.filter(
      (e) => e.subType === values["materialsub2"]
    );
    let updateSub2 = subCategory2?.filter((e) => e.subType === "");
    let data = {};
    if (duplicateMaterialValue?.length === 0) {
      if (updateSub2?.length > 0) {
        materialList?.forEach((e) => {
          if (e.id === updateSub2[0].id) {
            e[
              "materialValue"
            ] = `${selectedMaterialType} - ${subCategory1} - ${values["materialsub2"]}`;
            e["subCategory"] = `${selectedMaterialType} - ${subCategory1}`;
            e["label"] = `${subCategory1} - ${values["materialsub2"]}`;
          }
        });
      } else {
        data = {
          materialValue: `${selectedMaterialType} - ${subCategory1} - ${values["materialsub2"]}`,
          subCategory: `${selectedMaterialType} - ${subCategory1}`,
          categoryKey: categoryKey,
          id: uuidv4(),
          label: `${subCategory1} - ${values["materialsub2"]}`,
          category: selectedMaterialType,
          statusCode: true,
          subShortName: subShortName,
        };
        materialList?.push(data);
      }

      let submitData = {
        entityData: {
          materialsNewList: materialList,
          typeofDropDown: "material",
        },
      };

      // Adding SubCategory2 in Materials
      let sub2 = {
        subType: values["materialsub2"],
        id: updateSub2?.length > 0 ? updateSub2[0].id : data["id"],
      };
      if (updateSub2?.length > 0) {
        subCategory2[0]["subType"] = values["materialsub2"];
      } else {
        subCategory2.push(sub2);
      }
      this.setState({
        addCategory: false,
        subCategory2,
      });
      // Updating New Material values
      this.props.actionupdateDropDownOptions(
        this.props.materials[0]?.id,
        submitData
      );
      message.success("Material Added successfully!");
      this.onSelectMaterial(sub2["id"]);
      this.formRef.current.setFieldsValue({
        subCategory2: sub2["id"],
      });
    } else {
      message.error("Material name already exists!");
    }
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const {
      materialAttachment,
      addCategory,
      subCategory2,
      subCategory1,
      quarryRoles,
      selected,
      selectedRowKeys,
      citySearch,
      leadsData,
      checkData,
      selectedMaterial,
      contactPopup,
      recordMaterialData,
      selectedMaterialType,
      recordContactData,
      newRole,
      materialPopup,
      contactInfo,
      filterData,
      quantityType,
      selectedTerm,
      notesData,
      notesPopup,
      recordNotesData,
      diameter,
      imageLoading,
      materialList,
      materialCategory,
      searchingData,
      searchData,
      localLoading,
    } = this.state;

    const { loading, errorLoading } = this.props;

    const selectAfter = (
      <Select
        defaultValue={quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading || localLoading} />
        <Row style={{ marginBottom: "1vw" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            xxl={{ span: 6 }}
            // span={12}
          >
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                // span={10}
                className="quarrytoolgrid"
                style={{ display: "flex" }}
              >
                <Search
                  className="products-search"
                  size="large"
                  placeholder="Search"
                  style={{ borderRadius: "5px" }}
                  enterButton
                  onChange={this.searchResult}
                  allowClear
                />
                {searchData && !selected && (
                  <img
                    src={blueIcon}
                    alt=""
                    style={{
                      width: "20px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => this.handleClick(true)}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 18 }}
            xl={{ span: 18 }}
            xxl={{ span: 18 }}
          >
            <Row
              style={{
                justifyContent: "right",
              }}
              className="total-layout-quarry-database"
            >
              <Col className="gridbtntool">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={this.showModal}
                >
                  <span
                    style={{ fontWeight: "500", fontSize: "16px" }}
                    className="col_styling table-font-mobile"
                  >
                    Quarry
                  </span>
                </Button>
              </Col>
              <Col className="gridbtntool">
                <Button
                  className="filterButton"
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => {
                    this.setState({ filterVisible: true });
                  }}
                >
                  <Row>
                    <Col>
                      <span
                        style={{
                          color:
                            Object.keys(filterInfo)?.length > 0
                              ? "red"
                              : "#5a6067",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                        className="col_styling table-font-mobile"
                      >
                        {Object.keys(filterInfo)?.length > 0
                          ? `${"Filter (" + Object.keys(filterInfo)?.length})`
                          : "Filter"}
                      </span>
                    </Col>
                    <Col>
                      <IoFilterSharp
                        style={{
                          fontSize: "22px",
                          marginLeft: "8px",
                          marginTop: "2px",
                          color:
                            Object.keys(filterInfo)?.length > 0
                              ? "red"
                              : "#5a6067",
                        }}
                        className="trucking-filter-icon"
                      />
                    </Col>
                  </Row>
                </Button>
              </Col>
              {/*  )} */}
              <Col className="gridbtntool">
                <Row
                  style={{
                    background: "#cecece",
                    height: "40px",
                    borderRadius: "6px",
                  }}
                >
                  <Col style={{ marginTop: "4px" }}>
                    <Button
                      style={{
                        border: "none",
                        borderRadius: "5px",
                        padding: "5px 8px",
                        margin: "0px 5px",
                        background: selected ? "#FFF" : "transparent",
                      }}
                      onClick={() =>
                        this.setState({
                          selected: true,
                          bothData: filterData,
                          quarryData: [],
                        })
                      }
                    >
                      <UnorderedListOutlined
                        style={{
                          fontSize: "22px",
                          color: this.state.selected ? "#586370" : "#586370",
                        }}
                      />
                    </Button>
                  </Col>
                  <Col style={{ marginTop: "4px" }}>
                    <Button
                      style={{
                        border: "none",
                        borderRadius: "5px",
                        padding: "5px 8px",
                        margin: "0px 5px",
                        background: this.state.selected
                          ? "transparent"
                          : "#FFF",
                      }}
                      onClick={() => this.setState({ selected: false })}
                    >
                      <FaMap
                        style={{
                          fontSize: "22px",
                          color: !this.state.selected ? "#586370" : "#586370",
                        }}
                      />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row>
          <span
            style={{ marginBottom: "1vw", fontSize: "16px", color: "#495057" }}
          >
            20 quarry found near<b> New York</b>
          </span>
        </Row> */}

        <Row>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            {errorLoading ? (
              <ErrorView
                refreshQuarry={this.onClickRefresh}
                type="QuarryTool"
              />
            ) : (
              <Card bodyStyle={{ padding: "0px" }} data-aos="fade-up">
                {selected ? (
                  <>{this.tableInfo(filterData)}</>
                ) : (
                  <div className="quarryheight_tool">
                    {this.mapComponent(searchingData)}
                  </div>
                )}
              </Card>
            )}

            {selectedRowKeys.length > 0 && (
              <FooterData count={selectedRowKeys.length} call />
            )}
          </Col>
        </Row>

        {this.state.filterVisible && (
          <Filter
            visible={this.state.filterVisible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            filterPropQuarryData={checkData}
            onClearFilter={this.onClearFilter}
            QuarryTool
            filterQuarryData={this.state.quarryData}
            materialCategory={materialCategory}
          />
        )}

        {this.state?.isModalVisible && (
          <BaseDrawer
            visible={this.state?.isModalVisible}
            className="quarrytool_modal mapquarry"
            title={leadsData !== "" ? "Update Quarry" : "Add Quarry"}
            onClose={() => {
              this.setState({
                isModalVisible: false,
                selectedMaterial: [],
                selectedTerm: "",
              });
            }}
            formId="Quarry"
            destroyOnClose={true}
            footer={[
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    this.setState({
                      isModalVisible: false,
                      selectedMaterial: [],
                      materialAttachment: [],
                    });
                  }}
                  className="cancelbtnd"
                  style={{
                    height: "35px",
                    width: "100px",
                    borderRadius: "5px",
                    marginRight: "25px",
                  }}
                  type="ghost"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    height: "35px",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  form="Quarry"
                >
                  Save
                </Button>
              </div>,
            ]}
          >
            <Form
              layout="vertical"
              onFinish={this.onFinish}
              id="Quarry"
              ref={this.formRef}
            >
              <Row gutter={[24, 0]}>
                {/* <Col
                  // xxl={{ span: 11, offset: 0 }}
                  // xl={{ span: 11, offset: 0 }}
                  // lg={{ span: 11, offset: 0 }}
                  // md={{ span: 24, offset: 0 }}
                  // sm={{ span: 24, offset: 0 }}
                  // xs={{ span: 24, offset: 0 }}
                  span={24}
                  // offset={0}
                >
                  <span className="title_changes">Quarry Type</span>
                  <Form.Item
                    name="quarryType"
                    rules={[
                      {
                        required: false,
                        message: "Please Select your Quarry Type!",
                      },
                    ]}
                    initialValue={leadsData?.quarryType}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      allowClear
                      // className="select_Height"
                      style={{
                        height: "auto !important",
                        width: "100% !important",
                      }}
                      className="select_multiplequarytol"
                      onChange={this.selectQuarryType}
                      placeholder="Please Select A Customer Account"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {materialsNewList?.category?.map((eachItem) => (
                        <Option
                          key={eachItem?.code}
                          value={eachItem?.id}
                          className="option_style"
                        >
                          {eachItem?.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col span={24}>
                  <span className="title_changes">Quarry Name</span>
                  <Form.Item
                    name="quarryName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your quarry Name!",
                      },
                    ]}
                    initialValue={leadsData?.quarryName}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter your quarry Name"
                      onChange={(e) =>
                        this.setState({ quarryName: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Quarry Nick Name</span>
                  <Form.Item
                    name="quarryNickName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Nick Name!",
                      },
                    ]}
                    initialValue={leadsData?.quarryNickName}
                  >
                    <Input
                      className="form-input"
                      placeholder="Please Enter your Nick Name"
                      maxLength={12}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Quarry Phone Number</span>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Phone",
                      },
                    ]}
                    initialValue={leadsData?.phone}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      format="### ###-####"
                      style={{ width: "100%" }}
                      placeholder="Please Enter Your Phone"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row justify={"space-between"}>
                    <span className="title_changes">
                      {this.state.showLocationInput
                        ? "Location"
                        : "Coordinates"}{" "}
                    </span>

                    <span
                      className="title_changes"
                      style={{ fontSize: "12px" }}
                    >
                      {this.state.showLocationInput ? "Disable" : "Enable"}{" "}
                      Coordinates{" "}
                      <Switch
                        checked={!this.state.showLocationInput}
                        onChange={() => {
                          this.setState({
                            showLocationInput: !this.state.showLocationInput,
                          });
                          this.formRef.current.setFieldsValue({
                            lat: "",
                            lng: "",
                          });
                        }}
                      />
                    </span>
                  </Row>
                </Col>
                {this.state.showLocationInput ? (
                  <Col span={24}>
                    <Form.Item
                      name="location"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Location!",
                        },
                      ]}
                    >
                      <GooglePlacesAutocomplete
                        className="form-input"
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          citySearch,
                          defaultInputValue: `${
                            leadsData?.address ? leadsData?.address : ""
                          }`,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.handleSelect(address);
                            this.getAddress(address);
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Latitude</span>
                        <Form.Item
                          name="lat"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Cordinates!",
                            },
                          ]}
                          initialValue={leadsData?.lat && leadsData?.lat}
                        >
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Please Paste Cordinates Here"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Longitude</span>
                        <Form.Item
                          name="lng"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Cordinates!",
                            },
                          ]}
                          initialValue={leadsData?.lng && leadsData?.lng}
                        >
                          <Input
                            type="text"
                            placeholder="Please Paste Cordinates Here"
                          />
                        </Form.Item>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                )}

                {/* <Col span={24}>
                  <span className="title_changes">Location</span>
                  <Form.Item
                    name="location"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Location!",
                      },
                    ]}
                  >
                    <GooglePlacesAutocomplete
                      className="form-input"
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        citySearch,
                        defaultInputValue: `${
                          leadsData?.address ? leadsData?.address : ""
                        }`,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                          this.getAddress(address);
                        },
                      }}
                    />
                  </Form.Item>
                </Col> */}
                {/* ONLY IF CO-ORDINATES ARE REQUIRED THEN BELOW CODE IS USED IN FORM */}
                {/* <Col span={24}>
                  <span className="title_changes">Co-ordinates</span>
                  <Form.Item
                    name="latLng"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Cordinates!",
                      },
                    ]}
                    initialValue={
                      leadsData?.lat && leadsData?.lat + ", " + leadsData?.lng
                    }
                  >
                    <Input
                      className="ant-input-number numberictest"
                      // format="##.#######"
                      type="text"
                      style={{ width: "100%" }}
                      placeholder="Please Paste Cordinates Here"
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                  style={{ marginBottom: "10px" }}
                >
                  <Row style={{ alignItems: "baseline" }}>
                    <span className="title_changes">Charge Account</span>
                    <Form.Item
                      name="chargeAccount"
                      rules={[
                        {
                          required: false,
                          message: "Please enter your Charge Account",
                        },
                      ]}
                      className="switchtoggle"
                    >
                      <Switch
                        defaultChecked={leadsData?.chargeAccount}
                        checkedChildren={"Yes"}
                        unCheckedChildren={"No"}
                        // onChange={(e) => this.showSwitch(e)}
                      />
                    </Form.Item>
                  </Row>
                </Col> */}
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Account Terms</span>
                  <Form.Item
                    name="accountTerms"
                    rules={[
                      {
                        required: true,
                        message: "Select Your Account Terms",
                      },
                    ]}
                    initialValue={leadsData?.accountTerms}
                  >
                    <Select
                      size="large"
                      allowClear
                      className="select_multiplequarytol"
                      placeholder="Account Terms"
                      showArrow
                      style={{ width: "100%" }}
                      onChange={this.onSelectAccountTerms}
                    >
                      <Option value="Net 30" key="Net 30">
                        Net 30
                      </Option>
                      <Option value="Cash" key="Cash">
                        Cash/Prepaid
                      </Option>
                      <Option value="Custom Input" key="Custom Input">
                        Custom Input
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                {selectedTerm === "Custom Input" && (
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={12}
                  >
                    <span className="title_changes">
                      Account Terms (Others)
                    </span>
                    <Form.Item
                      name="accountTermsOthers"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter New Account Terms",
                        },
                      ]}
                    >
                      <NumberFormat
                        className="ant-input-number numberictest"
                        style={{ width: "100%" }}
                        prefix={"Net "}
                        placeholder="Please Enter New Account Terms"
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Early Pay Discount</span>
                  <Form.Item
                    initialValue={leadsData?.payDiscount}
                    name="payDiscount"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your Discount",
                      },
                    ]}
                  >
                    <InputNumber
                      // prefix=""
                      // suffix={
                      //   <Tooltip title="Ex. 2% Net 7 implies a 2% discount if paid prior to Net 7">
                      //     <HiInformationCircle
                      //       style={{ color: "rgba(0,0,0,.45)" }}
                      //     />
                      //   </Tooltip>
                      // }
                      formatter={(value) => (value !== "" ? `${value}%` : "")}
                      style={{ width: "100%" }}
                      placeholder="Please enter your Discount"
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Discount Terms</span>
                  <Form.Item
                    initialValue={leadsData?.payDiscountTerms}
                    name="payDiscountTerms"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your Terms",
                      },
                    ]}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      style={{ width: "100%" }}
                      prefix={"Net "}
                      placeholder="Please Enter New Account Terms"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes"> Pit Open</span>
                  <Form.Item
                    name="startTime"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your  Pit Open",
                      },
                    ]}
                    initialValue={
                      leadsData?.startTime && moment(leadsData?.startTime)
                    }
                  >
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      minuteStep={15}
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      className="time-picker "
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Pit Close</span>
                  <Form.Item
                    name="endTime"
                    rules={[
                      {
                        required: false,
                        message: "Please enter Pit Close",
                      },
                    ]}
                    initialValue={
                      leadsData?.endTime && moment(leadsData?.endTime)
                    }
                  >
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      minuteStep={15}
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      className="time-picker "
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={11}
                >
                  <span className="title_changes">Account Charge Limit</span>
                  <Form.Item
                    initialValue={leadsData?.accountLimit}
                    name="accountLimit"
                    rules={[
                      {
                        required: false,
                        message: "type your Account Limit",
                      },
                    ]}
                  >
                    <NumberFormat
                      className="ant-input-number numberictest"
                      // format="#######"
                      style={{ width: "100%" }}
                      prefix={"$ "}
                      suffix={".00"}
                      thousandSeparator
                      placeholder="Please enter your Account Limit"
                    />
                  </Form.Item>
                </Col>

                {/* <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Current Balance</span>
                    <Form.Item
                      initialValue={""}
                      name="currentbalance"
                      rules={[
                        {
                          required: true,
                          message: "Current Balance require",
                        },
                      ]}
                    >
                      <Input
                        // prefix=""
                        // suffix={
                        //   <Tooltip title="Ex. 2% Net 7 implies a 2% discount if paid prior to Net 7">
                        //     <HiInformationCircle
                        //       style={{ color: "rgba(0,0,0,.45)" }}
                        //     />
                        //   </Tooltip>
                        // }
                        style={{ width: "100%" }}
                        placeholder="Please enter your current balance"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    // span={11}
                  >
                    <span className="title_changes">Overdue Balance</span>
                    <Form.Item
                      initialValue={""}
                      name="overduebalance"
                      rules={[
                        {
                          required: true,
                          message: "Overdue Balance require",
                        },
                      ]}
                    >
                      <Input
                        // prefix=""
                        // suffix={
                        //   <Tooltip title="Ex. 2% Net 7 implies a 2% discount if paid prior to Net 7">
                        //     <HiInformationCircle
                        //       style={{ color: "rgba(0,0,0,.45)" }}
                        //     />
                        //   </Tooltip>
                        // }
                        style={{ width: "100%" }}
                        placeholder="Please enter your Overdue Balance "
                        className="form-input"
                      />
                    </Form.Item>
                    </Col> */}
                <>
                  {selectedMaterial?.length > 0 && (
                    <span
                      className="title_changes table-font-mobile"
                      style={{
                        marginLeft: "14px",
                        fontSize: "17px",
                        fontWeight: "500",
                        borderBottom: "1px solid #586370",
                      }}
                    >
                      Materials & Quantity:
                    </span>
                  )}

                  <Col span={24}>
                    {selectedMaterial?.map((m) => (
                      <Popover
                        title={null}
                        trigger="hover"
                        placement="bottom"
                        content={
                          <Row>
                            <Col span={24}>
                              <span>{m.label}</span>
                            </Col>
                          </Row>
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            width: "100%",
                          }}
                          className="loop-hrline"
                        >
                          <span
                            style={{
                              margin: "0px 3px",
                              wordBreak: "break-word",
                              width: "100%",
                              // maxWidth: "625px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              fontSize: "16px",
                            }}
                            className="col_styling table-font-mobile"
                          >
                            {m?.label}
                          </span>
                          <span
                            className="title_changes"
                            style={{
                              margin: "0px 3px",
                              wordBreak: "break-word",
                              width: "100%",
                              // maxWidth: "625px",
                              textOverflow: "ellipsis",
                              // whiteSpace: "nowrap",
                              // overflow: "hidden",
                              fontSize: "16px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "100%",
                                fontWeight: "400",
                              }}
                              className="col_styling table-font-mobile "
                            >
                              {` - ($${parseFloat(m?.price)?.toFixed(2)} per ${
                                m?.quantityType
                              })`}
                            </span>
                          </span>
                          <Col>
                            <Popover
                              placement="left"
                              content={
                                <Row
                                  className="popovergrid"
                                  style={{ maxWidth: "90px" }}
                                >
                                  <Col span={24}>
                                    <Button
                                      className="btn_edit"
                                      onClick={() => this.editMaterial(m)}
                                    >
                                      <span className="span_edit">
                                        <MdModeEditOutline
                                          style={{
                                            marginRight: "5px",
                                          }}
                                        />{" "}
                                        Edit
                                      </span>
                                    </Button>
                                  </Col>
                                  <Col span={24}>
                                    <Button className="btn_edit">
                                      <Popconfirm
                                        title="Are you sure？"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() =>
                                          this.deleteMaterial(m?.materialId)
                                        }
                                        // onConfirm={() => this.deleteCompany(record)}
                                      >
                                        <span className="span_edit">
                                          <MdDelete
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          />
                                          Delete
                                        </span>
                                      </Popconfirm>
                                    </Button>
                                  </Col>
                                </Row>
                              }
                            >
                              <EllipsisOutlined
                                style={{
                                  fontSize: "30px",
                                  color: "grey",
                                }}
                                className="action-ellipsis-button"
                              />
                            </Popover>
                          </Col>
                        </div>
                      </Popover>
                    ))}
                  </Col>
                </>
                <Col span={24}>
                  <Form.Item name="button">
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        fontWeight: "500",
                        height: "40px",
                        marginTop: "25px",
                      }}
                      onClick={() => {
                        this.setState({
                          materialAttachment: [],
                          materialPopup: true,
                          recordMaterialData: "",
                          quantityType: "Ton",
                          diameter: "",
                          selectedMaterialType: "Unknown",
                        });
                        Object.values(materialCategory["Unknown"])?.map(
                          (c, i) => {
                            this.setState({
                              subCategory1: c.subcat,
                              subCategory2: c.subTypes,
                              categoryKey: c.categoryKey,
                              subShortName: c.subShortName,
                            });
                            materialList?.forEach((f) => {
                              if (f.id === c.subTypes?.find((e) => e.id)?.id) {
                                this.setState({ materialSelectedData: f });
                              }
                            });
                            return c;
                          }
                        );
                      }}
                    >
                      ADD MATERIAL
                    </Button>
                  </Form.Item>
                </Col>

                {leadsData?.contactDetails?.length > 0 && (
                  <Col span={24}>
                    <span
                      className="title_changes"
                      style={{
                        // marginLeft: "14px",
                        fontSize: "17px",
                        fontWeight: "500",
                        borderBottom: "1px solid #586370",
                      }}
                    >
                      Contact Details:
                    </span>
                  </Col>
                )}

                {contactInfo?.map((c) => (
                  <Col span={24}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                      className="loop-hrline"
                    >
                      <Popover
                        content={
                          (c?.phone || c?.email) && (
                            <Row>
                              <Col span={24}>
                                <span>{c.phone}</span>
                              </Col>
                              <Col span={24}>
                                <span
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: "left",
                                  }}
                                >
                                  {c.email}
                                </span>
                              </Col>
                            </Row>
                          )
                        }
                      >
                        <span
                          className="title_changes"
                          style={{
                            margin: "0px 5px",
                            wordBreak: "break-word",
                            width: "100%",
                            maxWidth: "270px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textTransform: "capitalize",
                            cursor: "pointer",
                          }}
                        >
                          {c.contactRole
                            ? c.firstName +
                              " " +
                              c.lastName +
                              ", " +
                              c.contactRole
                            : c.firstName + " " + c.lastName}
                        </span>
                      </Popover>
                      <Col>
                        <Popover
                          placement="left"
                          content={
                            <Row
                              className="popovergrid"
                              style={{ maxWidth: "90px" }}
                            >
                              <Col span={24}>
                                <Button
                                  className="btn_edit"
                                  onClick={() => this.editContactInfo(c)}
                                >
                                  <span className="span_edit">
                                    <MdModeEditOutline
                                      style={{
                                        marginRight: "5px",
                                      }}
                                    />{" "}
                                    Edit
                                  </span>
                                </Button>
                              </Col>
                              <Col span={24}>
                                <Button className="btn_edit">
                                  <Popconfirm
                                    title="Are you sure？"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() =>
                                      this.deleteContactInfo(c.id)
                                    }
                                    // onConfirm={() => this.deleteCompany(record)}
                                  >
                                    <span className="span_edit">
                                      <MdDelete
                                        style={{
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                      Delete
                                    </span>
                                  </Popconfirm>
                                </Button>
                              </Col>
                            </Row>
                          }
                        >
                          <EllipsisOutlined
                            style={{
                              fontSize: "30px",
                              color: "grey",
                            }}
                            className="action-ellipsis-button"
                          />
                        </Popover>
                      </Col>
                    </div>
                  </Col>
                ))}

                <Col span={24}>
                  <Form.Item name="button">
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        fontWeight: "500",
                        height: "40px",
                      }}
                      onClick={() =>
                        this.setState({
                          contactPopup: true,
                          recordContactData: "",
                        })
                      }
                    >
                      ADD CONTACT
                    </Button>
                  </Form.Item>
                </Col>

                {leadsData?.notesDetails?.length > 0 && (
                  <Col span={24}>
                    <span
                      className="title_changes"
                      style={{
                        // marginLeft: "14px",
                        fontSize: "17px",
                        fontWeight: "500",
                        borderBottom: "1px solid #586370",
                      }}
                    >
                      Notes:
                    </span>
                  </Col>
                )}

                {notesData?.map((c) => (
                  <Col span={24}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                      className="loop-hrline"
                    >
                      <Popover
                        content={
                          (c?.date || c?.typeofNotes) && (
                            <Row>
                              <Col span={24}>
                                <span>{c.date}</span>
                              </Col>
                              <Col span={24}>
                                <span
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: "left",
                                  }}
                                >
                                  {c.typeofNotes}
                                </span>
                              </Col>
                            </Row>
                          )
                        }
                      >
                        <span
                          className="title_changes"
                          style={{
                            margin: "0px 5px",
                            wordBreak: "break-word",
                            width: "100%",
                            maxWidth: "270px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textTransform: "capitalize",
                            cursor: "pointer",
                          }}
                        >
                          {c.notes}
                        </span>
                      </Popover>
                      <Col>
                        <Popover
                          placement="left"
                          content={
                            <Row
                              className="popovergrid"
                              style={{ maxWidth: "90px" }}
                            >
                              <Col span={24}>
                                <Button
                                  className="btn_edit"
                                  onClick={() => this.editNotes(c)}
                                >
                                  <span className="span_edit">
                                    <MdModeEditOutline
                                      style={{
                                        marginRight: "5px",
                                      }}
                                    />{" "}
                                    Edit
                                  </span>
                                </Button>
                              </Col>
                              <Col span={24}>
                                <Button className="btn_edit">
                                  <Popconfirm
                                    title="Are you sure？"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => this.deleteNotes(c.id)}
                                    // onConfirm={() => this.deleteCompany(record)}
                                  >
                                    <span className="span_edit">
                                      <MdDelete
                                        style={{
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                      Delete
                                    </span>
                                  </Popconfirm>
                                </Button>
                              </Col>
                            </Row>
                          }
                        >
                          <EllipsisOutlined
                            style={{
                              fontSize: "30px",
                              color: "grey",
                            }}
                            className="action-ellipsis-button"
                          />
                        </Popover>
                      </Col>
                    </div>
                  </Col>
                ))}

                <Col span={24}>
                  <Form.Item name="button">
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        fontWeight: "500",
                        height: "40px",
                      }}
                      onClick={() =>
                        this.setState({
                          notesPopup: true,
                          recordNotesData: "",
                        })
                      }
                    >
                      ADD NOTES
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseDrawer>
        )}

        {materialPopup && (
          <BaseModal
            title={
              recordMaterialData !== ""
                ? "Update Material Details"
                : "Add New Material"
            }
            onCancel={() => {
              this.setState({
                materialPopup: false,
                materialCheck: false,
                selectedMaterialType: "",
                subCategory2: [],
                subCategory1: "",
                materialAttachment: [],
              });
            }}
            visible={materialPopup}
            destroyOnClose={true}
            formId="addMaterial"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"40vw"}
          >
            <Form
              onFinish={this.onFinishMaterial}
              initialValues={{ remember: true }}
              id="addMaterial"
              ref={this.formRef}
            >
              <Row gutter={[24, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Material Categories</span>
                  <Form.Item
                    name="cat"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Your Material Category!",
                      },
                    ]}
                    initialValue={selectedMaterialType}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e) => {
                        this.setState({
                          selectedMaterialType: e,
                        });
                        if (e) {
                          this.formRef.current.setFieldsValue({
                            subCategory1: undefined,
                            subCategory2: undefined,
                            price: "",
                          });
                          this.setState({
                            subCategory1: "",
                            subCategory2: [],
                          });
                        }
                      }}
                      // onChange={this.onSelectMaterialCat}
                    >
                      {Object.keys(materialCategory)?.map((c, i) => (
                        <Option key={i} value={c}>
                          {c}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Material Type</span>
                  <Form.Item
                    name="subCategory1"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Material Type!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.label?.split(" - ")[0] || "Unknown"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e, option) => {
                        this.setState({
                          subCategory1: e,
                          subCategory2: option.label,
                          categoryKey: option.labelprop,
                          subShortName: option.labelprop2,
                        });
                        if (e) {
                          this.formRef.current.setFieldsValue({
                            subCategory2: undefined,
                            price: "",
                          });
                        }
                      }}
                      // onChange={this.onSelectMaterial}
                    >
                      {selectedMaterialType &&
                        materialCategory[selectedMaterialType] !== undefined &&
                        Object.values(
                          materialCategory[selectedMaterialType]
                        )?.map((c, i) => (
                          <Option
                            key={i}
                            value={c?.subcat}
                            label={c?.subTypes}
                            labelprop={c?.categoryKey}
                            labelprop2={c?.subShortName}
                          >
                            {c?.subcat}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span
                    className="title_changes"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Material Name
                  </span>

                  <div className="contactname-multiple">
                    <Form.Item
                      name="subCategory2"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Material Name!",
                        },
                      ]}
                      initialValue={
                        subCategory1
                          ? recordMaterialData?.id || "Unknown"
                          : recordMaterialData?.value?.split(" - ")[2] ||
                            "Unknown"
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height select_arrow_none"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Category2"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={this.onSelectMaterial}
                      >
                        {selectedMaterialType &&
                          subCategory1 &&
                          subCategory2?.map(
                            (c, i) =>
                              c.subType !== "" && (
                                <Option key={i} value={c.id}>
                                  {c.subType}
                                </Option>
                              )
                          )}
                      </Select>
                    </Form.Item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                        padding: "8px",
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        height: "40px",
                        borderLeft: "1px solid #ddd",
                        zIndex: "+9",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (selectedMaterialType && subCategory1) {
                          this.setState({
                            addCategory: true,
                          });
                        } else {
                          message.info(
                            "Please add Material Category & Material Type"
                          );
                        }
                      }}
                    >
                      <span
                        className="title_changes"
                        style={{ fontSize: "13px", fontWeight: "500" }}
                      >
                        <PlusOutlined
                          style={{ color: "#000", marginRight: "5px" }}
                        />
                        Material
                      </span>
                    </div>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Price</span>
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Price",
                      },
                    ]}
                    style={{ marginBottom: "10px" }}
                    initialValue={recordMaterialData?.price}
                  >
                    <InputNumber
                      // prefix={"$"}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      precision={2}
                      decimalSeparator={"."} // value={quantity}
                      addonBefore={"$"}
                      addonAfter={selectAfter}
                      placeholder="Please Enter Price"
                      // addonAfter={selectAfter}
                      style={{ height: "40px", width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Testing Status</span>
                  <Form.Item
                    name="materialTest"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Testing!",
                      },
                    ]}
                    initialValue={recordMaterialData?.materialTest || "Unknown"}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A testMaterial"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.testMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Washed Status</span>
                  <Form.Item
                    name="materialStatus"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter washed status!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialStatus || "Unknown"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A statusMaterial"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.statusMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {diameter ? (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 14 }}
                    md={{ span: 18 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ marginTop: "20px" }}
                  >
                    <Row gutter={[12, 0]}>
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 14 }}
                        md={{ span: 18 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Min</span>
                        <Form.Item
                          name="materialMinDiameter"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialMinDiameter}
                          style={{ marginBottom: "10px" }}
                        >
                          {/* <InputNumber
                              formatter={(value) =>
                                value !== "" ? `${value}"` : ""
                              }
                              style={{ width: "100%", height: "40px" }}
                              placeholder="Please enter your diameter"
                              className="form-input"
                            /> */}
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            className="form-input"
                            // formatter={(value) => `${value}"`}
                            placeholder="Please enter diameter"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 14 }}
                        md={{ span: 18 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="title_changes">Max</span>
                          <Switch
                            checkedChildren={"Diameter"}
                            unCheckedChildren={"Range"}
                            onChange={(e) => this.handleCheckStatus(e)}
                            checked={diameter}
                          >
                            Size Range
                          </Switch>
                        </span>
                        <Form.Item
                          name="materialMaxDiameter"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Diameter!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialMaxDiameter}
                          style={{ marginBottom: "10px" }}
                        >
                          {/* <InputNumber
                              formatter={(value) =>
                                value !== "" ? `${value}"` : ""
                              }
                              style={{ width: "100%", height: "40px" }}
                              placeholder="Please enter your diameter"
                              className="form-input"
                            /> */}
                          <Input
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            className="form-input"
                            // formatter={(value) => `${value}"`}
                            placeholder="Please enter diameter"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 14 }}
                    md={{ span: 18 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ marginTop: "20px" }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="title_changes">Diameter</span>
                      <Switch
                        checkedChildren={"Diameter"}
                        unCheckedChildren={"Range"}
                        onChange={(e) => this.setState({ diameter: e })}
                      >
                        Size Range
                      </Switch>
                    </span>
                    <Form.Item
                      name="materialDiameter"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Diameter!",
                        },
                      ]}
                      initialValue={
                        recordMaterialData?.materialDiameter || "Unknown"
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      <Input
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        className="form-input"
                        // formatter={(value) => `${value}"`}
                        placeholder="Please enter diameter"
                      />
                      {/* <InputNumber
                          formatter={(value) =>
                            value !== "" ? `${value}"` : ""
                          }
                          style={{ width: "100%", height: "40px" }}
                          placeholder="Please enter your diameter"
                          className="form-input"
                        /> */}
                    </Form.Item>
                  </Col>
                )}

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 14 }}
                  md={{ span: 18 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "20px" }}
                >
                  <span className="title_changes">Supply</span>
                  <Form.Item
                    name="materialSupply"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter supply!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialSupply || "Unknown Supply"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Supply"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                        <Option key={i} value={m}>
                          {m}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="title_changes">Material Tax</span>
                  <Form.Item
                    name="materialTax"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Material Tax!",
                      },
                    ]}
                    initialValue={recordMaterialData?.materialTax || "Unknown"}
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      // style={{ height: "40px" }}
                      placeholder="Please Select A Material"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value="Unknown">Unknown</Option>
                      <Option value="Natural"> Natural</Option>
                      <Option value="Manufactured">Manufactured</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="title_changes">Material Description</span>
                  <Form.Item
                    name="materialNotes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter materialNotes!",
                      },
                    ]}
                    initialValue={
                      recordMaterialData?.materialNotes
                        ? recordMaterialData?.materialNotes
                        : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter materialNotes"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={24}
                >
                  <span className="title_changes">Material Picture</span>
                  <Form.Item
                    name="materialPicture"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach materialPicture",
                      },
                    ]}
                  >
                    <Spin spinning={imageLoading}>
                      <Dragger
                        onChange={(e) =>
                          this.uploadTicketPdf(e, "materialPicture")
                        }
                        customRequest={dummyRequest}
                        listType="picture"
                        multiple={true}
                        className="drag-ticket"
                        showUploadList={false}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </Dragger>

                      <div style={{ marginTop: "10px" }}>
                        {materialAttachment?.length > 0 &&
                          materialAttachment?.map((file) => (
                            <div style={{ marginBottom: "5px" }}>
                              <Card
                                key={file.uid}
                                bodyStyle={{ padding: "4px 10px 4px 4px" }}
                                width="100%"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {file.fileType === "image/jpeg" ||
                                  file.fileType === "image/png" ? (
                                    <img
                                      src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${file?.quoteAttachmentId}`}
                                      alt="Avatar"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        objectFit: "fill",
                                        marginRight: "10px",
                                      }}
                                    />
                                  ) : (
                                    <FilePdfOutlined
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "35px",
                                      }}
                                    />
                                  )}
                                  <span>{file?.fileName}</span>
                                  <Button
                                    icon={
                                      <DeleteOutlined
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      />
                                    }
                                    onClick={() =>
                                      this.handleFileDelete(
                                        file,
                                        "materialPicture"
                                      )
                                    }
                                    className="upload_Delete_Icon"
                                  />
                                </div>
                              </Card>
                            </div>
                          ))}
                      </div>
                    </Spin>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {contactPopup && (
          <BaseModal
            title={
              recordContactData !== "" ? "Update Contact" : "Add New Contact"
            }
            onCancel={() => {
              this.setState({
                contactPopup: false,
                isCheckcontact: false,
                newRole: "",
              });
            }}
            visible={contactPopup}
            destroyOnClose={true}
            formId="addContact"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addContact}
              initialValues={{ remember: true }}
              id="addContact"
            >
              <Row gutter={[24, 0]}>
                <>
                  <Col span={12}>
                    <span className="title_changes">First Name</span>
                    <Form.Item
                      name="fName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your First Name!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.firstName
                          ? recordContactData?.firstName
                          : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="title_changes">Last Name</span>
                    <Form.Item
                      name="lName"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your Last Name!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.lastName
                          ? recordContactData?.lastName
                          : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter your Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Phone</span>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Phone!",
                        },
                      ]}

                      // initialValue={
                      //   recordContactData?.phone ? recordContactData?.phone : ""
                      // }
                    >
                      <NumberFormat
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        className="ant-input-number numberictest"
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                        placeholder="Please Enter Your Phone"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Email</span>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Email!",
                        },
                        {
                          type: "email",
                          message: "The Input Is Not Valid Email",
                        },
                      ]}
                      initialValue={
                        recordContactData?.email ? recordContactData?.email : ""
                      }
                    >
                      <Input
                        className="form-input"
                        placeholder="Please Enter Your Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="title_changes">Role</span>
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Role!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.contactRole &&
                        recordContactData?.contactRole
                      }
                    >
                      {/* <Input
                        className="form-input"
                        placeholder="Please Enter Your Role"
                      /> */}
                      <Select
                        size="large"
                        showSearch
                        allowClear
                        className="select_Height"
                        // style={{ height: "40px" }}
                        placeholder="Please Select A Role"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                          this.setState({ newRole: value });
                          value === "Others" &&
                            message.info("Please Input Your Role");
                        }}
                      >
                        {quarryRoles?.map((role) => (
                          <Option value={role?.label}>{role?.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col span={12}>
                        <span className="title_changes">Area</span>
                        <Form.Item
                          name="address"
                          rules={[
                            {
                              required: false,
                              message: "Please enter your address",
                            },
                          ]}
                        >
                          <GooglePlacesAutocomplete
                            className="form-input"
                            // apiKey={process.env.REACT_APP_MAPS_KEY}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              defaultInputValue: `${
                                recordContactData?.address
                                  ? recordContactData?.address
                                  : ""
                              }`,
                              citySearch,
                              placeholder: "Search Address...",
                              onChange: (address) => {
                                this.handleSelect(address);
                                this.getAddress(address);
                              },
                            }}
                          />
                        </Form.Item>
                      </Col> */}

                  {newRole === "Add New Role" && (
                    <Col span={24}>
                      <Form.Item name="roleOther">
                        <Input
                          className="form-input"
                          placeholder="Please Enter Role"
                        />
                      </Form.Item>
                      <Checkbox
                        checked={this.state.isCheckcontact}
                        onChange={(e) =>
                          this.setState({
                            isCheckcontact: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  )}

                  <Col span={24}>
                    <span className="title_changes">Notes</span>
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your Notes!",
                        },
                      ]}
                      initialValue={
                        recordContactData?.notes ? recordContactData?.notes : ""
                      }
                    >
                      <TextArea
                        rows={4}
                        style={{
                          width: "100%",
                          height: "75px",
                          borderColor: "#d6d6d6",
                        }}
                        className="form-input"
                        placeholder="Please Enter Your Notes"
                      />
                    </Form.Item>
                  </Col>
                  {(contactInfo?.filter((e) => e?.isPrimaryContact === true)
                    ?.length === 0 ||
                    contactInfo?.length === 0 ||
                    recordContactData?.isPrimaryContact === true) && (
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      {/* <span className="title_changes">Add this contact as primary</span> */}
                      <Checkbox
                        checked={this.state.primaryContactCheck}
                        onChange={(e) =>
                          this.setState({
                            primaryContactCheck: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add This Contact As Primary
                        </span>
                      </Checkbox>
                    </Col>
                  )}
                </>
              </Row>
            </Form>
          </BaseModal>
        )}

        {addCategory && (
          <BaseModal
            title={"Add Material"}
            onCancel={() => {
              this.setState({
                addCategory: false,
              });
            }}
            visible={addCategory}
            destroyOnClose={true}
            formId="addCategory"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addCategory}
              initialValues={{ remember: true }}
              id="addCategory"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Material Name</span>
                  <Form.Item
                    name="materialsub2"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Material Name!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Material"
                      autoFocus
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
        {notesPopup && (
          <BaseModal
            title={recordNotesData !== "" ? "Update Notes" : "Add New Notes"}
            onCancel={() => {
              this.setState({
                notesPopup: false,
              });
            }}
            visible={notesPopup}
            destroyOnClose={true}
            formId="addNotes"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"25%"}
          >
            <Form
              onFinish={this.addNotes}
              initialValues={{ remember: true }}
              id="addNotes"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Type of Notes</span>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.typeofNotes
                        ? recordNotesData?.typeofNotes
                        : ""
                    }
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Type Of Notes"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.notes ? recordNotesData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalData: state?.reducer?.quarriesResult,
    quarriesInfo: state?.reducer?.quarriesInfo,
    loading: state?.reducer?.quarriesResult?.loading,
    dropDownOptions: state?.reducer?.dropDownOptions,
    dropDownOptionsInfo: state?.reducer?.dropDownOptionsInfo,
    materials: state?.reducer?.dropDownOptions?.totalData?.filter(
      (m) => m?.typeofDropDown === "material"
    ),
    quarryRoles: state?.reducer?.dropDownOptions?.totalData?.filter(
      (q) => q?.typeofDropDown === "quarryRoles"
    ),
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchQuarries: () => {
    dispatch(getQuarryLocations());
  },
  actionFetchAddQuarry: (data) => {
    dispatch(addQuarry(data));
  },
  actionFetchUpdateQuarry: (id, data) => {
    dispatch(updateQuarry(id, data));
  },
  actionFetchDeleteQuarry: (id) => {
    dispatch(deleteQuarry(id));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(QuarryTool));
