import PropTypes from "prop-types";
import { Modal, Button } from "antd";

const BaseModal = (props) => {
  // console.log(props.loading);
  return (
    <Modal
      visible
      destroyOnClose={props.destroyOnClose}
      footer={
        props.footer ? (
          <div style={props?.bottomStyle ? { position: 'relative', top:'-491px', zIndex:'1000', margin:'0px 20px' }: {}}>
            {props?.rejectButton && (
              <Button
                onClick={props.onReject}
                style={{
                  height: props.btnHeight,
                  width: props.btnWidth,
                  borderRadius: "5px",
                  marginRight: "25px",
                  cursor: "pointer",
                  color: "red",
                  fontSize: "30px",
                  alignItems: "center",
                  background: "#ff00000d",
                  justifyContent: "center",
                  display: "flex",
                  border: " 1px solid red",
                  float: "left",
                  fontWeight: "bold",
                }}
              >
                {props.rejectBtn}
              </Button>
            )}
            <Button
              onClick={props.onCancel}
              style={{
                height: props.btnHeight,
                width: props.btnWidth,
                borderRadius: "5px",
                marginRight: "25px",
                cursor: "pointer",
              }}
              htmlType="reset"
            >
              {props.cancelButton}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                height: props.btnHeight,
                width: props.btnWidth,
                borderRadius: "5px",
                cursor: "pointer",
              }}
              loading={props.loading}
              form={props.formId}
              disabled={props.disabled}
            >
              {props.submitButton}
            </Button>
          </div>
        ) : (
          ""
        )
      }
      onCancel={props.onCancel}
      width={
        window.screen.width >= "768"
          ? props.width
          : window.screen.width > "501" && window.screen.width <= "767"
          ? "50%"
          : "100%"
      }
      // props.width}
      maskClosable={false}
      title={props.title}
      className={props.className}
    >
      {props.children}
    </Modal>
  );
};

BaseModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  destroyOnClose: PropTypes.bool,
  width: PropTypes.string,
  btnWidth: PropTypes.string,
  btnHeight: PropTypes.string,
  disabled: PropTypes.bool,
  // loading: PropTypes.func.isRequired,
};

BaseModal.defaultProps = {
  destroyOnClose: true,
  width: "40%",
  submitButton: "Submit",
  cancelButton: "Cancel",
  rejectBtn: "Reject & Reupload",
  footer: true,
  loading: false,
  btnWidth: window.screen.width > 1023 ? "auto" : "maxContent",
  btnHeight: "35px",
  disabled: false,
  rejectButton: false,
};

export default BaseModal;
