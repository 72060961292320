const asyncActionType = (type) => ({
  pending: `${type}/PENDING`,
  success: `${type}/SUCCESS`,
  error: `${type}/ERROR`,
});

export const FETCH_COMPANIES = asyncActionType("COMPANIES");
export const FETCH_COMPANY_DELETE = asyncActionType("COMPANIES/DELETE");
export const FETCH_COMPANY_UPDATE = asyncActionType("COMPANIES/UPDATE");
export const FETCH_COMPANY_ADD = asyncActionType("ADDCOMPANY");
export const FETCH_OWNEROPERATORS = asyncActionType("OWNEROPERATORS");
export const FETCH_LEADS = asyncActionType("LEADS");
export const FETCH_LEAD = asyncActionType("LEAD");
export const FETCH_LEADS_ADD = asyncActionType("LEADS/ADD");
export const FETCH_LEADS_UPDATE = asyncActionType("LEADS/UPDATE");
export const FETCH_LEADS_DELETE = asyncActionType("LEADS/DELETE");
export const FETCH_CONTACTS = asyncActionType("CONTACTS");
export const FETCH_CONTACTS_DELETE = asyncActionType("CONTACTS/DELETE");
export const FETCH_JOBCONTACT_DELETE = asyncActionType("JOBCONTACT/DELETE");
export const FETCH_CONTACTS_UPDATE = asyncActionType("CONTACTS/UPDATE");
export const FETCH_CONTACTS_ADD = asyncActionType("CONTACTS/ADD");
export const FETCH_CONTACT_ADD = asyncActionType("CONTACT/ADD");
export const FETCH_DROPDOWNOPTIONS = asyncActionType("DROPDOWNOPTIONS");
export const FETCH_DROPDOWNOPTIONS_UPDATE = asyncActionType("DROPDOWNOPTIONS/UPDATE");
export const FETCH_COMPANY = asyncActionType("COMPANY");
export const FETCH_DOCUMENTS = asyncActionType("DOCUMENTS");
export const FETCH_DOCUMENTS_ADD = asyncActionType("DOCUMENTS/ADD");
export const FETCH_DOCUMENTS_UPDATE = asyncActionType("DOCUMENTS/UPDATE");
export const FETCH_DOCUMENTS_DELETE = asyncActionType("DOCUMENTS/DELETE");
export const FETCH_TRUCKSLIST = asyncActionType("TRUCKSLIST");
export const FETCH_TRUCKLIST = asyncActionType("TRUCKLIST");
export const FETCH_TRUCKSLIST_ADD = asyncActionType("TRUCKSLIST/ADD");
export const FETCH_TRUCKLIST_UPDATE = asyncActionType("TRUCKLIST/UPDATE");
export const FETCH_QUARRIESLIST = asyncActionType("QUARRIESLIST");
export const FETCH_QUARRY = asyncActionType("QUARRY");
export const FETCH_QUARRIESLIST_ADD = asyncActionType("QUARRIESLIST/ADD");
export const FETCH_QUARRIESLIST_UPDATE = asyncActionType("QUARRIESLIST/UPDATE");
export const FETCH_QUARRIESLIST_DELETE = asyncActionType("QUARRIESLIST/DELETE");
export const FETCH_TRUCKSLIST_QUERY = asyncActionType("TRUCKSLIST/QUERY");
export const FETCH_NOTES = asyncActionType("NOTES");
export const FETCH_NOTES_ADD = asyncActionType("NOTES/ADD");
export const FETCH_NOTES_UPDATE = asyncActionType("NOTES/UPDATE");
export const FETCH_NOTES_DELETE = asyncActionType("NOTES/DELETE");
export const FETCH_TOPVENDORS = asyncActionType("VENDORS");
export const FETCH_USERS = asyncActionType("USERS");
export const FETCH_USER_ADD = asyncActionType("USERS/ADD");
export const FETCH_USER_UPDATE = asyncActionType("USERS/UPDATE");
export const FETCH_USER_DELETE = asyncActionType("USERS/DELETE");
export const FETCH_ROLES = asyncActionType("ROLES");
export const FETCH_ROLE_ADD = asyncActionType("ROLE/ADD");
export const FETCH_ROLE_UPDATE = asyncActionType("ROLE/UPDATE");
export const FETCH_ROLE_DELETE = asyncActionType("ROLE/DELETE");
export const FETCH_DISPATCH = asyncActionType("DISPATCH");
export const FETCH_TICKETPROCESS = asyncActionType("TICKETPROCESS");
export const FETCH_TICKETPROCESS_ADD = asyncActionType("TICKETPROCESS_ADD");
export const FETCH_TICKETPROCESS_DELETE = asyncActionType("TICKETPROCESS_DELETE");
export const FETCH_INVOICES = asyncActionType("INVOICES");
export const FETCH_INVOICE = asyncActionType("INVOICE");
export const FETCH_INVOICE_GENERATE = asyncActionType("INVOICE/GENERATE");
export const FETCH_DASHBOARD = asyncActionType("DASHBOARD");
export const FETCH_SALESPERSON = asyncActionType("SALESPERSON_GET");
export const FETCH_RECURRING = asyncActionType("RECURRING_GET");
export const UPDATE_QUARRYSTATUS = asyncActionType("QUARRYSTATUS");
export const GET_DISPATCH_SUMMARY = asyncActionType("GET_DISPATCH_SUMMARY");
export const DELETE_TRUCKSLIST = asyncActionType("DELETE_TRUCKSLIST");
export const GET_DELETE_DATA = asyncActionType("GET_DELETE_DATA");
export const DELETE_RESTORE_DATA = asyncActionType("DELETE_RESTORE_DATA");
export const FETCH_ORDERS = asyncActionType("FETCH_ORDERS");
export const FETCH_ORDERS_UPDATE = asyncActionType("FETCH_ORDERS_UPDATE");
export const UPDATE_INVOICES = asyncActionType("UPDATE_INVOICES");
export const DELETE_ORDERS_DATA = asyncActionType("DELETE_ORDERS");
export const UPDATE_ESTIMATE = asyncActionType("UPDATE_ESTIMATE");
export const FETCH_SALES_SUMMARY = asyncActionType("FETCH_SALES_SUMMARY");
export const UPDATE_MULTIPLE_INVOICES = asyncActionType("UPDATE_MULTIPLE_INVOICES");
export const GET_ACCOUNTS_PAYABLE = asyncActionType("GET_ACCOUNTS_PAYABLE");
export const FETCH_SINGLE_TICKETPROCESS = asyncActionType("FETCH_SINGLE_TICKETPROCESS");
export const FETCH_DISPATCH_EMAIL = asyncActionType("FETCH_DISPATCH_EMAIL");
export const UPDATE_ARCHIVE_COMPANY = asyncActionType("UPDATE_ARCHIVE_COMPANY");
