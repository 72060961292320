import React, { Component } from "react";
import {
  Input,
  Row,
  Col,
  Card,
  Button,
  Tag,
  Form,
  Popover,
  Upload,
  Popconfirm,
  notification,
  message,
  Progress,
  InputNumber,
  Space,
  Switch,
  DatePicker,
  Select,
} from "antd";
import {
  EllipsisOutlined,
  CheckCircleFilled,
  InboxOutlined,
} from "@ant-design/icons";
import Loading from "../../../Common/Loading";
import { Storage } from "aws-amplify";
import moment from "moment";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import { deleteRecord, update } from "../../../api/Apis";
import { withAppContext } from "../../../Store/AppContext";
import { withRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { BsArrowLeftShort } from "react-icons/bs";
import { connect } from "react-redux";
import { getTickets } from "../../../Redux/Actions/actions";
import { PDFViewer } from "react-view-pdf";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
const { Dragger } = Upload;
const { TextArea } = Input;
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class RejectUpload extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: "",
      boardView: true,
      listView: false,
      loading: false,
      selectionType: "",
      selectedRowKeys: [],
      selected: true,
      color: "active",
      isModalVisible: false,
      isModalVisible1: false,
      isReviewModalVisible: false,
      ticketDate: "",
      totalTicketsData: [],
      startTime: "",
      dropoffAddress: "",
      pickupAddress: "",
      reviewData: "",
      leadsData: "",
      ticketUploaded: "",
      fileList: [],
      ticketLoading: false,
      newTicket: true,
      assignedJobs: [],
      setCounter: [],
      singlePdfTicket: "",
      filterData: [],
      quantityType: "",
      isEdit: false,
      showInvoiceModal: false,
      invoiceDate: "",
      selectedTickets: [],
      ticketStatus: "",
      minValue: 0,
      maxValue: 5,
      currentPage: 1,
      perpageSize: 5,
      showApproved: true,
      showProcesssed: true,
      reviewNotes: "",
      selectedTicketsInfo: [],
      percentage: 0,
      pickUpTime: "",
      showRejectModel: false,
      pickupTimeErr: false,
      signCustomer: false,
      ticketIndex: 0,
      selectedMaterialId: "",
      selectedCarrierId: "",
      jobInfo: "",
      totalTrucksData: [],
      showNotes: false,
      rejectFinishData: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props?.jobInfo) !== JSON.stringify(state?.jobInfo)) {
      return {
        jobInfo: props.jobInfo,
      };
    }
    if (
      JSON.stringify(props?.totalTrucksData) !==
      JSON.stringify(state?.totalTrucksData)
    ) {
      return {
        totalTrucksData: props.totalTrucksData,
      };
    }
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Ticket Processing";
    this.onSelectJob();
    AOS.init({
      duration: 2000,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      const rejectTicketsData = this.props?.totalResult?.ticketsData?.filter(
        (e) => e.uploadStatus === "Open"
      );
      this.setState({
        jobInfo: rejectTicketsData,
      });
    }
  }

  onSelectJob = () => {
    const { jobInfo } = this.state;
    let leadsData = {};
    let jobDetails = Object.values(jobInfo)[0];
    leadsData["salesPersonId"] = jobDetails["salesPersonId"];
    leadsData["salesCommission"] = jobDetails["salesCommission"];
    leadsData["jobId"] = jobDetails["jobId"];
    leadsData["jobNumber"] = jobDetails["jobNumber"];
    leadsData["jobName"] = jobDetails["jobName"];
    leadsData["dropoffAddress"] = jobDetails["dropoffAddress"];
    leadsData["material"] = jobDetails["material"];
    leadsData["pickupAddress"] = jobDetails["pickup"];
    leadsData["jobType"] = jobDetails["jobType"];
    leadsData["companyId"] = jobDetails["companyId"];
    leadsData["companyName"] = jobDetails["companyName"];
    leadsData["companyAddress"] = jobDetails["companyAddress"];
    leadsData["companyPhone"] = jobDetails["companyPhone"];
    leadsData["taxExempt"] = jobDetails["taxExempt"];
    leadsData["ticketNumber"] = "";
    leadsData["ticketDate"] = "";
    leadsData["assignTrucks"] = jobDetails["material"]["assignTrucks"];
    leadsData["dispatchNotes"] = jobDetails["dispatchNotes"];
    leadsData["jobNotes"] = jobDetails["notes"];
    leadsData["quantityType"] = jobDetails["trucksRequestedType"];
    // leadsData["orderNumber"] = jobDetails["orderNumber"];
    // leadsData["orderId"] = jobDetails["id"];
    leadsData["approvedDate"] = jobDetails["approvedDate"];
    leadsData["invoiceNotes"] = jobDetails["invoiceNotes"];

    this.setState({
      leadsData,
    });
  };

  nextReview = (record, id, incrementIndex) => {
    record.map((item, index) => {
      if (item.id === id) {
        this.nextData(record, id, incrementIndex ? index : index + 1, item);
      }
      return true;
    });
  };
  // message.error("There are no more tickets to review!");

  nextData = (record, id, currentIndexValue, keyValue) => {
    record?.map((item, index) => {
      if (index === currentIndexValue) {
        this.setState({
          leadsData: item,
          ticketUploaded: item.ticketUploadPicture,
          singlePdfTicket: item.singlePdfTicket,
          ticketIndex: index + 1,
          selectedMaterialId: item?.material[0]?.id,
          selectedCarrierId: item?.selectedCarrierId || "",
          pickupTimeErr: false,
          signCustomer: false,
        });
        this.formRef.current.setFieldsValue({
          uploadStatus: item?.uploadStatus ? item?.uploadStatus : "",
          ticketNumber: item?.ticketNumber ? item?.ticketNumber : "",
          truckNumber: item?.truckNumber ? item?.truckNumber : "",
          quantity: item?.quantity ? item?.quantity : "",
          pickupTime: item?.pickupTime ? item["pickupTime"] : "",
          pickupVal: item?.pickupVal ? item["pickupVal"] : "",
          ticketDate: item?.ticketDate ? moment(item?.ticketDate) : "",
          ticketVerification: item?.ticketVerification
            ? item?.ticketVerification
            : "",
          ticketPrice: item?.ticketPrice ? item?.ticketPrice : "",
          material: "",
          reviewNotes: item.reviewNotes ? item.reviewNotes : "",
        });
      }
      return true;
    });
    if (record?.length === currentIndexValue) {
      this.setState({
        isModalVisible: false,
        loading: false,
      });
      message.info("There are no more tickets to review!");
      this.props.actionFetchGetTickets();
      this.props.handleCancel();
      return true;
    }
  };

  uploadModal = () => {
    const { leadsData } = this.state;
    leadsData["pickupVal"] = "";
    leadsData["uploadStatus"] = "";
    this.setState({
      isModalVisible: true,
      leadsData,
      isEdit: false,
      percentage: 0,
      ticketUploaded: "",
      singlePdfTicket: "",
      newTicket: true,
      reviewNotes: "",
    });
  };

  showModal = (record) => {
    const { jobInfo } = this.state;
    jobInfo?.forEach((element, index) => {
      if (element.id === record.id) {
        this.setState({
          loading: false,
          isModalVisible: true,
          newTicket: false,
          ticketUploaded: element.ticketUploadPicture,
          singlePdfTicket: element.singlePdfTicket,
          quantityType: element.quantityType,
          leadsData: element,
          isEdit: true,
          ticketIndex: index + 1,
          selectedMaterialId: record?.material[0]?.id,
          selectedCarrierId: element?.selectedCarrierId || "",
        });
      }
    });
  };

  handleUploadType = (file) => {
    let isPDF = file.type === "application/pdf";
    if (!isPDF) {
      notification["error"]({
        message: `${file.name} is not a PDf file`,
      });
      this.setState({
        ticketUploaded: "",
        singlePdfTicket: "",
        ticketLoading: false,
      });
      return Upload.LIST_IGNORE;
    }
  };

  uploadTicketPdf = async (info) => {
    const { leadsData } = this.state;
    this.setState({
      ticketLoading: true,
    });

    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              let ticketUploadPicture = info["file"]["uid"] + "_TNBS_ticket";
              Storage.put(ticketUploadPicture, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                if (leadsData?.uploadStatus === "Open") {
                  let singlePdfTicket = leadsData.singlePdfTicket.replace(
                    leadsData.ticketUploadPicture,
                    result.key
                  );
                  Storage.put(
                    singlePdfTicket.replace("public/", ""),
                    info.file.originFileObj,
                    {
                      contentType: info.file.type,
                    }
                  ).then((result) => {
                    this.setState(
                      {
                        singlePdfTicket: singlePdfTicket,
                      },
                      this.settime()
                    );
                  });
                }
                this.setState(
                  {
                    ticketUploaded: result.key,
                    ticketLoading: false,
                    percentage: info?.file?.percent,
                  },
                  this.settime()
                );
              });
            } else {
              this.setState({
                ticketLoading: false,
                percentage: 0,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;

      case "removed":
        this.setState({
          ticketUploaded: "",
          ticketLoading: false,
          percentage: 0,
        });
        break;
      default:
        this.setState(
          {
            ticketLoading: false,
            percentage: 0,
          },
          this.settime()
        );
    }
  };

  fileDownloadQuote = async (fileName) => {
    Storage.get(fileName, { download: true })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    this.downloadBlobQuote(fileName);
  };

  downloadBlobQuote(fileName) {
    const url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/${fileName}`;
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = fileName || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loading: false,
    });
    return a;
  }

  handleToDeleteTicket = (record) => {
    this.setState({ loading: true });
    deleteRecord("ticketProcess", record.id)
      .then((deleteRes) => {
        if (deleteRes.success) {
          this.props.actionFetchGetTickets();
          // this.getTicketInfo();
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log("catchEror", error);
        this.setState({ loading: false });
      });
  };

  settime = () => {
    setTimeout(() => {
      this.setState({ percentage: this.state.percentage + 40 });
    }, 1000);
  };

  searchResult = (searchValue) => {
    const { totalTicketsData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = totalTicketsData.filter(
        (e) => parseInt(e["jobNumber"]) === searchValue
      );
    } else {
      searchFilter = totalTicketsData.filter(
        (e) => e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      filterData: searchFilter,
    });
  };

  handlePagination = async (page, pageSize, load) => {
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
  };

  onResetData = () => {
    this.getTicketInfo();
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  openNotification = () => {
    const { updatedTruckNumber } = this.state;
    const btn = (
      <div>
        {/* <Button
          style={{
            borderRadius: "5px",
            height: "30px",
            marginRight: "10px",
          }}
          size="small"
          onClick={() => {
            notification.destroy();
          }}
        >
          Manager Review
        </Button> */}
        <Button
          style={{
            borderRadius: "5px",
            height: "30px",
          }}
          size="small"
          onClick={() => {
            notification.destroy();
          }}
        >
          Cancel
        </Button>
      </div>
    );
    notification.warning({
      message: `TNB${updatedTruckNumber} is not in the owner operator database. Please verify you have input the correct truck number.`,
      btn,
      placement: "topRight",
    });
  };

  truckNumberValidation = () => {
    const { leadsData, updatedTruckNumber, totalTrucksData } = this.state;

    const findTruckNumber = totalTrucksData?.filter(
      (element) =>
        element.truckId === updatedTruckNumber ||
        (element.secondTruckNo?.length > 0 &&
          element.secondTruckNo.includes(updatedTruckNumber))
    );

    let updatedData = { ...leadsData };

    if (findTruckNumber?.length > 1) {
      this.setState({
        showMultpleTrucks: findTruckNumber,
        showTruckModal: true,
      });
    } else if (updatedTruckNumber && findTruckNumber?.length === 1) {
      const existingTruck = updatedData.material[0].assignTrucksInfo.filter(
        (e) => e.id === findTruckNumber[0]?.id
      );
      if (
        updatedData.material[0].assignTrucksInfo?.length > 0 &&
        existingTruck?.length === 0
      ) {
        updatedData.material[0].assignTrucksInfo.push(findTruckNumber[0]);
      }
      this.setState({
        selectedMaterialId: updatedData.material[0]?.id,
        selectedCarrierId: findTruckNumber[0]?.carrierId,
        showTruckModal: false,
        leadsData: updatedData,
      });
    } else {
      this.openNotification();
      return true;
    }
  };

  handleTruckFinsih = () => {
    const { showMultpleTrucks, leadsData, selectedTruck } = this.state;
    let updatedData = { ...leadsData };
    const selectedItem = showMultpleTrucks.find((e) => e.id === selectedTruck);
    const existingTruck = updatedData.material[0].assignTrucksInfo.filter(
      (e) => e?.id === selectedItem?.id
    );
    if (
      updatedData.material[0].assignTrucksInfo?.length > 0 &&
      existingTruck?.length === 0
    ) {
      updatedData.material[0].assignTrucksInfo.push(selectedItem);
    }

    this.setState({
      selectedCarrierId: selectedItem?.carrierId,
      showTruckModal: false,
      leadsData: updatedData,
      selectedMaterialId: updatedData?.material[0]?.id,
    });
  };

  onFinish = (values) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const {
      ticketUploaded,
      leadsData,
      pickUpTime,
      quantityType,
      signCustomer,
      pickupTimeErr,
      singlePdfTicket,
      selectedMaterialId,
      selectedCarrierId,
    } = this.state;
    let pickUpTimeStamp = leadsData?.pickUpTimeStamp || "";
    if (leadsData?.ticketVerification === "Open") {
      let timeVal = leadsData?.pickupTime || pickUpTime;
      const [month, day, year] =
        moment(values["ticketDate"]).format("L")?.split("/") || [];
      const [hour, minute] = timeVal.split(":");
      const isPM = timeVal.toLowerCase().includes("pm");
      pickUpTimeStamp = new Date(
        year,
        month - 1,
        day,
        isPM ? parseInt(hour) + 12 : parseInt(hour),
        parseInt(minute)
      ).toISOString();
    }
    let data = {
      entityData: {},
    };
    if (!signCustomer) {
      message.error("Ticket is not signed by Customer!");
      return true;
    }
    if (pickupTimeErr) {
      message.error("Please enter valid time");
      return true;
    }
    if (selectedMaterialId !== "" && selectedCarrierId !== "") {
      this.setState({
        loading: true,
      });
      if (
        leadsData.selectedMaterial?.length > 0 &&
        JSON.stringify(leadsData.selectedMaterial[0].assignTrucksInfo) !==
          JSON.stringify(leadsData?.material[0]["assignTrucksInfo"])
      ) {
        leadsData.selectedMaterial[0].assignTrucksInfo =
          leadsData?.material[0]["assignTrucksInfo"];
      }
      try {
        values["managerReview"] = true;
        values["uploadStatus"] = "Closed";
        values["ticketDate"] = values["ticketDate"]
          ? moment(values["ticketDate"]).format("L")
          : "";
        values["quantityType"] = quantityType || leadsData.quantityType;
        values["pickupTime"] = leadsData?.pickupTime || pickUpTime;
        values["pickupVal"] = values["pickupVal"] || leadsData?.pickupVal;
        values["pickUpTimeStamp"] = pickUpTimeStamp;
        values["material"] = leadsData["material"];
        values["selectedMaterial"] = leadsData?.material?.filter(
          (e) => e.id === selectedMaterialId
        );
        values["selectedCarrierId"] = selectedCarrierId;
        values["totalPrice"] =
          values["selectedMaterial"]?.length > 0
            ? values["selectedMaterial"][0]["landedPrice"] !== "N/A"
              ? values["selectedMaterial"][0]["landedPrice"]
              : leadsData?.material[0]?.totalPrice +
                leadsData?.material[0]?.truckingPrice
            : 0;
        values["ticketVerification"] = "Approved";
        values["updatedBy"] = userDetails?.id;
        values["singlePdfTicket"] = singlePdfTicket;
        values["ticketUploadPicture"] = ticketUploaded;
        data["entityData"] = values;
        this.setState({
          rejectFinishData: data,
          showNotes: true,
        });
      } catch (error) {
        console.log("catchError", error);
      }
    } else {
      this.truckNumberValidation();
      return true;
    }
  };

  onFinishNotes = (values, jobData) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { leadsData, rejectFinishData } = this.state;
    try {
      this.setState({
        loading: true,
      });
      rejectFinishData["entityData"]["managerInfo"] = {
        name: userDetails?.firstName + " " + userDetails?.lastName,
        date: moment().format("L"),
        time: moment().format("LT"),
        notes: values["managerNotes"],
      };
      let data = rejectFinishData;
      update("ticketProcess", leadsData.id, data).then((updateRes) => {
        let result = updateRes.data.data;
        jobData?.map((item) => {
          if (item.id === result.id) {
            item["uploadStatus"] = result["uploadStatus"];
          }
          return true;
        });
        if (updateRes.success) {
          message.success("Ticket status updated sucessfully");
          this.setState({
            loading: false,
            reviewNotes: "",
            ticketStatus: "",
            pickUpTime: "",
            percentage: 0,
            rejectFinishData: {},
            showNotes: false,
            signCustomer: false,
          });
          if (jobData?.length > 0) {
            this.nextReview(jobData, leadsData.id, false);
          }
        }
      });
    } catch (error) {
      console.log("catchError", error);
    }
  };

  handleTimeChange = (e) => {
    let checkData = e?.target.value;
    let value = "";

    if (
      parseInt(checkData.slice(0, 2)) >= 10 &&
      parseInt(checkData.slice(0, 2)) <= 12
    ) {
      value = checkData;
    } else if (parseInt(checkData.slice(0, 2)) >= 13) {
      value = 0 + checkData;
    } else {
      value = checkData;
    }
    let hours = parseInt(value.slice(0, 2)); // extract hours from the input
    let minutes = parseInt(value.slice(2, 4)); // extract minutes from the input
    const type = value.slice(4);
    if (parseInt(value.length) > 5 || minutes >= 60) {
      this.setState({
        pickupTimeErr: true,
      });
    } else if (parseInt(value.length) === 3 || parseInt(value.length) >= 5) {
      if (parseInt(value.slice(2, 4)) <= 9) {
        minutes = "0" + minutes;
      }
      // extract minutes from the input
      let formattedTime = "";
      formattedTime =
        type === "a"
          ? hours + ":" + minutes + " AM"
          : hours + ":" + minutes + " PM";
      this.setState({ pickUpTime: formattedTime, pickupTimeErr: false });
    } else {
      this.setState({ pickUpTime: "", pickupTimeErr: false });
    }
  };

  render() {
    const {
      selectedRowKeys,
      showTruckModal,
      showMultpleTrucks,
      quantityType,
      ticketIndex,
      loading,
      leadsData,
      isEdit,
      isModalVisible,
      ticketUploaded,
      newTicket,
      singlePdfTicket,
      ticketLoading,
      percentage,
      pickUpTime,
      showNotes,
      signCustomer,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: (e) => this.setState({ selectedTruck: e[0] }),
    };

    const equipmentColumns = [
      {
        title: "Primary Truck#",
        dataIndex: "truckId",
        key: "truckId",
        sorter: false,
        className: "col_style_quarry",
        fixed: "left",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (truckId) => {
          return <span className="col_style_quarry">{truckId}</span>;
        },
      },
      {
        title: "Secondary Truck#",
        dataIndex: "secondTruckNo",
        key: "secondTruckNo",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (secondTruckNo, record) => {
          return (
            <div className="col_style_quarry">
              {record?.secondTruckNo?.length > 0
                ? record?.secondTruckNo.join(", ")
                : secondTruckNo}
            </div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "truck_type",
        key: "truck_type",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (truck_type, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              <span className="col_style_quarry">{truck_type}</span>
            </Row>
          );
        },
      },
      {
        title: "Location",
        dataIndex: "parking_location",
        key: "parking_location",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (parking_location) => {
          return <span className="col_style_quarry">{parking_location}</span>;
        },
      },
      {
        title: "Dispatcher",
        dataIndex: "dispatcher",
        key: "dispatcher",
        sorter: false,
        className: "col_style_quarry",
        onCell: (record) => ({
          onClick: () =>
            this.setState({
              selectedTruck: record.id,
              selectedRowKeys: [record.id],
            }),
        }),
        render: (dispatcher) => {
          return <span className="col_style_quarry">{dispatcher}</span>;
        },
      },
    ];

    const Controls = () => {
      const { resetTransform } = useControls();

      return (
        <div className="tools">
          {/* <span class="custom-button" onClick={() => zoomIn()}>
            Zoom In
          </span>
          <span class="custom-button1" onClick={() => zoomOut()}>
            Zoom Out
          </span> */}
          <span class="custom-button2" onClick={() => resetTransform()}>
            Reset
          </span>
        </div>
      );
    };

    const selectAfter = (
      <Select
        defaultValue={quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Select.Option value="Tons">Tons</Select.Option>
        <Select.Option value="Loads">Loads</Select.Option>
        <Select.Option value="Hours">Hours</Select.Option>
      </Select>
    );
    const { jobInfo } = this.state;

    const columns = [
      {
        title: "UPLOAD DATE",
        dataIndex: "uploadDate",
        key: "uploadDate",
        sorter: false,
        showTable: true,
        className: "col_styling",
        render: (uploadDate) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row style={{ color: "#383838" }}>
                {moment(uploadDate).format("l")}
              </Row>
            </span>
          );
        },
      },
      {
        title: "JOB #",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        showTable: true,
        className: "col_styling",
        // width: "8%",
        render: (jobNumber) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row style={{ color: "#383838" }}>{jobNumber}</Row>
            </span>
          );
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "jobName",
        key: "jobName",
        sorter: false,
        showTable: true,
        className: "col_styling",
        // width: "8%",
        render: (jobName) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row style={{ color: "#383838" }}>{jobName}</Row>
            </span>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        showTable: true,
        className: "col_styling",
        // width: "8%",
        render: (companyName) => {
          return (
            <span
              className="col_styling table-font-mobile-accounts"
              style={{
                textTransform: "capitalize",
                width: "10px",
              }}
            >
              <Row style={{ color: "#383838" }}>{companyName}</Row>
            </span>
          );
        },
      },
      {
        title: "VERIFICATION",
        dataIndex: "uploadStatus",
        key: "uploadStatus",
        sorter: false,
        className: "col_styling",
        showTable: true,
        render: (tag2, record) => {
          return (
            <Tag
              style={{
                background: "transparent",
                border: "none",
                height: "40px",
                fontSize: "16px",
                borderRadius: "5px",
                padding: "0px",
                color:
                  tag2 === "Open"
                    ? "#0058e0"
                    : tag2 === "Pending"
                    ? "#d16a00"
                    : tag2 === "Approved"
                    ? "#00b02e"
                    : tag2 === "Invoiced" && "#de0000",
              }}
              className="col_styling table-font-mobile-accounts"
              key={tag2}
            >
              {tag2 === "Review" ? (
                <Button
                  type="button"
                  onClick={() => this.showModal(record)}
                  style={{
                    height: "40px",
                    backgroundColor: "#586370",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    width: "fit-content",
                    color: "#FFF",
                    fontWeight: "600",
                  }}
                  className="ticket-uplaod-tags-button"
                >
                  <span
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "15px",
                    }}
                  >
                    {"Quailty Check"}
                  </span>
                </Button>
              ) : tag2 === "Closed" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: "40px",
                    fontWeight: "600",
                  }}
                  onClick={() => this.showModal(record)}
                >
                  <CheckCircleFilled style={{ marginRight: "5px" }} />
                  <span>{tag2}</span>
                </div>
              ) : (
                tag2 === "Open" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    <Button
                      type="button"
                      onClick={() => this.showModal(record)}
                      style={{
                        height: "40px",
                        backgroundColor: "#f78d8d",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        width: "140px",
                        color: "#ffffff",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "16px",
                        }}
                      >
                        {"Review"}
                      </span>
                    </Button>
                  </div>
                )
              )}
            </Tag>
          );
        },
      },
      {
        title: "ACTION",
        dataIndex: "id",
        key: "",
        sorter: false,
        // width: "75px",
        fixed: "right",
        showTable: true,
        render: (action, record) => {
          return (
            <>
              <Col>
                <Popover
                  placement="bottom"
                  content={
                    <>
                      <Row className="popovergrid">
                        <Col span={24}>
                          <Button
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              border: "none",
                              padding: "0px",
                              textAlign: "left",
                              width: "100%",
                              height: "auto",
                            }}
                            onClick={() => this.showModal(record)}
                          >
                            <span
                              style={{
                                color: "black",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <MdModeEditOutline
                                style={{ marginRight: "5px" }}
                              />
                              Edit
                            </span>
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Button
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              border: "none",
                              padding: "0px",
                              textAlign: "left",
                              width: "100%",
                              height: "auto",
                            }}
                          >
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() =>
                                this.handleToDeleteTicket(record)
                              }
                            >
                              <span
                                style={{
                                  color: "black",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "left",
                                }}
                              >
                                <MdDelete style={{ marginRight: "5px" }} />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                >
                  <EllipsisOutlined
                    style={{ fontSize: "35px", color: "grey" }}
                    className="action-ellipsis-button"
                  />
                </Popover>
              </Col>
            </>
          );
        },
      },
    ].filter((e) => e.showTable === true);

    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading} />

        <Row
          style={{
            marginBottom: "1vw",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Row
            onClick={() => {
              this.props.actionFetchGetTickets();
              this.props.handleCancel();
            }}
            style={{ cursor: "pointer" }}
          >
            <BsArrowLeftShort
              style={{ color: "#5f6c74", fontSize: "25px" }}
              className="back-button-return-icon"
            />
            <p
              style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </Row>

        <Row>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card bodyStyle={{ padding: "0px" }} data-aos="fade-up">
              {jobInfo?.length > 0 ? (
                <BaseTable
                  className="financeticket_table table_laptopscreen"
                  columnsData={columns}
                  source={jobInfo?.filter((e) => e.uploadStatus === "Open")}
                  total={jobInfo?.length}
                  rowKey={(record) => record.id}
                  handleTableChage={(e) =>
                    this.handleTableChage(e, "ticketUpload")
                  }
                  sizeRange={
                    window.screen.width > 1023
                      ? JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.ticketUpload
                      : jobInfo?.length
                  }
                  pagination={window.screen.width > 1023 ? true : false}
                />
              ) : (
                <Card
                  style={{ padding: "150px", textAlign: "center" }}
                  className="no-items-card"
                >
                  <span style={{ fontSize: "20px" }} className="no-items-card">
                    No Tickets Found!
                  </span>
                </Card>
              )}
            </Card>
          </Col>
          {isModalVisible && (
            <BaseModal
              className="tickectfinan_modal antmodal_grid headermodal"
              title={
                !newTicket && ticketUploaded
                  ? leadsData?.uploadStatus === "Closed"
                    ? `View Details ${ticketIndex} / ${jobInfo?.length}`
                    : `Edit Details ${ticketIndex} / ${jobInfo?.length}`
                  : "Upload Ticket"
              }
              onCancel={() => {
                this.setState({
                  isModalVisible: false,
                  newTicket: true,
                  ticketUploaded: "",
                  percentage: 0,
                  ticketStatus: "",
                });
                this.props.actionFetchGetTickets();
              }}
              formId="ticketForm"
              loading={loading}
              submitButton={
                isEdit
                  ? leadsData?.uploadStatus === "Review"
                    ? "Submit for data input"
                    : leadsData?.uploadStatus === "Open"
                    ? "Submit for review"
                    : "Next"
                  : "Upload"
              }
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "900px"
              }
              btnHeight="40px"
              disabled={ticketLoading ? true : false}
            >
              <Form id="ticketForm" onFinish={this.onFinish} ref={this.formRef}>
                {!newTicket && ticketUploaded !== "" && (
                  <Col
                    xxl={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    className="pdfviewcls"
                  >
                    <TransformWrapper
                      initialScale={1}
                      initialPositionX={1}
                      initialPositionY={1}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                          <Controls />
                          <TransformComponent>
                            <PDFViewer
                              url={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${singlePdfTicket}?#view=FitH`}
                              viewer="url"
                              style={{
                                width: "100%",
                                height: "400px",
                                overflow: "auto",
                              }}
                              onError={(error) => {
                                console.error("Error loading PDF:", error);
                              }}
                            />
                          </TransformComponent>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                  </Col>
                )}
                <div className="bordered-class">
                  <>
                    <Col
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <b> Signed By Customer</b>
                        <Space direction="vertical">
                          <Switch
                            checked={signCustomer}
                            onChange={(e) =>
                              this.setState({
                                signCustomer: e,
                              })
                            }
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                          />
                        </Space>
                      </div>
                    </Col>

                    <Row gutter={[24, 8]} style={{ marginTop: "1vw" }}>
                      <Col
                        xxl={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                        lg={{ span: 12, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b>Ticket Date</b>

                        <Form.Item
                          name="ticketDate"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Date!",
                            },
                          ]}
                          initialValue={
                            leadsData?.ticketDate &&
                            moment(leadsData?.ticketDate)
                          }
                          style={{ marginBottom: "0px" }}
                        >
                          <DatePicker
                            className="form-input"
                            style={{ width: "100%" }}
                            placeholder="Select Date"
                            format={"M/D/YYYY"}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                        lg={{ span: 12, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b style={{ fontSize: "14px" }}>
                          Load Time
                          {pickUpTime !== "Invalid Date" && pickUpTime !== ""
                            ? " (" + pickUpTime + ")"
                            : leadsData?.pickupTime
                            ? " (" + leadsData?.pickupTime + ")"
                            : ""}
                        </b>
                        <br />
                        <Form.Item
                          name="pickupVal"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Load Time!",
                            },
                          ]}
                          initialValue={
                            leadsData?.pickupVal ? leadsData?.pickupVal : ""
                          }
                          style={{ marginBottom: "0px" }}
                        >
                          <Input
                            className="form-input"
                            placeholder="Please enter Time"
                            onChange={(e) => this.handleTimeChange(e)}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xxl={{ span: 8, offset: 0 }}
                        xl={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        md={{ span: 8, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b>Ticket Number</b>
                        <Form.Item
                          name="ticketNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Ticket Number!",
                            },
                          ]}
                          initialValue={leadsData?.ticketNumber}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input
                            className="form-input"
                            placeholder="Please enter ticket number"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={{ span: 8, offset: 0 }}
                        xl={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        md={{ span: 8, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b>Truck Number</b>
                        <Form.Item
                          name="truckNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Truck Number!",
                            },
                          ]}
                          style={{ marginBottom: "0px" }}
                          initialValue={
                            leadsData?.truckNumber ? leadsData?.truckNumber : ""
                          }
                        >
                          <Input
                            type="number"
                            onChange={(e) => {
                              this.setState({
                                updatedTruckNumber: Number(e.target.value),
                                selectedMaterialId: "",
                                selectedCarrierId: "",
                              });
                            }}
                            placeholder="Please Select Truck number"
                            style={{
                              width: "100%",
                            }}
                            className="form-input"
                            onBlur={this.truckNumberValidation}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xxl={{ span: 8, offset: 0 }}
                        xl={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <b>Quantity</b>

                        <Form.Item
                          name="quantity"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Quantity",
                            },
                          ]}
                          initialValue={
                            leadsData?.quantity ? leadsData?.quantity : ""
                          }
                          style={{ marginBottom: "0px" }}
                        >
                          <InputNumber
                            placeholder="Please Enter Quantity"
                            addonAfter={selectAfter}
                            style={{ height: "40px", width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      {leadsData["uploadStatus"] === "Open" &&
                        leadsData["ticketNotes"] && (
                          <Col
                            span={24}
                          >
                            <b>Review Notes:</b>
                            <TextArea
                              bordered={false}
                              rows={4}
                              style={{
                                width: "100%",
                                height: "75px",
                                borderColor: "#d6d6d6",
                                border: "1px solid gray",
                                fontSize: "18px",
                                fontWeight: "500",
                                background: "#f0f0f0",
                                color: "#000",
                              }}
                              value={leadsData.ticketNotes}
                              className="form-input"
                              placeholder="Please Enter Review Notes"
                              readOnly
                            />
                          </Col>
                        )}
                    </Row>
                  </>

                  {(newTicket || leadsData["uploadStatus"] === "Open") && (
                    <Row>
                      <Col
                        xxl={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                        style={{
                          marginTop:
                            leadsData["uploadStatus"] === "Open" && "1vw",
                        }}
                      >
                        {leadsData["uploadStatus"] === "Open" && (
                          <b>Reupload Ticket</b>
                        )}
                        <Dragger
                          onChange={this.uploadTicketPdf}
                          customRequest={dummyRequest}
                          listType="picture"
                          multiple={false}
                          className="drag-ticket"
                          beforeUpload={this.handleUploadType}
                          maxCount={1}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-hint">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger>
                        {percentage > 1 && (
                          <Progress
                            percent={percentage}
                            status={"active"}
                            strokeColor={percentage === 100 && "green"}
                          />
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
              </Form>
            </BaseModal>
          )}
          {showTruckModal && (
            <BaseModal
              className="tickectfinan_modal antmodal_grid headermodal"
              title={"Trucks"}
              onCancel={() =>
                this.setState({
                  showTruckModal: false,
                })
              }
              formId="trucksData"
              loading={loading}
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "850px"
              }
            >
              <Form onFinish={this.handleTruckFinsih} id="trucksData">
                <BaseTable
                  columnsData={equipmentColumns}
                  source={showMultpleTrucks}
                  total={showMultpleTrucks?.length}
                  className="table_laptopscreen"
                  rowSelection={{
                    type: "radio",
                    ...rowSelection,
                  }}
                  handleTableChage={(e) =>
                    this.handleTableChage(e, "multipleTrucks")
                  }
                  sizeRange={
                    window.screen.width > 1023
                      ? JSON.parse(localStorage.getItem("tableSizeRange"))
                          ?.multipleTrucks
                      : showMultpleTrucks?.length
                  }
                  pagination={window.screen.width > 1023 ? true : false}
                  rowKey={(record) => record?.id}
                />
              </Form>
            </BaseModal>
          )}
          {showNotes && (
            <BaseModal
              className="tickectfinan_modal antmodal_grid headermodal"
              title={"Manager Review Notes"}
              onCancel={() =>
                this.setState({
                  showNotes: false,
                })
              }
              formId="reviewForm"
              loading={loading}
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "80%"
                  : "520px"
              }
            >
              <Form
                onFinish={(e) => this.onFinishNotes(e, jobInfo)}
                id="reviewForm"
              >
                <Form.Item
                  name="managerNotes"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Review Notes",
                    },
                  ]}
                  style={{ marginBottom: "0px" }}
                >
                  <TextArea
                    rows={4}
                    style={{
                      width: "100%",
                      height: "75px",
                      borderColor: "#d6d6d6",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                    className="form-input"
                    placeholder="Please Enter Review Notes"
                  />
                </Form.Item>
              </Form>
            </BaseModal>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    totalResult: state?.reducer?.ticketsResult,
    loading: state?.reducer?.ticketsResult?.loading,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetTickets: () => {
    dispatch(getTickets());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(RejectUpload)));
