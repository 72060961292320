import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Popover,
  Input,
  Form,
  Popconfirm,
  Select,
  message,
  Checkbox,
  DatePicker,
  Radio,
  // DatePicker,
} from "antd";
import {
  UnorderedListOutlined,
  EllipsisOutlined,
  PlusOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { RiDashboardLine } from "react-icons/ri";
import funnelStages from "../../../Json/funnel.json";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import FooterData from "../../../Common/FooterData";
import SearchFunction from "../../../Common/SearchFunction";
import { add } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import DraggableCard from "../../../Common/DraggableCard";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import Loading from "../../../Common/Loading";
import ErrorView from "../../../Common/ErrorView";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import TextArea from "antd/lib/input/TextArea";
import Filter from "../../../Common/Filter";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { IoFilterSharp } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import {
  addCompany,
  deleteCompany,
  getDropDownOptions,
  getOwnerOperators,
  // getTrucksListQuery,
  updateCompany,
  updateDropDownOptions,
} from "../../../Redux/Actions/actions";
import { withAppContext } from "../../../Store/AppContext";
import { MdArchive, MdUnarchive } from "react-icons/md";
import { FaMap } from "react-icons/fa";
import MapComponent from "../../Maps/MapComponent";

const { Option } = Select;
class OwnerOperator extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      boardView: true,
      selectionType: "",
      selectedRowKeys: [],
      visible: false,
      addressState: "",
      zipCode: "",
      leadsData: "",
      funnelData: [],
      citySearch: "",
      totalLeadsData: [],
      latitude: "",
      longitude: "",
      filterVisible: false,
      isCustomerEditModal: false,
      companyData: "",
      filterCompanyData: [],
      filterDriverData: [],
      recordMaterailData: "",
      materialCheck: false,
      quantityType: "Tons",
      pickUpAddress: "",
      dropAddress: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      roleOther: "",
      isContactVisible: false,
      leadQuote: [],
      recordCompanyId: "",
      equipmentTypeDetails: [],
      getCompanyData: [],
      dropoffLatitude: "",
      dropoffLongitude: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      selectedTerm: false,
      checkData: [
        "Uncontacted Lead",
        "Pending Carrier Packet + Insurance",
        "Pending Insurance",
        "Pending Carrier Packet",
        "Carrier Data Input",
        "Welcome Call",
        "Update Needed",
      ],
      selectedPaymentType: "",
      contactInfo: [],
      primaryContactCheck: false,
      carrierName: "",
      city: "",
      // radioData: undefined,
      // ownerCompanyData: this.props.columnsData,
      // filterownerCompanyData: this.props.columnsData,
      // searchDataList: this.props.columnsData,
      ownerRoles: this.props.ownerRoles[0]?.ownerRolesData?.sort((a, b) =>
        a?.value > b?.value ? 1 : -1
      ),
      showUpdatedNeeded: false,
      updateNeededData: [],
      filterownerCompanyData: [],
      paymentTerms: [],
      paymentTermsVal: "Net 7",
      payment_discount: "12.5%",
      searchData: undefined,
      archiveData: true,
      trucksData: [],
      showTab: "listView",
    };
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Trucking & Material";
    this.props.actionFetchOwnerOperators();
    this.props.actiongetDropDownOptions();
    this.funnelInfo();
    document.title = "Twisted Nail - Owner-Operator";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    if (
      JSON.stringify(prevState?.filterownerCompanyData) ===
      JSON.stringify(this.state?.filterownerCompanyData)
    ) {
      let hashKeyValue = window.location.hash;
      if (hashKeyValue === "#quickJob") {
        hashKeyValue = "";
        this.props.history.push(`${window.location.pathname}`);
        this.props.actionFetchOwnerOperators();
        this.props.actiongetDropDownOptions();
      }
    }
    if (
      prevProps?.result?.success === false &&
      this.props?.result?.success === true
    ) {
      const hasFilter =
        filterStorageData?.filter &&
        Object.keys(filterStorageData?.filter).length > 0;
      this.setState(
        {
          ownerCompanyData: this.props.columnsData,
          filterownerCompanyData: this.props.columnsData,
          searchDataList: this.props.columnsData,
        },
        () => {
          if (hasFilter) {
            this.filterResultData(filterStorageData?.filter, true);
          }
        }
      );
    }
    if (
      prevProps?.companiesInfo?.success === false &&
      this.props?.companiesInfo?.success === true
    ) {
      this.props.actionFetchOwnerOperators();
    }
    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      this.setState({
        trucksData: this.props?.trucksData[0]?.trucksData,
        paymentTerms: this.props?.termsData[0]?.termsData,
      });
    }
    if (
      prevProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props.actiongetDropDownOptions();
    }
    if (
      prevProps?.totalTrucksListResult?.success === false &&
      this?.props?.totalTrucksListResult?.success === true
    ) {
      this.setState({
        updateNeededData: this.props?.totalTrucksListResult?.trucksData,
        filterUpdateNeededData: this.props?.totalTrucksListResult?.trucksData,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    // console.log("--------getDerivedStateFromProps------------");
    if (
      JSON.stringify(props?.ownerRoles[0]?.ownerRolesData) !==
      JSON.stringify(state?.ownerRoles)
    ) {
      return {
        ownerRoles: props.ownerRoles[0]?.ownerRolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
      };
    }
  }

  onClickRefresh = () => {
    this.props.actionFetchOwnerOperators();
    this.props.actiongetDropDownOptions();
    this.funnelInfo();
  };

  onResetData = () => {
    this.setState({
      searchData: undefined,
    });
    this.props.actionFetchOwnerOperators();
    this.props.actiongetDropDownOptions();
    this.funnelInfo();
  };

  funnelInfo = () => {
    let funnel = [];
    const funnelId = "2";
    funnelStages.stages.map(
      (eachItem) =>
        parseInt(funnelId) === parseInt(eachItem.funnelId) &&
        eachItem.stage.map((stage) => {
          funnel.push(stage.content);
          this.setState({
            funnelData: funnel,
          });
          return true;
        })
    );
  };

  showModal = (record) => {
    // console.log(record);
    this.setState({
      isCompanyModalVisible: true,
      companyData: record,
      paymentTermsVal: record?.payment_terms,
      payment_discount: record?.payment_discount,
      // contactInfo: record?.contactInfo,
      latitude: record?.lat,
      longitude: record?.lng,
      selectedAddress: record?.address,
      equipmentTypeDetails: record?.carrierDetails,
      city: record?.cityName,
      addressState: record?.stateName,
    });
  };

  archiveEdit = (record) => {
    let data = {
      entityData: {
        showCarrier:
          record.showCarrier !== undefined ? !record.showCarrier : false,
      },
    };
    this.props.actionUpdateCompany(record?.id, data);
    message.success(
      `${record.carrier_name} ${
        data["entityData"]["showCarrier"] ? "retrived" : "archived"
      } successfully`
    );
  };

  onClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.props.actionFetchOwnerOperators();
    this.props.actiongetDropDownOptions();
    this.setState({
      filterVisible: false,
      // radioData: undefined,
      visible: false,
      checkData: [
        "Uncontacted Lead",
        "Pending Carrier Packet + Insurance",
        "Pending Insurance",
        "Pending Carrier Packet",
        "Carrier Data Input",
        "Welcome Call",
        "Update Needed",
      ],
    });
  };

  filterResultData = (data, noUpdate) => {
    const { searchDataList, ownerCompanyData } = this.state;
    let filteredData = ownerCompanyData?.filter(
      (f) => f?.stageId && data?.includes(f?.stageId)
    );
    let pendingPacketCount = filteredData?.filter(
      (f) => f?.stageId === "Pending Carrier Packet"
    )?.length;

    if (!noUpdate) {
      localStorage.setItem(
        "filterStorageData",
        JSON.stringify({
          filter: data,
          searchDataList,
        })
      );
    }
    this.setState({
      filterownerCompanyData: filteredData,
      checkData: data,
      pendingPacketCount: pendingPacketCount,
    });
    this.closeFilter();
    this.showMap(filteredData);
  };

  onSelectChange = (data) => {
    this.setState({ selectedRowKeys: data });
  };

  deleteCompany = (record) => {
    const { actionDeleteCompany } = this.props;
    actionDeleteCompany(record?.id);
  };

  getAddress = (address) => {
    this.setState({ selectedAddress: address.label });
  };

  handleSelect = (address) => {
    this.setState({
      citySearch: address.label,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    // let street_number = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          this.setState({ latitude: lat, longitude: lng });
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        // console.log(address, "address");
        for (let i = 0; i < address?.length; i++) {
          // if (address[i].types[0] === "street_number") {
          //   street_number = address[i].long_name;
          // }
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          } else if (address[i].types[0] === "route") {
            cityValue = address[i].long_name;
          } else if (address[i].types[0] === "political") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        // console.log("address", cityValue, stateValue);
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
          // streetNumber: street_number,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  searchResult = (searchValue) => {
    const { ownerCompanyData, updateNeededData, showUpdatedNeeded } =
      this.state;
    if (showUpdatedNeeded) {
      let searchFilter = [];
      if (searchValue === "") {
        this.onResetData();
      } else if (typeof searchValue === "number") {
        searchFilter = updateNeededData?.filter(
          (e) =>
            parseInt(e["usdot"]) === searchValue ||
            parseInt(e["phone"]?.replace(/[^+0-9]/g, "")) === searchValue
        );
      } else {
        searchFilter = updateNeededData?.filter(
          (e) =>
            e["carrier_name"]?.toLowerCase().indexOf(searchValue) > -1 ||
            e["address"]?.toLowerCase().indexOf(searchValue) > -1 ||
            e["contact_name"]?.toLowerCase().indexOf(searchValue)
        );
      }
      this.getLatLangData(searchValue);
      this.setState({
        filterUpdateNeededData: searchFilter,
      });
    } else {
      let searchFilter = [];
      if (searchValue === "") {
        this.onResetData();
      } else if (typeof searchValue === "number") {
        searchFilter = ownerCompanyData?.filter(
          (e) =>
            parseInt(e["usdot"]) === searchValue ||
            parseInt(e["phone"]?.replace(/[^+0-9]/g, "")) === searchValue
        );
      } else {
        searchFilter = ownerCompanyData?.filter(
          (e) =>
            e["company"]?.toLowerCase().indexOf(searchValue) > -1 ||
            e["address"]?.toLowerCase().indexOf(searchValue) > -1 ||
            e["contact_name"]?.toLowerCase().indexOf(searchValue)
        );
      }
      this.getLatLangData(searchValue);

      this.setState({
        filterownerCompanyData: searchFilter,
      });
    }
  };

  getLatLangData = (address) => {
    let newData = {};
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          console.log(lat, lng, "results");
          newData["lat"] = lat;
          newData["lng"] = lng;
          newData["address"] = address;
          this.setState({
            searchData: newData,
          });
        });
      })
      .catch((error) => console.error("Error", error));
  };

  addContact = (values) => {
    const { recordContactData, companyData, primaryContactCheck } = this.state;

    if (recordContactData !== "") {
      let filteredData = this.state.contactInfo.filter(
        (c) => c.id !== recordContactData?.id
      );
      this.setState({
        contactInfo: filteredData,
      });
    }

    let data = {
      entityData: {
        id:
          recordContactData?.id !== undefined
            ? recordContactData?.id
            : uuidv4(),
        contact_name: values["fName"] + " " + values["lName"],
        firstName: values.fName,
        lastName: values.lName,
        contact_number: values.phone,
        email: values.email,
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
        notes: values.notes,
        company: "",
        companyId: companyData?.id,
      },
    };

    if (primaryContactCheck) {
      data["entityData"]["isPrimaryContact"] = true;
    } else {
      data["entityData"]["isPrimaryContact"] = false;
    }

    let contactData = this.state.contactInfo?.filter(
      (c) =>
        c?.email !== undefined &&
        c?.email === values?.email &&
        c?.contact_number !== undefined &&
        c?.contact_number === values?.phone
    );

    if (contactData?.length > 0) {
      message.info("Given Email and Phone Number already exists");
      return true;
    } else {
      this.setState({
        loading: true,
        isContactVisible: false,
      });
      if (this.state.isCheckcontact) {
        this.updateOwnerRolesData(values["roleOther"]);
      }
      add(appConfig["entityNames"]["checkContact"], data)
        .then((result) => {
          if (result.data.message === "success") {
            message.success("Contact Added Successfully!");
            let contactInfo =
              this.state.contactInfo?.length > 0 ? this.state.contactInfo : [];
            if (recordContactData?.length > 0) {
              let filteredData = this.state.contactInfo.filter(
                (c) => c.id !== recordContactData?.id
              );
              filteredData.push(data["entityData"]);
              this.setState({
                contactInfo: filteredData,
                loading: false,
                isCheckcontact: false,
                newRole: "",
                primaryContactCheck: false,
              });
            }
            contactInfo.push(data["entityData"]);
            this.setState({
              contactInfo,
              loading: false,
              isCheckcontact: false,
              newRole: "",
              primaryContactCheck: false,
            });
          } else {
            message.info(result.data.message);
            this.setState({
              loading: false,
              isCheckcontact: false,
              newRole: "",
              primaryContactCheck: false,
            });
            return true;
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
        });
    }
  };

  deleteContactInfo = (id) => {
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState({
      contactInfo,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    this.setState({
      isContactVisible: true,
      recordContactData: data,
      primaryContactCheck: data?.isPrimaryContact,
    });
  };

  updateOwnerRolesData = (values) => {
    let ownerRolesData = this.state.ownerRoles;
    ownerRolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: ownerRolesData?.length + 1,
    });
    let data = {
      entityData: {
        ownerRolesData,
        typeofDropDown: "ownerOperatorRoles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.ownerRoles[0]?.id, data);
  };

  editModal = (record) => {
    // console.log(record);
    this.setState({
      isModalVisible: true,
      leadsData: record,
      selectedAddress: record?.address,
      city: record?.cityName,
      addressState: record?.stateName,
      latitude: record?.lat,
      longitude: record?.lng,
    });
  };

  onFinishCompanyDetails = (values) => {
    const {
      companyData,
      selectedAddress,
      latitude,
      longitude,
      city,
      addressState,
      contactInfo,
      equipmentTypeDetails,
      carrierName,
      payment_discount,
    } = this.state;
    const data = {
      entityData: {
        company:
          values["carrier_name"] !== undefined ? values["carrier_name"] : "",
        carrier_name:
          values["carrier_name"] !== undefined ? values["carrier_name"] : "",
        carrier_status: values["carrier_status"],
        address: selectedAddress,
        cityName: city,
        stateName: addressState,
        usdot: parseInt(values["usdot"]),
        phone: values["phone"],
        email: values["email"],
        payment_terms:
          // values["payment_terms"] === "Custom Input"
          //   ? values["paymentTermsOthers"]
          //   :
          values["payment_terms"],
        payment_discount: payment_discount,
        insurance_expiry:
          values["insuranceDate"] === null
            ? ""
            : values["insuranceDate"] === ""
            ? ""
            : moment(values["insuranceDate"]).format("L"),
        direct_deposit: values["direct_deposit"],
        lat: latitude,
        lng: longitude,
        notes: values["notes"],
        contactInfo: contactInfo,
        registered_since: moment(values["registered_since"]).format("l"),
      },
    };

    if (companyData !== "") {
      this.setState({
        isCompanyModalVisible: false,
      });
      if (carrierName !== "" && carrierName !== undefined) {
        equipmentTypeDetails?.map((t) => (t["carrier_name"] = carrierName));
        data["entityData"]["carrierDetails"] = equipmentTypeDetails;
      }
      // console.log(data);
      this.updateCompanyDetails(data);
    } else {
      data["entityData"]["leadType"] = [{ id: "2", value: "Owner Operator" }];
      data["entityData"]["stageId"] = "Uncontacted Lead";
      data["entityData"]["carrier_status"] = "Applicant";
      data["entityData"]["isOwnerOperator"] = true;
      data["entityData"]["carrierDetails"] = [];
      // console.log(data);
      if (data?.entityData?.contactInfo?.length > 0) {
        this.setState({
          isCompanyModalVisible: false,
        });
        this.addCompany(data);
      } else {
        message.info("Please add atleast one contact");
      }
    }
  };

  addCompany = (data) => {
    this.props.actionAddCompany(data);
    this.setState({
      companyData: "",
      contactInfo: [],
      selectedAddress: "",
      latitude: "",
      longitude: "",
      city: "",
      addressState: "",
    });
  };

  updateCompanyDetails = (data) => {
    this.props.actionUpdateCompany(this.state.companyData?.id, data);
    this.setState({
      selectedPaymentType: "",
      carrierName: "",
    });
  };

  closeFilter = () => {
    this.setState({
      visible: false,
      filterVisible: false,
    });
  };

  onChangeTerms = (e, option) => {
    this.setState({
      paymentTermsVal: e,
      payment_discount: option.key,
    });
    // this.setState({ selectedPaymentType: e });
    // this.formRef.current.setFieldsValue({
    //   paymentTermsOthers: "",
    // });
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  getCarrierStatusCounts = (stageId, type) => {
    const { filterownerCompanyData } = this.state;
    let showActiveCarrier = filterownerCompanyData?.filter(
      (e) => e.showCarrier !== false
    );
    let result = [];
    if (type === "averageDays") {
      let currentDate = new moment(moment().format("l"));
      result = showActiveCarrier
        .filter(
          (e) =>
            e?.stageId === "Pending Carrier Packet + Insurance" ||
            e?.stageId === "Pending Insurance" ||
            e?.stageId === "Pending Carrier Packet"
        )
        ?.map((e) => {
          var recordDate = new moment(moment(e.updatedAt).format("l"));
          var diff = currentDate - recordDate;
          let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
          e["averageDays"] = duration;
          return e;
        });
    } else {
      result = showActiveCarrier.filter((data) => data.stageId === stageId);
    }

    return result;
  };
  showMap = (filterData) => {
    return <MapComponent filteredMapData={filterData} ownerStageId />;
  };

  handleMoveToDriver = (values) => {
    let data = {
      entityData: {
        firstName: values["contactInfo"][0]["firstName"],
        lastName: values["contactInfo"][0]["lastName"],
        phone: values["phone"],
        email: values["email"],
        address: values["address"],
        cityName: values["cityName"],
        stateName: values["stateName"],
        stageId: "Contacted",
        leadType: [
          {
            id: "3",
            value: "Driver",
          },
        ],
        lat: values["lat"],
        lng: values["lng"],
        notes: values["notes"],
      },
    };
    console.log(data, "data");
    this.props.actionAddCompany(data);
    this.deleteCompany(values);
  };

  render() {
    const { loading, errorLoading } = this.props;

    const {
      trucksData,
      archiveData,
      paymentTermsVal,
      paymentTerms,
      showTab,
      selectedRowKeys,
      citySearch,
      // ownerCompanyData,
      filterownerCompanyData,
      searchDataList,
      boardView,
      companyData,
      totalLeadsData,
      isContactVisible,
      // equipmentTypeDetails,
      isCompanyModalVisible,
      // selectedTerm,
      recordContactData,
      newRole,
      // selectedPaymentType,
      contactInfo,
      ownerRoles,
      // showUpdatedNeeded,
      // filterUpdateNeededData,
      searchData,
    } = this.state;
    // console.log(filterownerCompanyData, "filterownerCompanyData");
    let today = moment().add(30, "days").calendar();

    const ownerOperatorColumns = [
      {
        title: "CARRIER NAME",
        dataIndex: "carrier_name",
        key: "carrier_name",
        sorter: (a, b) =>
          a?.carrier_name?.toLowerCase() < b?.carrier_name?.toLowerCase()
            ? -1
            : 1,
        className: "col_styling",
        width: "20%",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/owner-operator-profile/${record.id}`),
        }),
        render: (carrier_name, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {carrier_name
                  ? carrier_name
                  : record?.companyName
                  ? record?.companyName
                  : record?.company}
              </span>
            ),
          };
        },
      },
      {
        title: "CITY,STATE",
        dataIndex: "address",
        key: "address",
        sorter: (a, b) =>
          a?.cityName?.toLowerCase() < b?.cityName?.toLowerCase() ? -1 : 1,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/owner-operator-profile/${record.id}`),
        }),
        render: (address, record) => {
          let searchInfo =
            searchData !== undefined
              ? `(${searchData.lat + ", " + searchData.lng}) `
              : "";

          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (record?.cityName || record?.stateName) && (
              <Popover content={<span>{address}</span>}>
                <span
                  className="col_styling table-font-mobile"
                  style={{
                    color: "#474747",
                    fontSize: "14px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {record?.cityName !== null && record?.stateName !== null ? (
                    <span>
                      <b>{searchInfo}</b>
                      {`${record?.cityName}, ${record?.stateName}`}
                    </span>
                  ) : (
                    <span>
                      <b>{searchInfo}</b> {`${record?.stateName}`}
                    </span>
                  )}
                </span>
              </Popover>
            ),
          };
        },
      },
      {
        title: "CONTACT NAME",
        dataIndex: "contact_name",
        key: "contact_name",
        sorter: (a, b) =>
          a?.contactDetails[0]?.isPrimaryContact &&
          b?.contactDetails[0]?.isPrimaryContact
            ? a?.contactDetails[0]?.contact_name?.toLowerCase() <
              b?.contactDetails[0]?.contact_name?.toLowerCase()
              ? -1
              : 1
            : a?.contactDetails[0]?.isPrimaryContact
            ? -1
            : 1,
        className: "col_styling",
        width: "180px",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/owner-operator-profile/${record.id}`),
        }),
        render: (contact_name, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                className="col_styling table-font-mobile"
                style={{
                  color: "#474747",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                {record?.contactDetails?.map((e) => (
                  <div>
                    {e?.isPrimaryContact === true
                      ? e?.contact_name
                        ? e?.contact_name
                        : e?.firstName + " " + e?.lastName
                      : ""}
                  </div>
                ))}
              </span>
            ),
          };
        },
      },
      {
        title: "CONTACT PHONE",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling",
        width: "180px",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/owner-operator-profile/${record.id}`),
        }),
        render: (record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                className="col_styling table-font-mobile"
                style={{
                  color: "#474747",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                {record?.contactDetails?.map((e) => (
                  <div>
                    {e?.isPrimaryContact === true
                      ? e?.contact_number && e?.contact_number
                      : ""}
                  </div>
                ))}
              </span>
            ),
          };
        },
      },
      {
        title: "TRUCK COUNT",
        dataIndex: "",
        key: "",
        sorter: (a, b) => a?.carrierDetails?.length - b?.carrierDetails?.length,
        className: "col_styling",
        width: "180px",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/owner-operator-profile/${record.id}`),
        }),
        render: (record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                className="col_styling table-font-mobile"
                style={{
                  color: "#474747",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                }}
              >
                {record?.carrierDetails?.length}
              </span>
            ),
          };
        },
      },
      {
        title: "Date Added",
        dataIndex: "createdAt",
        className: "col_styling",
        sorter: (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        render: (createdAt, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <span
                className="col_styling table-font-mobile"
                style={{
                  color: "#474747",
                  fontSize: "14px",
                }}
              >
                {moment(createdAt).format("MM/DD/YYYY")}
              </span>
            ),
          };
        },
      },
      {
        title: "CARRIER STATUS",
        dataIndex: "stageId",
        key: "stageId",
        sorter: false,
        className: "col_styling",
        width: "180px",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(`/app/owner-operator-profile/${record.id}`),
        }),
        render: (stageId, record) => {
          // const isInsuranceExpired =
          //   new Date().getTime() >=
          //   new Date(record?.insurance_expiry).getTime();
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <Button
                className="lead_ButtonStatus table-font-mobile"
                style={{
                  background: "transparent",
                  borderRadius: "6px",
                  // borderColor: isInsuranceExpired
                  //   ? "white"
                  //   : appConfig["STAGE_COLORS"][stageId],
                  // color: isInsuranceExpired
                  //   ? "white"
                  //   : appConfig["STAGE_COLORS"][stageId],
                  // borderWidth: "1px",
                  borderColor: "#595959",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                  fontWeight: "600",
                }}
              >
                {/* <Button
              className="col_styling"
              style={{
                color:
                  stageId === "Active Carrier"
                    ? "#4C9150"
                    : stageId === "Updated Needed"
                    ? "#434F61"
                    : "#D15300",
                fontSize: "16px",
                fontFamily: "sans-serif",
                fontWeight: "600",
                borderColor:
                  stageId === "Active Carrier"
                    ? "#4C9150"
                    : stageId === "Updated Needed"
                    ? "#434F61"
                    : "#D15300",
                borderRadius: "5px",
              }}
            > */}
                {stageId}
              </Button>
            ),
          };
        },
      },
      // {
      //   title: "REGISTERED SINCE",
      //   dataIndex: "createdAt",
      //   key: "createdAt",
      //   sorter: false,
      //   className: "col_styling",
      //   width: "180px",
      //   onCell: (record) => ({
      //     onClick: () =>
      //       this.props.history.push(`/app/owner-operator-profile/${record.id}`),
      //   }),
      //   render: (createdAt) => {
      //     return (
      //       <span
      //         className="col_styling"
      //         style={{
      //           color: "#474747",
      //           fontSize: "14px",
      //           fontFamily: "sans-serif",
      //         }}
      //       >
      //         {moment(createdAt).format("L")}
      //       </span>
      //     );
      //   },
      // },
      // {
      //   title: "INSURANCE EXPIRY DATE",
      //   dataIndex: "insurance_expiry",
      //   key: "insurance_expiry",
      //   sorter: false,
      //   className: "col_styling",
      //   width: "180px",
      //   onCell: (record) => ({
      //     onClick: () =>
      //       this.props.history.push(`/app/owner-operator-profile/${record.id}`),
      //   }),
      //   render: (insurance_expiry) => {
      //     return (
      //       <span
      //         className="col_styling"
      //         style={{
      //           // color: "#474747",
      //           fontSize: "14px",
      //           fontFamily: "sans-serif",
      //           color:
      //             new Date(today).getTime() <=
      //             new Date(insurance_expiry).getTime()
      //               ? "black"
      //               : "red",
      //         }}
      //       >
      //         {moment(insurance_expiry).format("L")}
      //       </span>
      //     );
      //   },
      // },
      {
        title: "ACTIONS",
        dataIndex: "actions",
        key: "actions",
        sorter: false,
        width: "120px",
        className: "col_styling",
        fixed: "right",
        // onCell: (record) => ({
        //   onClick: () =>
        //     (window.location="/app/loads/loadprofile/"+record.id),
        // }),
        render: (actions, record) => {
          return {
            props: {
              style: {
                background:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fcfacc"
                    : "white",
                borderColor:
                  new Date().getTime() >=
                  new Date(record?.insurance_expiry).getTime()
                    ? "#ff090924"
                    : new Date(today).getTime() >
                        new Date(record?.insurance_expiry).getTime() &&
                      new Date().getTime() <
                        new Date(record?.insurance_expiry).getTime()
                    ? "#fadb14"
                    : "#d9d9d9",
              },
            },
            children: (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
                className="table-font-mobile"
              >
                {/* <Col>
                  <a href={`tel:${record.phone}`}>
                    <button
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <MdPhone
                        style={{ color: "#878787", fontSize: "22px" }}
                        className="icon"
                      />
                    </button>
                  </a>
                </Col>
                <Col>
                  <a href={`mailto:${record.email}`}>
                    <button
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <MdEmail
                        style={{ color: "#878787", fontSize: "22px" }}
                        className="icon"
                      />
                    </button>
                  </a>
                </Col> */}
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row
                        className="popovergrid"
                        style={{ maxWidth: "190px" }}
                      >
                        <Col span={24}>
                          <Button
                            className="btn_edit"
                            onClick={() => this.showModal(record)}
                          >
                            <span className="span_edit">
                              <MdModeEditOutline
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Edit
                            </span>
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Button className="btn_edit">
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => this.deleteCompany(record)}
                            >
                              <span className="span_edit">
                                <MdDelete style={{ marginRight: "5px" }} />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>

                        <Col span={24}>
                          <Button className="btn_edit">
                            <Popconfirm
                              title={
                                <span>
                                  {`Are you sure you want to ${
                                    record?.showCarrier === false
                                      ? "retrive"
                                      : "archive"
                                  } `}
                                  <b>{record.carrier_name}？</b>
                                </span>
                              }
                              okText={
                                <span style={{ width: "60px", height: "30px" }}>
                                  Yes
                                </span>
                              }
                              cancelText={
                                <span style={{ width: "60px", height: "30px" }}>
                                  No
                                </span>
                              }
                              onConfirm={() => this.archiveEdit(record)}
                            >
                              <span className="span_edit">
                                {record?.showCarrier === false ? (
                                  <>
                                    <MdUnarchive
                                      style={{ marginRight: "5px" }}
                                    />{" "}
                                    Retrive
                                  </>
                                ) : (
                                  <>
                                    <MdArchive style={{ marginRight: "5px" }} />{" "}
                                    Archive
                                  </>
                                )}
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                        {record.stageId === "Uncontacted Lead" && (
                          <Col span={24}>
                            <Button
                              className="btn_edit"
                              onClick={() => this.handleMoveToDriver(record)}
                            >
                              <span className="span_edit">
                                <RedoOutlined style={{ marginRight: "5px" }} />{" "}
                                <span> Move Driver Database</span>
                              </span>
                            </Button>
                          </Col>
                        )}
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </Row>
            ),
          };
        },
      },
    ];

    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange,
    // };
    const searchFields = ["contactInfo", "usdot", "address", "companyName"];

    const sourceData = archiveData
      ? filterownerCompanyData.filter((e) => e.showCarrier !== false)
      : searchDataList?.filter((e) => e.showCarrier === false);
    return (
      <>
        <Row style={{ marginBottom: "1vw" }}>
          <Col span={24}>
            <Row
              style={{
                borderRadius: "10px",
                marginBottom: "20px",
                padding: "0px 20px",
                backgroundColor: "#ffffff",
                border: "1px solid gray",
                textAlign: "left",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "600",
                      width: "100%",
                      display: "flex",
                      flexWrap: "nowrap",
                    }}
                    className="owner-operator-card-head"
                  >
                    Pending All
                  </span>

                  <span>
                    {
                      this.state.filterownerCompanyData.filter(
                        (e) =>
                          e.showCarrier !== false &&
                          (e?.stageId ===
                            "Pending Carrier Packet + Insurance" ||
                            e?.stageId === "Pending Insurance" ||
                            e?.stageId === "Pending Carrier Packet")
                      )?.length
                    }
                  </span>
                </p>
              </Col>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "600",
                      width: "100%",
                      display: "flex",
                      flexWrap: "nowrap",
                    }}
                    className="owner-operator-card-head"
                  >
                    Pending Insurance
                  </span>
                  {this.getCarrierStatusCounts("Pending Insurance")?.length}
                </p>
              </Col>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "600",
                      width: "100%",
                      display: "flex",
                      flexWrap: "nowrap",
                    }}
                    className="owner-operator-card-head"
                  >
                    Pending Packet
                  </span>
                  {
                    this.getCarrierStatusCounts("Pending Carrier Packet")
                      ?.length
                  }
                </p>
              </Col>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ margin: "12px 0px" }}
              >
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  className="owner-operator-card-body"
                >
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#747474",
                      fontWeight: "600",
                      width: "100%",
                      display: "flex",
                      flexWrap: "nowrap",
                    }}
                    className="owner-operator-card-head"
                  >
                    Average Days Pending
                  </span>

                  <span>
                    {parseInt(
                      this.getCarrierStatusCounts("", "averageDays")?.reduce(
                        (a, v) => (a = a + v.averageDays),
                        0
                      ) /
                        filterownerCompanyData.filter(
                          (e) =>
                            e?.stageId ===
                              "Pending Carrier Packet + Insurance" ||
                            e?.stageId === "Pending Insurance" ||
                            e?.stageId === "Pending Carrier Packet"
                        )?.length || 0
                    )}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Loading enableLoading={loading} /> */}
        {totalLeadsData && (
          <div
            style={{
              background: "#fafafa",
            }}
          >
            <Row style={{ marginBottom: "1vw" }}>
              {boardView ? (
                <Col
                  xxl={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={12}
                >
                  <Row>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      className="quarrytoolgrid"
                    >
                      <SearchFunction
                        tableName={"leads"}
                        getSearchData={this.searchResult}
                        onResetData={this.onResetData}
                        searchFields={searchFields}
                      />
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // span={12}
                ></Col>
              )}
              <Col
                xxl={{ span: 18 }}
                xl={{ span: 18 }}
                lg={{ span: 18 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                // span={12}
                style={{ marginLeft: "auto" }}
              >
                {parseInt(this.props?.context?.dropDownId) !== 3 && (
                  <Row className="owner-operator-layout">
                    <Col className="gridbtntool-owner-operator">
                      {boardView ? (
                        <Row gutter={[8, 0]}>
                          <Col className="gridbtntool-owner-operator">
                            <Button
                              className="show-on-desktop"
                              type="primary"
                              icon={
                                archiveData ? (
                                  <MdArchive style={{ fontSize: "18px" }} />
                                ) : (
                                  <MdUnarchive style={{ fontSize: "18px" }} />
                                )
                              }
                              onClick={() =>
                                this.setState({
                                  archiveData: !archiveData,
                                  banData: true,
                                })
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "5px",
                                height: "40px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                  marginLeft: "5px",
                                }}
                              >
                                {archiveData ? "Archive" : "Retrive"}
                              </span>
                            </Button>
                            <Button
                              className="show-on-mobile"
                              type="primary"
                              icon={
                                archiveData ? (
                                  <MdArchive
                                    style={{ fontSize: "16px" }}
                                    className="trucking-filter-icon"
                                  />
                                ) : (
                                  <MdUnarchive
                                    style={{ fontSize: "16px" }}
                                    className="trucking-filter-icon"
                                  />
                                )
                              }
                              onClick={() =>
                                this.setState({
                                  archiveData: !archiveData,
                                  banData: true,
                                })
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "5px",
                                height: "40px",
                                padding: "5px",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  marginLeft: "5px",
                                }}
                                className="col_styling table-font-mobile"
                              >
                                {archiveData ? "Archive" : "Retrive"}
                              </div>
                            </Button>
                          </Col>
                          {/* <Col
                            className="gridbtntool-owner-operator"
                            style={{
                              border: "1px solid #d9d9d9",
                              borderRadius: "6px",
                            }}
                          >
                            <span
                              className="title_changes"
                              style={{ marginRight: "10px" }}
                            >
                              Show Update Needed
                            </span>
                            <Switch
                              checked={showUpdatedNeeded}
                              className="switchcolor"
                              onChange={(e) =>
                                this.setState({
                                  showUpdatedNeeded: e,
                                })
                              }
                            />
                          </Col> */}

                          <Col className="gridbtntool-owner-operator">
                            <Button
                              // className="ownerOperator_button"
                              type="primary"
                              icon={
                                <PlusOutlined className="trucking-filter-icon" />
                              }
                              onClick={() =>
                                this.setState({
                                  isCompanyModalVisible: true,
                                  companyData: "",
                                })
                              }
                              style={{
                                borderRadius: "5px",
                                height: "40px",
                              }}
                            >
                              <span
                                style={{ fontWeight: "500", fontSize: "14px" }}
                                className="show-on-desktop"
                              >
                                Owner Operator
                              </span>
                              <span
                                style={{ fontWeight: "500", fontSize: "12px" }}
                                className="show-on-mobile table-font-mobile"
                              >
                                Operator
                              </span>
                            </Button>
                          </Col>

                          <Col className="gridbtntool-owner-operator">
                            <Button
                              className="button_Crm show-on-desktop"
                              style={{
                                borderRadius: "5px",
                                height: "40px",
                              }}
                              onClick={() => {
                                this.setState({ visible: true });
                              }}
                            >
                              <Row>
                                <Col>
                                  <span
                                    style={{
                                      color: "#5a6067",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Filter
                                  </span>
                                </Col>
                                <Col>
                                  <IoFilterSharp
                                    style={{
                                      fontSize: "22px",
                                      marginLeft: "8px",
                                      marginTop: "2px",
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Button>
                            <Button
                              className="button_Crm show-on-mobile filter_button"
                              style={{
                                borderRadius: "5px",
                                height: "40px",
                              }}
                              onClick={() => {
                                this.setState({ visible: true });
                              }}
                            >
                              <Row>
                                <Col>
                                  <IoFilterSharp
                                    style={{
                                      fontSize: "18px",
                                      marginTop: "2px",
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Button>
                          </Col>
                          <Col className="gridbtntool">
                            <Row
                              style={{
                                background: "#cecece",
                                height: "40px",
                                borderRadius: "6px",
                              }}
                            >
                              <Col style={{ marginTop: "4px" }}>
                                <Button
                                  style={{
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 8px",
                                    margin: "0px 5px",
                                    background:
                                      this.state.showTab === "listView"
                                        ? "#fff"
                                        : "transparent",
                                    boxShadow: "none",
                                    outline: "none",
                                  }}
                                  onClick={() => {
                                    this.setState({ showTab: "listView" });
                                    window.screen.width >= 1200 &&
                                      this.props.context.updateSideronCollapse(
                                        false
                                      );
                                  }}
                                >
                                  <UnorderedListOutlined
                                    style={{
                                      fontSize: "22px",
                                      color:
                                        this.state.showTab === "listView"
                                          ? "#586370"
                                          : "#586370",
                                    }}
                                  />
                                </Button>
                              </Col>
                              <Col style={{ marginTop: "4px" }}>
                                <Button
                                  style={{
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 8px",
                                    margin: "0px 5px",
                                    background:
                                      this.state.showTab === "boardView"
                                        ? "#fff"
                                        : "transparent",
                                    boxShadow: "none",
                                    outline: "none",
                                  }}
                                  onClick={() => {
                                    this.setState({ showTab: "boardView" });
                                    window.screen.width >= 1200 &&
                                      this.props.context.updateSideronCollapse(
                                        false
                                      );
                                  }}
                                >
                                  <RiDashboardLine
                                    style={{
                                      fontSize: "22px",
                                      background:
                                        this.state.showTab === "boardView"
                                          ? "#fff"
                                          : "transparent",
                                    }}
                                  />
                                </Button>
                              </Col>
                              <Col style={{ marginTop: "4px" }}>
                                <Button
                                  style={{
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 8px",
                                    margin: "0px 5px",
                                    background:
                                      this.state.showTab === "showMapComponent"
                                        ? "#fff"
                                        : "transparent",
                                    boxShadow: "none",
                                    outline: "none",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      showTab: "showMapComponent",
                                    })
                                  }
                                >
                                  <FaMap
                                    style={{
                                      fontSize: "22px",
                                      color:
                                        this.state.showTab ===
                                        "showMapComponent"
                                          ? "#586370"
                                          : "#586370",
                                    }}
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            {showTab === "boardView" ? (
              <Row style={{ overflow: "auto" }}>
                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ overflow: "auto" }}
                >
                  {errorLoading ? (
                    <ErrorView refreshCrm={this.onClickRefresh} type="CRM" />
                  ) : (
                    <Card
                      bodyStyle={{
                        padding: "0px",
                        overflowX: "auto",
                        height: "100%",
                      }}
                      style={{
                        background: "#eff1f3",
                        minHeight:
                          window.width <= 377 && window.width <= 799
                            ? "70vh"
                            : "50vh",
                        height: "100%",
                        padding: "20px",
                      }}
                      className="kanbanscrollCard"
                    >
                      <DraggableCard
                        headingData={this.state.funnelData}
                        contentData={filterownerCompanyData}
                        onResetData={this.onResetData}
                        trucksData={trucksData}
                      />
                    </Card>
                  )}
                </Col>
              </Row>
            ) : showTab === "showMapComponent" ? (
              <Row>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  // style={{ height: "75vh" }}
                  className="twistednailmap"
                >
                  {this.showMap(
                    filterownerCompanyData?.filter((e) =>
                      archiveData
                        ? e.showCarrier !== false
                        : e.showCarrier === false
                    )
                  )}
                </Col>
              </Row>
            ) : (
              <Row data-aos="fade-up">
                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {errorLoading ? (
                    <ErrorView refreshCrm={this.onClickRefresh} type="CRM" />
                  ) : (
                    <Card bodyStyle={{ padding: "0px", overflowX: "auto" }}>
                      <Loading enableLoading={loading} />
                      <BaseTable
                        className="customertablegrp table_laptopscreen"
                        columnsData={ownerOperatorColumns}
                        sortDirections={[
                          "ascend",
                          "descend" || "descend",
                          "ascend",
                        ]}
                        source={sourceData?.sort((a, b) => {
                          const stageIdOrder = [
                            "Approved Carrier",
                            "Archived Carrier",
                            "Carrier Data Input",
                            "Pending Carrier Packet",
                            "Pending Insurance",
                            "Pending Carrier Packet + Insurance",
                            "Uncontacted Lead",
                          ];
                          const stageIdComparison =
                            stageIdOrder.indexOf(a.stageId) -
                            stageIdOrder.indexOf(b.stageId);

                          if (stageIdComparison !== 0) {
                            return stageIdComparison;
                          }

                          return (
                            new Date(a.insurance_expiry) -
                            new Date(b.insurance_expiry)
                          );
                        })}
                        // rowSelection={rowSelection}
                        total={sourceData?.length}
                        rowKey={(record) => record.id}
                        handleTableChage={(e) =>
                          this.handleTableChage(e, "ownerOperator")
                        }
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.ownerOperator
                            : filterownerCompanyData.filter((e) =>
                                archiveData
                                  ? e.showCarrier !== false
                                  : e.showCarrier === false
                              )?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                      {selectedRowKeys?.length > 0 && (
                        <FooterData count={selectedRowKeys?.length} deactive />
                      )}
                    </Card>
                  )}
                </Col>
              </Row>
            )}

            {isContactVisible && (
              <BaseModal
                className="crmform_modal antmodal_grid headermodal"
                title="Add New Contact"
                onCancel={() => {
                  this.setState({
                    isCheckcontact: false,
                    isContactVisible: false,
                    newRole: "",
                  });
                }}
                loading={this.state.loading}
                visible={isContactVisible}
                destroyOnClose={true}
                formId="contact"
                width={"25%"}
              >
                <Form
                  style={{ width: "100%" }}
                  onFinish={this.addContact}
                  id="contact"
                >
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <span className="title_changes">First Name</span>
                      <Form.Item
                        name="fName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your First Name!",
                          },
                        ]}
                        initialValue={recordContactData?.firstName}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({
                              firstName: e.target.value,
                            })
                          }
                          className="form-input"
                          placeholder="Please Enter Your First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <span className="title_changes">Last Name</span>
                      <Form.Item
                        name="lName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter your Last Name!",
                          },
                        ]}
                        initialValue={recordContactData?.lastName}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({
                              lastName: e.target.value,
                            })
                          }
                          className="form-input"
                          placeholder="Please Enter your Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <span className="title_changes">Phone</span>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Phone!",
                          },
                        ]}
                        initialValue={recordContactData?.contact_number}
                      >
                        <NumberFormat
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          className="ant-input-number numberictest"
                          format="(###) ###-####"
                          style={{ width: "100%" }}
                          placeholder="Please Enter Your Phone"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <span className="title_changes">Email</span>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Email!",
                          },
                          {
                            type: "email",
                            message: "The Input Is Not Valid Email",
                          },
                        ]}
                        initialValue={recordContactData?.email}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          className="form-input"
                          placeholder="Please Enter Your Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <span className="title_changes">Role</span>
                      <Form.Item
                        name="role"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Role!",
                          },
                        ]}
                        initialValue={
                          recordContactData?.contactRole &&
                          recordContactData?.contactRole
                        }
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_Height"
                          // style={{ height: "40px" }}
                          placeholder="Please Select A Role"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) => {
                            this.setState({ newRole: value });
                            value === "Others" &&
                              message.info("Please Input Your Role");
                          }}
                        >
                          {ownerRoles?.map((role) => (
                            <Option key={role.id} value={role.label}>
                              {role.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {newRole === "Add New Role" && (
                      <Col span={24}>
                        <span className="title_changes">Add New Role</span>
                        <Form.Item name="roleOther">
                          <Input
                            className="form-input"
                            placeholder="Please Enter Role"
                          />
                        </Form.Item>
                        <Checkbox
                          checked={this.state.isCheckcontact}
                          onChange={(e) =>
                            this.setState({
                              isCheckcontact: e.target.checked,
                            })
                          }
                          style={{ marginBottom: "18px" }}
                        >
                          <span
                            className="checkBox_size"
                            style={{ fontSize: "14px" }}
                          >
                            Add this new role to default list
                          </span>
                        </Checkbox>
                      </Col>
                    )}
                    <Col span={24}>
                      <span className="title_changes">Notes</span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Notes!",
                          },
                        ]}
                        initialValue={recordContactData?.notes}
                      >
                        <TextArea
                          rows={4}
                          style={{
                            width: "100%",
                            height: "65px",
                            borderColor: "#d6d6d6",
                          }}
                          className="form-input"
                          placeholder="Please Enter Your Notes"
                        />
                      </Form.Item>
                    </Col>

                    {(contactInfo?.filter((e) => e?.isPrimaryContact === true)
                      ?.length === 0 ||
                      contactInfo?.length === 0 ||
                      recordContactData?.isPrimaryContact === true) && (
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        {/* <span className="title_changes">Add this contact as primary</span> */}
                        <Checkbox
                          checked={this.state.primaryContactCheck}
                          onChange={(e) =>
                            this.setState({
                              primaryContactCheck: e.target.checked,
                            })
                          }
                          style={{ marginBottom: "18px" }}
                        >
                          <span
                            className="checkBox_size"
                            style={{ fontSize: "14px" }}
                          >
                            Add This Contact As Primary
                          </span>
                        </Checkbox>
                      </Col>
                    )}
                  </Row>
                </Form>
              </BaseModal>
            )}

            {this.state.visible && (
              <Filter
                visible={this.state.visible}
                closeFilter={this.closeFilter}
                filterResultData={this.filterResultData}
                onClearFilter={this.onClearFilter}
                checkData={this.state.checkData}
                ownerOperator
                // radioData={this.state.radioData}
              />
            )}

            {isCompanyModalVisible && (
              <BaseModal
                title={
                  companyData !== ""
                    ? "Update Carrier Details"
                    : "Add New Carrier"
                }
                onCancel={() => {
                  this.setState({
                    isCompanyModalVisible: false,
                    selectedPaymentType: "",
                    paymentTermsVal: "Net 7",
                    payment_discount: "12.5%",
                  });
                }}
                formId="CompanyDetails"
                className="antmodal_grid headermodal"
                width={
                  window.screen.width > "501" && window.screen.width <= "991"
                    ? "80%"
                    : "640px"
                }
                loading={this.state.loading}
              >
                <Form
                  onFinish={this.onFinishCompanyDetails}
                  id="CompanyDetails"
                  ref={this.formRef}
                >
                  <Row gutter={[32, 0]}>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={11}
                    >
                      <span className="title_changes">Carrier Name</span>
                      <Form.Item
                        name="carrier_name"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Carrier Name",
                          },
                        ]}
                        initialValue={companyData?.carrier_name}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ carrierName: e.target.value })
                          }
                          className="form-input"
                          placeholder="Please enter Carrier Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Registered Since</span>
                      <Form.Item
                        name="registered_since"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your date!",
                          },
                        ]}
                        initialValue={
                          companyData?.registered_since
                            ? moment(companyData?.registered_since)
                            : moment()
                        }
                      >
                        <DatePicker
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          format={"MM/DD/YYYY"}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">USDOT</span>
                      <Form.Item
                        name="usdot"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your USDOT!",
                          },
                        ]}
                        initialValue={companyData?.usdot}
                      >
                        <NumberFormat
                          className="ant-input-number numberictest"
                          format="##########"
                          style={{ width: "100%" }}
                          placeholder="Please Enter Your USDOT"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Deposit Method</span>
                      <Form.Item
                        name="direct_deposit"
                        // onChange={this.getparking_location}
                        rules={[
                          {
                            required: false,
                            errorMessage: true,
                            message: "Please Enter Your Direct Deposit!",
                          },
                        ]}
                        initialValue={
                          companyData?.direct_deposit
                            ? companyData?.direct_deposit
                            : "Direct Deposit"
                        }
                      >
                        <Radio.Group
                          defaultValue={
                            companyData?.direct_deposit
                              ? companyData?.direct_deposit
                              : "Direct Deposit"
                          }
                          // onChange={(e) =>
                          //   this.setState({ supplier: e?.target?.value })
                          // }
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "left",
                            // flexWrap: "wrap",
                            // maxWidth:"100%",
                            padding: "8px",
                            border: "1px solid #e5e5e5",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                        >
                          <Radio
                            style={{ fontSize: "16px" }}
                            value={"Direct Deposit"}
                          >
                            Direct Deposit
                          </Radio>
                          <Radio style={{ fontSize: "16px" }} value={"Check"}>
                            Check
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={11}
                    >
                      <span className="title_changes">
                        Insurance Expiration Date
                      </span>
                      <Form.Item
                        name="insuranceDate"
                        rules={[
                          {
                            required: false,
                            message: "Select Insurance Exipiry Date",
                          },
                        ]}
                        initialValue={
                          companyData?.insurance_expiry
                            ? moment(companyData?.insurance_expiry)
                            : ""
                        }
                      >
                        <DatePicker
                          size="large"
                          format={"MM/DD/YYYY"}
                          // className="ant-input-number numberictest"
                          style={{ width: "100%", borderRadius: "5px" }}
                          placeholder="Please Select A Date"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Payment Terms</span>
                      <Form.Item
                        name="payment_terms"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Require!",
                          },
                        ]}
                        initialValue={paymentTermsVal}
                        // initialValue={companyData?.payment_terms}
                      >
                        <Select
                          allowClear
                          className="select_Height"
                          style={{ height: "40px", fontWeight: "300" }}
                          placeholder="Please Select Payment Type"
                          onChange={this.onChangeTerms}
                        >
                          {paymentTerms?.map((e) => (
                            <Option
                              key={e.payment_discount}
                              value={e.payment_terms}
                            >
                              {e.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* {selectedPaymentType === "Custom Input" && (
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        // span={12}
                      >
                        <span className="title_changes">
                          Payment Terms (Others)
                        </span>
                        <Form.Item
                          name="paymentTermsOthers"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter New Payment Terms",
                            },
                          ]}
                        >
                          <NumberFormat
                            className="ant-input-number numberictest"
                            style={{ width: "100%" }}
                            prefix={"Net "}
                            placeholder="Please Enter New Payment Terms"
                          />
                        </Form.Item>
                      </Col>
                    )} */}

                    {/* {companyData !== "" && (
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Carrier Status</span>
                        <Form.Item
                          name="carrier_status"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Require!",
                            },
                          ]}
                          initialValue={
                            companyData?.carrier_status
                              ? companyData?.carrier_status
                              : "Applicant"
                          }
                        >
                          <Select
                            defaultValue={"Applicant"}
                            allowClear
                            className="select_Height"
                            style={{ height: "40px", fontWeight: "300" }}
                            placeholder="Please Select Payment Type"
                          >
                            {statusData?.map((e) => (
                              <Option key={e.label} value={e.label}>
                                {e.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )} */}

                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Office Phone</span>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Office Phone!",
                          },
                        ]}
                        initialValue={companyData?.contact_number}
                      >
                        <NumberFormat
                          className="ant-input-number numberictest"
                          format="(###) ###-####"
                          style={{ width: "100%" }}
                          placeholder="Please Enter Office Phone"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Office Email</span>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Office Email!",
                          },
                          {
                            type: "email",
                            message: "The Input Is Not Valid Email",
                          },
                        ]}
                        initialValue={companyData?.email}
                      >
                        <Input
                          className="form-input"
                          placeholder="Please Enter Office Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{
                        span: 24,
                      }}
                      xl={{
                        span: 24,
                      }}
                      lg={{
                        span: 24,
                      }}
                      md={{ span: 12 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // span={11}
                    >
                      <span className="title_changes">
                        Company Mailing Address
                      </span>
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Company Mailing Address",
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          // apiKey={process.env.REACT_APP_MAPS_KEY}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            defaultInputValue: `${
                              companyData?.address ? companyData?.address : ""
                            }`,
                            citySearch,
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address);
                              this.getAddress(address);
                            },
                            // styles: {
                            //   input: (provided) => ({
                            //     ...provided,
                            //     color: 'blue',
                            //   }),
                            //   option: (provided) => ({
                            //     ...provided,
                            //     color: 'green',
                            //   }),
                            //   singleValue: (provided) => ({
                            //     ...provided,
                            //     color: 'red',
                            //   }),
                            // },
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {companyData === "" && (
                      <>
                        {contactInfo?.map((c) => (
                          <Col
                            xxl={{ span: 24 }}
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                alignItems: "center",
                              }}
                            >
                              <Popover
                                content={
                                  (c?.contact_number || c?.email) && (
                                    <Row>
                                      <Col
                                        xxl={{ span: 24 }}
                                        xl={{ span: 24 }}
                                        lg={{ span: 24 }}
                                        md={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        xs={{ span: 24 }}
                                      >
                                        <span>{c.contact_number}</span>
                                      </Col>
                                      <Col
                                        xxl={{ span: 24 }}
                                        xl={{ span: 24 }}
                                        lg={{ span: 24 }}
                                        md={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        xs={{ span: 24 }}
                                      >
                                        <span
                                          style={{
                                            color: "black",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: "left",
                                          }}
                                        >
                                          {c.email}
                                        </span>
                                      </Col>
                                    </Row>
                                  )
                                }
                              >
                                <span
                                  className="title_changes"
                                  style={{
                                    margin: "0px 5px",
                                    wordBreak: "break-word",
                                    width: "100%",
                                    maxWidth: "270px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textTransform: "capitalize",
                                    cursor: "pointer",
                                  }}
                                >
                                  {c.contactRole
                                    ? c.firstName +
                                      " " +
                                      c.lastName +
                                      ", " +
                                      c.contactRole
                                    : c.firstName + " " + c.lastName}
                                </span>
                              </Popover>
                              <Col>
                                <Popover
                                  placement="left"
                                  content={
                                    <Row className="popovergrid">
                                      <Col
                                        xxl={{ span: 24 }}
                                        xl={{ span: 24 }}
                                        lg={{ span: 24 }}
                                        md={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        xs={{ span: 24 }}
                                      >
                                        <Button
                                          style={{
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            border: "none",
                                            padding: "0px",
                                            textAlign: "left",
                                            width: "100%",
                                            height: "auto",
                                          }}
                                          onClick={() =>
                                            this.editContactInfo(c)
                                          }
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              alignItems: "center",
                                              justifyContent: "left",
                                            }}
                                          >
                                            <MdModeEditOutline
                                              style={{ marginRight: "5px" }}
                                            />
                                            Edit
                                          </span>
                                        </Button>
                                      </Col>
                                      <Col
                                        xxl={{ span: 24 }}
                                        xl={{ span: 24 }}
                                        lg={{ span: 24 }}
                                        md={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        xs={{ span: 24 }}
                                      >
                                        <Button
                                          style={{
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            border: "none",
                                            padding: "0px",
                                            textAlign: "left",
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        >
                                          <Popconfirm
                                            title="Are you sure？"
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={() =>
                                              this.deleteContactInfo(c.id)
                                            }
                                          >
                                            <span
                                              style={{
                                                color: "black",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                alignItems: "center",
                                                justifyContent: "left",
                                              }}
                                            >
                                              <MdDelete
                                                style={{ marginRight: "5px" }}
                                              />{" "}
                                              Delete
                                            </span>
                                          </Popconfirm>
                                        </Button>
                                      </Col>
                                    </Row>
                                  }
                                >
                                  <EllipsisOutlined
                                    style={{
                                      fontSize: "30px",
                                      color: "grey",
                                      marginTop: "3px",
                                    }}
                                    className="action-ellipsis-button"
                                  />
                                </Popover>
                              </Col>
                            </div>
                          </Col>
                        ))}

                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <Form.Item name="button">
                            <Button
                              type="primary"
                              style={{
                                width: "100%",
                                fontWeight: "500",
                                height: "40px",
                              }}
                              onClick={() =>
                                this.setState({
                                  isContactVisible: true,
                                  recordContactData: "",
                                })
                              }
                            >
                              ADD CONTACT
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Notes</span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Notes!",
                          },
                        ]}
                        initialValue={companyData?.notes}
                      >
                        <TextArea
                          rows={4}
                          style={{
                            width: "100%",
                            height: "75px",
                            borderColor: "#d6d6d6",
                          }}
                          className="form-input"
                          placeholder="Please Enter Your Notes"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </BaseModal>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    result: state?.reducer?.ownerOperatorsData,
    columnsData: state?.reducer?.ownerOperatorsData?.ownersData,
    loading: state.reducer?.ownerOperatorsData?.loading,
    loadingTrucks: state.reducer?.trucksListResult?.loading,
    errorLoading: state.reducer?.ownerOperatorsData?.error,
    companiesInfo: state.reducer?.companiesInfo,
    ownerRoles: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "ownerOperatorRoles"
    ),
    termsData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "carrierTerms"
    ),
    trucksData: state.reducer?.dropDownOptions?.totalData?.filter(
      (t) => t.typeofDropDown === "trucksType"
    ),
    dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
    dropDownOptions: state.reducer?.dropDownOptions,
    totalTrucksListResult: state.reducer?.trucksResult,
    trucksListInfo: state.reducer?.trucksListInfo,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchOwnerOperators: () => {
    dispatch(getOwnerOperators());
  },
  actionDeleteCompany: (id) => {
    dispatch(deleteCompany(id));
  },
  actionUpdateCompany: (id, data) => {
    dispatch(updateCompany(id, data));
  },
  actionAddCompany: (data) => {
    dispatch(addCompany(data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  // actionFetchTrucksList: () => {
  //   dispatch(getTrucksListQuery());
  // },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(OwnerOperator));
