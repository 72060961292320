import React, { Component } from "react";
import L from "leaflet";
import "leaflet.gridlayer.googlemutant";
import "leaflet/dist/leaflet.css";
import redicon from "../../Images/marker-icon.png";
import greenicon from "../../Images/PickupIcon.png";
import { withAppContext } from "../../Store/AppContext";
import moment from "moment";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  Col,
  Row,
  Button,
  Card,
  message,
  Popover,
  Select,
  Form,
  Switch,
} from "antd";
import { add, list, update } from "../../api/Apis";
import Loading from "../../Common/Loading";
import BaseModal from "../../Common/BaseModal";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
class MapDirection extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.marker = {};
    this.ID = "multimap";
    this.state = {
      directions: null,
      truckData: [],
      dragData: [],
      mapData: this.props.mapsData,
      truckPopup: false,
      truckInfo: "",
      assignMaterialId: "",
      materialData: [],
      dispacthId: "",
      loading: false,
      assignTruck: [],
      distance: "",
      duration: "",
      showQuickDispatch: false,
      showRepeatDispatch: false,
      repeatData: [],
      repeatNotes: "",
      updateProps: false,
      showAssignTrucks: [],
      orderStateDetails: [],
      waiveContract: undefined,
    };
  }

  componentDidMount() {
    let origin = {};
    origin = {
      lat: this.props?.quarryData?.dropOffLatLng?.lat,
      lng: this.props?.quarryData.dropOffLatLng?.lng,
    };
    this.dispatchMap(true, origin);
    this.props.context.getTrucksType();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props?.mapsData) !== JSON.stringify(state?.mapData) &&
      !state.updateProps
    ) {
      return {
        mapData: props.mapsData,
      };
    }
    if (
      (props.quarryData?.waiveContract || !props.quarryData?.waiveContract) &&
      state.waiveContract === undefined
    ) {
      return {
        waiveContract: props?.quarryData?.waiveContract,
      };
    }
    if (
      JSON.stringify(props.orderLeadDetails) !==
      JSON.stringify(state.orderStateDetails)
    ) {
      const sortedArray = props?.orderLeadDetails.sort((a, b) => {
        const countA = props?.orderLeadDetails.filter(
          (num) => num.truckId === a.truckId
        ).length;
        const countB = props?.orderLeadDetails.filter(
          (num) => num.truckId === b.truckId
        ).length;
        if (countB !== countA) return countB - countA;
        return a.truckId - b.truckId;
      });

      let filterData = sortedArray.filter(
        (val, ind, self) =>
          self.findIndex((e) => e.truckId === val.truckId) === ind
      );

      return {
        orderStateDetails: filterData,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    let origin = {};
    origin = {
      lat: this.props?.quarryData?.dropOffLatLng?.lat,
      lng: this.props?.quarryData.dropOffLatLng?.lng,
    };
    if (
      JSON.stringify(prevProps?.mapsData) !==
      JSON.stringify(this.props?.mapsData)
    ) {
      this.map.remove();
      this.dispatchMap(true, origin);
    }
  }

  onFinish = (assignTruck) => {
    const { assignedTrucks, dragData } = this.state;
    let assignedTruck = assignedTrucks ? assignedTrucks : [];
    assignedTruck.push(assignTruck["id"]);
    let dragedData = dragData?.length > 0 ? dragData : [];
    dragedData.push(assignTruck);
    this.marker[assignTruck?.truckId]?.remove();
    this.setState({
      truckInfo: "",
      dragData: dragedData,
      assignTruck: {},
      truckData: "",
      truckPopup: false,
      assignedTrucks: assignedTruck,
    });
  };

  getDistanceAndDuration = (s, d) => {
    this.setState({ loading: true });
    let url =
      "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
      [s?.lat, s?.lng] +
      "&origins=" +
      [d?.lat, d?.lng] +
      "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
    let milesData = {
      entityData: {
        url,
      },
    };
    add("getMiles", milesData)
      .then((res) => {
        if (res.success) {
          this.setState({
            distance: res.data.distance,
            duration: res.data.duration,
            loading: false,
            truckPopup: true,
          });
          this.formRef.current.setFieldsValue({
            material: this.state?.materialData[0]?.id,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err, "err");
      });
  };

  findRepeatedTrucksCount = (info) => {
    const { orderLeadDetails } = this.props;
    return orderLeadDetails?.filter((e) => e?.truckId === info);
  };

  setValues = (mt, m) => {
    this.formRef.current.setFieldsValue({
      material: mt?.id,
      jobNumber: m?.jobNumber,
    });
  };

  truckColorCode = (L, markerInfo, colorCode) => {
    return L.divIcon({
      html:
        "<div class='naviconmapss' style='color: " +
        colorCode +
        "; border: 1px solid " +
        colorCode +
        "; border-radius: 30px; padding:15px;width:100%;height:100%'>" +
        "<i class='fas fa-map-marker'></i>" +
        "<div class='truckid'>" +
        "<p>" +
        markerInfo?.truckId +
        "</p>" +
        "</div>" +
        "</div>",
    });
  };

  dispatchMap = async (createMap, origin) => {
    const { mapData } = this.state;
    const checkData = (d, truck, singleTruck) => {
      [this.props?.quarryData]?.map(async (m) => {
        m?.material?.map((mt) => {
          if (mt.assigned !== true) {
            if (
              m["truckType"].filter((e) => singleTruck.truck_type === e)
                .length > 0
            ) {
              singleTruck["payment_terms"] = truck.payment_terms;
              singleTruck["carrier_name"] = truck.carrier_name;
              singleTruck["contact_Info"] = truck.contactDetails;
              let materialData = [];
              materialData.push(mt);
              // assignData = true;
              this.setState({
                assignTruck: {
                  route: m,
                  truck: truck,
                  truckInfo: singleTruck,
                  materialData,
                },
                truckData: truck,
                truckInfo: singleTruck,
                materialData,
              });
              this.onFinish(singleTruck);
            } else {
              message.error(
                `Please Select Truck Type: ${m["truckType"].join(", ")}`
              );
              this.marker[singleTruck.truckId].setLatLng([
                singleTruck?.lat,
                singleTruck?.lng,
              ]);
            }
          }
          return true;
        });
      });
      // if (assignData) {
      //   this.getDistanceAndDuration(sourcePath, destinationPath);
      // }
    };

    if (createMap) {
      // markers.clearLayers();
      this.map = L.map(this.ID, {
        zoom: 12,
        center: origin,
        attributionControl: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: "topleft",
        },
      });
      var googleHybrid = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
      var googleStreets = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
      var googleTraffic = L.tileLayer(
        "https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          minZoom: 2,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );

      var baseMaps = {
        Default: googleStreets,
        Satellite: googleHybrid,
        Traffic: googleTraffic,
      };

      var overlays = {
        //add any overlays here
      };

      L.control
        .layers(baseMaps, overlays, { position: "topright" })
        .addTo(this.map);
      L.gridLayer
        .googleMutant({
          // type: "hybrid",
          maxZoom: 20,
        })
        .addTo(this.map);

      [this.props?.quarryData]?.map(async (markerInfo, index) => {
        // console.log(markerInfo,'markerInfo')
        markerInfo?.material?.map((data) => {
          // let msg =
          //   "<span class='para_linegrp'><b class='para_line'> Material -</b><p class='parapopup'>" +
          //   markerInfo?.material[0]?.value +
          //   "</p></span>"
          //     ? "<span class='para_linegrp'><b class='para_line'> Material -</b><p class='parapopup'>" +
          //       markerInfo?.material?.map((e) => e?.value) +
          //       "</p></span>"
          //     : "";
          let msg;
          if (
            markerInfo.jobType === "Trucking & Material Job" ||
            markerInfo.jobType !== "Trucking Job"
          ) {
            //msg = markerInfo.pickup ? markerInfo.pickup + "<br/>" : "";
            msg =
              "<span class='para_linegrp'><b class='para_line'> Material -</b><p class='parapopup'>" +
              markerInfo?.material[0]?.value +
              "</p></span>"
                ? "<span class='para_linegrp'><b class='para_line'> Material -</b><p class='parapopup'>" +
                  markerInfo?.material?.map((e) => e?.value) +
                  "</p></span>"
                : "";
          } else {
            msg =
              "<span class='para_linegrp'><b class='para_line'> Material -</b><p class='parapopup'>" +
              markerInfo?.material[0]?.value +
              "</p></span>"
                ? ""
                : "";
          }
          msg +=
            "<span class='para_linegrp'><b class='para_line'> Date -</b><p class='parapopup'>" +
            moment(markerInfo?.updatedAt).format("L") +
            "</p></span>";
          let waypoints = [
            L.latLng(data?.pickUpLatLng?.lat, data?.pickUpLatLng?.lng),
            L.latLng(
              markerInfo["dropOffLatLng"]["lat"],
              markerInfo["dropOffLatLng"]["lng"]
            ),
          ];

          let icon = L.icon({
            iconUrl: redicon,
            iconSize: [20, "auto"],
            iconAnchor: [8.5, 32],
            popupAnchor: [0, -32],
          });
          const icon1 = L.icon({
            iconUrl: greenicon,
            iconSize: [20, "auto"],
            iconAnchor: [8.5, 32],
            popupAnchor: [0, -32],
          });
          var message = [
            "<span class='para_linegrp'><b class='para_line'> PickUp -</b><p class='parapopup'>" +
              markerInfo?.material?.map((e) => e?.pickUpAddress) +
              "</p></span>",
            "<span class='para_linegrp'><b class='para_line'> DropOff -</b><p class='parapopup'>" +
              markerInfo.dropoff +
              "</p></span>",
          ];
          L.Routing.control({
            waypoints,
            plan: L.Routing.plan(waypoints, {
              createMarker: function (i, wp) {
                return L.marker(wp.latLng, {
                  draggable: false,
                  icon: i === 0 ? icon1 : icon,
                }).bindPopup(
                  "<span style='font-weight:bold;'>" +
                    message[i] +
                    msg +
                    "<span>"
                );
              },
              routeWhileDragging: true,
            }),

            lineOptions: {
              styles: [{ color: "#6FA1EC", weight: 6 }],
            },
            show: false,
            addWaypoints: false,
            routeWhileDragging: false,
            draggableWaypoints: false,
            fitSelectedRoutes: false,
            showAlternatives: false,

            routeLine: function (route) {
              var line = L.Routing.line(route, {
                addWaypoints: false,
                routeWhileDragging: false,
                autoRoute: true,
                useZoomParameter: false,
                draggableWaypoints: false,
              });
              line.eachLayer(function (l) {});
              return line;
            },
          }).addTo(this.map);
          return true;
        });
        //.bindPopup("<span style='font-weight:bold;'>" + msg + "<span>");
      });
      if (mapData?.length > 0) {
        mapData?.map(async (m) => {
          if (
            m?.carrierDetails?.length > 0 &&
            m.showCarrier !== false &&
            m.banCarrier !== false
          ) {
            m.carrierDetails.map((markerInfo) => {
              // "<p class='parapopup'><b class='para_line'>Truck Details: </b>";
              let msg = "";
              msg += markerInfo.truckId
                ? "<p class='parapopup'><b class='para_line'>TNB" +
                  markerInfo?.truckId +
                  "," +
                  markerInfo?.truck_code +
                  "&nbsp;&nbsp; " +
                  markerInfo?.carrier_name +
                  "</b></p>"
                : "";

              m.contactDetails?.map(
                (c) =>
                  c?.isPrimaryContact &&
                  (msg += c?.contact_name
                    ? "<p class='parapopup'>" +
                      c?.contact_name +
                      "&nbsp;&nbsp; " +
                      c?.contact_number +
                      "</p>"
                    : "")
              );

              msg +=
                "<p class='parapopup' style='width:100%;justify-content: center;'><b class='para_line' style='width:100%;justify-content: center;'>Current Dispatch</b></p>";
              msg +=
                "<p class='parapopupjob'>Job " +
                this.props?.quarryData?.jobNumber +
                "</p>";

              let marker = new L.marker(
                [markerInfo["lat"], markerInfo["lng"]],
                {
                  draggable: this.props.mapStatus ? false : true,
                  icon:
                    markerInfo.truck_type === "Round Bottom"
                      ? this.truckColorCode(L, markerInfo, "#fd7f6f")
                      : markerInfo.truck_type === "End Dump"
                      ? this.truckColorCode(L, markerInfo, "#7eb0d5")
                      : markerInfo.truck_type === "Belly Dump"
                      ? this.truckColorCode(L, markerInfo, "#b2e061")
                      : markerInfo.truck_type === "Super Dump"
                      ? this.truckColorCode(L, markerInfo, "#bd7ebe")
                      : markerInfo.truck_type === "15+ Ton Dump Truck"
                      ? this.truckColorCode(L, markerInfo, "#ffb55a")
                      : markerInfo.truck_type === "10+ Ton Dump Truck"
                      ? this.truckColorCode(L, markerInfo, "#ffee65")
                      : markerInfo.truck_type === "Flatbed"
                      ? this.truckColorCode(L, markerInfo, "#beb9db")
                      : markerInfo.truck_type === "Stepdeck"
                      ? this.truckColorCode(L, markerInfo, "#fdcce5")
                      : markerInfo.truck_type === "Heavy Haul"
                      ? this.truckColorCode(L, markerInfo, "#8bd3c7")
                      : this.truckColorCode(L, markerInfo, "#000"),
                }
              )
                // .addTo(this.map)
                .bindPopup("<span style='font-weight:bold;'>" + msg + "</span>")
                // .addTo(markers);
                .addTo(this.map);
              // this.map.addLayer(markers);
              // .bindPopup("<span style='font-weight:bold;'>" + msg + "<span>");
              this.marker[markerInfo["truckId"]] = marker;

              marker.on("dragend", function (e) {
                var marker = e.target;
                var position = marker.getLatLng();
                checkData(position, m, markerInfo);
              });
              return true;
            });
          }
        });
      }
    } else {
      this.map.eachLayer((layer) => {
        if (layer.feature !== undefined) {
          this.map.removeLayer(layer);
        }
      });
    }
  };

  deleteTruck = (truck, singletruck) => {
    let { dragData } = this.state;
    let newData = [];
    dragData?.map((d) => {
      if (d?.truckInfo?.truckId !== singletruck?.truckId) {
        newData.push(d);
      }
      return true;
    });
    this.setState({
      dragData: newData,
    });
    this.marker[singletruck.truckId].addTo(this.map);
    this.marker[singletruck.truckId].setLatLng([
      singletruck?.lat,
      singletruck?.lng,
    ]);
  };

  submitMap = () => {
    const { dragData, repeatNotes, waiveContract } = this.state;
    let updateData = this.props.quarryData?.material[0];

    if (dragData?.length > 0) {
      [updateData]?.forEach((element) => {
        element["assignTrucks"] = [];
        dragData?.forEach((e) => {
          element["assignTrucks"].push(e.truckId);
        });
        element["assignTrucksInfo"] = dragData;
        element["assigned"] = true;
      });
      this.setState({
        loading: true,
      });

      let data = {
        entityData: {
          material: [updateData],
          assigned: true,
          dispatchNotes: repeatNotes,
          dispatchStatus: "Completed",
          dispatchCompletedDate: moment().format("l"),
          waiveContract,
        },
      };
      update("orders", this.props?.quarryData?.id, data)
        .then((res) => {
          if (res.success) {
            const { dragData } = this.state;
            let truckNums = dragData?.map((d) => d?.truckId);
            this.props.handleCancel();
            message.info(
              `${truckNums?.map((e) => e)} trucks are assigned to Job ${
                this.props?.quarryData?.jobNumber
              }`
            );
            this.setState({ truckPopup: false, loading: false });
          } else {
            this.props.handleCancel();
            this.setState({ truckPopup: false, loading: false });
          }
        })
        .catch((err) => {
          console.log(err);
          this.props.handleCancel();
        });
    } else {
      message.error("Please assign any one truck!");
      this.setState({
        loading: false,
      });
    }
  };

  onCancelModal = () => {
    this.props.onCancel(false);
  };

  quickDispatch = (values) => {
    const { mapsData } = this.props;
    let truckInfo = [];
    mapsData?.forEach((f) => {
      f?.carrierDetails?.forEach((e) => {
        if (values?.assignTrucks?.includes(e?.id)) {
          e["payment_terms"] = f.payment_terms;
          e["carrier_name"] = f.carrier_name;
          e["contact_Info"] = f?.contactDetails;
          truckInfo?.push(e);
          this.marker[e?.truckId]?.remove();
        }
      });
    });
    this.setState({
      dragData: truckInfo,
      showQuickDispatch: false,
      assignedTrucks: values["assignTrucks"],
      showRepeatDispatch: false,
      repeatNotes: values["dispatchNotes"]
        ? values["dispatchNotes"]
        : this.props?.quarryData["dispatchNotes"],
    });
  };

  showRepeatDispatch = () => {
    const { quarryData, totalMapData } = this.props;

    let filter = [];

    if (quarryData?.originalJobId) {
      list("leads", { originalJobId: quarryData?.originalJobId }).then(
        (res) => {
          if (res.success) {
            let repeatData = [];
            let sortedList = res.data?.filter(
              (element) =>
                element.dispatchStatus === "Completed" &&
                element?.id !== quarryData?.id
            );
            sortedList = sortedList.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            [sortedList[0]]?.forEach((element) => {
              element?.material[0]?.assignTrucksInfo?.forEach((e) => {
                repeatData?.push(e?.id);
              });

              let truckTypeData = quarryData?.truckType.concat(
                element?.truckType
              );
              totalMapData.map((m) => {
                let newCarrier = m.carrierDetails.filter(
                  (t) =>
                    truckTypeData?.filter(
                      (c) => String(t?.truck_type) === String(c)
                    ).length > 0
                );
                if (newCarrier.length > 0) {
                  let filterObj = m;
                  filterObj["carrierDetails"] = newCarrier;
                  filter.push(filterObj);
                }
                return true;
              });
              this.setState({
                repeatData,
                repeatNotes: element?.dispatchNotes,
                assignedTrucks: [],
                updateProps: true,
                mapData: filter,
              });
            });

            if (repeatData?.length > 0) {
              this.setState({
                showRepeatDispatch: true,
              });
            } else {
              message.error("No Previous Dispatch Data!");
            }
          }
        }
      );
    } else {
      message.error("No Previous Dispatch Data!");
      this.setState({
        repeatData: [],
        showRepeatDispatch: false,
      });
    }
  };

  removeTruckData = (value) => {
    let assignedTrucks = [],
      data = [];
    const { dragData } = this.state;
    dragData?.forEach((e) => {
      if (e?.truckId !== value?.truckId) {
        data.push(e);
        assignedTrucks?.push(e?.id);
      }
    });
    this.setState({
      assignedTrucks,
      dragData: data,
    });
  };

  handleQuickDispatch = () => {
    const { mapData } = this.state;
    const newMapData = mapData?.map((e) => e?.carrierDetails)?.flat();
    const showAssignTrucks = newMapData.filter(
      (val, ind, self) =>
        self.findIndex((e) => e.truckId === val.truckId) === ind
    );
    this.setState({
      showQuickDispatch: true,
      showAssignTrucks: showAssignTrucks?.sort((a, b) =>
        a?.truck_type?.toLowerCase() > b?.truck_type?.toLowerCase() ? 1 : -1
      ),
    });
  };

  render() {
    const {
      dragData,
      showQuickDispatch,
      showRepeatDispatch,
      mapData,
      loading,
      assignedTrucks,
      repeatData,
      repeatNotes,
      showAssignTrucks,
      orderStateDetails,
      waiveContract,
    } = this.state;
    const { mapStatus, quarryData, requestDispatch } = this.props;
    return (
      <>
        <Loading enableLoading={loading} />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {!requestDispatch && (
            <Row className="dispatch-tool-map">
              {!mapStatus && (
                <>
                  <Col>
                    <Button
                      style={{
                        borderRadius: "5px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        margin: "0px 5px",
                      }}
                      className="table-font-mobile-accounts"
                      onClick={this.handleQuickDispatch}
                    >
                      <span className="table-font-mobile-accounts">
                        Quick Dispatch
                      </span>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{
                        borderRadius: "5px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        margin: "0px 5px",
                      }}
                      onClick={this.showRepeatDispatch}
                    >
                      <span className="table-font-mobile-accounts">
                        Repeat Dispatch
                      </span>
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          )}

          {requestDispatch ? (
            <Row style={{ width: "100%" }}>
              <Col
                xxl={{ span: 11 }}
                xl={{ span: 11 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ minHeight: "250px" }}
              >
                {this.props.mapsData && (
                  <div id={this.ID} style={{ height: "100%" }} />
                )}
              </Col>

              <Col
                xxl={{ span: 13 }}
                xl={{ span: 13 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Card
                  style={{
                    minHeight: "250px",
                    textAlign: "left",
                  }}
                  bodyStyle={{ padding: "10px" }}
                >
                  <Row>
                    <Col
                      xxl={24}
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ margin: "10px 0px" }}
                    >
                      <div className="scrlchk" style={{ height: "100%" }}>
                        {[quarryData]?.map((d) => (
                          <Row style={{ width: "100%" }}>
                            <Col
                              xxl={24}
                              xl={24}
                              lg={24}
                              md={24}
                              sm={24}
                              xs={24}
                            >
                              <Row style={{ width: "100%" }}>
                                <Col
                                  xxl={12}
                                  xl={12}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                >
                                  <div className="mapbox-1view">
                                    <h5>Customer </h5>
                                    <p>{d?.companyName}</p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Job Name </h5>
                                    <p>{d?.jobName}</p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Job# </h5>
                                    <p>{d?.jobNumber}</p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Job Type </h5>
                                    <p>{d?.jobType}</p>
                                  </div>
                                </Col>
                                <Col
                                  xxl={12}
                                  xl={12}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                >
                                  <div className="mapbox-1view">
                                    <h5 className="dispatch-data">
                                      Jobsite Contact{" "}
                                    </h5>
                                    <p>{d?.company}</p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5 className="dispatch-data">
                                      Contact Title{" "}
                                    </h5>
                                    <p>
                                      {d?.multipleContacts?.length > 0 &&
                                        d?.multipleContacts[0]?.firstName +
                                          " " +
                                          d?.multipleContacts[0]?.lastName}
                                    </p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5 className="dispatch-data">
                                      Contact Phone{" "}
                                    </h5>
                                    <p>
                                      {d?.multipleContacts?.length > 0 &&
                                        d?.multipleContacts[0]?.phone}
                                    </p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5 className="dispatch-data">
                                      {" "}
                                      Contact Email{" "}
                                    </h5>
                                    <p>
                                      {d?.multipleContacts?.length > 0 &&
                                        d?.multipleContacts[0]?.email}
                                    </p>
                                  </div>
                                </Col>
                                {d?.material?.map((e) => (
                                  <Col
                                    xxl={24}
                                    xl={24}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                  >
                                    <Popover content={e.pickUpAddress}>
                                      <div className="mapbox-1view">
                                        <h5>Pickup </h5>
                                        <Popover content={e.vendor}>
                                          <p className="vendor-design">
                                            {e.vendor}
                                          </p>
                                        </Popover>
                                        <p className="adress-design">
                                          {e.pickUpAddress}
                                        </p>
                                      </div>
                                    </Popover>
                                  </Col>
                                ))}
                                <Col
                                  xxl={24}
                                  xl={24}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                >
                                  <Popover content={d.dropoff}>
                                    <div className="mapbox-1view">
                                      <h5>DropOff </h5>
                                      <p>Job# {d?.jobNumber}</p>
                                      <p className="adress-design">
                                        {d?.dropoff}
                                      </p>
                                    </div>
                                  </Popover>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              xxl={24}
                              xl={24}
                              lg={24}
                              md={24}
                              sm={24}
                              xs={24}
                              style={{
                                fontSize: "15px",
                                marginTop: "5px",
                              }}
                            >
                              <b
                                style={{
                                  marginBottom: "0px",
                                  width: "100%",
                                }}
                                className="notes-dispatch"
                              >
                                Job Notes:
                              </b>
                              <div
                                style={{
                                  minHeight: "75px",
                                  borderRadius: "3px",
                                  padding: "6px",
                                  width: "100%",
                                  border: "1px solid #ddd",
                                }}
                                className="detail-ans"
                              >
                                {repeatNotes
                                  ? repeatNotes
                                  : d?.dispatchNotes && d?.dispatchNotes}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <>
              <Col
                xxl={{ span: 11 }}
                xl={{ span: 11 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ minHeight: "595px" }}
              >
                {this.props.mapsData && (
                  <div id={this.ID} style={{ height: "100%" }} />
                )}
              </Col>

              <Col
                xxl={{ span: 13 }}
                xl={{ span: 13 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                {[quarryData]?.map((d) => (
                  <h1 style={{ textAlign: "center", marginBottom: "0px" }}>
                    {d?.companyName}
                  </h1>
                ))}
                <Card
                  style={{
                    minHeight: "595px",
                    textAlign: "left",
                  }}
                  bodyStyle={{ padding: "10px" }}
                >
                  <Row>
                    <Col xxl={7} xl={12} lg={12} md={24} sm={24} xs={24}>
                      <Card
                        className="maptitlecard"
                        bodyStyle={{
                          padding: "0px",
                        }}
                        style={{
                          height: "92%",
                          overflow: "scroll",
                          border: "1px solid black",
                        }}
                      >
                        <h4
                          className="headingdispatcher"
                          style={{
                            fontWeight: "bold",
                            marginLeft: "7vh",
                            borderBottom: "1px solid black",
                          }}
                        >
                          Trucks Assigned
                        </h4>
                        <Row style={{ width: "100%" }}>
                          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            {mapStatus
                              ? quarryData?.material[0]?.assignTrucksInfo
                                  ?.length > 0 &&
                                quarryData?.material[0]?.assignTrucksInfo?.map(
                                  (e) => (
                                    <div className="mapbox-trucklist">
                                      <p>{"TNB" + e?.truckId}</p>
                                      <h6>{e?.truck_code}</h6>
                                      <p>{e?.carrier_name}</p>
                                    </div>
                                  )
                                )
                              : dragData?.length > 0 &&
                                dragData?.map((e) => (
                                  <div className="mapbox-trucklist">
                                    <p>{"TNB" + e?.truckId}</p>
                                    <h6>{e?.truck_code}</h6>
                                    <p>{e?.carrier_name}</p>
                                    <CloseCircleOutlined
                                      onClick={() => this.removeTruckData(e)}
                                      style={{
                                        color: "#8d9ca6",
                                        fontSize: "14px",
                                        marginLeft: "auto",
                                      }}
                                    />
                                  </div>
                                ))}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col
                      xxl={17}
                      xl={12}
                      lg={12}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ margin: "10px 0px" }}
                    >
                      <div className="scrlchk design">
                        {[quarryData]?.map((d) => (
                          <Row style={{ width: "100%" }}>
                            <Col
                              xxl={24}
                              xl={24}
                              lg={24}
                              md={24}
                              sm={24}
                              xs={24}
                            >
                              <Row style={{ width: "100%" }}>
                                <Col
                                  xxl={12}
                                  xl={12}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                >
                                  <div className="mapbox-1view">
                                    <h5>Title</h5>
                                    <p>
                                      {d?.accountsPayableContact?.map(
                                        (e) => e.contactRole
                                      )}
                                    </p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Job Name </h5>
                                    <p>{d?.jobName}</p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Job# </h5>
                                    <p>{d?.jobNumber}</p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Order Size</h5>
                                    <p>
                                      {d.material?.length > 0
                                        ? d?.trucksRequested +
                                          " " +
                                          d?.trucksRequestedType
                                        : "N/A"}
                                    </p>
                                  </div>

                                  <div className="mapbox-1view">
                                    <h5>Miles </h5>
                                    <p>{d?.distance}</p>
                                  </div>
                                </Col>
                                <Col
                                  xxl={12}
                                  xl={12}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                  className="tool-align"
                                >
                                  <div className="tooltip-container">
                                    <div className="mapbox-1view">
                                      <h5 className="con-change">Contact</h5>
                                      <p className="ellipsis-text">
                                        {d?.companyName}
                                      </p>
                                      <div className="tooltip">
                                        <p key={d?.companyName}>
                                          {d?.companyName}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Phone #</h5>
                                    <p>
                                      {d?.accountsPayableContact?.map(
                                        (e) => e.phone
                                      )}
                                    </p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5> Email</h5>
                                    <p>
                                      {d?.accountsPayableContact?.map(
                                        (e) => e.email
                                      )}
                                    </p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Material </h5>
                                    <p>
                                      {d?.jobType !== "Trucking Job"
                                        ? d.material?.map(
                                            (e) => e.value.split(" - ")[2]
                                          )
                                        : d?.material[0]?.materialName}
                                    </p>
                                  </div>
                                  <div className="mapbox-1view">
                                    <h5>Minutes</h5>
                                    <p>{d?.duration}</p>
                                  </div>
                                </Col>
                                {d?.material?.map((e) => (
                                  <Col
                                    xxl={24}
                                    xl={24}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    key={e.id}
                                  >
                                    <div className="mapbox-1view">
                                      <h5>Pickup </h5>
                                      <Popover content={e.vendor}>
                                        <p className="align-vendors">
                                          {e.vendor}
                                        </p>
                                      </Popover>
                                      <Popover content={e.pickUpAddress}>
                                        <p className="adress-design">
                                          {e.pickUpAddress}
                                        </p>
                                      </Popover>
                                    </div>
                                  </Col>
                                ))}

                                <Col
                                  xxl={24}
                                  xl={24}
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                >
                                  <Popover content={d.dropoff}>
                                    <div className="mapbox-1view">
                                      <h5>DropOff </h5>
                                      <p>Job# {d?.jobNumber}</p>
                                      <p className="adress-design">
                                        {d?.dropoff}
                                      </p>
                                    </div>
                                  </Popover>
                                </Col>
                                {(d?.material[0]?.truckingCostType === "Hour" ||
                                  d?.material[0]?.truckingPriceType ===
                                    "Hour") && (
                                    <>
                                      <Col
                                        xxl={24}
                                        xl={24}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                      >
                                        <span
                                          className="title_changes"
                                          style={{
                                            paddingLeft: "10px",
                                            fontWeight: 600,
                                          }}
                                        >
                                          Waive Contract Hourly Minimum{" "}
                                        </span>
                                        <Switch
                                          checkedChildren={"Yes"}
                                          unCheckedChildren={"No"}
                                          onChange={(e) =>
                                            this.setState({ waiveContract: e })
                                          }
                                          checked={waiveContract}
                                        />
                                      </Col>
                                      {waiveContract !== undefined && (
                                        <span
                                          className="title_changes"
                                          style={{
                                            marginBottom: "10px",
                                            paddingLeft: "10px",
                                            fontWeight: "600",
                                            color: "red",
                                          }}
                                        >
                                          {waiveContract
                                            ? `You have removed the ${d?.material[0]?.hourlyCharge} hour minimum for this dispatch request`
                                            : `Hourly Minimum of ${d?.material[0]?.hourlyCharge} hours will be applied to this dispatch`}
                                        </span>
                                      )}
                                    </>
                                  )}
                                <Col
                                  xxl={24}
                                  xl={24}
                                  style={{
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                  }}
                                  className="notes-dispatch"
                                >
                                  <div className="mapbox-1views">
                                    <h5>Approved Truck Types</h5>
                                  </div>
                                  <div className="typematerials1">
                                    {this.props.context.trucksData?.map(
                                      (t) =>
                                        d?.truckType?.includes(t.value) && (
                                          <Col
                                            xxl={{ span: 8 }}
                                            xl={{ span: 8 }}
                                            lg={{ span: 8 }}
                                            md={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            xs={{ span: 24 }}
                                          >
                                            <div
                                              className="itemmaterial"
                                              value={t.value}
                                            >
                                              <CheckCircleOutlined
                                                style={{
                                                  color: "green",
                                                  fontSize: "14px",
                                                }}
                                              />
                                              <span
                                                style={{
                                                  // color: "#8d9ca6",
                                                  marginLeft: "5px",
                                                  fontWeight: "500",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {t.value}
                                              </span>
                                            </div>
                                          </Col>
                                        )
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              xxl={24}
                              xl={24}
                              lg={24}
                              md={24}
                              sm={24}
                              xs={24}
                              style={{
                                fontSize: "15px",
                                marginBottom: "20px",
                              }}
                            >
                              <b
                                style={{
                                  marginBottom: "0px",
                                  width: "100%",
                                  marginLeft: "12px",
                                }}
                                className="notes-dispatch"
                              >
                                Notes for Dispatcher:
                              </b>
                              <div
                                style={{
                                  minHeight: "75px",
                                  borderRadius: "3px",
                                  padding: "6px",
                                  width: "95%",
                                  marginLeft: "12px",
                                  border: "1px solid black",
                                }}
                                className="detail-ans"
                              >
                                {repeatNotes
                                  ? repeatNotes
                                  : d?.dispatchNotes && d?.dispatchNotes}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Col>
                    <Col
                      xxl={24}
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ marginTop: "-20px" }}
                    >
                      {orderStateDetails?.length > 0 && (
                        <Col
                          xxl={24}
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          style={{
                            fontSize: "15px",
                            marginBottom: "20px",
                          }}
                        >
                          <Row>
                            <div
                              style={{ overflowX: "auto", width: "100%" }}
                              className="twistedtable"
                            >
                              <table style={{ width: "100%" }}>
                                <thead>
                                  <tr>
                                    <th>Truck #</th>
                                    <th>Type</th>
                                    <th>Contact</th>
                                    <th>Number</th>
                                    <th>Deadhead</th>
                                    <th>Date</th>
                                    <th>Experience</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {orderStateDetails?.map(
                                    (data, index) =>
                                      !assignedTrucks?.includes(data.id) && (
                                        <tr style={{ cursor: "pointer" }}>
                                          <td
                                            onClick={() => this.onFinish(data)}
                                          >
                                            {"TNB " + data?.truckId}
                                          </td>
                                          <td>{data?.truck_code}</td>
                                          <td>
                                            {data?.contact_Info?.some(
                                              (e) => e.isPrimaryContact
                                            )
                                              ? data?.contact_Info?.find(
                                                  (e) => e.isPrimaryContact
                                                )?.contact_name
                                              : data?.contact_Info?.[0]
                                                  ?.contact_name}
                                          </td>
                                          <td>
                                            {data?.contact_Info?.some(
                                              (e) => e.isPrimaryContact
                                            )
                                              ? data?.contact_Info?.find(
                                                  (e) => e.isPrimaryContact
                                                )?.contact_number
                                              : data?.contact_Info?.[0]
                                                  ?.contact_number}
                                          </td>
                                          <td>
                                            {data?.distance !== 0 &&
                                              data?.distance +
                                                ", " +
                                                data?.duration}
                                          </td>
                                          <td>{data?.dispatchCompletedDate}</td>
                                          <td>
                                            {
                                              this.findRepeatedTrucksCount(
                                                data.truckId
                                              )?.length
                                            }
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Row>
                        </Col>
                      )}
                    </Col>
                    <div className="footerdispatch">
                      <Button
                        style={{
                          height: "35px",
                          width: "125px",
                          borderRadius: "5px",
                          marginRight: "25px",
                        }}
                        htmlType="reset"
                        onClick={this.onCancelModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          height: "35px",
                          width: "125px",
                          borderRadius: "5px",
                        }}
                        onClick={this.submitMap}
                        loading={loading}
                      >
                        {"Assign Truck"}
                      </Button>
                    </div>
                  </Row>
                </Card>
              </Col>
            </>
          )}

          {showQuickDispatch && (
            <BaseModal
              title={"Quick Dispatch"}
              onCancel={() => {
                this.setState({
                  showQuickDispatch: false,
                });
              }}
              formId="assignTruck"
              className="antmodal_grid headermodal"
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "25%"
                  : "500px"
              }
            >
              <Form onFinish={this.quickDispatch} id={"assignTruck"}>
                <Col span={24}>
                  <span
                    className="title_changes"
                    style={{ marginBottom: "10px" }}
                  >
                    Assign Trucks
                  </span>

                  <Form.Item
                    name="assignTrucks"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Trucks ",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                    className="addontext"
                    initialValue={assignedTrucks}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        fontWeight: "300",
                        minHeight: "40px",
                      }}
                      placeholder="Please Select Your Type"
                      className="select_multiplequarytol"
                      filterOption={(input, option) =>
                        option?.labelNew
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        option?.key.indexOf(input) > -1
                      }
                    >
                      {showAssignTrucks?.map((c) => (
                        <Option
                          value={c?.id}
                          key={c?.truckId}
                          labelNew={c?.carrier_name}
                        >
                          {`TNB${c?.truckId} - ${c?.truck_type} - ${c?.carrier_name}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Form>
            </BaseModal>
          )}

          {showRepeatDispatch && repeatData?.length > 0 && (
            <BaseModal
              title={"Repeat Dispatch"}
              onCancel={() => {
                this.setState({
                  showRepeatDispatch: false,
                });
              }}
              formId="assignTruck"
              className="antmodal_grid headermodal"
              width={
                window.screen.width > "501" && window.screen.width <= "991"
                  ? "25%"
                  : "500px"
              }
            >
              <Form onFinish={this.quickDispatch} id={"assignTruck"}>
                <Col span={24}>
                  <span
                    className="title_changes"
                    style={{ marginBottom: "10px" }}
                  >
                    Assigned Trucks
                  </span>
                  <Form.Item
                    name="assignTrucks"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Trucks ",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                    className="addontext"
                    initialValue={repeatData}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        fontWeight: "300",
                        minHeight: "40px",
                      }}
                      placeholder="Please Select Your Type"
                      className="select_multiplequarytol"
                      filterOption={(input, option) =>
                        option?.labelNew
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        option?.key.indexOf(input) > -1
                      }
                    >
                      {mapData?.map((e) =>
                        e?.carrierDetails?.map((c) => (
                          <Option
                            value={c?.id}
                            key={c?.truckId}
                            labelNew={c?.carrier_name}
                            // label={e?.id}
                          >
                            {`TNB${c?.truckId} - ${c?.truck_type} - ${c?.carrier_name}`}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginTop: "1vw" }}>
                  <span className="title_changes">Dispatch Notes</span>
                  <Form.Item
                    name="dispatchNotes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Dispatch Notes!",
                      },
                    ]}
                    initialValue={repeatNotes}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Dispatch Notes"
                    />
                  </Form.Item>
                </Col>
              </Form>
            </BaseModal>
          )}
        </div>
      </>
    );
  }
}

export default withAppContext(MapDirection);
