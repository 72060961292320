import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../../Store/AppContext";
import { connect } from "react-redux";
import {
  addTicket,
  deleteTicket,
  generateInvoice,
  getDispatch,
  getDropDownOptions,
  getTickets,
  getTrucksList,
  getInvoices,
} from "../../../Redux/Actions/actions";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  Pagination,
  Progress,
  Collapse,
} from "antd";
import moment from "moment";
import Filter from "../../../Common/Filter";
import AOS from "aos";
import Loading from "../../../Common/Loading";
import BaseModal from "../../../Common/BaseModal";
import FinanceTicketProcessing from "./FinanceTicketProcessing";
import Nodata from "../../nodata";
import "aos/dist/aos.css";
import { BsArrowLeftShort } from "react-icons/bs";
import { CheckOutlined, ArrowRightOutlined } from "@ant-design/icons";
import RejectUpload from "../Ticket Upload/RejectUpload";
import { IoFilterSharp } from "react-icons/io5";
import { list } from "../../../api/Apis";
import DataInputTickets from "./DataInputTickets";

const { RangePicker } = DatePicker;
const { Option } = Select;

class TicketProcessing extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      totalTicketsData: [],
      assignedJobs: [],
      filterData: [],
      showInvoiceModal: false,
      invoiceDate: "",
      visible: false,
      selectedTickets: [],
      minValue: 0,
      maxValue: 5,
      minjobValue: 0,
      maxjobValue: 20,
      minCompanyValue: 0,
      maxCompanyValue: 20,

      currentPage: 1,
      perpageSize: 5,
      jobcurrentPage: 1,
      jobperpageSize: 20,
      companyCurrentPage: 1,
      companyPerpageSize: 20,
      showApproved: true,
      showProcesssed: true,
      invoiceType: "",
      selectedTicketsInfo: [],
      showFinace: false,
      jobInfoData: {},
      groupedData: {},
      filterGroupedData: {},
      datesVal: null,
      value: null,
      filteredJobs: [],
      individualOrder: {},
      showSingleOrder: false,
      trucksResult: [],
      rejectTicketsData: [],
      showRejected: false,
      individualCompanyData: {},
      individualJobData: {},
      showSingleCompany: false,
      ClickCard: "",
      checkedCard: false,
      ticketVerifiedCount: 0,
      showDataInput: false,
      dataInputTicketsData: [],
      showSingleJob: false,
      totalTrucksData: [],
      invoiceData: [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Ticket Processing";
    const {
      actionFetchGetTickets,
      actiongetDropDownOptions,
      actionFetchTrucksList,
      actionFetchGetDispatch,
      actionFetchGetInvoices,
    } = this.props;
    actionFetchGetTickets();
    actionFetchGetDispatch();
    actionFetchTrucksList();
    actiongetDropDownOptions();
    actionFetchGetInvoices();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate = async (prevProps) => {
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    const { dropDownOptions, totalResult, invoiceResult } = this.props;
    const { jobInfoData, dateRestriction } = this.state;
    if (
      prevProps?.totalResult?.success === false &&
      totalResult?.success === true
    ) {
      const ticketData = this.props?.totalResult?.ticketsData?.filter(
        (e) => e.uploadStatus === "Closed"
      );
      const rejectTicketsData = this.props?.totalResult?.ticketsData?.filter(
        (e) => e.uploadStatus === "Open"
      );
      const dataInputTicketsData = ticketData?.filter(
        (e) => e.ticketVerification === "Open"
      );
      let selectedTicketsInfo = ticketData?.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.companyId === value.companyId &&
              t?.ticketVerification === "Approved"
          )
      );

      let data = ticketData?.sort(
        (a, b) =>
          new Date(moment(b?.uploadDate).format("L")) -
          new Date(moment(a?.uploadDate).format("L"))
      );

      if (data?.length > 0) {
        let groupedData = this.groupedByDate(data);
        if (Object.values(jobInfoData)?.length > 0 && dateRestriction !== "") {
          let updatedGropupData = await this.getRolesInfo(userData, data);
          if (updatedGropupData?.length > 0) {
            groupedData = this.groupedByDate(updatedGropupData);
            if (groupedData[dateRestriction]) {
              this.showIndividualCustomer(
                dateRestriction,
                Object.values(groupedData[dateRestriction]),
                false
              );
            }
          } else {
            this.setState({
              showSingleJob: false,
              showSingleCompany: false,
            });
          }
        }
        this.init(groupedData);
        this.setState({
          rejectTicketsData,
          dataInputTicketsData,
          groupedData,
          filterGroupedData: groupedData,
          totalTicketsData: ticketData,
          filterData: ticketData,
          selectedTicketsInfo,
          selectedTickets: [],
        });
      }
    }

    if (
      prevProps?.dropDownOptions?.success === false &&
      dropDownOptions?.success === true
    ) {
      this.setState({
        trucksTypesData: this.props?.trucksTypesData[0]?.trucksData,
        ticketVerifiedCount: this.props?.ticketVerifiedCount[0]?.usersCount,
      });
    }

    if (
      prevProps?.trucksResult?.success === false &&
      this.props?.trucksResult?.success === true
    ) {
      let data = {};
      this.props?.trucksResult?.trucksListData?.forEach((e) => {
        if (e?.carrierDetails?.length > 0) {
          e?.carrierDetails?.forEach((c) => {
            if (c?.truckId !== null && c?.truckId !== undefined) {
              data[e.id] = e.id in data ? data[e.id] : [];
              data[e.id].push(parseInt(c?.truckId));
            }
          });
        }
      });
      let carrierData = [];
      let newData = this.props?.trucksResult?.trucksListData;
      if (newData?.length > 0) {
        newData?.forEach((c) => {
          if (
            c?.carrierDetails?.length > 0
            // &&
            // c.showCarrier !== false &&
            // c.banCarrier !== false
          ) {
            c?.carrierDetails?.forEach((t) =>
              carrierData?.push({
                carrierId: c?.id,
                carrier_name: t?.carrier_name,
                id: t?.id,
                truckId: t?.truckId,
                truck_code: t?.truck_code,
                truck_type: t?.truck_type,
                usdot: c?.usdot,
                contact_Info: c?.contactDetails,
                parking_location: t?.parking_location,
                insurance_expiry: c?.insurance_expiry,
                payment_terms: c?.payment_terms,
                payment_discount: c?.payment_discount,
                direct_deposit: c?.direct_deposit,
                lat: t?.lat,
                lng: t?.lng,
                secondTruckNo: t?.secondTruckNo || [],
              })
            );
          }
        });
        let removeDuplicatetrucks = carrierData?.filter(
          (record, index, self) =>
            self.findIndex((e) => e.truckId === record.truckId) === index
        );
        this.setState({
          totalTrucksData: removeDuplicatetrucks,
        });
      }
      this.setState({
        trucksResult: data,
      });
    }

    if (
      prevProps?.dispatchResult?.success === false &&
      this.props?.dispatchResult?.success === true
    ) {
      let data = this.props?.dispatchResult?.dispatchData?.filter(
        (e) =>
          "assigned" in e === true &&
          e.assigned &&
          e.dispatchStatus === "Completed"
      );
      this.setState({
        assignedJobs: data,
        useFilterData: data,
        loading: false,
      });
    }

    if (
      prevProps?.generateInvoiceInfo?.success === false &&
      this.props?.generateInvoiceInfo?.success === true
    ) {
      if (this.props.generateInvoiceInfo.data.message === "Success") {
        message.success("Invoices are getting generated. It may take time depending on the number of invoices");
        this.props?.actionFetchGetTickets();
      } else {
        message.info(this.props.generateInvoiceInfo.data.message);
      }
      this.setState({
        localLoading: false,
      });
    }

    if (
      prevProps?.invoiceResult?.success === false &&
      invoiceResult?.success === true
    ) {
      let invoiceData = invoiceResult?.invoicesData?.sort(
        (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
      );
      this.setState({
        invoiceData,
      });
    }
  };

  init = (data) => {
    const { filterGroupedData } = this.state;
    const intervalId = setInterval(() => {
      const lastDiv = document.querySelector(
        ".rc-virtual-list-holder-inner > div:last-child"
      );

      if (lastDiv) {
        const title = lastDiv.getAttribute("title");
        if (
          title ===
          `${Object.keys(data !== undefined ? data : filterGroupedData)?.length
          } / page`
        ) {
          lastDiv.style.padding = "5px 16px";
          lastDiv.innerHTML = "All / page";
        }
        clearInterval(intervalId); // Stop the interval once the content is updated
      }
    }, 300);

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  };

  getRolesInfo = async (userData, data) => {
    let tableData = [];
    let result = await list("roles", { id: userData?.userRoleInfo });
    const roleData = result?.data[0]?.otherData?.filter(
      (e) => e.title === "Finance"
    );
    const finaceInfo =
      roleData &&
      roleData[0]?.multiPannel?.filter((m) => m.title === "Ticket Processing");
    if (finaceInfo && finaceInfo[0]?.selectedOptions?.includes("Edit")) {
      tableData = data;
    } else if (
      finaceInfo &&
      finaceInfo[0]?.selectedOptions?.includes("Review")
    ) {
      tableData = data.filter((t) => t?.ticketVerification === "Review");
    } else if (
      finaceInfo &&
      finaceInfo[0]?.selectedOptions?.includes("Data Input")
    ) {
      tableData = data?.filter((element) => {
        if (element["dataInputUsers"]?.length > 0) {
          return (
            element["ticketVerification"] === "Open" &&
            !element["dataInputUsers"].includes(userData?.id)
          );
        } else {
          return element["ticketVerification"] === "Open";
        }
      });
    }
    return tableData;
  };

  handleClickOutside = (event) => {
    const { ClickCard, showInvoiceModal } = this.state;
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      ClickCard &&
      !showInvoiceModal
    ) {
      this.setState({
        newClickCard: ClickCard,
        ClickCard: "",
      });
    }
  };

  groupedByDate = (data) => {
    const groupedData = {};
    data.forEach((obj) => {
      const weekStart = new Date(obj.uploadDate);
      weekStart.setDate(weekStart.getDate() - ((weekStart.getDay() + 6) % 7)); // Monday of the current week
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6); // Sunday of the current week

      // Format the dates in "MM/DD/YYYY" format
      const formattedWeekStart = `${(weekStart.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${weekStart
          .getDate()
          .toString()
          .padStart(2, "0")}/${weekStart.getFullYear()}`;
      const formattedWeekEnd = `${(weekEnd.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${weekEnd
          .getDate()
          .toString()
          .padStart(2, "0")}/${weekEnd.getFullYear()}`;

      const weekKey = `${formattedWeekStart} - ${formattedWeekEnd}`;
      // add the object to the appropriate week in the grouped data object
      if (!groupedData[weekKey]) {
        groupedData[weekKey] = [];
      }
      groupedData[weekKey].push(obj);
    });
    return groupedData;
  };

  showIndividualJob = (companyKey, jobValue) => {
    let individualJob = {};
    jobValue.map((a) => {
      individualJob[a["jobId"]] =
        a["jobId"] in individualJob === true ? individualJob[a["jobId"]] : [];
      individualJob[a["jobId"]].push(a);
      return true;
    });
    this.init(individualJob);
    this.setState({
      individualJobData: individualJob,
      showSingleJob: true,
      individualCompanyId: companyKey,
      ClickCard: "",
      checkedCard: false,
    });
  };

  showIndividualCustomer = (jobKey, jobValue, mainCardClick) => {
    const { individualCompanyData, individualCompanyId, jobInfoData } =
      this.state;
    let individualCompany = {};
    jobValue.map((a) => {
      individualCompany[a["companyId"]] =
        a["companyId"] in individualCompany === true
          ? individualCompany[a["companyId"]]
          : [];
      individualCompany[a["companyId"]].push(a);
      return true;
    });
    if (
      !mainCardClick &&
      Object.values(jobInfoData)?.length > 0 &&
      Object.keys(individualCompanyData)?.length > 0 &&
      individualCompanyId !== ""
    ) {
      this.showIndividualJob(
        individualCompanyId,
        Object.values(individualCompany[individualCompanyId])
      );
    }
    this.init(individualCompany);
    this.setState({
      individualCompanyData: individualCompany,
      showSingleCompany: true,
      dateRestriction: jobKey,
      jobInfoData: {},
      ClickCard: "",
      checkedCard: false,
    });
  };

  handleSingleOrder = (jobKey, jobValue) => {
    this.setState({
      checkedCard: false,
      showFinace: true,
      jobInfoData: { [jobKey]: jobValue },
    });
  };

  handleApproved = () => {
    this.setState({
      showApproved: !this.state.showApproved,
    });
  };

  handlePending = () => {
    this.setState({
      showProcesssed: !this.state.showProcesssed,
    });
  };

  handleCancel = () => {
    this.setState({
      showFinace: false,
      // jobInfoData: {},
    });
    this.props?.actionFetchGetTickets();
  };

  closeFilter = () => {
    this.setState({
      visible: false,
    });
  };

  onClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.setState({
      filterVisible: false,
      visible: false,
      checkData: undefined,
    });
    this.props?.actionFetchGetTickets();
    this.closeFilter();
  };

  filterResultData = (data) => {
    const { filterData } = this.state;
    let resultData = this.findAllFilterData(filterData, data);
    const groupedData = this.groupedByDate(resultData);
    this.setState({
      groupedData,
      checkData: data,
    });
    let filterInfo = data;
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
      if (filterInfo[key] === "") {
        delete filterInfo[key];
      }
    });
    localStorage.setItem(
      "filterStorageData",
      JSON.stringify({
        filter: data,
        filterShowData: filterInfo,
      })
    );
    this.closeFilter();
  };

  findAllFilterData = (data, filterCriteria) => {
    return data?.filter((item) => {
      if (
        (!filterCriteria.companyId ||
          filterCriteria.companyId === item.companyId) &&
        (!filterCriteria.jobId || filterCriteria.jobId === item.jobId) &&
        (!filterCriteria.truckNumber ||
          filterCriteria.truckNumber === item.truckNumber)
      ) {
        return true;
      }
      return false;
    });
  };

  customersCount = (value) => {
    let filterCustomers = value?.filter(
      (val, ind, self) =>
        ind === self?.findIndex((e) => e.companyId === val.companyId)
    );
    return filterCustomers;
  };

  jobCount = (value) => {
    let filterJobs = value?.filter(
      (val, ind, self) => ind === self?.findIndex((e) => e.jobId === val.jobId)
    );
    return filterJobs;
  };

  stageCount = (value, type) => {
    let filterCustomers = value?.filter((e) => e.ticketVerification === type);
    return filterCustomers;
  };

  generateInvoice = () => {
    const { newClickCard } = this.state;
    const { selectedTickets } = this.state;
    if (selectedTickets.length > 0) {
      let companyInfo = selectedTickets?.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.companyId === value.companyId &&
              t.ticketVerification === "Approved"
          )
      );
      if (companyInfo.length === 1) {
        let data = {
          entityData: selectedTickets,
        };
        try {
          this.props?.actionFetchGenerateInvoice(data);
        } catch {
          this.setState({ loading: false });
        }
      } else {
        message.error(
          "Please select tickets from the same company and must be approved!"
        );
        this.setState({ loading: false });
        return;
      }
    } else {
      this.setState({
        ClickCard: newClickCard,
        showInvoiceModal: true,
      });
    }
  };

  handleCustomerSelect = (value) => {
    if (value) {
      const { selectedTicketsInfo } = this.state;
      const selectedCustomer = selectedTicketsInfo.find(
        (ticket) => ticket.companyId === value
      );
      this.setState({
        filteredJobs: selectedCustomer ? [selectedCustomer] : [],
        invoiceType: "Customer Name",
      });
    } else {
      this.formRef.current.setFieldsValue({
        jobId: "",
      });
      this.setState({
        filteredJobs: [],
        invoiceType: "Customer Name",
      });
    }
  };

  onFinishInvoice = (values) => {
    const {
      invoiceDate,
      invoiceType,
      ClickCard,
      groupedData,
      showSingleCompany,
    } = this.state;

    let data = {};

    if (showSingleCompany && ClickCard) {
      data = {
        entityData: {
          startDate: "",
          endDate: "",
          companyId: [ClickCard],
          invoiceType: "Customer Name",
        },
      };
    } else if (ClickCard) {
      let individualCompany = {};
      Object.values(groupedData[ClickCard]).map((a) => {
        individualCompany[a["companyId"]] =
          a["companyId"] in individualCompany === true
            ? individualCompany[a["companyId"]]
            : [];
        individualCompany[a["companyId"]].push(a);
        return true;
      });
      data = {
        entityData: {
          startDate: "",
          endDate: "",
          companyId: Object.keys(individualCompany),
          invoiceType: "Customer Name",
        },
      };
    } else {
      data = {
        entityData: {
          startDate: invoiceDate ? moment(invoiceDate[0]).format("L") : "",
          endDate: invoiceDate ? moment(invoiceDate[1]).format("L") : "",
          companyId: values["companyId"] ? [values["companyId"]] : [],
          invoiceType: invoiceType,
          jobId: values["jobId"] || null,
        },
      };
    }
    if (data.entityData.companyId.length > 0) {
      this.setState({
        showInvoiceModal: false,
        localLoading: true,
        invoiceDate: "",
        invoiceType: "",
        ClickCard: "",
        newClickCard: "",
      });
      try {
        this.props?.actionFetchGenerateInvoice(data);
      } catch {
        this.setState({
          showInvoiceModal: false,
          localLoading: true,
          invoiceDate: "",
          invoiceType: "",
          ClickCard: "",
          newClickCard: "",
        });
      }
    } else {
      this.setState({
        showInvoiceModal: false,
        invoiceDate: "",
        invoiceType: "",
        ClickCard: "",
        newClickCard: "",
      });
      message.info("There is no approved records");
    }
  };

  searchResult = (searchValue) => {
    const { totalTicketsData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = totalTicketsData.filter(
        (e) => parseInt(e["jobNumber"]) === searchValue
      );
    } else {
      searchFilter = totalTicketsData.filter(
        (e) => e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      filterData: searchFilter,
    });
  };

  onResetData = () => {
    this.props?.actionFetchGetTickets();
    this.props?.actionFetchGetDispatch();
  };

  handlePagination = async (page, pageSize, load) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    const { showSingleJob, showSingleCompany } = this.state;
    if (showSingleJob) {
      localStorage.setItem(
        "tableSizeRange",
        JSON.stringify({ ...tableSizeRange, ticketProcessJobData: pageSize })
      );
      if (page <= 1) {
        this.setState({
          minjobValue: 0,
          maxjobValue: pageSize,
        });
      } else {
        this.setState({
          minjobValue: (page - 1) * pageSize,
          maxjobValue: page * pageSize,
        });
      }
      this.setState({
        jobcurrentPage: page,
        jobperpageSize: pageSize,
      });
    } else if (showSingleCompany) {
      localStorage.setItem(
        "tableSizeRange",
        JSON.stringify({ ...tableSizeRange, ticketCompanyData: pageSize })
      );
      if (page <= 1) {
        this.setState({
          minCompanyValue: 0,
          maxCompanyValue: pageSize,
        });
      } else {
        this.setState({
          minCompanyValue: (page - 1) * pageSize,
          maxCompanyValue: page * pageSize,
        });
      }
      this.setState({
        companyCurrentPage: page,
        companyPerpageSize: pageSize,
      });
    } else {
      localStorage.setItem(
        "tableSizeRange",
        JSON.stringify({ ...tableSizeRange, ticketProcessGroupData: pageSize })
      );
      if (page <= 1) {
        this.setState({
          minValue: 0,
          maxValue: pageSize,
        });
      } else {
        this.setState({
          minValue: (page - 1) * pageSize,
          maxValue: page * pageSize,
        });
      }
      this.setState({
        currentPage: page,
        perpageSize: pageSize,
      });
    }
  };

  onOpenChange = (open) => {
    if (open) {
      this.setState({
        datesVal: [null, null],
      });
    } else {
      this.setState({
        datesVal: [null],
      });
    }
  };

  handleDateRange = (val) => {
    const { filterGroupedData } = this.state;
    this.setState({
      value: val,
    });
    if (val) {
      const filteredData = Object.keys(filterGroupedData)
        .filter((key) => {
          const startDate = new Date(key.split(" - ")[0]);
          const endDate = new Date(key.split(" - ")[1]);
          return val.some((date) => {
            const [rangeStart, rangeEnd] = val;
            return (
              (new Date(date) >= startDate && new Date(date) <= endDate) ||
              (startDate >= new Date(rangeStart) &&
                startDate <= new Date(rangeEnd)) ||
              (endDate >= new Date(rangeStart) && endDate <= new Date(rangeEnd))
            );
          });
        })
        .reduce((obj, key) => {
          obj[key] = filterGroupedData[key];
          return obj;
        }, {});

      this.setState({
        groupedData: filteredData,
      });
    } else {
      this.setState({
        groupedData: filterGroupedData,
      });
    }
  };

  ordersCount = (individualCompanyData, e) => {
    return (
      Object.values(individualCompanyData[e])?.filter(
        (val, ind, self) => self.findIndex((e) => e.jobId === val.jobId) === ind
      ) || []
    );
  };

  showInvoiceData = (info, nameVal, name) => {
    return (
      <div style={{ fontSize: "18px", color: "gray" }}>
        <div style={{ marginBottom: "1vw" }}>
          <span>
            Are you sure you would like to process invoices for the {name} of{" "}
            <b>{nameVal}</b> ?
          </span>
        </div>
        <div style={{ textDecoration: "underLine", fontWeight: "500" }}>
          To be processed:{" "}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Customers: </span>
          <b>{this.customersCount(info)?.length}</b>|<span> Unique Jobs: </span>
          <b>{this.jobCount(info)?.length}</b>|
          <span
            style={{
              color:
                this.stageCount(info, "Open")?.length > 0 ? "red" : "inherit",
            }}
          >
            Data Input:{" "}
          </span>
          <b
            style={{
              color:
                this.stageCount(info, "Open")?.length > 0 ? "red" : "inherit",
            }}
          >
            {" "}
            {this.stageCount(info, "Open")?.length}
          </b>
          |
          <span
            style={{
              color:
                this.stageCount(info, "Review")?.length > 0 ? "red" : "inherit",
            }}
          >
            Pending Review:{" "}
          </span>
          <b
            style={{
              color:
                this.stageCount(info, "Review")?.length > 0 ? "red" : "inherit",
            }}
          >
            {" "}
            {this.stageCount(info, "Review")?.length}
          </b>
          |<span> Invoices: </span>
          <b>{this.stageCount(info, "Invoiced")?.length}</b>|
          <span> Approved: </span>
          <b>{this.stageCount(info, "Approved")?.length}</b>|
          <span> Tickets: </span>
          <b>{info?.length}</b>
        </div>
      </div>
    );
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  findJobType = (value) => {
    const checkBoth = value?.find((e) => e?.othersData?.originalJobType);
    if (checkBoth) {
      return "Trucking & Material Job"; // checkBoth?.othersData?.originalJobType
    }
    return value[0]?.jobType;
  };

  weeklyDataInputTickets = (data, status, type, dateRestriction) => {
    const dataTickets = type === 'header' ? Object.values(data)[0] : data;
    let filterTickets = dataTickets?.filter(
      (each) => each.ticketVerification === status
    );
    let rejectedData =[];
    if (type === 'manager') {
      rejectedData = filterTickets?.filter((t) =>
        moment(t?.uploadDate).isBetween(
          moment(dateRestriction?.split("-")[0])
            .subtract(1, "day")
            ?.format("l"),
          moment(dateRestriction?.split("-")[1])
            .add(1, "day")
            ?.format("l")
        )
      );
    }
    this.setState({
      dataInputTicketsData: filterTickets,
      showDataInput: type !== 'manager',
      showRejected: type === 'manager',
      rejectTicketsData: rejectedData
    });
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const {
      ticketVerifiedCount,
      checkedCard,
      ClickCard,
      showRejected,
      rejectTicketsData,
      value,
      groupedData,
      jobInfoData,
      showFinace,
      visible,
      checkData,
      showInvoiceModal,
      minValue,
      maxValue,
      currentPage,
      perpageSize,
      selectedTicketsInfo,
      individualJobData,
      jobcurrentPage,
      jobperpageSize,
      minjobValue,
      maxjobValue,
      companyCurrentPage,
      companyPerpageSize,
      minCompanyValue,
      maxCompanyValue,
      showSingleJob,
      showApproved,
      showProcesssed,
      filteredJobs,
      totalTicketsData,
      dateRestriction,
      trucksResult,
      individualCompanyData,
      showSingleCompany,
      localLoading,
      trucksTypesData,
      filterData,
      showDataInput,
      dataInputTicketsData,
      totalTrucksData,
      invoiceData,
    } = this.state;
    const { loading } = this.props;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { userRoleAccess } = this.props.context;
    const roleData = userRoleAccess?.otherData?.filter(
      (e) => e.title === "Finance"
    );
    const finaceInfo =
      roleData &&
      roleData[0]?.multiPannel?.filter((m) => m.title === "Ticket Processing");

    let tableData = filterData;
    let groupedDataInfo = groupedData;
    if (finaceInfo && finaceInfo[0]?.selectedOptions?.includes("Edit")) {
    } else if (
      finaceInfo &&
      finaceInfo[0]?.selectedOptions?.includes("Review")
    ) {
      if (showApproved) {
        tableData = tableData.filter((t) => t?.ticketVerification === "Review");
      } else {
        tableData = tableData.filter(
          (t) => t?.ticketVerification === "Approved"
        );
      }
      groupedDataInfo = this.groupedByDate(tableData);
    } else if (
      finaceInfo &&
      finaceInfo[0]?.selectedOptions?.includes("Data Input")
    ) {
      if (showProcesssed) {
        tableData = tableData?.filter((element) => {
          if (element["dataInputUsers"]?.length > 0) {
            return (
              element["ticketVerification"] === "Open" &&
              !element["dataInputUsers"].includes(userDetails?.id)
            );
          } else {
            return element["ticketVerification"] === "Open";
          }
        });
      }

      groupedDataInfo = this.groupedByDate(tableData);
    }
    return (
      <div
        style={{
          background: "#fafafa",
        }}
      >
        <Loading enableLoading={loading || localLoading} />
        <Row data-aos="fade-up">
          {showFinace ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <FinanceTicketProcessing
                jobInfo={jobInfoData}
                handleCancel={this.handleCancel}
                showApproved={showApproved}
                showProcesssed={showProcesssed}
                handleApproved={this.handleApproved}
                handlePending={this.handlePending}
                dateRestriction={dateRestriction}
                trucksResult={trucksResult}
                trucksTypesData={trucksTypesData}
                ticketVerifiedCount={ticketVerifiedCount}
                totalTrucksData={totalTrucksData}
                invoiceData={invoiceData}
                getInvoiceData={() => this.props.actionFetchGetInvoices()}
              />
            </Col>
          ) : showDataInput ? (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <DataInputTickets
                jobInfo={dataInputTicketsData}
                handleCancel={() => {
                  this.setState({
                    showDataInput: false,
                  });
                  this.handleCancel();
                }}
                showApproved={showApproved}
                showProcesssed={showProcesssed}
                handleApproved={this.handleApproved}
                handlePending={this.handlePending}
                trucksResult={trucksResult}
                dateRestriction={dateRestriction}
                trucksTypesData={trucksTypesData}
                ticketVerifiedCount={ticketVerifiedCount}
                totalTrucksData={totalTrucksData}
                invoiceData={invoiceData}
              />
            </Col>
          ) : (
            <>
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                {finaceInfo &&
                  finaceInfo[0]?.selectedOptions?.includes("Edit") ? (
                  <Row className="ticket_heading_title_card" gutter={[12, 0]}>
                    <Col
                      xxl={{ span: 5 }}
                      xl={{ span: 5 }}
                      lg={{ span: 5 }}
                      md={{ span: 16 }}
                      sm={{ span: 16 }}
                      xs={{ span: 16 }}
                      style={{ margin: "12px 0px", cursor: "pointer" }}
                      onClick={() =>
                        this.weeklyDataInputTickets(
                          groupedDataInfo,
                          "Open",
                          'header'
                        )
                      }
                    >
                      <p className="owner-operator-card-body ticket_p_tag">
                        <span className="owner-operator-card-head ticket_head_span">
                          Data Input
                        </span>
                        {parseInt(
                          totalTicketsData.filter(
                            (e) => e.ticketVerification === "Open"
                          ).length
                        )}
                      </p>
                    </Col>
                    <Col
                      xxl={{ span: 5 }}
                      xl={{ span: 5 }}
                      lg={{ span: 5 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      xs={{ span: 8 }}
                      style={{ margin: "12px 0px", cursor: "pointer" }}
                      onClick={() =>
                        this.weeklyDataInputTickets(
                          groupedDataInfo,
                          "Review",
                          'header'
                        )
                      }
                    >
                      <p className="owner-operator-card-body ticket_p_tag">
                        <span className="owner-operator-card-head ticket_head_span">
                          Pending Review
                        </span>
                        {parseInt(
                          totalTicketsData.filter(
                            (e) => e.ticketVerification === "Review"
                          ).length
                        )}
                      </p>
                    </Col>

                    <Col
                      xxl={{ span: 5 }}
                      xl={{ span: 5 }}
                      lg={{ span: 5 }}
                      md={{ span: 8 }}
                      sm={{ span: 8 }}
                      xs={{ span: 8 }}
                      style={{ margin: "12px 0px" }}
                      onClick={() =>
                        this.weeklyDataInputTickets(
                          rejectTicketsData,
                          "Open",
                          'manager',
                          Object.keys(groupedDataInfo)[0]
                        )
                      }
                    >
                      <p className="owner-operator-card-body ticket_p_tag">
                        <span className="owner-operator-card-head ticket_head_span">
                          Manager Review
                        </span>
                        {parseInt(rejectTicketsData?.length)}
                      </p>
                    </Col>

                    <Col
                      xxl={{ span: 5 }}
                      xl={{ span: 5 }}
                      lg={{ span: 5 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 12 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p className="owner-operator-card-body ticket_p_tag">
                        <span className="owner-operator-card-head ticket_head_span">
                          Approved
                        </span>
                        {parseInt(
                          totalTicketsData.filter(
                            (e) => e.ticketVerification === "Approved"
                          ).length
                        )}
                      </p>
                    </Col>
                    <Col
                      xxl={{ span: 4 }}
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 12 }}
                      sm={{ span: 12 }}
                      xs={{ span: 12 }}
                      style={{ margin: "12px 0px" }}
                    >
                      <p className="owner-operator-card-body ticket_p_tag">
                        <span className="owner-operator-card-head ticket_head_span">
                          Invoiced
                        </span>
                        {parseInt(
                          totalTicketsData.filter(
                            (e) => e.ticketVerification === "Invoiced"
                          ).length
                        )}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {finaceInfo && finaceInfo.length > 0 && (
                  <Row style={{ marginBottom: "1vw" }}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 16 }}
                      xxl={{ span: 16 }}
                    >
                      <Row>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 10 }}
                          xl={{ span: 10 }}
                          xxl={{ span: 10 }}
                          className="quarrytoolgrid rangepicker-invoice"
                        >
                          <RangePicker
                            style={{
                              height: "40px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            value={
                              value !== null && [
                                moment(value[0]),
                                moment(value[1]),
                              ]
                            }
                            // disabledDate={disabledDate}
                            onCalendarChange={(val) =>
                              this.setState({
                                datesVal: val,
                              })
                            }
                            locale={moment.locale("en", {
                              week: {
                                dow: 1,
                              },
                            })}
                            onChange={(val) => this.handleDateRange(val)}
                            onOpenChange={this.onOpenChange}
                            changeOnBlur
                            format={"MM/DD/YYYY"}
                          />
                        </Col>

                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 10 }}
                          xl={{ span: 14 }}
                          xxl={{ span: 14 }}
                          className="quarrytoolgrid generate-invoice-button"
                          style={{
                            display: "flex",
                            justifyContent:
                              finaceInfo[0]?.selectedOptions?.includes(
                                "Edit"
                              ) && "space-evenly",
                          }}
                        >
                          {finaceInfo &&
                            finaceInfo[0]?.selectedOptions?.includes("Edit") ? (
                            <>
                              <Col>
                                <Button
                                  style={{
                                    height: "40px",
                                    borderRadius: "5px",
                                    border:
                                      ClickCard && checkedCard
                                        ? "1px solid #ff1a1a"
                                        : "1px solid #979fae",
                                  }}
                                  onClick={this.generateInvoice}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      color:
                                        ClickCard && checkedCard
                                          ? "#ff1a1a"
                                          : "#7f899b",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Generate Invoice
                                  </span>
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  type="primary"
                                  style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                  }}
                                  onClick={() =>
                                    rejectTicketsData?.length > 0
                                      ? this.setState({
                                        showRejected: true,
                                      })
                                      : message.info(
                                        "There are no review tickets!"
                                      )
                                  }
                                >
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {"Manager Review (" +
                                      rejectTicketsData?.length +
                                      ")"}
                                  </span>
                                </Button>
                              </Col>

                              <Col>
                            <Button
                              type="primary"
                              style={{
                                borderRadius: "5px",
                                height: "40px",
                                marginLeft:
                                  finaceInfo[0]?.selectedOptions?.includes(
                                    "Edit"
                                  )
                                    ? "0px"
                                    : "20px",
                              }}
                              onClick={() =>
                                dataInputTicketsData?.length > 0
                                  ? this.setState({
                                    showDataInput: true,
                                  })
                                  : message.info(
                                    "There are no dataInput tickets!"
                                  )
                              }
                            >
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                {"Data Input (" +
                                  parseInt(dataInputTicketsData?.length) +
                                  ")"}
                              </span>
                            </Button>
                          </Col>
                            </>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>

                    {!showSingleJob && !showRejected && !showSingleCompany && (
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 8 }}
                      >
                        <Row style={{ justifyContent: "right" }}>
                          <Col className="gridbtntool">
                            <Button
                              // className="button_Crm"
                              style={{
                                borderRadius: "5px",
                                height: "40px",
                              }}
                              onClick={() => {
                                this.setState({ visible: true });
                              }}
                            >
                              <Row>
                                <Col>
                                  <span
                                    style={{
                                      color:
                                        Object.keys(filterInfo)?.length > 0
                                          ? "red"
                                          : "#5a6067",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                    className="table-font-mobile"
                                  >
                                    {Object.keys(filterInfo)?.length > 0
                                      ? `${"Filter (" +
                                      Object.keys(filterInfo)?.length
                                      })`
                                      : "Filter"}
                                  </span>
                                </Col>
                                <Col>
                                  <IoFilterSharp
                                    style={{
                                      fontSize: "22px",
                                      marginLeft: "8px",
                                      marginTop: "2px",
                                      color:
                                        Object.keys(filterInfo)?.length > 0
                                          ? "red"
                                          : "#5a6067",
                                    }}
                                    className="trucking-filter-icon"
                                  />
                                </Col>
                              </Row>
                            </Button>
                          </Col>

                          {finaceInfo &&
                            finaceInfo[0]?.selectedOptions?.includes("Edit") ? (
                            ""
                          ) : finaceInfo[0]?.selectedOptions?.includes(
                            "Review"
                          ) ? (
                            <Col className="gridbtntool">
                              <Button
                                className="hover_style"
                                style={{
                                  borderRadius: "5px",
                                  height: "40px",
                                  border: "none",
                                }}
                                onClick={() =>
                                  this.handleApproved(showApproved)
                                }
                              >
                                <CheckOutlined
                                  style={{
                                    color: "#586370",
                                    fontSize: "17px",
                                    fontWeight: "500",
                                  }}
                                />
                                <span
                                  style={{
                                    color: "#586370",
                                    fontSize: "17px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {showApproved
                                    ? "Show Approved"
                                    : "Hide Approved"}
                                </span>
                              </Button>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </Col>
                    )}
                  </Row>
                )}
              </Col>
              <Col span={24}>
                <div ref={this.setWrapperRef}>
                  {showRejected ? (
                    rejectTicketsData?.length > 0 && (
                      <RejectUpload
                        jobInfo={rejectTicketsData}
                        handleCancel={() =>
                          this.setState({
                            showRejected: false,
                          })
                        }
                        handleApproved={this.handleApproved}
                        handlePending={this.handlePending}
                        totalTrucksData={totalTrucksData}
                      />
                    )
                  ) : showSingleJob ? (
                    Object.keys(individualJobData)?.length > 0 ? (
                      <div className="dispatchertool-grpgrid">
                        <Row
                          onClick={() =>
                            this.setState({ showSingleJob: false })
                          }
                          style={{ cursor: "pointer", width: "100%" }}
                        >
                          <BsArrowLeftShort
                            style={{ color: "#5f6c74", fontSize: "23px" }}
                            className="back-button-return-icon"
                          />
                          <p
                            style={{
                              color: "#808080",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                            className="back-button-return"
                          >
                            Return
                          </p>
                        </Row>
                        {Object.keys(individualJobData)
                          .slice(
                            window.screen.width > 1023 ? minjobValue : 0,
                            window.screen.width > 1023
                              ? maxjobValue
                              : Object.keys(individualJobData).length
                          )
                          ?.map((e, i) => (
                            <div className="dispatcher-contentgrid" key={e.id}>
                              <Row
                                style={{
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Col span={24} style={{ marginLeft: "0px" }}>
                                  <Input
                                    type="checkbox"
                                    name="cardRadio"
                                    checked={ClickCard === e && checkedCard}
                                    className="cardRadio-intbtn checkBox_btn"
                                    onChange={(val) => {
                                      this.setState({
                                        checkedCard: val.target.checked,
                                        ClickCard: val.target.checked ? e : "",
                                      });
                                    }}
                                    style={{ left: "15px" }}
                                  />
                                  <Card
                                    bodyStyle={{ padding: "0px" }}
                                    style={{
                                      borderRadius: "5px",
                                      height: "220px",
                                    }}
                                    hoverable
                                    className={
                                      ClickCard === e && checkedCard
                                        ? "dispatcherCard clickCard-dispatchertoolgrp"
                                        : parseInt(
                                          parseInt(
                                            Object.values(
                                              individualJobData[e]
                                            )?.filter(
                                              (t) =>
                                                t?.ticketVerification ===
                                                "Invoiced"
                                            )?.length * 100
                                          ) /
                                          Object.values(
                                            individualJobData[e]
                                          )?.length
                                        ) === 100
                                          ? "dispatcherCard green-dispatchertoolgrp"
                                          : parseInt(
                                            parseInt(
                                              Object.values(
                                                individualJobData[e]
                                              )?.filter(
                                                (t) =>
                                                  t?.ticketVerification ===
                                                  "Invoiced"
                                              )?.length * 100
                                            ) /
                                            Object.values(
                                              individualJobData[e]
                                            )?.length
                                          ) !== 0
                                            ? "dispatcherCard red-dispatchertoolgrp"
                                            : "dispatcherCard bor-dispatchertoolgrp"
                                    }
                                    onClick={() => {
                                      this.setState({
                                        ClickCard: !checkedCard ? e : "",
                                        checkedCard: !checkedCard,
                                      });
                                    }}
                                    onDoubleClick={() =>
                                      this.handleSingleOrder(
                                        e,
                                        Object.values(individualJobData[e])
                                      )
                                    }
                                  >
                                    <div
                                      className="bor-dispatchertool"
                                      style={{
                                        padding: "10px",
                                        height: "218px",
                                        borderRadius: "15px",
                                        backgroundColor:
                                          parseInt(
                                            parseInt(
                                              Object.values(
                                                individualJobData[e]
                                              )?.filter(
                                                (t) =>
                                                  t?.ticketVerification ===
                                                  "Invoiced"
                                              )?.length * 100
                                            ) /
                                            Object.values(
                                              individualJobData[e]
                                            )?.length
                                          ) === 100
                                            ? "#00800417"
                                            : parseInt(
                                              parseInt(
                                                Object.values(
                                                  individualJobData[e]
                                                )?.filter(
                                                  (t) =>
                                                    t?.ticketVerification ===
                                                    "Invoiced"
                                                )?.length * 100
                                              ) /
                                              Object.values(
                                                individualJobData[e]
                                              )?.length
                                            ) !== 0
                                              ? "#ff090924"
                                              : "#8080802b",
                                      }}
                                    >
                                      <p
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          fontWeight: "bold",
                                        }}
                                        className="ticket-upload-card"
                                      >
                                        {
                                          Object.values(individualJobData[e])[0]
                                            ?.companyName
                                        }
                                      </p>
                                      <p>
                                        {this.findJobType(
                                          Object.values(individualJobData[e])
                                        )}
                                      </p>
                                      <p>
                                        {"#" +
                                          Object.values(individualJobData[e])[0]
                                            ?.jobNumber}{" "}
                                      </p>
                                      <p>
                                        {" "}
                                        {
                                          Object.values(individualJobData[e])[0]
                                            ?.jobName
                                        }
                                      </p>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column-reverse",
                                          width: "100%",
                                        }}
                                      >
                                        <p>
                                          Tickets :{" "}
                                          {
                                            Object.values(
                                              individualJobData[e]
                                            )?.filter(
                                              (t) =>
                                                t?.ticketVerification ===
                                                "Invoiced"
                                            )?.length
                                          }
                                          /
                                          {
                                            Object.values(individualJobData[e])
                                              ?.length
                                          }
                                        </p>

                                        <Progress
                                          percent={(
                                            (Object.values(
                                              individualJobData[e]
                                            )?.filter(
                                              (t) =>
                                                t?.ticketVerification ===
                                                "Invoiced"
                                            )?.length *
                                              100) /
                                            Object.values(individualJobData[e])
                                              ?.length
                                          )?.toFixed(e)}
                                          size="small"
                                          strokeColor="#00B894"
                                        />
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        <div
                          className="disp_fixed show-on-desktop"
                          style={{ height: "50px", marginBottom: "20px" }}
                        >
                          <Pagination
                            style={{ float: "right", marginTop: "1vw" }}
                            total={Object.keys(individualJobData).length}
                            showTotal={(total) => `${total} items`}
                            pageSizeOptions={[
                              10,
                              25,
                              50,
                              100,
                              `${Object.keys(individualJobData)?.length}`,
                            ]}
                            current={jobcurrentPage}
                            pageSize={
                              JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.ticketProcessJobData
                                ? JSON.parse(
                                  localStorage.getItem("tableSizeRange")
                                )?.ticketProcessJobData
                                : jobperpageSize
                            }
                            onChange={this.handlePagination}
                            defaultCurrent={1}
                            showSizeChanger={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <Nodata />
                    )
                  ) : showSingleCompany ? (
                    Object.keys(individualCompanyData)?.length > 0 ? (
                      <div className="dispatchertool-grpgrid">
                        <Row
                          onClick={() => {
                            this.setState({ showSingleCompany: false });
                            this.init();
                          }}
                          style={{ cursor: "pointer", width: "100%" }}
                        >
                          <BsArrowLeftShort
                            style={{ color: "#5f6c74", fontSize: "23px" }}
                            className="back-button-return-icon"
                          />
                          <p
                            style={{
                              color: "#808080",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                            className="back-button-return"
                          >
                            Return
                          </p>
                        </Row>

                        {Object.keys(individualCompanyData)
                          .slice(
                            window.screen.width > 1023 ? minCompanyValue : 0,
                            window.screen.width > 1023
                              ? maxCompanyValue
                              : Object.keys(individualCompanyData).length
                          )
                          ?.map((e, i) => (
                            <div className="dispatcher-contentgrid">
                              <Row
                                style={{
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Col span={24} style={{ marginLeft: "0px" }}>
                                  <Input
                                    type="checkbox"
                                    name="cardRadio"
                                    checked={ClickCard === e && checkedCard}
                                    className="cardRadio-intbtn checkBox_btn"
                                    onChange={(val) => {
                                      this.setState({
                                        checkedCard: val.target.checked,
                                        ClickCard: val.target.checked ? e : "",
                                      });
                                    }}
                                    style={{ left: "15px" }}
                                  />
                                  <Card
                                    bodyStyle={{ padding: "0px" }}
                                    style={{
                                      borderRadius: "5px",
                                      height: "220px",
                                    }}
                                    hoverable
                                    className={
                                      ClickCard === e && checkedCard
                                        ? "dispatcherCard clickCard-dispatchertoolgrp"
                                        : parseInt(
                                          parseInt(
                                            Object.values(
                                              individualCompanyData[e]
                                            )?.filter(
                                              (t) =>
                                                t?.ticketVerification ===
                                                "Invoiced"
                                            )?.length * 100
                                          ) /
                                          Object.values(
                                            individualCompanyData[e]
                                          )?.length
                                        ) === 100
                                          ? "dispatcherCard green-dispatchertoolgrp"
                                          : parseInt(
                                            parseInt(
                                              Object.values(
                                                individualCompanyData[e]
                                              )?.filter(
                                                (t) =>
                                                  t?.ticketVerification ===
                                                  "Invoiced"
                                              )?.length * 100
                                            ) /
                                            Object.values(
                                              individualCompanyData[e]
                                            )?.length
                                          ) !== 0
                                            ? "dispatcherCard red-dispatchertoolgrp"
                                            : "dispatcherCard bor-dispatchertoolgrp"
                                    }
                                    onClick={() => {
                                      this.setState({
                                        ClickCard: !checkedCard ? e : "",
                                        checkedCard: !checkedCard,
                                      });
                                    }}
                                    onDoubleClick={() => {
                                      this.showIndividualJob(
                                        e,
                                        Object.values(individualCompanyData[e])
                                      );
                                    }}
                                  >
                                    <div
                                      className="bor-dispatchertool"
                                      style={{
                                        padding: "10px",
                                        height: "218px",
                                        borderRadius: "15px",
                                        backgroundColor:
                                          parseInt(
                                            parseInt(
                                              Object.values(
                                                individualCompanyData[e]
                                              )?.filter(
                                                (t) =>
                                                  t?.ticketVerification ===
                                                  "Invoiced"
                                              )?.length * 100
                                            ) /
                                            Object.values(
                                              individualCompanyData[e]
                                            )?.length
                                          ) === 100
                                            ? "#00800417"
                                            : parseInt(
                                              parseInt(
                                                Object.values(
                                                  individualCompanyData[e]
                                                )?.filter(
                                                  (t) =>
                                                    t?.ticketVerification ===
                                                    "Invoiced"
                                                )?.length * 100
                                              ) /
                                              Object.values(
                                                individualCompanyData[e]
                                              )?.length
                                            ) !== 0
                                              ? "#ff090924"
                                              : "#8080802b",
                                      }}
                                    >
                                      <p
                                        style={{
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                          // whiteSpace: "nowrap",
                                          fontWeight: "bold",
                                          marginTop: "1vw",
                                        }}
                                        className="ticket-upload-card"
                                      >
                                        {
                                          Object.values(
                                            individualCompanyData[e]
                                          )[0]?.companyName
                                        }
                                      </p>
                                      <p>
                                        {
                                          Object.values(
                                            individualCompanyData[e]
                                          )[0]?.companyAddress
                                        }
                                      </p>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column-reverse",
                                          width: "100%",
                                        }}
                                      >
                                        <p>
                                          Jobs :{" "}
                                          {
                                            this.ordersCount(
                                              individualCompanyData,
                                              e
                                            )?.length
                                          }
                                        </p>

                                        <Progress
                                          percent={(
                                            (Object.values(
                                              individualCompanyData[e]
                                            )?.filter(
                                              (t) =>
                                                t?.ticketVerification ===
                                                "Invoiced"
                                            )?.length *
                                              100) /
                                            Object.values(
                                              individualCompanyData[e]
                                            )?.length
                                          )?.toFixed(e)}
                                          size="small"
                                          strokeColor="#00B894"
                                        />
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        <div
                          className="disp_fixed show-on-desktop"
                          style={{ height: "50px", marginBottom: "20px" }}
                        >
                          <Pagination
                            style={{ float: "right", marginTop: "1vw" }}
                            total={Object.keys(individualCompanyData).length}
                            showTotal={(total) => `${total} items`}
                            pageSizeOptions={[
                              10,
                              25,
                              50,
                              100,
                              `${Object.keys(individualCompanyData)?.length}`,
                            ]}
                            current={companyCurrentPage}
                            pageSize={
                              JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.ticketCompanyData
                                ? JSON.parse(
                                  localStorage.getItem("tableSizeRange")
                                )?.ticketCompanyData
                                : companyPerpageSize
                            }
                            onChange={this.handlePagination}
                            defaultCurrent={1}
                            showSizeChanger={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <Nodata />
                    )
                  ) : Object.keys(groupedDataInfo)?.length > 0 ? (
                    <Col span={24}>
                      {Object.keys(groupedDataInfo)
                        .slice(minValue, maxValue)
                        .map((e) => (
                          <>
                            <Row
                              style={{
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Col span={24}>
                                <Input
                                  type="checkbox"
                                  name="cardRadio"
                                  checked={ClickCard === e && checkedCard}
                                  className="cardRadio-intbtn checkBox_btn"
                                  onChange={(val) => {
                                    this.setState({
                                      checkedCard: val.target.checked,
                                      ClickCard: val.target.checked ? e : "",
                                    });
                                  }}
                                />
                                <Card
                                  className="ticket-processing-card show-on-desktop"
                                  hoverable
                                  style={{
                                    marginBottom: "1vw",
                                    borderRadius: "5px",
                                    border:
                                      ClickCard === e && checkedCard
                                        ? "2px solid #ff1a1a"
                                        : this.stageCount(
                                          Object.values(groupedDataInfo[e]),
                                          "Invoiced"
                                        )?.length ===
                                          groupedDataInfo[e]?.length
                                          ? "1px solid #333"
                                          : this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Open"
                                          )?.length ===
                                            groupedDataInfo[e]?.length
                                            ? "1px solid #ff1a1a"
                                            : this.stageCount(
                                              Object.values(groupedDataInfo[e]),
                                              "Review"
                                            )?.length ===
                                              groupedDataInfo[e]?.length
                                              ? "1px solid #dddd00"
                                              : this.stageCount(
                                                Object.values(groupedDataInfo[e]),
                                                "Approved"
                                              )?.length ===
                                                groupedDataInfo[e]?.length
                                                ? "1px solid #6aa84f"
                                                : "1px solid #E8E8E8",
                                    cursor: "pointer",
                                    backgroundColor:
                                      this.stageCount(
                                        Object.values(groupedDataInfo[e]),
                                        "Invoiced"
                                      )?.length === groupedDataInfo[e]?.length
                                        ? "#E8E8E8"
                                        : this.stageCount(
                                          Object.values(groupedDataInfo[e]),
                                          "Open"
                                        )?.length ===
                                          groupedDataInfo[e]?.length
                                          ? "#ff090924"
                                          : this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Review"
                                          )?.length ===
                                            groupedDataInfo[e]?.length
                                            ? "#ffffcc"
                                            : this.stageCount(
                                              Object.values(groupedDataInfo[e]),
                                              "Approved"
                                            )?.length ===
                                              groupedDataInfo[e]?.length
                                              ? "#00800417"
                                              : "white",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      ClickCard: !checkedCard ? e : "",
                                      checkedCard: !checkedCard,
                                    });
                                  }}
                                  onDoubleClick={() => {
                                    this.setState(
                                      {
                                        jobInfoData: {},
                                        individualCompanyData: {},
                                      },
                                      () =>
                                        this.showIndividualCustomer(
                                          e,
                                          Object.values(groupedDataInfo[e]),
                                          true
                                        )
                                    );
                                  }}
                                  bodyStyle={{ padding: "0px" }}
                                >
                                  <Row
                                    className="ticket_uplaod"
                                    style={{
                                      fontSize: "15px",
                                      padding: "35px",
                                    }}
                                  >
                                    <Col
                                      style={{
                                        fontWeight: "bold",
                                        display: "flex",
                                      }}
                                    >
                                      <Col>{e}</Col>
                                    </Col>
                                    <Col>
                                      <Col className="ticket_heading">
                                        Customers
                                      </Col>
                                      <Col
                                        className="ticket_heading"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {
                                          this.customersCount(
                                            Object.values(groupedDataInfo[e])
                                          )?.length
                                        }
                                      </Col>
                                    </Col>

                                    <Col>
                                      <Col className="ticket_heading">Jobs</Col>
                                      <Col
                                        className="ticket_heading"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {
                                          this.jobCount(
                                            Object.values(groupedDataInfo[e])
                                          )?.length
                                        }
                                      </Col>
                                    </Col>

                                    <Col
                                      onClick={() =>
                                        this.weeklyDataInputTickets(
                                          Object.values(groupedDataInfo[e]),
                                          "Open"
                                        )
                                      }
                                    >
                                      <Col className="ticket_heading">
                                        Data Input
                                      </Col>
                                      <Col
                                        className="ticket_heading"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {
                                          this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Open"
                                          )?.length
                                        }
                                      </Col>
                                    </Col>

                                    <Col
                                      onClick={() =>
                                        this.weeklyDataInputTickets(
                                          Object.values(groupedDataInfo[e]),
                                          "Review"
                                        )
                                      }
                                    >
                                      <Col className="ticket_heading">
                                        Pending Review
                                      </Col>
                                      <Col
                                        className="ticket_heading"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {
                                          this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Review"
                                          )?.length
                                        }
                                      </Col>
                                    </Col>

                                    <Col>
                                      <Col className="ticket_heading">
                                        Approved
                                      </Col>
                                      <Col
                                        className="ticket_heading"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {
                                          this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Approved"
                                          )?.length
                                        }
                                      </Col>
                                    </Col>

                                    <Col>
                                      <Col className="ticket_heading">
                                        Invoiced
                                      </Col>
                                      <Col
                                        className="ticket_heading"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {
                                          this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Invoiced"
                                          )?.length
                                        }
                                      </Col>
                                    </Col>

                                    <Col
                                      style={{
                                        fontWeight: "bold",
                                        color: "#586370",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Row
                                        onDoubleClick={() => {
                                          this.showIndividualCustomer(
                                            e,
                                            Object.values(groupedDataInfo[e]),
                                            true
                                          );
                                        }}
                                      >
                                        <Col>Details</Col>
                                        <Col
                                          style={{
                                            marginLeft: "6px",
                                            color: "#586370",
                                          }}
                                        >
                                          {" "}
                                          <ArrowRightOutlined />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                            <Collapse
                              style={{ marginBottom: "12px" }}
                              className="show-on-mobile"
                              key={e}
                            >
                              <Collapse.Panel
                                showArrow={false}
                                header={
                                  <Col span={18}>
                                    <Row justify={"space-between"}>
                                      <Col
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "bold",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        className="collapse-details-size"
                                        span={18}
                                      >
                                        <Col>{e}</Col>
                                      </Col>
                                    </Row>
                                  </Col>
                                }
                                extra={
                                  <>
                                    <Col
                                      style={{
                                        fontWeight: "bold",
                                        color: "#586370",
                                        cursor: "pointer",
                                        display: "flex",
                                      }}
                                      span={24}
                                    >
                                      <Row
                                        onDoubleClick={() => {
                                          this.showIndividualCustomer(
                                            e,
                                            Object.values(groupedDataInfo[e]),
                                            true
                                          );
                                        }}
                                      >
                                        <Col
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          className="collapse-details-size"
                                        >
                                          Details
                                        </Col>
                                        <Col
                                          style={{
                                            fontSize: "12px",
                                            marginLeft: "6px",
                                            color: "#586370",
                                          }}
                                        >
                                          {" "}
                                          <ArrowRightOutlined />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </>
                                }
                              >
                                <Row>
                                  <Col
                                    xxl={{ span: 24 }}
                                    xl={{ span: 24 }}
                                    lg={{ span: 24 }}
                                    md={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    xs={{ span: 24 }}
                                  >
                                    <div
                                      className="display_inovice"
                                      style={{
                                        flexWrap: "nowrap",
                                      }}
                                    >
                                      <Col className="ticket_heading display_head">
                                        Customers
                                      </Col>
                                      <Col className="ticket_heading display_val">
                                        {
                                          this.customersCount(
                                            Object.values(groupedDataInfo[e])
                                          )?.length
                                        }
                                      </Col>
                                    </div>

                                    <div
                                      className="display_inovice"
                                      style={{
                                        flexWrap: "nowrap",
                                      }}
                                    >
                                      <Col className="ticket_heading display_head">
                                        Jobs
                                      </Col>
                                      <Col className="ticket_heading display_val">
                                        {
                                          this.jobCount(
                                            Object.values(groupedDataInfo[e])
                                          )?.length
                                        }
                                      </Col>
                                    </div>

                                    <div
                                      className="display_inovice"
                                      style={{
                                        flexWrap: "nowrap",
                                      }}
                                    >
                                      <Col className="ticket_heading display_head">
                                        Data Input
                                      </Col>
                                      <Col className="ticket_heading display_val">
                                        {
                                          this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Open"
                                          )?.length
                                        }
                                      </Col>
                                    </div>
                                    <div
                                      className="display_inovice"
                                      style={{
                                        flexWrap: "nowrap",
                                      }}
                                    >
                                      <Col className="ticket_heading display_head">
                                        Pending Review
                                      </Col>
                                      <Col className="ticket_heading display_val">
                                        {
                                          this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Review"
                                          )?.length
                                        }
                                      </Col>
                                    </div>
                                    <div
                                      className="display_inovice"
                                      style={{
                                        flexWrap: "nowrap",
                                      }}
                                    >
                                      <Col className="ticket_heading display_head">
                                        Approved
                                      </Col>
                                      <Col className="ticket_heading display_val">
                                        {
                                          this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Approved"
                                          )?.length
                                        }
                                      </Col>
                                    </div>
                                    <div
                                      className="display_inovice"
                                      style={{
                                        flexWrap: "nowrap",
                                      }}
                                    >
                                      <Col className="ticket_heading display_head">
                                        Invoiced
                                      </Col>
                                      <Col className="ticket_heading display_val">
                                        {
                                          this.stageCount(
                                            Object.values(groupedDataInfo[e]),
                                            "Invoiced"
                                          )?.length
                                        }
                                      </Col>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse.Panel>
                            </Collapse>
                          </>
                        ))}

                      {Object.keys(groupedDataInfo).length > 0 && (
                        <div
                          className="disp_fixed show-on-desktop"
                          style={{ height: "50px", marginBottom: "20px" }}
                        >
                          <Pagination
                            style={{ float: "right", marginTop: "1vw" }}
                            total={Object.keys(groupedDataInfo).length}
                            showTotal={(total) => `${total} items`}
                            pageSizeOptions={[
                              5,
                              10,
                              25,
                              50,
                              100,
                              `${Object.keys(groupedDataInfo)?.length}`,
                            ]}
                            current={currentPage}
                            pageSize={
                              JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.ticketProcessGroupData
                                ? JSON.parse(
                                  localStorage.getItem("tableSizeRange")
                                )?.ticketProcessGroupData
                                : perpageSize
                            }
                            onChange={this.handlePagination}
                            defaultCurrent={1}
                            showSizeChanger={true}
                          />
                        </div>
                      )}
                    </Col>
                  ) : (
                    <Nodata />
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>

        {visible && (
          <Filter
            visible={visible}
            closeFilter={this.closeFilter}
            filterResultData={this.filterResultData}
            checkData={checkData}
            totalData={tableData}
            ticketProcessing
            // trucksData={trucksData}
            onClearFilter={this.onClearFilter}
          />
        )}

        {showInvoiceModal && ClickCard !== "" && ClickCard !== undefined && (
          <BaseModal
            className="antmodal_grid headermodal"
            title={"Invoice Details"}
            onCancel={() =>
              this.setState({
                showInvoiceModal: false,
                invoiceType: "",
                ClickCard: "",
                newClickCard: "",
              })
            }
            formId="invoiceForm"
            loading={loading}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "980px"
            }
            submitButton={"Confirm"}
          >
            <Form
              onFinish={this.onFinishInvoice}
              id="invoiceForm"
              ref={this.formRef}
            >
              {showSingleJob
                ? this.showInvoiceData(
                  Object.values(individualJobData[ClickCard]),
                  Object.values(individualJobData[ClickCard])[0]?.jobName,
                  "jobName"
                )
                : showSingleCompany
                  ? this.showInvoiceData(
                    Object.values(individualCompanyData[ClickCard]),
                    Object.values(individualCompanyData[ClickCard])[0]
                      ?.companyName,
                    "customer"
                  )
                  : this.showInvoiceData(
                    Object?.values(groupedData[ClickCard]),
                    ClickCard,
                    "week"
                  )}
            </Form>
          </BaseModal>
        )}

        {showInvoiceModal && !ClickCard && (
          <BaseModal
            className="tickectfinan_modal antmodal_grid headermodal"
            title={"Invoice Details"}
            onCancel={() =>
              this.setState({
                showInvoiceModal: false,
                invoiceType: "",
              })
            }
            formId="invoiceForm"
            loading={loading}
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
          >
            <Form
              onFinish={this.onFinishInvoice}
              id="invoiceForm"
              ref={this.formRef}
            >
              <div>
                <span className="title_changes">Select Customer Name</span>
                <Form.Item
                  name="companyId"
                  rules={[
                    {
                      required: false,
                      message: "Please Select Customer Name!",
                    },
                  ]}
                >
                  <Select
                    className="select_multiplequarytol"
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select Customer Name"
                    allowClear
                    onChange={this.handleCustomerSelect}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {selectedTicketsInfo?.map((t) => (
                      <Option key={t.companyId} value={t.companyId}>
                        {t.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div>
                <span className="title_changes">Select Job#</span>
                <Form.Item
                  name="jobId"
                  rules={[
                    {
                      required: false,
                      message: "Please Select JobName!",
                    },
                  ]}
                >
                  <Select
                    className="select_Height"
                    showSearch
                    style={{
                      fontWeight: "600",
                      height: "40px",
                      width: "100%",
                    }}
                    placeholder="Search to Select Job"
                    allowClear
                    onChange={(e) => {
                      if (e) {
                        this.setState({
                          invoiceType: "Job #/Job Name",
                        });
                      } else {
                        this.setState({
                          invoiceType: "Customer Name",
                        });
                      }
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.key.indexOf(input) > -1
                    }
                  >
                    {filteredJobs.map((job) => (
                      <Option
                        key={job.jobNumber}
                        value={job.jobId}
                        className="option_style"
                        label={job.jobName}
                      >
                        <span>
                          {" #" + job.jobNumber + " / " + job.jobName}
                        </span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </BaseModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
// console.log(state),
({
  totalResult: state?.reducer?.ticketsResult,
  loading: state?.reducer?.ticketsResult?.loading,
  errorLoading: state?.reducer?.ticketsResult?.error,
  dispatchResult: state?.reducer?.dispatchResult,
  generateInvoiceInfo: state?.reducer?.generateInvoiceInfo,
  invoiceResult: state?.reducer?.invoicesResult,
  ticketsInfo: state?.reducer?.ticketsInfo,
  trucksResult: state.reducer?.trucksListResult,
  dropDownOptions: state.reducer?.dropDownOptions,
  trucksTypesData: state.reducer?.dropDownOptions?.totalData?.filter(
    (t) => t.typeofDropDown === "trucksType"
  ),
  ticketVerifiedCount: state.reducer?.dropDownOptions?.totalData?.filter(
    (t) => t.typeofDropDown === "ticketVerification"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchGetTickets: () => {
    dispatch(getTickets());
  },
  actionFetchGetDispatch: () => {
    dispatch(getDispatch());
  },
  actionFetchGenerateInvoice: (data) => {
    dispatch(generateInvoice(data));
  },
  actionFetchAddTickets: (data) => {
    dispatch(addTicket(data));
  },
  actionsFetchDeleteTicket: (id) => {
    dispatch(deleteTicket(id));
  },
  actionFetchTrucksList: () => {
    dispatch(getTrucksList());
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionFetchGetInvoices: () => {
    dispatch(getInvoices());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(TicketProcessing)));
