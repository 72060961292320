import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Tabs,
  Form,
  DatePicker,
  Popover,
  Upload,
  Spin,
  Select,
  message,
  InputNumber,
  Checkbox,
  Tooltip,
  Popconfirm,
  Switch,
  Divider,
  Tag,
} from "antd";
import {
  PlusOutlined,
  CameraOutlined,
  FilePdfOutlined,
  EllipsisOutlined,
  // EditFilled,
  CloseOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  // CheckCircleOutlined,
  CopyOutlined,
  EditOutlined,
  LinkOutlined,
  InboxOutlined,
  CheckCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { GrDocumentText } from "react-icons/gr";
import { SiGooglesheets } from "react-icons/si";
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
import { Storage } from "aws-amplify";
import { add, updateTotal } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import Loading from "../../../Common/Loading";
import funnelStages from "../../../Json/funnel.json";
import TimePicker from "rc-time-picker";
import ErrorView from "../../../Common/ErrorView";
import { withAppContext } from "../../../Store/AppContext";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import MapComponent from "../../Maps/MapComponent";
import { getLatLng } from "react-places-autocomplete";
import { v4 as uuidv4 } from "uuid";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import {
  getSingleTicketData,
  addContact,
  addDocument,
  addNotes,
  deleteContactandJob,
  deleteNotes,
  getCompany,
  getDocuments,
  getDropDownOptions,
  getLead,
  getNotes,
  getQuarryLocations,
  getTopVendors,
  updateContacts,
  updateDocument,
  updateDropDownOptions,
  updateLead,
  updateNotes,
  updateGenerateEstimate,
  disptachMail,
} from "../../../Redux/Actions/actions";
import QuarryJsonData from "../../../Json/quarryData.json";
import Nodata from "../../nodata";
const { Option } = Select;

const { TextArea } = Input;

const { TabPane } = Tabs;
const { Dragger } = Upload;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class LeadDetails extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isNoteModalVisible: false,
      newNoteData: "",
      visible: false,
      selectedStatus: "",
      pickUpAddress: "",
      keyValue: "1",
      dropAddress: "",
      areaAddress: "",
      quoteAttachment: "",
      imageLoading: false,
      isEditLeadModalVisible: false,
      isDocModalVisible: false,
      isCallModalVisible: false,
      leadDetails: [],
      internalLoading: false,
      funnelData: [],
      quotesData: [],
      documentsData: [],
      invoicesData: [],
      notesData: [],
      newNote: "",
      idData: "",
      selectedInvoice: "",
      selectedQuote: "",
      isQuickMsgVisible: false,
      quarryData: [],
      leadId: "",
      latitude: "",
      longitude: "",
      isJobEditModal: false,
      jobInfo: "",
      quantityType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      isCheckBox: false,
      newRole: "",
      quoteAttachmentId: "",
      selectedMaterial: [],
      recordMaterialData: [],
      materialCheck: false,
      selectedMaterialType: "",
      finalMaterialData: [],
      addContact: false,
      truckingCostType: "Ton",
      isContactVisible: false,
      contactsData: [],
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      roleOther: "",
      leadsData: [],
      companyId: "",
      jobContactData: "",
      materialPopup: false,
      pickupDetails: [],
      selectedAddress: "",
      repeatDays: [],
      activeClasses: false,
      afterValue: "",
      onValue: "",
      newVendor: "",
      truckingPriceType: "Ton",
      dropoffLatitude: "",
      dropoffLongitude: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      dispatchModal: false,
      vendorData: [],
      seperate: false,
      initialMaterialCost: "",
      materialPriceState: "",
      selectVendor: "",
      materialValue: "",
      categoriesList: [],
      materialCostPrice: "",
      materialTruckPrice: 0,
      milesData: "",
      duration: "",
      vendorName: "",
      quantity: 1,
      masterRoleId: "",
      masterTruckId: "",
      notesPopup: false,
      recordNotesData: "",
      fileType: "",
      recurring: "",
      filterInvoicesData: [],
      recordUpdate: false,
      contactDetails: [],
      quarryContacts: [],
      rolesData: [],
      trucksData: [],
      materialList: [],
      materialSelectedData: {},
      topVendorsData: [],
      vendorDetails: "",
      updateJobIdContact: [],
      useMarkUp: "",
      truckingCost: "",
      openDropdown: "",
      customRate: false,
      materialCustomRate: false,
      diameter: "",
      materialCategory: {},
      selectedRowKeys: [],
      salesCommission: "",
      accountChargeLimit: "Cash Account",
      isSheetModalVisible: false,
      projectAttachment: "",
      projectAttachmentId: "",
      orderDetails: [],
      invoiceNote: true,
      invoiceNotes: {
        notes1: "",
        notes2: "",
        notes3: "",
      },
      quoteType: "",
      ticketsInfo: [],
      isModalData: false,
      mailRecord: "",
      isMailModalVisible: false,
      emailModal: false,
      loading: false,
      popupVisible: false,
      loginEmail: "",
      loginPassword: "",
      resendLoader: false,
      showForget: false,
      showConfirm: false,
      ConfirmMail: false,
      isEmailConfirmed: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    const {
      actionFetchgetLead,
      actiongetDocuments,
      actiongetNotes,
      actionFetchQuarries,
      actiongetDropDownOptions,
      actionFetchTickets,
    } = this.props;

    actionFetchgetLead(id);
    actiongetDocuments(id);
    actiongetNotes(id);
    this.props?.context?.updateLeadId(id);
    actionFetchQuarries();
    actiongetDropDownOptions();
    actionFetchTickets(id);
    document.title = "Twisted Nail - Lead Details";
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }

  onRefresh = () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.actionFetchgetLead(id);
    this.props.actiongetDocuments(id);
    this.props.actiongetNotes(id);
    this.props?.context?.updateLeadId(id);
    this.props.actiongetDropDownOptions();
    this.props.actionFetchQuarries();
    this.props?.actionFetchTickets(id);
  };

  componentDidUpdate(newProps) {
    const { mailResult } = this.props;
    if (newProps.mailResult.success === false && mailResult.success) {
      message.success("E-mail sent successfully");
    }
    if (
      newProps?.ticketsResult?.success === false &&
      this.props.ticketsResult?.success === true
    ) {
      const TicketsData = this.props?.ticketsResult?.ticketsData?.filter(
        (e) => e.ticketVerification !== "Open" && e.pickUpTimeStamp
      );
      this.setState({
        ticketsInfo: TicketsData,
      });
    }
    if (
      newProps?.estimateResult?.success === false &&
      this.props?.estimateResult?.success === true
    ) {
      this.props.actiongetDocuments(this.props?.match?.params?.id);
      this.fileDownloadQuote(
        `quotes/${this.props.estimateResult?.data?.fileName}`
      );
    }
    if (
      newProps?.topVendorsData?.success === false &&
      this.props?.topVendorsData?.success === true
    ) {
      if (this.props?.topVendorsData.vendors?.length > 0) {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
      } else {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
        message.info("No vendors found!");
      }
    }

    if (newProps?.match?.params?.id !== this.props?.match?.params?.id) {
      // this.getLeadDetails(this.props?.match?.params?.id);
      this.props?.context?.updateLeadId(this.props?.match?.params?.id);
      this.props.actiongetDropDownOptions();
    }

    if (
      newProps?.totalResult?.success === false &&
      this.props?.totalResult?.success === true
    ) {
      this.setState({
        leadDetails: this.props?.totalResult?.lead,
        quarryContacts: this.props?.totalResult?.lead?.contactDetails?.filter(
          (e) => e.materialId
        ),
        quarryData: [this.props?.totalResult?.lead],
        loading: false,
        invoicesData: this.props?.totalResult?.lead?.invoiceDetails,
        jobInfo: this.props?.totalResult?.lead?.jobType,
        selectedMaterial: this.props?.totalResult?.lead.material,
        filterInvoicesData:
          this.props?.totalResult?.lead?.invoiceDetails?.filter(
            (i) => !i?.ARinvoiceStatus
            // i?.ARinvoiceStatus !== "Paid" &&
            // i?.ARinvoiceStatus !== undefined
          ),
        dropAddress: this.props?.totalResult?.lead?.dropoff,
        dropoffLatitude: this.props?.totalResult?.lead?.dropOffLatLng?.lat,
        dropoffLongitude: this.props?.totalResult?.lead?.dropOffLatLng?.lng,
        contactDetails: this.props?.totalResult?.lead?.contactDetails,
        invoiceNotes: {
          ...this.props?.totalResult?.lead?.invoiceNotes,
          notes1: this.props?.totalResult?.lead?.invoiceNotes?.notes1,
          notes2: this.props?.totalResult?.lead?.invoiceNotes?.notes2,
          notes3: this.props?.totalResult?.lead?.invoiceNotes?.notes3,
        },
      });
      this.funnelInfo(this.props?.totalResult?.lead);
      this.props?.actionFetchCompany(this.props?.totalResult?.lead?.companyId);
    }

    if (
      newProps?.leadUpdateInfo?.success === false &&
      this.props?.leadUpdateInfo?.success === true
    ) {
      this.setState({
        loading: false,
        selectedMaterial: [],
        recordMaterialData: [],
        materialCostType: "Ton",
        truckingCostType: "Ton",
        quantityType: "Ton",
        totalPriceType: "Ton",
        taxExempt: "",
        dropoffLatitude: "",
        dropoffLongitude: "",
        recordUpdate: false,
        quarryContacts: [],
        openDropdown: "",
        dispatchModal: false,
        internalLoading: false,
        emailModal: false,
      });
      this.props.actionFetchgetLead(this.props.match.params.id);
    }

    if (
      newProps?.contactsInfo?.success === false &&
      this.props?.contactsInfo?.success === true
    ) {
      // console.log("contactsInfo");
      this.setState({
        newRole: "",
        roleOther: "",
        roleCheckBox: false,
        isCheckBox: false,
        jobContactData: "",
      });
      this.props.actionFetchgetLead(this.props.match.params.id);
    }

    if (
      newProps?.contactData?.success === false &&
      this.props?.contactData?.success === true
    ) {
      this.addContactToJob(this.props?.contactData?.contact?.data);
      this.setState({
        isCheckcontact: false,
        roleOther: "",
        newRole: "",
      });
    }

    if (
      newProps?.companyResult?.success === false &&
      this.props?.companyResult?.success === true
    ) {
      let quarryContacts = [];

      if (this.props?.totalResult?.lead?.multipleContacts?.length > 0) {
        quarryContacts =
          this.props.companyResult?.companyData?.contactDetails.concat(
            this.props?.totalResult?.lead?.multipleContacts
          );
      } else {
        quarryContacts = this.props.companyResult?.companyData?.contactDetails;
      }
      quarryContacts = quarryContacts?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t?.id === value?.id)
      );
      this.setState({
        contactsData: quarryContacts,
      });
    }

    if (
      newProps?.docResult?.success === false &&
      this.props?.docResult?.success === true
    ) {
      this.setState({
        quoteAttachment: "",
      });
      if (this.props?.docResult.noUpdate) {
        this.props.actionFetchgetLead(this.props.match.params.id);
      }
      this.props.actiongetDocuments(this.props.match.params.id);
    }

    if (
      newProps?.documentsData?.success === false &&
      this.props?.documentsData?.success === true
    ) {
      this.setState({
        documentsData: this.props.documentsData.docData,
      });
    }

    if (
      newProps?.notesResult?.success === false &&
      this.props?.notesResult?.success === true
    ) {
      // console.log("notes")
      this.setState({
        notesData: this.props.notesResult.notesData,
      });
    }

    if (
      newProps?.notesInfo?.success === false &&
      this.props?.notesInfo?.success === true
    ) {
      this.props.actiongetNotes(this.props.match.params.id);
      this.props.actionFetchgetLead(this.props.match.params.id);
    }

    if (
      newProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props?.actiongetDropDownOptions();
    }

    if (
      newProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      let materialData = {};
      this.props.materialList[0]?.materialsNewList?.forEach(
        (eachMat, index) => {
          if (eachMat?.statusCode) {
            materialData[eachMat.category] = !Object.keys(
              materialData
            ).includes(eachMat.category)
              ? []
              : materialData[eachMat.category];
          }
        }
      );

      // console.log("drop")
      this.setState({
        materialCategory: materialData,
        rolesData: this.props.rolesData[0]?.rolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        trucksData: this.props.trucksData[0]?.trucksData,
        materialList: this.props.materialList[0]?.materialsNewList,
      });
    }

    if (
      newProps?.quarryResult?.success === false &&
      this.props?.quarryResult?.success === true
    ) {
      // console.log("quarry");
      this.setState({
        quarryDetails: this.props.quarryResult.quarryData,
        vendorData: this.props.quarryResult.quarryData,
      });
    }
  }

  calculateTimeDifferences = (objects) => {
    let totalDifference = 0;

    for (let i = 0; i < objects.length - 1; i++) {
      const currentTime = new Date(moment(objects[i]?.pickUpTimeStamp));
      const nextTime = new Date(moment(objects[i + 1]?.pickUpTimeStamp));
      let differenceInSeconds;
      if (nextTime > currentTime) {
        differenceInSeconds = (nextTime - currentTime) / 1000; // Convert milliseconds to seconds
      } else {
        differenceInSeconds = (currentTime - nextTime) / 1000; // Convert milliseconds to seconds
      }
      totalDifference += differenceInSeconds;
    }
    return totalDifference / objects.length;
  };

  formatTimeDifference = (differenceInSeconds) => {
    if (differenceInSeconds < 60) {
      return `${differenceInSeconds} seconds`;
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      const remainingMinutes = Math.floor((differenceInSeconds % 3600) / 60);
      return `${hours} hour${
        hours !== 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`;
    } else {
      const days = Math.floor(differenceInSeconds / 86400);
      const remainingHours = Math.floor((differenceInSeconds % 86400) / 3600);
      const remainingMinutes = Math.floor((differenceInSeconds % 3600) / 60);
      return `${days} day${days !== 1 ? "s" : ""} ${remainingHours} hour${
        remainingHours !== 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`;
    }
  };

  switchInvoices = (e) => {
    const { invoicesData } = this.state;
    if (e === true) {
      this.setState({
        filterInvoicesData: invoicesData,
      });
    } else {
      this.setState({
        filterInvoicesData: invoicesData?.filter((i) => !i?.ARinvoiceStatus),
      });
    }
  };

  funnelInfo = (data) => {
    let funnel = [];
    const funnelId = data.leadType[0]?.id;
    funnelStages.stages.map(
      (eachItem) =>
        parseInt(funnelId) === parseInt(eachItem.funnelId) &&
        eachItem.stage.map((stage) => {
          funnel.push(stage.content);
          this.setState({
            funnelData: funnel,
          });
          return true;
        })
    );
  };

  onSelectChange = (data, value) => {
    this.setState({
      selectedRowKeys: data,
      vendorDetails: value[0],
      initialMaterialCost: value[0]?.material[0]?.price,
      materialCostPrice: value[0]?.material[0]?.price,
      materialPriceState: "",
      truckingCost: "",
    });
    this.formRef.current.setFieldsValue({
      quantity: "",
      totalPrice: "",
      markUp: "",
      truckingCost: "",
      truckingPrice: "",
      materialCost: value[0]?.material[0]?.price,
    });
  };

  deleteNotes = (id) => {
    this.props.actionDeleteNotes(id);
  };

  showDataModal = (record) => {
    this.setState({ isModalData: true, mailRecord: record });
  };

  handleSendEmail = (formData) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { mailRecord, leadDetails } = this.state;
    const { actionFetchDispatchMail } = this.props;
    mailRecord["email"] = formData["email"];
    mailRecord["dispatchData"] = leadDetails["dispatchData"];
    mailRecord["companyName"] = leadDetails["companyName"];
    mailRecord["jobName"] = leadDetails["jobName"];
    mailRecord["contactDetails"] = leadDetails["contactDetails"];
    mailRecord["jobType"] = leadDetails["jobType"];
    mailRecord["dropoff"] = leadDetails["dropoff"];
    mailRecord["materialNotes"] = leadDetails["requiredMaterials"];
    mailRecord["userName"] = userDetails["firstName"] + userDetails["lastName"];
    mailRecord["taxExempt"] = leadDetails["taxExempt"];
    mailRecord["dropOffLatLng"] = leadDetails["dropOffLatLng"];

    let data = {
      entityData: mailRecord,
    };
    actionFetchDispatchMail(data);
    this.setState({
      isModalData: false,
      isSendEmailModalVisible: false,
    });
  };

  showModal = (key) => {
    if (key === "4") {
      this.setState({ isDocModalVisible: true });
    } else if (key === "6") {
      this.setState({ isCallModalVisible: true });
    } else if (key === "3") {
      this.setState({
        isContactVisible: true,
        jobContactData: "",
      });
    } else {
      this.setState({
        notesPopup: true,
        recordNotesData: "",
      });
    }
  };

  editModal = (record) => {
    this.setState({ isContactVisible: true, jobContactData: record });
  };

  getAddress = (address, type) => {
    if (type === "pickup") {
      this.setState({
        selectedPickupAddress: address.label,
      });
    } else if (type === "dropOff") {
      this.setState({
        selectedDropOffAddress: address.label,
      });
    } else {
      this.setState({ selectedAddress: address.label });
    }
  };

  handleSelect = (address, type) => {
    const { dropoffLatitude, dropoffLongitude } = this.state;
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "pickup") {
            this.setState({
              loading: true,
            });
            let url =
              "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
              [dropoffLatitude, dropoffLongitude] +
              "&origins=" +
              [lat, lng] +
              "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
            let milesData = {
              entityData: {
                url,
              },
            };
            add("getMiles", milesData)
              .then((res) =>
                this.setState({
                  pickUpLatitude: lat,
                  pickupLongitude: lng,
                  milesData: res.data.distance,
                  duration: res.data.duration,
                  loading: false,
                })
              )
              .catch((err) => {
                this.setState({
                  loading: false,
                });
                console.log(err, "err");
              });
          } else if (type === "dropOff") {
            this.setState({ dropoffLatitude: lat, dropoffLongitude: lng });
          } else {
            this.setState({ latitude: lat, longitude: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onFinish = (values) => {
    const {
      leadDetails,
      selectedMaterial,
      updateJobIdContact,
      dropoffLatitude,
      dropoffLongitude,
      selectedDropOffAddress,
      quarryContacts,
      contactDetails,
      salesCommission,
      accountChargeLimit,
      invoiceNotes,
      documentsData,
    } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    if (accountChargeLimit !== "Cash Account") {
      let docSheet = [];
      docSheet = documentsData?.filter((e) => e.sheetStatus);
      if (docSheet?.length === 0) {
        message.error("Please upload Project Information Sheet");
        this.setState({
          isSheetModalVisible: true,
        });
        return true;
      }
    }

    let commonData = {
      company: leadDetails?.companyDetails?.company,
      companyName: leadDetails?.companyDetails?.companyName,
      contactperson: "",
      contactId: "",
      jobName: values["jobName"],
      jobType: values["jobType"],
      invoiceNotes: invoiceNotes,
      dropoff: selectedDropOffAddress,
      dropOffLatLng: {
        lat: dropoffLatitude,
        lng: dropoffLongitude,
      },
      multipleContacts: updateJobIdContact?.filter((e) =>
        e?.jobId?.includes(leadDetails?.id)
      ),
      notes:
        values["notes"] === ""
          ? ""
          : values["notes"] !== ""
          ? values["notes"]
          : leadDetails?.notes,
      salesPersonId: values["salesPerson"],
      salesCommission,
      accountChargeLimit: values["accountChargeLimit"],
    };
    let leadData = {
      entityData: {
        ...commonData,
        material: selectedMaterial,
        taxExempt: values.taxExempt,
        taxIncluded: values.taxIncluded,
        taxStatus: values.taxIncluded === "Tax Added" ? true : false,
      },
    };

    let quarryContactsData = [];
    let quarryContact = {};

    contactDetails?.filter((e) => {
      quarryContactsData = quarryContacts?.filter((q) => q.id !== e.id);
      return e;
    });
    quarryContact["entityData"] = quarryContactsData;
    this.updateMultipleContacts(
      quarryContact,
      id,
      leadData,
      quarryContactsData
    );
  };

  updateMultipleContacts = (
    quarryContact,
    id,
    leadData,
    quarryContactsData
  ) => {
    const { updateJobIdContact, leadQuote } = this.state;
    if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote) &&
      quarryContactsData?.length > 0
    ) {
      quarryContact["entityData"] =
        quarryContactsData.concat(updateJobIdContact);
      leadData["entityData"]["multipleContacts"] = quarryContact["entityData"];
      this.updateMultipleContact(quarryContact, id, leadData);
    } else if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote)
    ) {
      let updateData = {
        entityData: updateJobIdContact,
      };
      this.updateMultipleContact(updateData, id, leadData);
    } else if (quarryContactsData?.length > 0) {
      leadData["entityData"]["multipleContacts"] =
        quarryContactsData.concat(updateJobIdContact);
      this.addMultipleContact(quarryContact, id, leadData);
    } else {
      this.updateLead(id, leadData);
    }
  };

  updateMultipleContact = (quarryContact, id, leadData) => {
    updateTotal("updateMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.setState({
            internalLoading: true,
          });
          this.updateLead(id, leadData);
        } else {
          this.setState({
            internalLoading: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  addMultipleContact = (quarryContact, id, leadData) => {
    add("addMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.setState({
            internalLoading: true,
          });
          this.updateLead(id, leadData);
        } else {
          this.setState({
            internalLoading: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  updateJobMaterials = (data) => {
    this.setState({ loading: true });
    const { leadDetails } = this.state;
    let materialsData = leadDetails?.material;
    materialsData?.map((m) => {
      data?.map((d) => {
        if (d?.materialId === m?.id) {
          m["distance"] = d?.distance;
          m["duration"] = d?.duration;
        }
        return true;
      });
      return true;
    });
    let finalData = {
      entityData: {
        material: materialsData,
      },
    };
    this.updateLead(leadDetails?.id, finalData);
  };

  onFinishDispatch = (values) => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    const {
      leadDetails,
      selectedStatus,
      jobInfo,
      selectedMaterial,
      updateJobIdContact,
      dropoffLatitude,
      dropoffLongitude,
      selectedDropOffAddress,
      taxExempt,
      taxIncluded,
      taxStatus,
      documentsData,
      seperate,
    } = this.state;

    let accountPayableContact = updateJobIdContact?.filter(
      (e) => e?.contactRole === "Accounts Payable"
    );

    let commonData = {
      company: leadDetails?.companyDetails?.company,
      companyName: leadDetails?.companyDetails?.companyName,
      contactperson: "",
      contactId: "",
      jobName: leadDetails?.jobName,
      jobType: leadDetails?.jobType,
      dropoff: selectedDropOffAddress,
      dropOffLatLng: {
        lat: dropoffLatitude,
        lng: dropoffLongitude,
      },
      multipleContacts: updateJobIdContact?.filter((e) =>
        e?.jobId?.includes(leadDetails?.id)
      ),
      notes: leadDetails?.notes,
      salesPersonId: leadDetails?.salesPersonId,
      salesCommission: leadDetails?.salesCommission,
      accountChargeLimit: leadDetails?.accountChargeLimit || "Cash Account",
    };

    let statusCommonData = {
      ...commonData,
      companyName: leadDetails?.companyDetails?.company,
      companyAddress: leadDetails?.companyDetails?.address,
      stageId: selectedStatus,
      accountsPayableContact: accountPayableContact,
      accountTerms: leadDetails?.companyDetails?.accountTerms,
      jobNumber: leadDetails?.jobNumber,
    };

    if (statusCommonData.accountChargeLimit === "Charge Account") {
      let docSheet = [];
      docSheet = documentsData?.filter((e) => e.sheetStatus === "Approved");
      if (docSheet?.length === 0) {
        message.error("Need Approval Of Project Information Sheet!");
        return true;
      }
    }

    if (jobInfo === "Trucking Job") {
      let leadData = {
        entityData: {
          ...statusCommonData,
          material: selectedMaterial,
        },
      };
      leadData.entityData.material.map((e) => (e["assigned"] = false));
      this.verificationTruckingJob(id, leadData);
    } else if (jobInfo === "Trucking & Material Job") {
      let leadData = {
        entityData: {
          ...statusCommonData,
          taxExempt,
          taxIncluded,
          taxStatus,
          seperate: seperate ? seperate : false,
          material: selectedMaterial,
        },
      };
      leadData.entityData.material.map((e) => (e["assigned"] = false));
      this.verificationTuckingAndMaterial(id, leadData);
    } else if (jobInfo === "Both") {
      let materialLeadData = {
        entityData: {
          ...statusCommonData,
          taxExempt,
          taxIncluded,
          taxStatus,
          seperate: seperate ? seperate : false,
          material: selectedMaterial,
        },
      };
      materialLeadData.entityData.material.map((e) => (e["assigned"] = false));
      this.verificationTuckingAndMaterial(id, materialLeadData, "both");
    }
  };

  showMap = (quarryData) => {
    return (
      <MapComponent
        isMarkerShown
        quarryData={quarryData}
        generateEstimate
        jobProfile={false}
      />
    );
  };

  verificationTruckingJob = (id, data) => {
    const {
      selectedMaterial,
      leadDetails,
      updateJobIdContact,
      leadQuote,
      dropAddress,
    } = this.state;
    let truckingCostData = data.entityData?.material?.filter(
      (e) => e?.truckingCost
    );
    let truckingPriceData = data.entityData?.material?.filter(
      (e) => e?.truckingPrice
    );
    if (
      selectedMaterial[0]?.pickUpAddress !== "" &&
      selectedMaterial[0]?.pickUpAddress !== undefined &&
      selectedMaterial?.length !== 0
    ) {
      if (dropAddress !== "" && dropAddress !== undefined) {
        if (
          truckingCostData?.length === data?.entityData?.material?.length &&
          truckingPriceData?.length === data?.entityData?.material?.length
        ) {
          if (
            leadDetails?.companyDetails?.accountTerms !== "" &&
            leadDetails?.companyDetails?.accountTerms !== undefined
          ) {
            if (
              JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote)
            ) {
              let updateData = {
                entityData: updateJobIdContact,
              };
              this.addMultipleContact(updateData, id, data);
            } else {
              this.setState({
                dispatchModal: false,
              });
              this.updateLead(id, data);
            }
          } else {
            message.error(
              "Please Enter Account Terms In Customer Account Profile To Proceed Further"
            );
          }
        } else {
          message.error("Please Enter Trucking Cost And Trucking Price");
        }
      } else {
        message.info("Please Select Dropoff Location");
      }
    } else {
      message.info("Please Select Pickup Address");
    }
  };

  verificationTuckingAndMaterial = (id, data, type) => {
    const {
      selectedMaterial,
      leadDetails,
      contactDetails,
      quarryContacts,
      dropAddress,
    } = this.state;
    let newMaterial = data?.entityData?.material || [];
    let vendor = newMaterial?.filter((e) => e?.vendor);
    if (type === "both") {
      // let truckMaterial = newMaterial?.filter((e) => !e?.category);
      // if (
      //   truckMaterial[0]?.pickUpAddress === "" &&
      //   truckMaterial[0]?.pickUpAddress === undefined &&
      //   truckMaterial?.length === 0
      // ) {
      //   message.info("Please Select Pickup Address");
      //   return true;
      // }
      // let truckingCostData = truckMaterial?.material?.filter(
      //   (e) => e?.truckingCost
      // );
      // let truckingPriceData = truckMaterial?.material?.filter(
      //   (e) => e?.truckingPrice
      // );
      // if (
      //   truckingCostData?.length !== truckMaterial?.material?.length &&
      //   truckingPriceData?.length !== truckMaterial?.material?.length
      // ) {
      //   message.error("Please Enter Trucking Cost And Trucking Price");
      //   return true;
      // }
      newMaterial = newMaterial?.filter((e) => e?.category);
      vendor = vendor?.filter((e) => e?.category);
    }
    if (vendor?.length === newMaterial?.length) {
      if (selectedMaterial?.length > 0) {
        if (
          leadDetails.taxExempt !== undefined &&
          leadDetails.taxExempt !== ""
        ) {
          if (dropAddress !== "" && dropAddress !== undefined) {
            if (
              leadDetails?.companyDetails?.accountTerms !== "" &&
              leadDetails?.companyDetails?.accountTerms !== undefined
            ) {
              this.setState({
                dispatchModal: false,
              });
              let quarryContactsData = [];
              let quarryContact = {};
              contactDetails?.filter((e) => {
                quarryContactsData = quarryContacts?.filter(
                  (q) => q.id !== e.id
                );
                return e;
              });
              quarryContact["entityData"] = quarryContactsData;
              this.updateMultipleContacts(
                quarryContact,
                id,
                data,
                quarryContactsData
              );
            } else {
              message.error(
                "Please Enter Account Terms In Customer Account Profile To Proceed Further"
              );
            }
          } else {
            message.error("Please Select Dropoff Location");
          }
        } else {
          message.error("Please Select Tax exempt!");
        }
      } else {
        message.error("Please Add Atleast One Material");
      }
    } else {
      message.error("Please Add Vendor Details To Materials");
    }
  };

  onFinishDoc = (values) => {
    const { fileType, quoteAttachment, quoteAttachmentId, leadDetails } =
      this.state;
    this.setState({
      loading: true,
      isDocModalVisible: false,
    });
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    let data = {
      entityData: {
        leadId: this.props?.match?.params?.id,
        date: moment(values["date"]).format("L"),
        document: values["documentName"],
        docType: values["docType"],
        description: values["description"],
        docAttachment: quoteAttachment,
        docAttachmentId: quoteAttachmentId,
        fileType: fileType,
        assignedTo: leadDetails?.jobNumber,
      },
    };
    if (values["docType"] === "Project Information Sheet") {
      data["entityData"]["sheetStatus"] = "Pending";
      let submitData = {
        entityData: {
          projectInformationSheet: "Received",
        },
      };
      this.updateLead(id, submitData);
    }

    if (values["docType"] === "Tax Exemption Certificate") {
      let submitData = {
        entityData: {
          taxExempt: "Exempt",
        },
      };
      this.updateLead(id, submitData);
    }
    this.props.actionAddDoc(data, true);
  };

  onFinishNote = (values) => {
    const { recordNotesData } = this.state;
    this.setState({
      loading: true,
      notesPopup: false,
    });
    let data = {
      entityData: {
        leadId: this.props?.match?.params?.id,
        typeofNotes: values["type"],
        notes: values["notes"],
      },
    };
    if (recordNotesData !== "") {
      this.props.actionUpdateNotes(recordNotesData?.id, data);
    } else {
      this.props.actionAddNotes(data);
    }
  };

  onFinishCall = (values) => {
    this.setState({
      loading: true,
      isCallModalVisible: false,
    });
    let data = {
      entityData: {
        leadId: this.props?.match?.params?.id,
        date: moment(values["date"]).format("L"),
        time: moment(values["time"]).format("hh:mm A"),
        callStatus: values["callStatus"],
      },
    };
    this.addApicall(appConfig["entityNames"]["addCalls"], data);
  };

  fileDownloadQuote = async (fileName) => {
    const { quoteType, leadDetails } = this.state;
    let companyName = leadDetails?.companyName?.replace(/ /g, "");

    let savedFileName =
      quoteType === "estimate"
        ? `Generate Estimate_${companyName}`
        : `Generate Quote_${companyName}`;
    this.setState({
      internalLoading: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  fileDownload = async (fileName, downloadFileName, companyName) => {
    let savedFileName = downloadFileName + "_" + companyName?.replace(/ /g, "");
    this.setState({
      internalLoading: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    // a.target = "_blank";
    a.download = filename || "download";

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      internalLoading: false,
    });
    return a;
  }

  handleUploadPic = async (info, type) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList?.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Storage.get(result.key).then((urlResult) => {
                  if (type === "doc") {
                    this.setState({
                      quoteAttachment: urlResult,
                      imageLoading: false,
                      loading: false,
                      quoteAttachmentId: result.key,
                      fileType: info.file.type,
                    });
                  } else {
                    this.setState({
                      projectAttachment: urlResult,
                      imageLoading: false,
                      loading: false,
                      projectAttachmentId: result.key,
                      fileType: info.file.type,
                    });
                  }
                });
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  onClickStageId = (e) => {
    const { leadDetails, documentsData } = this.state;

    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    if (leadDetails.accountChargeLimit === "Charge Account") {
      let docSheet = [];
      docSheet = documentsData?.filter((e) => e.sheetStatus === "Approved");
      if (docSheet?.length === 0) {
        message.error("Need Approval Of Project Information Sheet!");
        return true;
      }
    }

    this.setState({
      selectedStatus: e,
      isStatusChanged: true,
    });

    this.updateStageId(e, id);
  };

  updateStageId = (e, id) => {
    const { leadDetails } = this.state;

    let commonData = {
      stageId: e,
    };
    if (e === "Dispatch Approved") {
      commonData["approvedDate"] = moment().format("l");
      let data = {
        entityData: {
          ...commonData,
        },
      };
      this.updateLead(id, data);
    } else if (e === "Build Quote") {
      this.setState({
        jobInfo: leadDetails?.jobType,
        jobEditModal: true,
        selectedMaterial: leadDetails?.material ? leadDetails?.material : [],
        selectedMaterialType: "",
        selectedDropOffAddress: leadDetails?.dropoff,
        dropAddress: leadDetails?.dropoff,
        leadQuote: leadDetails?.multipleContacts,
        dropoffLatitude: leadDetails?.dropOffLatLng?.lat,
        dropoffLongitude: leadDetails?.dropOffLatLng?.lng,
        taxExempt: leadDetails?.taxExempt,
        taxIncluded: leadDetails.taxIncluded,
        taxStatus: leadDetails.taxStatus,
        dispatchModal: true,
        seperate: true,
      });

      // let accountPayableContact = leadDetails?.contactDetails?.filter(
      //   (e) => e?.contactRole === "Accounts Payable"
      // );
      // // console.log(accountPayableContact, "accountPayableContact");
      // if (
      //   accountPayableContact?.length !== 0 &&
      //   accountPayableContact[0]?.phone !== "" &&
      //   accountPayableContact[0]?.phone !== undefined &&
      //   accountPayableContact[0]?.email !== "" &&
      //   accountPayableContact[0]?.email !== undefined
      // ) {

      // } else {
      //   if (
      //     accountPayableContact?.length > 0 &&
      //     (accountPayableContact[0]?.phone === "" ||
      //       accountPayableContact[0]?.phone === undefined ||
      //       accountPayableContact[0]?.email === "" ||
      //       accountPayableContact[0]?.email === undefined)
      //   ) {
      //     message.error("Please add Email & Phone to Accounts Payable Contact");
      //   } else {
      //     message.error(
      //       "Please add Accounts Payable Contact Information to Proceed"
      //     );
      //   }

      //   this.setState({
      //     selectedStatus: leadDetails?.stageId,
      //   });
      // }
    } else if (leadDetails?.material?.length > 0) {
      if (e === "Quote Accepted") {
        let data = {
          entityData: {
            ...commonData,
            quoteStatus: "Accepted",
          },
        };
        this.updateLead(id, data);
      } else {
        let data = {
          entityData: {
            ...commonData,
            quoteStatus: "",
          },
        };
        this.updateLead(id, data);
      }
    } else {
      message.error("Please add atleast one material !");
    }
  };

  updateLead = (id, data) => {
    this.props.actionUpdateJob(id, data);
  };

  onClickReturn = () => {
    this.props.history.goBack();
  };

  onClickCompany = (id) => {
    this.props.history.push(`/app/customer-account-profile/${id}`);
  };

  onChangeTrucksType = (values) => {
    this.setState({ selectedTruckType: values });
  };

  deleteMultipleContacts = (data) => {
    let deletContactsData = {
      entityData: data,
    };
    add(appConfig["entityNames"]["deleteMultipleContacts"], deletContactsData)
      .then()
      .catch((err) => console.log(err));
  };

  onFinishMaterial = (values) => {
    const {
      recordMaterialData,
      materialSelectedData,
      quantityType,
      jobInfo,
      pickUpLatitude,
      pickupLongitude,
      truckingPriceType,
      selectedPickupAddress,
      initialMaterialCost,
      duration,
      milesData,
      totalPriceType,
      materialCostType,
      truckingCostType,
      vendorDetails,
      topVendorsData,
      materialPriceState,
      quantity,
      materialTruckPrice,
      truckingCost,
      diameter,
      selectedMaterial,
    } = this.state;

    if (jobInfo === "Trucking Job") {
      let truckingData = {
        entityData: {
          id:
            materialSelectedData["id"] === undefined
              ? uuidv4()
              : materialSelectedData["id"],
          truckingPrice:
            parseFloat(values["truckingPrice"]) ||
            recordMaterialData?.truckingPrice,
          truckingPriceType: truckingPriceType,
          pickUpAddress: selectedPickupAddress,
          pickUpLatLng: {
            lat: pickUpLatitude,
            lng: pickupLongitude,
          },
          truckingCost:
            parseFloat(values["truckingCost"]) ||
            recordMaterialData?.truckingCost,
          truckingCostType: truckingCostType,
          duration: duration,
          milesData: milesData,
          // distance: recordMaterialData?.distance !== undefined &&  recordMaterialData?.distance,
          // duration: recordMaterialData?.duration !== undefined && recordMaterialData?.duration
        },
      };
      if (
        truckingData.entityData.pickUpAddress !== undefined &&
        truckingData.entityData.pickUpAddress !== ""
      ) {
        this.addMaterialOrTruckInfo(truckingData);
      } else {
        message.error("Please Enter PickUp Location");
      }
    } else {
      if (truckingPriceType === totalPriceType) {
        if (topVendorsData?.length === 0) {
          message.error("Please Find Nearest Vendors!");
          return true;
        }
        if (topVendorsData?.length !== 0 && vendorDetails === "") {
          message.error("Please select at least one Vendor!");
          return true;
        }

        let minD = values["materialMinDiameter"]?.replace('"', "");
        let maxD = values["materialMaxDiameter"]?.replace('"', "");
        let mD = values["materialDiameter"]?.replace('"', "");

        let materialMinDiameter = 0,
          materialMaxDiameter = 0,
          materialDiameter = 0,
          filteredData = [];

        if (diameter) {
          materialMinDiameter =
            minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
              ? this.DecimalToMixedFraction(minD)
              : minD;

          materialMaxDiameter =
            maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
              ? this.DecimalToMixedFraction(maxD)
              : maxD;
        } else {
          materialDiameter =
            mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
              ? this.DecimalToMixedFraction(mD)
              : mD;
        }

        selectedMaterial?.forEach((c) => {
          if (
            c.vendorId === vendorDetails?.vendorId &&
            c.id === materialSelectedData?.id &&
            (c.diameter
              ? c["materialMinDiameter"] === materialMinDiameter &&
                c["materialMaxDiameter"] === materialMaxDiameter
              : c["materialDiameter"] === materialDiameter)
          ) {
          } else {
            filteredData.push(c);
          }
        });
        if (
          filteredData.filter(
            (e) =>
              e.vendorId === vendorDetails?.id &&
              e.id === materialSelectedData["id"] &&
              (e.diameter
                ? e["materialMinDiameter"] === materialMinDiameter &&
                  e["materialMaxDiameter"] === materialMaxDiameter
                : e["materialDiameter"] === materialDiameter)
          )?.length > 0
        ) {
          message.error("You can't select same material with same vendor!");
          return true;
        }
        let materialData = {
          entityData: {
            id: materialSelectedData["id"],
            label: materialSelectedData["label"],
            value:
              vendorDetails?.material && vendorDetails?.material[0]["value"],
            category: materialSelectedData["category"],
            categoryKey: materialSelectedData["categoryKey"],
            subShortName: materialSelectedData["subShortName"],
            materialShortValue:
              materialSelectedData["categoryKey"] +
              " - " +
              materialSelectedData["subShortName"] +
              " - " +
              materialSelectedData["label"].split(" - ")[1],
            truckingPrice: materialTruckPrice,
            truckingPriceType: truckingPriceType,
            truckingCost: truckingCost,
            truckingCostType: truckingCostType,
            quantity: quantity,
            quantityType: quantityType,
            vendorId: vendorDetails?.id,
            vendor: vendorDetails?.quarryName,
            vendorTerms:
              vendorDetails?.accountTerms || materialSelectedData?.vendorTerms,
            vendorDiscountTerms:
              vendorDetails?.payDiscountTerms ||
              materialSelectedData?.vendorDiscountTerms,
            vendorDiscount:
              vendorDetails?.payDiscount ||
              materialSelectedData?.vendorDiscount,
            pickUpAddress: vendorDetails?.address,
            pickUpLatLng: {
              lat: vendorDetails?.lat,
              lng: vendorDetails?.lng,
            },
            materialCost: parseFloat(initialMaterialCost),
            materialCostType: materialCostType,
            totalPrice: materialPriceState,
            totalPriceType: totalPriceType,
            duration: vendorDetails?.duration || materialSelectedData?.duration,
            milesData:
              vendorDetails?.distance || materialSelectedData?.milesData,
            materialCostAtQuarryPerTon: parseFloat(initialMaterialCost),
            landedPrice:
              totalPriceType === truckingPriceType
                ? parseFloat(materialPriceState) + materialTruckPrice
                : "N/A",
            landedCost:
              materialCostType === truckingCostType &&
              initialMaterialCost &&
              truckingCost
                ? parseFloat(initialMaterialCost + truckingCost)?.toFixed(2)
                : "N/A",
            totalSale:
              materialPriceState && quantity && materialTruckPrice
                ? parseFloat(
                    (materialPriceState + materialTruckPrice) * quantity
                  )?.toFixed(2)
                : "N/A",
            totalExepense:
              initialMaterialCost && quantity && truckingCost
                ? parseFloat(
                    (initialMaterialCost + truckingCost) * quantity
                  )?.toFixed(2)
                : "",
            totalProfit:
              materialPriceState !== undefined &&
              materialPriceState !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              quantity !== undefined &&
              quantity !== "" &&
              initialMaterialCost !== undefined &&
              initialMaterialCost !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              truckingCost !== undefined &&
              truckingCost !== "" &&
              parseFloat(
                (materialPriceState + materialTruckPrice) * quantity -
                  (initialMaterialCost + truckingCost) * quantity
              )?.toFixed(2),
            totalProfitPercentage:
              materialPriceState !== undefined &&
              materialPriceState !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              quantity !== undefined &&
              quantity !== "" &&
              initialMaterialCost !== undefined &&
              initialMaterialCost !== "" &&
              materialTruckPrice !== undefined &&
              materialTruckPrice !== "" &&
              truckingCost !== undefined &&
              truckingCost !== "" &&
              (
                (((materialPriceState + materialTruckPrice) * quantity -
                  (initialMaterialCost + truckingCost) * quantity) /
                  ((materialPriceState + materialTruckPrice) * quantity)) *
                100
              )?.toFixed(2),
            materialDiameter: materialDiameter,
            materialStatus: values["materialStatus"],
            materialSupply: values["materialSupply"],
            materialTest: values["materialTest"],
            materialMaxDiameter: materialMaxDiameter,
            materialMinDiameter: materialMinDiameter,
            diameter,
          },
        };
        // console.log(materialData);

        this.addMaterialOrTruckInfo(materialData);
        this.addVendorContact(vendorDetails);
        // }
      } else {
        message.error(
          "Please Select Same Units For Material Price & Trucking Price"
        );
      }
    }
  };

  handleButtonClick = (action) => {
    const { selectedData, leadDetails } = this.state;
    if (selectedData === undefined || selectedData?.length === 0) {
      message.error("Please Select Atleast One Material!");
      return;
    }
    let combineMaterials = [];
    if (
      (leadDetails?.jobType === "Trucking & Material Job" ||
        leadDetails?.jobType === "Both") &&
      selectedData?.length > 0
    ) {
      const sameMaterialType = selectedData.filter(
        (e) => e?.category && e.totalPriceType === e.truckingCostType
      );
      sameMaterialType.forEach((e) => {
        e.materialTypeEstimate = "Landed";
        e.newQuantityType = e.totalPriceType;
      });

      const diffMaterialType = selectedData.filter(
        (e) => e?.category && e.totalPriceType !== e.truckingCostType
      );

      const removeKey = (array, keyToRemove) => {
        return array.map(({ [keyToRemove]: _, ...rest }) => ({
          ...rest,
          materialTypeEstimate:
            keyToRemove === "truckingPrice" ? "Material" : "Delivery",
          newQuantityType:
            keyToRemove === "truckingPrice"
              ? rest.totalPriceType
              : rest.truckingCostType,
        }));
      };
      let materialData = [],
        truckData = [];
      if (diffMaterialType?.length > 0) {
        materialData = removeKey(diffMaterialType, "truckingPrice");
        truckData = removeKey(diffMaterialType, "totalPrice");
      }
      combineMaterials = [...sameMaterialType, ...materialData, ...truckData];
    } else {
      combineMaterials = selectedData;
    }

    let data = {
      entityData: {
        material: combineMaterials,
        salesPersonId: leadDetails?.salesPersonId,
        accountChargeLimit: leadDetails?.accountChargeLimit,
        jobType: leadDetails?.jobType,
        jobNumber: leadDetails?.jobNumber,
        company: leadDetails?.company,
        dropoff: leadDetails?.dropoff,
        stageId: action === "estimate" ? "Generate Estimate" : "Generate Quote",
        leadId: leadDetails?.id,
        accountTerms: leadDetails?.accountTerms,
      },
    };
    this.setState({
      quoteType: action,
      internalLoading: true,
    });
    this.props.actionUpdateEstimate(data);
  };

  addVendorContact = (vendorDetails) => {
    const { leadDetails, quarryContacts } = this.state;
    let contactsData = quarryContacts?.length > 0 ? quarryContacts : [];
    vendorDetails?.contactDetails
      ?.filter((q) => q?.isPrimaryContact)
      ?.map((c) => {
        if (contactsData?.filter((t) => t.id === c.id)?.length > 0) {
        } else {
          contactsData.push({
            id: c["id"],
            materialId: c?.materialId,
            quarryId: vendorDetails?.id,
            jobId: [leadDetails?.id],
            firstName: c["firstName"],
            lastName: c["lastName"],
            phone: c["phone"],
            email: c["email"],
            notes: c["notes"],
            contactRole: c["contactRole"],
          });
        }
        return true;
      });
    this.setState({
      quarryContacts: contactsData,
    });
  };

  addMaterialOrTruckInfo = (data) => {
    const { selectedMaterial } = this.state;
    this.setState({
      loading: false,
    });
    let materialInfo = selectedMaterial?.length > 0 ? selectedMaterial : [];
    materialInfo.push(data["entityData"]);
    this.setState({
      pickupDetails: [],
      selectedMaterial: materialInfo,
      loading: false,
      quantityType: "Ton",
      selectedMaterialType: "",
      materialSelectedData: "",
      quantity: "",
      recordMaterialData: "",
      materialPopup: false,
      truckingCostType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      truckingPriceType: "Ton",
      selectedPickupAddress: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      finalMaterialData: [],
      filterResultData: [],
      duration: "",
      milesData: "",
      selectVendor: "",
      initialMaterialCost: "",
      materialPriceState: "",
      materialTruckPrice: "",
      materialCostPrice: "",
    });
    if (this.state.recordUpdate === true) {
      let leadData = {
        entityData: {
          material: materialInfo,
        },
      };
      this.updateLead(this.props.match.params.id, leadData);
    }
  };

  editMaterial = (data, type) => {
    if (this.state.leadDetails?.stageId !== "Dispatch Approved") {
      if (type === "recordUpdate") {
        this.setState({ recordUpdate: true });
      }
      const { jobInfo } = this.state;
      if (data !== "") {
        let filteredData = [];
        this.state?.selectedMaterial?.forEach((c) => {
          if (c.vendorId === data?.vendorId && c.id === data?.id) {
          } else {
            filteredData.push(c);
          }
        });
        this.setState({ selectedMaterial: filteredData });
      }
      if (jobInfo === "Trucking Job") {
        this.setState({
          materialPopup: true,
          recordMaterialData: data,
          selectedMaterialType: data?.category,
          materialSelectedData: data,
          truckingCostType: data?.truckingCostType
            ? data?.truckingCostType
            : "Ton",
          truckingPriceType: data?.truckingPriceType
            ? data?.truckingPriceType
            : "Ton",
          duration: data?.duration,
          milesData: data?.milesData,
          selectedPickupAddress: data?.pickUpAddress,
          pickUpLatitude: data?.pickUpLatLng?.lat,
          pickupLongitude: data?.pickUpLatLng?.lng,
          customRate: false,
          materialCustomRate: false,
        });
      } else {
        this.getVendorDetails(data);
      }
    }
  };

  getVendorDetails = (data) => {
    const { vendorData } = this.state;
    let vendor = vendorData?.filter((v) => v?.id === data?.vendorId);
    let categoriesList = vendor[0]?.material?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.category === value.category)
    );

    this.setState(
      {
        diameter: data?.diameter,
        customRate: false,
        materialCustomRate: false,
        pickupDetails: vendor,
        vendorDetails: vendor[0],
        vendorName: vendor[0]?.quarryName,
        selectedRowKeys: [vendor[0]?.id],
        selectVendor: vendor,
        categoriesList,
        milesData: data?.milesData,
        duration: data?.duration,
        materialValue: data?.category,
        materialPopup: true,
        recordMaterialData: data,
        useMarkUp: data.quantity,
        selectedMaterialType: data?.category,
        materialSelectedData: data,
        quantityType: data?.quantityType ? data?.quantityType : "Ton",
        quantity: data?.quantity,
        truckingCost: data?.truckingCost,
        truckingCostType: data?.truckingCostType
          ? data?.truckingCostType
          : "Ton",
        truckingPriceType: data?.truckingPriceType
          ? data?.truckingPriceType
          : "Ton",
        materialCostType: data?.materialCostType
          ? data?.materialCostType
          : "Ton",
        totalPriceType: data?.totalPriceType ? data?.totalPriceType : "Ton",
        initialMaterialCost: data?.materialCost,
        materialPriceState: data?.totalPrice,
        materialTruckPrice: data?.truckingPrice,
        materialCostPrice: data?.materialCostAtQuarryPerTon,
      },
      () => {
        this.getTopVendors(data);
        this.onSelectMaterialCat(data?.category, "edit");
      }
    );
  };

  onCancelMaterial = () => {
    const { recordMaterialData, selectedMaterial } = this.state;
    if (
      recordMaterialData !== "" &&
      recordMaterialData !== undefined &&
      recordMaterialData?.length !== 0
    ) {
      let materialInfo = selectedMaterial?.length > 0 ? selectedMaterial : [];
      materialInfo.push(recordMaterialData);
      this.setState({
        selectedMaterial: materialInfo,
        recordMaterialData: "",
        selectedMaterialType: "",
        materialPopup: false,
        filterResultData: [],
        finalMaterialData: [],
        initialMaterialCost: "",
        pickupDetails: [],
        materialPriceState: "",
        duration: "",
        milesData: "",
        selectVendor: "",
        materialCostPrice: "",
        materialSelectedData: "",
        vendorDetails: "",
        quantity: "",
        materialTruckPrice: "",
        truckingCost: "",
        customRate: false,
        materialCustomRate: false,
      });
    } else {
      this.setState({ selectedMaterialType: "", materialPopup: false });
    }
  };

  deleteMaterial = (record) => {
    if (this.state.jobInfo === "Trucking Job") {
      let materialInfo = this.state.selectedMaterial.filter(
        (c) => c.id !== record.id
      );
      this.setState({
        selectedMaterial: materialInfo,
      });
      message.info("Trucking Details Deleted Successfully");
    } else {
      let filteredData = [];
      this.state?.selectedMaterial?.forEach((c) => {
        if (c.vendorId === record?.vendorId && c.id === record?.id) {
        } else {
          filteredData.push(c);
        }
      });
      this.setState({
        selectedMaterial: filteredData,
      });
      message.info("Material Deleted Successfully!");
    }
  };

  onSelectMaterialCat = (e, type) => {
    const { materialList } = this.state;
    if (type === "edit") {
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    } else {
      let materialsData = [];
      materialList?.map((newMat) => {
        if (e === newMat?.category) {
          materialsData.push(newMat);
        }
        return true;
      });
      if (e) {
        this.formRef.current.setFieldsValue({ material: undefined, price: "" });
        this.setState({
          materialSelectedData: "",
          topVendorsData: [],
          vendorDetails: "",
          selectedRowKeys: [],
        });
      }
      this.setState({
        selectedMaterialType: e,
        finalMaterialData: materialsData,
      });
    }
  };

  onSelectMaterial = (e) => {
    const { finalMaterialData } = this.state;
    finalMaterialData?.filter(
      (f) => f.id === e && this.setState({ materialSelectedData: f })
    );
    this.setState({
      topVendorsData: [],
      vendorDetails: "",
      selectedRowKeys: [],
    });
  };

  onFinishContact = (values) => {
    const { leadDetails, jobContactData } = this.state;
    this.setState({ isContactVisible: false });

    let contactsData = {
      entityData: {
        companyId: leadDetails?.companyId,
        firstName: values["fName"],
        lastName: values["lName"],
        phone: values["phone"],
        email: values["email"],
        notes: values["notes"],
        jobId: jobContactData?.jobId
          ? jobContactData?.jobId
          : [leadDetails?.id],
        contactRole:
          values["role"] === "Add New Role"
            ? values["roleOther"]
            : values["role"],
      },
    };
    if (jobContactData !== "") {
      this.updateContact(contactsData);
    } else {
      this.addContact(contactsData);
    }
    // console.log(contactsData, "contactsData");
    if (this.state.roleCheckBox) {
      this.updateRolesData(values["roleOther"]);
    }
  };

  updateRolesData = (values) => {
    let rolesData = this.state.rolesData;
    rolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: rolesData?.length + 1,
    });
    let data = {
      entityData: {
        rolesData,
        typeofDropDown: "roles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.rolesData[0]?.id, data);
  };

  addContact = (data) => {
    this.props.actionAddContact(data);
  };

  updateContact = (data) => {
    this.props.actionUpdateContact(this.state?.jobContactData?.id, data);
  };

  addContactToJob = (newContact) => {
    this.setState({ selectedContacts: [] });
    const { leadDetails } = this.state;
    let existContactData = leadDetails?.multipleContacts;
    existContactData.push(newContact);
    let data = {
      entityData: {
        multipleContacts: existContactData,
      },
    };
    this.updateLead(leadDetails?.id, data);
  };

  handleSelectContact = (e) => {
    const { contactsData, leadDetails } = this.state;
    contactsData?.map((contact) => {
      if (e?.includes(contact?.id)) {
        if (
          contact["jobId"]?.length > 0 &&
          !contact["jobId"]?.includes(leadDetails?.id)
        ) {
          contact["jobId"].push(leadDetails?.id);
        } else {
          contact["jobId"] =
            contact["jobId"]?.length > 0 ? contact["jobId"] : [leadDetails?.id];
        }
        return contact;
      } else {
        if (contact["jobId"]?.includes(leadDetails?.id)) {
          contact["jobId"] = contact["jobId"]?.filter(
            (ct) => ct !== leadDetails?.id
          );
        }
      }
      return contact;
    });
    this.setState({ contactId: e, updateJobIdContact: contactsData });
  };

  onSelectVendor = (value, option) => {
    this.formRef.current.setFieldsValue({
      material: "",
      quantity: "",
      materialCategory: "",
      materialPriceState: "",
      totalPrice: "",
    });

    this.setState({
      loading: true,
    });
    const { vendorData, dropoffLatitude, dropoffLongitude } = this.state;
    let data = vendorData?.filter((e) => e?.id === option?.key);
    let url =
      "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
      [dropoffLatitude, dropoffLongitude] +
      "&origins=" +
      [data[0]?.lat, data[0]?.lng] +
      "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";

    let milesData = {
      entityData: {
        url,
      },
    };

    let categoriesList = data[0]?.material?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.category === value.category)
    );

    add("getMiles", milesData)
      .then((res) =>
        this.setState({
          newVendor: value,
          selectVendor: data,
          categoriesList,
          selectedMaterialType: "",
          finalMaterialData: [],
          pickupDetails: data,
          milesData: res.data.distance,
          duration: res.data.duration,
          loading: false,
          materialPriceState: "",
        })
      )
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err, "err");
      });
  };

  onChangeVendor = (e) => {
    if (e !== undefined) {
      this.setState({
        vendorName: e,
        materialTruckPrice: "",
      });
    } else {
      this.setState({
        initialMaterialCost: "",
        materialPriceState: "",
        materialCostState: "",
        materialTruckPrice: "",
        selectVendor: [],
        milesData: "",
        duration: "",
        vendorName: "",
        categoriesList: [],
      });
      this.formRef.current.setFieldsValue({
        material: "",
        quantity: "",
        materialCategory: "",
        totalPrice: "",
        truckingPrice: "",
      });
    }
  };

  deleteContact = (data) => {
    this.props?.actionDeleteContact(data.id);
  };

  editNotes = (data) => {
    this.setState({
      notesPopup: true,
      recordNotesData: data,
    });
  };

  resetvendorValues = () => {
    this.setState({
      topVendorsData: [],
      selectedRowKeys: [],
    });
  };

  DecimalToMixedFraction = (val) => {
    // eslint-disable-next-line no-eval
    const decimal = eval(val);
    // Split the decimal into whole and fractional parts
    const wholeNumber = Math.floor(decimal);
    const fractionalPart = decimal - wholeNumber;
    // Convert the fractional part to a fraction
    const fraction = this.decimalToFraction(fractionalPart);

    // Combine the whole number and fraction
    const mixedFraction =
      wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : `${fraction}`;

    return mixedFraction;
  };

  decimalToFraction = (decimal) => {
    const EPSILON = 1.0e-9;
    let numerator = decimal;
    let denominator = 1;

    while (Math.abs(Math.round(numerator) - numerator) > EPSILON) {
      numerator *= 10;
      denominator *= 10;
    }

    const gcd = this.getGreatestCommonDivisor(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  };

  getGreatestCommonDivisor = (a, b) => {
    if (b === 0) {
      return a;
    }
    return this.getGreatestCommonDivisor(b, a % b);
  };

  getTopVendors = (values) => {
    const {
      materialSelectedData,
      dropoffLatitude,
      dropoffLongitude,
      diameter,
    } = this.state;

    let materialMinDiameter = 0,
      materialMaxDiameter = 0,
      materialDiameter = 0;

    let minD = values["materialMinDiameter"]?.replace('"', "");
    let maxD = values["materialMaxDiameter"]?.replace('"', "");
    let mD = values["materialDiameter"]?.replace('"', "");

    if (diameter) {
      materialMinDiameter =
        minD?.split(" ")?.length !== 2 && String(parseInt(minD)) !== minD
          ? this.DecimalToMixedFraction(minD)
          : minD;

      materialMaxDiameter =
        maxD?.split(" ")?.length !== 2 && String(parseInt(maxD)) !== maxD
          ? this.DecimalToMixedFraction(maxD)
          : maxD;
    } else {
      materialDiameter =
        mD?.split(" ")?.length !== 2 && String(parseInt(mD)) !== mD
          ? this.DecimalToMixedFraction(mD)
          : mD;
    }

    if (materialSelectedData !== "") {
      this.setState({
        loading: true,
        initialMaterialCost: materialSelectedData?.materialCost
          ? materialSelectedData?.materialCost
          : "",
      });

      let data = {
        entityData: {
          material: materialSelectedData,
          dropOffLatLng: { lat: dropoffLatitude, lng: dropoffLongitude },
          materialDiameter: materialDiameter,
          materialStatus: values["materialStatus"],
          materialSupply: values["materialSupply"],
          materialTest: values["materialTest"],
          materialMaxDiameter: materialMaxDiameter,
          materialMinDiameter: materialMinDiameter,
          diameter,
        },
      };
      this.props?.actionFetchTopVendors(data);
    } else {
      message.error("Please Select Material!");
    }
  };

  handleCheckStatus = (e) => {
    this.setState({ diameter: e });
    this.formRef.current.setFieldsValue({
      materialDiameter: "",
      materialMinDiameter: "",
      materialMaxDiameter: "",
    });
  };

  handleTableChage = (props, type) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, [type]: props })
    );
  };

  onFinishSheet = () => {
    const { fileType, projectAttachment, projectAttachmentId, leadDetails } =
      this.state;
    this.setState({
      isSheetModalVisible: false,
    });
    let data = {
      entityData: {
        date: moment().format("L"),
        leadId: this.props?.match?.params?.id,
        document: "Project Information Sheet",
        docAttachment: projectAttachment,
        docAttachmentId: projectAttachmentId,
        fileType: fileType,
        assignedTo: leadDetails?.jobNumber,
        docType: "Account Charge Limit",
        sheetStatus: "Pending",
      },
    };
    this.props.actionAddDoc(data, false);
    message.success("Project Information Sheet uploaded successfully!");
  };

  updateSheetStatus = (status, docId) => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    let data = {
      entityData: {
        sheetStatus: status,
      },
    };
    this.props.actionUpdateDoc(docId, data);

    if (status === "Approved" || status === "Rejected") {
      let data = {
        entityData: {
          accountChargeLimit:
            status === "Approved" ? "Charge Account" : "Cash Account",
          projectInformationSheet:
            status === "Approved" ? "Received" : "Not Received",
        },
      };
      this.updateLead(id, data);
    }
  };

  reDirectUrl = (id) => {
    const { leadDetails } = this.state;
    return this.props.history.push(
      `/app/updateJobDetails/${leadDetails.id}?${id}`
    );
  };

  handleCopy = () => {
    const { mailRecord, leadDetails } = this.state;
    const truckingData = [
      {
        title: "Delivery Date",
        value: moment(leadDetails?.dispatchData?.dispatchDate)?.format(
          "dddd, MM/DD/YYYY"
        ),
      },
      { title: "Customer", value: leadDetails?.companyName },
      { title: "Job Name", value: leadDetails?.jobName },
      {
        title: "Contact Name",
        value:
          leadDetails?.contactDetails[0]?.firstName +
          leadDetails?.contactDetails[0]?.lastName,
      },
      { title: "Contact Number", value: leadDetails?.contactDetails[0]?.phone },

      { title: "Job Type", value: leadDetails?.jobType },
      {
        title: "Quantity Requested",
        value: `${leadDetails?.dispatchData?.trucksRequested} ${leadDetails?.dispatchData?.trucksRequestedType}`,
      },
      { title: "Approved Trucks", value: mailRecord?.truckType.join(", ") },

      { title: "Pickup Location", value: mailRecord?.pickUpAddress },
      {
        title: "Pindrop Location",
        value:
          mailRecord?.pickUpLatLng?.lat + ", " + mailRecord?.pickUpLatLng?.lng,
      },

      { title: "Delivery Location", value: leadDetails?.dropoff },
      {
        title: "Pindrop Location",
        value:
          leadDetails?.dropOffLatLng?.lat +
          ", " +
          leadDetails?.dropOffLatLng?.lng,
      },

      {
        title: "Rate Information",
        value: `$${parseFloat(mailRecord?.truckingPrice)?.toLocaleString(
          "us-EN",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )} per ${mailRecord?.truckingCostType.toLowerCase()}`,
      },
      {
        title: "Minimum Hours",
        value: mailRecord?.hourlyCharge
          ? `${mailRecord?.hourlyCharge}-Hour Minimum`
          : "6-Hour Minimum",
      },
    ];

    const materialData = [
      {
        title: "Date",
        value: moment(leadDetails?.dispatchData?.dispatchDate)?.format(
          "dddd, MM/DD/YYYY"
        ),
      },
      { title: "Customer", value: leadDetails?.companyName },
      { title: "Job Name", value: leadDetails?.jobName },
      {
        title: "Contact Name",
        value:
          leadDetails?.contactDetails[0]?.firstName +
          leadDetails?.contactDetails[0]?.lastName,
      },
      { title: "Contact Number", value: leadDetails?.contactDetails[0]?.phone },
      { title: "Job Type", value: leadDetails?.jobType },
      { title: "Material Notes", value: leadDetails?.requiredMaterials },
      { title: "Approved Trucks", value: mailRecord?.truckType.join(", ") },

      { title: "Pickup Location", value: mailRecord?.pickUpAddress },
      {
        title: "Pindrop Location",
        value:
          mailRecord?.pickUpLatLng?.lat + ", " + mailRecord?.pickUpLatLng?.lng,
      },

      { title: "Delivery Location", value: leadDetails?.dropoff },
      {
        title: "Pindrop Location",
        value:
          leadDetails?.dropOffLatLng?.lat +
          ", " +
          leadDetails?.dropOffLatLng?.lng,
      },

      { title: "Vendor Name", value: mailRecord?.vendor },

      {
        title: "Material Information",
        value: `${mailRecord?.category} ${
          mailRecord?.value?.split(" - ")[2]
        }, ${mailRecord?.quantity} ${mailRecord?.quantityType}s `,
      },
      {
        title: "Trucking",
        value: `${parseFloat(mailRecord?.truckingPrice)?.toLocaleString(
          "us-EN",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )} per ${mailRecord?.truckingCostType.toLowerCase()}, ${
          leadDetails?.taxExempt
        }`,
      },
      {
        title: "Material",
        value: `${parseFloat(mailRecord?.totalPrice)?.toLocaleString("us-EN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} per ${mailRecord?.totalPriceType.toLowerCase()}, ${
          leadDetails?.taxExempt
        }`,
      },
      {
        title: "Quarry Cost",
        value: `${parseFloat(mailRecord?.landedPrice)?.toLocaleString("us-EN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} per ${mailRecord?.totalPriceType.toLowerCase()}`,
      },
    ];

    const textToCopy =
      leadDetails?.jobType === "Trucking Job"
        ? truckingData.map((item) => `${item.title}: ${item.value}`).join("\n")
        : materialData.map((item) => `${item.title}: ${item.value}`).join("\n");

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Dispatch Info copied to clipboard");
      })
      .catch((err) => {
        message.error("Failed to copy data");
      });
  };

  showTabRightButton = (keyValue) => {
    if (keyValue === "5") {
      return (
        <Row
          style={{
            marginRight: "10px",
          }}
        >
          <span
            style={{
              marginRight: "3px",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            All Invoices
          </span>
          <Switch
            className="switchcolor"
            onChange={(e) => this.switchInvoices(e)}
          />
        </Row>
      );
    } else if (
      keyValue !== "1" &&
      keyValue !== "7" &&
      keyValue !== "8" &&
      keyValue !== "9" &&
      keyValue !== "2" &&
      keyValue !== "5"
    ) {
      return (
        <Button
          type="primary"
          htmlType="submit"
          icon={<PlusOutlined />}
          style={{
            height: "35px",
            borderRadius: "5px",
            marginRight: "10px",
          }}
          onClick={() => this.showModal(keyValue)}
        >
          {keyValue === "6"
            ? "Notes"
            : keyValue === "4"
            ? "Document"
            : keyValue === "3" && "Contact"}
        </Button>
      );
    }
  };

  showBaseTable = (
    columnsData,
    source,
    tableChange,
    sizeRange,
    rowsSelection
  ) => {
    return (
      <BaseTable
        columnsData={columnsData}
        source={source}
        handleTableChage={(e) => this.handleTableChage(e, tableChange)}
        sizeRange={
          window.screen.width > 1023
            ? JSON.parse(localStorage.getItem("tableSizeRange"))?.jobDetails
            : sizeRange
        }
        pagination={window.screen.width > 1023 ? true : false}
        className="table_laptopscreen"
        rowSelection={rowsSelection}
        rowKey={(record) => record.id}
      />
    );
  };

  render() {
    const {
      isSheetModalVisible,
      isModalData,
      isSendEmailModalVisible,
      mailRecord,
      useMarkUp,
      materialCategory,
      customRate,
      materialCustomRate,
      truckingPriceType,
      totalPriceType,
      duration,
      milesData,
      selectedStatus,
      citySearch,
      imageLoading,
      documentsData,
      quoteAttachment,
      materialTruckPrice,
      isCallModalVisible,
      isDocModalVisible,
      quarryData,
      jobInfo,
      newRole,
      selectedMaterial,
      recordMaterialData,
      isContactVisible,
      jobContactData,
      materialPopup,
      dispatchModal,
      leadDetails,
      initialMaterialCost,
      materialPriceState,
      notesData,
      notesPopup,
      recordNotesData,
      fileType,
      filterInvoicesData,
      rolesData,
      trucksData,
      finalMaterialData,
      topVendorsData,
      selectedRowKeys,
      truckingCost,
      quantity,
      materialCostType,
      truckingCostType,
      internalLoading,
      diameter,
      invoiceNotes,
      keyValue,
    } = this.state;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const rowsSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedData: selectedRows,
        });
      },
    };

    const accountStatus = [
      { label: "Approve", value: "Approved" },
      { label: "Reject", value: "Rejected" },
    ];
    const { loading, errorLoading } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    let today = moment().add(30, "days").calendar();

    const invoiceStatusview = (record) => (
      <div>
        {accountStatus?.map((e) => (
          <Row
            onClick={() => this.updateSheetStatus(e.value, record.id)}
            style={{
              cursor: "pointer",
            }}
          >
            <Button
              className={e.label === "Approve" ? "approveLimit" : "closeLimit"}
              style={{
                width: "100px",
                marginBottom: "5px",
                background: "#FFF",
                color: e.label === "Approve" ? "green" : "red",
                border:
                  e.label === "Approve" ? "1px solid green" : "1px solid red",
              }}
              icon={
                e.label === "Approve" ? <CheckOutlined /> : <CloseOutlined />
              }
            >
              <span className="col_styling" style={{ marginLeft: "5px" }}>
                {e.label}
              </span>
            </Button>
          </Row>
        ))}
      </div>
    );

    const topVendorsColumns = [
      {
        title: "VENDOR NAME",
        dataIndex: "quarryName",
        key: "quarryName",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (quarryName, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {quarryName}
            </Row>
          );
        },
      },
      {
        title: "RUN & RATE INFORMATION",
        dataIndex: "distance",
        key: "distance",
        sorter: false,
        width: "40%",
        className: "col_style_quarry",
        render: (distance, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {distance + "les, " + record?.duration}
            </Row>
          );
        },
      },
      {
        title: "COST / Ton",
        dataIndex: "material",
        key: "material",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (material, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
              onClick={() => this.onSelectChange([record.id], [record])}
            >
              {"$" + material[0]?.price?.toFixed(2)}
            </Row>
          );
        },
      },
    ];

    const truckingJobColumns = (type) =>
      [
        {
          title: "Order#",
          dataIndex: "orderNumber",
          key: "orderNumber",
          sorter: true,
          show: false,
          width: "100px",
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (orderNumber) => {
            return <span className="col_style_quarry">{orderNumber}</span>;
          },
        },
        {
          title: "Pickup Location",
          dataIndex: "pickUpAddress",
          key: "pickUpAddress",
          sorter: false,
          width: "18%",
          className: "col_style_quarry",
          show: type === "order" ? false : true,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (pickUpAddress) => (
            <Tooltip placement="topLeft" title={pickUpAddress}>
              {pickUpAddress}
            </Tooltip>
          ),
        },
        {
          title: "Dropoff Location",
          dataIndex: "leadDetails",
          key: "leadDetails",
          sorter: false,
          width: "18%",
          className: "col_style_quarry",
          show: type === "order" ? false : true,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: () => (
            <Tooltip placement="topLeft" title={leadDetails?.dropoff}>
              <span className="col_style_quarry">{leadDetails?.dropoff}</span>
            </Tooltip>
          ),
        },

        {
          title: "Material Name",
          dataIndex: "materialName",
          key: "materialName",
          sorter: false,
          width: "18%",
          show: true,
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (materialName) => {
            return <div style={{ display: "flex" }}>{materialName}</div>;
          },
        },
        {
          title: type === "order" ? "Trucks Type" : "Requested Trucks",
          dataIndex: "truckType",
          key: "truckType",
          sorter: false,
          width: "18%",
          show: type === "order" ? false : true,
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (truckType) => {
            const truckCodes = trucksData
              ?.filter((e) => truckType?.includes(e.value))
              .map((e) => e.truck_code);

            const commaSeparatedCodes = truckCodes.join(", ");

            return (
              <span className="col_style_quarry">
                <div style={{ display: "flex" }}>{commaSeparatedCodes}</div>
              </span>
            );
          },
        },
        {
          title: "Assigned Trucks",
          dataIndex: "",
          key: "",
          sorter: false,
          width: "100px",
          show: false,
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (record) => {
            return (
              <div style={{ display: "flex" }}>
                {record?.material[0]?.assignTrucks?.join(", ")}
              </div>
            );
          },
        },
        {
          title: "Trucking Cost",
          dataIndex: "truckingCost",
          key: "truckingCost",
          sorter: false,
          className: "col_style_quarry",
          width: "150px",
          show: true,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (truckingCost, record) => {
            return (
              <span className="col_style_quarry">
                {truckingCost &&
                  `$${parseFloat(truckingCost)?.toFixed(2)} / ${
                    record?.truckingCostType
                  }`}
              </span>
            );
          },
        },
        {
          title: "Trucking Price",
          dataIndex: "truckingPrice",
          key: "truckingPrice",
          sorter: false,
          className: "col_style_quarry",
          width: "150px",
          show: true,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (truckingPrice, record) => {
            return (
              <span className="col_style_quarry">
                {truckingPrice &&
                  `$${parseFloat(truckingPrice)?.toFixed(2)} / ${
                    record?.truckingPriceType
                  }`}
              </span>
            );
          },
        },
        {
          title: "Drive Miles",
          dataIndex: "milesData",
          key: "milesData",
          sorter: false,
          width: "8%",
          className: "col_style_quarry",
          show: true,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (milesData) => {
            return (
              <span className="col_style_quarry">
                {milesData !== undefined && (
                  <div style={{ textTransform: "capitalize" }}>
                    {milesData?.text + "les"}
                  </div>
                )}
                {/* <div style={{ textTransform: "capitalize" }}>
                {record?.duration?.text}
              </div> */}
              </span>
            );
          },
        },
        {
          title: "Drive Minutes",
          dataIndex: "milesData",
          key: "milesData",
          sorter: false,
          width: "13%",
          className: "col_style_quarry",
          show: true,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (milesData, record) => {
            return (
              <div style={{ textTransform: "capitalize" }}>
                {record?.duration?.text}
              </div>
            );
          },
        },
        {
          title: "Run Minutes",
          dataIndex: "",
          key: "runMintues",
          sorter: false,
          width: "13%",
          className: "col_style_quarry",
          show: true,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          // fixed: "right",
          render: (record) => {
            const { ticketsInfo } = this.state;
            let filterData = ticketsInfo?.filter(
              (e) => e.material[0]?.materialId === record?.materialId
            );
            return (
              <span className="col_style_quarry">
                {filterData?.length > 0 && (
                  <div style={{ textTransform: "capitalize" }}>
                    {this.formatTimeDifference(
                      this.calculateTimeDifferences(filterData)
                    )}
                  </div>
                )}
              </span>
            );
          },
        },
        {
          title: "Minimum Hours",
          dataIndex: "hourlyCharge",
          key: "hourlyCharge",
          sorter: false,
          width: "10%",
          className: "col_style_quarry",
          show:
            leadDetails?.material?.filter(
              (e) =>
                e.truckingCostType === "Hour" || e.truckingPriceType === "Hour"
            )?.length > 0,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          // fixed: "right",
          render: (hourlyCharge) => {
            return (
              <span className="col_style_quarry">
                {hourlyCharge && `${hourlyCharge} Hours`}
              </span>
            );
          },
        },
        {
          title: "Dispatch Info",
          dataIndex: "",
          key: "",
          sorter: false,
          width: "10%",
          className: "col_style_quarry",
          show: true,
          fixed: "right",
          render: (record) => {
            return (
              leadDetails?.dispatchData && (
                <div>
                  <Tooltip title="Dispatch Info">
                    <EyeOutlined
                      onClick={() => this.showDataModal(record)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontSize: "25px",
                        color: "#586370",
                      }}
                    />
                  </Tooltip>
                </div>
              )
            );
          },
        },
      ]?.filter((e) => (type === "order" ? !e.show : e.show));

    const materialJobColumns = (type) =>
      [
        {
          title: "Order#",
          dataIndex: "orderNumber",
          key: "orderNumber",
          sorter: true,
          show: false,
          width: "100px",
          fixed: "left",
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (orderNumber) => {
            return <span className="col_style_quarry">{orderNumber}</span>;
          },
        },
        {
          title: "Vendor",
          dataIndex: "vendor",
          key: "vendor",
          sorter: false,
          show: type === "order" ? false : true,
          width: "100px",
          fixed: "left",
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (vendor, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order" ? record.material[0]?.vendor : vendor}
              </span>
            );
          },
        },
        {
          title: "Material",
          dataIndex: "category",
          key: "category",
          sorter: false,
          show: type === "order" ? false : true,
          width: "120px",
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (category, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order" ? (
                  <>
                    {/* <div>{`${record?.trucksRequested} ${record?.trucksRequestedType}`}</div> */}
                    <div>{record?.material[0]?.category}</div>
                    <div>{record?.material[0]?.value?.split(" - ")[2]}</div>
                  </>
                ) : (
                  <>
                    <div>{`${record?.quantity} ${record?.quantityType}s`}</div>
                    <div>{category}</div>
                    <div>{record?.value?.split(" - ")[2]}</div>
                  </>
                )}
              </span>
            );
          },
        },
        {
          title: "Dispatch Quantity",
          dataIndex: "",
          key: "",
          sorter: false,
          show: false,
          width: "150px",
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (record) => {
            return (
              <span className="col_style_quarry">
                <div>{`${record?.trucksRequested} ${record?.trucksRequestedType}`}</div>
              </span>
            );
          },
        },
        {
          title: "Assigned Trucks",
          dataIndex: "",
          key: "",
          sorter: false,
          width: "100px",
          show: false,
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (record) => {
            return (
              <div style={{ display: "flex" }}>
                {record.material[0].assignTrucks?.join(", ")}
              </div>
            );
          },
        },
        {
          title: type === "order" ? "Trucks Type" : "Requested Trucks",
          dataIndex: "truckType",
          key: "truckType",
          sorter: false,
          width: "150px",
          show: type === "order" ? false : true,
          className: "col_style_quarry",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (truckType) => {
            const truckCodes = trucksData
              ?.filter((e) => truckType.includes(e.value))
              .map((e) => e.truck_code);

            const commaSeparatedCodes = truckCodes.join(", ");
            return (
              <span className="col_style_quarry">{commaSeparatedCodes}</span>
            );
          },
        },
        {
          title: "Material (C)",
          dataIndex: "materialCost",
          key: "materialCost",
          sorter: false,
          show: true,
          className: "col_style_quarry",
          width: "75px",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (materialCost, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order"
                  ? record?.material[0]?.materialCost &&
                    `$${record?.material[0]?.materialCost?.toLocaleString(
                      "us-EN",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )} / ${record?.material[0]?.materialCostType}s`
                  : materialCost &&
                    `$${materialCost?.toLocaleString("us-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} / ${record?.materialCostType}s`}
              </span>
            );
          },
        },
        {
          title: "Material (P)",
          dataIndex: "totalPrice",
          key: "totalPrice",
          sorter: false,
          show: true,
          className: "col_style_quarry",
          width: "75px",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (totalPrice, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order"
                  ? record?.material[0]?.totalPrice &&
                    `$${parseFloat(
                      record?.material[0]?.totalPrice
                    )?.toLocaleString("us-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} / ${record?.material[0]?.totalPriceType}s`
                  : totalPrice &&
                    `$${parseFloat(totalPrice)?.toLocaleString("us-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} / ${record?.totalPriceType}s`}
              </span>
            );
          },
        },
        {
          title: "Trucking (C)",
          dataIndex: "truckingCost",
          key: "truckingCost",
          sorter: false,
          show: true,

          className: "col_style_quarry",
          width: "75px",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (truckingCost, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order"
                  ? record?.material[0]?.truckingCost &&
                    `$${parseFloat(
                      record?.material[0]?.truckingCost
                    )?.toLocaleString("us-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} / ${record?.material[0]?.truckingCostType}s`
                  : truckingCost &&
                    `$${parseFloat(truckingCost)?.toLocaleString("us-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} / ${record?.truckingCostType}s`}
              </span>
            );
          },
        },
        {
          title: "Trucking (P)",
          dataIndex: "truckingPrice",
          key: "truckingPrice",
          sorter: false,
          className: "col_style_quarry",
          width: "75px",
          show: true,

          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (truckingPrice, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order"
                  ? record.material[0]?.truckingPrice &&
                    `$${parseFloat(
                      record?.material[0]?.truckingPrice
                    )?.toLocaleString("us-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} / ${record.material[0]?.truckingPriceType}s`
                  : truckingPrice &&
                    `$${parseFloat(truckingPrice)?.toLocaleString("us-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} / ${record?.truckingPriceType}s`}
              </span>
            );
          },
        },
        {
          title: "Landed (P)",
          dataIndex: "landedPrice",
          key: "landedPrice",
          className: "col_style_quarry",
          width: "75px",
          show: true,

          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (landedPrice, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order"
                  ? record.material[0]?.landedPrice && landedPrice === "N/A"
                    ? "N/A"
                    : `$${parseFloat(
                        record?.material[0]?.landedPrice
                      )?.toLocaleString("us-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} / ${
                        record?.material[0]?.landedPriceType ||
                        record?.material[0]?.totalPriceType
                      }s`
                  : landedPrice && landedPrice === "N/A"
                  ? "N/A"
                  : `$${parseFloat(landedPrice)?.toLocaleString("us-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} / ${
                      record?.landedPriceType || record?.totalPriceType
                    }s`}
              </span>
            );
          },
        },
        {
          title: "Drive Miles",
          dataIndex: "milesData",
          key: "milesData",
          show: type === "order" ? false : true,
          sorter: false,
          className: "col_style_quarry",
          width: "100px",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (milesData, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order"
                  ? record?.material[0]?.milesData !== undefined && (
                      <div style={{ textTransform: "capitalize" }}>
                        {record?.material[0]?.milesData + "les"}
                      </div>
                    )
                  : milesData !== undefined && (
                      <div style={{ textTransform: "capitalize" }}>
                        {milesData + "les"}
                      </div>
                    )}
              </span>
            );
          },
        },
        {
          title: "Drive Mintues",
          dataIndex: "duration",
          key: "duration",
          show: type === "order" ? false : true,
          sorter: false,
          className: "col_style_quarry",
          width: "100px",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          render: (duration, record) => {
            return (
              <span className="col_style_quarry">
                {type === "order" ? (
                  <>
                    <div style={{ textTransform: "capitalize" }}>
                      {record?.material[0]?.duration}
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ textTransform: "capitalize" }}>
                      {duration}
                    </div>
                  </>
                )}
              </span>
            );
          },
        },
        {
          title: "Run Minutes",
          dataIndex: "",
          key: "runMintues",
          show: type === "order" ? false : true,
          sorter: false,
          className: "col_style_quarry",
          width: "100px",
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          fixed: "right",
          render: (record) => {
            const { ticketsInfo } = this.state;
            let filterData = ticketsInfo?.filter(
              (e) => e.material[0]?.materialId === record?.materialId
            );
            return (
              <span className="col_style_quarry">
                {filterData?.length > 0 && (
                  <div style={{ textTransform: "capitalize" }}>
                    {this.formatTimeDifference(
                      this.calculateTimeDifferences(filterData)
                    )}
                  </div>
                )}
              </span>
            );
          },
        },
        {
          title: "Minimum Hours",
          dataIndex: "hourlyCharge",
          key: "hourlyCharge",
          sorter: false,
          width: "10%",
          className: "col_style_quarry",
          show:
            leadDetails?.material?.filter(
              (e) =>
                e.truckingCostType === "Hour" || e.truckingPriceType === "Hour"
            )?.length > 0,
          onCell: (record) => ({
            onDoubleClick: () => {
              this.reDirectUrl(record.id);
            },
          }),
          // fixed: "right",
          render: (hourlyCharge) => {
            return (
              <span className="col_style_quarry">
                {hourlyCharge && `${hourlyCharge} Hours`}
              </span>
            );
          },
        },
        {
          title: "Dispatch Info",
          dataIndex: "",
          key: "",
          sorter: false,
          width: "8%",
          className: "col_style_quarry",
          show: true,
          fixed: "right",
          render: (record) => {
            return (
              leadDetails?.dispatchData && (
                <div>
                  <Tooltip title="Dispatch Info">
                    <EyeOutlined
                      onClick={() => this.showDataModal(record)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontSize: "25px",
                        color: "#586370",
                      }}
                    />
                  </Tooltip>
                </div>
              )
            );
          },
        },
      ].filter((e) => (type === "order" ? !e.show : e.show));

    const invoiceColumns = [
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        width: "10%",
        className: "col_style_quarry",
        render: (createdAt) => {
          return (
            <span className="col_style_quarry">
              {moment(createdAt).format("L")}
            </span>
          );
        },
      },
      {
        title: "Due Date",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (createdAt, record) => {
          let date = record?.companyTerms?.split(" ");
          // console.log(date[1]);
          var new_date = moment(
            moment(createdAt).format("L"),
            "MM/DD/YYYY"
          ).add(parseInt(date[1]), "days");
          // console.log(moment(new_date).format("L"));
          return (
            <span
              style={{
                color:
                  new Date(today).getTime() >= new Date(new_date).getTime()
                    ? "black"
                    : "red",
              }}
              className="col_style_quarry"
            >
              {moment(new_date).format("L")}
            </span>
          );
        },
      },
      {
        title: "Days Outstanding",
        dataIndex: "ARPaymentDate",
        key: "ARPaymentDate",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (ARPaymentDate, record) => {
          // console.log(record?.ARinvoiceStatus, "record?.ARinvoiceStatus");
          var x =
            record?.ARinvoiceStatus === "Paid"
              ? new moment(moment(record?.ARPaymentDate).format("l"))
              : new moment(moment().format("l"));
          var y = new moment(moment(record?.createdAt).format("l"));
          var diff = x - y;
          let duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
          return (
            <span
              // style={{
              //   color:
              //     new Date(today).getTime() <=
              //     new Date(insurance_expiry).getTime()
              //       ? "black"
              //       : "red",
              // }}
              className="col_style_quarry"
            >
              {duration + " Days"}
            </span>
          );
        },
      },
      {
        title: "Invoice#",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (invoiceNumber) => {
          return <span className="col_style_quarry">{invoiceNumber}</span>;
        },
      },
      {
        title: "Amount",
        dataIndex: "ticketsTotal",
        key: "ticketsTotal",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (ticketsTotal) => {
          return (
            <span className="col_style_quarry">
              {`$${parseFloat(ticketsTotal)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}`}
            </span>
          );
        },
      },
      // {
      //   title: "Status",
      //   dataIndex: "invoiceStatus",
      //   key: "invoiceStatus",
      //   sorter: false,
      //   className: "col_style_quarry",
      //   render: (invoiceStatus, record) => {
      //     return (
      //       <>
      //         <Popover
      //           placement="right"
      //           content={invoiceStatusview(record.id, invoiceStatus)}
      //           trigger="hover"
      //         >
      //           <Button
      //             className="lead_ButtonStatus"
      //             style={{
      //               color: "#3f27c3",
      //               border: "1px solid #3f27c3",
      //             }}
      //           >
      //             <span className="col_style_quarry" style={{ width: "55px" }}>
      //               {invoiceStatus}
      //             </span>
      //           </Button>
      //         </Popover>
      //       </>
      //     );
      //   },
      // },
      {
        title: "Attachment",
        dataIndex: "invoicePath",
        key: "invoicePath",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (invoicePath, record) => {
          return (
            <span className="col_style_quarry">
              {invoicePath !== undefined &&
                invoicePath !== "" &&
                invoicePath !== null && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownload(
                        record?.invoicePath,
                        "Attachment",
                        "invoicePath"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "coloumn",
                      }}
                    >
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          marginRight: "5px",
                        }}
                        className="trucking-details-download"
                      />
                      <span style={{ marginTop: "6px" }}>Download</span>
                    </Row>
                  </span>
                )}
            </span>
          );
        },
      },
    ];

    const docColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: false,
        width: "12%",
        className: "col_style_quarry",
        render: (date, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                color: record?.sheetStatus === "Pending" ? "red" : "black",
              }}
            >
              {date}
            </span>
          );
        },
      },
      {
        title: "Document Name",
        dataIndex: "document",
        key: "document",
        sorter: false,
        className: "col_style_quarry",
        render: (document, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                color: record?.sheetStatus === "Pending" ? "red" : "black",
              }}
            >
              {document}
            </span>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "docType",
        key: "docType",
        sorter: false,
        className: "col_style_quarry",
        render: (docType, record) => {
          return (
            <span
              className="col_style_quarry"
              style={{
                color: record?.sheetStatus === "Pending" ? "red" : "black",
              }}
            >
              {docType}
            </span>
          );
        },
      },
      {
        title: "Attachment",
        dataIndex: "docAttachment",
        key: "docAttachment",
        sorter: false,
        width: "5%",
        className: "col_style_quarry",
        render: (docAttachment, record) => {
          return (
            <span className="col_style_quarry">
              {docAttachment !== undefined &&
                docAttachment !== "" &&
                docAttachment !== null && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.fileDownload(
                        record.docAttachmentId,
                        "Attachment",
                        "docAttachment"
                      );
                    }}
                  >
                    <Row
                      style={{
                        color: "#7B8190",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "coloumn",
                      }}
                    >
                      <HiOutlineDocumentArrowDown
                        style={{
                          fontSize: "30px",
                          height: "30px",
                          // marginRight: "5px",
                        }}
                      />
                      {/* <span style={{ marginTop: "6px" }}>Download</span> */}
                    </Row>
                  </span>
                )}
            </span>
          );
        },
      },
      {
        title: "STATUS",
        dataIndex: "sheetStatus",
        key: "sheetStatus",
        sorter: false,
        className: "col_styling",
        width: "250px",
        render: (tag2, record) => {
          return (
            <span className="col_style_quarry">
              {tag2 && (
                <Tag
                  style={{
                    background: "transparent",
                    border: "none",
                    height: "40px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    padding: "0px",
                    color:
                      tag2 === "Pending"
                        ? "#d16a00"
                        : tag2 === "Approved"
                        ? "#00b02e"
                        : tag2 === "Rejected" && "#de0000",
                  }}
                  key={tag2}
                >
                  <Popover
                    placement="right"
                    content={tag2 === "Pending" && invoiceStatusview(record)}
                    trigger="hover"
                  >
                    {tag2 === "Approved" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          height: "40px",
                          fontWeight: "600",
                        }}
                      >
                        <CheckCircleFilled style={{ marginRight: "5px" }} />
                        <span>{tag2}</span>
                      </div>
                    ) : tag2 === "Rejected" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          height: "40px",
                          fontWeight: "600",
                        }}
                      >
                        <CloseCircleOutlined style={{ marginRight: "5px" }} />
                        <span>{tag2}</span>
                      </div>
                    ) : (
                      <Button
                        type="button"
                        style={{
                          height: "40px",
                          backgroundColor:
                            tag2 === "Pending" ? "#f78d8d" : "#586370",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                          width: "fit-content",
                          // width: "140px",
                          color: "#FFF",
                          fontWeight: "600",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "600",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "15px",
                          }}
                        >
                          {tag2}
                        </span>
                      </Button>
                    )}
                  </Popover>
                </Tag>
              )}
            </span>
          );
        },
      },
    ];

    const contactsColumns = [
      {
        title: "Contact Name",
        dataIndex: "firstName",
        key: "firstName",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (firstName, record) => {
          return (
            <span className="col_style_quarry">
              {firstName + " " + record.lastName}
            </span>
          );
        },
      },
      {
        title: "Role",
        dataIndex: "contactRole",
        key: "contactRole",
        sorter: false,
        width: "20%",
        className: "col_style_quarry",
        render: (contactRole) => {
          return <span className="col_style_quarry">{contactRole}</span>;
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        className: "col_style_quarry",
        width: "15%",
        render: (phone) => {
          return <span className="col_style_quarry">{phone}</span>;
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
        className: "col_style_quarry",
        width: "40%",
        render: (email) => {
          return <span className="col_style_quarry">{email}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "5%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    {" "}
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editModal(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline style={{ marginRight: "5px" }} />
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteContact(record)}
                        >
                          <span className="span_edit">
                            <MdDelete style={{ marginRight: "5px" }} /> Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: "30px", color: "grey" }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    const notesColumns = [
      {
        title: "Date + Time ",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => a.createdAt - b.createdAt,
        width: "20%",
        className: "col_style_quarry",
        render: (createdAt) => {
          return (
            <span className="col_style_quarry">
              {moment(createdAt).format("MM-DD-YYYY  LT")}
            </span>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "typeofNotes",
        key: "typeofNotes",
        sorter: false,
        className: "col_style_quarry",
        width: "25%",
        render: (typeofNotes) => {
          return <span className="col_style_quarry">{typeofNotes}</span>;
        },
      },
      {
        title: "Note",
        dataIndex: "notes",
        key: "notes",
        sorter: false,
        className: "col_style_quarry",
        render: (notes) => {
          return <span className="col_style_quarry">{notes}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_style_quarry",
        width: "10%",
        render: (record) => {
          return (
            <Col>
              <Popover
                placement="left"
                content={
                  <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                    <Col span={24}>
                      <Button
                        className="btn_edit"
                        onClick={() => this.editNotes(record)}
                      >
                        <span className="span_edit">
                          <MdModeEditOutline
                            style={{
                              marginRight: "5px",
                            }}
                          />{" "}
                          Edit
                        </span>
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Button className="btn_edit">
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.deleteNotes(record?.id)}
                          // onConfirm={() => this.deleteCompany(record)}
                        >
                          <span className="span_edit">
                            <MdDelete
                              style={{
                                marginRight: "5px",
                              }}
                            />
                            Delete
                          </span>
                        </Popconfirm>
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: "30px",
                    color: "grey",
                  }}
                  className="action-ellipsis-button"
                />
              </Popover>
            </Col>
          );
        },
      },
    ];

    const content = (
      <div>
        {this.state.funnelData.map(
          (e) =>
            e !== "Paused" &&
            e !== "Close Job" && (
              <Row
                onClick={() =>
                  this.state.leadDetails.stageId === "Job Lead"
                    ? [
                        "Job Lead",
                        "Build Quote",
                        "Quote Delivered",
                        "Quote Accepted",
                        "Dispatch Approved",
                      ].includes(e)
                      ? ""
                      : this.onClickStageId(e)
                    : this.state.leadDetails.stageId === "Contacted"
                    ? [
                        "Job Lead",
                        "Contacted",
                        "Quote Delivered",
                        "Quote Accepted",
                        "Dispatch Approved",
                        // "Paused",
                        // "Close Job",
                      ].includes(e)
                      ? ""
                      : this.onClickStageId(e)
                    : this.state.leadDetails.stageId === "Build Quote"
                    ? [
                        "Job Lead",
                        "Contacted",
                        "Build Quote",
                        "Quote Accepted",
                        "Dispatch Approved",
                      ].includes(e)
                      ? ""
                      : this.onClickStageId(e)
                    : this.state.leadDetails.stageId === "Quote Delivered"
                    ? [
                        "Job Lead",
                        "Contacted",
                        "Build Quote",
                        "Quote Delivered",
                        "Dispatch Approved",
                      ].includes(e)
                      ? ""
                      : this.onClickStageId(e)
                    : this.state.leadDetails.stageId === "Quote Accepted"
                    ? [
                        "Job Lead",
                        "Contacted",
                        "Build Quote",
                        "Quote Delivered",
                        "Quote Accepted",
                      ].includes(e)
                      ? ""
                      : this.onClickStageId(e)
                    : this.onClickStageId(e)
                }
                style={{
                  cursor: `${
                    this.state.leadDetails.stageId === "Job Lead"
                      ? [
                          "Job Lead",
                          "Build Quote",
                          "Quote Delivered",
                          "Quote Accepted",
                          "Dispatch Approved",
                        ].includes(e)
                        ? "not-allowed"
                        : "pointer"
                      : this.state.leadDetails.stageId === "Contacted"
                      ? [
                          "Job Lead",
                          "Contacted",
                          "Quote Delivered",
                          "Quote Accepted",
                          "Dispatch Approved",
                          "Paused",
                          "Close Job",
                        ].includes(e)
                        ? "not-allowed"
                        : "pointer"
                      : this.state.leadDetails.stageId === "Build Quote"
                      ? [
                          "Job Lead",
                          "Contacted",
                          "Build Quote",
                          "Quote Accepted",
                          "Dispatch Approved",
                          "Paused",
                          "Close Job",
                        ].includes(e)
                        ? "not-allowed"
                        : "pointer"
                      : this.state.leadDetails.stageId === "Quote Delivered"
                      ? [
                          "Job Lead",
                          "Contacted",
                          "Build Quote",
                          "Quote Delivered",
                          "Dispatch Approved",
                          "Paused",
                          "Close Job",
                        ].includes(e)
                        ? "not-allowed"
                        : "pointer"
                      : this.state.leadDetails.stageId === "Quote Accepted"
                      ? [
                          "Job Lead",
                          "Contacted",
                          "Build Quote",
                          "Quote Delivered",
                          "Quote Accepted",
                          "Paused",
                          "Close Job",
                        ].includes(e)
                        ? "not-allowed"
                        : "pointer"
                      : "pointer"
                  }`,
                }}
              >
                <Col
                  style={{
                    fontSize: "13px",
                    fontFamily: "sans-serif",
                  }}
                >
                  <Button
                    style={{
                      width: "180px",
                      marginBottom: "5px",
                      background:
                        this.state.leadDetails.stageId === "Job Lead"
                          ? [
                              "Job Lead",
                              "Build Quote",
                              "Quote Delivered",
                              "Quote Accepted",
                              "Dispatch Approved",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#1890ff"
                            : "#FFF"
                          : this.state.leadDetails.stageId === "Contacted"
                          ? [
                              "Job Lead",
                              "Contacted",
                              "Quote Delivered",
                              "Quote Accepted",
                              "Dispatch Approved",
                              "Paused",
                              "Close Job",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#1890ff"
                            : "#FFF"
                          : this.state.leadDetails.stageId === "Build Quote"
                          ? [
                              "Job Lead",
                              "Contacted",
                              "Build Quote",
                              "Quote Accepted",
                              "Dispatch Approved",
                              "Paused",
                              "Close Job",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#1890ff"
                            : "#FFF"
                          : this.state.leadDetails.stageId === "Quote Delivered"
                          ? [
                              "Job Lead",
                              "Contacted",
                              "Build Quote",
                              "Quote Delivered",
                              "Dispatch Approved",
                              "Paused",
                              "Close Job",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#1890ff"
                            : "#FFF"
                          : this.state.leadDetails.stageId === "Quote Accepted"
                          ? [
                              "Job Lead",
                              "Contacted",
                              "Build Quote",
                              "Quote Delivered",
                              "Quote Accepted",
                              "Paused",
                              "Close Job",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#1890ff"
                            : "#FFF"
                          : e === this.state.selectedStatus
                          ? "#1890ff"
                          : "#FFF",

                      color:
                        this.state.leadDetails.stageId === "Job Lead"
                          ? [
                              "Job Lead",
                              "Build Quote",
                              "Quote Delivered",
                              "Quote Accepted",
                              "Dispatch Approved",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#FFF"
                            : "#1890ff"
                          : this.state.leadDetails.stageId === "Contacted"
                          ? [
                              "Job Lead",
                              "Contacted",
                              "Quote Delivered",
                              "Quote Accepted",
                              "Dispatch Approved",
                              "Paused",
                              "Close Job",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#FFF"
                            : "#1890ff"
                          : this.state.leadDetails.stageId === "Build Quote"
                          ? [
                              "Job Lead",
                              "Contacted",
                              "Build Quote",
                              "Quote Accepted",
                              "Dispatch Approved",
                              "Paused",
                              "Close Job",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#FFF"
                            : "#1890ff"
                          : this.state.leadDetails.stageId === "Quote Delivered"
                          ? [
                              "Job Lead",
                              "Contacted",
                              "Build Quote",
                              "Quote Delivered",
                              "Dispatch Approved",
                              "Paused",
                              "Close Job",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#FFF"
                            : "#1890ff"
                          : this.state.leadDetails.stageId === "Quote Accepted"
                          ? [
                              "Job Lead",
                              "Contacted",
                              "Build Quote",
                              "Quote Delivered",
                              "Quote Accepted",
                              "Paused",
                              "Close Job",
                            ].includes(e)
                            ? ""
                            : e === this.state.selectedStatus
                            ? "#FFF"
                            : "#1890ff"
                          : e === this.state.selectedStatus
                          ? "#FFF"
                          : "#1890ff",

                      pointerEvents: "none",
                    }}
                    disabled={
                      this.state.leadDetails.stageId === "Job Lead"
                        ? [
                            "Job Lead",
                            "Build Quote",
                            "Quote Delivered",
                            "Quote Accepted",
                            "Dispatch Approved",
                          ].includes(e)
                          ? true
                          : false
                        : this.state.leadDetails.stageId === "Contacted"
                        ? [
                            "Job Lead",
                            "Contacted",
                            "Quote Delivered",
                            "Quote Accepted",
                            "Dispatch Approved",
                            "Paused",
                            "Close Job",
                          ].includes(e)
                          ? true
                          : false
                        : this.state.leadDetails.stageId === "Build Quote"
                        ? [
                            "Job Lead",
                            "Contacted",
                            "Build Quote",
                            "Quote Accepted",
                            "Dispatch Approved",
                            "Paused",
                            "Close Job",
                          ].includes(e)
                          ? true
                          : false
                        : this.state.leadDetails.stageId === "Quote Delivered"
                        ? [
                            "Job Lead",
                            "Contacted",
                            "Build Quote",
                            "Quote Delivered",
                            "Dispatch Approved",
                            "Paused",
                            "Close Job",
                          ].includes(e)
                          ? true
                          : false
                        : this.state.leadDetails.stageId === "Quote Accepted"
                        ? [
                            "Job Lead",
                            "Contacted",
                            "Build Quote",
                            "Quote Delivered",
                            "Quote Accepted",
                            "Paused",
                            "Close Job",
                          ].includes(e)
                          ? true
                          : false
                        : false
                    }
                    name={e}
                    id={e}
                    className={e}
                  >
                    {e}
                  </Button>
                </Col>
              </Row>
            )
        )}
      </div>
    );

    const selectAfter = (
      <Select
        defaultValue={this.state?.quantityType}
        className="select-after"
        onChange={(e) => {
          this.setState({ quantityType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterMaterial = (
      <Select
        defaultValue={this.state?.materialCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ materialCostType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    const selectAfterTruck = (
      <Select
        defaultValue={this.state?.truckingCostType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingCostType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterTruckPrice = (
      <Select
        defaultValue={this.state?.truckingPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ truckingPriceType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Hour">Hour</Option>
      </Select>
    );

    const selectAfterCustomer = (
      <Select
        defaultValue={this.state?.totalPriceType}
        className="select-after"
        onChange={(e) => {
          this.setState({ totalPriceType: e });
        }}
      >
        <Option value="Ton">Ton</Option>
        <Option value="Load">Load</Option>
        <Option value="Yard">Yard</Option>
      </Select>
    );

    return (
      <>
        <Loading enableLoading={loading || internalLoading} />
        <Row
          style={{
            backgroundColor: "#fafafa",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "60px",
            width: "100%",
          }}
        >
          <Row onClick={this.onClickReturn} style={{ cursor: "pointer" }}>
            <BsArrowLeftShort
              style={{ color: "#5f6c74", fontSize: "23px" }}
              className="back-button-return-icon"
            />
            <p
              style={{ color: "#808080", fontSize: "16px", fontWeight: "600" }}
              className="back-button-return"
            >
              Return
            </p>
          </Row>
        </Row>
        <div
          style={{
            background: "#fafafa",
            minHeight: "75vh",
          }}
        >
          <Row style={{ marginBottom: "1vw" }}>
            {errorLoading ? (
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <ErrorView
                  refreshLeadDetails={this.onRefresh}
                  type="LeadDetails"
                />
              </Col>
            ) : (
              <Row style={{ width: "100%" }} gutter={[12, 0]}>
                <Col
                  // span={8}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 9 }}
                  xxl={{ span: 9 }}
                  data-aos={window.screen.width > 1023 ? "fade-right" : ""}
                  style={{ marginBottom: "1vw", minHeight: "35vh" }}
                >
                  <Card
                    className="height-card"
                    bodyStyle={{ padding: "24px 12px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      {leadDetails?.stageId !== "Close Job" && (
                        <div
                          style={{
                            position: "absolute",
                            top: "-12px",
                            right: "0px",
                            border: "0.5px solid #aaa8a8",
                            width: "2vw",
                            height: "2vw",
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            alignContent: "center",
                            justifyContent: " center",
                            padding: "8px",
                            borderRadius: "100%",
                          }}
                          className="edit-details-button"
                        >
                          <Button
                            style={{
                              border: "none",
                              cursor: "pointer",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => {
                              this.props.history.push(
                                `/app/updateJobDetails/${leadDetails.id}`
                              );
                            }}
                            type="ghost"
                          >
                            <EditOutlined
                              style={{
                                color: "#5f6c74",
                                fontSize: "18px",
                                fontWeight: "600",
                              }}
                            />
                          </Button>
                        </div>
                      )}
                      <p
                        className="detail-heading responsive_text"
                        style={{
                          fontSize: "16px",
                          color: "#5f6c74",
                          fontWeight: "800",
                          marginBottom: "6px",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            fontSize: "16px",
                            color: "#5f6c74",
                            fontWeight: "800",
                            marginBottom: "0px",
                            textAlign: "center",
                          }}
                        >
                          {leadDetails?.jobType === "Both"
                            ? "Trucking & Material"
                            : leadDetails?.jobType}
                        </span>
                        {leadDetails?.jobName && (
                          <span
                            style={{
                              padding: "3px",
                              // borderRadius: "3px",
                              // marginTop: "10px",
                              textTransform: "capitalize",
                              fontSize: "16px",
                              color: "#0d0f10",
                              fontWeight: "800",
                              width: "100%",
                              maxWidth: "max-content",
                            }}
                            className="main-job-details"
                          >{`#${leadDetails?.jobName}`}</span>
                        )}
                        <span
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "#e3e7ea",
                            padding: "3px",
                            borderRadius: "3px",
                            color: "#0d0f10",
                          }}
                          className="main-job-details"
                        >{`#${
                          leadDetails?.jobNumber && leadDetails?.jobNumber
                        }`}</span>
                      </p>

                      <div className="linkcompany">
                        <Link
                          to={`/app/customer-account-profile/${leadDetails?.companyId}`}
                          style={{
                            fontSize: "20px",
                            textTransform: "capitalize",
                            color: "#0d0f10",
                            fontWeight: "800",
                          }}
                          className="main-job-details"
                        >
                          {leadDetails?.companyDetails?.company}
                          <LinkOutlined style={{ marginLeft: "5px" }} />
                        </Link>
                      </div>
                      <Row style={{ width: "100%", justifyContent: "center" }}>
                        {leadDetails.stageId === "Build Quote" && (
                          <>
                            <Button
                              style={{
                                borderRadius: "6px",
                                borderColor: "#237804",
                              }}
                              onClick={() => this.handleButtonClick("estimate")}
                            >
                              <span
                                className="span_edit"
                                style={{ color: "#237804" }}
                              >
                                <LinkOutlined
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />{" "}
                                Generate Estimate
                              </span>
                            </Button>
                          </>
                        )}

                        {leadDetails.stageId === "Build Quote" && (
                          <>
                            <Button
                              style={{
                                marginLeft: "auto",
                                borderRadius: "6px",
                                borderColor: "#0958d9",
                                margin: "0px 24px auto",
                              }}
                              onClick={() => this.handleButtonClick("quote")}
                            >
                              <span
                                className="span_edit"
                                style={{ color: "#0958d9" }}
                              >
                                <FilePdfOutlined
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />{" "}
                                Generate Quote
                              </span>
                            </Button>
                          </>
                        )}
                        <Popover
                          placement="right"
                          content={
                            leadDetails?.stageId !== "Dispatch Approved" &&
                            leadDetails?.stageId !== "Paused" &&
                            leadDetails?.stageId !== "Close Job"
                              ? content
                              : ""
                          }
                          trigger="hover"
                        >
                          <Button
                            className="lead_ButtonStatus"
                            style={{
                              background: "transparent",
                              borderRadius: "6px",
                              borderColor:
                                appConfig["STAGE_COLORS"][leadDetails.stageId],
                              color:
                                appConfig["STAGE_COLORS"][leadDetails.stageId],
                              borderWidth: "1px",
                              borderStyle: "solid",
                              minWidth: "164px",
                              justifyContent: "center",
                            }}
                          >
                            {selectedStatus ? (
                              selectedStatus
                            ) : (
                              <span style={{ margin: "0px auto" }}>
                                {leadDetails.stageId &&
                                leadDetails.stageId === "Close Job"
                                  ? "Closed"
                                  : leadDetails.stageId}
                              </span>
                            )}
                          </Button>
                        </Popover>
                      </Row>
                    </div>

                    {leadDetails?.jobType !== "Trucking Job" && (
                      <Row
                        span={24}
                        style={{
                          marginBottom: "8px",
                          marginTop: "15px",
                        }}
                      >
                        <Col
                          span={12}
                          className="detail-ans"
                          style={{ fontWeight: "600" }}
                        >
                          Tax Exempt
                        </Col>
                        <Col span={12} className="detail-ans">
                          {leadDetails?.taxExempt}{" "}
                        </Col>
                      </Row>
                    )}
                    <Row
                      span={24}
                      style={{
                        marginBottom: "8px",
                        marginTop: "5px",
                      }}
                    >
                      <Col
                        span={12}
                        className="detail-ans"
                        style={{ fontWeight: "600" }}
                      >
                        Project Info Sheet
                      </Col>
                      <Col
                        span={12}
                        className={
                          documentsData?.length === 0 ||
                          leadDetails?.projectInformationSheet ===
                            "Not Received"
                            ? "detail-ans red-text"
                            : "detail-ans"
                        }
                      >
                        {documentsData?.length === 0 ? (
                          "Not Received"
                        ) : documentsData.some(
                            (e) => e?.sheetStatus === "Pending"
                          ) ? (
                          <>
                            <span>Received </span>
                            <span className="red-text">(Pending)</span>
                          </>
                        ) : (
                          <span>{leadDetails?.projectInformationSheet}</span>
                        )}
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{
                        marginBottom: "8px",
                        marginTop: "5px",
                      }}
                    >
                      <Col
                        span={12}
                        className="detail-ans"
                        style={{ fontWeight: "600" }}
                      >
                        Account Charge Limit
                      </Col>
                      <Col span={12} className="detail-ans">
                        {leadDetails?.accountChargeLimit}
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{
                        marginBottom: "8px",
                        marginTop: "5px",
                      }}
                    >
                      <Col
                        span={12}
                        className="detail-ans"
                        style={{ fontWeight: "600" }}
                      >
                        On-Site Contact
                      </Col>
                      <Col
                        span={12}
                        className="detail-ans"
                        style={{
                          color: leadDetails?.contactDetails?.some(
                            (e) => e.contactRole === "On-Site Contact"
                          )
                            ? ""
                            : "red",
                        }}
                      >
                        {leadDetails?.contactDetails?.some(
                          (e) => e.contactRole === "On-Site Contact"
                        )
                          ? leadDetails?.contactDetails?.map(
                              (e) =>
                                e.contactRole === "On-Site Contact" &&
                                e.firstName + " " + e.lastName
                            )
                          : "--EMPTY--"}
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{
                        marginBottom: "8px",
                        marginTop: "5px",
                      }}
                    >
                      <Col
                        span={12}
                        className="detail-ans"
                        style={{ fontWeight: "600" }}
                      >
                        On-Site Phone
                      </Col>
                      <Col
                        span={12}
                        className="detail-ans"
                        style={{
                          color: leadDetails?.contactDetails?.some(
                            (e) => e.contactRole === "On-Site Contact"
                          )
                            ? ""
                            : "red",
                        }}
                      >
                        {leadDetails?.contactDetails?.some(
                          (e) => e.contactRole === "On-Site Contact"
                        )
                          ? leadDetails?.contactDetails?.map(
                              (e) =>
                                e.contactRole === "On-Site Contact" && e.phone
                            )
                          : "--EMPTY--"}
                      </Col>
                    </Row>

                    <Row gutter={[8, 0]} className="fontreducer">
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <p className="detail-heading detail-heading-notes">
                          Team Notes
                        </p>
                        <div
                          style={{
                            minHeight: "80px",
                            borderRadius: "3px",
                            backgroundColor: "#e3e7ea",
                            padding: "5px",
                          }}
                          className="detail-ans"
                        >
                          {leadDetails?.notes && leadDetails?.notes}
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <p className="detail-heading detail-heading-notes">
                          Invoice Notes
                        </p>
                        <div
                          style={{
                            minHeight: "80px",
                            borderRadius: "3px",
                            backgroundColor: "#e3e7ea",
                            padding: "5px",
                          }}
                          className="detail-ans"
                        >
                          {invoiceNotes?.notes1 && (
                            <div style={{ display: "flex" }}>
                              {invoiceNotes?.notes1 + ", "}
                            </div>
                          )}
                          {invoiceNotes?.notes2 && (
                            <div style={{ display: "flex" }}>
                              {invoiceNotes?.notes2 + ", "}
                            </div>
                          )}{" "}
                          {invoiceNotes?.notes3 && (
                            <div style={{ display: "flex" }}>
                              {invoiceNotes?.notes3}
                            </div>
                          )}
                        </div>
                      </div>
                    </Row>
                  </Card>
                </Col>

                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 15, offset: 0 }}
                  xxl={{ span: 15, offset: 0 }}
                  data-aos={window.screen.width > 1023 ? "fade-left" : ""}
                  style={{
                    width: "100%",
                    minHeight: "35vh",
                  }}
                >
                  {!dispatchModal &&
                  quarryData?.length > 0 &&
                  quarryData[0]?.dropOffLatLng !== "" &&
                  quarryData[0]?.material?.length > 0 ? (
                    <div
                      className="lead_detailmap"
                      style={{ height: "61.5vh" }}
                    >
                      <MapComponent
                        isMarkerShown
                        quarryData={quarryData}
                        jobProfile
                        disableScroll
                      />
                    </div>
                  ) : (
                    <Nodata height />
                  )}
                </Col>

                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  xxl={{ span: 24, offset: 0 }}
                  data-aos={window.screen.width > 1023 ? "fade-left" : ""}
                >
                  <Card
                    data-aos={window.screen.width > 1023 ? "fade-up" : ""}
                    className="card_virtual job-profile-card"
                    bodyStyle={{ padding: "0px" }}
                    style={{ background: "#eff1f3", marginBottom: "10px" }}
                  >
                    <Tabs
                      defaultActiveKey="1"
                      className="ant-card"
                      style={{ borderRadius: "5px" }}
                      onChange={(e) => {
                        this.setState({
                          keyValue: e,
                        });
                      }}
                      tabBarExtraContent={this.showTabRightButton(keyValue)}
                    >
                      <TabPane
                        tab={
                          <span>
                            {leadDetails?.jobType === "Trucking Job"
                              ? "Trucking Details"
                              : "Material Details"}
                          </span>
                        }
                        key="1"
                      >
                        {this.showBaseTable(
                          leadDetails?.jobType === "Trucking Job"
                            ? truckingJobColumns("material")
                            : materialJobColumns("material"),
                          leadDetails?.jobType === "Trucking Job"
                            ? leadDetails?.material
                            : leadDetails?.material?.filter((e) => e.category),
                          "jobDetails",
                          leadDetails?.material?.length,
                          rowsSelection
                        )}
                      </TabPane>
                      {leadDetails?.jobType === "Both" && (
                        <TabPane tab={<span>Trucking Details</span>} key="7">
                          {this.showBaseTable(
                            truckingJobColumns("material"),
                            leadDetails?.material?.filter((e) => !e.category),
                            "jobTruckDetails",
                            leadDetails?.material?.filter((e) => !e.category)
                              ?.length,
                            rowsSelection
                          )}
                        </TabPane>
                      )}
                      {leadDetails?.jobType === "Both" ? (
                        <>
                          <TabPane
                            tab={<span>Material Order Details</span>}
                            key="8"
                          >
                            {this.showBaseTable(
                              materialJobColumns("order"),
                              leadDetails?.orderDetails?.filter(
                                (e) => e.jobType === "Trucking & Material Job"
                              ),
                              "orderMaterialDetails",
                              leadDetails?.orderDetails?.filter(
                                (e) => e.jobType === "Trucking & Material Job"
                              )?.length
                            )}
                          </TabPane>
                          <TabPane
                            tab={<span>Truck Order Details</span>}
                            key="9"
                          >
                            {this.showBaseTable(
                              truckingJobColumns("order"),
                              leadDetails?.orderDetails?.filter(
                                (e) => e.jobType === "Trucking Job"
                              ),
                              "orderTruckingDetails",
                              leadDetails?.orderDetails?.filter(
                                (e) => e.jobType === "Trucking Job"
                              )?.length
                            )}
                          </TabPane>
                        </>
                      ) : (
                        <TabPane tab={<span>Order Details</span>} key="2">
                          {this.showBaseTable(
                            leadDetails?.jobType !== "Trucking Job"
                              ? materialJobColumns("order")
                              : truckingJobColumns("order"),
                            leadDetails?.orderDetails,
                            "orderDetails",
                            leadDetails?.orderDetails?.material?.length?.length
                          )}
                        </TabPane>
                      )}
                      <TabPane tab={<span>Contacts</span>} key="3">
                        {this.showBaseTable(
                          contactsColumns,
                          leadDetails?.multipleContacts,
                          "jobContactDetails",
                          leadDetails?.multipleContacts?.length
                        )}
                      </TabPane>
                      <TabPane
                        tab={
                          <span
                            style={{
                              color:
                                documentsData?.some(
                                  (e) => e.sheetStatus === "Pending"
                                ) && "red",
                            }}
                          >
                            {documentsData?.some(
                              (e) => e.sheetStatus === "Pending"
                            )
                              ? "Documents (" +
                                documentsData?.filter(
                                  (e) => e.sheetStatus === "Pending"
                                )?.length +
                                ")"
                              : "Documents"}
                          </span>
                        }
                        key="4"
                      >
                        {this.showBaseTable(
                          docColumns,
                          documentsData?.sort(
                            (a, b) => moment(b.date) - moment(a?.date)
                          ),
                          "jobDocDetails",
                          documentsData?.length
                        )}
                      </TabPane>
                      <TabPane tab={<span>Invoices</span>} key="5">
                        {this.showBaseTable(
                          invoiceColumns,
                          filterInvoicesData?.sort(
                            (a, b) => a.createdAt - b.createdAt
                          ),
                          "jobInoviceDetails",
                          filterInvoicesData?.length
                        )}
                      </TabPane>
                      <TabPane tab={<span>Notes</span>} key="6">
                        {this.showBaseTable(
                          notesColumns,
                          notesData,
                          "jobNotesDetails",
                          notesData?.length
                        )}
                      </TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </Row>
            )}
          </Row>
        </div>

        {isSheetModalVisible && (
          <BaseModal
            title="Project Information Sheet"
            visible={isSheetModalVisible}
            onCancel={() => {
              this.setState({
                isSheetModalVisible: false,
                projectAttachment: "",
              });
            }}
            loading={loading}
            disabled={imageLoading ? true : false}
            formId="addDocumentSheet"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            className="antmodal_grid headermodal"
          >
            <Form onFinish={this.onFinishSheet} id="addDocumentSheet">
              <Row gutter={[32, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="projectSheet"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach Your Project Sheet",
                      },
                    ]}
                  >
                    <Dragger
                      onChange={this.handleUploadPic}
                      customRequest={dummyRequest}
                      listType="picture"
                      multiple={false}
                      className="drag-ticket"
                      maxCount={1}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-hint">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {dispatchModal && (
          <BaseModal
            title={
              selectedStatus === "Build Quote" &&
              `Build Quote #${leadDetails?.jobNumber}`
            }
            onCancel={() =>
              this.setState({
                dispatchModal: false,
                selectedStatus: "",
                quoteAttachment: "",
                isCheckBox: false,
                isCheckBoxMaterial: false,
                materialPopup: false,
                openDropdown: "",
              })
            }
            formId="dispatchForm"
            loading={loading}
            className="antmodal_grid headermodal"
            width={"80%"}
          >
            <Form
              onFinish={this.onFinishDispatch}
              id="dispatchForm"
              ref={this.formRef}
            >
              <Row gutter={[8, 0]}>
                <Col
                  xxl={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  {quarryData?.length > 0 &&
                  quarryData[0]?.dropOffLatLng !== "" &&
                  quarryData[0]?.material?.length > 0 ? (
                    <div className="lead_detailmap" style={{ height: "25vh" }}>
                      {this.showMap(quarryData)}
                    </div>
                  ) : (
                    <Nodata />
                  )}
                </Col>
                <Col
                  xxl={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                  >
                    <div className="mapbox-1view">
                      <h5 className="detail-heading-quote">Job#</h5>
                      <p>{leadDetails?.jobNumber}</p>
                    </div>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                  >
                    <div className="mapbox-1view">
                      <h5 className="detail-heading-quote">Job Name</h5>
                      <p>
                        {leadDetails?.jobName
                          ? leadDetails?.jobName
                          : leadDetails?.jobNumber}
                      </p>
                    </div>
                  </Col>

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="mapbox-1view">
                      <h5 className="detail-heading-quote">Job Type</h5>
                      <p>{leadDetails?.jobType}</p>
                    </div>
                  </Col>
                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                  >
                    <div className="mapbox-1view">
                      <h5 className="detail-heading-quote">Company</h5>
                      <p> {leadDetails?.companyDetails?.company}</p>
                    </div>
                  </Col>

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                  >
                    <div className="mapbox-1view">
                      <h5 className="detail-heading-quote">Contact Name</h5>
                      <p>
                        {" "}
                        {leadDetails?.multipleContacts?.map((e) => (
                          <div>{e?.firstName + " " + e?.lastName}</div>
                        ))}
                      </p>
                    </div>
                  </Col>

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="mapbox-1view">
                      <h5 className="detail-heading-quote">Account Terms</h5>
                      <p>
                        {" "}
                        {leadDetails?.companyDetails?.accountTerms &&
                          leadDetails?.companyDetails?.accountTerms}
                      </p>
                    </div>
                  </Col>

                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="mapbox-1view">
                      <h5 className="detail-heading-quote">Dropoff Location</h5>
                      <p> {leadDetails?.dropoff}</p>
                    </div>
                  </Col>

                  <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="mapbox-1view">
                      <h5 className="detail-heading-quote">Notes</h5>
                      <p> {leadDetails?.notes}</p>
                    </div>
                  </Col>
                </Col>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  {leadDetails?.jobType !== "Trucking Job" && (
                    <Row style={{ width: "100%" }}>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <span
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "rgb(88,99,112)",
                            textTransform: "capitalize",
                          }}
                          className="table-font-mobile"
                        >
                          Material Details:
                        </span>
                      </Row>
                      <Col
                        span={24}
                        style={{ overflowX: "auto" }}
                        className="twistedtable"
                      >
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>Vendor</th>
                              <th>Material</th>
                              <th>Quantity</th>
                              <th>Requested Trucks</th>
                              <th>Material Cost</th>
                              <th>Material Price</th>
                              <th>Trucking Cost</th>
                              <th>Trucking Price</th>
                              <th>Landed Price</th>
                              {selectedMaterial?.filter(
                                (e) =>
                                  e.truckingCostType === "Hour" ||
                                  e.truckingPriceType === "Hour"
                              )?.length > 0 && <th>Minimum Hours</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {leadDetails?.material
                              ?.filter((e) => e.category)
                              ?.map((data, index) => (
                                <tr>
                                  <td>{data?.vendor}</td>
                                  <td>{data?.value}</td>
                                  <td>
                                    {data?.quantity && data?.quantityType
                                      ? `${data?.quantity} - ${data?.quantityType}`
                                      : ""}
                                  </td>
                                  <td>
                                    {trucksData
                                      ?.flatMap((e) =>
                                        data?.truckType?.map((d) =>
                                          d === e.value ? e.truck_code : null
                                        )
                                      )
                                      .filter((code) => code !== null)
                                      .join(", ")}
                                  </td>
                                  <td>
                                    {data?.materialCost &&
                                    data?.materialCostType
                                      ? `$${parseFloat(
                                          data?.materialCost
                                        )?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })} - (${data?.materialCostType})`
                                      : ""}
                                  </td>
                                  <td>
                                    {data?.totalPrice && data?.totalPriceType
                                      ? `$${parseFloat(
                                          data?.totalPrice
                                        )?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })} / ${data?.totalPriceType}`
                                      : ""}
                                  </td>
                                  <td>
                                    {data?.truckingCost &&
                                    data?.truckingCostType
                                      ? `$${parseFloat(
                                          data?.truckingCost
                                        )?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })} / ${data?.truckingCostType}`
                                      : ""}
                                  </td>
                                  <td>
                                    {data?.truckingPrice &&
                                    data?.truckingPriceType
                                      ? `$${parseFloat(
                                          data?.truckingPrice
                                        )?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })} / ${data?.truckingPriceType}`
                                      : ""}
                                  </td>
                                  <td>
                                    {data?.landedPrice === "N/A"
                                      ? data?.landedPrice
                                      : `$${parseFloat(
                                          data?.landedPrice
                                        )?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })}`}
                                  </td>
                                  {selectedMaterial?.filter(
                                    (e) =>
                                      e.truckingCostType === "Hour" ||
                                      e.truckingPriceType === "Hour"
                                  )?.length > 0 && (
                                    <td>
                                      {data.hourlyCharge
                                        ? `${data.hourlyCharge} Hours`
                                        : ""}
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  )}

                  {leadDetails?.jobType !== "Trucking & Material Job" && (
                    <Row style={{ width: "100%" }}>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        <span
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "rgb(88,99,112)",
                            textTransform: "capitalize",
                          }}
                        >
                          Trucking Details:
                        </span>
                      </Row>
                      <Col
                        span={24}
                        style={{ overflowX: "auto" }}
                        className="twistedtable"
                      >
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>Pickup Location </th>
                              <th>Material Name </th>
                              <th>Requested Trucks</th>
                              <th>Trucking Cost</th>
                              <th>Trucking Price</th>
                              {selectedMaterial?.filter(
                                (e) =>
                                  e.truckingCostType === "Hour" ||
                                  e.truckingPriceType === "Hour"
                              )?.length > 0 && <th>Minimum Hours</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {leadDetails?.material
                              ?.filter((e) => !e.category)
                              ?.map((data, index) => (
                                <tr>
                                  <td>{data?.pickUpAddress}</td>
                                  <td>{data?.materialName}</td>
                                  <td>
                                    {trucksData
                                      ?.flatMap((e) =>
                                        data?.truckType?.map((d) =>
                                          d === e.value ? e.truck_code : null
                                        )
                                      )
                                      .filter((code) => code !== null)
                                      .join(", ")}
                                  </td>
                                  <td>
                                    {data?.truckingCost &&
                                    data?.truckingCostType
                                      ? `$${parseFloat(
                                          data?.truckingCost
                                        )?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })} / ${data?.truckingCostType}`
                                      : ""}
                                  </td>
                                  <td>
                                    {data?.truckingPrice &&
                                    data?.truckingPriceType
                                      ? `$${parseFloat(
                                          data?.truckingPrice
                                        )?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })} / ${data?.truckingPriceType}`
                                      : ""}
                                  </td>
                                  {selectedMaterial?.filter(
                                    (e) =>
                                      e.truckingCostType === "Hour" ||
                                      e.truckingPriceType === "Hour"
                                  )?.length > 0 && (
                                    <td>
                                      {data.hourlyCharge
                                        ? `${data.hourlyCharge} Hours`
                                        : ""}
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isCallModalVisible && (
          <BaseModal
            title="Add Call"
            onCancel={() => {
              this.setState({
                isCallModalVisible: false,
              });
            }}
            formId="addCall"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            loading={loading}
            className="antmodal_grid headermodal"
          >
            <Form onFinish={this.onFinishCall} id="addCall">
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Date</span>
                  <Form.Item
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Date!",
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      defaultValue={moment()}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Time</span>
                  <Form.Item
                    name="time"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Time",
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      minuteStep={15}
                      showSecond={false}
                      focusOnOpen={true}
                      defaultValue={moment()}
                      format="hh:mm A"
                      className="time-picker"
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes"> Status</span>
                  <Form.Item
                    name="callStatus"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Call Status",
                      },
                    ]}
                    //initialValue="Select Status"
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select Call Status"
                      className="select_Height"
                      // mode="multiple"
                      showArrow
                      style={{ width: "100%" }}
                    >
                      <Option value="Connected">Connected</Option>
                      <Option value="Not Connected">Not Connected</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Description</span>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Description!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Description"
                    />
                  </Form.Item>
                </Col> */}
              </Row>
            </Form>
          </BaseModal>
        )}

        {isDocModalVisible && (
          <BaseModal
            title="Add Document"
            visible={this.state.isDocModalVisible}
            onCancel={() => {
              this.setState({
                isDocModalVisible: false,
                quoteAttachment: "",
              });
            }}
            loading={loading}
            disabled={imageLoading ? true : false}
            formId="addDocument"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            className="antmodal_grid headermodal"
          >
            <Form onFinish={this.onFinishDoc} id="addDocument">
              <Row gutter={[32, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Date</span>
                  <Form.Item
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Date!",
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      defaultValue={moment()}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Document Name</span>
                  <Form.Item
                    name="documentName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Document Name",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      className="form-input"
                      placeholder="Please Enter Document Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Type</span>
                  <Form.Item
                    name="docType"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Document  Status",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      className="select_Height"
                      placeholder="Doc Type"
                      showArrow
                      style={{ width: "100%" }}
                    >
                      <Option value="Contract">Contract</Option>
                      <Option value="Insurance">Insurance</Option>
                      <Option value="Project Information Sheet">
                        Project Information Sheet
                      </Option>
                      <Option value="Tax Exemption Certificate">
                        Tax Exemption Certificate
                      </Option>
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Description</span>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Description",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Description"
                    />
                  </Form.Item>
                </Col> */}
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Attachment</span>
                  <Form.Item
                    name="docAttachment"
                    rules={[
                      {
                        required: false,
                        message: "Please Attach Your Document",
                      },
                    ]}
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      style={{
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                      }}
                      showUploadList={false}
                      customRequest={dummyRequest}
                      onChange={(e) => this.handleUploadPic(e, "doc")}
                    >
                      <Spin spinning={imageLoading}>
                        {quoteAttachment ? (
                          fileType === "application/pdf" ? (
                            <FilePdfOutlined
                              style={{
                                fontSize: "30px",
                                height: "30px",
                                marginRight: "5px",
                              }}
                            />
                          ) : fileType ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                            <GrDocumentText
                              style={{
                                fontSize: "30px",
                                height: "30px",
                                marginRight: "5px",
                              }}
                            />
                          ) : fileType ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                            <SiGooglesheets
                              style={{
                                fontSize: "30px",
                                height: "30px",
                                marginRight: "5px",
                              }}
                            />
                          ) : (
                            <img
                              src={`${quoteAttachment}`}
                              alt="avatar"
                              style={{ width: "100px", height: "100%" }}
                            />
                          )
                        ) : (
                          <CameraOutlined className="camera-icon" />
                        )}
                      </Spin>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isContactVisible && (
          <BaseModal
            title={jobContactData !== "" ? "Update Contact" : " Add Contact"}
            onCancel={() => {
              this.setState({
                isContactVisible: false,
                isCheckBox: false,
                newRole: "",
                roleCheckBox: false,
              });
            }}
            loading={loading}
            formId="contact"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
            className="antmodal_grid headermodal"
          >
            <Form
              style={{ width: "100%" }}
              onFinish={this.onFinishContact}
              id="contact"
            >
              <Row gutter={[12, 0]}>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">First Name</span>
                  <Form.Item
                    name="fName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your First Name!",
                      },
                    ]}
                    initialValue={jobContactData?.firstName}
                  >
                    <Input
                      onChange={(e) =>
                        this.setState({ firstName: e.target.value })
                      }
                      className="form-input"
                      placeholder="Please Enter Your First Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <span className="title_changes">Last Name</span>
                  <Form.Item
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Last Name!",
                      },
                    ]}
                    initialValue={jobContactData?.lastName}
                  >
                    <Input
                      onChange={(e) =>
                        this.setState({ lastName: e.target.value })
                      }
                      className="form-input"
                      placeholder="Please Enter your Last Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Phone</span>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Phone!",
                      },
                    ]}
                    initialValue={jobContactData?.phone}
                  >
                    <NumberFormat
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      className="ant-input-number numberictest"
                      format="(###) ###-####"
                      style={{ width: "100%", height: "40px" }}
                      placeholder="Please Enter Your Phone"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Email</span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Email!",
                      },
                      {
                        type: "email",
                        message: "The Input Is Not Valid Email",
                      },
                    ]}
                    initialValue={jobContactData?.email}
                  >
                    <Input
                      onChange={(e) => this.setState({ email: e.target.value })}
                      className="form-input"
                      placeholder="Please Enter Your Email"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={{
                    span: 24,
                  }}
                  xl={{
                    span: 24,
                  }}
                  lg={{
                    span: 24,
                  }}
                  md={{
                    span: 24,
                  }}
                  sm={{
                    span: 24,
                  }}
                  xs={{
                    span: 24,
                  }}
                >
                  <span className="title_changes">Role</span>
                  <Form.Item
                    name="role"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Role!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue={jobContactData?.contactRole}
                      size="large"
                      showSearch
                      allowClear
                      className="select_Height"
                      placeholder="Please Select A Role"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        this.setState({ newRole: value });
                        value === "Others" && message.info("Please Enter Role");
                      }}
                    >
                      {rolesData?.map((role) => (
                        <Option key={role.id} value={role.label}>
                          {role.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {newRole === "Add New Role" && (
                  <>
                    <Col span={24}>
                      <span className="title_changes">Add New Role</span>
                      <Form.Item
                        name="roleOther"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter New Role!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ roleOther: e?.target?.value })
                          }
                          className="form-input"
                          placeholder="Add New Role"
                        />
                      </Form.Item>

                      <Checkbox
                        checked={this.state.roleCheckBox}
                        onChange={(e) =>
                          this.setState({
                            roleCheckBox: e.target.checked,
                          })
                        }
                        style={{ marginBottom: "18px" }}
                      >
                        <span
                          className="checkBox_size"
                          style={{ fontSize: "14px" }}
                        >
                          Add this new role to default list
                        </span>
                      </Checkbox>
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      jobContactData?.notes ? jobContactData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}

        {isModalData && (
          <BaseModal
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>Dispatch Info</span>
                <Button
                  key="copy"
                  icon={<CopyOutlined />}
                  onClick={this.handleCopy}
                  className="cancelbtnd"
                  style={{
                    height: "35px",
                    width: "100px",
                    borderRadius: "5px",
                    marginRight: "20px",
                  }}
                >
                  Copy
                </Button>
              </div>
            }
            onCancel={() => {
              this.setState({
                isModalData: false,
                mailRecord: "",
              });
            }}
            loading={loading}
            width="30%"
            submitButton={"Send via Email"}
            className="antmodal_grid headermodal"
            formId="dispatchForm"
          >
            <Form
              onFinish={() =>
                this.setState({
                  isSendEmailModalVisible: true,
                })
              }
              id="dispatchForm"
            >
              <div>
                {leadDetails?.jobType === "Trucking Job" ? (
                  <div className="form-data-trucking">
                    <div className="trucking-job-data">
                      <span>
                        {moment(
                          leadDetails?.dispatchData?.dispatchDate
                        )?.format("dddd, MM/DD/YYYY")}
                      </span>
                      <span>{leadDetails?.companyName}</span>
                      <span>{leadDetails?.jobName}</span>
                      <span>
                        {`${leadDetails?.contactDetails[0]?.firstName} ${leadDetails?.contactDetails[0].lastName}`}
                        {leadDetails?.contactDetails[0]?.phone &&
                          `- ${leadDetails?.contactDetails[0]?.phone}`}
                      </span>
                    </div>

                    <div className="trucking-job-data">
                      <span>{leadDetails?.jobType}</span>
                      <span>
                        {`${leadDetails?.dispatchData?.trucksRequested} ${leadDetails?.dispatchData?.trucksRequestedType}`}
                      </span>
                      <span>{mailRecord?.truckType.join(", ")}</span>
                    </div>

                    <div className="trucking-job-data">
                      <span className="heading_item">Pickup Location</span>
                      <span>{mailRecord?.pickUpAddress}</span>
                      <span>
                        {mailRecord?.pickUpLatLng?.lat +
                          ", " +
                          mailRecord?.pickUpLatLng?.lng}
                      </span>
                    </div>

                    <div className="trucking-job-data">
                      <span className="heading_item">Delivery Location</span>
                      <span>{leadDetails?.dropoff}</span>
                      <span>
                        {leadDetails?.dropOffLatLng?.lat +
                          ", " +
                          leadDetails?.dropOffLatLng?.lng}
                      </span>
                    </div>

                    <div className="trucking-job-data">
                      <span className="heading_item">Rate Information</span>
                      <span>{`$${parseFloat(
                        mailRecord?.truckingPrice
                      )?.toLocaleString("us-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} per ${mailRecord?.truckingCostType.toLowerCase()}`}</span>
                      <span>6-Hour Minimum</span>
                    </div>
                  </div>
                ) : (
                  <div className="form-data-trucking">
                    <div className="trucking-job-data">
                      <span>
                        {moment(
                          leadDetails?.dispatchData?.dispatchDate
                        )?.format("dddd, MM/DD/YYYY")}
                      </span>
                      <span>{leadDetails?.companyName}</span>
                      <span>{leadDetails?.jobName}</span>
                      <span>
                        {`${leadDetails?.contactDetails[0]?.firstName} ${leadDetails?.contactDetails[0].lastName}`}
                        {leadDetails?.contactDetails[0]?.phone &&
                          `- ${leadDetails?.contactDetails[0]?.phone}`}
                      </span>
                    </div>

                    <div className="trucking-job-data">
                      <span>{leadDetails?.jobType}</span>
                      <span>{leadDetails?.requiredMaterials}</span>
                      <span>{mailRecord?.truckType.join(", ")}</span>
                    </div>

                    <div className="trucking-job-data">
                      <span className="heading_item">Pickup Location</span>
                      <span>{mailRecord?.vendor}</span>
                      <span>{mailRecord?.pickUpAddress}</span>
                      <span>
                        {mailRecord?.pickUpLatLng?.lat +
                          ", " +
                          mailRecord?.pickUpLatLng?.lng}
                      </span>
                    </div>

                    <div className="trucking-job-data">
                      <span className="heading_item">Delivery Location</span>
                      <span>{leadDetails?.dropoff}</span>
                      <span>
                        {leadDetails?.dropOffLatLng?.lat +
                          ", " +
                          leadDetails?.dropOffLatLng?.lng}
                      </span>
                    </div>

                    <div className="trucking-job-data">
                      <span className="heading_item">Material Information</span>
                      <span>{`${mailRecord?.category} ${
                        mailRecord?.value?.split(" - ")[2]
                      }`}</span>
                      <span>{`${mailRecord?.quantity} ${mailRecord?.quantityType}s`}</span>
                    </div>

                    <div className="trucking-job-data">
                      <span className="heading_item">Rate Information</span>
                      <span>{`Trucking - $${parseFloat(
                        mailRecord?.truckingPrice
                      )?.toLocaleString("us-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} per ${mailRecord?.truckingCostType.toLowerCase()}, ${
                        leadDetails?.taxExempt
                      } `}</span>
                      <span>{`Material - $${parseFloat(
                        mailRecord?.totalPrice
                      )?.toLocaleString("us-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} per ${mailRecord?.totalPriceType.toLowerCase()}, ${
                        leadDetails?.taxExempt
                      }`}</span>
                      <span>{`Quarry Cost - $${parseFloat(
                        mailRecord?.landedPrice
                      )?.toLocaleString("us-EN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} per ${mailRecord?.totalPriceType.toLowerCase()}`}</span>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </BaseModal>
        )}

        {isSendEmailModalVisible && (
          <BaseModal
            title="Send Email"
            visible={true}
            onCancel={() => this.setState({ isSendEmailModalVisible: false })}
            width="30%"
            submitButton={"Confirm"}
            className="antmodal_grid headermodal"
            formId={"sendEmail"}
          >
            <Form onFinish={this.handleSendEmail} id={"sendEmail"}>
              <span className="title_changes">Email being sent to</span>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input email",
                  },
                  {
                    type: "email",
                    message: "The input is not valid Corporate Email",
                  },
                ]}
                initialValue={userDetails?.email}
              >
                <Input placeholder="Email address" className="form-input" />
              </Form.Item>
            </Form>
          </BaseModal>
        )}

        {materialPopup && (
          <BaseModal
            title={
              recordMaterialData?.length !== 0 && jobInfo === "Trucking Job"
                ? "Update Trucking Details"
                : recordMaterialData?.length !== 0 && jobInfo !== "Trucking Job"
                ? "Update Material Details"
                : jobInfo !== "Trucking Job"
                ? "Add Material Details"
                : "Add Trucking Details"
            }
            onCancel={this.onCancelMaterial}
            loading={loading}
            formId="material"
            // width="40%"
            className="antmodal_grid headermodal"
            submitButton={
              jobInfo === "Trucking & Material Job" &&
              topVendorsData?.length === 0
                ? "Find Nearest Vendors"
                : recordMaterialData?.length !== 0 && jobInfo !== "Trucking Job"
                ? "Update"
                : "Add"
            }
            btnWidth={"fit-content"}
            width={
              jobInfo === "Trucking & Material Job" ? "55%" : "35%"
              // window.screen.width > "501" && window.screen.width <= "991"
              //   ? "80%"
              //   : "560px"
            }
          >
            <Loading enableLoading={loading} />
            <Form
              onFinish={
                jobInfo === "Trucking & Material Job" &&
                topVendorsData?.length === 0
                  ? this.getTopVendors
                  : this.onFinishMaterial
              }
              initialValues={{ remember: true }}
              id="material"
              ref={this.formRef}
              style={{ width: "100%" }}
            >
              <Row style={{ width: "100%" }} gutter={[24, 0]}>
                {jobInfo !== "Trucking Job" && (
                  <>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Material Categories</span>
                      <Form.Item
                        name="materialCategory"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Material!",
                          },
                        ]}
                        initialValue={recordMaterialData?.category}
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_Height"
                          placeholder="Please Select A Material"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onClear={() =>
                            this.setState({
                              materialSelectedData: "",
                            })
                          }
                          onChange={(value) => {
                            this.onSelectMaterialCat(value);
                            this.setState({
                              materialValue: value,
                              initialMaterialCost: "",
                              materialPriceState: "",
                              materialCostState: "",
                              materialTruckPrice: "",
                            });
                            this.formRef.current.setFieldsValue({
                              material: "",
                              quantity: "",
                              totalPrice: "",
                              truckingPrice: "",
                            });
                          }}
                        >
                          {Object.keys(materialCategory).map((c, i) => (
                            <Option key={i} value={c}>
                              {c}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">
                        Material Subcategory
                      </span>
                      <Form.Item
                        name="material"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your Material!",
                          },
                        ]}
                        initialValue={recordMaterialData?.label}
                      >
                        <Select
                          size="large"
                          onClear={() =>
                            this.setState({
                              materialSelectedData: "",
                            })
                          }
                          showSearch
                          allowClear
                          className="select_Height"
                          placeholder="Please Select A Material"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={this.onSelectMaterial}
                        >
                          {finalMaterialData?.map(
                            (m) =>
                              m.statusCode && (
                                <Option key={m?.id} value={m?.id}>
                                  {m?.label}
                                </Option>
                              )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <Col span={24}>
                        <span className="title_changes">Testing Status</span>
                        <Form.Item
                          name="materialTest"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Testing!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialTest}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A testMaterial"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.testMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {diameter ? (
                        <Col span={24} style={{ marginTop: "20px" }}>
                          <Row gutter={[12, 0]}>
                            <Col span={12}>
                              <span className="title_changes">Min</span>
                              <Form.Item
                                name="materialMinDiameter"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Diameter!",
                                  },
                                ]}
                                initialValue={
                                  recordMaterialData?.materialMinDiameter
                                }
                                style={{ marginBottom: "10px" }}
                              >
                                {/* <InputNumber
                                  formatter={(value) =>
                                    value !== "" ? `${value}"` : ""
                                  }
                                  style={{ width: "100%", height: "40px" }}
                                  placeholder="Please enter your diameter"
                                  className="form-input"
                                  onChange={this.resetvendorValues}
                                /> */}
                                <Input
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  className="form-input"
                                  placeholder="Please enter diameter"
                                  onChange={this.resetvendorValues}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="title_changes">Max</span>
                                <Switch
                                  checkedChildren={"Diameter"}
                                  unCheckedChildren={"Range"}
                                  onChange={(e) =>
                                    this.setState({ diameter: e })
                                  }
                                  checked={diameter}
                                >
                                  Size Range
                                </Switch>
                              </span>
                              <Form.Item
                                name="materialMaxDiameter"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Diameter!",
                                  },
                                ]}
                                initialValue={
                                  recordMaterialData?.materialMaxDiameter
                                }
                                style={{ marginBottom: "10px" }}
                              >
                                <Input
                                  style={{
                                    height: "40px",
                                    width: "100%",
                                  }}
                                  className="form-input"
                                  placeholder="Please enter diameter"
                                  onChange={this.resetvendorValues}
                                />
                                {/* <InputNumber
                                  formatter={(value) =>
                                    value !== "" ? `${value}"` : ""
                                  }
                                  style={{ width: "100%", height: "40px" }}
                                  placeholder="Please enter your diameter"
                                  className="form-input"
                                  onChange={this.resetvendorValues}
                                /> */}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col span={24} style={{ marginTop: "20px" }}>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="title_changes">Diameter</span>
                            <Switch
                              checkedChildren={"Diameter"}
                              unCheckedChildren={"Range"}
                              onChange={(e) => this.handleCheckStatus(e)}
                            >
                              Size Range
                            </Switch>
                          </span>
                          <Form.Item
                            name="materialDiameter"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Diameter!",
                              },
                            ]}
                            initialValue={recordMaterialData?.materialDiameter}
                            style={{ marginBottom: "10px" }}
                          >
                            {/* <InputNumber
                              formatter={(value) =>
                                value !== "" ? `${value}"` : ""
                              }
                              style={{ width: "100%", height: "40px" }}
                              placeholder="Please enter your diameter"
                              className="form-input"
                              onChange={this.resetvendorValues}
                            /> */}
                            <Input
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              className="form-input"
                              placeholder="Please enter diameter"
                              onChange={this.resetvendorValues}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Col>
                    <Col
                      span={12}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <Col span={24}>
                        <span className="title_changes">Washed Status</span>
                        <Form.Item
                          name="materialStatus"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter washed status!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialStatus}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A statusMaterial"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.statusMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ marginTop: "20px" }}>
                        <span className="title_changes">Supply</span>
                        <Form.Item
                          name="materialSupply"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter supply!",
                            },
                          ]}
                          initialValue={recordMaterialData?.materialSupply}
                          style={{ marginBottom: "10px" }}
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            className="select_Height"
                            // style={{ height: "40px" }}
                            placeholder="Please Select A Supply"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={this.resetvendorValues}
                          >
                            {QuarryJsonData?.supplyMaterial?.map((m, i) => (
                              <Option key={i} value={m}>
                                {m}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Col>

                    <Loading enableLoading={this.state.loading} />
                    {topVendorsData?.length > 0 && (
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        className="tiprotitle"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <BaseTable
                          columnsData={topVendorsColumns}
                          source={topVendorsData?.slice(0, 3)}
                          total={topVendorsData?.length}
                          className="table_laptopscreen"
                          pagination={false}
                          rowSelection={{
                            type: "radio",
                            ...rowSelection,
                          }}
                          rowKey={(record) => record.id}
                        />
                      </Col>
                    )}
                    {topVendorsData?.length > 0 && (
                      <>
                        {/* <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            Required Quantity
                          </span>
                          <Form.Item
                            name="quantity"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Quantity",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Quantity"
                              addonAfter={selectAfter}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) =>
                                this.setState({
                                  quantity: e,
                                  // initialMaterialCost: materialCostPrice,
                                })
                              }
                            />
                          </Form.Item>
                        </Col> */}
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Use Markup</span>
                          <Form.Item
                            name="markUp"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Use Markup",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Use Markup"
                              addonAfter={"%"}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                this.setState({
                                  useMarkUp: e,
                                  materialPriceState:
                                    e === initialMaterialCost &&
                                    e < initialMaterialCost
                                      ? 0
                                      : initialMaterialCost +
                                        initialMaterialCost * (e / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  totalPrice:
                                    e === 0
                                      ? initialMaterialCost
                                      : e
                                      ? initialMaterialCost +
                                        initialMaterialCost * (e / 100)
                                      : "",
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Material Cost</span>
                          <Form.Item
                            name="materialCost"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Material Cost!",
                              },
                            ]}
                            initialValue={initialMaterialCost?.toLocaleString(
                              2
                            )}
                            style={{ marginBottom: "0px" }}
                          >
                            <InputNumber
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              className="material"
                              prefix={"$"}
                              precision={2}
                              decimalSeparator={"."}
                              placeholder="Please Enter Material Cost"
                              addonAfter={selectAfterMaterial}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              readOnly={!materialCustomRate}
                              onChange={(e) => {
                                this.setState({
                                  materialCostPrice: e,
                                  initialMaterialCost: e,
                                  materialPriceState:
                                    useMarkUp === e && useMarkUp < e
                                      ? 0
                                      : e + e * (useMarkUp / 100),
                                });
                                this.formRef.current.setFieldsValue({
                                  totalPrice:
                                    useMarkUp === 0
                                      ? e
                                      : useMarkUp
                                      ? e + e * (useMarkUp / 100)
                                      : "",
                                });
                              }}
                            />
                          </Form.Item>
                          <div
                            style={{ marginTop: "2px", marginBottom: "5px" }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                this.setState({
                                  materialCustomRate: e.target.checked,
                                })
                              }
                            >
                              <span>Custom Rate</span>
                            </Checkbox>
                          </div>
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">Material Price</span>
                          <Form.Item
                            name="totalPrice"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Material Price!",
                              },
                            ]}
                            initialValue={recordMaterialData?.totalPrice?.toLocaleString(
                              2
                            )}
                          >
                            <InputNumber
                              prefix={"$"}
                              precision={2}
                              // formatter={(value) =>
                              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              // }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              decimalSeparator={"."} // value={quantity}
                              placeholder="Please Enter Material Price"
                              addonAfter={selectAfterCustomer}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                this.formRef.current.setFieldsValue({
                                  markUp:
                                    ((parseFloat(e) - initialMaterialCost) /
                                      initialMaterialCost) *
                                    100,
                                });
                                this.setState({ materialPriceState: e });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xxl={{ span: 8 }}
                          xl={{ span: 8 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                        >
                          <span className="title_changes">
                            Required Quantity
                          </span>
                          <Form.Item
                            name="quantity"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Quantity",
                              },
                            ]}
                            initialValue={recordMaterialData?.quantity}
                          >
                            <InputNumber
                              decimalSeparator="."
                              precision={2}
                              placeholder="Quantity"
                              addonAfter={selectAfter}
                              style={{
                                height: "40px",
                                width: "100%",
                              }}
                              onChange={(e) =>
                                this.setState({
                                  quantity: e,
                                  // initialMaterialCost: materialCostPrice,
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                )}

                {jobInfo === "Trucking Job" && (
                  <>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Pickup Location</span>
                      <Form.Item
                        name="pickup"
                        rules={[
                          {
                            required: false,
                            errorMessage: true,
                            message: "Please Enter Your Pickup Location",
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            citySearch,
                            defaultInputValue: `${
                              recordMaterialData?.pickUpAddress
                                ? recordMaterialData?.pickUpAddress
                                : ""
                            }`,
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address, "pickup");
                              this.getAddress(address, "pickup");
                              this.setState({
                                pickUpAddress: address.label,
                              });
                            },
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {((milesData !== "" && milesData !== undefined) ||
                      recordMaterialData?.milesData !== undefined) && (
                      <Col
                        span={24}
                        className="tiprotitle"
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <span className="title_changes">
                          <b
                            style={{
                              marginBottom: "10px",
                              borderBottom: "1px solid gray",
                            }}
                          >
                            Run and Rate information:
                          </b>
                          {milesData?.text !== undefined ? (
                            <div>
                              {milesData?.text + "les, " + duration?.text}
                            </div>
                          ) : (
                            recordMaterialData?.milesData?.text !==
                              undefined && (
                              <div>
                                {recordMaterialData?.milesData?.text +
                                  "les, " +
                                  recordMaterialData?.duration?.text}
                              </div>
                            )
                          )}
                        </span>
                      </Col>
                    )}
                  </>
                )}
                {((jobInfo === "Trucking & Material Job" &&
                  topVendorsData?.length > 0) ||
                  jobInfo !== "Trucking & Material Job") && (
                  <>
                    <Col
                      xxl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      xl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      lg={{ span: jobInfo === "Trucking Job" ? 12 : 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Trucking Price</span>
                      <Form.Item
                        name="truckingPrice"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Trucking Price!",
                          },
                        ]}
                        initialValue={recordMaterialData?.truckingPrice?.toLocaleString(
                          2
                        )}
                      >
                        <InputNumber
                          prefix={"$"}
                          precision={2}
                          decimalSeparator={"."} // value={quantity}
                          placeholder="Please Enter Trucking Price"
                          addonAfter={selectAfterTruckPrice}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          onChange={(e) => {
                            this.setState({
                              materialTruckPrice: e,
                              truckingCost:
                                parseFloat(e) - parseFloat(e) * (12.5 / 100),
                            });
                            this.formRef.current.setFieldsValue({
                              truckingCost:
                                parseFloat(e) - parseFloat(e) * (12.5 / 100),
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      xl={{ span: jobInfo === "Trucking Job" ? 12 : 8 }}
                      lg={{ span: jobInfo === "Trucking Job" ? 12 : 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Trucking Cost</span>
                      <Form.Item
                        name="truckingCost"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Trucking Cost!",
                          },
                        ]}
                        initialValue={recordMaterialData?.truckingCost?.toLocaleString(
                          2
                        )}
                        style={{ marginBottom: "0px" }}
                      >
                        <InputNumber
                          prefix={"$"}
                          precision={2}
                          // formatter={(value) =>
                          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          // }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          decimalSeparator={"."} // value={quantity}
                          placeholder="Please Enter Trucking Cost"
                          addonAfter={selectAfterTruck}
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                          readOnly={!customRate}
                          onChange={(e) => {
                            this.setState({
                              truckingCost: e,
                              materialTruckPrice:
                                parseFloat(e) + parseFloat(e) * (12.5 / 100),
                            });
                            this.formRef.current.setFieldsValue({
                              truckingPrice:
                                parseFloat(e) + parseFloat(e) * (12.5 / 100),
                            });
                          }}
                        />
                      </Form.Item>

                      <div style={{ marginTop: "2px", marginBottom: "5px" }}>
                        <Checkbox
                          onChange={(e) =>
                            this.setState({
                              customRate: e.target.checked,
                            })
                          }
                        >
                          <span>Custom Rate</span>
                        </Checkbox>
                      </div>
                    </Col>
                  </>
                )}

                {jobInfo === "Trucking & Material Job" &&
                  topVendorsData?.length > 0 && (
                    <>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      ></Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row justify="space-between">
                          <Col span={12}>
                            <span className="title_changes">
                              Landed Cost -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {materialCostType === truckingCostType &&
                            initialMaterialCost &&
                            truckingCost
                              ? `$${parseFloat(
                                  initialMaterialCost + truckingCost
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                 / ${materialCostType}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Landed Price -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialPriceState &&
                            materialTruckPrice
                              ? `$${parseFloat(
                                  materialPriceState + materialTruckPrice
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })} / ${truckingPriceType}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Divider
                        style={{ width: "100%", border: "1px solid #dbdbdb" }}
                      />
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">Total Sale - </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialPriceState &&
                            quantity &&
                            materialTruckPrice
                              ? `$${parseFloat(
                                  (materialPriceState + materialTruckPrice) *
                                    quantity
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Total Expense -{" "}
                            </span>
                          </Col>
                          <Col span={12}>
                            {materialCostType === truckingCostType &&
                            initialMaterialCost &&
                            quantity &&
                            truckingCost
                              ? `$${parseFloat(
                                  (initialMaterialCost + truckingCost) *
                                    quantity
                                )?.toLocaleString("us-EN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span className="title_changes">
                              Total Profit (%) -
                            </span>
                          </Col>
                          <Col span={12}>
                            {truckingPriceType === totalPriceType &&
                            materialCostType === truckingCostType &&
                            materialPriceState !== undefined &&
                            materialPriceState !== "" &&
                            materialTruckPrice !== undefined &&
                            materialTruckPrice !== "" &&
                            quantity !== undefined &&
                            quantity !== "" &&
                            initialMaterialCost !== undefined &&
                            initialMaterialCost !== "" &&
                            materialTruckPrice !== undefined &&
                            materialTruckPrice !== "" &&
                            truckingCost !== undefined &&
                            truckingCost !== ""
                              ? `$${parseFloat(
                                  (materialPriceState + materialTruckPrice) *
                                    quantity -
                                    (initialMaterialCost + truckingCost) *
                                      quantity
                                )?.toLocaleString("us-EN", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })} (${
                                  !isNaN(
                                    (
                                      (((materialPriceState +
                                        materialTruckPrice) *
                                        quantity -
                                        (initialMaterialCost + truckingCost) *
                                          quantity) /
                                        ((materialPriceState +
                                          materialTruckPrice) *
                                          quantity)) *
                                      100
                                    )?.toFixed(2)
                                  )
                                    ? (
                                        (((materialPriceState +
                                          materialTruckPrice) *
                                          quantity -
                                          (initialMaterialCost + truckingCost) *
                                            quantity) /
                                          ((materialPriceState +
                                            materialTruckPrice) *
                                            quantity)) *
                                        100
                                      )?.toFixed(2)
                                    : "0.00"
                                }%)`
                              : "N/A"}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
              </Row>
            </Form>
          </BaseModal>
        )}

        {notesPopup && (
          <BaseModal
            title={recordNotesData !== "" ? "Update Notes" : "Add New Notes"}
            onCancel={() => {
              this.setState({
                notesPopup: false,
              });
            }}
            visible={notesPopup}
            destroyOnClose={true}
            formId="addNotes"
            loading={loading}
            className="antmodal_grid headermodal"
            width={
              window.screen.width > "501" && window.screen.width <= "991"
                ? "80%"
                : "520px"
            }
          >
            <Form
              onFinish={this.onFinishNote}
              initialValues={{ remember: true }}
              id="addNotes"
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <span className="title_changes">Type of Notes</span>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.typeofNotes
                        ? recordNotesData?.typeofNotes
                        : ""
                    }
                  >
                    <Input
                      style={{
                        width: "100%",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Type Of Notes"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="title_changes">Notes</span>
                  <Form.Item
                    name="notes"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Notes!",
                      },
                    ]}
                    initialValue={
                      recordNotesData?.notes ? recordNotesData?.notes : ""
                    }
                  >
                    <TextArea
                      rows={4}
                      style={{
                        width: "100%",
                        height: "75px",
                        borderColor: "#d6d6d6",
                      }}
                      className="form-input"
                      placeholder="Please Enter Your Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </BaseModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  totalResult: state.reducer?.leadData,
  errorLoading: state?.reducer?.leadData?.error,
  loading: state?.reducer?.leadData?.loading,
  leadUpdateInfo: state?.reducer?.leadUpdateInfo,
  contactsInfo: state.reducer?.contactsInfo,
  contactData: state.reducer?.contactData,
  companyResult: state?.reducer?.companyResult,
  docResult: state.reducer?.docResult,
  documentsData: state.reducer?.docsData,
  notesResult: state.reducer?.notesResult,
  notesInfo: state.reducer?.notesInfo,
  rolesData: state.reducer?.dropDownOptions?.totalData?.filter(
    (t) => t.typeofDropDown === "roles"
  ),
  trucksData: state.reducer?.dropDownOptions?.totalData?.filter(
    (t) => t.typeofDropDown === "trucksType"
  ),
  materialList: state.reducer?.dropDownOptions?.totalData?.filter(
    (q) => q?.typeofDropDown === "material"
  ),
  dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
  dropDownOptions: state.reducer?.dropDownOptions,
  quarryResult: state.reducer?.quarriesResult,
  topVendorsData: state.reducer.vendorsData,
  estimateResult: state.reducer.estimateResult,
  ticketsResult: state?.reducer?.ticketsResult,
  mailResult: state?.reducer?.mailResult,
});

const mapDispatchToProps = (dispatch) => ({
  actionFetchgetLead: (id) => {
    dispatch(getLead(id));
  },
  actionUpdateJob: (id, data) => {
    dispatch(updateLead(id, data));
  },
  actionAddContact: (data) => {
    dispatch(addContact(data));
  },
  actionUpdateContact: (id, data) => {
    dispatch(updateContacts(id, data));
  },
  actionDeleteContact: (id) => {
    dispatch(deleteContactandJob(id));
  },
  actionFetchCompany: (id) => {
    dispatch(getCompany(id));
  },
  actionAddDoc: (data, updateStatus) => {
    dispatch(addDocument(data, updateStatus));
  },
  actionUpdateDoc: (id, data) => {
    dispatch(updateDocument(id, data));
  },
  actiongetDocuments: (id) => {
    dispatch(getDocuments(id));
  },
  actiongetNotes: (id) => {
    dispatch(getNotes(id));
  },
  actionAddNotes: (id) => {
    dispatch(addNotes(id));
  },
  actionUpdateNotes: (id, data) => {
    dispatch(updateNotes(id, data));
  },
  actionDeleteNotes: (id) => {
    dispatch(deleteNotes(id));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actionFetchQuarries: () => {
    dispatch(getQuarryLocations());
  },
  actionFetchTopVendors: (data) => {
    dispatch(getTopVendors(data));
  },
  actionUpdateEstimate: (data) => {
    dispatch(updateGenerateEstimate(data));
  },
  actionFetchTickets: (id) => {
    dispatch(getSingleTicketData(id));
  },
  actionFetchDispatchMail: (data) => {
    dispatch(disptachMail(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(LeadDetails)));
