import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Popover,
  Input,
  Form,
  Popconfirm,
  Select,
  message,
  Checkbox,
  Tooltip,
  AutoComplete,
} from "antd";
import {
  UnorderedListOutlined,
  EllipsisOutlined,
  UsergroupAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { RiDashboardLine } from "react-icons/ri";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import SearchFunction from "../../../Common/SearchFunction";
import { withAppContext } from "../../../Store/AppContext";
import { add, list, updateTotal } from "../../../api/Apis";
import appConfig from "../../../config/AppConfig";
import DraggableCard from "../../../Common/DraggableCard";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import funnelStages from "../../../Json/funnel.json";
import Loading from "../../../Common/Loading";
import ErrorView from "../../../Common/ErrorView";
import BaseModal from "../../../Common/BaseModal";
import BaseTable from "../../../Common/BaseTable";
import TextArea from "antd/lib/input/TextArea";
import { IoFilterSharp } from "react-icons/io5";
import Filter from "../../../Common/Filter";
import { v4 as uuidv4 } from "uuid";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaMap } from "react-icons/fa";
import { connect } from "react-redux";
import {
  addContact,
  addContacts,
  addLead,
  deleteLead,
  getCompanies,
  getCompany,
  getDropDownOptions,
  getLeads,
  getQuarryLocations,
  updateDropDownOptions,
  updateLead,
} from "../../../Redux/Actions/actions";
import { withRouter } from "react-router-dom";
import moment from "moment";
import MapComponent from "../../Maps/MapComponent";

const jobType = [
  { label: "Trucking Job", value: "Trucking Job" },
  { label: "Trucking & Material Job", value: "Both" },
];

const { Option } = Select;

class TruckingMaterial extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      boardView: true,
      selectedRowKeys: [],
      visible: false,
      addressState: "",
      zipCode: "",
      leadsData: "",
      funnelData: [],
      citySearch: "",
      totalLeadsData: [],
      searchingData: [],
      filterData: [],
      latitude: "",
      longitude: "",
      filterVisible: false,
      companyData: [],
      filterCompanyData: [],
      filterDriverData: [],
      jobInfo: "",
      truckDropInfo: "",
      selectedMaterial: [],
      recordMaterailData: "",
      materialCheck: false,
      selectedMaterialType: "",
      pickUpAddress: "",
      dropAddress: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      roleOther: "",
      isContactVisible: false,
      contactsData: [],
      leadQuote: [],
      recordCompanyId: "",
      equipmentTypeDetails: [],
      getCompanyData: [],
      quantityType: "Ton",
      truckingCostType: "Ton",
      truckingPriceType: "Ton",
      totalPriceType: "Ton",
      materialCostType: "Ton",
      dropoffLatitude: "",
      dropoffLongitude: "",
      pickUpLatitude: "",
      pickupLongitude: "",
      filterPropTruckingData: {
        company: undefined,
        jobType: undefined,
        jobStatus: [
          "Job Lead",
          "Contacted",
          "Build Quote",
          "Quote Delivered",
          "Quote Accepted",
          "Dispatch Approved",
        ],
      },
      pickupDetails: [],
      loading: false,
      vendorData: [],
      initialMaterialCost: "",
      materialCostState: "",
      materialPriceState: "",
      lead: false,
      companyCheck: "",
      recordContactData: "",
      selectVendor: "",
      materialValue: "",
      categoriesList: [],
      materialCostPrice: "",
      materialTruckPrice: 0,
      milesData: "",
      duration: "",
      vendorName: "",
      quantity: 1,
      contactInfo: [],
      masterRoleId: "",
      masterTruckId: "",
      quarryContacts: [],
      rolesData: [],
      materialList: [],
      materialSelectedData: {},
      topVendorsData: [],
      vendorDetails: "",
      updateJobIdContact: [],
      modallLoading: false,
      customRate: false,
      materialCustomRate: false,
      materialCategory: {},
      openDropdown: "",
      salesCommission: "",
      selectedCompanyId: "",
      companyContacts: [],
      isNewContact: true,
      showMapComponent: false,
      showTab: "listView",
      showSearchResult: false,
    };
  }

  componentDidMount = () => {
    document.title = "Twisted Nail - Trucking & Material";
    this?.props?.actionFetchLeads();
    this?.props?.actionFetchCompanies();
    this.funnelInfo();
    this.props.actiongetDropDownOptions();
    this.props.actionFetchQuarries();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    let filterStorageData = JSON.parse(
      localStorage.getItem("filterStorageData")
    );
    if (
      prevProps?.topVendorsData?.success === false &&
      this.props?.topVendorsData?.success === true
    ) {
      if (this.props?.topVendorsData.vendors?.length > 0) {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
      } else {
        this.setState({
          topVendorsData: this.props?.topVendorsData.vendors,
          loading: false,
        });
        message.info("No vendors found!");
      }
    }
    if (prevProps.context.dropDownId !== this.props.context.dropDownId) {
      this.funnelInfo();
      this?.props?.actionFetchLeads();
      this?.props?.actionFetchCompanies();
      this.props.actiongetDropDownOptions();
    }
    if (
      JSON.stringify(prevState?.totalLeadsData) ===
      JSON.stringify(this.state?.totalLeadsData)
    ) {
      let hashKeyValue = window.location.hash;
      if (hashKeyValue === "#quickJob") {
        hashKeyValue = "";
        this.props.history.push(`${window.location.pathname}`);
        this?.props?.actionFetchLeads();
      }
    }
    if (
      prevProps?.result?.success === false &&
      this.props?.result?.success === true
    ) {
      const hasFilter =
        filterStorageData?.filter &&
        Object.keys(filterStorageData?.filter).length > 0;

      let getLeadsData = this.props?.result?.leads?.filter(
        (f) => !Array.isArray(f)
      );
      const filterData = getLeadsData?.filter(
        (e) => e?.leadType && e?.leadType[0]?.id === "1" && e?.archived !== false
      );
      this.setState(
        {
          totalLeadsData: filterData,
          filterData: filterData,
          searchingData: filterData
        },
        () => {
          if (hasFilter) {
            this.filterResultData(filterStorageData?.filter, true);
          }
        }
      );
    }
    if (
      prevProps?.companiesResult?.success === false &&
      this.props?.companiesResult?.success === true
    ) {
      this.setState({
        companyData: this.props?.companiesResult?.companies?.filter(
          (c) => !c.isOwnerOperator && c.company
        ),
      });
    }
    if (
      prevProps?.leadInfo?.success === false &&
      this.props?.leadInfo?.success === true
    ) {
      this.props.history?.push(`${window.location.pathname}#quickJob`);
      this?.props?.actionFetchLeads();
      this?.props?.actionFetchCompanies();
      this.setState({
        isCheckBox: false,
        isCheckBoxcontact: false,
        deliverySelectedAddress: "",
        pickupSelectedAddress: "",
        dropoffLatitude: "",
        dropoffLongitude: "",
        pickUpLatitude: "",
        pickupLongitude: "",
        contactInfo: [],
      });
    }

    if (
      prevProps?.leadUpdateInfo?.success === false &&
      this.props?.leadUpdateInfo?.success === true
    ) {
      this?.props?.actionFetchLeads();
      this?.props?.actionFetchCompanies();
      this.props.context.handleFilterInfo();
      this.setState({
        selectedMaterial: [],
        recordMaterialData: [],
        truckDropInfo: false,
        materialCostType: "Ton",
        truckingCostType: "Ton",
        quantityType: "Ton",
        totalPriceType: "Ton",
        showSpecificDate: false,
        iscalenderVisible: false,
        dropoffLatitude: "",
        dropoffLongitude: "",
        quarryContacts: this.state?.leadsData?.multipleContacts?.filter(
          (e) => e.materialId
        ),
        selectedContacts: this.state?.leadsData?.multipleContacts?.map(
          (o) => o?.id
        ),
        checkData: "",
      });
    }
    if (
      prevProps?.companyResult?.success === false &&
      this.props?.companyResult?.success === true
    ) {
      let quarryContacts = [];
      if (this.state?.leadsData?.multipleContacts?.length > 0) {
        quarryContacts =
          this.props.companyResult?.companyData?.contactDetails.concat(
            this.state?.leadsData?.multipleContacts
          );
      } else {
        quarryContacts = this.props.companyResult?.companyData?.contactDetails;
      }

      quarryContacts = quarryContacts?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t?.id === value?.id)
      );

      this.setState({
        contactsData: quarryContacts,
        modallLoading: false,
      });
    }
    if (
      prevProps?.contactData?.success === false &&
      this.props?.contactData?.success === true
    ) {
      let data = this.state.selectedContacts;
      data.push(this.props.contactData.contact.data.id);
      let contactData = this.state.contactsData;
      contactData.push(this.props.contactData.contact.data);
      this.setState({
        selectedContacts: data,
        contactsData: contactData,
        modallLoading: false,
      });
      this?.props?.actionFetchLeads();
    }
    if (
      prevProps?.dropDownOptions?.success === false &&
      this.props?.dropDownOptions?.success === true
    ) {
      let materialData = {};
      this.props.materialList[0]?.materialsNewList?.forEach(
        (eachMat, index) => {
          if (eachMat?.statusCode) {
            materialData[eachMat.category] = !Object.keys(
              materialData
            ).includes(eachMat.category)
              ? []
              : materialData[eachMat.category];
          }
        }
      );
      this.setState({
        materialCategory: materialData,
        rolesData: this.props.rolesData[0]?.rolesData?.sort((a, b) =>
          a?.value > b?.value ? 1 : -1
        ),
        materialList: this.props.materialList[0]?.materialsNewList,
      });
    }
    if (
      prevProps?.dropDownOptionsInfo?.success === false &&
      this.props?.dropDownOptionsInfo?.success === true
    ) {
      this.props.actiongetDropDownOptions();
    }
    if (
      prevProps?.quarriesData?.success === false &&
      this.props?.quarriesData?.success === true
    ) {
      this.setState({
        quarryDetails: this.props.quarriesData.quarryData,
        vendorData: this.props.quarriesData.quarryData,
      });
    }
  }

  onClickRefresh = () => {
    this?.props?.actionFetchLeads();
    this.funnelInfo();
  };

  onResetData = () => {
    this.setState({
      showSearchResult: false,
    });
    this?.props?.actionFetchLeads();
  };

  funnelInfo = () => {
    let funnel = [];
    const funnelId = "1";
    funnelStages.stages.map(
      (eachItem) =>
        parseInt(funnelId) === parseInt(eachItem.funnelId) &&
        eachItem.stage.map((stage) => {
          funnel.push(stage.content);
          this.setState({
            funnelData: funnel,
          });
          return true;
        })
    );
  };
  showMap = (filterData) => {
    const { showSearchResult } = this.state;
    return (
      <MapComponent
        filteredMapData={filterData}
        stageId
        truckingSearch={showSearchResult}
      />
    );
  };

  deleteRecordData = (record) => {
    this.props.actionDeleteLead(record?.id);
  };

  filterResultData = (data, noUpdate) => {
    const { filterData } = this.state;
    if (data["jobType"]?.length === 0) {
      delete data["jobType"];
    }
    let resultData = this.findAllFilterData(filterData, data);
    this.setState({
      totalLeadsData: resultData,
      filterPropTruckingData: data,
      searchingData: resultData,
    });

    let filterInfo = {};
    const { jobStatus, ...employeeRest } = data;
    filterInfo = employeeRest;
    Object.keys(filterInfo)?.forEach((key) => {
      if (filterInfo[key] === undefined) {
        delete filterInfo[key];
      }
    });
    if (!noUpdate) {
      localStorage.setItem(
        "filterStorageData",
        JSON.stringify({
          filter: data,
          filterShowData: filterInfo,
        })
      );
    }
    this.closeFilter();
  };

  findAllFilterData = (data, filterCriteria) => {
    return data.filter((item) => {
      if (
        (!filterCriteria.company ||
          filterCriteria.company === item.companyId) &&
        (!filterCriteria.jobType ||
          filterCriteria.jobType.includes(item.jobType)) &&
        (!filterCriteria.salesPersonName ||
          filterCriteria.salesPersonName === item.salesPersonId) &&
        (!filterCriteria.jobStatus ||
          filterCriteria.jobStatus.includes(item.stageId))
      ) {
        return true;
      }
      return false;
    });
  };

  closeFilter = () => {
    this.setState({
      visible: false,
      filterVisible: false,
    });
  };

  onClearFilter = () => {
    localStorage.removeItem("filterStorageData", JSON.stringify({}));
    this.setState(
      {
        filterVisible: false,
        visible: false,
        filterPropTruckingData: {
          company: undefined,
          jobType: undefined,
          jobStatus: [
            "Job Lead",
            "Contacted",
            "Build Quote",
            "Quote Delivered",
            "Quote Accepted",
            "Dispatch Approved",
          ],
        },
      },
      () => this?.props?.actionFetchLeads()
    );
  };

  updateMultipleContacts = (
    quarryContact,
    id,
    leadData,
    quarryContactsData
  ) => {
    const { updateJobIdContact, leadQuote } = this.state;
    if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote) &&
      quarryContactsData?.length > 0
    ) {
      quarryContact["entityData"] =
        quarryContactsData.concat(updateJobIdContact);
      leadData["entityData"]["multipleContacts"] = quarryContact["entityData"];
      this.updateMultipleContact(quarryContact, id, leadData);
    } else if (
      JSON.stringify(updateJobIdContact) !== JSON.stringify(leadQuote)
    ) {
      let updateData = {
        entityData: updateJobIdContact,
      };
      this.updateMultipleContact(updateData, id, leadData);
    } else if (quarryContactsData?.length > 0) {
      leadData["entityData"]["multipleContacts"] =
        quarryContactsData.concat(updateJobIdContact);
      this.addMultipleContact(quarryContact, id, leadData);
    } else {
      this.props.actionUpdateJob(id, leadData);
    }
  };

  updateMultipleContact = (quarryContact, id, leadData) => {
    updateTotal("updateMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.props.actionUpdateJob(id, leadData);
        }
      })
      .catch((err) => console.log(err));
  };

  addMultipleContact = (quarryContact, id, leadData) => {
    add("addMultipleContacts", quarryContact)
      .then((result) => {
        if (result.success) {
          this.props.actionUpdateJob(id, leadData);
        }
      })
      .catch((err) => console.log(err));
  };

  getAddress = (address, type) => {
    if (type === "pickup") {
      this.setState({
        selectedPickupAddress: address.label,
      });
    } else if (type === "dropOff") {
      this.setState({
        selectedDropOffAddress: address.label,
      });
    } else {
      this.setState({ selectedAddress: address.label });
    }
  };

  handleSelect = (address, type) => {
    const { dropoffLatitude, dropoffLongitude, lead } = this.state;
    this.setState({
      citySearch: address.label,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          if (type === "pickup") {
            if (!lead) {
              this.setState({
                loading: true,
              });
              let url =
                "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
                [dropoffLatitude, dropoffLongitude] +
                "&origins=" +
                [lat, lng] +
                "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
              let milesData = {
                entityData: {
                  url,
                },
              };
              add("getMiles", milesData)
                .then((res) =>
                  this.setState({
                    pickUpLatitude: lat,
                    pickupLongitude: lng,
                    milesData: res.data.distance,
                    duration: res.data.duration,
                    loading: false,
                  })
                )
                .catch((err) => {
                  this.setState({
                    loading: false,
                  });
                  console.log(err, "err");
                });
            } else {
              this.setState({
                pickUpLatitude: lat,
                pickupLongitude: lng,
              });
            }
          } else if (type === "dropOff") {
            this.setState({ dropoffLatitude: lat, dropoffLongitude: lng });
          } else {
            this.setState({ latitude: lat, longitude: lng });
          }
        });
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address?.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
  };

  searchResult = (searchValue) => {
    const { filterData } = this.state;
    let searchFilter = [];
    if (typeof searchValue === "number") {
      searchFilter = filterData.filter(
        (e) =>
          e?.jobNumber.toString().indexOf(searchValue.toString()) > -1 ||
          parseInt(e["phone"]?.replace(/[^+0-9]/g, ""), 10) === searchValue
      );
    } else if (typeof searchValue === "string") {
      searchValue = searchValue.toLowerCase();
      searchFilter = filterData.filter(
        (e) =>
          e["company"]?.toLowerCase().indexOf(searchValue) > -1 ||
          e["companyName"]?.toLowerCase().indexOf(searchValue) > -1
      );
    }
    this.setState({
      totalLeadsData: searchFilter,
      showSearchResult: true,
    });
    this.showMap(searchFilter);
  };

  onFinishContact = (values) => {
    const { leadsData, lead, selectedCompanyId, companyContacts } = this.state;
    this.setState({
      loading: true,
    });
    if (this.state.isCheckcontact) {
      this.updateRolesData(values["roleOther"], "roles");
    }
    if (lead) {
      const { recordContactData } = this.state;

      if (recordContactData !== "") {
        let filteredData = this.state.contactInfo.filter(
          (c) => c.id !== recordContactData?.id
        );
        this.setState({
          contactInfo: filteredData,
        });
      }

      let data = {
        entityData: {
          id: uuidv4(),
          contact_name:
            values["fName"] + " " + values["lName"] ||
            recordContactData?.contact_name,
          contact_number: values.phone,
          jobId: leadsData ? [leadsData?.id] : [],
          contact_email: values.email,
          firstName: values.fName,
          lastName: values.lName,
          phone: values.phone,
          email: values.email,
          contactRole:
            values["role"] === "Add New Role"
              ? values["roleOther"]
              : values["role"],
          company:
            this.state.companyCheck === ""
              ? ""
              : this.state.companyCheck || recordContactData?.company,
          companyId:
            selectedCompanyId !== "" && selectedCompanyId !== undefined
              ? selectedCompanyId
              : "",
          notes: values["notes"],
        },
      };
      let contactData = this.state.contactInfo?.filter(
        (c) => c?.email === values?.email && c?.phone === values?.phone
      );
      // console.log(values?.email, "values?.email", contactData);
      if (contactData?.length > 0) {
        message.info("Given Email and Phone Number already exists");
        this.setState({
          loading: false,
        });
        return true;
      } else {
        this.setState({
          isContactVisible: false,
        });
        add(appConfig["entityNames"]["checkContact"], data)
          .then((result) => {
            if (result.data.message === "success") {
              add(appConfig["entityNames"]["updateContact"], data);
              message.success("Contact Added Successfully!");
              let contactInfo =
                this.state.contactInfo?.length > 0
                  ? this.state.contactInfo
                  : [];
              if (recordContactData?.length > 0) {
                let filteredData = this.state.contactInfo?.filter(
                  (c) => c.id !== recordContactData?.id
                );
                filteredData.push(data["entityData"]);
                this.formRef.current.setFieldsValue({
                  contactperson: filteredData?.map((e) => e.id),
                });
                this.setState({
                  contactInfo: filteredData,
                  companyContacts: companyContacts.concat(filteredData),
                  loading: false,
                  isCheckcontact: false,
                  newRole: "",
                });
              }
              contactInfo.push(data["entityData"]);
              this.formRef.current.setFieldsValue({
                contactperson: contactInfo?.map((e) => e.id),
              });
              this.setState({
                companyContacts: companyContacts.concat(contactInfo),
                contactInfo,
                loading: false,

                isCheckcontact: false,
                newRole: "",
              });
            } else {
              message.info(result.data.message);
              this.setState({
                loading: false,
                isCheckcontact: false,
                newRole: "",
              });
              return true;
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            console.log(err);
          });
      }
    } else {
      this.setState({ isContactVisible: false });
      let commonData = {
        companyId: leadsData?.companyId,
        firstName: values["fName"],
        lastName: values["lName"],
        phone: values["phone"],
        email: values["email"],
        jobId: [leadsData?.id],
        contactRole:
          values["role"] === "Others" ? values["roleOther"] : values["role"],
      };
      let contactsData = {
        entityData: {
          ...commonData,
        },
      };
      // console.log(contactsData, "contactsData");
      this.addContact(contactsData);
    }
  };

  updateRolesData = (values) => {
    let rolesData = this.state.rolesData;
    rolesData.push({
      label: values,
      value: values.charAt(0).toUpperCase(),
      id: rolesData?.length + 1,
    });
    let data = {
      entityData: {
        rolesData,
        typeofDropDown: "roles",
      },
    };
    this.props.actionupdateDropDownOptions(this.props.rolesData[0]?.id, data);
  };

  addContact = (data) => {
    this.setState({
      modallLoading: true,
    });
    this.props.actionAddGenericContact(data);
  };

  onFinishLead = (values) => {
    const {
      contactInfo,
      dropoffLatitude,
      dropoffLongitude,
      selectedDropOffAddress,
      selectedPickupAddress,
      pickUpLatitude,
      pickupLongitude,
      isNewContact,
      selectedCompanyId,
    } = this.state;

    let commonData = {
      companyId:
        selectedCompanyId !== undefined && selectedCompanyId !== ""
          ? selectedCompanyId
          : "",
      company: values["customerAccount"],
      companyName: values["customerAccount"],
      multipleContacts:
        contactInfo?.length === 0
          ? [
              {
                firstName: values["customerAccount"],
                lastName: "",
                contactRole: "",
                phone: "",
                email: "",
                notes: "",
              },
            ]
          : contactInfo,
      leadType: [
        {
          id: "1",
          value: "Material Sales",
        },
      ],
      jobType: values?.jobType,
      jobName: "",
      dropoff: selectedDropOffAddress,
      dropOffLatLng: {
        lat: dropoffLatitude,
        lng: dropoffLongitude,
      },
      stageId: "Contacted",
      notes: values["notes"],
      isNewCompany:
        selectedCompanyId !== undefined && selectedCompanyId !== ""
          ? false
          : true,
      isNewContact,
    };
    let truckingData = {
      entityData: {
        ...commonData,
      },
    };
    let truckingWithPickUp = {
      entityData: {
        ...commonData,
        material: [
          {
            pickUpAddress: selectedPickupAddress,
            pickUpLatLng: {
              lat: pickUpLatitude,
              lng: pickupLongitude,
            },
          },
        ],
      },
    };
    let materialData = {
      entityData: {
        ...commonData,
        requiredMaterials: values["requiredMaterials"],
      },
    };

    if (values["jobType"] === "Trucking Job") {
      this.setState({ lead: false });
      if (selectedPickupAddress !== undefined && selectedPickupAddress !== "") {
        let url =
          "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=" +
          [dropoffLatitude, dropoffLongitude] +
          "&origins=" +
          [pickUpLatitude, pickupLongitude] +
          "&key=AIzaSyB781k9fNf61hnxE3A6cvcascwhAhIVIK4";
        let milesData = {
          entityData: {
            url,
          },
        };
        add("getMiles", milesData)
          .then((res) => {
            if (res.success) {
              truckingWithPickUp["entityData"]["material"][0]["duration"] =
                res.data.duration;
              truckingWithPickUp["entityData"]["material"][0]["milesData"] =
                res.data.distance;
              // console.log(truckingWithPickUp);
              this.props.actionAddJob(truckingWithPickUp);
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            console.log(err, "err");
          });
      } else {
        this.setState({ lead: false });
        // console.log(truckingData);
        this.props.actionAddJob(truckingData);
      }
    } else {
      this.setState({ lead: false });
      this.props.actionAddJob(materialData);
    }
  };

  deleteContactInfo = (id) => {
    let contactInfo = this.state.contactInfo.filter((c) => c.id !== id);
    this.setState({
      contactInfo,
    });
    message.info("Contact Removed Successfully!");
  };

  editContactInfo = (data) => {
    this.setState({
      isContactVisible: true,
      recordContactData: data,
    });
  };

  handleCheckStatus = (e) => {
    this.setState({ diameter: e });
    this.formRef.current.setFieldsValue({
      materialDiameter: "",
      materialMinDiameter: "",
      materialMaxDiameter: "",
    });
  };

  handleTableChage = (props) => {
    const tableSizeRange = JSON.parse(localStorage.getItem("tableSizeRange"));
    localStorage.setItem(
      "tableSizeRange",
      JSON.stringify({ ...tableSizeRange, truckingMaterial: props })
    );
  };

  onSelectCompanyName = (value, option) => {
    this.setState({
      companyCheck: value,
      selectedCompanyId: option.key,
    });
    this.getContactDetails(option.key);
  };

  handleChangeCompanyName = (value, option) => {
    this.setState({
      companyContacts: [],
    });
    if (option) {
      this.setState({
        selectedCompanyId: option.key,
      });
      this.getContactDetails(option.key);
    }
    this.setState({
      companyCheck: value,
    });
  };

  getContactDetails = (id) => {
    if (id) {
      list(appConfig["entityNames"]["updateContact"], { companyId: id }).then(
        (result) => {
          if (result.success) {
            this.setState({
              loading: false,
              companyContacts: result.data.filter((d) => d.isDriver !== true),
            });
          }
        }
      );
    }
  };

  render() {
    const filterInfo =
      JSON.parse(localStorage.getItem("filterStorageData"))?.filterShowData ||
      {};
    const {
      companyContacts,
      companyData,
      jobInfo,
      totalLeadsData,
      isContactVisible,
      newRole,
      vendorData,
      lead,
      contactInfo,
      recordContactData,
      showTab,
      rolesData,
    } = this.state;

    const { loading, errorLoading } = this.props;

    const customerColumns = [
      {
        title: "JOB#",
        dataIndex: "jobNumber",
        key: "jobNumber",
        sorter: false,
        className: "col_styling",
        width: "6%",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (jobNumber, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {jobNumber}
              </span>
            </>
          );
        },
      },
      {
        title: "JOB NAME",
        dataIndex: "",
        key: "",
        sorter: false,
        className: "col_styling",
        width: "200px",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record?.jobName ? record?.jobName : record?.jobNumber}
              </span>
            </>
          );
        },
      },
      {
        title: "JOB TYPE",
        dataIndex: "jobType",
        key: "jobType",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (jobType, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {jobType === 'Both' ? 'Trucking & Material' : jobType?.replace("Job", "")}
              </span>
            </>
          );
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "companyName",
        key: "companyName",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () =>
            this.props.history.push(
              `/app/customer-account-profile/${record.companyId}`
            ),
        }),
        render: (companyName, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {companyName}
              </span>
            </>
          );
        },
      },
      {
        title: "CONTACT",
        dataIndex: "multipleContacts",
        key: "multipleContacts",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (multipleContacts, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {multipleContacts?.length > 0 &&
                  multipleContacts[0]?.firstName +
                    " " +
                    multipleContacts[0]?.lastName}
              </span>
            </>
          );
        },
      },
      {
        title: "CONTACT PHONE",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (phone, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {record?.multipleContacts?.length > 0 &&
                  record?.multipleContacts[0]?.phone}
              </span>
            </>
          );
        },
      },
      {
        title: "DATE ADDED",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (createdAt, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {moment(createdAt).format("MM/DD/YYYY")}
              </span>
            </>
          );
        },
      },
      {
        title: "LAST ACTIVITY",
        dataIndex: "updatedAt",
        key: "updatedAt",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (updatedAt, record) => {
          return (
            <>
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
                className="table-font-mobile"
              >
                {moment(updatedAt).format("MM/DD/YYYY")}
              </span>
            </>
          );
        },
      },
      {
        title: "STAGE",
        dataIndex: "stageId",
        key: "stageId",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => {
            this.props.history.push(`/app/jobs/job-profile/${record.id}`);
          },
        }),
        render: (stageId) => {
          return (
            <Button
              className="lead_ButtonStatus"
              style={{
                background: "transparent",
                borderRadius: "6px",
                borderColor: appConfig["STAGE_COLORS"][stageId],
                color: appConfig["STAGE_COLORS"][stageId],
                borderWidth: "1px",
                borderStyle: "solid",
                width: "153px",
              }}
            >
              <span
                className="col_styling table-font-mobile"
                style={{
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  margin: "0px auto",
                }}
              >
                {stageId === "Close Job" ? "Closed" : stageId}
              </span>
            </Button>
          );
        },
      },
      {
        title: "ACTIONS",
        dataIndex: "actions",
        key: "actions",
        sorter: false,
        className: "col_styling",
        width: "5%",
        fixed: "right",

        render: (actions, record) => {
          return (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Col>
                  <Popover
                    placement="left"
                    content={
                      <Row className="popovergrid" style={{ maxWidth: "90px" }}>
                        {record?.stageId !== "Close Job" && (
                          <Col span={24}>
                            <Button
                              className="btn_edit"
                              onClick={() => {
                                this.props.history.push(
                                  `/app/updateJobDetails/${record.id}`
                                );
                              }}
                            >
                              <span className="span_edit">
                                <MdModeEditOutline
                                  className="table-font-mobile"
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                Edit
                              </span>
                            </Button>
                          </Col>
                        )}
                        <Col span={24}>
                          <Button className="btn_edit">
                            <Popconfirm
                              title="Are you sure？"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => this.deleteRecordData(record)}
                            >
                              <span className="span_edit">
                                <MdDelete style={{ marginRight: "5px" }} />{" "}
                                Delete
                              </span>
                            </Popconfirm>
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <EllipsisOutlined
                      style={{ fontSize: "30px", color: "grey" }}
                      className="action-ellipsis-button"
                    />
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      },
    ];

    const searchFields = ["jobNumber", "companyName", "jobName"];

    return (
      <>
        <Loading enableLoading={loading} />
        {totalLeadsData && (
          <div
            style={{
              background: "#fafafa",
            }}
          >
            <Row style={{ marginBottom: "1vw" }}>
              <Col
                xxl={{ span: 6 }}
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Row>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    className="quarrytoolgrid"
                  >
                    <SearchFunction
                      searchData={totalLeadsData}
                      tableName={"leads"}
                      getSearchData={this.searchResult}
                      onResetData={this.onResetData}
                      searchFields={searchFields}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xxl={{ span: 18 }}
                xl={{ span: 18 }}
                lg={{ span: 18 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ marginLeft: "auto" }}
              >
                <Row className="trucking-material-layout" gutter={[8, 0]}>
                  <Col className="gridbtntool">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() =>
                        this.setState({
                          lead: true,
                          jobInfo: "",
                          contactInfo: [],
                        })
                      }
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        margin: "auto 0px",
                      }}
                    >
                      <span>Job</span>
                    </Button>
                  </Col>

                  <Col className="gridbtntool">
                    <Button
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      onClick={() => {
                        this.setState({ visible: true });
                      }}
                    >
                      <Row>
                        <Col>
                          <span
                            style={{
                              color:
                                Object.keys(filterInfo)?.length > 0
                                  ? "red"
                                  : "#5a6067",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                            className="table-font-mobile"
                          >
                            {Object.keys(filterInfo)?.length > 0
                              ? `${
                                  "Filter (" + Object.keys(filterInfo)?.length
                                })`
                              : "Filter"}
                          </span>
                        </Col>
                        <Col>
                          <IoFilterSharp
                            style={{
                              fontSize: "22px",
                              marginLeft: "8px",
                              marginTop: "2px",
                              color:
                                Object.keys(filterInfo)?.length > 0
                                  ? "red"
                                  : "#5a6067",
                            }}
                            className="trucking-filter-icon"
                          />
                        </Col>
                      </Row>
                    </Button>
                  </Col>
                  <Col className="gridbtntool">
                    <Row
                      style={{
                        background: "#cecece",
                        height: "40px",
                        borderRadius: "6px",
                      }}
                    >
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background:
                              this.state.showTab === "listView"
                                ? "#fff"
                                : "transparent",
                            boxShadow: "none",
                            outline: "none",
                          }}
                          onClick={() => {
                            this.setState({ showTab: "listView" });
                            window.screen.width >= 1200 &&
                              this.props.context.updateSideronCollapse(false);
                          }}
                        >
                          <UnorderedListOutlined
                            style={{
                              fontSize: "22px",
                              color:
                                this.state.showTab === "listView"
                                  ? "#586370"
                                  : "#586370",
                            }}
                          />
                        </Button>
                      </Col>
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background:
                              this.state.showTab === "boardView"
                                ? "#fff"
                                : "transparent",
                            boxShadow: "none",
                            outline: "none",
                          }}
                          onClick={() => {
                            this.setState({ showTab: "boardView" });
                            window.screen.width >= 1200 &&
                              this.props.context.updateSideronCollapse(false);
                          }}
                        >
                          <RiDashboardLine
                            style={{
                              fontSize: "22px",
                              background:
                                this.state.showTab === "boardView"
                                  ? "#fff"
                                  : "transparent",
                            }}
                          />
                        </Button>
                      </Col>
                      <Col style={{ marginTop: "4px" }}>
                        <Button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            padding: "5px 8px",
                            margin: "0px 5px",
                            background:
                              this.state.showTab === "showMapComponent"
                                ? "#fff"
                                : "transparent",
                            boxShadow: "none",
                            outline: "none",
                          }}
                          onClick={() =>
                            this.setState({ showTab: "showMapComponent" })
                          }
                        >
                          <FaMap
                            style={{
                              fontSize: "22px",
                              color:
                                this.state.showTab === "showMapComponent"
                                  ? "#586370"
                                  : "#586370",
                            }}
                          />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            {showTab === "boardView" ? (
              <Row style={{ overflow: "auto" }}>
                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ overflow: "auto" }}
                >
                  {errorLoading ? (
                    <ErrorView refreshCrm={this.onClickRefresh} type="CRM" />
                  ) : (
                    <Card
                      bodyStyle={{
                        padding: "0px",
                        overflowX: "auto",
                        height: "100%",
                      }}
                      style={{
                        background: "#eff1f3",
                        minHeight: "70vh",
                      }}
                      className="kanbanscrollCard"
                    >
                      <DraggableCard
                        headingData={this.state.funnelData}
                        contentData={totalLeadsData}
                        requestMethod={this.onResetData}
                        truckTypeData={this.props.context.trucksData}
                        vendorData={vendorData}
                        leads
                      />
                    </Card>
                  )}
                </Col>
              </Row>
            ) : showTab === "showMapComponent" ? (
              <Row>
                <Col
                  xxl={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  className="twistednailmap"
                >
                  {this.showMap(totalLeadsData)}
                </Col>
              </Row>
            ) : (
              <Row data-aos="fade-up">
                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {errorLoading ? (
                    <ErrorView refreshCrm={this.onClickRefresh} type="CRM" />
                  ) : (
                    <Card bodyStyle={{ padding: "0px", overflowX: "auto" }}>
                      <BaseTable
                        className="customertablegrp table_laptopscreen table-font-mobile"
                        columnsData={customerColumns}
                        source={totalLeadsData.sort((a, b) =>
                          parseInt(a.jobNumber).valueOf() <
                          parseInt(b.jobNumber).valueOf()
                            ? 1
                            : -1
                        )}
                        // rowSelection={rowSelection}
                        total={totalLeadsData?.length}
                        rowKey={(record) => record.id}
                        handleTableChage={this.handleTableChage}
                        sizeRange={
                          window.screen.width > 1023
                            ? JSON.parse(localStorage.getItem("tableSizeRange"))
                                ?.truckingMaterial
                            : totalLeadsData?.length
                        }
                        pagination={window.screen.width > 1023 ? true : false}
                      />
                    </Card>
                  )}
                </Col>
              </Row>
            )}

            {isContactVisible && (
              <BaseModal
                className="crmform_modal antmodal_grid headermodal"
                title="Add New Contact"
                onCancel={() => {
                  this.setState({
                    isCheckBox: false,
                    isCheckBoxTruckType: false,
                    isContactVisible: false,
                    newRole: "",
                    isCheckcontact: false,
                  });
                }}
                loading={loading}
                visible={isContactVisible}
                destroyOnClose={true}
                formId="contact"
                width={
                  window.screen.width > "501" && window.screen.width <= "991"
                    ? "80%"
                    : "500px"
                }
              >
                <Form
                  style={{ width: "100%" }}
                  onFinish={this.onFinishContact}
                  id="contact"
                >
                  <Row gutter={[24, 0]}>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">First Name</span>
                      <Form.Item
                        name="fName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Your First Name!",
                          },
                        ]}
                        initialValue={recordContactData?.firstName}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({
                              firstName: e.target.value,
                            })
                          }
                          autoFocus
                          className="form-input"
                          placeholder="Please Enter Your First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Last Name</span>
                      <Form.Item
                        name="lName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter your Last Name!",
                          },
                        ]}
                        initialValue={recordContactData?.lastName}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({
                              lastName: e.target.value,
                            })
                          }
                          className="form-input"
                          placeholder="Please Enter your Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Phone</span>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Phone!",
                          },
                        ]}
                        initialValue={recordContactData?.phone}
                      >
                        <NumberFormat
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          className="ant-input-number numberictest"
                          format="(###) ###-####"
                          style={{ width: "100%" }}
                          placeholder="Please Enter Your Phone"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Email</span>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Email!",
                          },
                          {
                            type: "email",
                            message: "The Input Is Not Valid Email",
                          },
                        ]}
                        initialValue={recordContactData?.email}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          className="form-input"
                          placeholder="Please Enter Your Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Role</span>
                      <Form.Item
                        name="role"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Role!",
                          },
                        ]}
                        initialValue={recordContactData?.contactRole}
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          className="select_Height"
                          // style={{ height: "40px" }}
                          placeholder="Please Select A Role"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value) => {
                            this.setState({ newRole: value });
                            value === "Others" &&
                              message.info("Please Enter Role");
                          }}
                        >
                          {rolesData?.map((role) => (
                            <Option key={role.id} value={role.label}>
                              {role.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {newRole === "Add New Role" && (
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Add New Role</span>
                        <Form.Item
                          name="roleOther"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter New Role!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              this.setState({
                                roleOther: e?.target?.value,
                              })
                            }
                            className="form-input"
                            placeholder="Add New Role"
                          />
                        </Form.Item>

                        <Checkbox
                          checked={this.state.isCheckcontact}
                          onChange={(e) =>
                            this.setState({
                              isCheckcontact: e.target.checked,
                            })
                          }
                          style={{ marginBottom: "18px" }}
                        >
                          <span
                            className="checkBox_size"
                            style={{ fontSize: "14px" }}
                          >
                            Add this new role to default list
                          </span>
                        </Checkbox>
                      </Col>
                    )}
                    <Col
                      xxl={{ span: 24 }}
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Notes</span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Notes!",
                          },
                        ]}
                        initialValue={
                          recordContactData?.notes
                            ? recordContactData?.notes
                            : ""
                        }
                      >
                        <TextArea
                          rows={4}
                          style={{
                            width: "100%",
                            height: "65px",
                            borderColor: "#d6d6d6",
                          }}
                          className="form-input"
                          placeholder="Please Enter Your Notes"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </BaseModal>
            )}

            {this.state.visible && (
              <Filter
                visible={this.state.visible}
                closeFilter={this.closeFilter}
                filterResultData={this.filterResultData}
                onClearFilter={this.onClearFilter}
                truckingMaterial
                filterPropTruckingData={this.state.filterPropTruckingData}
                JobData={this.state.filterData}
                company={companyData}
                salesPersonName={this.props.context.salesPersonsData}
              />
            )}

            {lead && (
              <BaseModal
                className="crmform_modal antmodal_grid headermodal"
                title="Add New Job"
                onCancel={() =>
                  this.setState({ lead: false, isNewContact: true })
                }
                loading={loading}
                visible={lead}
                destroyOnClose={true}
                formId="lead"
                width={
                  window.screen.width > "501" && window.screen.width <= "991"
                    ? "80%"
                    : "560px"
                }
              >
                <Form
                  style={{ width: "100%" }}
                  onFinish={this.onFinishLead}
                  id="lead"
                  ref={this.formRef}
                >
                  <Row gutter={[24, 0]}>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Job Type</span>
                      <Form.Item
                        name="jobType"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Job Type!",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          allowClear
                          className="select_Height"
                          onChange={(value) =>
                            this.setState({
                              jobInfo: value,
                              pickUpAddress: "",
                              pickupLatitude: "",
                              pickupLongitude: "",
                              truckDropInfo: "",
                              isContactVisible: false,
                              selectedMaterial: [],
                            })
                          }
                          placeholder="Please Select A Job Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {jobType.map((eachItem) => (
                            <Option
                              key={eachItem.value}
                              value={eachItem.value}
                              className="option_style"
                            >
                              {eachItem.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span className="title_changes">Customer Account</span>
                      <Form.Item
                        name="customerAccount"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Customer Account!",
                          },
                        ]}
                      >
                        <AutoComplete
                          allowClear
                          size="large"
                          showSearch
                          className="select_Height"
                          style={{
                            borderColor: "#d6d6d6",
                          }}
                          onSelect={this.onSelectCompanyName}
                          onChange={this.handleChangeCompanyName}
                          placeholder="Please Select A Customer Account"
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        >
                          {companyData.map(
                            (eachItem) =>
                              eachItem.companyName && (
                                <Option
                                  key={eachItem.id}
                                  value={eachItem.companyName}
                                  className="option_style"
                                >
                                  {eachItem.companyName}
                                </Option>
                              )
                          )}
                        </AutoComplete>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <span className="title_changes">Contact Name</span>
                      <div className="contactname-multiple">
                        <Form.Item
                          name="contactperson"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Your Contact Name!",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            allowClear
                            mode="multiple"
                            showSearch
                            className="select_multiplequarytol"
                            style={{
                              height: "auto !important",
                              width: "100% !important",
                            }}
                            placeholder="Please Select A Contact Name"
                            onChange={(e, option) => {
                              this.setState({
                                contactInfo: option.map((e) => e.option),
                                isNewContact: false,
                              });
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {companyContacts?.map((eachItem, i) => (
                              <Option
                                key={i}
                                value={eachItem?.id}
                                option={eachItem}
                                className="option_style"
                              >
                                {eachItem.firstName + " " + eachItem.lastName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        {!isContactVisible && (
                          <Tooltip
                            title="Add Contact"
                            overlayStyle={{
                              backGroundColor: "#000",
                              color: "#000",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "500",
                                fontSize: "20px",
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "auto",
                                padding: "8px",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                height: "100%",
                                borderLeft: "1px solid #ddd",
                                zIndex: "+9",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.state.companyCheck !== ""
                                  ? this.setState({
                                      isContactVisible: true,
                                      recordContactData: "",
                                    })
                                  : message.info(
                                      "Please Enter Your Company Name First!"
                                    )
                              }
                            >
                              <UsergroupAddOutlined />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </Col>

                    <>
                      {contactInfo?.map((c) => (
                        <Col
                          xxl={{ span: 24 }}
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                          xs={{ span: 24 }}
                          style={{ marginBottom: "1vw" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                            }}
                          >
                            <Popover
                              content={
                                (c?.phone || c?.email) && (
                                  <Row>
                                    <Col
                                      xxl={{ span: 24 }}
                                      xl={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <span>{c.phone}</span>
                                    </Col>
                                    <Col
                                      xxl={{ span: 24 }}
                                      xl={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          alignItems: "center",
                                          justifyContent: "left",
                                        }}
                                      >
                                        {c.email}
                                      </span>
                                    </Col>
                                  </Row>
                                )
                              }
                            >
                              <span
                                className="title_changes"
                                style={{
                                  margin: "0px 5px",
                                  wordBreak: "break-word",
                                  maxWidth: "270px",
                                  width: "100%",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                }}
                              >
                                {c.contactRole
                                  ? c.firstName +
                                    " " +
                                    c.lastName +
                                    ", " +
                                    c.contactRole
                                  : c.firstName + " " + c.lastName}
                              </span>
                            </Popover>
                            <Col>
                              <Popover
                                placement="left"
                                content={
                                  <Row
                                    className="popovergrid"
                                    style={{ maxWidth: "80px" }}
                                  >
                                    <Col
                                      xxl={{ span: 24 }}
                                      xl={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <Button
                                        className="btn_edit"
                                        onClick={() => this.editContactInfo(c)}
                                      >
                                        <span className="span_edit">
                                          <MdModeEditOutline
                                            style={{ marginRight: "5px" }}
                                          />
                                          Edit
                                        </span>
                                      </Button>
                                    </Col>
                                    <Col
                                      xxl={{ span: 24 }}
                                      xl={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <Button className="btn_edit">
                                        <Popconfirm
                                          title="Are you sure？"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() =>
                                            this.deleteContactInfo(c.id)
                                          }
                                        >
                                          <span className="span_edit">
                                            <MdDelete
                                              style={{ marginRight: "5px" }}
                                            />{" "}
                                            Delete
                                          </span>
                                        </Popconfirm>
                                      </Button>
                                    </Col>
                                  </Row>
                                }
                              >
                                <EllipsisOutlined
                                  style={{ fontSize: "30px", color: "grey" }}
                                  className="action-ellipsis-button"
                                />
                              </Popover>
                            </Col>
                          </div>
                        </Col>
                      ))}
                    </>

                    {jobInfo === "Trucking Job" && (
                      <Col
                        xxl={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <span className="title_changes">Pickup Address</span>
                        <Form.Item
                          name="pickupAddress"
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Your Address",
                            },
                          ]}
                        >
                          <GooglePlacesAutocomplete
                            className="form-input"
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              placeholder: "Search Address...",
                              onChange: (address) => {
                                this.handleSelect(address, "pickup");
                                this.getAddress(address, "pickup");
                              },
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col
                      xxl={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <span className="title_changes">Delivery Address</span>
                      <Form.Item
                        name="deliveryAddress"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Address",
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          className="form-input"
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address, "dropOff");
                              this.getAddress(address, "dropOff");
                              this.setState({
                                dropAddress: address.label,
                              });
                            },
                          }}
                        />
                      </Form.Item>
                    </Col>

                    {jobInfo !== "Trucking Job" && (
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span className="title_changes">Materials</span>
                        <Form.Item
                          name="requiredMaterials"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Required Materials!",
                            },
                          ]}
                        >
                          <TextArea
                            rows={4}
                            style={{
                              width: "100%",
                              height: "65px",
                              borderColor: "#d6d6d6",
                            }}
                            className="form-input"
                            placeholder="Please Enter Required Materials"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      span={jobInfo !== "Trucking Job" ? 12 : 24}
                    >
                      <span className="title_changes">Notes</span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Your Notes!",
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          style={{
                            width: "100%",
                            height: "75px",
                            borderColor: "#d6d6d6",
                          }}
                          className="form-input"
                          placeholder="Please Enter Your Notes"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </BaseModal>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // console.log(state),
  ({
    result: state.reducer?.leadsData,
    loading: state.reducer?.leadsData.loading,
    errorLoading: state.reducer?.leadsData.error,
    companiesResult: state?.reducer?.companiesData,
    leadInfo: state?.reducer?.leadsInfo,
    leadUpdateInfo: state?.reducer?.leadUpdateInfo,
    companyResult: state?.reducer?.companyResult,
    contactsInfo: state.reducer?.contactsInfo,
    contactData: state.reducer?.contactData,
    rolesData: state.reducer?.dropDownOptions?.totalData?.filter(
      (q) => q?.typeofDropDown === "roles"
    ),
    materialList: state.reducer?.dropDownOptions?.totalData?.filter(
      (q) => q?.typeofDropDown === "material"
    ),
    dropDownOptions: state.reducer.dropDownOptions,
    dropDownOptionsInfo: state.reducer?.dropDownOptionsInfo,
    quarriesData: state.reducer.quarriesResult,
    topVendorsData: state.reducer.vendorsData,
  });

const mapDispatchToProps = (dispatch) => ({
  actionFetchLeads: (id) => {
    dispatch(getLeads(id));
  },
  actionFetchCompanies: () => {
    dispatch(getCompanies());
  },
  actionAddJob: (data) => {
    dispatch(addLead(data));
  },
  actionUpdateJob: (id, data) => {
    dispatch(updateLead(id, data));
  },
  actionDeleteLead: (id) => {
    dispatch(deleteLead(id));
  },
  actionFetchCompany: (id) => {
    dispatch(getCompany(id));
  },
  actionAddContact: (data) => {
    dispatch(addContacts(data));
  },
  actionAddGenericContact: (data) => {
    dispatch(addContact(data));
  },
  actiongetDropDownOptions: () => {
    dispatch(getDropDownOptions());
  },
  actionupdateDropDownOptions: (id, data) => {
    dispatch(updateDropDownOptions(id, data));
  },
  actionFetchQuarries: () => {
    dispatch(getQuarryLocations());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAppContext(withRouter(TruckingMaterial)));
